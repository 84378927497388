export const isTesterClientCode = (clientCode) => {
    return clientCode === "398667" || clientCode === "365984";
};
export const isMarikaClientCode = (clientCode) => {
    return clientCode === "396604";
};

export const isAlasKuul = (clientCode) => {
    return clientCode === "52560" || clientCode === "385614";
};

export const isPrimePartner = (clientCode) => {
    return clientCode === "385484" || clientCode === "388313";
};

export const isPrimePartnerEst = (clientCode) => {
    return clientCode === "385484";
};

export const isOverall = (clientCode) => {
    return clientCode === "505122" || clientCode === "502391";
};

export const isNBQ = (clientCode) => {
    return clientCode === "3308" || clientCode === "512290";
};

export const isAmericanTestAccount = (clientCode) => {
    return clientCode === "10152";
};

export const isRehvidPluss = (clientCode) => {
    return clientCode === "8577" || isMarikaClientCode(clientCode) || isTesterClientCode(clientCode);
};

export const isTAF = (clientCode) => {  // Test accounts : 300 - 399, production: 400 - 599, 467489
    const firstDigit = clientCode.substr(0, 1);
    return clientCode === "505502" || clientCode === "467489" || (clientCode.length === 3 && (firstDigit === "3" || firstDigit === "4" || firstDigit === "5"));
};

export const isTerra = (clientCode) => {
    return ["469693", "469694", "469695", "470228", "470574", "470575", "470576", "471517"].includes(clientCode);
};

export const isChange = (clientCode) => {
    return clientCode === "507062";
};

export const isOkaidi = (clientCode) => {
    return clientCode === "507760";
};

export const isRealiseerimiskeskus = (clientCode) => {
    return clientCode === "4686";
};

export const isCorpowear = (clientCode) => {
    return clientCode === "510871" || clientCode === "511843";
};

export const isRFix = (clientCode) => {
    return clientCode === "511851" || clientCode === "351127";
};

export const isOnOff = (clientCode) => {
    return clientCode === "58624";
};

export const isUuemViis = (clientCode) => {
    return clientCode === "4547";
};

export const isArvutitark = (clientCode) => {
    return clientCode === "512765";
};

export const isInfralink = (clientCode) => {
    return clientCode === "396685";
};

export const isMatkasuvilad = (clientCode) => {
    return clientCode === "395694" || clientCode === "519598";
};
