export const verifyUserSuccess = (user, clientCode, eSess, sessionRefreshTimestamp, logOutTimestamp) => {
    return {
        type: "VERIFY_USER_SUCCESS",
        payload: {
            user: user,
            clientCode: clientCode,
            eSess: eSess,
            sessionRefreshTimestamp: sessionRefreshTimestamp,
            logOutTimestamp: logOutTimestamp
        }
    }
};

export const logout = (showYouWereLoggedOutMessage = false) => {
    return {
        type: "LOGOUT",
        showYouWereLoggedOutMessage: showYouWereLoggedOutMessage
    }
};

export const setLogOutWarningShown = (logOutWarningShown) => {
    return {
        type: "LOG_OUT_WARNING_SHOWN",
        payload: {
            logOutWarningShown: logOutWarningShown
        }
    }
};
