import React, {useState, useEffect} from 'react';
import {Button, Checkbox, Dropdown, Label} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {componentSet} from "../actions/component";
import {getVatRates, makeErplyRequest, makeReportsApiRequest} from "../util/erplyRequests";
import {errorMessageSet} from "../actions/headerMessage";
import {setFulfillableOrders} from "../actions/getSalesDocuments";
import {getDeliveryTypesSuccess} from "../actions/getDeliveryTypes";
import GoBackToStartBtn from "./GoBackToStartBtn";
import GoBackBtn from "./GoBackBtn";
import {
    addWorkDaysToDate, filterOutDocumentsWithAttributeValue,
    filterOutDocumentsWithNoRows,
    formatDate,
    getApiAttributeValue, getFulfillableOrderTotal,
} from "../util/misc";
import {isArvutitark, isOverall, isPrimePartner, isRehvidPluss} from "../util/isClient";
import {setFulfillableOrdersFilters} from "../actions/filters";
import DatePickerComponent from "./DatePickerComponent";

const FulfillableOrdersFilters = () => {
    const deliveryTypes = useSelector(state => state.getDeliveryTypesReducer.deliveryTypes);
    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);
    const clientCode = useSelector(state => state.verifyUserReducer.clientCode);
    const fulfillableOrdersFilters = useSelector(state => state.filtersReducer.fulfillableOrdersFilters);

    const isPP = isPrimePartner(clientCode);
    const isOV = isOverall(clientCode);

    const [fromDate, setFromDate] = useState(fulfillableOrdersFilters !== null ? fulfillableOrdersFilters.fromDate : isPP ? addWorkDaysToDate(new Date(), -7) : null);
    const [toDate, setToDate] = useState(fulfillableOrdersFilters !== null ? fulfillableOrdersFilters.toDate : isPP ? addWorkDaysToDate(new Date(), 1) : null);
    const [shipDateFrom, setShipDateFrom] = useState(fulfillableOrdersFilters !== null ? fulfillableOrdersFilters.shipDateFrom : isPP ? addWorkDaysToDate(new Date(), -7) : null);
    const [shipDateTo, setShipDateTo] = useState(fulfillableOrdersFilters !== null ? fulfillableOrdersFilters.shipDateTo : isPP ? addWorkDaysToDate(new Date(), 1) : null);
    const [selectedDeliveryTypeIDs, setSelectedDeliveryTypeIDs] = useState(fulfillableOrdersFilters !== null && fulfillableOrdersFilters.selectedDeliveryTypeIDs.length > 0 ? fulfillableOrdersFilters.selectedDeliveryTypeIDs : []);
    const [paidOrdersOnly, setPaidOrdersOnly] = useState(fulfillableOrdersFilters !== null ? fulfillableOrdersFilters.paidOrdersOnly : isArvutitark(clientCode));
    const [checkCustomerBalance, setCheckCustomerBalance] = useState(fulfillableOrdersFilters !== null ? fulfillableOrdersFilters.checkCustomerBalance : isArvutitark(clientCode));

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        getDeliveryTypes();
    }, []);

    const handleGoBackOnClick = () => {
        dispatch(componentSet("SalesOrder"));
    };

    const handleOnDeliveryTypeChange = (event, { value }) => {
        setSelectedDeliveryTypeIDs(value);
    };

    const getDeliveryTypeOptions = () => {
        const nullOption = {key: 0, text: "", value: null};
        const options = deliveryTypes.map(deliveryType => getDeliveryTypeOption(deliveryType));
        options.unshift(nullOption);

        return options;
    };

    const getDeliveryTypeOption = (deliveryType) => {
        return {key: deliveryType.deliveryTypeID, text: deliveryType.name, value: deliveryType.deliveryTypeID};
    };

    const getDeliveryTypes = () => {
        const params = {
            request: "getDeliveryTypes"
        };
        dispatch(makeErplyRequest(params, t("getDeliveryTypesError"), null, getDeliveryTypesSuccess, null, true));
    };

    const getFulfillableOrders = async () => {
        dispatch(setFulfillableOrdersFilters({fromDate: fromDate, toDate: toDate, shipDateFrom: shipDateFrom, shipDateTo: shipDateTo,
            selectedDeliveryTypeIDs: selectedDeliveryTypeIDs, paidOrdersOnly: paidOrdersOnly, checkCustomerBalance: checkCustomerBalance}));
        const noOfRequests = selectedDeliveryTypeIDs.length === 0 ? 1 : selectedDeliveryTypeIDs.length;
        let requests = [dispatch(getVatRates(t))];

        for (let i = 0; i < noOfRequests; i++) {
            const params = {
                warehouseId: selectedWarehouse.warehouseID
            };

            if (fromDate !== null) {
                params.startDate = formatDate(fromDate);
            }
            if (toDate !== null) {
                params.endDate = formatDate(toDate);
            }
            if (shipDateFrom !== null) {
                params.deliveryStartDate = formatDate(shipDateFrom);
            }
            if (shipDateTo !== null) {
                params.deliveryEndDate = formatDate(shipDateTo);
            }
            if (selectedDeliveryTypeIDs.length > 0) {
                params.deliveryTypeId = selectedDeliveryTypeIDs[i];
            }
            if (paidOrdersOnly) {
                params.paidOrdersOnly = paidOrdersOnly
            }

            requests.push(dispatch(makeReportsApiRequest(t, "v1/orders/fulfillable", null, params, true)));
        }

        const responses = await Promise.all(requests);
        const vatRates = responses[0];

        let fulfillableOrders = [];
        for (let i = 1, n = responses.length; i < n; i++) { // First response is of "getVatRates"
            fulfillableOrders = fulfillableOrders.concat(filterOutDocumentsWithNoRows(responses[i].data));
        }

        if (fulfillableOrders.length === 0) return dispatch(errorMessageSet(t("criteriaDocsNotFound")));

        if (checkCustomerBalance) {
            const customerIDs = fulfillableOrders.map(order => order.customerId);
            const customers = await dispatch(makeErplyRequest({
                request: "getCustomers",
                customerIDs: [...new Set(customerIDs)].join(","),
                getBalanceInfo: 1
            }, t("getCustomersError"), null, null, null, true, true));

            fulfillableOrders = filterOrdersByCustomerBalance(fulfillableOrders, customers, vatRates);
        }

        let salesOrders = await getSalesOrders(fulfillableOrders);
        if (isOV) {
            fulfillableOrders = filterOrdersByDocumentTypeAttribute(salesOrders, fulfillableOrders);
        }
        fulfillableOrders = setExtraData(salesOrders, fulfillableOrders);    // Otherwise re-orders (they have custom numbers) are displayed with number 0
        if (isRehvidPluss(clientCode)) {
            salesOrders = filterOutDocumentsWithAttributeValue(salesOrders, "documentScannedInWms", 1);
            fulfillableOrders = fulfillableOrders.filter(fulfillableOrder => salesOrders.some(salesOrder => salesOrder.id == fulfillableOrder.id));
        }

        if (fulfillableOrders.length === 0) return dispatch(errorMessageSet(t("criteriaDocsNotFound")));

        dispatch(setFulfillableOrders(fulfillableOrders));
        dispatch(componentSet("FulfillableOrders"));
    };

    const setExtraData = (orders, filteredDocs) => {
        for (let i = 0, n = orders.length; i < n; i++) {
            for (let j = 0, n = filteredDocs.length; j < n; j++) {
                if (orders[i].id == filteredDocs[j].id) {
                    filteredDocs[j].number = orders[i].number;
                    filteredDocs[j].deliveryTypeName = orders[i].deliveryTypeName;
                    break;
                }
            }
        }

        return filteredDocs;
    };

    const getSalesOrders = (orders) => {
        let orderIDs = [];

        // Get re-orders only or all orders in case of OV or RP to get attributes
        for (let i = 0, n = orders.length; i < n; i++) {
            if (orders[i].number == 0 || isOV || isRehvidPluss(clientCode)) {
                // if (orders[i].number == 0 || isOV || isRehvidPluss(clientCode) || isAlasKuul(clientCode)) {
                orderIDs.push(orders[i].id);
            }
        }

        if (orderIDs.length === 0) {
            return Promise.resolve(orders);
        } else {
            const params = {
                request: "getSalesDocuments",
                ids: orderIDs.join(",")
            };
            return dispatch(makeErplyRequest(params, t("getSalesDocumentsError"), null, null, null, true));
        }
    };

    const filterOrdersByDocumentTypeAttribute = (salesDocuments, orders) => {
        let filteredOrders = [];

        for (let i = 0, n = salesDocuments.length; i < n; i++) {
            const documentType = getApiAttributeValue("documentType", salesDocuments[i].attributes);

            if (documentType === "sale" || documentType === "web" || documentType === "vantage") {
                const order = orders.find(order => order.id === salesDocuments[i].id);
                filteredOrders.push(order);
            }
        }

        return filteredOrders;
    };

    const filterOrdersByCustomerBalance = (fulfillableOrders, customers, vatRates) => {
        const filteredOrders = [];

        fulfillableOrders.forEach(order => {
            const orderTotal = getFulfillableOrderTotal(order, vatRates);
            const customer = customers.find(customer => customer.customerID == order.customerId);
            if (customer && customer.availableCredit >= orderTotal) filteredOrders.push(order);
        });

        return filteredOrders;
    };

    return (
        <div className={"textAlignLeft"}>
            <DatePickerComponent labelText={"dateFrom"} value={fromDate} setStateFunction={setFromDate} className={"searchInput"}/>
            <DatePickerComponent labelText={"dateTo"} value={toDate} setStateFunction={setToDate} className={"searchInput"}/>
            <DatePickerComponent labelText={"shipDateFrom"} value={shipDateFrom} setStateFunction={setShipDateFrom} className={"searchInput"}/>
            <DatePickerComponent labelText={"shipDateTo"} value={shipDateTo} setStateFunction={setShipDateTo} className={"searchInput"}/>
            <div className={"searchRow"}>
                <Label className={"searchLabel searchLabelMedium"} size={"large"} pointing={"right"}>{t("deliveryType")}</Label>
                <Dropdown
                    className={"searchInput searchInputMedium"}
                    selection
                    options={getDeliveryTypeOptions()}
                    onChange={handleOnDeliveryTypeChange}
                    clearable
                    multiple
                    fluid
                    value={selectedDeliveryTypeIDs}
                />
            </div>
            <div className={"btnsGroup"}>
                <Button className={"menuBtn"} color={"blue"} size={"big"} onClick={getFulfillableOrders}>{t("search")} </Button>
                <div className={"flex flexCenter"}>
                    <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                    <GoBackToStartBtn/>
                </div>
            </div>
            <div className={"marginTop"}>
                <Checkbox checked={paidOrdersOnly} onChange={() => setPaidOrdersOnly(!paidOrdersOnly)} label={<label className={"settingsCheckboxLabel"}>{t("paidOrdersOnly")}</label>}/><br/>
                <Checkbox checked={checkCustomerBalance} onChange={() => setCheckCustomerBalance(!checkCustomerBalance)} label={<label className={"settingsCheckboxLabel"}>{t("checkCustomerBalance")}</label>}/>
            </div>
        </div>
    );
};

export default FulfillableOrdersFilters
