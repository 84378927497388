const initialState = {
    component: "Login",
    previousComponent: null,
    previousSettingsComponent: null,    //Rendered after going back from the settings component
    preSettingsPreviousComponent: null,
    componentSequence: [],
    settingsBtnHasBeenClicked: false,
    changeScannedProductsDifferencesOnly: false,    // Set if changes are applied to all scanned products or to scanned products which amounts differ from amounts on document in ChangeScannedProducts component
    preSettingsSequence: [], // Sequence before clicking Settings button,
    isLoading: false,
    isLongLoad: false
};

export const componentReducer = (state = initialState, action) => {
    switch(action.type) {
        case "COMPONENT_SET":
            return {
                ...state,
                component: action.payload,
                componentSequence: getSequence(state.componentSequence, action.payload)
            };
        case "PREVIOUS_COMPONENT_SET":
            return {
                ...state,
                previousComponent: action.payload,
            };
        case "SET_SEQUENCE":
            return {
                ...state,
                componentSequence: action.payload,
            };
        case "SET_PREVIOUS_SETTINGS_COMPONENT":
            return {
                ...state,
                previousSettingsComponent: action.payload,
                preSettingsPreviousComponent: state.previousComponent
            };
        case "SET_SETTINGS_BTN_HAS_BEEN_CLICKED":
            return {
                ...state,
                settingsBtnHasBeenClicked: action.payload,
            };
        case "SET_CHANGE_SCANNED_PRODUCTS_DIFFERENCES_ONLY":
            return {
                ...state,
                changeScannedProductsDifferencesOnly: action.payload,
            };
        case "SET_PRE_SETTINGS_SEQUENCE":
            return {
                ...state,
                preSettingsSequence: action.payload,
            };
        case "SET_IS_LOADING":
            return {
                ...state,
                isLoading: action.payload.isLoading,
                isLongLoad: action.payload.isLongLoad,
            };
        default:
            return state;
    }
};

const getSequence = (componentSequence, newComponent) => {
    if (!componentSequence.includes(newComponent)) {
        componentSequence.push(newComponent);
        return componentSequence;
    } else {
        componentSequence.pop();
        return componentSequence;
    }
};
