import React, {useState, useRef, useEffect} from 'react';
import {Button, Dropdown, Input, Label} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {componentSet, setIsLoading, setSequence} from "../actions/component";
import {useDispatch, useSelector} from "react-redux";
import {errorMessageSet} from "../actions/headerMessage";
import {makeErplyRequest} from "../util/erplyRequests";
import {getDeliveryTypesSuccess} from "../actions/getDeliveryTypes";
import {getApiAttributeValue, getConfParameterValue, isNotAPositiveInteger, simpleLog} from "../util/misc";
import {isAlasKuul, isCorpowear, isNBQ, isOnOff, isPrimePartner} from "../util/isClient";
import {sendToPrintService} from "../util/printRequests";
import {dpdUserWarehouseIdAttributeName, inventoryTransferDeliveryTypeAttributeName} from "../util/constants";
import {getWarehousesSuccess} from "../actions/getWarehouses";

const DpdParcelLabelsNew = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const createdDocumentId = useSelector(state => state.scanReducer.createdDocumentId);
    const selectedDocumentsProducts = useSelector(state => state.scanReducer.selectedDocumentsProducts);
    const clientCode = useSelector(state => state.verifyUserReducer.clientCode);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const summedScannedProducts = useSelector(state => state.scanReducer.summedScannedProducts);
    const componentSequence = useSelector(state => state.componentReducer.componentSequence);
    const selectedDocuments = useSelector(state => state.scanReducer.selectedDocuments);
    const deliveryTypes = useSelector(state => state.getDeliveryTypesReducer.deliveryTypes);
    const warehouses = useSelector(state => state.getWarehousesReducer.warehouses);
    const isInventoryTransfer = componentSequence.includes("InventoryTransfer");
    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);

    const serviceEngineUrl = clientCode === "398667" || clientCode === "396604" ? "https://se-api.erply.com/DpdTest/V2" : // Sandbox
        "https://se-api.erply.com/Dpd/V2";  // Live
    const getServiceEngineApiKeyUrl = "https://se-api.erply.com/Engine/V1/sessions/key/create";

    const noOfParcelsOnDocument = getApiAttributeValue("num_of_parcel", selectedDocuments[0].attributes);
    const baseDocActualSourceWarehouseID = getApiAttributeValue(dpdUserWarehouseIdAttributeName, selectedDocuments[0].attributes); // OnOff only
    const deliveryTypeCodeOnTransferOrder = getApiAttributeValue(inventoryTransferDeliveryTypeAttributeName, selectedDocuments[0].attributes); // Inventory transfers only

    const [noOfParcels, setNoOfParcels] = useState(noOfParcelsOnDocument && noOfParcelsOnDocument != 0 ? noOfParcelsOnDocument : 1);
    const [inventoryTransferDeliveryTypeCode, setInventoryTransferDeliveryTypeCode] = useState(deliveryTypeCodeOnTransferOrder ? deliveryTypeCodeOnTransferOrder : 0); // Inventory transfers only
    const [actualSourceWarehouseID, setActualSourceWarehouseID] = useState(baseDocActualSourceWarehouseID ? baseDocActualSourceWarehouseID : selectedWarehouse.warehouseID);

    const serviceEngineErrorCounter = useRef(0);

    useEffect(() => {
        if (isInventoryTransfer) {
            getDeliveryTypes();
        }
        if (isOnOff(clientCode)) {
            getWarehouses();
        }
    }, []);

    const isOpenInAndroidApp = window.ReactNativeWebView;
    const canDpdPrint = dispatch(getConfParameterValue("wmsDpdPrinterName")) !== "" && dispatch(getConfParameterValue("wmsPrinterServiceAddress")) !== "" && isOpenInAndroidApp;

    const isAK = isAlasKuul(clientCode);
    const isPP = isPrimePartner(clientCode);

    const getWarehouses = (warehouseID) => {
        const params = {request: "getWarehouses"};
        let successAction = getWarehousesSuccess;
        if (warehouseID) {
            params.warehouseID = warehouseID;
            successAction = null;
        }
        return dispatch(makeErplyRequest(params, t("getWarehousesError"), null, successAction, null, true));
    };

    const getCompanyInfo = () => {
        return dispatch(makeErplyRequest({request: "getCompanyInfo"}, t("getCompanyInfoError"))).then(data => data[0]);
    };

    const getCustomer = (clientID) => {
        const params = isInventoryTransfer ?
            {
                request: "getWarehouses",
                warehouseID: clientID
            } :
            {
                request: "getCustomers",
                customerID: clientID,
                getAddresses: 1,
                getContactPersons: 1
            };

        const errorText = isInventoryTransfer ? t("getWarehousesError") : t("getCustomersError");
        return dispatch(makeErplyRequest(params, errorText)).then((customers) => {
            return customers[0];
        });
    };

    const createDelivery = async () => {
        if (isNotAPositiveInteger(noOfParcels)) {
            return dispatch(errorMessageSet(t("notAPositiveInteger")));
        } else if (noOfParcels > 20) {
            return dispatch(errorMessageSet(t("maxNoOfParcelsIs20")));
        } else if (isOpenInAndroidApp && !canDpdPrint) {
            return dispatch(errorMessageSet(t("checkDpdSettings")));
        }

        const [document, senderWarehouse, companyData] = await Promise.all([getDocument(), getWarehouses(actualSourceWarehouseID).then(warehouses => warehouses[0]), getCompanyInfo()]);

        if ((isInventoryTransfer && inventoryTransferDeliveryTypeCode == 0) ||
            (!isInventoryTransfer && (!document.hasOwnProperty("deliveryTypeName") || document.deliveryTypeName === null || !document.deliveryTypeName.startsWith("DPD - ")))) {
            return dispatch(errorMessageSet(`DPD ${t("deliveryConditionMissing")}`));
        }

        const senderName = companyData.name.trim().substring(0, 35);
        if (senderName === "") return dispatch(errorMessageSet(t("companyNameMustBeSet")));
        const senderPhone = senderWarehouse.phone.trim().substring(0, 30);
        if (senderPhone === "") return dispatch(errorMessageSet(t("warehousePhoneMustBeSet")));
        const senderStreet = senderWarehouse.street.trim().substring(0, 35);
        if (senderStreet === "") return dispatch(errorMessageSet(t("warehouseAddressStreetMustBeSet")));
        const senderCity = senderWarehouse.city.trim().substring(0, 35);
        if (senderCity === "") return dispatch(errorMessageSet(t("warehouseAddressCityMustBeSet")));
        const senderPostalCode = senderWarehouse.ZIPcode.trim().substring(0, 7);
        if (senderPostalCode === "") return dispatch(errorMessageSet(t("warehouseAddressZipCodeMustBeSet")));

        const receiverID = getReceiver(document);
        const receiverAddressID = confParameters.invoice_client_is_payer == 1 ? document.shipToAddressID : document.addressID;
        const deliveryTypeRequiresParcelTerminal = document.deliveryTypeName === "DPD - Tavapakk, B2C, Pakipoodi saatmine";
        const deliveryTypeRequiresMobileNo = document.deliveryTypeName === "DPD - Tavapakk" || document.deliveryTypeName === "DPD - Tavapakk, laupäevane toimetus";
        const parcelTerminal = getApiAttributeValue("parcel_terminal", document.attributes);
        const weight = getWeight();

        if (!confParameters.hasOwnProperty("dpdServiceEngineApiKey") || confParameters.dpdServiceEngineApiKey === "") {
            return dispatch(errorMessageSet(t("noDpdServiceEngineApiKey")));
        } else if (receiverID === "" || receiverID == 0) {
            return dispatch(errorMessageSet(t("createdDocumentHasNoReceiver")));
        } else if (!isInventoryTransfer && !deliveryTypeRequiresParcelTerminal && (receiverAddressID === "" || receiverAddressID == 0)) {
            return dispatch(errorMessageSet(t("createdDocumentHasNoReceiverAddress")));
        } else if (deliveryTypeRequiresParcelTerminal) {
            if (!parcelTerminal || parcelTerminal === "" || parcelTerminal == 0) {
                return dispatch(errorMessageSet(t("orderHasNoParcelTerminal")));
            }

            const maxAverageWeight = 31.5;
            if (weight / noOfParcels > maxAverageWeight) {
                const errorMessage = `${t("parcelWeightMustNotExceed")} ${maxAverageWeight} kg. ${t("pleaseSplitDelivery")}`;
                return dispatch(errorMessageSet(errorMessage));
            }
        }

        const receiver = await getCustomer(receiverID);
        const contactPerson = getContactPerson(receiver, document);
        const address = getAddress(receiver, receiverAddressID);

        if (address.street === "") {
            return dispatch(errorMessageSet(t("deliveryAddressHasNoStreet")));
        } else if (address.city === "") {
            return dispatch(errorMessageSet(t("deliveryAddressHasNoCity")));
        } else if ((isInventoryTransfer && address.ZIPcode === "") || (!isInventoryTransfer && address.postalCode === "")) {
            return dispatch(errorMessageSet(t("deliveryAddressHasNoPostalCode")));
        } else if ((isInventoryTransfer && receiver.country === "") || (!isInventoryTransfer && (receiver.countryID === "" || receiver.countryID == 0))) {
            return dispatch(errorMessageSet(t("receiverHasNoCountry")));
        } else if (isInventoryTransfer && receiver.country.length !== 2) {
            return dispatch(errorMessageSet(t("countryMustBeCountryCode")));
        }

        if (!isInventoryTransfer && isAK) {
            if (contactPerson === null) {
                return dispatch(errorMessageSet(t("orderHasNoContactPerson")));
            } else if (contactPerson.mobile === "") {
                if (isAK && deliveryTypeRequiresMobileNo) {
                    return dispatch(errorMessageSet(t("contactPersonHasNoMobileNo")));
                }
            }
        } else {
            const phoneField = isInventoryTransfer ? "phone" : "mobile";
            if (receiver[phoneField] === "") {
                return dispatch(errorMessageSet(t("receiverHasNoMobileNo")));
            } else if (!isAK && receiver[phoneField].substring(0, 1) !== "+") {
                return dispatch(errorMessageSet(t("receiverMobileNoMustBeginWithAreaCode")));
            }
        }

        let deliveryTypeCode;
        if (isInventoryTransfer) {
            deliveryTypeCode = inventoryTransferDeliveryTypeCode;
        } else {
            const deliveryTypes = await getDeliveryTypes();
            deliveryTypeCode = deliveryTypes.find(type => type.deliveryTypeID == document.deliveryTypeID).code;
        }

        let countryCode = "EE";
        if (isInventoryTransfer) {
            countryCode = receiver.country;
        } else if (receiver.countryID && receiver.countryID != 0) {
            const countries = await getCountries();
            countryCode = countries.find(country => country.countryID == receiver.countryID).countryCode;
        }

        const receiverName = (isInventoryTransfer ? receiver.name : receiver.fullName).substring(0, 35);
        const receiverStreet = deliveryTypeRequiresParcelTerminal ? "" : address.street.trim().substring(0, 35);
        const receiverCity = deliveryTypeRequiresParcelTerminal ? "" : address.city.trim().substring(0, 35);
        const receiverPostalCode = deliveryTypeRequiresParcelTerminal ? "" : (isInventoryTransfer ? address.ZIPcode : address.postalCode).trim().substring(0, 7);
        const pudoId = deliveryTypeRequiresParcelTerminal ? parcelTerminal : null;
        const defaultFormat = "A6";
        const paperSize = confParameters.hasOwnProperty("dpdLabelFormat") ? confParameters.dpdLabelFormat : defaultFormat;
        let documentNo = String(document[isInventoryTransfer ? "inventoryTransferNo" : "number"]);
        if (documentNo === "" && document.customNumber !== "") documentNo = document.customNumber;

        const params = {
            senderName: senderName,
            senderPhone: senderPhone,
            senderStreet: senderStreet,
            senderCity: senderCity,
            senderPostalCode: senderPostalCode,
            receiverName: receiverName,
            receiverEmail: receiver.email.trim().substring(0, 100),
            receiverPhone: getReceiverPhone(receiver, contactPerson, countryCode),
            receiverStreet: receiverStreet,
            receiverCity: receiverCity,
            receiverPostalCode: receiverPostalCode,
            receiverCountry: countryCode,
            contactName: contactPerson ? contactPerson.fullName : "",
            contactInfo: getContactInfo(contactPerson, countryCode, document),
            shipmentReferences: [documentNo],
            noOfParcels: noOfParcels,
            weight: weight,
            serviceAlias: getDeliveryTypeCode(deliveryTypeCode),
            pudoId: pudoId,
            paperSize: paperSize,
            additionalServices: []
        };

        console.log("Create delivery request", params);
        simpleLog("Params sent to DPD: " + JSON.stringify(params));

        const url = `${serviceEngineUrl}/createDelivery`;
        const serviceEngineResponse = await sendServiceEngineRequest("post", url, JSON.stringify(params));

        if (serviceEngineResponse !== false) {
            const pdf = serviceEngineResponse[0].shipmentLabels.pages[0].binaryData.replace("data:application/pdf;base64,", "");
            const parcelNos = serviceEngineResponse[0].parcelNumbers.join("|");
            addDpdAttributes(parcelNos, document);

            if (isOpenInAndroidApp) {
                sendToPrintService(pdf, dispatch(getConfParameterValue("wmsDpdPrinterName")), dispatch(getConfParameterValue("wmsPrinterServiceAddress")));
            } else {
                downloadDpdLabels(pdf, document);
            }

            setPreScanComponent();
        }
    };

    const getAddress = (receiver, receiverAddressID) => {
        if (isInventoryTransfer) {
            return receiver;
        } else {
            if (!receiver.addresses) return {};
            return receiver.addresses.find(address => address.addressID == receiverAddressID);
        }
    };

    // Mapping for new DPD API
    const getDeliveryTypeCode = (deliveryTypeCode) => {
        if (deliveryTypeCode === "D") return "CLASSIC";
        if (deliveryTypeCode === "D-SAT") return "SAT_CLASSIC";
        if (deliveryTypeCode === "E10") return "DPD10";
        if (deliveryTypeCode === "E12") return "DPD12";
        if (deliveryTypeCode === "D-B2C") return "PRIVATE";
        if (deliveryTypeCode === "D-B2C-SAT") return "SAT_PRIVATE";
        if (deliveryTypeCode === "DPD MAX") return "MAX";
        if (deliveryTypeCode === "DPDG") return "GUARANTEE";
        return deliveryTypeCode;
    };

    const getContactInfo = (contactPerson, country, document) => {
        let contactInfo = "";

        const addToContactInfo = (text) => {
            if (text === "") return;
            if (contactInfo !== "") contactInfo += "; ";
            contactInfo += text;
        };

        if (isCorpowear(clientCode)) {
            addToContactInfo(document.packingUnitsDescription);
        } else if (contactPerson) {
            const contactPersonPhone = modifyPhoneNoStart(contactPerson.phone, country);
            const contactPersonMobile = modifyPhoneNoStart(contactPerson.mobile, country);
            addToContactInfo(contactPersonPhone);
            addToContactInfo(contactPersonMobile);
        }

        return contactInfo.trim().substring(0, 35);
    };

    const removeForbiddenCharsFromPhoneNo = (phoneNo) => {
        return phoneNo.replaceAll(" ", "").replaceAll("-", "");
    };

    const getReceiverPhone = (customer, contactPerson, country) => {
        let receiverPhone = removeForbiddenCharsFromPhoneNo(isInventoryTransfer ? customer.phone : customer.mobile);

        if (!isInventoryTransfer) {
            if ((isCorpowear(clientCode) || isNBQ(clientCode) || isAK) && contactPerson) {
                receiverPhone = removeForbiddenCharsFromPhoneNo(contactPerson.mobile);
            }

            if (receiverPhone === "") receiverPhone = customer.phone;
        }

        return modifyPhoneNoStart(receiverPhone, country).substring(0, 30);
    };

    const modifyPhoneNoStart = (phoneNo, country) => {
        phoneNo = removeForbiddenCharsFromPhoneNo(phoneNo);

        if (phoneNo !== "" && phoneNo.substring(0, 1) !== "+") {
            if (phoneNo.substring(0, 2) === "00") {
                phoneNo = `+${phoneNo.substring(2)}`;
            } else if (phoneNo.substring(0, 3) === "372" || phoneNo.substring(0, 3) === "371" || phoneNo.substring(0, 3) === "358") {
                phoneNo = `+${phoneNo}`;
            } else if (country === "EE" && phoneNo.substring(0, 4) !== "+372") {
                phoneNo = `+372${phoneNo}`;
            }
        }

        return phoneNo;
    };

    const getReceiver = (document) => {
        if (isInventoryTransfer) {
            if (document.warehouseToID == confParameters.wmsInTransitWarehouseID) {
                return getApiAttributeValue("destinationWarehouseID", document.attributes);
            }
            return document.warehouseToID
        } else if (confParameters.invoice_client_is_payer == 1) {
            return document.shipToID;
        } else {
            return document.clientID;
        }
    };

    const getDocument = () => {
        const params = {
            request: isInventoryTransfer ? "getInventoryTransfers" : "getSalesDocuments",
            [isInventoryTransfer ? "inventoryTransferID" : "id"]: createdDocumentId
        };

        const errorText = isInventoryTransfer ? t("getInventoryTransfersError") : t("getSalesDocumentsError");
        return dispatch(makeErplyRequest(params, errorText)).then((docs) => {
            return docs[0];
        });
    };

    const addDpdAttributes = (parcelNos, document) => {
        const params = isInventoryTransfer ?
            {
                request: "saveInventoryTransfer",
                inventoryTransferID: document.inventoryTransferID,
                attributeName1: "parcelNumbers",
                attributeValue1: parcelNos,
                attributeName2: inventoryTransferDeliveryTypeAttributeName,
                attributeValue2: inventoryTransferDeliveryTypeCode
            } :
            {
                request: "saveSalesDocument",
                id: document.id,
                longAttributeName0: "parcelNumbers",
                longAttributeValue0: parcelNos
            };

        params.attributeName0 = "num_of_parcel";
        params.attributeValue0 = noOfParcels;

        if (isPP && !isInventoryTransfer) {
            params.internalNotes = parcelNos.split("|").join(", ");
        }
        if (isOnOff(clientCode)) {
            params.attributeName1 = dpdUserWarehouseIdAttributeName;
            params.attributeValue1 = actualSourceWarehouseID;
        }

        const errorText = isInventoryTransfer ? t("saveInventoryTransfersError") : t("saveSalesDocumentError");
        dispatch(makeErplyRequest(params, errorText, null, null, null, false, false));
    };

    const downloadDpdLabels = (labels, createdDocument) => {
        const documentNumber = isInventoryTransfer ? createdDocument.inventoryTransferNo : createdDocument.number;

        const byteCharacters = atob(labels);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const file = new Blob([byteArray], { type: 'application/pdf;base64' });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `DPD_${t("parcelLabels")}_${documentNumber}`;
        link.dispatchEvent(new MouseEvent('click'));
    };

    const sendServiceEngineRequest = async (method, url, body, updateApiKey = false) => {
        dispatch(setIsLoading(true));
        const serviceEngineApiKey = await getServiceEngineApiKey(updateApiKey);
        simpleLog("serviceEngineApiKey: " + serviceEngineApiKey);

        return fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'API_KEY': serviceEngineApiKey
            },
            body: body
        }).then(response => {
            return response.json();
        }).then(async data => {
            simpleLog("serviceEngineResponse: " + JSON.stringify(data, Object.getOwnPropertyNames(data)));
            if (data.hasOwnProperty("error")) {
                if (data.error.startsWith("Session is expired or invalid") && serviceEngineErrorCounter.current < 1) {  // ApiKey has expired or is invalid
                    console.log("ApiKey has expired or is invalid. Creating a new one...");
                    serviceEngineErrorCounter.current ++;
                    return sendServiceEngineRequest(method, url, body, true);
                }

                dispatch(setIsLoading(false));
                dispatch(errorMessageSet(`${t("interactionWithDpdUnsuccessful")}. ${data.error}`));
                return false;
            } else if (data.status === "error") {
                dispatch(setIsLoading(false));
                dispatch(errorMessageSet(`${t("interactionWithDpdUnsuccessful")}. ${data.message}`));
                return false;
            }

            dispatch(setIsLoading(false));
            return data.message;
        }).catch(err => {
            simpleLog("serviceEngineResponseError: " + err);
            console.log(err);
            if (err.includes("Shipments not found")  && serviceEngineErrorCounter.current < 1) {     // Could be using some other account's dpdApiKey
                serviceEngineErrorCounter.current ++;
                return sendServiceEngineRequest(method, url, body, true);
            } else {
                dispatch(setIsLoading(false));
                dispatch(errorMessageSet(`${t("interactionWithDpdUnsuccessful")}. ${err}`));
                return false;
            }
        });
    };

    const getServiceEngineApiKey = (update = false) => {
        if (localStorage.getItem("dpdApiKey") !== null && !update) {
            return Promise.resolve(localStorage.getItem("dpdApiKey"));
        }

        const data = new URLSearchParams();
        data.set("jwt", confParameters.dpdServiceEngineApiKey);

        return fetch(getServiceEngineApiKeyUrl, {
            method: "post",
            body: data
        }).then(response => {
            return response.json();
        }).then(data => {
            return data.token;
        }).catch(err => {
            console.log(err)
        });
    };

    const getWeight = () => {
        let weight = 0;

        for (let i = 0, n = summedScannedProducts.length; i < n; i++) {
            const product = selectedDocumentsProducts.find(product => product.productID == summedScannedProducts[i].productID);
            weight += parseFloat(product.grossWeight) * summedScannedProducts[i].amount;
        }

        return weight;
    };

    const getCountries = () => {
        const params = {request: "getCountries"};
        return dispatch(makeErplyRequest(params, t("getCountriesError"), null, null, null, true));
    };

    const getDeliveryTypes = () => {
        if (deliveryTypes.length > 0) {
            return deliveryTypes;
        }
        const params = {request: "getDeliveryTypes"};
        return dispatch(makeErplyRequest(params, t("getDeliveryTypesError"), null, getDeliveryTypesSuccess, null, true));
    };

    const getContactPerson = (receiver, document) => {
        if (isInventoryTransfer) {
            return null;
        }

        const contactPersonID = confParameters.invoice_client_is_payer == 1 ? document.shipToContactID : document.contactID;

        if (contactPersonID != 0 && contactPersonID != null) {
            return receiver.contactPersons.find(contactPerson => contactPerson.contactPersonID == contactPersonID);
        } else {
            return null;
        }
    };

    const setPreScanComponent = () => {
        const preScanComponent = componentSequence[3];
        const newSequence = componentSequence.slice(0, 4);

        dispatch(componentSet(preScanComponent));
        dispatch(setSequence(newSequence));
    };

    const createDeliveryTypeDropdown = () => {
        if (isInventoryTransfer && deliveryTypes.length > 0) {
            return (
                <div className={"searchRow"}>
                    <Label className={"searchLabel searchLabelMedium"} size={"large"} pointing={"right"}>{t("deliveryType")}</Label>
                    <Dropdown
                        className={"searchInput searchInputMedium"}
                        selection
                        options={getDeliveryTypeOptions()}
                        onChange={handleDeliveryTypeChange}
                        fluid
                        value={inventoryTransferDeliveryTypeCode}
                    />
                </div>
            )
        }
    };

    const getDeliveryTypeOptions = () => {
        const options = deliveryTypes.reduce((options, deliveryType) => {
            if (deliveryType.name.startsWith("DPD - ") && deliveryType.name !== "DPD - Tavapakk, B2C, Pakipoodi saatmine") {
                options.push({key: deliveryType.code, text: deliveryType.name, value: deliveryType.code});
            }
            return options;
        }, []);
        const nullOption = {key: 0, text: "", value: 0};
        options.unshift(nullOption);

        return options;
    };

    const handleDeliveryTypeChange = (event, { value }) => {
        setInventoryTransferDeliveryTypeCode(value);
    };

    const createActualSourceWarehouseDropdown = () => {
        if (isOnOff(clientCode) && warehouses.length > 0) {
            return (
                <div className={"searchRow"}>
                    <Label className={"searchLabel searchLabelMedium"} size={"large"} pointing={"right"}>{t("actualSourceWarehouse")}</Label>
                    <Dropdown
                        className={"searchInput searchInputMedium"}
                        selection
                        options={getActualSourceWarehouseOptions()}
                        onChange={handleActualSourceWarehouseChange}
                        fluid
                        value={actualSourceWarehouseID}
                    />
                </div>
            )
        }
    };

    const getActualSourceWarehouseOptions = () => {
        return warehouses.reduce((options, warehouse) => {
            options.push({key: warehouse.warehouseID, text: warehouse.name, value: warehouse.warehouseID});
            return options;
        }, []);
    };

    const handleActualSourceWarehouseChange = (event, { value }) => {
        setActualSourceWarehouseID(value);
    };

    return (
        <div>
            <div className={"searchRow"}>
                <Label className={"searchLabel searchLabelMedium"} size={"large"} pointing={"right"}>{t("noOfParcels")}</Label>
                <Input className={"searchInput searchInputMedium"} type="number" onInput={e => setNoOfParcels(e.target.value)} value={noOfParcels}/>
            </div>
            {createDeliveryTypeDropdown()}
            {createActualSourceWarehouseDropdown()}
            <Button className={"menuBtn"} primary onClick={createDelivery} size='big'>{isOpenInAndroidApp ? t("createDpdDeliveryAndPrintLabels") : t("createDpdDeliveryAndDownloadLabels")}</Button>
            <Button className={"menuBtn marginTop"} onClick={setPreScanComponent} size='big'>{t("doNotWantDpdLabels")}</Button>
        </div>
    );
};

export default DpdParcelLabelsNew
