import React, {useState} from 'react';
import {Button, Icon, Transition} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {componentSet, previousComponentSet, setSequence} from "../actions/component";
import {useDispatch, useSelector} from "react-redux";
import {
    capitalise,
    filterOutDocumentsWithNoRows,
    formatDate,
    getApiAttributeValue,
    getDateOneMonthAgo
} from "../util/misc";
import {setDocumentType} from "../actions/createDocument";
import {isRehvidPluss} from "../util/isClient";
import SupplierSearchInput from "./SupplierSearchInput";
import {
    setSelectedDocuments,
    setScanBySupplier,
    setSupplierPurchaseInvoices,
    setProductsNotOnDocument, setIsInTransitTransfer, setSupplierBin
} from "../actions/scan";
import {getUserRights, makeErplyRequest, saveBin} from "../util/erplyRequests";
import {errorMessageSet} from "../actions/headerMessage";
import {
    setIsGetUndeliveredGoods,
    setSearchResultDocuments,
} from "../actions/searchDocuments";
import {getWarehousesSuccess} from "../actions/getWarehouses";

const ProductsIn = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const clientCode = useSelector(state => state.verifyUserReducer.clientCode);
    const suppliers = useSelector(state => state.getSuppliersReducer.suppliers);
    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);

    const [showSupplierInput, setShowSupplierInput] = useState(false);
    const [supplierID, setSupplierID] = useState("");

    const createShowUndeliveredGoodsButton = () => {
        if (isRehvidPluss(clientCode)) {
            return <Button key={"showUndeliveredGoodsButton"} className={"menuBtn"} onClick={handleShowUndeliveredGoodsButtonClick} size='big'>{t("showUndeliveredGoods")}</Button>;
        }
    };

    const handleShowUndeliveredGoodsButtonClick = () => {
        dispatch(setIsGetUndeliveredGoods(true));
        dispatch(componentSet("SearchOrders"));
    };

    const createScanBySupplierElements = () => {
        if (isRehvidPluss(clientCode)) {
            return ([
                createScanBySupplierButton(),
                createSupplierInput(),
                createEnterSupplierButton()
            ])
        }
    };

    const createScanBySupplierButton = () => {
        if (isRehvidPluss(clientCode)) {
            return <Button key={"scanBySupplierButton"} className={"menuBtn"} onClick={handleScanBySupplierButtonClick} size='big'>{t("scanBySupplier")}</Button>;
        }
    };

    const handleScanBySupplierButtonClick = async () => {
        if (!showSupplierInput) {
            const userRights = await getUserRights(dispatch, t);
            const hasSufficientRights = userRights.modules.purchases.add === 1 && userRights.modules.purchases.edit === 2;

            if (hasSufficientRights) {
                setShowSupplierInput(!showSupplierInput)
            } else {
                const errorMessage = `${t("userMustHaveRights")}: ${capitalise(t("purchaseInvoice"))} - ${t("add")}/${t("edit")}`;
                return dispatch(errorMessageSet(errorMessage));
            }
        } else {
            setShowSupplierInput(!showSupplierInput)
        }
    };

    const createSupplierInput = () => {
        return (
            <Transition key={"supplierSearchInput"} visible={showSupplierInput} animation='swing left' duration={300}>
                <div><SupplierSearchInput setSupplierID={setSupplierID}/></div>
            </Transition>
        );
    };

    const createEnterSupplierButton = () => {
        return (
            <Transition key={"enterSupplierButton"} visible={showSupplierInput && supplierID !== ""} animation='fly right' duration={300}>
                <div><Button className={"menuBtn"} color='blue' fluid size='large' onClick={getDataAndGoToScan}>{t("forward")}</Button></div>
            </Transition>
        );
    };

    const getDataAndGoToScan = async () => {
        const supplier = suppliers.find(supplier => supplier.id == supplierID);

        // Get all supplier's purchase invoices with stateID 3 or 5
        const getPurchaseInvoices = async () => {
            let params = {
                request: "getPurchaseDocuments",
                getRowsForAllInvoices: 1,
                supplierID: supplierID,
                type: "PRCINVOICEONLY",
                stateID: 3
            };

            const confirmedPurchaseInvoices = await dispatch(makeErplyRequest(params, t("getPurchaseDocumentsError"), null, null, null, true));

            params.stateID = 5;
            const partiallyReceivedPurchaseInvoices = await dispatch(makeErplyRequest(params, t("getPurchaseDocumentsError"), null, null, null, true));

            return confirmedPurchaseInvoices.concat(partiallyReceivedPurchaseInvoices);
        };

        // Get all products on supplier's purchase invoices
        const getProducts = (purchaseInvoices) => {
            let productIDs = [];
            purchaseInvoices.forEach(purchaseInvoice => {
                purchaseInvoice.rows.forEach(row => {
                    productIDs.push(row.productID);
                });
            });

            if (productIDs.length === 0) {
                return [];
            }

            const params = {
                request: "getProducts",
                productIDs: [...new Set(productIDs)].join(","),
                getPackageInfo: 1
            };
            return dispatch(makeErplyRequest(params, t("getProductsError"), null, null, null, true));
        };

        const getSupplierBin = () => {
            const binCode = `supplier${supplier.id}Products`;

            const params = {
                request: "getBins",
                code: binCode,
                warehouseID: selectedWarehouse.warehouseID
            };

            return dispatch(makeErplyRequest(params, t("getBinsError"))).then(async (bins) => {
                if (bins.length === 0) {
                    console.log(`Creating bin with code ${binCode}...`);
                    return dispatch(saveBin(t, false, binCode));
                } else {
                    return bins[0];
                }
            });
        };

        const purchaseInvoices = await getPurchaseInvoices();
        if (purchaseInvoices.length === 0) {
            return dispatch(errorMessageSet(t("criteriaDocsNotFound")));
        }

        dispatch(setSelectedDocuments([], t));
        dispatch(setScanBySupplier(supplier));
        dispatch(setSupplierPurchaseInvoices(purchaseInvoices));

        const [products, supplierBin] = await Promise.all([getProducts(purchaseInvoices), getSupplierBin()]);
        dispatch(setSupplierBin(supplierBin));
        dispatch(setProductsNotOnDocument(products));

        dispatch(previousComponentSet("ProductsIn"));
        dispatch(componentSet("Scan"));
    };

    const handlePurchaseDocumentOnClick = () => {
        dispatch(componentSet("PurchaseOrder"));
    };

    const handleReturnProductsOnClick = () => {
        dispatch(componentSet("ReturnProducts"));
    };

    const handleInventoryTransferOrderOnClick = () => {
        dispatch(setIsInTransitTransfer(false));
        dispatch(componentSet("InventoryTransfer"));
    };

    const handleCreatePurchaseInvoiceOnClick = () => {
        dispatch(setDocumentType("purchaseInvoice"));
        dispatch(componentSet("CreateDocument"));
    };

    const handleCreateReturnWaybillOnClick = () => {
        dispatch(setDocumentType("returnWaybill"));
        dispatch(componentSet("CreateDocument"));
    };

    const createCreateReturnWaybillButton = () => {
        if (confParameters.wmsEnableCreatingReturnWaybills == 1) {
            return <Button className={"menuBtn"} onClick={handleCreateReturnWaybillOnClick} size='big'>{t("createReturnWaybill")}</Button>;
        }
    };

    const createInTransitInventoryTransfersButton = () => {
        if (confParameters.wmsInTransitWarehouseID !== "") {
            return <Button className={"menuBtn"} onClick={handleInTransitInventoryTransferOnClick} size='big'>{t("inTransitInventoryTransfers")}</Button>;
        }
    };

    const handleInTransitInventoryTransferOnClick = async () => {
        const getTransfersRequest = dispatch(makeErplyRequest({
            request: "getInventoryTransfers",
            type: "TRANSFER",
            warehouseToID: confParameters.wmsInTransitWarehouseID,
            confirmed: 1,
            searchAttributeName: "destinationWarehouseID",
            searchAttributeValue: selectedWarehouse.warehouseID,
            dateFrom: formatDate(getDateOneMonthAgo())
        }, t("getInventoryTransfersError"), null, null, null, true));

        const getWarehousesRequest = dispatch(makeErplyRequest({
            request: "getWarehouses"
        }, t("getWarehousesError"), null, getWarehousesSuccess, null, false, false));

        const [transfers, warehouses] = await Promise.all([getTransfersRequest, getWarehousesRequest]);
        let filteredTransfers = filterOutDocumentsWithNoRows(transfers);
        filteredTransfers = filteredTransfers.filter(transfer => {
            const followUpTransferID = getApiAttributeValue("followUpTransferID", transfer.attributes);
            return followUpTransferID === false;
        });

        if (filteredTransfers.length === 0) {
            dispatch(errorMessageSet(t("criteriaDocsNotFound")));
        } else {
            dispatch(setSequence(["ChooseWarehouse", "MainMenu", "ProductsIn", "InventoryTransfer"]));
            dispatch(setIsInTransitTransfer(true));
            dispatch(setSearchResultDocuments(filteredTransfers));
            dispatch(componentSet("InventoryTransfersListConfirmation"));
        }
    };

    const handleGoBackOnClick = () => {
        dispatch(componentSet("MainMenu"));
    };

    return (
        <div>
            {createShowUndeliveredGoodsButton()}
            {createScanBySupplierElements()}
            <Button className={"menuBtn"} onClick={handlePurchaseDocumentOnClick} size='big'>{t("PurchaseOrder")}</Button>
            {createCreateReturnWaybillButton()}
            {confParameters.wmsEnableProductsReturn == 1 ? <Button className={"menuBtn"} onClick={handleReturnProductsOnClick} size='big'>{t("returnProducts")}</Button> : ""}
            <Button className={"menuBtn"} onClick={handleInventoryTransferOrderOnClick} size='big'>{capitalise(t("inventoryTransferOrder"))}</Button>
            {confParameters.wmsEnableCreatingPurchaseInvoices == 1 ? <Button className={"menuBtn"} onClick={handleCreatePurchaseInvoiceOnClick} size='big'>{t("createPurchaseInvoice")}</Button> : ""}
            {createInTransitInventoryTransfersButton()}
            <Button className={"goBackBtn menuBtn"} onClick={handleGoBackOnClick} size='big'>
                <Icon name={"chevron circle left"}/>
                {t("goBack")}
            </Button>
        </div>
    );
};

export default ProductsIn
