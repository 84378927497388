import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Icon, Input, Table} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {componentSet} from "../actions/component";
import {errorMessageSet, successMessageSet} from "../actions/headerMessage";
import {setModal} from "../actions/modal";
import {deepCopy, translateAccordingToCountry} from "../util/misc";
import {setScannedProducts} from "../actions/placeProducts";

const CheckGoodsInBinChangeScannedProducts = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const scannedProducts = useSelector(state => state.placeProductsReducer.scannedProducts);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const sourceBinQuantities = useSelector(state => state.placeProductsReducer.sourceBinQuantities);

    const [amounts, setAmounts] = useState(scannedProducts.map(product => product.amount));

    const setAmount = (value, index, onBlur = false) => {
        const amountsCopy = amounts.slice();
        amountsCopy[index] = onBlur && value === "" ? 0 : value;
        setAmounts(amountsCopy);
    };

    const createButtons = (isBottom = false) => {
        if (!(isBottom && scannedProducts.length < 3)) {
            return (
                <div className={"settingsBtns flexCenter"}>
                    <Button className={"menuBtnHalfWidth"} size={"large"} onClick={handleGoBackOnClick}>
                        <Icon name={"chevron circle left"}/>
                        {t("goBack")}
                    </Button>
                    <Button className={"menuBtnHalfWidth"} size={"large"} color={"green"} onClick={handleSaveOnClick}>
                        {t("save")}
                    </Button>
                </div>
            )
        }
    };

    const handleGoBackOnClick = () => {
        dispatch(componentSet("CheckGoodsInBinScanFinish"));
    };

    const handleSaveOnClick = async () => {
        for (let i = 0, n = amounts.length; i < n; i++) {
            if (Number(amounts[i]) < 0) return dispatch(errorMessageSet(t("amountMustBeNonNegative")));
        }

        let amountsChanged = false;

        for (let i = 0, n = scannedProducts.length; i < n; i++) {
            if (Number(scannedProducts[i].amount) !== Number(amounts[i])) {
                amountsChanged = true;
                break;
            }
        }

        if (!amountsChanged) return dispatch(errorMessageSet(t("noChanges")));

        dispatch(setModal(t("confirmation"), t("confirmChange?"), changeScannedAmounts));
    };

    const changeScannedAmounts = () => {
        let newScannedProducts = deepCopy(scannedProducts);
        newScannedProducts.forEach((product, index) => product.amount = Number(amounts[index]));
        newScannedProducts = newScannedProducts.filter(product => product.amount > 0);
        dispatch(setScannedProducts(newScannedProducts));
        dispatch(successMessageSet(t("scannedAmountsChanged")));
        dispatch(componentSet("CheckGoodsInBinScanFinish"));
    };

    const createTables = () => {
        return scannedProducts.map((product, index) => createProductTable(product, index));
    };

    const createProductTable = (product, index) => {
        const sourceBinQuantity = sourceBinQuantities.find(quantity => quantity.productID == product.productID);
        const amountInBin = sourceBinQuantity ? sourceBinQuantity.amount : 0;

        return (
            <Table key={index} celled structured unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign='center' colSpan={"2"}>{index + 1}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    <Table.Row>
                        <Table.Cell className={"tableHeading"} width={1}>{t("product")}</Table.Cell>
                        <Table.Cell>{product.name}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className={"tableHeading"} width={1}>{t("code")}</Table.Cell>
                        <Table.Cell>{product.code}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className={"tableHeading"} width={1}>{translateAccordingToCountry(t("EAN"), confParameters)}</Table.Cell>
                        <Table.Cell>{product.code2}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className={"tableHeading"} width={1}>{t("binAmount")}</Table.Cell>
                        <Table.Cell>{amountInBin}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className={"tableHeading"} width={1}>{t("scannedProducts")}</Table.Cell>
                        <Table.Cell>
                            <Input type="number" className={"fullWidth"} onChange={(e, {value}) => setAmount(value, index)} value={amounts[index]}
                                   onBlur={(e) => setAmount(e.target.value, index, true)}/>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        )
    };

    return (
        <div>
            {createButtons()}
            {createTables()}
            {createButtons(true)}
        </div>
    )
};

export default CheckGoodsInBinChangeScannedProducts
