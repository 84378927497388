import React, { useState, useEffect } from 'react';
import {Button, Dropdown, Grid, Header, Segment} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {
    getConfParameters,
    makeCafaApiRequest,
    makeErplyBulkRequest,
    makeErplyRequest
} from "../util/erplyRequests";
import {getWarehousesSuccess, setWarehouse} from "../actions/getWarehouses";
import {useDispatch, useSelector} from "react-redux";
import {errorMessageSet} from "../actions/headerMessage";
import erplyLogo2 from "../images/erplyLogo2.png";
import {componentSet, setSequence} from "../actions/component";
import {setConfParameters, setConfParametersInitialised} from "../actions/getConfParameters";
import {logout} from "../actions/verifyUser";
import {languageSet} from "../actions/language";
import {faDoorOpen} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {setCafaConfParameters} from "../actions/newErplyApis";
import {checkSessionRefreshTimestamp, getCafaConfParameterValue, isInfralinkSelfService} from "../util/misc";
import {isTAF, isUuemViis, isTesterClientCode, isArvutitark} from "../util/isClient";
import {setDocumentType} from "../actions/createDocument";

const ChooseWarehouse = () => {
    const [selectedWarehouse, setSelectedWarehouse] = useState(null);

    const warehouses = useSelector(state => state.getWarehousesReducer.warehouses);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const language = useSelector(state => state.languageReducer.language);
    const user = useSelector(state => state.verifyUserReducer.user);
    const clientCode = useSelector(state => state.verifyUserReducer.clientCode);

    const confParamSettings = [
        {confParameter: "wmsAdminUserGroup", defaultValue: "1"},
        {confParameter: "wmsEnabledModules", defaultValue: "ProductsIn,ProductsOut,PlaceProducts,Replenishment,AmountsInBins"},
        {confParameter: "wmsRapidInScan", defaultValue: 0, isCafaParameter: !isTAF(clientCode)},
        {confParameter: "wmsRapidOutScan", defaultValue: 0, isCafaParameter: !isTAF(clientCode)},
        {confParameter: "wmsUseProductLocationsInWarehouse", defaultValue: 0},
        {confParameter: "wmsSelectSuggestedBin", defaultValue: 0},
        {confParameter: "wmsExcludedEans", defaultValue: ""},
        {confParameter: "wmsCreatedPurchaseDocument", defaultValue: "PRCWAYBILL"},
        {confParameter: "wmsConfirmPurchaseDocuments", defaultValue: 0},
        {confParameter: "wmsTotalPurchaseAmountAsScanAmount", defaultValue: 0},
        {confParameter: "wmsTotalPurchaseAmountAsScanAmountListClickOnly", defaultValue: 0},
        {confParameter: "wmsTotalSalesAmountAsScanAmount", defaultValue: 0},
        {confParameter: "wmsTotalSalesAmountAsScanAmountListClickOnly", defaultValue: 0},
        {confParameter: "wmsTotalInventoryTransferAmountAsScanAmount", defaultValue: 0},
        {confParameter: "wmsTotalInventoryTransferAmountAsScanAmountListClickOnly", defaultValue: 0},
        {confParameter: "wmsCreateSalesDocumentAfterScan", defaultValue: 1},
        {confParameter: "wmsCreatedSalesDocument", defaultValue: "WAYBILL"},
        {confParameter: "wmsConfirmSalesDocuments", defaultValue: 0},
        {confParameter: "wmsAllowOutScanAmountExceeding", defaultValue: 0},
        {confParameter: "wmsAllowInScanAmountExceeding", defaultValue: 0},
        {confParameter: "wmsConfirmInventoryTransfers", defaultValue: 0},
        {confParameter: "wmsInTransitWarehouseID", defaultValue: ""},
        {confParameter: "wmsConfirmWriteOffs", defaultValue: 0},
        {confParameter: "wmsSendComparisonEmail", defaultValue: 0},
        {confParameter: "wmsEmailReceiver", defaultValue: "creator"},
        {confParameter: "wmsEmailReceiverCustomEmail", defaultValue: ""},
        {confParameter: "wmsShowPackageCalculations", defaultValue: 0},
        {confParameter: "wmsCreateInvRegistrationOnBinAmountChange", defaultValue: 0},
        {confParameter: "wmsEnableCreatingWaybills", defaultValue: 0},
        {confParameter: "wmsEnableCreatingInvWriteOffs", defaultValue: 0},
        {confParameter: "wmsEnableCreatingInvTransferOrders", defaultValue: 0},
        {confParameter: "wmsEnableCreatingPurchaseInvoices", defaultValue: 0},
        {confParameter: "wmsEnableConfirmingDirectlyCreatedPurchaseInvoices", defaultValue: 0},
        {confParameter: "wmsEnableProductsReturn", defaultValue: 0},
        {confParameter: "wmsEnableCode2Change", defaultValue: 0},
        {confParameter: "wmsEnableRepeatedTransferOrderScan", defaultValue: 0},
        {confParameter: "wmsMaxAmountAllowedToScan", defaultValue: ""},
        {confParameter: "wmsEnableSettingPurchaseDocNoAfterScan", defaultValue: 0},
        {confParameter: "wmsFirstCheckPackageCodes", defaultValue: 0},
        {confParameter: "wmsEnableNegativeScanProductsOut", defaultValue: 0},
        {confParameter: "wmsSetPurchaseOrderAlwaysFulfilled", defaultValue: 0},
        {confParameter: "wmsEnableScanningBatches", defaultValue: 0},
        {confParameter: "wmsEnableCreatingProducts", defaultValue: 0},
        {confParameter: "wmsExcludedPackageTypesFromPackageCalculator", defaultValue: ""},
        {confParameter: "wmsEnableScanningProductsNotOnOutDocument", defaultValue: 0},
        {confParameter: "wmsEnableScanningProductsNotOnInDocument", defaultValue: 1},
        {confParameter: "wmsScanAsBatch", defaultValue: 0},
        {confParameter: "wmsEnableAssembly", defaultValue: 0},
        {confParameter: "wmsEnableCreatingReturnWaybills", defaultValue: 1},
        {confParameter: "wmsInvTransferScanReorderMultiple", defaultValue: 0},
        {confParameter: "wmsCreatePurchaseInvoiceRowPrice", defaultValue: "purchasePrice"},
        {confParameter: "wmsEnableSettingCreatePurchaseInvoiceRowPrice", defaultValue: 0},
        {confParameter: "wmsEnableAddingCode2", defaultValue: 1},
        {confParameter: "wmsEnableMultiplePurchaseOrderScan", defaultValue: 0},
        {confParameter: "wmsEnableCreatingPurchaseReturns", defaultValue: 0},
        {confParameter: "wmsEnablePurchaseReturn", defaultValue: 0},
        {confParameter: "api_get_product_use_new_fulltext_index", defaultValue: 0},
        {confParameter: "wmsEnableCreatingInvTransfers", defaultValue: 0},
        {confParameter: "wmsSortProductsOnCreatedDocumentBy", defaultValue: "scanOrder"},
        {confParameter: "wmsPutProductsScannedAsPackagesOnCreatedDocumentAsPackages", defaultValue: 0},
        {confParameter: "wmsPrioritiseCode1", defaultValue: 0},
        {confParameter: "wmsConfirmBinAmountWithUserIfLessThan", defaultValue: 1},
        {confParameter: "wmsCompulsoryScanUserGroups", defaultValue: ""},
        {confParameter: "wmsSetDefaultAmountAfterScan", defaultValue: 1},
        {confParameter: "wmsInventoryRegistrationRowPrice", defaultValue: "cost"},
        {confParameter: "wmsEnableSettingInScanBin", defaultValue: 1},
        {confParameter: "wmsSearchSalesOrderByNumberPaymentWarning", defaultValue: "noWarningIfNoPayments"},
        {confParameter: "wmsCreatedPurchaseDocumentNoFieldValue", defaultValue: "regnumber"},

        {confParameter: "wmsPrinterServiceAddress", defaultValue: "", isCafaParameter: isArvutitark(clientCode)},
        {confParameter: "wmsLabelPrinterName", defaultValue: "", isCafaParameter: isArvutitark(clientCode)},
        {confParameter: "wmsLabelTemplateID", defaultValue: ""},
        {confParameter: "wmsDpdPrinterName", defaultValue: "", isCafaParameter: isArvutitark(clientCode)},
        {confParameter: "wmsPrintPackingSlip", defaultValue: 0},
        {confParameter: "wmsPackingSlipPrinterName", defaultValue: "", isCafaParameter: isArvutitark(clientCode)},
        {confParameter: "wmsPackingSlipTemplateID", defaultValue: ""},
        {confParameter: "wmsPrintInventoryTransferReport", defaultValue: 0},
        {confParameter: "wmsInventoryTransferReportPrinterName", defaultValue: "", isCafaParameter: isArvutitark(clientCode)},
        {confParameter: "wmsInventoryTransferReportTemplateID", defaultValue: ""},
        {confParameter: "wmsPrintSalesDocument", defaultValue: 0},
        {confParameter: "wmsSalesDocumentPrinterName", defaultValue: "", isCafaParameter: isArvutitark(clientCode)},
        {confParameter: "wmsSalesDocumentTemplateID", defaultValue: ""},
        {confParameter: "wmsPrintSsccLabel", defaultValue: 0},
        {confParameter: "wmsSsccLabelPrinterName", defaultValue: "", isCafaParameter: isArvutitark(clientCode)},
        {confParameter: "wmsSsccLabelTemplateID", defaultValue: ""},

        {confParameter: "wmsIsUsingTelema", defaultValue: 0},
        {confParameter: "wmsIsUsingDpd", defaultValue: 0},
        {confParameter: "wmsIsUsingVenipak", defaultValue: 0},
        {confParameter: "wmsIsUsingSmartpost", defaultValue: 0},
        {confParameter: "wmsIsUsingOmniva", defaultValue: 0},
    ];

    useEffect(() => {
        if (isUuemViis(clientCode) || isTesterClientCode(clientCode)) {
            window.setRequestTimeout = true;
        }

        disableRefresh();
        getData();
    }, []);

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    // Ensure no accidental refresh
    const disableRefresh = () => {
        window.onbeforeunload = function () {return false;}
    };

    const setDefaultLanguage = (confParameters) => {
        let language = confParameters.default_language;
        if (language === "lat" && confParameters.country !== "LV") language = "fra";
        dispatch(languageSet(language));
        i18n.changeLanguage(language);
    };

    const getData = async () => {
        if (dispatch(isInfralinkSelfService())) await dispatch(checkSessionRefreshTimestamp(t));
        const cookie = localStorage.getItem("asd")
        if (cookie) alert("localStorage: " + cookie)
        else localStorage.setItem("asd", "123")

        getAllowedWarehouses();

        if (confParameters === null) {
            dispatch(getConfParameters(t)).then((confParameters) => {
                if (language === "eng") {   // User has not changed language in the login view
                    setDefaultLanguage(confParameters);
                }
                initialiseMissingWmsConfParameters(confParameters);
                getCafaConfParameters(confParameters);
            });
        }
    };

    const getAllowedWarehouses = async () => {
        const params = {
            request: "getAllowedWarehouses",
            userID: user.userID
        };
        dispatch(makeErplyRequest(params, t("getWarehousesError"), null, getWarehousesSuccess, null)).then(warehouses => {
            // Select first warehouse
            if (warehouses.length > 0) {
                setSelectedWarehouse(warehouses[0]);
            }
        });
    };

    const getCafaConfParameters = async (confParameters) => {
        const cafaConfParameters = await dispatch(makeCafaApiRequest(t, "configuration/WMS", setCafaConfParameters, "GET"));
        initialiseMissingCafaConfParameters(cafaConfParameters, confParameters);
    };

    const initialiseMissingCafaConfParameters = async (cafaConfParameters, confParameters) => {
        let saveParameterRequests = [];
        for (let i = 0, n = confParamSettings.length; i < n; i++) {
            if (confParamSettings[i].isCafaParameter) {
                const existingCafaParameter = getCafaConfParameterValue(cafaConfParameters, confParamSettings[i].confParameter, user);

                if (existingCafaParameter === undefined) {
                    // If the account has a previously configured regular confParameter, set this as an initial value, otherwise set the default value
                    const value = confParameters.hasOwnProperty(confParamSettings[i].confParameter) ? confParameters[confParamSettings[i].confParameter] : String(confParamSettings[i].defaultValue);
                    const params = JSON.stringify({
                        application: "WMS",
                        level: "User",
                        level_id: user.userID,
                        name: confParamSettings[i].confParameter,
                        value: value
                    });
                    saveParameterRequests.push(dispatch(makeCafaApiRequest(t, "v3/configuration", null, "PUT", params)));
                }
            }
        }

        if (saveParameterRequests.length > 0) {
            await Promise.all(saveParameterRequests);
            dispatch(makeCafaApiRequest(t, "configuration/WMS", setCafaConfParameters, "GET"));
        }
    };

    const initialiseMissingWmsConfParameters = (confParameters) => {
        let requests = [];

        for (let i = 0, n = confParamSettings.length; i < n; i++) {
            if (!confParamSettings[i].isCafaParameter && !confParameters.hasOwnProperty(confParamSettings[i].confParameter)) {
                const confParameter = confParamSettings[i].confParameter;
                const defaultValue = confParamSettings[i].defaultValue;

                const request = {
                    requestName: "saveConfParameter",
                    parameterName: confParameter,
                    parameterValue: defaultValue,
                    requestID: i
                };

                requests.push(request)
            }
        }

        // Needed to be able to search for sales orders by their custom no (eg 100101-2)
        if (!confParameters.hasOwnProperty("search_invoice_by_regular_and_custom_number") || confParameters.search_invoice_by_regular_and_custom_number == 0) {
            const request = {
                requestName: "saveConfParameter",
                parameterName: "search_invoice_by_regular_and_custom_number",
                parameterValue: 1,
                requestID: 999
            };

            requests.push(request)
        }

        // Quantities in bins module needs to be enabled
        const quantitiesInBinsModuleEnabled = confParameters.additionalModules.find(module => module.name === "quantities_in_bins").enabled == 1;
        if (!quantitiesInBinsModuleEnabled) {
            dispatch(errorMessageSet(t("quantitiesInBinsModuleMustBeEnabled")));
        }

        if (requests.length > 0) {
            console.log("Initialising WMS config parameters...");
            console.log("saveConfParameters requests", requests);

            dispatch(makeErplyBulkRequest(requests, t("saveConfParameterError"), null, null, null, false)).then(() => {
                dispatch(setConfParameters(requests));
                dispatch(setConfParametersInitialised(true));
            });
        } else {
            dispatch(setConfParametersInitialised(true));
        }
    };

    const getOptions = () => {
        let options = [];
        for (let i = 0, n = warehouses.length; i < n; i++) {
            options.push({key: warehouses[i].warehouseID, text: warehouses[i].name, value: warehouses[i].warehouseID})
        }

        return options;
    };

    const handleChange = (event, { value }) => {
        const warehouse = warehouses.find(warehouse => warehouse.warehouseID === value);
        setSelectedWarehouse(warehouse);
    };

    const handleOnClick = () => {
        if (selectedWarehouse === null) {
            dispatch(errorMessageSet(t("selectWarehouse")));
        } else {
            dispatch(setWarehouse(selectedWarehouse));

            if (dispatch(isInfralinkSelfService())) {
                dispatch(setDocumentType("waybill"));
                dispatch(setSequence(["ChooseWarehouse", "MainMenu", "ProductsOut"]));
                dispatch(componentSet("CreateDocument"));
            } else {
                dispatch(componentSet("MainMenu"));
            }
        }
    };

    const handleLogOutOnClick = () => {
        dispatch(logout());
    };

    return (
        <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle' id={"chooseWarehouses"}>
            <Grid.Column>
                <Header as='h2' color='blue'>
                    <div>
                        <img className={"logo"} src={erplyLogo2} alt="logo" />
                    </div>
                </Header>
                <Segment stacked>
                    <Dropdown
                        id='warehousesSelect'
                        placeholder={t("selectWarehouse")}
                        fluid
                        selection
                        options={getOptions()}
                        onChange={handleChange}
                        search
                        value={selectedWarehouse ? selectedWarehouse.warehouseID : null}
                        noResultsMessage={t("noResultsFound")}
                    />
                    <Button color='blue' fluid size='large' onClick={() => handleOnClick()}>
                        {t("forward")}
                    </Button>
                    <Button className={"marginTopSmall"} fluid size='large' onClick={handleLogOutOnClick}>
                        <FontAwesomeIcon className={"marginRightSmall"} icon={faDoorOpen}/>
                        {t("logOut")}
                    </Button>
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

export default ChooseWarehouse
