const initialState = {
    binsAvailableForReplenishment: [],
    selectedBin: null,
    addedAmounts: []
};

export const replenishmentReducer = (state = initialState, action) => {
    switch(action.type) {
        case "SET_BINS_AVAILABLE_FOR_REPLENISHMENT":
            return {
                ...state,
                binsAvailableForReplenishment: action.payload
            };
        case "SET_SELECTED_BIN":
            return {
                ...state,
                selectedBin: action.payload
            };
        case "SET_ADDED_AMOUNTS":
            return {
                ...state,
                addedAmounts: action.payload
            };
        default:
            return state;
    }
};
