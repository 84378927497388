import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {componentSet, setIsLoading, setSequence} from "../actions/component";
import {Button, Table} from "semantic-ui-react";
import GoBackToStartBtn from "./GoBackToStartBtn";
import React from "react";
import {setProductsDisplayed, setSourceBinQuantities} from "../actions/placeProducts";
import {errorMessageSet} from "../actions/headerMessage";
import {getWmsApiBinQuantities} from "../util/erplyRequests";

const RegisterToAddressResult = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const scannedProduct = useSelector(state => state.placeProductsReducer.scannedProduct);
    const sourceBin = useSelector(state => state.placeProductsReducer.sourceBin);
    const destinationBin = useSelector(state => state.placeProductsReducer.destinationBin);
    const placedAmount = useSelector(state => state.placeProductsReducer.placedAmount);
    const destinationBinAmountBefore = useSelector(state => state.placeProductsReducer.destinationBinAmountBefore);
    const isCheckGoodsInReceivingArea = useSelector(state => state.placeProductsReducer.isCheckGoodsInReceivingArea);
    const isCheckGoodsInBin = useSelector(state => state.placeProductsReducer.isCheckGoodsInBin);

    const isRegisterProductsToAddress = !scannedProduct.hasOwnProperty("amount");   // From "Place products" -> "Register products to address" or "Stocktakings", no source bin

    const handleGoBackButtonClick = () => {
        dispatch(componentSet("PlaceProducts"));
        dispatch(setSequence(["ChooseWarehouse", "MainMenu", "PlaceProducts"]));
    };

    const handleGoBackToScan = () => {
        if (isRegisterProductsToAddress) {   // From "Place products" -> "Register products to address"
            dispatch(componentSet("RegisterToAddressScanProduct"));
            dispatch(setSequence(["ChooseWarehouse", "MainMenu", "PlaceProducts", "RegisterToAddressScanProduct"]));
        } else {
            dispatch(setIsLoading(true));

            getBinQuantities().then((binQuantities) => {
                if (binQuantities.length > 0) {
                    dispatch(setSourceBinQuantities(binQuantities));
                    dispatch(componentSet("RegisterToAddressScanProduct"));
                    dispatch(setSequence(["ChooseWarehouse", "MainMenu", "PlaceProducts", "RegisterToAddressScanProduct"]));
                } else {
                    sourceBin.code === "receiving_area" ? dispatch(errorMessageSet(t("noProductsInReceivingArea"))) : dispatch(errorMessageSet(t("noProductsInBin")));
                }
            });

            dispatch(setIsLoading(false));
        }
    };

    const getBinQuantities = () => {
        const pageSize = isCheckGoodsInBin ? 999999 : 100;
        return dispatch(getWmsApiBinQuantities(t, true, null, sourceBin.binID, null, 0.000001, pageSize));
    };

    const getAddressFromRows = () => {
        if (!isRegisterProductsToAddress) {
            return [
                <Table.Row>
                    <Table.Cell>{t("addressFrom")}</Table.Cell>
                    <Table.Cell>{sourceBin.code}</Table.Cell>
                </Table.Row>,
                <Table.Row>
                    <Table.Cell>{t("amountBefore")}</Table.Cell>
                    <Table.Cell>{scannedProduct.amount}</Table.Cell>
                </Table.Row>,
                <Table.Row>
                    <Table.Cell>{t("amountNow")}</Table.Cell>
                    <Table.Cell>{scannedProduct.amount - placedAmount}</Table.Cell>
                </Table.Row>
            ];
        }
    };

    const createBackToScanButton = () => {
        if (!isCheckGoodsInReceivingArea) {
            return <Button className={"menuBtn"} primary size={"big"} onClick={handleGoBackToScan}>{t("goBackToScan")}</Button>;
        }
    };

    return (
        <div>
            <Table celled inverted unstackable>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>{t("product")}</Table.Cell>
                        <Table.Cell>{scannedProduct.name}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>{t("amount")}</Table.Cell>
                        <Table.Cell>{placedAmount}</Table.Cell>
                    </Table.Row>
                    {getAddressFromRows()}
                    <Table.Row>
                        <Table.Cell>{t("addressTo")}</Table.Cell>
                        <Table.Cell>{destinationBin.code}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>{t("amountBefore")}</Table.Cell>
                        <Table.Cell>{destinationBinAmountBefore}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>{t("amountNow")}</Table.Cell>
                        <Table.Cell>{destinationBinAmountBefore + placedAmount}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <div className={"btnsGroup"}>
                {createBackToScanButton()}
                <Button className={"menuBtn"} onClick={handleGoBackButtonClick} size='big'>{t("goBackToPlaceProducts")}</Button>
                <GoBackToStartBtn addClassNames={["menuBtn"]} removeClassNames={["menuBtnHalfWidth"]} size={"big"}/>
            </div>
        </div>
    );
};

export default RegisterToAddressResult
