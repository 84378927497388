const initialState = {
    customers: [],
    receivers: []
};

export const getCustomersReducer = (state = initialState, action) => {
    switch(action.type) {
        case "GET_CUSTOMERS_SUCCESS":
            return {
                ...state,
                customers: action.payload,
            };
        case "SET_RECEIVERS":
            return {
                ...state,
                receivers: action.payload
            };
        default:
            return state;
    }
};
