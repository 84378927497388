const initialState = {
    fulfillableOrdersFilters: null,
};

export const filtersReducer = (state = initialState, action) => {
    switch(action.type) {
        case "SET_FULFILLABLE_ORDERS_FILTERS":
            return {
                ...state,
                fulfillableOrdersFilters: action.payload
            };
        default:
            return state;
    }
};
