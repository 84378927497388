import React, {useState, useRef} from 'react';
import {Button, Dropdown, Input, Label} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {componentSet} from "../actions/component";
import { makeErplyBulkRequest, makeErplyRequest} from "../util/erplyRequests";
import {getCustomersSuccess} from "../actions/getCustomers";
import {errorMessageSet} from "../actions/headerMessage";
import GoBackToStartBtn from "./GoBackToStartBtn";
import {setAssignments} from "../actions/getAssignments";
import GoBackBtn from "./GoBackBtn";

const Assignment = () => {
    const customerDropdown = useRef(null);

    const [customerDropdownOpen, setCustomerDropdownOpen] = useState(false);
    const [selectedCustomerID, setSelectedCustomerID] = useState(null);
    const [assignmentNo, setAssignmentNo] = useState("");

    const customers = useSelector(state => state.getCustomersReducer.customers);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleGoBackOnClick = () => {
        dispatch(componentSet("ProductsOut"));
    };

    const getCustomers = (searchString) => {
        if (searchString !== "") {
            customerDropdown.current.state.value = "";

            const params = {
                request: "getCustomers",
                searchName: searchString,
                searchFromMiddle: 1
            };

            dispatch(makeErplyRequest(params, t("getCustomersError"), null, getCustomersSuccess, null)).then((customers) => {
                if (customers.length > 0 && customerDropdown.current.state.value === "") {
                    setCustomerDropdownOpen(true);
                } else {
                    setCustomerDropdownOpen(false);
                }
            });
        }
    };

    const handleOnCustomerChange = (event, { value }) => {
        setSelectedCustomerID(value);
        setCustomerDropdownOpen(false);
    };

    const getCustomerOptions = () => {
        return customers.map(customer => getCustomerOption(customer));
    };

    const getCustomerOption = (customer) => {
        return {key: customer.customerID, text: customer.fullName, value: customer.customerID};
    };

    const searchAssignments = () => {
        if (selectedCustomerID === null && assignmentNo === "") {
            return dispatch(errorMessageSet(t("oneFilterMustBeSet")));
        }

        let requests = [];

        if (selectedCustomerID !== null) {
            requests.push(getRequest("customerID", selectedCustomerID, 0));
        }

        if (assignmentNo !== "") {
            requests.push(getRequest("assignmentNo", assignmentNo, 1));
        }

        dispatch(makeErplyBulkRequest(requests, t("getAssignmentsError"))).then((requests) => {
            const assignments = [].concat(...requests.map(request => request.records));

            if (assignments.length === 0) {
                dispatch(errorMessageSet(t("criteriaDocsNotFound")));
            } else {
                dispatch(setAssignments(assignments));
                dispatch(componentSet("AssignmentList"));
            }
        });
    };

    const getRequest = (attributeName, attributeValue, requestID) => {
        return {
            requestName: "getAssignments",
            searchAttributeName: attributeName,
            searchAttributeValue: attributeValue,
            requestID: requestID
        };
    };

    const handleCustomerOnBlur = () => {
        setCustomerDropdownOpen(false);
    };

    return (
        <div>
            <div className={"searchRow"}>
                <Label className={"searchLabel"} size={"large"} pointing={"right"}>{t("assignmentNo")}</Label>
                <Input className={"searchInput"} onInput={e => setAssignmentNo(e.target.value)}/>
            </div>
            <div className={"searchRow"}>
                <Label className={"searchLabel searchLabelShort"} size={"large"} pointing={"right"}>{t("customer")}</Label>
                <Dropdown
                    className={"searchInput searchInputLong"}
                    placeholder={t("enterName")}
                    selection
                    open={customerDropdownOpen}
                    onInput={e => getCustomers(e.target.value)}
                    options={getCustomerOptions()}
                    search
                    onChange={handleOnCustomerChange}
                    noResultsMessage={t("noResultsFound")}
                    onBlur={handleCustomerOnBlur}
                    ref={customerDropdown}
                />
            </div>
            <div className={"btnsGroup"}>
                <Button className={"menuBtn"} color={"blue"} size={"big"} onClick={searchAssignments}>{t("search")}</Button>
                <div className={"flex flexCenter"}>
                    <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                    <GoBackToStartBtn/>
                </div>
            </div>
        </div>
    );
};

export default Assignment
