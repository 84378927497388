import successSound from "../sounds/success.mp3";
import failSound from "../sounds/fail.mp3";
import {errorMessageSet} from "../actions/headerMessage";
import {componentSet, previousComponentSet, setIsLoading, setSettingsBtnHasBeenClicked} from "../actions/component";
import {akTc2000LocationInWarehouseId} from "./constants";
import {getBinRecords, getConfParameters, makeErplyRequest, responseHasErrors, verifyUser} from "./erplyRequests";
import {setModal} from "../actions/modal";
import React from "react";
import {
    setCurrentSessionScannedProducts,
    setScannedProducts,
    setScannedProductsByDocument, setSummedScannedProducts
} from "../actions/scan";
import {logout, setLogOutWarningShown} from "../actions/verifyUser";
import {isInfralink, isMarikaClientCode, isTesterClientCode} from "./isClient";
import {setInformativeModal} from "../actions/informativeModal";

// Non-Erply request
export const makeRequest = async (url, method, body, errorMessage, dispatch, returnJson = false, setLoader = true) => {
    const maxNoOfReRequests = 3;
    let noOfReRequests = 0;
    if (setLoader) {
        dispatch(setIsLoading(true));
    }

    const sendRequest = () => {
        return fetch(url, {
            method: method,
            body: body,
        }).then(response => {
            if (returnJson) {
                return response.json();
            } else {
                return response.text();
            }
        }).catch(async error => {
            if (noOfReRequests < maxNoOfReRequests && (error.name === "AbortError" || (error.name === "TypeError" && error.message === "Network request failed"))) {  // Unexplainable error occurring in React Native
                noOfReRequests ++;
                return await sendRequest();
            } else {
                if (errorMessage) {
                    dispatch(errorMessageSet(errorMessage + ": " + error.toString()));
                }

                return {error: error.toString()};
            }
        });
    };

    const response = await sendRequest();
    if (setLoader) {
        dispatch(setIsLoading(false));
    }
    return response;
};

export const formatDate = (dateObj) => {
    if (dateObj === null) {
        return "";
    } else if (!isDateObject(dateObj)) {
        dateObj = new Date(dateObj);
    }
    const parts = dateObj.toLocaleDateString('en-GB').split('/');
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
};

export const formatDateStringToEst = (dateString) => {
    const parts = dateString.split("-");
    return `${parts[2]}.${parts[1]}.${parts[0]}`;
};

export const timestampToDateString = (timestamp, est = false) => {
    if (timestamp === null || timestamp === 0) {
        return "";
    }

    let date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return est ? `${day}-${month}-${year}` : `${year}-${month}-${day}`;
};

export const setExpandedRow = (rowKey, expandedRows, setExpandedRows) => {
    let newExpandedRows = [];
    let rowWasShrunk = false;

    for (let i = 0, n = expandedRows.length; i < n; i++) {
        if (expandedRows[i] !== rowKey) {
            newExpandedRows.push(expandedRows[i]);
        } else {
            rowWasShrunk = true;
        }
    }

    if (!rowWasShrunk) {
        newExpandedRows.push(rowKey);
    }

    setExpandedRows(newExpandedRows);
};

export const isSelectedRow = (rowKey, expandedRows) => {
    for (let i = 0, n = expandedRows.length; i < n; i++) {
        if (expandedRows[i] === rowKey) {
            return true;
        }
    }

    return false;
};

export const filterOutDocumentsWithNoRows = (documents) => {
    let documentsWithRows = [];
    for (let i = 0, n = documents.length; i < n; i++) {
        if (documents[i].hasOwnProperty("rows")) {
            if (!documents[i].rows.isArray) {   //getFulfillableOrders returns rows as an object instead of an array
                documents[i].rows = Object.values(documents[i].rows);
            }

            if (documents[i].rows.length > 0) {
                documentsWithRows.push(documents[i]);
            }
        }
    }

    return documentsWithRows;
};

export const filterOutDocumentsWithAttributeValue = (documents, attributeName, attributeValue) => {
    let filteredDocuments = [];
    for (let i = 0, n = documents.length; i < n; i++) {
        if (documents[i].hasOwnProperty("attributes")) {
            const attribute = documents[i].attributes.find(attribute => attribute.attributeName === attributeName);
            if (!attribute || attribute.attributeValue != attributeValue) {
                filteredDocuments.push(documents[i]);
            }
        } else {
            filteredDocuments.push(documents[i]);
        }
    }

    return filteredDocuments;
};

export const getElementByFieldValue = (array, fieldName, fieldValue) => {
    for (let i = 0, n = array.length; i < n; i++) {
        if (array[i][fieldName] == fieldValue) {
            return array[i];
        }
    }
};

export const isNotAPositiveInteger = (number) => {
    return Number(number) <= 0 || !Number.isInteger(Number(number));
};

export const isNonNegativeInteger = (number) => {
    return number !== "" && number !== null && Number.isInteger(Number(number)) && Number(number) >= 0;
};

export const isNonNegativeNumber = (number) => {
    return number !== "" && number !== null && Number(number) >= 0;
};

export const getDocumentType = (componentSequence, isAssembly) => {
    if (documentIsInventoryTransfer(componentSequence)) {
        return "INVENTORY_TRANSFER";
    } else if (isAssembly) {
        return "INVENTORY_REGISTRATION";
    } else if (documentIsAssignment(componentSequence)) {
        return "ASSIGNMENT";
    } else if (documentIsCreditInvoice(componentSequence) || documentIsSalesOrder(componentSequence)) {
        return "SALES_DOCUMENT";
    } else {
        return "PURCHASE_DOCUMENT";
    }
};

export const getIdFieldName = (isInventoryTransfer, isAssembly, isAssignment) => {
    if (isInventoryTransfer) {
        return "inventoryTransferID";
    } else if (isAssembly) {
        return "inventoryRegistrationID";
    } else if (isAssignment) {
        return "assignmentID";
    } else {
        return "id";
    }
};

export const getSavedDocumentIdFieldName = (isInventoryTransfer, isAssembly, isAssignment) => {
    if (isInventoryTransfer) {
        return "inventoryTransferID";
    } else if (isAssembly) {
        return "inventoryRegistrationID";
    } else if (isAssignment) {
        return "assignmentID";
    } else {
        return "invoiceID";
    }
};

export const getUniqueArray = (array, fieldName) => {
    let allValues = [];

    for (let i = 0, n = array.length; i < n; i++) {
        allValues.push(array[i][fieldName]);
    }

    return [...new Set(allValues)];
};

export const getProductProperty = (productsArray, productID, property) => {
    for (let i = 0, n = productsArray.length; i < n; i++) {
        if (productID == productsArray[i].productID) {
            return productsArray[i][property];
        }
    }
};

export const playSound = (type) => {
    const sound = type === "success" ? successSound : failSound;
    const audio = new Audio(sound);
    audio.play();
};

export const getApiAttributeValue = (name, attributes) => {
    if (typeof attributes === 'undefined') {
        return false;
    }

    for (let i = 0, n = attributes.length; i < n; i++) {
        if (attributes[i].attributeName === name) {
            return attributes[i].attributeValue;
        }
    }

    return false;
};

export const getNextAttributeNumber = (params) => {
    let nextAttributeNumber = 0;

    if (params.hasOwnProperty("attributeName0")) {
        for (const key of Object.keys(params)) {
            if (key.startsWith("attributeName") && key.substring(13) >= nextAttributeNumber) {
                nextAttributeNumber = Number(key.substring(13)) + 1;
            }
        }
    }

    return nextAttributeNumber;
};

export const translateBinFromEng = (binCode, language) => {
    if (language === "est" && binCode === "receiving_area") {
        return "Vastuvõtuala";
    }
    return binCode;
};

export const translateBinToEng = (binCode, language) => {
    if (language === "est" && binCode.toLowerCase() === "vastuvõtuala") {
        return "receiving_area";
    }
    return binCode;
};

export const capitalise = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const roundFloatingPointError = (float) => {
    return +parseFloat(float).toFixed(5);
};

// Field name order by which products are requested by using the field name in parameters
export const getGetProductsCodeOrder = (confParameters) => {
    const codeOrder = confParameters.wmsPrioritiseCode1 == 1 ? ["code", "code2"] : ["code2", "code"];
    codeOrder.push("code3", "supplierCode");

    if (confParameters.additionalModules.find(module => module.name === "product_extra_codes").enabled === 1) {
        codeOrder.push("code5", "code6", "code7", "code8");
    }

    return codeOrder;
};

export const simpleLog = (message) => {
    const body = {message: JSON.stringify(message)};

    fetch("./services/simpleLogger.php", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
};

export const addWorkDaysToDate = (datStartDate, lngNumberOfWorkingDays, blnIncSat, blnIncSun) => {
    var intWorkingDays = 5;
    var intNonWorkingDays = 2;
    var intStartDay = datStartDate.getDay(); // 0=Sunday ... 6=Saturday
    var intOffset;
    var intModifier = 0;

    if (blnIncSat) { intWorkingDays++; intNonWorkingDays--; }
    if (blnIncSun) { intWorkingDays++; intNonWorkingDays--; }
    var newDate = new Date(datStartDate)
    if (lngNumberOfWorkingDays >= 0) {
        // Moving Forward
        if (!blnIncSat && blnIncSun) {
            intOffset = intStartDay;
        } else {
            intOffset = intStartDay - 1;
        }
        // Special start Saturday rule for 5 day week
        if (intStartDay == 6 && !blnIncSat && !blnIncSun) {
            intOffset -= 6;
            intModifier = 1;
        }
    } else {
        // Moving Backward
        if (blnIncSat && !blnIncSun) {
            intOffset = intStartDay - 6;
        } else {
            intOffset = intStartDay - 5;
        }
        // Special start Sunday rule for 5 day week
        if (intStartDay == 0 && !blnIncSat && !blnIncSun) {
            intOffset++;
            intModifier = 1;
        }
    }
    // ~~ is used to achieve integer division for both positive and negative numbers
    newDate.setTime(datStartDate.getTime() + (new Number((~~((lngNumberOfWorkingDays + intOffset) / intWorkingDays) * intNonWorkingDays) + lngNumberOfWorkingDays + intModifier)*86400000));
    return newDate;
};

// Replaces all white space chars with space
export const unifyWhiteSpace = (string) => {
    return string.replace(/\s/g, " ");
};

export const translateAccordingToCountry = (text, confParameters) => {
    if (confParameters.country === "CA" || confParameters.country === "US") {
        return text.replace("EAN", "UPC");
    }
    return text;
};

export const getQueryParametersString = (parameters) => {
    return Object.entries(parameters).length > 0 ? "?" + encodeURIComponent(Object.entries(parameters).map(([key, val]) => `${key}=${val}`).join('&')) : "";
};

export const commaSeparatedCodesIncludeCode = (commaSeparatedCodes, scannedCode) => {
    if (commaSeparatedCodes) {
        const codeParts = commaSeparatedCodes.split(",");
        for (let i = 0, n = codeParts.length; i < n; i++) {
            if (codeParts[i].toLowerCase() === scannedCode.toLowerCase()) {
                return true;
            }
        }
    }

    return false;
};

export const sumBatches = (scannedBatchesByDocument) => {
    let summedBatches = [];

    for (let i = 0, n = scannedBatchesByDocument.length; i < n; i++) {
        for (let j = 0, n = scannedBatchesByDocument[i].scannedProducts.length; j < n; j++) {
            let batchExists = false;

            for (let k = 0, n = summedBatches.length; k < n; k++) {
                if (summedBatches[k].productID == scannedBatchesByDocument[i].scannedProducts[j].productID &&
                    summedBatches[k].binID == scannedBatchesByDocument[i].scannedProducts[j].binID && summedBatches[k].batchCode == scannedBatchesByDocument[i].scannedProducts[j].batchCode) {
                    batchExists = true;
                    summedBatches[k].amount += scannedBatchesByDocument[i].scannedProducts[j].amount;
                    break;
                }
            }

            if (!batchExists) {
                summedBatches.push(scannedBatchesByDocument[i].scannedProducts[j]);
            }
        }
    }

    return summedBatches;
};

export const isProdOrPreProd = () => {
    return window.location.href.endsWith(".erply.com/");
};

export const isProductionEnv = () => {
    return isProdOrPreProd() && !window.location.href.includes("-sb");
};

export const getCafaConfParameterValue = (cafaConfParameters, confParameterName, user) => {
    const confParameter = cafaConfParameters.find(param => param.application === "WMS"
        && param.level === "User" && param.name === confParameterName && param.level_id == user.userID);
    return confParameter ? confParameter.value : undefined;
};

// Subtract product amounts from follow up documents (amounts from previous confirmed scans)
export const subtractAmountsFromFollowUpDocuments = (followUpDocuments, scannedProducts, documentIDs, checkDocumentID = false, checkPackageIDs = false) => {
    for (let i = 0, n = followUpDocuments.length; i < n; i++) {
        if (followUpDocuments[i].hasOwnProperty("scannedAmounts")) {
            // Subtract amounts from JSON API attributes
            for (let j = 0, n = followUpDocuments[i].scannedAmounts.length; j < n; j++) {
                if (documentIDs.includes(String(followUpDocuments[i].scannedAmounts[j].documentID)) ||
                    documentIDs.includes(Number(followUpDocuments[i].scannedAmounts[j].documentID))) {
                    for (let k = 0, n = scannedProducts.length; k < n; k++) {
                        if (scannedProducts[k].productID == followUpDocuments[i].scannedAmounts[j].productID &&
                            !(checkDocumentID && scannedProducts[k].documentID != followUpDocuments[i].scannedAmounts[j].documentID)) {  // Purchase order only
                            scannedProducts[k].amount -= followUpDocuments[i].scannedAmounts[j].amount;
                        }
                    }
                }
            }
        } else {
            // Subtract amounts from Erply API follow-up documents
            for (let j = 0, n = followUpDocuments[i].rows.length; j < n; j++) {
                for (let k = 0, n = scannedProducts.length; k < n; k++) {
                    if (scannedProducts[k].productID == followUpDocuments[i].rows[j].productID &&
                        !(checkDocumentID && scannedProducts[k].documentID != followUpDocuments[i].baseDocuments[0].id) &&  // Purchase order only
                        !(checkPackageIDs && scannedProducts[k].packageID != followUpDocuments[i].rows[j].packageID)) {
                        scannedProducts[k].amount -= followUpDocuments[i].rows[j].amount;
                    }
                }
            }
        }
    }

    return scannedProducts.filter(product => roundFloatingPointError(product.amount) != 0);    // Remove 0-amounts
};

export const rowHasTc2000Product = (selectedDocumentsProducts, row) => {
    const productOnRow = selectedDocumentsProducts.find(product => product.productID == row.productID);
    return productOnRow.locationInWarehouseText === "TC2000" || productOnRow.locationInWarehouseID == akTc2000LocationInWarehouseId;
};

export const setFollowUpDocumentsScannedAmountsFromJsonAttributes = (followUpDocumentsJsonAttributes, followUpDocuments) => {
    if (followUpDocumentsJsonAttributes && !Array.isArray(followUpDocumentsJsonAttributes)) {
        followUpDocumentsJsonAttributes = [followUpDocumentsJsonAttributes];
    }

    followUpDocumentsJsonAttributes.forEach(jsonAttribute => {
        const followUpDocument = followUpDocuments.find(document => document.id == jsonAttribute.id);
        if (jsonAttribute.json_object) {
            followUpDocument.scannedAmounts = jsonAttribute.json_object.WMS.scannedAmounts;
        }
    })
};

export const rowIsBundle = (row) => {
    return row.hasOwnProperty("productComponents") && row.productComponents.length > 0;
};

export const documentIsCreditInvoice = (componentSequence) => {
    const isOutScan = componentSequence.includes("ProductsOut");
    return !isOutScan && componentSequence.includes("ReturnsListConfirmation");
};

export const documentIsPurchaseReturn = (componentSequence) => {
    const isOutScan = componentSequence.includes("ProductsOut");
    return isOutScan && componentSequence.includes("ReturnsListConfirmation");
};

export const documentIsInventoryTransfer = (componentSequence) => {
    return componentSequence.includes("InventoryTransfer");
};

export const documentIsAssignment = (componentSequence) => {
    return componentSequence.includes("Assignment");
};

export const documentIsSalesOrder = (componentSequence) => {
    return componentSequence.includes("SalesOrder");
};

export const documentIsPurchaseOrder = (componentSequence, isScanBySupplier) => {
    return !scanIsOutScan(componentSequence) && !documentIsInventoryTransfer(componentSequence) && !documentIsCreditInvoice(componentSequence) && !isScanBySupplier;
};

export const scanIsOutScan = (componentSequence) => {
    return componentSequence.includes("ProductsOut");
};

export const getDateOneMonthAgo = () => {
    const date = new Date();
    const month = date.getMonth();
    date.setMonth(date.getMonth() - 1);

// If still in same month, set date to last day of previous month
    if (date.getMonth() == month) date.setDate(0);
    date.setHours(0, 0, 0, 0);

    return date;
};

export const deepCopy = (element) => {
    return JSON.parse(JSON.stringify(element));
};

// Not configured to handle packages
export const rearrangeRowsAccordingToBaseDocument = (requestParams, products, baseDocument, isCreditInvoice) => {
    console.log({"Initial params before rearranging rows according to base document": deepCopy(requestParams)});
    console.log({"Products to be rearranged": products});
    const replaceableParams = ["productID", "amount", "price", "itemName", "vatrateID", "discount"];
    const productIDParamNames = Object.keys(requestParams).filter(param => param.startsWith("productID"));
    const productIDsRearranged = [];
    let noOfProductsNotInScannedProducts = 0;
    let noOfExtraSerialNoProductRows = 0;

    let noOfProductsNotOnDocument = 0;
    products.forEach(product => {
        if (product.productIsNotOnDocument === 1) noOfProductsNotOnDocument ++;
    });

    let splitAmountNewRowNo = productIDParamNames.length - noOfProductsNotOnDocument;

    const replaceRowNos = (oldRowNo, newRowNo) => {
        console.log(oldRowNo + "->" +  newRowNo)
        console.log(requestParams["itemName" + oldRowNo] + "->" +  requestParams["itemName" + newRowNo])
        if (oldRowNo != newRowNo) {
            replaceableParams.forEach(replaceableParam => {
                const newParamName = `${replaceableParam}${newRowNo}`;
                const oldParamName = `${replaceableParam}${oldRowNo}`;
                const currentValueOnNewRowNo = requestParams[newParamName];
                delete Object.assign(requestParams, {[newParamName] : requestParams[oldParamName] })[oldParamName];
                requestParams[oldParamName] = currentValueOnNewRowNo;
            });
        }
    };

    const splitAmount = (oldRowNo, oldAmount, newAmount) => {
        // Move products not on document one position further so they always end up in the end of rows (like they are pre-split)
        for (let i = 1; i <= noOfProductsNotOnDocument; i++) {
            replaceableParams.forEach(replaceableParam => {
                const newParamName = `${replaceableParam}${productIDParamNames.length}`;
                const oldParamName = `${replaceableParam}${productIDParamNames.length - i}`;
                requestParams[newParamName] = requestParams[oldParamName];
            });
        }

        replaceableParams.forEach(replaceableParam => {
            const newParamName = `${replaceableParam}${splitAmountNewRowNo}`;
            const oldParamName = `${replaceableParam}${oldRowNo}`;

            if (replaceableParam === "amount") {
                requestParams[newParamName] = oldAmount - newAmount;
                requestParams[oldParamName] = newAmount;
            } else {
                requestParams[newParamName] = requestParams[oldParamName];  // Does not differentiate between rows with same product but different prices/vatrates etc
            }
        });

        productIDParamNames.push(`productID${productIDParamNames.length}`);
        splitAmountNewRowNo ++;
    };

    const removeSerialNoFromProductName = (name) => {
        return name.split(" SN: ")[0];
    };

    const isSerialNoProduct = (name) => {
        return name && (name.includes(" SN: ") || instancesWhereProductHasSerialNoExist(name));
    };

    const instancesWhereProductHasSerialNoExist = (name) => {
        let instancesWhereProductHasSerialNoExist = false;

        Object.keys(requestParams).forEach(parameterName => {
            if (parameterName.startsWith("itemName") && requestParams[parameterName].startsWith(`${name} SN: `)) instancesWhereProductHasSerialNoExist = true;
        });

        return instancesWhereProductHasSerialNoExist;
    };

    for (let baseDocRowNo = 0, n = baseDocument.rows.length; baseDocRowNo < n; baseDocRowNo++) {
        let productWasInScannedProducts = false;
        const isTextBasedRow = baseDocument.rows[baseDocRowNo].productID == 0;
        let newRowNo = baseDocRowNo - noOfProductsNotInScannedProducts + noOfExtraSerialNoProductRows;

        let j = 0;
        while (j < productIDParamNames.length) {
            const currentRowNo = productIDParamNames[j].replace("productID", "");
            const isSnProduct = isSerialNoProduct(requestParams[`itemName${currentRowNo}`]);

            if ((!baseDocument.rows[baseDocRowNo].hasOwnProperty("itemName") || baseDocument.rows[baseDocRowNo].itemName === removeSerialNoFromProductName(requestParams[`itemName${currentRowNo}`])) &&
                (isTextBasedRow || (!isTextBasedRow && baseDocument.rows[baseDocRowNo].productID == requestParams[productIDParamNames[j]])) &&
                !(productIDsRearranged.includes(baseDocument.rows[baseDocRowNo].productID) && currentRowNo < newRowNo)) {
                if (!isSnProduct && ((isCreditInvoice && requestParams[`amount${currentRowNo}`] < baseDocument.rows[baseDocRowNo].amount) ||
                    (!isCreditInvoice && requestParams[`amount${currentRowNo}`] > baseDocument.rows[baseDocRowNo].amount))) {
                    splitAmount(currentRowNo, requestParams[`amount${currentRowNo}`], baseDocument.rows[baseDocRowNo].amount);
                }
                if (!isTextBasedRow) {
                    productIDsRearranged.push(baseDocument.rows[baseDocRowNo].productID);
                }

                if (isSnProduct && productWasInScannedProducts) {
                    noOfExtraSerialNoProductRows ++;
                    newRowNo ++;
                }

                productWasInScannedProducts = true;
                replaceRowNos(currentRowNo, newRowNo);

                if (!isSnProduct) break;
            }

            j++;
        }

        if (!productWasInScannedProducts) noOfProductsNotInScannedProducts ++;
    }

    return requestParams;
};

// Get all names of the product on documents (custom names have been used), purchase and sales documents only
export const getScannedProductNamesOnDocuments = (product, documents) => {
    const names = [];
    for (let i = 0, n = documents.length; i < n; i++) {
        for (let j = 0, n = documents[i].rows.length; j < n; j++) {
            if (documents[i].rows[j].productID == product.productID && documents[i].rows[j].hasOwnProperty("itemName")) {
                names.push(documents[i].rows[j].itemName);
            }
        }
    }

    return [...new Set(names)];
};

// Same products from different bins summed together
export const getSummedScannedProducts = (scannedProducts, differentiateByPackageID) => {
    let summedScannedProducts = [];
    for (let i = 0, n = scannedProducts.length; i < n; i++) {
        let productFound = false;

        for (let j = 0, n = summedScannedProducts.length; j < n; j++) {
            if (scannedProducts[i].productID == summedScannedProducts[j].productID &&
                scannedProducts[i].name === summedScannedProducts[j].name &&
                scannedProducts[i].bundleID == summedScannedProducts[j].bundleID &&
                scannedProducts[i].bundleName === summedScannedProducts[j].bundleName &&
                !(differentiateByPackageID && scannedProducts[i].packageID != summedScannedProducts[j].packageID)) {
                productFound = true;
                summedScannedProducts[j].amount += scannedProducts[i].amount;
            }
        }

        if (!productFound) {
            const productCopy = Object.assign({}, scannedProducts[i]);
            summedScannedProducts.push(productCopy);
        }
    }

    return summedScannedProducts;
};

// Returns an array where [0] - scanned products, [1] - scanned products by document, [2] - current session's scanned products
export const getScannedProducts = (binRecords, products, componentSequence, isScanBySupplier, multiplePurchaseOrderScanEnabled, productsNotOnDocument, differentiateByPackageID, followUpDocuments, confParameters) => {
    // Returns an array where [0] - scanned products, [1] - scanned products by document
    const getScannedProductsFromBinRecords = (binRecords) => {
        const createProduct = (binRecord, name, amount, vatrateID, productIsNotOnDocument) => {
            const product =  {
                productID: binRecord.productID,
                code: binRecord.productCode,
                code2: binRecord.productCode2,
                name: name,
                binID: binRecord.binID,
                binCode: binRecord.binCode,
                amount: amount,
                bundleID: binRecord.hasOwnProperty("bundleID") ? binRecord.bundleID : 0,
                bundleName: binRecord.bundleName
            };

            if (vatrateID) {
                product.vatrateID = vatrateID
            }
            if (productIsNotOnDocument) {
                product.productIsNotOnDocument = 1
            }
            if (differentiateByPackageID) {
                product.packageID = binRecord.packageID;
            }

            return product;
        };

        let scannedProducts = [];
        let scannedProductsByDocument = [];

        for (let i = 0, n = binRecords.length; i < n; i++) {
            const customName = binRecords[i].hasOwnProperty("customName") ? binRecords[i].customName : "";

            for (let k = 0, n = products.length; k < n; k++) {
                if (binRecords[i].productID == products[k].productID) {    // Ignore products not on document
                    let productIsInScannedProducts = false;
                    const name = customName !== "" ? customName : products[k].name;
                    const amount = scanIsOutScan(componentSequence) ? -1 * Number(binRecords[i].amount) : Number(binRecords[i].amount);
                    const product = createProduct(binRecords[i], name, amount);

                    if (documentIsPurchaseOrder(componentSequence, isScanBySupplier) && multiplePurchaseOrderScanEnabled) { // Multiple purchase orders chosen
                        scannedProductsByDocument.push(Object.assign({documentID: binRecords[i].documentID}, product));
                    }

                    for (let j = 0, n = scannedProducts.length; j < n; j++) {
                        if (scannedProducts[j].productID == binRecords[i].productID && scannedProducts[j].binID == binRecords[i].binID &&
                            !(customName !== "" && scannedProducts[j].name !== customName) &&
                            scannedProducts[j].bundleID == binRecords[i].bundleID &&
                            scannedProducts[j].bundleName === binRecords[i].bundleName &&
                            !(differentiateByPackageID && scannedProducts[j].packageID != binRecords[i].packageID)) {
                            productIsInScannedProducts = true;
                            scannedProducts[j].amount += roundFloatingPointError(amount);
                            break;
                        }
                    }

                    if (!productIsInScannedProducts) {
                        scannedProducts.push(product);
                    }
                }
            }
        }

        // Add scanned products not on document (ProductsIn with wmsEnableScanningProductsNotOnInDocument == 1 or ProductsOut with wmsEnableScanningProductsNotOnOutDocument == 1)
        for (let i = 0, n = binRecords.length; i < n; i++) {
            const productIsOnDocument = products.some(product => product.productID == binRecords[i].productID);

            if (!productIsOnDocument) {
                const productInScannedProducts = scannedProducts.find(product => product.productID == binRecords[i].productID && product.binID == binRecords[i].binID);
                const productNotOnDocument = productsNotOnDocument.find(product => product.productID == binRecords[i].productID);
                const name = productNotOnDocument ? productNotOnDocument.name : "";
                const amount = scanIsOutScan(componentSequence) ? -1 * Number(binRecords[i].amount) : Number(binRecords[i].amount);
                const vatrateID = productNotOnDocument ? productNotOnDocument.vatrateID : 0;  // Needed when saving document
                const product = createProduct(binRecords[i], name, amount, vatrateID, true);

                if (documentIsPurchaseOrder(componentSequence, isScanBySupplier) && multiplePurchaseOrderScanEnabled) { // Multiple purchase orders chosen
                    scannedProductsByDocument.push(Object.assign({documentID: binRecords[i].documentID}, product));
                }

                if (!productInScannedProducts) {
                    if (productNotOnDocument) { // This is to check if the product has been deleted from the database (extreme edge case)
                        scannedProducts.push(product);
                    }
                } else {
                    productInScannedProducts.amount += roundFloatingPointError(amount);
                }
            }
        }

        //Remove products with 0 amount total
        scannedProducts = scannedProducts.filter(product => roundFloatingPointError(product.amount) != 0);
        return [scannedProducts, scannedProductsByDocument];
    };

    const [scannedProducts, scannedProductsByDocument] = getScannedProductsFromBinRecords(binRecords);

    let currentSessionScannedProducts = scannedProducts;
    if (documentIsPurchaseOrder(componentSequence, isScanBySupplier) || (documentIsInventoryTransfer(componentSequence) && confParameters.wmsEnableRepeatedTransferOrderScan == 1)) {
        const latestBinRecords = getCurrentSessionBinRecords(binRecords, followUpDocuments);
        const [currentScannedProducts, currentScannedProductsByDocument] = getScannedProductsFromBinRecords(latestBinRecords);
        currentSessionScannedProducts = currentScannedProducts;
    }

    return [scannedProducts, scannedProductsByDocument, currentSessionScannedProducts];
};

export const getCurrentSessionBinRecords = (binRecords, followUpDocuments) => {
    let latestFollowUpDocumentCreationTimestamp = 0;
    followUpDocuments.forEach(followUpDocument => {
        if (followUpDocument.added > latestFollowUpDocumentCreationTimestamp) latestFollowUpDocumentCreationTimestamp = followUpDocument.added;
    });

    return binRecords.filter(binRecord => binRecord.added > latestFollowUpDocumentCreationTimestamp);
};

export const differentiateProductsByPackageID = (componentSequence, isScanBySupplier, confParameters) => {
    return documentIsPurchaseOrder(componentSequence, isScanBySupplier) && confParameters.wmsPutProductsScannedAsPackagesOnCreatedDocumentAsPackages == 1;
};

// Product has several different names on document and they must match
export const productNamesMustMatch = (componentSequence, product, documents) => {
    return documentIsSalesOrder(componentSequence) && getScannedProductNamesOnDocuments(product, documents).length > 1;
};

// Products' productIDs and names (if needed) match. Product order is not important
export const productsAreIdentical = (componentSequence, documents, product1, product2, checkBundleIDs = true, checkPackageIDs = false) => {
    const name1 = product1.hasOwnProperty("name") ? product1.name : product1.itemName;
    const name2 = product2.hasOwnProperty("name") ? product2.name : product2.itemName;
    const bundleID1 = product1.hasOwnProperty("bundleID") ? product1.bundleID : 0;
    const bundleID2 = product2.hasOwnProperty("bundleID") ? product2.bundleID : 0;
    const bundleName1 = product1.hasOwnProperty("bundleName") ? product1.bundleName : "";
    const bundleName2 = product2.hasOwnProperty("bundleName") ? product2.bundleName : "";
    return product1.productID == product2.productID &&
        !(productNamesMustMatch(componentSequence, product1, documents) && name1 !== name2) &&
        !(checkBundleIDs && (bundleID1 != bundleID2)) &&
        // !(checkBundleIDs && (bundleID1 != bundleID2 || bundleName1 !== bundleName2)) && // TODO: Siin on ostutellimuste followupdocuments koguste mahalahutamisel subtractedRows mingi viga
        !(checkPackageIDs && product1.packageID != product2.packageID);
};

const getFieldValue = (fieldNames, object) => {
    for (let i = 0, n = fieldNames.length; i < n; i++) {
        if (object.hasOwnProperty(fieldNames[i])) {
            return object[fieldNames[i]];
        }
    }

    return null;
};

export const getProductCode = (product) => {
    const fieldNames = ["code", "productCode"];
    return getFieldValue(fieldNames, product);
};

export const getProductCode2 = (product) => {
    const fieldNames = ["code2", "productCode2"];
    return getFieldValue(fieldNames, product);
};

export const getProductName = (product) => {
    const fieldNames = ["name", "productName", "itemName"];
    return getFieldValue(fieldNames, product);
};

export const isBundleComponent = (product) => {
    return product.hasOwnProperty("amountInBundle") || (product.hasOwnProperty("bundleID") && product.bundleID != 0);
};

export const productNeedsSerialNumber = (product, componentSequence, isScanBySupplier) => {
    return (product.hasSerialNumbers == 1 || getApiAttributeValue("SNNeeded", product.attributes) == 1) &&
        (documentIsSalesOrder(componentSequence) || documentIsPurchaseOrder(componentSequence, isScanBySupplier));
};

export const productIsInSelectedBundle = (product, productsOnDocument, bundle) => {
    return productsOnDocument.some(productOnDocument => productOnDocument.productID == product.productID && productOnDocument.bundleID == bundle.productID && productOnDocument.bundleName === bundle.itemName);
};

export const productPresentOnlyInBundle = (product, productsOnDocument) => {
    return productsOnDocument.length > 0 &&
        productsOnDocument.some(productOnDocument => productOnDocument.productID == product.productID) &&
        !productsOnDocument.some(productOnDocument => productOnDocument.productID == product.productID && !isBundleComponent(productOnDocument));
};

export const bundleIsFullyScanned = (subtractedRows, bundle) => {
    return !subtractedRows.some(row => row.bundleID == bundle.productID && row.bundleName === bundle.itemName);
};

export const sumSerialNoBinRecords = (binRecords, differentiateByNameAndBin = false) => {
    let summedSerialNos = [];

    binRecords.forEach(binRecord => {
        if (binRecord.serialNo !== "") {
            const existingSummedSerialNo = summedSerialNos.find(summedSerialNo => summedSerialNo.productID == binRecord.productID && summedSerialNo.serialNo === binRecord.serialNo &&
                !(differentiateByNameAndBin && summedSerialNo.name !== binRecord.customName && summedSerialNo.binID != binRecord.binID));
            let amount = Number(binRecord.amount);
            if (binRecord.referenceType === "SALES_DOCUMENT") amount *= -1;

            if (existingSummedSerialNo) {
                existingSummedSerialNo.amount += amount;
            } else {
                const summedSerialNo = {productID: binRecord.productID, serialNo: binRecord.serialNo, amount: amount, code: binRecord.productCode};
                if (differentiateByNameAndBin) {
                    summedSerialNo.name = binRecord.customName;
                    summedSerialNo.binID = binRecord.binID;
                }
                summedSerialNos.push(summedSerialNo);
            }
        }
    });

    // Sort by code
    summedSerialNos = summedSerialNos.sort(((a, b) => a.code.localeCompare(b.code)));
    return summedSerialNos;
};

export const getBundleAmountOnDocument = (document, bundle) => {
    let bundleAmountOnDocument = 0;

    document.rows.forEach(row => {
        if (row.productID == bundle.productID && row.itemName === bundle.itemName) {
            bundleAmountOnDocument += Number(row.amount);
        }
    });

    return bundleAmountOnDocument;
};

export const getProductPackage = (product, selectedDocumentsProductsAndProductsNotOnDocument) => {
    const productWithExtraData = selectedDocumentsProductsAndProductsNotOnDocument.find(p => p.productID == product.productID);
    return productWithExtraData.productPackages.find(productPackage => productPackage.packageID == product.packageID);
};

export const getLocationInWarehouseFromProductCard = (product) => {
    return product.locationInWarehouseText === "" ? product.locationInWarehouseName : product.locationInWarehouseText;
};

export const isDateObject = (date) => {
    return typeof date.getMonth === 'function';
};

export const confirmBinAmountWithUser = (t, amountInBin, binID, productID, callbackFunction) => {
    return (dispatch, getState) => {
        const state = getState();
        const confParameters = state.getConfParametersReducer.confParameters;
        const confirmBinAmountWithUserIfLessThan = confParameters.wmsConfirmBinAmountWithUserIfLessThan;

        if (confParameters.wmsUseProductLocationsInWarehouse == 1 && confirmBinAmountWithUserIfLessThan &&
            confirmBinAmountWithUserIfLessThan !== "" && amountInBin < confirmBinAmountWithUserIfLessThan) {
            const message = `${t("isNewBinAmount")} <b>${amountInBin}</b>?`;
            const onNo = () => {
                const onYes = async (newAmount) => {
                    newAmount = newAmount.replace(",", ".");

                    if (!isNonNegativeNumber(newAmount)) {
                        dispatch(errorMessageSet(t("amountMustBeNonNegative")));
                        displayEnterNewAmountModal();
                    } else if (amountInBin == newAmount) {
                        dispatch(errorMessageSet(t("noChanges")));
                        displayEnterNewAmountModal();
                    } else {
                        const user = state.verifyUserReducer.user;
                        const params = {
                            request: "adjustBinQuantities",
                            binID0: binID,
                            productID0: productID,
                            newAmount0: newAmount,
                            creatorID0: user.employeeID
                        };

                        await dispatch(makeErplyRequest(params, t("adjustBinQuantitiesError"), null, null, null));
                        callbackFunction();
                    }
                };

                const displayEnterNewAmountModal = () => {
                    dispatch(setModal(t("enterNewAmount"), "", onYes, true, "", [], callbackFunction));
                };

                displayEnterNewAmountModal();
            };

            dispatch(setModal(t("confirmation"), message, callbackFunction, false, "", [], onNo));
        } else {
            callbackFunction();
        }
    }
};

export const extractBoldTagsFromString = (string) => {
    let modifiedString = string;
    const boldStartIndex = string.indexOf("<b>");

    if (boldStartIndex !== -1) {
        const boldEndIndex = string.indexOf("</b>");

        if (boldEndIndex !== -1) {
            modifiedString = [string.substring(0, boldStartIndex), <b>{string.substring(boldStartIndex + 3, boldEndIndex)}</b>, string.substring(boldEndIndex + 4)];
        }
    }

    return modifiedString;
};

// Purchase documents only
export const getAmountLeftOnDocument = (selectedDocument, productID, binRecords) => {
    let initialAmount = 0;
    for (let i = 0, n = selectedDocument.rows.length; i < n; i++) {
        if (productID == selectedDocument.rows[i].productID) {
            initialAmount += selectedDocument.rows[i].amount;
        }
    }

    let scannedAmount = 0;
    for (let i = 0, n = binRecords.length; i < n; i++) {
        if (productID == binRecords[i].productID && selectedDocument.id == binRecords[i].documentID) {
            scannedAmount += binRecords[i].amount;
        }
    }

    return initialAmount - scannedAmount;
};

export const getDateRangeInMs = (startDate, endDate) => {
    if (endDate === "" || endDate === null) endDate = new Date();
    return endDate - startDate;
};

export const getGetDocumentsRequestName = (componentSequence, isAssembly) => {
    if (documentIsInventoryTransfer(componentSequence)) {
        return "getInventoryTransfers";
    } else if (isAssembly) {
        return "getInventoryRegistrations";
    } else if (documentIsAssignment(componentSequence)) {
        return "getAssignments";
    } else if (documentIsCreditInvoice(componentSequence) || documentIsSalesOrder(componentSequence)) {
        return "getSalesDocuments";
    } else {
        return "getPurchaseDocuments";
    }
};

export const getSaveDocumentRequestName = (componentSequence, isAssembly) => {
    if (documentIsInventoryTransfer(componentSequence)) {
        return "saveInventoryTransfer";
    } else if (isAssembly) {
        return "saveInventoryRegistration";
    } else if (documentIsAssignment(componentSequence)) {
        return "saveAssignment";
    } else if (documentIsCreditInvoice(componentSequence) || documentIsSalesOrder(componentSequence)) {
        return "saveSalesDocument";
    } else {
        return "savePurchaseDocument";
    }
};

export const getFulfillableOrderTotal = (order, vatRates) => {
    let total = 0;

    order.rows.forEach(row => {
        const vatRate = vatRates.find(vatRate => vatRate.id == row.vatRateId);
        const vatRateRate = vatRate ? vatRate.rate : 0;
        let rowTotal = row.orderedAmount * row.price;
        rowTotal += rowTotal * vatRateRate / 100;
        rowTotal = rowTotal * (100 - row.discount) / 100;
        total += rowTotal;
    });

    return Math.round((total + Number.EPSILON) * 100) / 100;
};

export const syncQuantitiesOnDocument = (t, documentIDs, componentSequence, isAssembly, isScanBySupplier, supplierBin, selectedDocumentsProducts, multiplePurchaseOrderScanEnabled, scannedProducts, differentiateByPackageID, followUpDocuments, confParameters) => {
    return async (dispatch) => {
        const binRecords = await dispatch(getBinRecords(t, documentIDs, getDocumentType(componentSequence, isAssembly), isScanBySupplier ? supplierBin.binID : null, true));
        const [newScannedProducts, scannedProductsByDocument, currentSessionScannedProducts] = getScannedProducts(binRecords, selectedDocumentsProducts, componentSequence, isScanBySupplier, multiplePurchaseOrderScanEnabled, scannedProducts, differentiateByPackageID, followUpDocuments, confParameters);
        dispatch(setScannedProducts(newScannedProducts));
        dispatch(setScannedProductsByDocument(scannedProductsByDocument));
        dispatch(setCurrentSessionScannedProducts(currentSessionScannedProducts));
        const summedScannedProducts = getSummedScannedProducts(currentSessionScannedProducts, differentiateByPackageID);
        dispatch(setSummedScannedProducts(summedScannedProducts));
        return [newScannedProducts, scannedProductsByDocument, currentSessionScannedProducts, summedScannedProducts];
    }
};

export const checkSessionRefreshTimestamp = (t) => {
    return async (dispatch, getState) => {
        const state = getState();
        const currentTimestamp = new Date().getTime();

        if (dispatch(isInfralinkSelfService())) {
            const sessionRefreshTimestamp = state.verifyUserReducer.sessionRefreshTimestamp;

            if (sessionRefreshTimestamp && sessionRefreshTimestamp <= currentTimestamp) {
                console.log("Refreshing session");
                const verifyUserResponse = await dispatch(verifyUser(t));
                if (responseHasErrors(verifyUserResponse)) {
                    simpleLog(`Infralink verifyUserResponse has errors`);
                    dispatch(logout(true));
                }
                await dispatch(getConfParameters(t));
            }
        } else {
            const logOutWarningShown = state.verifyUserReducer.logOutWarningShown;
            const logOutTimestamp = state.verifyUserReducer.logOutTimestamp;
            const minutesToLogout = Math.floor((logOutTimestamp - currentTimestamp) / 1000 / 60);

            if (logOutTimestamp) {
                if (logOutTimestamp <= currentTimestamp) {
                    dispatch(logout(true));
                } else if (!logOutWarningShown && minutesToLogout <= 5) {
                    dispatch(setInformativeModal(`${t("logOutWarningText1")} ${minutesToLogout} ${t("logOutWarningText2")}`, t("logOutWarningHeader")));
                    dispatch(setLogOutWarningShown(true));
                }
            }
        }
    }
};

export const checkSessionRefreshTimestampPeriodically = (t) => {
    return async (dispatch, getState) => {
        const state = getState();
        const sessionRefreshTimestamp = state.verifyUserReducer.sessionRefreshTimestamp;
        if (sessionRefreshTimestamp) setInterval(() => dispatch(checkSessionRefreshTimestamp(t)), 4000);
    }
};

export const exitSettingsView = () => {
    return (dispatch, getState) => {
        const state = getState();
        const preSettingsComponent = state.componentReducer.previousSettingsComponent;
        const preSettingsPreviousComponent = state.componentReducer.preSettingsPreviousComponent;
        dispatch(setSettingsBtnHasBeenClicked(false));
        dispatch(previousComponentSet(preSettingsPreviousComponent));
        dispatch(componentSet(preSettingsComponent));
    }
};

export const isInfralinkSelfService = (clientCode, user) => {
    return (dispatch, getState) => {
        const state = getState();
        if (!clientCode) clientCode = state.verifyUserReducer.clientCode;
        if (!user) user = state.verifyUserReducer.user;
        return (isInfralink(clientCode) && user.groupID == 10) || (isMarikaClientCode(clientCode) && user.groupID == 11) || (isTesterClientCode(clientCode) && user.userID == 7);
    }
};

export const getConfParameterValue = (parameterName) => {
    return (dispatch, getState) => {
        const state = getState();
        const confParameters = state.getConfParametersReducer.confParameters;
        const cafaConfParameters = state.newErplyApisReducer.cafaConfParameters;
        const user = state.verifyUserReducer.user;
        const cafaConfParameterValue = getCafaConfParameterValue(cafaConfParameters, parameterName, user);
        return cafaConfParameterValue !== undefined ? cafaConfParameterValue : confParameters[parameterName];
    }
};

export const binQuantityFieldsToProductFields = (binQuantities) => {
    binQuantities.forEach(binQuantity => {
        binQuantity.code = binQuantity.productCode;
        binQuantity.code2 = binQuantity.productCode2;
        binQuantity.code3 = binQuantity.productCode3;
        binQuantity.supplierCode = binQuantity.productSupplierCode;
        binQuantity.code5 = binQuantity.productCode5;
        binQuantity.code6 = binQuantity.productCode6;
        binQuantity.code7 = binQuantity.productCode7;
        binQuantity.code8 = binQuantity.productCode8;
    });
};

export const makeId = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter ++;
    }
    return result;
};

// Arrays must not contain {objects} or behavior may be undefined
export const arraysAreEqual = (array1, array2) => {
    return JSON.stringify(array1) === JSON.stringify(array2);
};
