import React, {useEffect, useState} from 'react';
import {Button, Dropdown, Label} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {componentSet} from "../actions/component";
import {useDispatch, useSelector} from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {makeErplyBulkRequest, makeErplyRequest} from "../util/erplyRequests";
import {errorMessageSet} from "../actions/headerMessage";
import GoBackToStartBtn from "./GoBackToStartBtn";
import GoBackBtn from "./GoBackBtn";
import {filterOutDocumentsWithNoRows, formatDate, getApiAttributeValue, getDateRangeInMs} from "../util/misc";
import {getWarehousesSuccess} from "../actions/getWarehouses";
import {setSearchResultDocuments, setSearchResultFollowUpDocuments} from "../actions/searchDocuments";
import DatePickerComponent from "./DatePickerComponent";

const SearchInventoryTransfers = () => {
    const getDefaultFromDate = () => {
        let d = new Date();
        d.setMonth(d.getMonth() - 3);
        return d;
    };

    useEffect(() => {
        getWarehouses();
    }, []);

    const [fromDate, setFromDate] = useState(getDefaultFromDate);
    const [toDate, setToDate] = useState(new Date());
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [warehouseID, setWarehouseID] = useState(null);

    const warehouses = useSelector(state => state.getWarehousesReducer.warehouses);
    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);
    const componentSequence = useSelector(state => state.componentReducer.componentSequence);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);

    const isOutScan = componentSequence.includes("ProductsOut");

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleGoBackOnClick = () => {
        dispatch(componentSet("InventoryTransfer"));
    };

    const getWarehouses = () => {
        const params = {request: "getWarehouses"};
        dispatch(makeErplyRequest(params, t("getWarehousesError"), null, getWarehousesSuccess, null));
    };

    const getOptions = () => {
        return warehouses.map(warehouse => getWarehouseOption(warehouse))
            .filter(option => option.value !== selectedWarehouse.warehouseID)  // Filter out selected warehouse from options;
    };

    const getWarehouseOption = (warehouse) => {
        return {key: warehouse.warehouseID, text: warehouse.name, value: warehouse.warehouseID};
    };

    const handleOnChange = (event, { value }) => {
        setWarehouseID(value);
        setDropdownOpen(false);
    };

    const searchInventoryTransfers = () => {
        if (fromDate === null) {
            return dispatch(errorMessageSet(t("fromDateMustBeSet")));
        }

        const dateRangeInMs = getDateRangeInMs(fromDate, toDate);
        const ms180Days = 15552000000;
        if (dateRangeInMs > ms180Days) {
            return dispatch(errorMessageSet(t("periodMustNotExceed180Days")));
        }

        const params = {
            request: "getInventoryTransfers",
            type: "TRANSFER_ORDER",
            dateFrom: formatDate(fromDate),
            dateTo: formatDate(toDate),
            confirmed: 1
        };

        if (isOutScan) {
            params.warehouseFromID = selectedWarehouse.warehouseID;
        } else {
            params.warehouseToID = selectedWarehouse.warehouseID;
        }

        if (warehouseID !== null) {
            if (isOutScan) {
                params.warehouseToID = warehouseID;
            } else {
                params.warehouseFromID = warehouseID;
            }
        }

        if (confParameters.wmsEnableRepeatedTransferOrderScan != 1) params.fulfilled = 0;

        dispatch(makeErplyRequest(params, t("getInventoryTransfersError"), null, null, null, true)).then(async (transferOrders) => {
            let filteredTransferOrders = filterOutDocumentsWithNoRows(transferOrders);
            filteredTransferOrders = filteredTransferOrders.filter(order => order.status !== "REJECTED"); // Can't put this into the request parameters because Berlin BO does not save statuses

            if (filteredTransferOrders.length === 0) {
                dispatch(errorMessageSet(t("criteriaDocsNotFound")));
            } else {
                if (confParameters.wmsEnableRepeatedTransferOrderScan == 1 || confParameters.wmsInTransitWarehouseID !== "") {
                    // Needed to subtract follow-up documents' amounts to get scannable amount displayed in InventoryTransfersListConfirmation view
                    // or to filter out inventory transfer orders of which an inventory transfer to In-transit warehouse has been made
                    const followUpDocuments = await getFollowUpDocuments(filteredTransferOrders);
                    if (confParameters.wmsInTransitWarehouseID !== "") {
                        // Filter out transfer orders with follow up In-transit transfers
                        filteredTransferOrders = filteredTransferOrders.filter(transferOrder => !followUpDocuments.some(followUpDocument =>
                            getApiAttributeValue("sourceTransferOrderID", followUpDocument.attributes) == transferOrder.inventoryTransferID));
                    }
                    dispatch(setSearchResultFollowUpDocuments(followUpDocuments));
                }

                if (filteredTransferOrders.length === 0) {
                    dispatch(errorMessageSet(t("criteriaDocsNotFound")));
                } else {
                    dispatch(setSearchResultDocuments(filteredTransferOrders));
                    dispatch(componentSet("InventoryTransfersListConfirmation"));
                }
            }
        });
    };

    const getFollowUpDocuments = (inventoryTransferOrders) => {
        const requests = inventoryTransferOrders.map(inventoryTransferOrder => ({
            requestName: "getInventoryTransfers",
            searchAttributeName: "sourceTransferOrderID",
            searchAttributeValue: inventoryTransferOrder.inventoryTransferID
        }));

        return dispatch(makeErplyBulkRequest(requests, t("getInventoryTransfersError"), null, null, null, true, true));
    };

    const handleNameOnBlur = () => {
        setDropdownOpen(false);
    };

    return (
        <div>
            <DatePickerComponent labelText={"dateFrom"} value={fromDate} setStateFunction={setFromDate} className={"searchInput"}/>
            <DatePickerComponent labelText={"dateTo"} value={toDate} setStateFunction={setToDate} className={"searchInput"}/>
            <div className={"searchRow"}>
                <Label className={"searchLabel searchLabelShort"} size={"large"} pointing={"right"}>{t("warehouse")}</Label>
                <Dropdown
                    fluid
                    className={"searchInput searchInputLong"}
                    placeholder={t("enterName")}
                    selection
                    open={dropdownOpen}
                    options={getOptions()}
                    search
                    onChange={handleOnChange}
                    clearable
                    noResultsMessage={t("noResultsFound")}
                    onBlur={handleNameOnBlur}
                    onClick={() => setDropdownOpen(true)}
                />
            </div>
            <div className={"btnsGroup"}>
                <Button className={"menuBtn"} color={"blue"} size={"big"} onClick={searchInventoryTransfers}>{t("search")} </Button>
                <div className={"flex flexCenter"}>
                    <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                    <GoBackToStartBtn/>
                </div>
            </div>
        </div>
    );
};

export default SearchInventoryTransfers
