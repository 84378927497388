import React, { useState, useEffect, useRef } from 'react';
import {Button, Input} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {componentSet, previousComponentSet} from "../actions/component";
import {errorMessageSet} from "../actions/headerMessage";
import {useDispatch} from "react-redux";
import GoBackToStartBtn from "./GoBackToStartBtn";
import {getBins} from "../util/erplyRequests";
import {setBinBeingEdited} from "../actions/binManagement";
import GoBackBtn from "./GoBackBtn";

const ChangeBin = () => {
    const binCodeInput = useRef(null);

    const [binCode, setBinCode] = useState("");

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        binCodeInput.current.focus();
    }, []);

    const handleGoBackOnClick = () => {
        dispatch(componentSet("BinManagement"));
    };

    const handleOkOnClick = async () => {
        if (binCode === "") {
            dispatch(errorMessageSet(t("enterFromAddress")));
        } else {
            const bin = await dispatch(getBins(t, true, null, null, "", binCode)).then(bins => bins[0]);

            if (bin === undefined) {
                dispatch(errorMessageSet(t("noBinFound")));
            } else {
                dispatch(setBinBeingEdited(bin));
                dispatch(previousComponentSet("ChangeBin"));
                dispatch(componentSet("AddBin"));
            }
        }
    };

    return (
        <div>
            <Input ref={binCodeInput} className={"searchDocNoInput"} onInput={e => setBinCode(e.target.value)} fluid placeholder={t('enterFromAddress')} value={binCode}/>
            <div className={"btnsGroup"}>
                <Button className={"menuBtn"} primary size={"big"} onClick={handleOkOnClick}>Ok</Button>
                <div className={"flex flexCenter"}>
                    <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                    <GoBackToStartBtn/>
                </div>
            </div>
        </div>
    );
};

export default ChangeBin
