const initialState = {
    user: {},
    isLoggedIn: false,
    clientCode: "",
    eSess: "",
    sessionRefreshTimestamp: null,
    logOutTimestamp: null,
    logOutWarningShown: false
};

export const verifyUserReducer = (state = initialState, action) => {
    switch(action.type) {
        case "VERIFY_USER_SUCCESS":
            return {
                ...state,
                user: action.payload.user,
                isLoggedIn: true,
                clientCode: action.payload.clientCode,
                eSess: action.payload.eSess,
                sessionRefreshTimestamp: action.payload.sessionRefreshTimestamp,
                logOutTimestamp: action.payload.logOutTimestamp
            };
        case "LOGOUT":
            return {
                ...state
            };
        case "LOG_OUT_WARNING_SHOWN":
            return {
                ...state,
                logOutWarningShown: action.payload.logOutWarningShown
            };
        default:
            return state;
    }
};
