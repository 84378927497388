const initialState = {
    productGroups: [],
};

export const getProductGroupsReducer = (state = initialState, action) => {
    switch(action.type) {
        case "GET_PRODUCT_GROUPS_SUCCESS":
            return {
                ...state,
                productGroups: action.payload
            };
        default:
            return state;
    }
};
