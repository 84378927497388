import React, {useState, useEffect, useRef} from 'react';
import {Button, Dropdown, Input, Label, Table} from "semantic-ui-react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {componentSet} from "../actions/component";
import GoBackToStartBtn from "./GoBackToStartBtn";
import {errorMessageSet, successMessageSet} from "../actions/headerMessage";
import {makeErplyRequest, responseHasErrors} from "../util/erplyRequests";
import {setAddedAmounts, setSelectedBin} from "../actions/replenishment";
import {setModal} from "../actions/modal";
import GoBackBtn from "./GoBackBtn";
import {isNotAPositiveInteger, translateBinFromEng} from "../util/misc";
import {setInformativeModal} from "../actions/informativeModal";

const ReplenishmentProductSelected = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const amountInput = useRef(null);

    const [amount, setAmount] = useState("");
    const [binID, setBinID] = useState("");
    const [binCodeDropdownOpen, setBinCodeDropdownOpen] = useState(false);

    const selectedBin = useSelector(state => state.replenishmentReducer.selectedBin);
    const addedAmounts = useSelector(state => state.replenishmentReducer.addedAmounts);
    const cart = useSelector(state => state.getBinsReducer.cart);   // An intermediary bin holding products between scanning and confirming replenishment
    const user = useSelector(state => state.verifyUserReducer.user);
    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);
    const language = useSelector(state => state.languageReducer.language);
    const bins = useSelector(state => state.replenishmentReducer.binsAvailableForReplenishment);

    useEffect(() => {
        amountInput.current.focus();
    }, []);

    const createProductInfoTable = () => {
        return <Table color={"grey"} celled inverted unstackable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{t("code")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("product")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("bin")}</Table.HeaderCell>
                    <Table.HeaderCell>{t("replenishmentAmount")}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>{selectedBin.code}</Table.Cell>
                    <Table.Cell>{selectedBin.name}</Table.Cell>
                    <Table.Cell>{selectedBin.binCode}</Table.Cell>
                    <Table.Cell>{getReplenishmentAmount()}</Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    };

    const getReplenishmentAmount = () => {
        let amountAdded = 0;
        for (let i = 0, n = addedAmounts.length; i < n; i++) {
            amountAdded += addedAmounts[i].amount;
        }

        const maxAmountToAdd = selectedBin.maximumAmount != 0 ? selectedBin.maximumAmount - selectedBin.amount : "∞";

        return amountAdded + "/" + maxAmountToAdd;
    };

    const handleGoBackOnClick = () => {
        addedAmounts.length === 0 ? goBack() : dispatch(setModal(t("confirmation"), t("confirmGoBackReplenishment"), () => restoreOriginalBinQuantities().then(goBack)))
    };

    const goBack = () => {
        dispatch(componentSet("Replenishment"));
    };

    const restoreOriginalBinQuantities = () => {
        let requestCounter = 1;
        const params = {request: "saveBinRecords"};

        for (let i = 0, n = addedAmounts.length; i < n; i++) {
            // Add back to bin
            params[`binID${requestCounter}`] = addedAmounts[i].binID;
            params[`productID${requestCounter}`] = selectedBin.productID;
            params[`amount${requestCounter}`] = addedAmounts[i].amount;
            params[`creatorID${requestCounter}`] = user.employeeID;

            requestCounter ++;

            // Remove from cart
            params[`binID${requestCounter}`] = cart.binID;
            params[`productID${requestCounter}`] = selectedBin.productID;
            params[`amount${requestCounter}`] = addedAmounts[i].amount * (-1);
            params[`creatorID${requestCounter}`] = user.employeeID;

            requestCounter ++;
        }

        return dispatch(makeErplyRequest(params, t("saveBinRecordsError"))).then(() => {
            dispatch(setAddedAmounts([]));
            return dispatch(setInformativeModal(t("putProductsBack"), ""));
        });
    };

    const validateFields = () => {
        if (binID === "") {
            dispatch(errorMessageSet(t("enterBin")));
        } else {
            if (isNotAPositiveInteger(amount)) {
                dispatch(errorMessageSet(t("notAPositiveInteger")));
            } else if (amountTooBig()) {
                dispatch(errorMessageSet(t("amountExceedsAmountInBin")));
            } else {
                Promise.all([addAmountToCart(), removeAmountFromBin()]).then(() => {
                    getBinQuantities().then((quantities) => {
                        updateBinAmounts(quantities);
                        addToScannedProducts();
                    });
                });
            }
        }
    };

    const amountTooBig = () => {
        return selectedBin.amountsInUnpreferredBins.find(amount => amount.binID == binID).amount < amount;
    };

    const addAmountToCart = () => {
        const params = {
            request: "saveBinRecords",
            binID1: cart.binID,
            productID1: selectedBin.productID,
            amount1: amount,
            creatorID1: user.employeeID
        };

        return dispatch(makeErplyRequest(params, t("saveBinRecordsError")));
    };

    const removeAmountFromBin = () => {
        const params = {
            request: "saveBinRecords",
            binID1: binID,
            productID1: selectedBin.productID,
            amount1: amount * (-1),
            creatorID1: user.employeeID
        };

        return dispatch(makeErplyRequest(params, t("saveBinRecordsError")));
    };

    const getBinQuantities = () => {
        const binIDs = selectedBin.amountsInUnpreferredBins.map(amount => amount.binID).join(",");

        const params = {
            request: "getBinQuantities",
            binIDs: binIDs,
            productIDs: selectedBin.productID,
            warehouseID: selectedWarehouse.warehouseID,
        };

        return dispatch(makeErplyRequest(params, t("saveBinRecordsError")));
    };

    const updateBinAmounts = (quantities) => {
        const selectedBinCopy = Object.assign({}, selectedBin);
        for (let i = 0, n = quantities.length; i < n; i++) {
            selectedBinCopy.amountsInUnpreferredBins.find(amount => amount.binID == quantities[i].binID).amount = quantities[i].amount;
        }

        dispatch(setSelectedBin(selectedBinCopy));
    };

    const addToScannedProducts = () => {
        const binCode = selectedBin.amountsInUnpreferredBins.find(amount => amount.binID == binID).binCode;
        const newAmount = {binID: binID, binCode: binCode, amount: Number(amount)};
        dispatch(setAddedAmounts([...addedAmounts, newAmount]));
    };

    const handleConfirmOnClick = () => {
        if (addedAmounts.length === 0) {
            dispatch(errorMessageSet(t("noScannedProducts")));
            return;
        }
        dispatch(setModal(t("confirmation"), t("confirmReplenishment?"), confirmReplenishment));
    };

    const confirmReplenishment = () => {
        let amountsSum = 0;
        for (let i = 0, n = addedAmounts.length; i < n; i++) {
            amountsSum += addedAmounts[i].amount;
        }

        const params = {
            request: "saveBinRecords",
            // Add to selected bin
            binID1: selectedBin.binID,
            productID1: selectedBin.productID,
            amount1: amountsSum,
            creatorID1: user.employeeID,
            // Remove from cart
            binID2: cart.binID,
            productID2: selectedBin.productID,
            amount2: amountsSum * (-1),
            creatorID2: user.employeeID
        };

        dispatch(makeErplyRequest(params, t("saveBinRecordsError"))).then((response) => {
            if (!responseHasErrors(response)) {
                bins.find(bin => bin.binID == selectedBin.binID).amount += amountsSum;
                dispatch(successMessageSet(t("binReplenished"), 5000));
                dispatch(setAddedAmounts([]));
                dispatch(componentSet("Replenishment"));
            }
        });
    };

    const createBinTable = (createRows) => {
        const colSpan = createRows === createTakenFromTableRows ? 2 : 1;

        return <Table color={"grey"} celled inverted unstackable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{t("bin")}</Table.HeaderCell>
                    <Table.HeaderCell colSpan={colSpan}>{t("amount")}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {createRows()}
            </Table.Body>
        </Table>
    };

    const createTakeFromTableRows = () => {
        return selectedBin.amountsInUnpreferredBins.map((amount, index) => createTakeFromTableRow(amount, index));
    };

    const createTakeFromTableRow = (amount, index) => {
        if (amount.binCode !== "cart") {    // Filter out cart
            return <Table.Row key={index}>
                <Table.Cell onClick={() => handleBinCodeCellOnClick(amount.binID)}>{translateBinFromEng(amount.binCode, language)}</Table.Cell>
                <Table.Cell onClick={() => handleAmountCellOnClick(amount.amount)}>{amount.amount}</Table.Cell>
            </Table.Row>
        } else {
            return null;
        }
    };

    const handleBinCodeCellOnClick = (binID) => {
        setBinID(binID);
        setBinCodeDropdownOpen(false);
    };

    const handleAmountCellOnClick = (amount) => {
        setAmount(amount);
    };

    const createTakenFromTableRows = () => {
        return addedAmounts.map((amount, index) => createTakenFromTableRow(amount, index));
    };

    const createTakenFromTableRow = (amount, index) => {
        return <Table.Row key={index}>
            <Table.Cell>{translateBinFromEng(amount.binCode, language)}</Table.Cell>
            <Table.Cell>{amount.amount}</Table.Cell>
            <Table.Cell className={"smallCell"}><Button icon={"remove"} size={"mini"} onClick={() => onDeleteRowButtonClick(amount)}/></Table.Cell>
        </Table.Row>
    };

    const onDeleteRowButtonClick = (amount) => {
        dispatch(setModal(t("confirmation"), t("confirmDelete?"), () => deleteRow(amount)));
    };

    const deleteRow = (amount) => {
        addBackToBin(amount).then(() => {
            getBinQuantities().then((quantities) => {
                removeAmountFromAddedAmounts(amount);
                updateBinAmounts(quantities);
                dispatch(successMessageSet(t("putProductsBack"), 5000));
            });
        });
    };

    const removeAmountFromAddedAmounts = (amount) => {
        let addedAmountsCopy = addedAmounts.slice();

        const index = addedAmountsCopy.findIndex(addedAmount => addedAmount.binID === amount.binID && addedAmount.amount === amount.amount);
        addedAmountsCopy.splice(index, 1);

        dispatch(setAddedAmounts(addedAmountsCopy));
    };

    const addBackToBin = (amount) => {
        const addedAmount = addedAmounts.find(addedAmount => addedAmount.binID === amount.binID && addedAmount.amount === amount.amount);

        const params = {
            request: "saveBinRecords",
            // Add back to bin
            binID1: addedAmount.binID,
            productID1: selectedBin.productID,
            amount1: addedAmount.amount,
            creatorID1: user.employeeID,
            // Remove from cart
            binID2: cart.binID,
            productID2: selectedBin.productID,
            amount2: addedAmount.amount * (-1),
            creatorID2: user.employeeID,
        };

        return dispatch(makeErplyRequest(params, t("saveBinRecordsError")));
    };

    const handleOnBinCodeInput = (searchString) => {
        searchString !== "" ? setBinCodeDropdownOpen(true) : setBinCodeDropdownOpen(false);
    };

    const handleBinCodeOnChange = (event, { value }) => {
        setBinID(value);
        setBinCodeDropdownOpen(false);
    };

    const handleBinCodeOnBlur = () => {
        setBinCodeDropdownOpen(false);
    };

    const getBinCodeDropdownOptions = () => {
        return selectedBin.amountsInUnpreferredBins.map(amount => getBinCodeOption(amount))
    };

    const getBinCodeOption = (amount) => {
        return {key: amount.binID, text: translateBinFromEng(amount.binCode, language), value: amount.binID};
    };

    return (
        <div>
            {createProductInfoTable()}
            <div className={"searchRow"}>
                <Label className={"searchLabel"} size={"large"} pointing={"right"}>{t("amount")}</Label>
                <Input className={"searchInput fullWidth"} ref={amountInput} placeholder={t("enterAmount")} type="number" onInput={e => setAmount(e.target.value)} fluid value={amount}/>
            </div>
            <div className={"searchRow"}>
                <Label className={"searchLabel searchLabelAlign"} size={"large"} pointing={"right"}>{t("bin")}</Label>
                <Dropdown
                    className={"fullWidth maxWidth"}
                    placeholder={t("enterBin")}
                    selection
                    open={binCodeDropdownOpen}
                    onInput={e => handleOnBinCodeInput(e.target.value)}
                    options={getBinCodeDropdownOptions()}
                    search
                    onBlur={handleBinCodeOnBlur}
                    onChange={handleBinCodeOnChange}
                    noResultsMessage={t("noResultsFound")}
                    value={binID}
                />
            </div>
            <div className={"btnsGroup flex flexCenter"}>
                <Button className={"menuBtnHalfWidth"} primary size={"large"} onClick={validateFields}>Ok</Button>
                <Button className={"menuBtnHalfWidth"} color={"green"} size={"large"} onClick={handleConfirmOnClick}>{t("confirm")}</Button>
            </div>
            <h3 className={"white"}>{t("takeFromBin")}</h3>
            {createBinTable(createTakeFromTableRows)}
            <h3 className={"white"}>{t("takenFromBins")}</h3>
            {createBinTable(createTakenFromTableRows)}
            <div className={"flex flexCenter"}>
                <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                <GoBackToStartBtn displayWarning={addedAmounts.length > 0} warning={t("confirmGoBackReplenishment")} onYes={restoreOriginalBinQuantities}/>
            </div>
        </div>
    );
};

export default ReplenishmentProductSelected
