export const setServiceEndpoints = (serviceEndpoints) => {
    return {
        type: "SET_SERVICE_ENDPOINTS",
        payload: serviceEndpoints
    }
};

export const setMailboxes = (mailboxes) => {
    return {
        type: "SET_MAILBOXES",
        payload: mailboxes
    }
};

export const setCafaConfParameters = (conf) => {
    return {
        type: "SET_CAFA_CONF_PARAMETERS",
        payload: conf
    }
};
