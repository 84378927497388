import React, {useState, useRef} from 'react';
import {Button, Dropdown, Label} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {componentSet} from "../actions/component";
import {makeErplyRequest} from "../util/erplyRequests";
import {
    getCustomersSuccess, setReceivers
} from "../actions/getCustomers";
import {errorMessageSet} from "../actions/headerMessage";
import GoBackToStartBtn from "./GoBackToStartBtn";
import GoBackBtn from "./GoBackBtn";
import {filterOutDocumentsWithNoRows, formatDate} from "../util/misc";
import DatePickerComponent from "./DatePickerComponent";
import SupplierSearchInput from "./SupplierSearchInput";
import {setSearchResultDocuments} from "../actions/searchDocuments";

const ReturnProducts = () => {
    const customerDropdown = useRef(null);
    const receiverDropdown = useRef(null);

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [customerDropdownOpen, setCustomerDropdownOpen] = useState(false);
    const [receiverDropdownOpen, setReceiverDropdownOpen] = useState(false);
    const [selectedCustomerID, setSelectedCustomerID] = useState("");
    const [selectedReceiverID, setSelectedReceiverID] = useState("");
    const [supplierID, setSupplierID] = useState("");

    const customers = useSelector(state => state.getCustomersReducer.customers);
    const receivers = useSelector(state => state.getCustomersReducer.receivers);
    const componentSequence = useSelector(state => state.componentReducer.componentSequence);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isPurchaseReturn = componentSequence.includes("ProductsOut");

    const handleGoBackOnClick = () => {
        dispatch(componentSet(isPurchaseReturn ? "ProductsOut" : "ProductsIn"));
    };

    const getCustomers = (searchString, isGetReceivers) => {
        if (searchString !== "") {
            const dropdown = isGetReceivers ? receiverDropdown : customerDropdown;
            dropdown.current.state.value = "";

            const params = {
                request: "getCustomers",
                searchName: searchString,
                searchFromMiddle: 1
            };

            const successAction = isGetReceivers ? setReceivers : getCustomersSuccess;
            dispatch(makeErplyRequest(params, t("getCustomersError"), null, successAction, null)).then((customers) => {
                const setDropdownOpenFunction = isGetReceivers ? setReceiverDropdownOpen : setCustomerDropdownOpen;
                if (customers.length > 0 && dropdown.current.state.value === "") {
                    setDropdownOpenFunction(true);
                } else {
                    setDropdownOpenFunction(false);
                }
            });
        }
    };

    const handleOnCustomerChange = (event, { value }) => {
        setSelectedCustomerID(value);
        setCustomerDropdownOpen(false);
    };

    const handleOnReceiverChange = (event, { value }) => {
        setSelectedReceiverID(value);
        setReceiverDropdownOpen(false);
    };

    const getCustomerOptions = (areReceivers) => {
        const customersArray = areReceivers ? receivers : customers;
        return customersArray.map(customer => getCustomerOption(customer));
    };

    const getCustomerOption = (customer) => {
        return {key: customer.customerID, text: customer.fullName, value: customer.customerID};
    };

    const searchInvoices = async () => {
        const params = {
            request: isPurchaseReturn ? "getPurchaseDocuments" : "getSalesDocuments",
            type: isPurchaseReturn ? "PRCRETURN" : "CREDITINVOICE",
            getRowsForAllInvoices: 1,
            confirmed: 0
        };

        if (selectedCustomerID !== "") {
            params.clientID = selectedCustomerID;
        }
        if (selectedReceiverID !== "") {
            params.shipToID = selectedReceiverID;
        }
        if (fromDate !== null) {
            params.dateFrom = formatDate(fromDate);
        }
        if (toDate !== null) {
            params.dateTo = formatDate(toDate);
        }
        if (supplierID !== "") {
            params.supplierID = supplierID;
        }

        let documents = await dispatch(makeErplyRequest(params, t(isPurchaseReturn ? "getPurchaseDocumentsError" : "getSalesDocumentsError"), null, null, null, true));
        documents = filterOutDocumentsWithNoRows(documents);

        if (documents.length === 0) {
            dispatch(errorMessageSet(t("criteriaDocsNotFound")));
        } else {
            dispatch(setSearchResultDocuments(documents));
            dispatch(componentSet("ReturnsListConfirmation"));
        }
    };

    const handleCustomerOnBlur = () => {
        setCustomerDropdownOpen(false);
    };

    const handleReceiverOnBlur = () => {
        setReceiverDropdownOpen(false);
    };

    const createCustomerInput = (customersAreReceivers) => {
        const labelText = customersAreReceivers ? "receiver" : "customer";
        const dropdownOpenValue = customersAreReceivers ? receiverDropdownOpen : customerDropdownOpen;
        const onChangeFunction = customersAreReceivers ? handleOnReceiverChange : handleOnCustomerChange;
        const onBlurFunction = customersAreReceivers ? handleReceiverOnBlur : handleCustomerOnBlur;
        const dropdownRef = customersAreReceivers ? receiverDropdown : customerDropdown;

        return (
            <div className={"searchRow"}>
                <Label className={"searchLabel searchLabelShort"} size={"large"} pointing={"right"}>{t(labelText)}</Label>
                <Dropdown
                    fluid
                    className={"searchInput searchInputLong"}
                    placeholder={t("enterName")}
                    selection
                    open={dropdownOpenValue}
                    onInput={e => getCustomers(e.target.value, customersAreReceivers)}
                    options={getCustomerOptions(customersAreReceivers)}
                    search
                    onChange={onChangeFunction}
                    noResultsMessage={t("noResultsFound")}
                    onBlur={onBlurFunction}
                    ref={dropdownRef}
                    clearable
                />
            </div>
        )
    };

    return (
        <div>
            <DatePickerComponent labelText={"dateFrom"} value={fromDate} setStateFunction={setFromDate} className={"searchInput"}/>
            <DatePickerComponent labelText={"dateTo"} value={toDate} setStateFunction={setToDate} className={"searchInput"}/>
            {isPurchaseReturn ? <SupplierSearchInput setSupplierID={setSupplierID}/> : createCustomerInput(false)}
            {isPurchaseReturn ? "" : createCustomerInput(true)}
            <div className={"btnsGroup"}>
                <Button className={"menuBtn"} color={"blue"} size={"big"} onClick={searchInvoices}>{t("search")}</Button>
                <div className={"flex flexCenter"}>
                    <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                    <GoBackToStartBtn/>
                </div>
            </div>
        </div>
    );
};

export default ReturnProducts
