import React from 'react';
import {Button} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Builder} from "xml2js";
import {errorMessageSet, successMessageSet} from "../actions/headerMessage";
import {makeErplyBulkRequest, makeErplyRequest} from "../util/erplyRequests";
import {setSelectedDocumentsAttribute} from "../actions/scan";
import {setModal} from "../actions/modal";
import {akTc2000LocationInWarehouseId} from "../util/constants";
import {setIsLoading} from "../actions/component";
import {documentIsCreditInvoice, getApiAttributeValue, makeRequest, simpleLog} from "../util/misc";

const SaadaKappiButton = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const selectedDocuments = useSelector(state => state.scanReducer.selectedDocuments);
    const componentSequence = useSelector(state => state.componentReducer.componentSequence);
    const selectedDocumentsProducts = useSelector(state => state.scanReducer.selectedDocumentsProducts);

    const isInventoryTransfer = componentSequence.includes("InventoryTransfer");
    const isOutScan = componentSequence.includes("ProductsOut");
    const isSalesOrder = isOutScan && !isInventoryTransfer;
    const isCreditInvoice = documentIsCreditInvoice(componentSequence);
    const attributeName = "warehouseStatus";
    const attributeValue = "sentToWMS";

    const handleOnClick = () => {
        dispatch(setModal(t("confirmation"), "Kas oled kindel, et soovid kappi saata?", sendToTC2000));
    };

    const sendToTC2000 = async () => {
        const orderNumber = isInventoryTransfer ? selectedDocuments[0].inventoryTransferNo : isSalesOrder ? selectedDocuments[0].number : selectedDocuments[0].id;
        const xml = await getXml(orderNumber);
        console.log("XML sent to TC2000", xml);
        const fileName = isSalesOrder || isCreditInvoice ? `${orderNumber}.ord` : isInventoryTransfer ? `${orderNumber}.inv` : `${orderNumber}.pur`;
        const folderName = isCreditInvoice ? "Purchase orders_Plus" : "Orders_Plus";

        const ftpResponse = await sendToAkFtp(xml, `${folderName}/${fileName}`);
        dispatch(setIsLoading(false));
        simpleLog(JSON.stringify({folderName: folderName, fileName: fileName, xml: xml, ftpResponse: ftpResponse}));

        if (ftpResponse === "Succesfully saved to ftp!") {
            const saveAttributeResponse = await saveWarehouseStatusAttribute();

            if (saveAttributeResponse.status !== "error") {
                dispatch(setSelectedDocumentsAttribute(attributeName, attributeValue));
                dispatch(successMessageSet("Kappi saadetud!"));
            }
        } else {
            dispatch(errorMessageSet(`Kappi saatmine ebaõnnestus! Ftp vastus: ${ftpResponse}`));
        }
    };

    const saveWarehouseStatusAttribute = () => {
        let params = {attributeName0: attributeName, attributeValue0: attributeValue};

        if (isInventoryTransfer) {
            params.request = "saveInventoryTransfer";
            params.inventoryTransferID = selectedDocuments[0].inventoryTransferID;
            return dispatch(makeErplyRequest(params, t("saveInventoryTransferError")));
        } else if (isSalesOrder || isCreditInvoice) {
            params.request = "saveSalesDocument";
            params.id = selectedDocuments[0].id;
            return dispatch(makeErplyRequest(params, t("saveSalesDocumentError")));
        } else {
            let requests = [];
            for (let i = 0, n = selectedDocuments.length; i < n; i++) {
                const hasBeenSentToTC2000 = getApiAttributeValue("warehouseStatus", selectedDocuments[i].attributes) !== false;

                if (!hasBeenSentToTC2000) {
                    let request = Object.assign({}, params);
                    request.requestName = "savePurchaseDocument";
                    request.id = selectedDocuments[i].id;
                    request.requestID = i;
                    requests.push(request);
                }
            }
            return dispatch(makeErplyBulkRequest(requests, t("savePurchaseDocumentError")));
        }
    };

    const sendToAkFtp = (xml, fileName) => {
        const body = JSON.stringify({
            xml: xml,
            fileName: fileName
        });

        // return makeRequest("http://localhost/wms/services/sendToAkFtp.php", "POST", body, null, dispatch);
        return makeRequest("./services/sendToAkFtp.php", "POST", body, null, dispatch);
    };

    const getXml = async (orderNumber) => {
        let rows = [];
        for (let i = 0, n = selectedDocuments.length; i < n; i++) {
            const hasBeenSentToTC2000 = getApiAttributeValue(attributeName, selectedDocuments[i].attributes) === attributeValue;

            if (!hasBeenSentToTC2000) {
                for (let j = 0, n = selectedDocuments[i].rows.length; j < n; j++) {
                    if (selectedDocuments[i].rows[j].productID > 0) {
                        const product = selectedDocumentsProducts.find(product => product.productID == selectedDocuments[i].rows[j].productID);

                        if (product && (product.locationInWarehouseText === "TC2000" || product.locationInWarehouseIDs == akTc2000LocationInWarehouseId)) {
                            let amount = Number(isCreditInvoice ? -1 * selectedDocuments[i].rows[j].amount : selectedDocuments[i].rows[j].amount);

                            if (isInventoryTransfer) {
                                // This attribute is set by a BO plugin
                                const invTransferPackageAmounts = getApiAttributeValue("warehouseIdToInvTransferPackageAmount", product.longAttributes);

                                if (invTransferPackageAmounts) {
                                    const invTransferPackageAmount = Number(JSON.parse(invTransferPackageAmounts)[selectedDocuments[0].warehouseToID]);

                                    if (invTransferPackageAmount > 0 && amount % invTransferPackageAmount !== 0) {
                                        amount += (invTransferPackageAmount - (amount % invTransferPackageAmount));
                                    }
                                }
                            }

                            const rowXml = {
                                ProductID: String(product.productID).substr(0, 25),
                                ProductName: product.name.substr(0, 40),
                                Code: product.code.substr(0, 25),
                                EAN: product.code2.substr(0, 25),
                                Weight: String(1000 * product.netWeight).substr(0, 8),
                                Unit: product.unitName ? product.unitName.substr(0, 5) : "",
                                ManufacturerCode: product.supplierCode.substr(0, 25),
                                PickLineInfoShow: `${product.code} [${product.supplierCode}]`.substr(0, 100),
                                [isCreditInvoice ? "OrderedQty" : "OrderedAmount"]: String(amount).substr(0, 8)
                            };
                            rows.push({[isCreditInvoice ? "PutAwayLine" : "PickLine"]: rowXml});
                        }
                    }
                }
            }
        }

        let customerName = "";
        if (isSalesOrder || isCreditInvoice) {
            customerName = selectedDocuments[0].clientName;
        } else if (isInventoryTransfer) {
            const warehouses = await dispatch(makeErplyRequest({request: "getWarehouses"}, t("getWarehousesError"), null, null, null, true));
            const warehouseTo = warehouses.find(warehouse => warehouse.warehouseID == selectedDocuments[0].warehouseToID);
            customerName = warehouseTo.name;
        }

        const xmlElements = {
            OrderNumber: String(orderNumber).substr(0, 20),
            [isCreditInvoice ? "Supplier" : "CustomerName"]: customerName.substr(0, 40),
            [isCreditInvoice ? "PutAwayLines" : "PickLines"]: rows
        };

        if (isCreditInvoice) {
            xmlElements.Warehouse = selectedDocuments[0].warehouseName.substr(0, 40);
        }

        const xmlObject = {[isCreditInvoice ? "PurchaseOrderData" : "OrderData"]: xmlElements};
        const builder = new Builder();
        return builder.buildObject(xmlObject);
    };

    return <Button className={"menuBtn"} color={"olive"} onClick={handleOnClick} size='big'>Saada kappi</Button>;
};

export default SaadaKappiButton
