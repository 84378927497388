import {componentSet, setIsLoading, setSequence} from "../actions/component";
import {Button, Checkbox, Dropdown, Icon, Input, Table, TextArea, Transition} from "semantic-ui-react";
import React, {useState, useRef, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import GoBackToStartBtn from "./GoBackToStartBtn";
import {errorMessageSet, successMessageSet} from "../actions/headerMessage";
import {
    getReasonCodes, getSalesDocumentArDataset,
    makeErplyBulkRequest,
    makeErplyRequest, makePimApiRequest, responseHasErrors, sendEmail
} from "../util/erplyRequests";
import {setModal} from "../actions/modal";
import {getCustomersSuccess} from "../actions/getCustomers";
import {getWarehousesSuccess} from "../actions/getWarehouses";
import GoBackBtn from "./GoBackBtn";
import {
    commaSeparatedCodesIncludeCode,
    confirmBinAmountWithUser,
    formatDate,
    getCafaConfParameterValue,
    getConfParameterValue,
    getGetProductsCodeOrder,
    isInfralinkSelfService,
    isNotAPositiveInteger,
    playSound,
    scanIsOutScan,
    translateBinToEng
} from "../util/misc";
import {setInformativeModal} from "../actions/informativeModal";
import {getSuppliersSuccess} from "../actions/getSuppliers";
import {isInfralink, isTAF} from "../util/isClient";
import {setScannedProducts} from "../actions/createDocument";
import DatePickerComponent from "./DatePickerComponent";
import {sendToActualReportsService} from "../util/printRequests";

const transitionTime = 200;

const CreateDocument = () => {
    const scanInput = useRef(null);
    const amountInput = useRef(null);
    const binInput = useRef(null);
    const invoiceNoInput = useRef(null);
    const additionalFieldDropdown = useRef(null);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);
    const user = useSelector(state => state.verifyUserReducer.user);
    const documentType = useSelector(state => state.createDocumentReducer.documentType);
    const scannedProductsAllDocumentTypes = useSelector(state => state.createDocumentReducer.scannedProducts);
    const suppliers = useSelector(state => state.getSuppliersReducer.suppliers);
    const customers = useSelector(state => state.getCustomersReducer.customers);
    const warehouses = useSelector(state => state.getWarehousesReducer.warehouses);
    const reasonCodes = useSelector(state => state.getReasonCodesReducer.reasonCodes);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const language = useSelector(state => state.languageReducer.language);
    const receivingArea = useSelector(state => state.getBinsReducer.receivingArea);
    const clientCode = useSelector(state => state.verifyUserReducer.clientCode);
    const cafaConfParameters = useSelector(state => state.newErplyApisReducer.cafaConfParameters);
    const componentSequence = useSelector(state => state.componentReducer.componentSequence);
    const isRapidOutScan = isTAF(clientCode) ? confParameters.wmsRapidOutScan == 1 : getCafaConfParameterValue(cafaConfParameters, "wmsRapidOutScan", user) == 1;
    const isRapidInScan = isTAF(clientCode) ? confParameters.wmsRapidInScan == 1 : getCafaConfParameterValue(cafaConfParameters, "wmsRapidInScan", user) == 1;
    const searchCodeFromCommaSeparatedCodes = confParameters.api_get_product_use_new_fulltext_index == 1;
    const isILSelfService = dispatch(isInfralinkSelfService());

    // Products grouped together by productID (AND binID if bins are used, same product with different binID is grouped separately)
    const scannedProducts = scannedProductsAllDocumentTypes.hasOwnProperty(documentType) ? scannedProductsAllDocumentTypes[documentType] : [];

    let binsEnabled = confParameters.wmsUseProductLocationsInWarehouse == 1;
    const binsAreUsed = (documentType === "purchaseInvoice" || documentType === "waybill" || documentType === "writeOff" || documentType === "returnWaybill" || documentType === "purchaseReturn" || documentType === "inventoryTransfer") && binsEnabled;
    const createBinField = (documentType === "waybill" || documentType === "writeOff" || documentType === "purchaseReturn" || documentType === "inventoryTransfer") && binsEnabled;
    const defaultAmount = (scanIsOutScan(componentSequence) && isRapidOutScan) || (!scanIsOutScan(componentSequence) && isRapidInScan) ? 1 : "";

    const [scannedCode, setScannedCode] = useState("");
    const [amount, setAmount] = useState(defaultAmount);
    const [binCode, setBinCode] = useState("");
    const [invoiceNo, setInvoiceNo] = useState("");
    const [date, setDate] = useState(null);
    const [confirmInvoice, setConfirmInvoice] = useState(false);
    const [sumRows, setSumRows] = useState(false);
    const [displaySaveDocView, setDisplayConfirmationView] = useState(false);
    const [additionalFieldDropdownOpen, setAdditionalFieldDropdownOpen] = useState(false);
    const [additionalFieldValue, setAdditionalFieldValue] = useState(null);
    const [showDocInfoFields, setShowDocInfoFields] = useState(false);
    const [notes, setNotes] = useState("");
    const [internalNotes, setInternalNotes] = useState("");
    const [packageDesc, setPackageDesc] = useState("");
    const [contactPersonID, setContactPersonID] = useState(null);

    useEffect(() => {
        scanInput.current.focus();
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown);
        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    });

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            if (isRapidOutScan || isRapidInScan) {
                if (createBinField && binCode === "") {
                    binInput.current.select();
                } else {
                    validateFields();
                }
            } else {
                amountInput.current.select();
            }
        }
    };

    const handleOkOnClick = () => {
        displaySaveDocView ? saveDocument() : validateFields();
    };

    const saveDocument = async () => {
        if (additionalFieldValue === null) {
            if (documentType === "inventoryTransferOrder" || documentType === "inventoryTransfer") {
                return dispatch(errorMessageSet(t("selectWarehouse")));
            } else if (documentType === "waybill" || documentType === "returnWaybill") {
                return dispatch(errorMessageSet(t("selectCustomer")));
            } else if (documentType === "writeOff") {
                return dispatch(errorMessageSet(t("selectReason")));
            } else if (documentType === "purchaseInvoice" || documentType === "purchaseReturn") {
                return dispatch(errorMessageSet(t("selectSupplier")));
            }
        } else if (scannedProducts.length === 0) {
            return dispatch(errorMessageSet(t("noScannedProducts")));
        } else if ((documentType === "purchaseInvoice" || documentType === "purchaseReturn") && invoiceNo === "") {
            return dispatch(errorMessageSet(t("enterDocNo")));
        } else if (isILSelfService && notes === "") {
            const errorMessage = `${t("pleaseFillIn")} "${t("infralinkNotesLabel")}"`;
            return dispatch(errorMessageSet(errorMessage));
        } else if (isILSelfService && contactPersonID === null) {
            const errorMessage = `${t("pleaseFillIn")} "${t("contactPerson")}"`;
            return dispatch(errorMessageSet(errorMessage));
        }

        const summedScannedProducts = getSummedProducts();

        if (documentType === "inventoryTransferOrder" || documentType === "inventoryTransfer") {
            let params = {
                request: "saveInventoryTransfer",
                creatorID: user.employeeID,
                warehouseFromID: selectedWarehouse.warehouseID,
                warehouseToID: additionalFieldValue.warehouseID,
                type: documentType === "inventoryTransferOrder" ? "TRANSFER_ORDER" : "TRANSFER"
            };

            params = addDocumentRows(params, summedScannedProducts);

            if (notes !== "") {
                params.notes = notes;
            }

            dispatch(makeErplyRequest(params, t("saveInventoryTransferError"))).then((response) => {
                displayInventoryTransferNo(response); // Display created inventory transfer order number(s)
            });
        } else if (documentType === "waybill") {
            const apiDocumentType = confParameters.wmsCreatedSalesDocument === "fromClientCard" ? getCustomerPreferredSalesDocument() : confParameters.wmsCreatedSalesDocument;

            const params = {
                request: "saveSalesDocument",
                type: apiDocumentType,
                warehouseID: selectedWarehouse.warehouseID,
                customerID: additionalFieldValue.customerID,
                employeeID: user.employeeID,
                confirmInvoice: confParameters.wmsConfirmSalesDocuments,
                euInvoiceType: additionalFieldValue.euCustomerType
            };

            addDocumentRows(params, summedScannedProducts);

            if (notes !== "") params.notes = notes;
            if (internalNotes !== "") params.internalNotes = internalNotes;
            if (packageDesc !== "") params.packingUnitsDescription = packageDesc;
            if (contactPersonID !== null) params.contactID = contactPersonID;
            if (apiDocumentType === "INVWAYBILL" && additionalFieldValue.paymentDays != 0) params.paymentDays = additionalFieldValue.paymentDays;

            dispatch(makeErplyRequest(params, t("saveSalesDocumentError"))).then((response) => {
                if (response.status !== "error") {
                    if (confParameters.wmsConfirmSalesDocuments == 1) {
                        dispatch(successMessageSet(`${t("waybillSaved")} ${t("withNo")} ${response[0].invoiceNo}!`));   // Display a success message with the saved waybill number
                    } else {
                        dispatch(successMessageSet(`${t("waybillSaved")}!`));   // Display a success message without the saved document number
                    }

                    if (isILSelfService) sendCreatedDocumentEmail(response, apiDocumentType);

                    goBack();
                }
            });
        } else if (documentType === "returnWaybill") {
            let params = {
                request: "saveSalesDocument",
                type: "WAYBILL",
                warehouseID: selectedWarehouse.warehouseID,
                customerID: additionalFieldValue.customerID,
                employeeID: user.employeeID,
                confirmInvoice: confParameters.wmsConfirmSalesDocuments,
                euInvoiceType: additionalFieldValue.euCustomerType
            };

            // Add rows
            params = addDocumentRows(params, summedScannedProducts, true, true);

            if (notes !== "") {
                params.notes = notes;
            }
            if (internalNotes !== "") {
                params.internalNotes = internalNotes;
            }
            if (packageDesc !== "") {
                params.packingUnitsDescription = packageDesc;
            }

            dispatch(makeErplyRequest(params, t("saveSalesDocumentError"))).then((response) => {
                if (response.status !== "error") {
                    if (confParameters.wmsConfirmSalesDocuments == 1) {
                        dispatch(successMessageSet(`${t("waybillSaved")} ${t("withNo")} ${response[0].invoiceNo}!`));   // Display a success message with the saved waybill number
                    } else {
                        dispatch(successMessageSet(`${t("waybillSaved")}!`));   // Display a success message without the saved document number
                    }

                    goBack();
                }
            });
        } else if (documentType === "writeOff") {
            let params = {
                request: "saveInventoryWriteOff",
                creatorID: user.employeeID,
                warehouseID: selectedWarehouse.warehouseID,
                confirmed: confParameters.wmsConfirmWriteOffs,
                reasonID: additionalFieldValue.reasonID
            };

            // Add rows
            params = addDocumentRows(params, summedScannedProducts);

            if (notes !== "") {
                params.comments = notes;
            }

            dispatch(makeErplyRequest(params, t("saveInventoryWriteOffError"))).then((response) => {
                if (response.status !== "error") {
                    if (confParameters.wmsConfirmWriteOffs == 1) {
                        getWriteOff(response); // Get created write-off number
                    } else {
                        dispatch(successMessageSet(`${t("writeOffSaved")}!`));   // Display a success message without the saved document number
                        goBack();
                    }
                }
            });
        } else if (documentType === "purchaseInvoice" || documentType === "purchaseReturn") {
            const type = documentType === "purchaseInvoice" ? "PRCINVOICE" : "PRCRETURN";

            let params = {
                request: "savePurchaseDocument",
                no: invoiceNo,
                warehouseID: selectedWarehouse.warehouseID,
                currencyCode: confParameters.default_currency,
                type: type,
                supplierID: additionalFieldValue.id,
                confirmInvoice: confirmInvoice ? 1 : 0,
                employeeID: user.employeeID
            };

            if (confirmInvoice) {
                params.stateID = 3 // Authorised
                // params.stateID = 4 // Fulfilled
            }

            // Add rows
            params = addDocumentRows(params, summedScannedProducts, documentType === "purchaseReturn", false, true, true);

            if (notes !== "") {
                params.notes = notes;
            }
            if (date !== null) {
                params.date = formatDate(date);
            }

            dispatch(makeErplyRequest(params, t("savePurchaseDocumentError"))).then((response) => {
                if (response.status !== "error") {
                    let successMessage = documentType === "purchaseInvoice" ? t("purchaseInvoiceSaved") : t("purchaseReturnSaved");
                    successMessage += ` ${t("withRegNo")} ${response[0].invoiceRegNo}!`;
                    dispatch(successMessageSet(`${successMessage}`));   // Display a success message with the saved document number
                    goBack();
                }
            });
        }
    };

    const sendCreatedDocumentEmail = async (saveDocumentResponse, documentType) => {
        const templateID = dispatch(getConfParameterValue("wmsSalesDocumentTemplateID"));
        if (templateID === "") return;

        const arDataset = await dispatch(getSalesDocumentArDataset(t, saveDocumentResponse, additionalFieldValue));
        const actualReportsResponse = await dispatch(sendToActualReportsService(clientCode, JSON.stringify(arDataset), templateID, confParameters));

        if (!responseHasErrors(actualReportsResponse)) {
            const subject = `${t(documentType)} ${t("numberShort")} ${saveDocumentResponse[0].invoiceNo}`;
            const body = `${additionalFieldValue.fullName}\n\nTäname Teid ostu eest OÜ Infralink ${selectedWarehouse.name} asuvast iseteeninduslaost!\n\nAntud ${t(documentType).toLowerCase()} kinnitab, et olete soovitud kauba kätte saanud. Pretensioonide puhul palume pöörduda meie poole kirjalikult aadressi info@infralink.ee kaudu 24h jooksul, lisades viite ${t(documentType).toLowerCase()} numbri ja pretensiooni kirjeldusega.`;
            const fileName = `${t(documentType)}_${saveDocumentResponse[0].invoiceNo}.pdf`;

            if (isInfralink(clientCode)) dispatch(sendEmail(t, subject, body, "info@infralink.ee", actualReportsResponse, fileName));
            const contactPerson = additionalFieldValue.contactPersons.find(contactPerson => contactPerson.contactPersonID == contactPersonID);
            dispatch(sendEmail(t, subject, body, contactPerson?.email, actualReportsResponse, fileName));
        }
    };

    const addDocumentRows = (params, summedScannedProducts, negativeAmounts = false, add0Discount = false, addPrice = false, addVatRate = false) => {
        if (confParameters.wmsSortProductsOnCreatedDocumentBy === "code") {
            summedScannedProducts.sort((a, b) => a.code.localeCompare(b.code));
        }

        for (let i = 0, n = summedScannedProducts.length; i < n; i++) {
            params[`productID${i}`] = summedScannedProducts[i].productID;
            params[`amount${i}`] = negativeAmounts ? summedScannedProducts[i].amount * (-1) : summedScannedProducts[i].amount;

            if (add0Discount) { // This will use product card price instead of the current price in the selected location (store) applied to the selected customer, according to price lists
                params[`discount${i}`] = 0;
            }
            if (addPrice) {
                params[`price${i}`] = summedScannedProducts[i][confParameters.wmsCreatePurchaseInvoiceRowPrice];
            }
            if (addVatRate && additionalFieldValue.vatrate_id !== 0) {
                params[`vatrateID${i}`] = additionalFieldValue.vatrate_id;
            }
        }

        return params;
    };

    const getCustomerPreferredSalesDocument = () => {
        return additionalFieldValue.shipGoodsWithWaybills == 1 ? "WAYBILL" : "INVWAYBILL";
    };

    const getWriteOff = async (saveInventoryWriteOffResponse) => {
        const params = {
            request: "getInventoryWriteOffs",
            inventoryWriteOffID: saveInventoryWriteOffResponse[0].inventoryWriteOffID
        };

        let successMessage = t("writeOffSaved");
        const writeOffs = await dispatch(makeErplyRequest(params, t("getInventoryWriteOffsError")));

        if (!responseHasErrors(writeOffs) && writeOffs.length > 0) {
            successMessage += ` ${t("withNo")} ${writeOffs[0].inventoryWriteOffNo}`;
        }

        dispatch(successMessageSet(successMessage));
        goBack();
    };

    const displayInventoryTransferNo = async (saveInventoryTransferResponse) => {
        const params = {
            request: "getInventoryTransfers",
            inventoryTransferID: saveInventoryTransferResponse[0].inventoryTransferID,
        };

        let successMessage = t(documentType === "inventoryTransfer" ? "inventoryTransferSaved" : "inventoryTransferOrderSaved");
        const inventoryTransfers = await dispatch(makeErplyRequest(params, t("getInventoryTransfersError")));

        if (!responseHasErrors(inventoryTransfers) && inventoryTransfers.length > 0) {
            successMessage += ` ${t("withNo")} ${inventoryTransfers[0].inventoryTransferNo}`;
        }

        dispatch(successMessageSet(successMessage));
        goBack();
    };

    const validateFields = () => {
        if (scannedCode === "") {
            dispatch(errorMessageSet(t("noItemsScanned")));
        } else if (createBinField && binCode === "") {
            dispatch(errorMessageSet(t("enterBin")));
        } else {
            if (isNotAPositiveInteger(amount)) {
                dispatch(errorMessageSet(t("notAPositiveInteger")));
            } else {
                checkIfBinAndProductExist();
            }
        }
    };

    const checkIfBinAndProductExist = () => {
        let requests = [getProduct()];
        if (createBinField) {
            requests.push(getBin())
        }

        dispatch(setIsLoading(true));
        Promise.all(requests).then((data) => {
            dispatch(setIsLoading(false));
            const product = data[0];

            if (product === null) {
                return dispatch(errorMessageSet(t("noProductFound")));
            } else if (product.type === "MATRIX") {
                return dispatch(errorMessageSet(t("matrixProductsMayNotBeAdded")));
            }

            if (createBinField) {
                const bin = data[1];

                if (bin === null) {
                    return dispatch(errorMessageSet(t("noBinFound")));
                }

                getBinQuantities(product, bin);
            } else if ((documentType === "purchaseInvoice" || documentType === "returnWaybill" || documentType === "purchaseReturn") && binsEnabled) {
                confirmScan(product, receivingArea);
            } else if (documentType === "inventoryTransferOrder" || !binsEnabled) {
                addToScannedProducts(product)
            } else {
                dispatch(errorMessageSet("Something went wrong. No document type conditions were met."));
            }
        });
    };

    const getBinQuantities = (product, bin) => {
        const params = {
            request: "getBinQuantities",
            binIDs: bin.binID,
            warehouseID: selectedWarehouse.warehouseID,
            productIDs: product.productID
        };

        dispatch(makeErplyRequest(params, t("getBinQuantitiesError"))).then((binQuantities) => {
            checkIfAmountExistsInBin(binQuantities, product, bin);
        });
    };

    const checkIfAmountExistsInBin = (binQuantities, product, bin) => {
        binQuantities.length > 0 && binQuantities[0].amount >= amount ? confirmScan(product, bin, binQuantities[0].amount) : dispatch(errorMessageSet(t("scannedAmountExceedsAvailableAmountInBin")));
    };

    const confirmScan = (product, bin, currentAmountInBin = null) => {
        const params = {
            request: "saveBinRecords",
            binID1: bin.binID,
            productID1: product.productID,
            amount1: documentType === "purchaseInvoice" || documentType === "returnWaybill" ? amount : amount * (-1),
            creatorID1: user.employeeID
        };

        dispatch(makeErplyRequest(params, t("saveBinRecordsError"))).then((response) => {
            if (response.status !== "error") {
                const addToScannedProductsFunction = () => {
                    addToScannedProducts(product, bin);
                };

                if (currentAmountInBin !== null) {  // Is out scan with bins
                    const newAmountInBin = currentAmountInBin - amount;
                    dispatch(confirmBinAmountWithUser(t, newAmountInBin, bin.binID, product.productID, addToScannedProductsFunction));
                } else {
                    addToScannedProductsFunction();
                }
            }
        });
    };

    const addToScannedProducts = (product, bin) => {
        product.amount = Number(amount);

        if (binsAreUsed) {
            product.binID = bin.binID;
            product.binCode = bin.code;
        }

        const products = [product];
        // Add containers
        if (documentType === "waybill" && product.containerID != 0) {
            const containerAmount = Number(amount) * product.containerAmount;
            const container = {
                productID: product.containerID,
                amount: containerAmount,
                binID: 0,
                name: product.containerName
            };
            products.push(container);
        }

        dispatch(setScannedProducts(documentType, [...scannedProducts, ...products]));

        clearScannedCode();
        setAmount(defaultAmount);
        setBinCode("");
        playSound("success");
    };

    const clearScannedCode = () => {
        setScannedCode("");
        scanInput.current.focus();
    };

    const getSummedProducts = () => {
        let summedScannedProducts = [];
        let productExistsInSummedScannedProducts = false;

        for (let i = 0, n = scannedProducts.length; i < n; i++) {
            productExistsInSummedScannedProducts = false;

            for (let j = 0, n = summedScannedProducts.length; j < n; j++) {
                if (scannedProducts[i].productID === summedScannedProducts[j].productID &&
                    !(scannedProducts[i].hasOwnProperty(confParameters.wmsCreatePurchaseInvoiceRowPrice) &&
                        scannedProducts[i][confParameters.wmsCreatePurchaseInvoiceRowPrice] !== summedScannedProducts[j][confParameters.wmsCreatePurchaseInvoiceRowPrice])) {
                    summedScannedProducts[j].amount += Number(scannedProducts[i].amount);
                    productExistsInSummedScannedProducts = true;
                    break;
                }
            }

            if (!productExistsInSummedScannedProducts) {
                summedScannedProducts.push(JSON.parse(JSON.stringify(scannedProducts[i])));
            }
        }

        return summedScannedProducts;
    };

    const getBin = () => {
        const params = {
            request: "getBins",
            warehouseID: selectedWarehouse.warehouseID,
            code: translateBinToEng(binCode, language)
        };

        return dispatch(makeErplyRequest(params, t("getBinsError"), null, null, null, false, false)).then((bins) => {
            return bins.length > 0 ? bins[0] : null;
        });
    };

    const getProduct = async () => {
        // Field name order by which products are requested by using the field name in parameters
        const getProductsCodeOrder = getGetProductsCodeOrder(confParameters);

        let requests = [];
        for (let i = 0, n = searchCodeFromCommaSeparatedCodes ? 1 : getProductsCodeOrder.length; i < n; i++) {
            const request = {
                requestName: "getProducts",
                active: 1
            };

            if (documentType === "purchaseInvoice" || documentType === "purchaseReturn") {
                request.getFIFOCost = 1;
                request.warehouseID = selectedWarehouse.warehouseID;
            } else if (documentType === "waybill") {
                request.getContainerInfo = 1;
            }

            if (searchCodeFromCommaSeparatedCodes) {
                request.searchNameIncrementally = scannedCode;
                request.searchCodeFromMiddle = 1;
            } else {
                request[getProductsCodeOrder[i]] = scannedCode;
            }

            requests.push(request)
        }

        const getProductsBulkResponse = await dispatch(makeErplyBulkRequest(requests, t("getProductsError"), null, null, null, false));

        for (let i = 0, n = getProductsBulkResponse.length; i < n; i++) {
            if (getProductsBulkResponse[i].records && getProductsBulkResponse[i].records.length > 0) {
                let product = null;

                if (searchCodeFromCommaSeparatedCodes) {
                    for (let k = 0, n = getProductsCodeOrder.length; k < n; k++) {
                        for (let j = 0, n = getProductsBulkResponse[i].records.length; j < n; j++) {
                            if (commaSeparatedCodesIncludeCode(getProductsBulkResponse[i].records[j][getProductsCodeOrder[k]], scannedCode)) {
                                product = getProductsBulkResponse[i].records[j];
                                break;
                            }
                        }
                    }
                } else {
                    product = getProductsBulkResponse[i].records[0];
                }

                return product;
            }
        }

        return null;
    };

    const handleGoBackOnClick = () => {
        if (displaySaveDocView) {
            setDisplayConfirmationView(false);
            setAdditionalFieldDropdownOpen(false);
            setAdditionalFieldValue(null);
            setContactPersonID(null);

            setTimeout(() => {
                scanInput.current.focus();
            }, transitionTime)
        } else {
            if (scannedProducts.length > 0 && binsAreUsed) {
                dispatch(setModal(t("warning"), t("confirmGoBack"), () => restoreOriginalBinQuantities().then(goBack)));
            } else {
                goBack();
            }
        }
    };

    const restoreOriginalBinQuantities = () => {
        let requestCounter = 1;
        const params = {request: "saveBinRecords"};

        for (let i = 0, n = scannedProducts.length; i < n; i++) {
            if (scannedProducts[i].binID !== 0) {  // Not a product container
                params[`binID${requestCounter}`] = scannedProducts[i].binID;
                params[`productID${requestCounter}`] = scannedProducts[i].productID;
                params[`amount${requestCounter}`] = documentType === "purchaseInvoice" || documentType === "returnWaybill" ? -1 * scannedProducts[i].amount: scannedProducts[i].amount;
                params[`creatorID${requestCounter}`] = user.employeeID;

                requestCounter ++;
            }
        }

        return dispatch(makeErplyRequest(params, t("saveBinRecordsError"))).then(() => {
            dispatch(setScannedProducts(documentType, []));
            return dispatch(setInformativeModal(t("putProductsBack"), ""));
        });
    };

    const goBack = () => {
        dispatch(setScannedProducts(documentType, []));
        if (documentType === "purchaseInvoice" || documentType === "returnWaybill") {
            dispatch(componentSet("ProductsIn"));
        } else {
            const component = isILSelfService ? "ChooseWarehouse" : "ProductsOut";
            dispatch(componentSet(component));
            if (isILSelfService) dispatch(setSequence(["ChooseWarehouse"]));
        }
    };

    const handleAmountInputOnClick = () => {
        amountInput.current.select();
    };

    const handleBinInputOnClick = () => {
        binInput.current.select();
    };

    const handleCreateDocumentOnClick = () => {
        if (scannedProducts.length === 0) {
            dispatch(errorMessageSet(t("noScannedProducts")));
            return;
        }

        setDisplayConfirmationView(true);
        setTimeout(() => {
            document.getElementById("additionalField").focus();
        }, transitionTime);

        if (documentType === "inventoryTransferOrder" || documentType === "inventoryTransfer") {
            getWarehouses();
        } else if (documentType === "writeOff") {
            dispatch(getReasonCodes(t, true));
        }
    };

    const getSuppliers = async (name) => {
        if (name.length > 2) {
            const filter = encodeURIComponent(`[["name","contains","${name}"],"and",["supplier_type_id","!=","3"]]`);   // Filter out contacts of suppliers
            const suppliers = await dispatch(makePimApiRequest(t, `v1/supplier?filter=${filter}`, "GET"));
            dispatch(getSuppliersSuccess(suppliers));
            setAdditionalFieldDropdownOpen(true);
        } else {
            setAdditionalFieldDropdownOpen(false);
        }
    };

    const displayScannedProducts = () => {
        if (scannedProducts.length !== 0) {
            return (
                <Table color={"grey"} celled inverted unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell className={"createDocumentHeaderCell"}>{t("product")}</Table.HeaderCell>
                            {documentType === "purchaseInvoice" || documentType === "purchaseReturn" ?
                                <Table.HeaderCell className={"createDocumentHeaderCell"}>{t("purchasePrice")}</Table.HeaderCell> : ""}
                            <Table.HeaderCell className={"createDocumentHeaderCell"} colSpan={2}>{t("amount")}</Table.HeaderCell>
                        </Table.Row>
                        {createTotalsRow()}
                    </Table.Header>
                    <Table.Body>
                        {createScannedRows()}
                    </Table.Body>
                </Table>
            )
        }
    };

    const createTotalsRow = () => {
        const getTotalAmount = () => {
            return scannedProducts.reduce((acc, product) => acc + product.amount, 0);
        };

        const getTotalPrice = () => {
            return scannedProducts.reduce((acc, product) => acc + Number(product[confParameters.wmsCreatePurchaseInvoiceRowPrice]), 0);
        };

        return (
            <Table.Row>
                <Table.HeaderCell/>
                {documentType === "purchaseInvoice" || documentType === "purchaseReturn" ?
                    <Table.HeaderCell className={"createDocumentTotalCell"}>{t("total")}: {getTotalPrice()}</Table.HeaderCell> : ""}
                <Table.HeaderCell className={"createDocumentTotalCell"} colSpan={2}>{t("total")}: {getTotalAmount()}</Table.HeaderCell>
            </Table.Row>
        )
    };

    const createScannedRows = () => {
        const products = sumRows ? getSummedProducts() : scannedProducts;
        return products.map((product, index) => createScannedRow(product, index));
    };

    const createScannedRow = (product, index) => {
        if (product.amount != 0) {
            return (
                <Table.Row className={"createDocumentRow"} key={index}>
                    <Table.Cell>{product.name}</Table.Cell>
                    {documentType === "purchaseInvoice" || documentType === "purchaseReturn" ? <Table.Cell onClick={() => changePrice(product)}>{product[confParameters.wmsCreatePurchaseInvoiceRowPrice]}</Table.Cell> : ""}
                    <Table.Cell>{product.amount}</Table.Cell>
                    <Table.Cell className={"smallCell"}><Button icon={"remove"} size={"mini"} onClick={() => onDeleteRowButtonClick(product)}/></Table.Cell>
                </Table.Row>
            )
        }
    };

    const changePrice = (product) => {
        if (confParameters.wmsEnableSettingCreatePurchaseInvoiceRowPrice == 1) {
            dispatch(setModal(t("enterPrice"), "", (price) => {
                price = String(price).replace(",", ".");
                if (isNaN(price) || price < 0) {
                    dispatch(errorMessageSet(t("valueMustNotBeNegative")));
                } else {
                    product[confParameters.wmsCreatePurchaseInvoiceRowPrice] = price;

                    // If product is a matrix variation, change price on all matrix variations with the same parent product
                    if (product.parentProductID) {
                        scannedProducts.forEach(scannedProduct => {
                            if (scannedProduct.parentProductID == product.parentProductID) {
                                scannedProduct[confParameters.wmsCreatePurchaseInvoiceRowPrice] = price;
                            }
                        });
                    }
                }
            }, true, product[confParameters.wmsCreatePurchaseInvoiceRowPrice]));
        }
    };

    const onDeleteRowButtonClick = (product) => {
        dispatch(setModal(t("confirmation"), t("confirmDelete?"), () => deleteRow(product)));
    };

    const deleteRow = (product) => {
        if (binsAreUsed && product.binID !== 0) {   // Not a product container
            addBackToBin(product);
        }

        const deleteAllRows = sumRows;  // Delete all rows with the deletable product
        let scannedProductsCopy = scannedProducts.slice();

        if (deleteAllRows) {
            scannedProductsCopy = scannedProductsCopy.filter(scannedProduct => scannedProduct.productID !== product.productID);
        } else {
            const index = (documentType === "waybill" || documentType === "writeOff") && binsEnabled ?
                scannedProductsCopy.findIndex(scannedProduct => scannedProduct.productID === product.productID &&
                scannedProduct.amount === product.amount && scannedProduct.binID === product.binID)
                :
                scannedProductsCopy.findIndex(scannedProduct => scannedProduct.productID === product.productID &&
                    scannedProduct.amount === product.amount);

            scannedProductsCopy.splice(index, 1);
        }

        dispatch(setScannedProducts(documentType, scannedProductsCopy));
    };

    const addBackToBin = (product) => {
        const params = {request: "saveBinRecords"};
        let requestCounter = 1;
        const addAllRows = sumRows;  // Add back all rows' amounts with the product

        if (addAllRows) {
            for (let i = 0, n = scannedProducts.length; i < n; i++) {
                if (scannedProducts[i].productID == product.productID) {
                    params[`binID${requestCounter}`] = scannedProducts[i].binID;
                    params[`productID${requestCounter}`] = scannedProducts[i].productID;
                    params[`amount${requestCounter}`] = documentType === "purchaseInvoice" || documentType === "returnWaybill" ? -1 * scannedProducts[i].amount : scannedProducts[i].amount;
                    params[`creatorID${requestCounter}`] = user.employeeID;

                    requestCounter ++;
                }
            }
        } else {
            const addedProduct = scannedProducts.find(scannedProduct => scannedProduct.productID === product.productID &&
                scannedProduct.amount === product.amount && scannedProduct.binID === product.binID);

            params[`binID${requestCounter}`] = addedProduct.binID;
            params[`productID${requestCounter}`] = addedProduct.productID;
            params[`amount${requestCounter}`] = documentType === "purchaseInvoice" || documentType === "returnWaybill" ? -1 * addedProduct.amount : addedProduct.amount;
            params[`creatorID${requestCounter}`] = user.employeeID;
        }

        dispatch(makeErplyRequest(params, t("saveBinRecordsError")));
    };

    const getAdditionalData = (searchString) => {
        if (searchString !== "") {
            additionalFieldDropdown.current.state.value = "";
            if (documentType === "inventoryTransferOrder" || documentType === "inventoryTransfer" || documentType === "writeOff") {
                setAdditionalFieldDropdownOpen(true);
            } else if (documentType === "waybill" || documentType === "returnWaybill") {
                getCustomers(searchString);
            } else if (documentType === "purchaseInvoice" || documentType === "purchaseReturn") {
                getSuppliers(searchString);
            }
        }
    };

    const getWarehouses = () => {
        const params = {request: "getWarehouses"};
        return dispatch(makeErplyRequest(params, t("getWarehousesError"), null, getWarehousesSuccess, null));
    };

    const getCustomers = (searchString) => {
        const params = {
            request: "getCustomers",
            searchName: searchString,
            searchFromMiddle: 1
        };

        if (documentType === "waybill") params.responseMode = "detail";
        if (isILSelfService) params.getContactPersons = 1;

        dispatch(makeErplyRequest(params, t("getCustomersError"), null, getCustomersSuccess, null)).then((customers) => {
            if (customers.length > 0 && additionalFieldDropdown.current.state.value === "") {
                setAdditionalFieldDropdownOpen(true);
            } else {
                setAdditionalFieldDropdownOpen(false);
            }
        });
    };

    const handleOnAdditionalFieldChange = (event, { value }) => {
        let additionalFieldValue;

        if (documentType === "inventoryTransferOrder" || documentType === "inventoryTransfer") {
            additionalFieldValue = warehouses.find(warehouse => warehouse.warehouseID === value);
        } else if (documentType === "waybill" || documentType === "returnWaybill") {
            additionalFieldValue = customers.find(customer => customer.customerID === value);
            setContactPersonID(null);
        } else if (documentType === "writeOff") {
            additionalFieldValue = reasonCodes.find(reasonCode => reasonCode.reasonID === value);
        } else if (documentType === "purchaseInvoice" || documentType === "purchaseReturn") {
            additionalFieldValue = suppliers.find(supplier => supplier.id === value);
        }

        setAdditionalFieldValue(additionalFieldValue);
        setAdditionalFieldDropdownOpen(false);
    };

    const getAdditionalFieldDropdownOptions = () => {
        if (documentType === "inventoryTransferOrder" || documentType === "inventoryTransfer") {
            return warehouses.filter(warehouse => warehouse.warehouseID !== selectedWarehouse.warehouseID)  // Filter out selected warehouse from options
                .map(warehouse => getWarehouseOption(warehouse))
        } else if (documentType === "waybill" || documentType === "returnWaybill") {
            return customers.map(customer => getCustomerOption(customer));
        } else if (documentType === "writeOff") {
            return reasonCodes.map(reasonCode => getReasonCodeOption(reasonCode));
        } else if (documentType === "purchaseInvoice" || documentType === "purchaseReturn") {
            return suppliers.map(supplier => getSupplierOption(supplier));
        }
    };

    const getSupplierOption = (supplier) => {
        return {key: supplier.id, text: supplier.name, value: supplier.id};
    };

    const getCustomerOption = (customer) => {
        return {key: customer.customerID, text: customer.fullName, value: customer.customerID};
    };

    const getWarehouseOption = (warehouse) => {
        return {key: warehouse.warehouseID, text: warehouse.name, value: warehouse.warehouseID};
    };

    const getReasonCodeOption = (reasonCode) => {
        return {key: reasonCode.reasonID, text: reasonCode.name, value: reasonCode.reasonID};
    };

    const handleAdditionalFieldOnBlur = () => {
        setAdditionalFieldDropdownOpen(false);
    };

    const getBinField = () => {
        if (createBinField) {
            return (
                <div>
                    <label className={"inScanLabel"}>{t("bin")}:</label>
                    <Input ref={binInput} onClick={handleBinInputOnClick} onInput={e => setBinCode(e.target.value)} fluid value={binCode}/>
                </div>
            )
        }
    };

    const getPlaceholderText = () => {
        if (documentType === "inventoryTransferOrder" || documentType === "inventoryTransfer") {
            return t("enterToWarehouse");
        } else if (documentType === "waybill" || documentType === "returnWaybill") {
            return t("enterCustomerName");
        } else if (documentType === "writeOff") {
            return t("enterReason");
        } else if (documentType === "purchaseInvoice" || documentType === "purchaseReturn") {
            return t("enterSupplierName");
        }
    };

    const handleAdditionalFieldOnClick = () => {
        if (documentType === "writeOff" || documentType === "inventoryTransferOrder" || documentType === "inventoryTransfer") {
            setAdditionalFieldDropdownOpen(true);
        }
    };

    const createDocInfoFields = () => {
        let notesFieldDescription;
        if (isILSelfService) notesFieldDescription = "infralinkNotesLabel";
        else if (documentType === "writeOff") notesFieldDescription = "comments";
        else if (documentType === "waybill" || documentType === "returnWaybill") notesFieldDescription = "notesOnInvoice";
        else notesFieldDescription = "notes";

        const extraFields = [
            <div key={"notes"}>
                <label className={"inScanLabel"}>{`${t(notesFieldDescription)}:`}</label>
                <TextArea className={"fullWidth"} onInput={e => setNotes(e.target.value)} value={notes}/>
            </div>
        ];

        if (!isILSelfService && (documentType === "waybill" || documentType === "returnWaybill")) {
            extraFields.push(
                <div key={"internalNotes"}>
                    <label className={"inScanLabel"}>{`${t('internalNotes')}:`}</label>
                    <TextArea className={"fullWidth"} onInput={e => setInternalNotes(e.target.value)} value={internalNotes}/>
                </div>,
                <div key={"packageDesc"}>
                    <label className={"inScanLabel"}>{`${t('packageDesc')}:`}</label>
                    <TextArea className={"fullWidth"} onInput={e => setPackageDesc(e.target.value)} value={packageDesc}/>
                </div>
            );
        }

        return (
            <Transition visible={displaySaveDocView && (isILSelfService || showDocInfoFields)} animation='zoom' duration={300}>
                <div id={"extraFields"}>
                    {extraFields}
                </div>
            </Transition>
        );
    };

    const createChangeDocInfoBtn = () => {
        if (!isILSelfService && displaySaveDocView) {
            return <Button className={"menuBtn"} color={"instagram"} size={"large"} onClick={() => setShowDocInfoFields(!showDocInfoFields)}>{t("changeDocInfo")}</Button>;
        }
    };

    const createBackButtons = () => {
        if (isILSelfService) {
            return <GoBackBtn addClassNames={["menuBtn"]} removeClassNames={["menuBtnHalfWidth"]} handleGoBackOnClick={handleGoBackOnClick} size={"large"}/>;
        } else {
            return (
                <div className={"flex flexCenter"}>
                    <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                    <GoBackToStartBtn displayWarning={scannedProducts.length > 0 && binsAreUsed} warning={t("confirmGoBack")} onYes={restoreOriginalBinQuantities}/>
                </div>
            );
        }
    };

    const createSumRowsCheckbox = () => {
        if (scannedProducts.length > 0) return <Checkbox className={"marginTop"} checked={sumRows} onChange={() => setSumRows(!sumRows)} label={<label className={"settingsCheckboxLabel"}>{t("sumRows")}</label>}/>
    };

    const handleSetAmount = (amount) => {
        const maxAmountAllowedToScan = confParameters.wmsMaxAmountAllowedToScan;

        if (maxAmountAllowedToScan !== "" && amount > Number(maxAmountAllowedToScan)) {
            setAmount(0);
        } else {
            setAmount(amount);
        }
    };

    const createContactPersonField = () => {
        return <Transition visible={isILSelfService && displaySaveDocView && additionalFieldValue !== null} animation='slide right' duration={transitionTime} unmountOnHide>
            <div className={"marginTop"}>
                <label className={"inScanLabel"}>{`${t("contactPerson")}:`}</label>
                <Dropdown
                    placeholder={t("chooseContactPerson")}
                    selection
                    options={getContactPersonDropdownOptions()}
                    search
                    onChange={handleContactPersonChange}
                    noResultsMessage={t("noResultsFound")}
                    value={contactPersonID}
                    className={"fullWidth"}
                />
            </div>
        </Transition>
    };

    const getContactPersonDropdownOptions = () => {
        if (!isILSelfService || additionalFieldValue === null) return [];
        return additionalFieldValue?.contactPersons?.map(contactPerson => getContactPersonOption(contactPerson));
    };

    const getContactPersonOption = (contactPerson) => {
        return {key: contactPerson.contactPersonID, text: contactPerson.fullName, value: contactPerson.contactPersonID};
    };

    const handleContactPersonChange = (event, { value }) => {
        setContactPersonID(value);
    };

    return (
        <div id={"createDocument"}>
            <Transition visible={displaySaveDocView} animation='slide right' duration={transitionTime} unmountOnHide>
                <Dropdown
                    placeholder={getPlaceholderText()}
                    selection
                    open={additionalFieldDropdownOpen}
                    onInput={e => getAdditionalData(e.target.value)}
                    options={getAdditionalFieldDropdownOptions()}
                    search
                    onChange={handleOnAdditionalFieldChange}
                    noResultsMessage={t("noResultsFound")}
                    searchInput={{ id: "additionalField", className: "fullHeight" }}
                    onBlur={handleAdditionalFieldOnBlur}
                    ref={additionalFieldDropdown}
                    onClick={handleAdditionalFieldOnClick}
                />
            </Transition>
            {createContactPersonField()}
            {documentType === "purchaseInvoice" || documentType === "purchaseReturn" ?
                <Transition visible={displaySaveDocView} animation='slide right' duration={transitionTime} unmountOnHide>
                    <div>
                        <label className={"inScanLabel"}>{t("docNo")}:</label>
                        <Input ref={invoiceNoInput} onInput={e => setInvoiceNo(e.target.value)} fluid placeholder={t('enterDocNo')} value={invoiceNo}/>
                        <label className={"inScanLabel"}>{t("date")}:</label>
                        <DatePickerComponent value={date} setStateFunction={setDate} className={"createDocumentDatePicker"}/>

                        {confParameters.wmsEnableConfirmingDirectlyCreatedPurchaseInvoices == 1 ?
                            <Checkbox className={"marginTop"} checked={confirmInvoice} onChange={() => setConfirmInvoice(!confirmInvoice)}
                                      label={<label className={"settingsCheckboxLabel marginBottom0"}>{t("confirmInvoice")}</label>}/> :
                            ""
                        }
                    </div>
                </Transition> : ""
            }
            {isILSelfService ? createDocInfoFields() : ""}
            <Transition visible={!displaySaveDocView} animation='slide right' duration={transitionTime}>
                <div>
                    <Input ref={scanInput} onInput={e => setScannedCode(e.target.value)} fluid placeholder={t('scan')} value={scannedCode}
                           icon={<Icon name='delete' link onClick={clearScannedCode}/>} autoComplete="off"/>
                    <label className={"inScanLabel"}>{t("amount")}:</label>
                    <Input ref={amountInput} type="number" onClick={handleAmountInputOnClick} onInput={e => handleSetAmount(e.target.value)} fluid value={amount}/>
                    {getBinField()}
                </div>
            </Transition>
            <div className={displaySaveDocView ? "marginTop" : "marginTop flexCenter"}>
                <Button className={displaySaveDocView ? "menuBtn" : "menuBtnHalfWidth"} primary size={"large"} onClick={handleOkOnClick}>{displaySaveDocView ? `${t("confirm")} ${t(documentType)}`.toUpperCase() : "Ok"}</Button>
                <Transition visible={!displaySaveDocView} animation='slide right' duration={transitionTime}>
                    <Button className={"menuBtnHalfWidth margin0Right"} color={"green"} size={"large"} onClick={handleCreateDocumentOnClick}>{`${t("create")} ${t(documentType)}`}</Button>
                </Transition>
            </div>
            {createChangeDocInfoBtn()}
            {isILSelfService ? "" : createDocInfoFields()}
            {createSumRowsCheckbox()}
            {displayScannedProducts()}
            {createBackButtons()}
        </div>
    )
};

export default CreateDocument
