import React, { useState, useEffect, useMemo } from 'react';
import {
    Dropdown,
    Input,
    Table,
    Transition
} from 'semantic-ui-react'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {errorMessageSet, successMessageSet} from "../actions/headerMessage";
import {
    getBinRecords,
    makeErplyBulkRequest,
    makeErplyRequest, makeJsonApiRequest
} from "../util/erplyRequests";
import {componentSet, setIsLoading} from "../actions/component";
import GoBackToStartBtn from "./GoBackToStartBtn";
import {
    setBinsContainingDocumentProducts,
    setFollowUpDocuments,
    setScannedProducts,
    setSummedScannedProducts,
    setScannedProductsByDocument,
    setNewAmountToProductInRowsWithSummedProducts,
    setCurrentSessionScannedProducts
} from "../actions/scan";
import {
    getBinQuantitiesSuccess,
    setBinQuantitiesOfProductNotOnDocument
} from "../actions/getBinQuantities";
import GoBackBtn from "./GoBackBtn";
import {
    documentIsCreditInvoice,
    documentIsSalesOrder,
    getDocumentType,
    getIdFieldName,
    getProductProperty,
    playSound,
    roundFloatingPointError,
    rowHasTc2000Product,
    setFollowUpDocumentsScannedAmountsFromJsonAttributes,
    translateAccordingToCountry,
    translateBinFromEng,
    getSummedScannedProducts,
    documentIsPurchaseOrder,
    getScannedProducts,
    productsAreIdentical,
    rowIsBundle,
    isBundleComponent,
    bundleIsFullyScanned,
    differentiateProductsByPackageID,
    getLocationInWarehouseFromProductCard,
} from "../util/misc";
import {isAlasKuul, isRFix} from "../util/isClient";

const ActiveDocumentReadOnlyView = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const defaultPackaging = t("piece");

    const isAssembly = useSelector(state => state.scanReducer.isAssembly);  // Used for Products Out -> Assemble
    const componentsWithSummedAmounts = useSelector(state => state.scanReducer.componentsWithSummedAmounts);
    const rowsWithSummedProducts = useSelector(state => state.scanReducer.rowsWithSummedProducts);
    const productsOnDocument = useSelector(state => state.scanReducer.selectedDocumentsProducts);
    const recipeProducts = useSelector(state => state.scanReducer.recipeProducts);
    const summedRows = isAssembly ? componentsWithSummedAmounts : rowsWithSummedProducts;
    const selectedDocumentsProducts = isAssembly ? recipeProducts : productsOnDocument;
    const documentIDs = useSelector(state => state.scanReducer.documentIDs);
    const selectedDocuments = useSelector(state => state.scanReducer.selectedDocuments);
    const previousComponent = useSelector(state => state.componentReducer.previousComponent);
    const componentSequence = useSelector(state => state.componentReducer.componentSequence);
    const scannedProducts = useSelector(state => state.scanReducer.scannedProducts);
    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const modalMessage = useSelector(state => state.modalReducer.message);
    const modalHeading = useSelector(state => state.modalReducer.heading);
    const followUpDocuments = useSelector(state => state.scanReducer.followUpDocuments);
    // Supplier whose purchase invoices will be used to map scanned products after scan (Rehvid Pluss only)
    const scanBySupplier = useSelector(state => state.scanReducer.scanBySupplier);
    // Intermediary bin where products from a specific supplier's purchase invoices are scanned to and later removed after confirming scan (Rehvid Pluss only)
    const supplierBin = useSelector(state => state.scanReducer.supplierBin);
    const isInTransitTransfer = useSelector(state => state.scanReducer.isInTransitTransfer);

    // OutScan-only selectors
    const binQuantities = useSelector(state => state.getBinQuantitiesReducer.binQuantities);
    const binQuantitiesOfProductNotOnDocument = useSelector(state => state.getBinQuantitiesReducer.binQuantitiesOfProductNotOnDocument);
    const language = useSelector(state => state.languageReducer.language);
    const clientCode = useSelector(state => state.verifyUserReducer.clientCode);
    const binsContainingDocumentProducts = useSelector(state => state.scanReducer.binsContainingDocumentProducts);  // Needed to sort bins by their "order" field
    // Alas-Kuul-specific field; null - has not been set, true - products displayed are only TC2000 products, false - products displayed are only non-TC2000 products
    const isTc2000Only = useSelector(state => state.scanReducer.isTc2000Only);

    const isAK = isAlasKuul(clientCode);

    const isScanBySupplier = scanBySupplier !== null;   // Rehvid PLuss only
    const isOutScan = componentSequence.includes("ProductsOut");
    const isCreditInvoice = documentIsCreditInvoice(componentSequence);
    const isInventoryTransfer = componentSequence.includes("InventoryTransfer");
    const isSalesOrder = documentIsSalesOrder(componentSequence, isAssembly);
    const isPurchaseOrder = documentIsPurchaseOrder(componentSequence, isScanBySupplier);
    const enableBinOrder = confParameters.wmsEnableBinOrder == 1;
    const binsEnabled = confParameters.wmsUseProductLocationsInWarehouse == 1;
    const isFromTlnKeskladu = !isScanBySupplier && selectedDocuments[0].warehouseFromID == 2;    // AK only
    const isSaadaKappiDocument = isAK && (isSalesOrder || isCreditInvoice || (isOutScan && isInventoryTransfer && isFromTlnKeskladu));  // AK only
    const multiplePurchaseOrderScanEnabled = confParameters.wmsEnableMultiplePurchaseOrderScan == 1;
    const differentiateByPackageID = differentiateProductsByPackageID(componentSequence, isScanBySupplier, confParameters);

    useEffect(() => {
        syncQuantitiesOnDocument(selectedDocumentsProducts, false, true);
    }, []);

    const initializeSelectedBins = (selectedDocumentsProducts, binQuantities, binsContainingDocumentProducts) => {
        let selectedBins = [];
        for (let i = 0, n = selectedDocumentsProducts.length; i < n; i++) {
            let binsWithProduct = [];

            for (let j = 0, m = binQuantities.length; j < m; j++) {
                if (binQuantities[j].productID == selectedDocumentsProducts[i].productID && binQuantities[j].amount > 0) {
                    let binWithProduct = {binCode: binQuantities[j].binCode, binID: binQuantities[j].binID, order: 9999, preferred: binQuantities[j].binPreferred};

                    if (binsContainingDocumentProducts) {   // Sorting bins by order has been enabled
                        const binContainingDocumentProducts = binsContainingDocumentProducts.find(bin => bin.binID == binQuantities[j].binID);
                        if (binContainingDocumentProducts) {
                            binWithProduct.order = binContainingDocumentProducts.order <= 0 || binContainingDocumentProducts.order === "" ? 9999 : binContainingDocumentProducts.order;
                        }
                    }

                    binsWithProduct.push(binWithProduct);
                }
            }

            // Sort 1) by order 2) by preferred/unpreferred 3) alphabetically
            binsWithProduct.sort((a, b) => {
                const orderA = a.order;
                const orderB = b.order;
                const textA = a.binCode.toUpperCase();
                const textB = b.binCode.toUpperCase();
                return (orderA < orderB) ? -1 : (orderA > orderB) ? 1 :
                    (a.preferred > b.preferred) ? -1 : (a.preferred < b.preferred) ? 1 :
                        (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

            // Move receiving area to the end
            const receivingAreaIndex = binsWithProduct.findIndex(option => option.binCode === translateBinFromEng("receiving_area", language));
            if (receivingAreaIndex !== -1) {
                binsWithProduct.push(binsWithProduct.splice(receivingAreaIndex, 1)[0]);
            }

            // Set first bin as selected
            if (binsWithProduct.length > 0) {
                const selectedBin = {productID: selectedDocumentsProducts[i].productID, selectedBinID: binsWithProduct[0].binID, selectedBinCode: binsWithProduct[0].binCode, order: binsWithProduct[0].order};
                selectedBins.push(selectedBin);
            }
        }

        setRowSelectedBins(selectedBins);
    };

    const [rowSelectedBins, setRowSelectedBins] = useState([]);
    const [subtractedRowsSorted, setSubtractedRowsSorted] = useState(false);
    const [selectedBundleID, setSelectedBundleID] = useState(null); // Fulfillable orders only
    // Bundle amounts have been increased (in a previous scan session bundles' amounts were increased by the user and thus a larger amount of components was scanned)
    const [bundleAmountsAdjusted, setBundleAmountsAdjusted] = useState(false);

    const subtractScannedProducts = () => {
        let subtractedRows = [];
        const bundleIDsWithMissingAmounts = [];

        const getSubtractedAmount = (summedRow) => {
            let newAmount = summedRow.amount;

            scannedProducts.forEach(scannedProduct => {
                if (productsAreIdentical(componentSequence, selectedDocuments, summedRow, scannedProduct)) {
                    newAmount -= scannedProduct.amount;
                }
            });

            return newAmount;
        };

        // Calculate bundle amounts that need to be adjusted
        if (!bundleAmountsAdjusted && isSalesOrder) {
            for (let i = 0, n = summedRows.length; i < n; i++) {
                let newAmount = getSubtractedAmount(summedRows[i]);

                if (!bundleAmountsAdjusted && isSalesOrder && isBundleComponent(summedRows[i]) && newAmount < 0) {
                    // In a previous scan session bundle's amount was increased by the user and thus a larger amount of components was scanned
                    const existingBundleIdWithMissingAmount = bundleIDsWithMissingAmounts.find(amount => amount.productID == summedRows[i].bundleID);
                    const missingBundleAmount = Math.ceil(-1 * newAmount / summedRows[i].amountInBundle);

                    if (existingBundleIdWithMissingAmount) {
                        if (existingBundleIdWithMissingAmount.amount < missingBundleAmount) {
                            existingBundleIdWithMissingAmount.amount = missingBundleAmount;
                        }
                    } else {
                        bundleIDsWithMissingAmounts.push({productID: summedRows[i].bundleID, amount: missingBundleAmount});
                    }
                }
            }
        }

        for (let i = 0, n = summedRows.length; i < n; i++) {
            let newAmount = getSubtractedAmount(summedRows[i]);

            // Increment bundle component amounts if in a previous scan session bundle's amount was increased by the user
            if (!bundleAmountsAdjusted && isSalesOrder && isBundleComponent(summedRows[i])) {
                const bundleIdWithMissingAmount = bundleIDsWithMissingAmounts.find(bundle => bundle.productID == summedRows[i].bundleID);

                if (bundleIdWithMissingAmount) {
                    newAmount += Number(summedRows[i].amountInBundle * bundleIdWithMissingAmount.amount);
                }
            }

            if (roundFloatingPointError(newAmount) > 0) {
                subtractedRows.push({...summedRows[i], amount: roundFloatingPointError(newAmount)});
            }
        }

        // Increment bundle amounts if in a previous scan session bundle's amount was increased by the user
        if (bundleIDsWithMissingAmounts.length > 0) {
            const userDisplayedAdjustments = [];

            bundleIDsWithMissingAmounts.forEach(bundleIdWithMissingAmount => {
                const summedRow = summedRows.find(row => row.productID == bundleIdWithMissingAmount.productID);
                const newAmount = summedRow.amount + bundleIdWithMissingAmount.amount;
                userDisplayedAdjustments.push(`${summedRow.name}: ${summedRow.amount}->${newAmount}`);
                subtractedRows.find(row => row.productID == bundleIdWithMissingAmount.productID).amount = newAmount;
                dispatch(setNewAmountToProductInRowsWithSummedProducts(bundleIdWithMissingAmount.productID, newAmount, true));
            });

            const bundleAdjustmentsMessage = `${t("bundleAmountsHaveBeenAdjusted")}. ${userDisplayedAdjustments.join(", ")}`;
            dispatch(successMessageSet(bundleAdjustmentsMessage));
        }

        // Add products not on document
        for (let i = 0, n = scannedProducts.length; i < n; i++) {
            const productIsOnDocument = selectedDocumentsProducts.some(product => product.productID == scannedProducts[i].productID);

            if (!productIsOnDocument) {
                const productInSubtractedRows = subtractedRows.find(product => product.productID == scannedProducts[i].productID);

                if (!productInSubtractedRows) {
                    const productClone = Object.assign({}, scannedProducts[i]);
                    productClone.amount = productClone.amount * (-1);
                    productClone.productIsNotOnDocument = 1;
                    subtractedRows.push(productClone);
                } else {
                    productInSubtractedRows.amount -= Number(scannedProducts[i].amount);
                }
            }
        }

        // Set selectedBundleID to null if a bundle was fully scanned
        if (selectedBundleID !== null && bundleIsFullyScanned(subtractedRows, selectedBundleID)) {
            setSelectedBundleID(null);
        }

        setBundleAmountsAdjusted(true);
        return subtractedRows;
    };

    // Displayed rows with subtracted scanned products
    const [subtractedRows, setSubtractedRows] = useState([]);
    useEffect(() => {
        if (scannedProducts.length === 0) {
            setSubtractedRows(summedRows);
        } else {
            setSubtractedRows(subtractScannedProducts())
        }
    }, [scannedProducts, summedRows]);

    const [productIDsToLabelPrint, setProductIDsToLabelPrint] = useState([]);   // Products checked for label printing

    useEffect(() => {
        let newProductIDsToLabelPrint;
        if (subtractedRows.length > 0) {
            newProductIDsToLabelPrint = [...new Set(subtractedRows.map(row => row.productID))];
        } else {
            newProductIDsToLabelPrint = productIDsToLabelPrint.slice();
            for (let i = 0, n = newProductIDsToLabelPrint.length; i < n; i++) {
                let rowIDExists = false;

                for (let j = 0, n = subtractedRows.length; j < n; j++) {
                    if (newProductIDsToLabelPrint[i] === subtractedRows[j].productID) {
                        rowIDExists = true;
                    }
                }

                if (!rowIDExists) {
                    newProductIDsToLabelPrint.splice(i, 1);
                }
            }
        }

        setProductIDsToLabelPrint(newProductIDsToLabelPrint);
    }, [subtractedRows]);

    useEffect(() => {
        if (rowSelectedBins.length > 0 || !binsEnabled) {
            sortSubtractedRows()
        }
    }, [rowSelectedBins, subtractedRows]);

    // Sort displayable subtracted rows 1) by order 2) alphabetically by their selected bin code; Move "Scan later" rows to the end
    const sortSubtractedRows = () => {
        const getBinCode = (rowBin) => {
            if (rowBin === undefined) {
                // Product is not present in any address. It must be moved to the end of the displayed rows
                return "ZZZZZZZZZZ";
            } else {
                return rowBin.selectedBinCode.toUpperCase();
            }
        };
        const getBinOrder = (rowBin) => {
            return rowBin ? rowBin.order : Number.MAX_SAFE_INTEGER;
        };

        let sortedSubtractedRows = subtractedRows;
        if (binsEnabled) {
            sortedSubtractedRows = sortedSubtractedRows.sort((subtractedRowA, subtractedRowB) => {
                const rowABin = rowSelectedBins.find(selectedBin => selectedBin.productID == subtractedRowA.productID);
                const rowBBin = rowSelectedBins.find(selectedBin => selectedBin.productID == subtractedRowB.productID);
                const rowABinOrder = getBinOrder(rowABin);
                const rowBBinOrder = getBinOrder(rowBBin);
                const rowABinCode = getBinCode(rowABin);
                const rowBBinCode = getBinCode(rowBBin);

                return (rowABinOrder < rowBBinOrder) ? -1 : (rowABinOrder > rowBBinOrder) ? 1 : (rowABinCode < rowBBinCode) ? -1 : (rowABinCode > rowBBinCode) ? 1 : 0;
            });
        }

        setSubtractedRows(sortedSubtractedRows);
        setSubtractedRowsSorted(true);  // This is so that scannableDocRows would update
    };

    const syncQuantitiesOnDocument = (products, playSuccessSound = false, firstSync = false, localBinRecords = null) => {
        let requests = [];
        if (!localBinRecords) {
            requests.push(dispatch(getBinRecords(t, documentIDs, getDocumentType(componentSequence, isAssembly), isScanBySupplier ? supplierBin.binID : null)));
        }

        if (isOutScan && binsEnabled) {
            const productIDs = selectedDocumentsProducts.map(product => product.productID).join(",");
            requests.push(getBinQuantities(productIDs));
        }

        dispatch(setIsLoading(true));
        Promise.all(requests).then(async (data) => {
            const freshBinRecords = !localBinRecords ? data[0] : localBinRecords;

            if (isOutScan && binsEnabled) {
                const binQuantities = data[1];

                let bins = null;
                if (enableBinOrder) {
                    bins = firstSync ? await getBinsContainingDocumentProducts(binQuantities) : binsContainingDocumentProducts;
                }

                initializeSelectedBins(products, binQuantities, bins);
            }

            updateScannedProducts(freshBinRecords, products);
            dispatch(setIsLoading(false));

            if (playSuccessSound) {
                playSound("success");
            }
        })
    };

    const getBinsContainingDocumentProducts = (binQuantities) => {
        const binIDs = [...new Set(binQuantities.filter(binQuantity => binQuantity.amount > 0).map(binQuantity => binQuantity.binID))].join(",");
        const params = {
            request: "getBins",
            binIDs: binIDs,
            warehouseID: selectedWarehouse.warehouseID,
            status: "ACTIVE"
        };

        return dispatch(makeErplyRequest(params, t("getBinsError"), null, setBinsContainingDocumentProducts, null, true));
    };

    const getBinQuantities = (productIDs, productIsNotOnDocument = false) => {
        const successAction = productIsNotOnDocument ? setBinQuantitiesOfProductNotOnDocument : getBinQuantitiesSuccess;
        const params = {
            request: "getBinQuantities",
            productIDs: productIDs,
            warehouseID: selectedWarehouse.warehouseID
        };

        return dispatch(makeErplyRequest(params, t("getBinQuantitiesError"), null, successAction, null, true));
    };

    const updateScannedProducts = async (binRecords, products) => {
        const followUpDocuments = await getFollowUpDocuments();
        const [scannedProducts, scannedProductsByDocument, currentSessionScannedProducts] = getScannedProducts(binRecords, products, componentSequence, isScanBySupplier, multiplePurchaseOrderScanEnabled, [], differentiateByPackageID, followUpDocuments, confParameters);
        dispatch(setScannedProducts(scannedProducts));
        dispatch(setScannedProductsByDocument(scannedProductsByDocument));
        dispatch(setCurrentSessionScannedProducts(currentSessionScannedProducts));
        const summedScannedProducts = getSummedScannedProducts(currentSessionScannedProducts, differentiateByPackageID);
        dispatch(setSummedScannedProducts(summedScannedProducts));
    };

    const handleGoBackOnClick = () => {
        dispatch(componentSet(previousComponent));
    };

    const getFollowUpDocuments = async () => {
        if (followUpDocuments !== null) {
            return followUpDocuments;
        } else if (isInTransitTransfer || !(isPurchaseOrder || (isInventoryTransfer && confParameters.wmsEnableRepeatedTransferOrderScan == 1))) {
            dispatch(setFollowUpDocuments([]));
            return [];
        }

        const followUpDocumentsIDs = await getFollowUpDocumentIDs();

        if (followUpDocumentsIDs.length === 0) {
            dispatch(setFollowUpDocuments([]));
            return [];
        } else {
            let requests = [];
            let bulkRequests = [];

            for (let i = 0, n = followUpDocumentsIDs.length; i < n; i++) {
                const request = {
                    requestName: isInventoryTransfer ? "getInventoryTransfers" : "getPurchaseDocuments",
                    [getIdFieldName(isInventoryTransfer, isAssembly)]: followUpDocumentsIDs[i]
                };
                if (!isInventoryTransfer) request.getAddedTimestamp = 1;
                bulkRequests.push(request);
            }

            if (isInventoryTransfer) {
                requests.push(dispatch(makeErplyBulkRequest(bulkRequests, t("getInventoryTransfersError"), null, null, null, true, true)));
            } else {
                requests.push(dispatch(makeErplyBulkRequest(bulkRequests, t("getPurchaseDocumentsError"), null, null, null, true, true)));
            }

            if (isPurchaseOrder && multiplePurchaseOrderScanEnabled) {
                requests.push(dispatch(makeJsonApiRequest(t, `v1/json_object/prcinvoice/${followUpDocumentsIDs.join(";")}`, "GET", null)));
            }

            let [followUpDocuments, followUpDocumentsJsonAttributes] = await Promise.all(requests);
            if (isPurchaseOrder && multiplePurchaseOrderScanEnabled) {
                setFollowUpDocumentsScannedAmountsFromJsonAttributes(followUpDocumentsJsonAttributes, followUpDocuments);
            }

            dispatch(setFollowUpDocuments(followUpDocuments));
            return followUpDocuments
        }
    };

    const getFollowUpDocumentIDs = async () => {
        let followUpDocumentsIDs = [];

        for (let i = 0, n = selectedDocuments.length; i < n; i++) {
            if (isInventoryTransfer) {
                const params = {
                    request: "getInventoryTransfers",
                    searchAttributeName: "sourceTransferOrderID",
                    searchAttributeValue: selectedDocuments[0].inventoryTransferID,
                };

                const inventoryTransfers = await dispatch(makeErplyRequest(params, t("getInventoryTransfersError")));
                followUpDocumentsIDs = inventoryTransfers.map(transfer => transfer.inventoryTransferID);
                if (selectedDocuments[i].followupInventoryTransferID != 0) {
                    followUpDocumentsIDs.push(selectedDocuments[i].followupInventoryTransferID);
                }
            } else {
                for (let j = 0, n = selectedDocuments[i].baseToDocuments.length; j < n; j++) {
                    followUpDocumentsIDs.push(selectedDocuments[i].baseToDocuments[j].id);
                }
            }
        }

        return Promise.resolve([...new Set(followUpDocumentsIDs)]);
    };

    const getPackageCalculation = (productID, amount, amountInBold = true) => {
        let remainingAmount = amount;
        const product = selectedDocumentsProducts.find(product => product.productID == productID);
        let calculatedAmount = "";

        while (remainingAmount > 0) {
            let packageWithLargestAmount = {packageAmount: 1};
            packageWithLargestAmount.packageType = product.unitName !== "" && product.unitName !== null ? product.unitName : defaultPackaging;

            for (let i = 0, n = product.productPackages.length; i < n; i++) {
                const excludedPackageTypes = confParameters.wmsExcludedPackageTypesFromPackageCalculator.split(",").map(Number);

                if (!excludedPackageTypes.includes(product.productPackages[i].packageTypeID) &&
                    Number(packageWithLargestAmount.packageAmount) <= Number(product.productPackages[i].packageAmount) &&
                    remainingAmount >= Number(product.productPackages[i].packageAmount)) {
                    packageWithLargestAmount = product.productPackages[i];
                }
            }

            let timesInRemainingAmount = Math.floor(remainingAmount / Number(packageWithLargestAmount.packageAmount));
            if (calculatedAmount !== "") {
                calculatedAmount += " + ";
            }

            remainingAmount -= timesInRemainingAmount * packageWithLargestAmount.packageAmount;
            if (remainingAmount > 0 && remainingAmount < 1) {
                timesInRemainingAmount += remainingAmount;
                remainingAmount -= remainingAmount;
            }

            calculatedAmount += `${timesInRemainingAmount} x ${packageWithLargestAmount.packageType}(${packageWithLargestAmount.packageAmount}${t("pieceShort")})`;
        }

        return amountInBold ? <span>{calculatedAmount} / <strong>{amount}</strong></span> : calculatedAmount + ` / ${amount}`;
    };

    const getBinDocRow = (productID, showPackageCalculations, isBundle) => {
        if (isOutScan && binsEnabled && binQuantities && !isBundle) {
            const selectedBinAmount = getSelectedRowBinAmount(productID, showPackageCalculations);
            const binAmount = <Input className={'binAmount'} readOnly value={selectedBinAmount || ""}/>;

            return (
                <Table.Row>
                    <Table.Cell className={"tableHeading"} width={1}>{t("binAmount")}</Table.Cell>
                    <Table.Cell>
                        <div className={"flex"}>
                            <Dropdown
                                className={'binSelect'}
                                value={getSelectedRowBinID(productID)}
                                fluid
                                selection
                                options={getBinOptions(productID)}
                                onChange={(e, {value}) => setSelectedRowBin(value, productID)}
                                search
                                noResultsMessage={t("noResultsFound")}
                            />
                            {binAmount}
                        </div>
                    </Table.Cell>
                </Table.Row>
            )
        }
    };

    const getSelectedRowBinAmount = (productID, showPackageCalculations) => {
        const selectedBinID = getSelectedRowBinID(productID);
        for (let i = 0, n = binQuantities.length; i < n; i++) {
            if (binQuantities[i].binID == selectedBinID && binQuantities[i].productID == productID) {
                if (showPackageCalculations) {
                    return getPackageCalculation(productID, binQuantities[i].amount, false);
                } else {
                    return binQuantities[i].amount;
                }
            }
        }
    };

    //duplicate
    const getSelectedRowBinID = (productID) => {
        for (let i = 0, n = rowSelectedBins.length; i < n; i++) {
            if (rowSelectedBins[i].productID == productID) {
                return rowSelectedBins[i].selectedBinID;
            }
        }
    };

    //duplicate
    const setSelectedRowBin = (value, productID) => {
        let selectedBinsCopy = rowSelectedBins.slice();
        for (let i = 0, n = selectedBinsCopy.length; i < n; i++) {
            if (selectedBinsCopy[i].productID == productID) {
                selectedBinsCopy[i].selectedBinID = value;
                setRowSelectedBins(selectedBinsCopy);
                break;
            }
        }
    };

    const getBinOptions = (productID, isMainBin = false) => {
        let options = [];
        const quantities = binQuantitiesOfProductNotOnDocument.length > 0 && isMainBin ? binQuantitiesOfProductNotOnDocument : binQuantities;

        if (quantities && (!enableBinOrder || (enableBinOrder && binsContainingDocumentProducts))) {
            for (let i = 0, n = quantities.length; i < n; i++) {
                if (productID == quantities[i].productID && quantities[i].amount > 0) {
                    const option = {key: quantities[i].binID, text: translateBinFromEng(quantities[i].binCode, language),
                        value: quantities[i].binID, order: 9999, preferred: quantities[i].binPreferred};

                    if (enableBinOrder) {
                        const binContainingDocumentProducts = binsContainingDocumentProducts.find(bin => bin.binID == quantities[i].binID);
                        if (binContainingDocumentProducts) {
                            option.order = binContainingDocumentProducts.order <= 0 || binContainingDocumentProducts.order === "" ? 9999 : binContainingDocumentProducts.order;
                        }
                    }
                    options.push(option);
                }
            }

            // Sort 1) by order 2) by preferred/unpreferred 3) alphabetically
            options.sort((a, b) => {
                const orderA = a.order;
                const orderB = b.order;
                const textA = a.text.toUpperCase();
                const textB = b.text.toUpperCase();
                return (orderA < orderB) ? -1 : (orderA > orderB) ? 1 :
                    (a.preferred > b.preferred) ? -1 : (a.preferred < b.preferred) ? 1 :
                        (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

            // Move receiving area to the end
            const receivingAreaIndex = options.findIndex(option => option.text === translateBinFromEng("receiving_area", language));
            if (receivingAreaIndex !== -1) {
                options.push(options.splice(receivingAreaIndex, 1)[0]);
            }
        }

        return options;
    };

    const getCode = (row, codeField) => {
        return row.hasOwnProperty(codeField) && row[codeField] !== null && row[codeField] !== "" ? row[codeField] : getProductProperty(selectedDocumentsProducts, row.productID, codeField);
    };

    const getName = (row) => {
        return row.hasOwnProperty("itemName") ? row.itemName : row.hasOwnProperty("name") ? row.name : getProductProperty(selectedDocumentsProducts, row.productID, "name");
    };

    const createDocRow = (row, index) => {
        if (row.hasOwnProperty("productIsNotOnDocument") && row.productIsNotOnDocument === 1) { // Do not display products not on document
            return null;
        } else {
            const isBundle = rowIsBundle(row);
            const color = isBundle ? "violet" : "black";
            const amount = roundFloatingPointError(row.amount);
            let displayedAmount = amount;

            const product = selectedDocumentsProducts.find(product => product.productID == row.productID);
            if (!product) {  // For some reason product has been deleted (possible with API only)
                dispatch(errorMessageSet(t("productNotFound")));
                return null;
            }

            let code2Cell = [];
            let code2Heading = <Table.Cell className={"tableHeading"} width={1}>{translateAccordingToCountry(t("EAN"), confParameters)}</Table.Cell>;

            if (!isBundle && isRFix(clientCode)) {
                const locationInWarehouse = getLocationInWarehouseFromProductCard(product);

                if (locationInWarehouse !== "") {
                    code2Cell.push(<p key={`locationInWarehouse${row.productID}`} className={"floatRight boldText greenColor"}>{locationInWarehouse}</p>);
                }
            }

            const showPackageCalculations = confParameters.wmsShowPackageCalculations == 1;
            if (showPackageCalculations && selectedDocumentsProducts.length > 0) { // Wait for getProducts to finish
                displayedAmount = getPackageCalculation(row.productID, amount);
            }

            let code = getCode(row, "code");
            if (isAK && !isOutScan && product.supplierCode && product.supplierCode !== "") {
                code += ` [${product.supplierCode}]`;
            }
            let codeCell = [<p key={code}>{code}</p>];

            const name = getName(row);
            let nameCell = [<p key={name}>{name}</p>];

            const getBundleComponentRow = (component) => {
                const subtractedRow = subtractedRows.find(subtractedRow => subtractedRow.bundleID == row.productID && subtractedRow.productID == component.productID);

                if (subtractedRow) {
                    const componentAmount = subtractedRow.amount;
                    const componentCode2Cell = [component.code2];

                    if (isRFix(clientCode)) {
                        const componentWithExtraData = selectedDocumentsProducts.find(product => product.productID == component.productID);
                        const componentLocationInWarehouse = getLocationInWarehouseFromProductCard(componentWithExtraData);

                        if (componentLocationInWarehouse !== "") {
                            componentCode2Cell.push(<p key={`locationInWarehouse${component.productID}`} className={"floatRight boldText greenColor"}>{componentLocationInWarehouse}</p>);
                        }
                    }

                    return (
                        <Table key={`bundle_${index}_${component.productID}`} size={"small"} color="purple" celled structured unstackable>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell className={"tableHeading"} width={1}>{translateAccordingToCountry(t("EAN"), confParameters)}</Table.Cell>
                                    <Table.Cell>{componentCode2Cell}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className={"tableHeading"} width={1}>{t("code")}</Table.Cell>
                                    <Table.Cell><div className={"flex"}>{component.code}</div></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className={"tableHeading"} width={1}>{t("amount")}</Table.Cell>
                                    <Table.Cell>{componentAmount}</Table.Cell>
                                </Table.Row>
                                {getBinDocRow(component.productID, showPackageCalculations)}
                                <Table.Row>
                                    <Table.Cell className={"tableHeading"} width={1}>{t("name")}</Table.Cell>
                                    <Table.Cell><div className={"flex"}>{component.name}</div></Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    )
                }
            };

            const bundleComponentRows = isBundle ? row.productComponents.map(component => getBundleComponentRow(component)).filter(row => row !== undefined) : [];
            const bundleIsFullyScanned = isBundle && bundleComponentRows.length === 0;
            let amountCell = [displayedAmount];

            if (isBundle) {
                if (bundleIsFullyScanned) {
                    // Display "ASSEMBLED" after bundle's amount if all bundle's components have been scanned
                    amountCell.push(<span key={`assembledText${row.productID}`} className={"marginLeftSmall fontSizeMedSmall lightGreenColor boldText"}>{t("assembled").toUpperCase()}</span>)
                }
            }

            let displayableRow = [
                <Table key={index} inverted={isBundle} color={color} celled structured unstackable>
                    <Table.Body>
                        <Table.Row>
                            {code2Heading}
                            <Table.Cell>{code2Cell}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell className={"tableHeading"} width={1}>{t("code")}</Table.Cell>
                            <Table.Cell><div className={"flex"}>{codeCell}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell className={"tableHeading"} width={1}>{t("amount")}</Table.Cell>
                            <Table.Cell>{amountCell}</Table.Cell>
                        </Table.Row>
                        {getBinDocRow(row.productID, showPackageCalculations, isBundle)}
                        <Table.Row>
                            <Table.Cell className={"tableHeading"} width={1}>{t("name")}</Table.Cell>
                            <Table.Cell><div className={"flex"}>{nameCell}</div></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            ];

            if (isBundle && bundleComponentRows.length > 0) {
                displayableRow.push(
                    <Transition visible={selectedBundleID == row.productID} animation='fade down' duration={200}>
                        <div>{bundleComponentRows}</div>
                    </Transition>
                );
            }

            return displayableRow;
        }
    };

    const displayScannableDocRows = () => {
        return scannableDocRows;
    };

    const scannableDocRows = useMemo(() => {
        if (subtractedRows.length > 0) {
            let displayableRows = subtractedRows;
            displayableRows = displayableRows.filter(row => !isBundleComponent(row));

            if (isSaadaKappiDocument) {
                if (isTc2000Only) {
                    displayableRows = displayableRows.filter(row => rowHasTc2000Product(selectedDocumentsProducts, row));
                } else {
                    displayableRows = displayableRows.filter(row => !rowHasTc2000Product(selectedDocumentsProducts, row));
                }
            }

            return displayableRows.map((row, index) => createDocRow(row, index));
        }
    }, [subtractedRows, selectedDocumentsProducts, rowSelectedBins,
        productIDsToLabelPrint, isTc2000Only, modalMessage, confParameters, subtractedRowsSorted, language, selectedBundleID, modalHeading]);

    return (
        <div className={"overflow"}>
            <div className={"grid"}>
                <div className={"flexCenter"}>
                    <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                    <GoBackToStartBtn/>
                </div>
            </div>
            <div id={"documentRowsTable"}>
                {displayScannableDocRows()}
            </div>
        </div>
    );
};

export default ActiveDocumentReadOnlyView
