export const componentSet = (component) => {
    return {
        type: "COMPONENT_SET",
        payload: component
    }
};

export const previousComponentSet = (component) => {
    return {
        type: "PREVIOUS_COMPONENT_SET",
        payload: component
    }
};

export const setSequence = (sequence) => {
    return {
        type: "SET_SEQUENCE",
        payload: sequence
    }
};

export const setPreSettingsComponent = (component) => {
    return {
        type: "SET_PREVIOUS_SETTINGS_COMPONENT",
        payload: component
    }
};

export const setSettingsBtnHasBeenClicked = (hasBeenClicked) => {
    return {
        type: "SET_SETTINGS_BTN_HAS_BEEN_CLICKED",
        payload: hasBeenClicked
    }
};

export const setChangeScannedProductsDifferencesOnly = (isDifferencesOnly) => {
    return {
        type: "SET_CHANGE_SCANNED_PRODUCTS_DIFFERENCES_ONLY",
        payload: isDifferencesOnly
    }
};

export const setPreSettingsSequence = (sequence) => {
    return {
        type: "SET_PRE_SETTINGS_SEQUENCE",
        payload: sequence
    }
};

export const setIsLoading = (isLoading, isLongLoad = false) => {
    return {
        type: "SET_IS_LOADING",
        payload: {
            isLoading: isLoading,
            isLongLoad: isLongLoad
        }
    }
};
