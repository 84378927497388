const initialState = {
    message: "",
    heading: "",
    onYes: null,    // Function to be called on "Yes" click
    includeTextInput: false, // Include an input field
    inputValue: "",  // Default input value
    dropdownOptions: [],
    previousMessage: "",
    onNo: null  // Function to be called on "No" click
};

export const modalReducer = (state = initialState, action) => {
    switch(action.type) {
        case "SET_MODAL":
            return {
                ...state,
                message: action.payload.message,
                heading: action.payload.heading,
                onYes: action.payload.onYes,
                includeTextInput: action.payload.includeTextInput,
                inputValue: action.payload.inputValue,
                dropdownOptions: action.payload.dropdownOptions,
                previousMessage: state.message,
                onNo: action.payload.onNo
            };
        default:
            return state;
    }
};
