import React, {useState, useRef} from 'react';
import {Button, Input, Label} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {componentSet, setIsLoading, setSequence} from "../actions/component";
import {useDispatch, useSelector} from "react-redux";
import {errorMessageSet} from "../actions/headerMessage";
import {makeErplyRequest} from "../util/erplyRequests";
import {getApiAttributeValue, getConfParameterValue, isNotAPositiveInteger} from "../util/misc";
import {sendToPrintService} from "../util/printRequests";
import {isMarikaClientCode, isTesterClientCode} from "../util/isClient";

const SmartpostParcelLabels = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const createdDocumentId = useSelector(state => state.scanReducer.createdDocumentId);
    const selectedDocuments = useSelector(state => state.scanReducer.selectedDocuments);
    const componentSequence = useSelector(state => state.componentReducer.componentSequence);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const clientCode = useSelector(state => state.verifyUserReducer.clientCode);

    const serviceEngineUrl = isTesterClientCode(clientCode) || isMarikaClientCode(clientCode) ? "https://se-api.erply.com/Smartposttest/V2/" : "https://se-api.erply.com/Smartpost/V2/";
    const createServiceEngineTokenUrl = "https://se-api.erply.com/Engine/V1/sessions/key/create";
    const serviceEngineApiKeyConfParameterName = "smartpostServiceEngineApiKey";
    const labelFormatConfParameterName = "smartpostLabelFormat";
    const noOfParcelsAttributeName = "num_of_parcel";

    const serviceEngineToken = useRef(null);

    const isOpenInAndroidApp = window.ReactNativeWebView;
    const canPrint = dispatch(getConfParameterValue("wmsDpdPrinterName")) !== "" && dispatch(getConfParameterValue("wmsPrinterServiceAddress")) !== "" && isOpenInAndroidApp;

    const getInitialNoOfParcels = () => {
        const noOfParcelsOnBaseDocument = getApiAttributeValue(noOfParcelsAttributeName, selectedDocuments[0].attributes);
        return noOfParcelsOnBaseDocument && noOfParcelsOnBaseDocument != 0 && noOfParcelsOnBaseDocument !== "" ? noOfParcelsOnBaseDocument : 1;
    };

    const [noOfParcels, setNoOfParcels] = useState(getInitialNoOfParcels());

    const setPreScanComponent = () => {
        const preScanComponent = componentSequence[3];
        const newSequence = componentSequence.slice(0, 4);

        dispatch(componentSet(preScanComponent));
        dispatch(setSequence(newSequence));
    };

    const sendToSmartpost = async () => {
        if (isNotAPositiveInteger(noOfParcels)) {
            return dispatch(errorMessageSet(t("notAPositiveInteger")));
        } else if (noOfParcels > 20) {
            return dispatch(errorMessageSet(t("maxNoOfParcelsIs20")));
        } else if (!confParameters.hasOwnProperty(serviceEngineApiKeyConfParameterName)) {
            return dispatch(errorMessageSet(t("smartpostServiceEngineApiKeyIsMissing")));
        }

        const createdDocument = await getCreatedDocument();
        const receiverID = confParameters.invoice_client_is_payer == 1 ? createdDocument.shipToID : createdDocument.clientID;
        const receiverAddressID = confParameters.invoice_client_is_payer == 1 ? createdDocument.shipToAddressID : createdDocument.addressID;
        const estonianParcelTerminalDeliveryTypeIsSelected = createdDocument.deliveryTypeName === "SMARTPOST - Pakiautomaat" || createdDocument.deliveryTypeName === "SMARTPOST - Parcel terminal";
        const courierDeliveryTypeIsSelected = createdDocument.deliveryTypeName === "SMARTPOST - Kullerteenus" || createdDocument.deliveryTypeName === "SMARTPOST - Courier service";
        const finnishParcelTerminalDeliveryTypeIsSelected = createdDocument.deliveryTypeName === "SMARTPOST - Pakiautomaat, Soome" || createdDocument.deliveryTypeName === "SMARTPOST - Parcel terminal, Finland";
        const finnishPostOfficeDeliveryTypeIsSelected = createdDocument.deliveryTypeName === "SMARTPOST - Postkontor, Soome" || createdDocument.deliveryTypeName === "SMARTPOST - Post office, Finland";
        const parcelTerminalCode = getApiAttributeValue("smartpostParcelTerminalCode", createdDocument.attributes);

        if (receiverID == 0) {
            return dispatch(errorMessageSet(t("createdDocumentHasNoReceiver")));
        } else if (courierDeliveryTypeIsSelected && receiverAddressID == 0) {
            return dispatch(errorMessageSet(t("createdDocumentHasNoReceiverAddress")));
        } else if (!createdDocument.deliveryTypeName.startsWith("SMARTPOST - ")) {
            return dispatch(errorMessageSet(`SMARTPOST ${t("deliveryConditionMissing")}`));
        } else if (!courierDeliveryTypeIsSelected && (!parcelTerminalCode || parcelTerminalCode === "-")) {
            const errorText = finnishPostOfficeDeliveryTypeIsSelected ? "postOfficeMissing" : "parcelTerminalMissing";
            return dispatch(errorMessageSet(t(errorText)));
        }

        const requests = [getReceiver(receiverID, courierDeliveryTypeIsSelected)];
        if (finnishParcelTerminalDeliveryTypeIsSelected) {
            requests.push(getDestinations("FI", "APT"));
        } else if (finnishPostOfficeDeliveryTypeIsSelected) {
            requests.push(getDestinations("FI", "PO"));
        }

        dispatch(setIsLoading(true));
        const requestResponses = await Promise.all(requests);
        dispatch(setIsLoading(false));
        const receiver = requestResponses[0];

        const phone = receiver.mobile.trim();
        if (phone === "") {
            return dispatch(errorMessageSet(t("receiverHasNoMobileNo")));
        }

        const params = {
            numOfParcel: noOfParcels,
            name: receiver.fullName.trim(),
            phone: phone
        };

        const parcelTerminalName = getApiAttributeValue("smartpostParcelTerminalName", createdDocument.attributes);

        if (estonianParcelTerminalDeliveryTypeIsSelected) {
            params.place_id = parcelTerminalCode;
            params.country = "EE";
        } else if (finnishParcelTerminalDeliveryTypeIsSelected || finnishPostOfficeDeliveryTypeIsSelected) {
            const destinations = requestResponses[1];
            const destination = destinations.find(destination => destination.postalcode === parcelTerminalCode && destination.name === parcelTerminalName);
            params.postalcode = destination.postalcode;
            params.routingcode = destination.routingcode;
            params.country = "FI";
        } else if (courierDeliveryTypeIsSelected) {
            const receiverAddress = receiver.addresses.find(address => address.addressID == receiverAddressID);
            const street = receiverAddress.street.trim();
            if (street === "") {
                return dispatch(errorMessageSet(t("deliveryAddressHasNoStreet")));
            }

            params.street = street;
            params.city = receiverAddress.city;
            params.postalcode = receiverAddress.postalCode;
            params.country = receiverAddress.country;
            if (params.country.toLowerCase() === "eesti") params.country = "EE";
        }

        const responseXml = await sendServiceEngineRequest("POST", "saveShipmentData", JSON.stringify(params));

        if (responseXml) {
            const parcels = getObjectsFromSmartpostXml(responseXml);
            const parcelNos = parcels.map(parcel => parcel.barcode);
            getLabels(parcelNos, createdDocument.number);
            saveAttributes(parcelNos);
        }
    };

    const getCreatedDocument = () => {
        const params = {
            request: "getSalesDocuments",
            id: createdDocumentId
        };

        return dispatch(makeErplyRequest(params, t("getSalesDocumentsError"))).then(docs => docs[0]);
    };

    const getReceiver = (receiverID, getAddresses) => {
        const params = {
            request: "getCustomers",
            id: receiverID
        };
        if (getAddresses) params.getAddresses = 1;

        return dispatch(makeErplyRequest(params, t("getCustomersError"), null, null, null, false, false)).then(customers => customers[0]);
    };

    const getDestinations = async (country, type) => {
        const params = {country: country, type: type};
        const responseXml = await sendServiceEngineRequest("POST", "getDestinations", JSON.stringify(params));
        return getObjectsFromSmartpostXml(responseXml);
    };

    const sendServiceEngineRequest = async (method, endpoint, body = null) => {
        console.log("Params sent to Service Engine", body);
        dispatch(setIsLoading(true));
        const serviceEngineToken = await getServiceEngineToken();

        return fetch(serviceEngineUrl + endpoint, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'API_KEY': serviceEngineToken
            },
            body: body
        })
            .then(response => response.json())
            .then(async data => {
                dispatch(setIsLoading(false));

                if (data.hasOwnProperty("error")) {
                    dispatch(errorMessageSet(`${t("serviceEngineError")}. ${data.error}`));
                    return false;
                } else if (data.status === "error") {
                    const isFromSmartpost = data.message.startsWith('<?xml version="1.0"?>');
                    if (isFromSmartpost) {
                        dispatch(errorMessageSet(`${t("smartpostError")}. ${formatSmartpostError(data.message)}`));
                    } else {
                        dispatch(errorMessageSet(`${t("serviceEngineError")}. ${data.message}`));
                    }
                    return false;
                }

                return data.message;
            })
            .catch(err => {
                dispatch(setIsLoading(false));
                const errorMessage = `${t("serviceEngineError")}: ${err}`;
                dispatch(errorMessageSet(errorMessage));
                throw new Error(errorMessage);
            });
    };

    const getServiceEngineToken = async () => {
        if (serviceEngineToken.current !== null) {
            return serviceEngineToken.current;
        }

        const data = new URLSearchParams();
        data.set("jwt", confParameters[serviceEngineApiKeyConfParameterName]);

        return fetch(createServiceEngineTokenUrl, {
            method: "POST",
            body: data
        })
            .then(response => response.json())
            .then(data => {
                serviceEngineToken.current = data.token;
                return serviceEngineToken.current;
            })
            .catch(err => {
                dispatch(errorMessageSet(`${t("serviceEngineTokenError")}. ${err}`));
            });
    };

    const formatSmartpostError = (errorXml) => {
        const removeXmlTags = (errorParts, tagName) => {
            for (let i = 0, n = errorParts.length; i < n; i++) {
                const openingTag = `<${tagName}>`;
                const closingTag = `</${tagName}>`;

                if (errorParts[i].includes(openingTag)) {
                    return errorParts[i].replace(openingTag, "").replace(closingTag, "").trim();
                }
            }

            return "";
        };

        const errorXmlElements = getObjectsFromSmartpostXml(errorXml);
        const errorXmlElement = errorXmlElements[errorXmlElements.length - 1]; // Smartpost sends an (identical) error for each parcel. Get only the last error if multiple parcels are requested
        const errorParts = errorXmlElement.error.trim().split("\n");
        const code = removeXmlTags(errorParts, "code");
        const field = removeXmlTags(errorParts, "field");
        const text = removeXmlTags(errorParts, "text");
        const input = removeXmlTags(errorParts, "input");

        let error = `${t("code")}: ${code}, ${t("field")}: ${field}, ${t("text")}: ${text}`;
        if (input !== "") {
            error += `, Input: ${input}`;
        }

        return error;
    };

    const getObjectsFromSmartpostXml = (xml) => {
        const parser = new DOMParser();
        const parsedXml = parser.parseFromString(xml, "text/xml");
        const elements = parsedXml.getElementsByTagName("item");
        const objects = [];

        for (let element of elements) {
            const object = {};

            for (let childNode of element.childNodes) {
                object[childNode.nodeName] = childNode.innerHTML;
            }

            objects.push(object);
        }

        return objects;
    };

    const getLabels = async (parcelNos, documentNo) => {
        const labelFormat = confParameters.hasOwnProperty(labelFormatConfParameterName) ? confParameters[labelFormatConfParameterName] : "A6";
        const params = {parcelNos: parcelNos, format: labelFormat};
        const labels = await sendServiceEngineRequest("POST", "getLabels", JSON.stringify(params));

        if (labels) {
            if (canPrint) {
                sendToPrintService(labels, dispatch(getConfParameterValue("wmsDpdPrinterName")), dispatch(getConfParameterValue("wmsPrinterServiceAddress")));
            } else {
                downloadLabels(labels, documentNo);
            }

            setPreScanComponent();
        }
    };

    const saveAttributes = async (parcelNos) => {
        const params = {
            request: "saveSalesDocument",
            id: createdDocumentId,
            longAttributeName0: "smartpostParcelNumbers",
            longAttributeValue0: JSON.stringify(parcelNos),
            attributeName0: noOfParcelsAttributeName,
            attributeValue0: noOfParcels
        };

        return dispatch(makeErplyRequest(params, t("saveSalesDocumentError"), null, null, null, false, false));
    };

    const downloadLabels = (labels, documentNo) => {
        const byteCharacters = atob(labels);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const file = new Blob([byteArray], { type: 'application/pdf;base64' });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `SMARTPOST_${t("parcelLabels")}_${documentNo}`;
        link.dispatchEvent(new MouseEvent('click'));
    };

    return (
        <div>
            <div className={"searchRow"}>
                <Label className={"searchLabel searchLabelMedium"} size={"large"} pointing={"right"}>{t("noOfParcels")}</Label>
                <Input className={"searchInput searchInputMedium"} type="number" onInput={e => setNoOfParcels(e.target.value)} value={noOfParcels}/>
            </div>
            <Button className={"menuBtn"} primary onClick={sendToSmartpost} size='big'>{t("createSmartpostDelivery")}</Button>
            <Button className={"menuBtn marginTop"} onClick={setPreScanComponent} size='big'>{t("doNotWantSmartpostLabels")}</Button>
        </div>
    );
};

export default SmartpostParcelLabels
