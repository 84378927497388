import React, {useState, useEffect, useRef} from 'react';
import {Button, Form, Grid, Header, Input, Segment} from 'semantic-ui-react'
import erplyLogo2 from "../images/erplyLogo2.png";
import {errorMessageSet} from "../actions/headerMessage";
import {useDispatch} from "react-redux";
import { useTranslation } from 'react-i18next';
import {responseHasErrors, verifyUser} from "../util/erplyRequests";
import {componentSet, previousComponentSet, setSequence} from "../actions/component";
import {setInformativeModal} from "../actions/informativeModal";
import {checkSessionRefreshTimestampPeriodically, isNotAPositiveInteger} from "../util/misc";
import {useClearCache} from "react-clear-cache";

const Login = () => {
    // const [username, setUsername] = useState("");
    // const [password, setPassword] = useState("");
    // const [clientCode, setClientCode] = useState("");
    // const [username, setUsername] = useState("edgar");
    // const [password, setPassword] = useState("priidiksoonA0");
    // const [clientCode, setClientCode] = useState("365984");
    const [username, setUsername] = useState("edgar.tereping@intral.net");
    const [password, setPassword] = useState("2ff6c239f5A");
    const [clientCode, setClientCode] = useState("398667");

    const usernameInput = useRef(null);
    const passwordInput = useRef(null);
    const clientCodeInput = useRef(null);

    useEffect(() => {
        dispatch(setSequence([]));  // Empty sequence
        dispatch(previousComponentSet("Login"));
        disableBrowserBackButton();
        enableRefresh();
        showYouWereLoggedOutMessage();
    }, []);

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const { isLatestVersion, emptyCacheStorage } = useClearCache();

    const emptyCache = () => {
        if (!isLatestVersion) {
            setTimeout(() => {
                emptyCacheStorage();    // Version used is not the latest WMS version. Empty cache and update version
            }, 100);    // For some reason starts looping without timeout
        }
    };

    const showYouWereLoggedOutMessage = () => {
        if (sessionStorage.getItem("wmsShowYouWereLoggedOutMessage") === "1") {
            dispatch(setInformativeModal(t("youWereLoggedOut"), t("youWereLoggedOutHeader")));
            sessionStorage.removeItem("wmsShowYouWereLoggedOutMessage");
        }
    };

    // Ensure the app gets updated
    const enableRefresh = () => {
        window.onbeforeunload = () => {};
    };

    // Ensure no accidental back button click
    const disableBrowserBackButton = () => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event)
        {
            window.history.pushState(null, document.title, window.location.href);
        });
    };

    const validateForm = () => {
        if (username === "") {
            usernameInput.current.focus();
            return dispatch(errorMessageSet(t("enterUsername")));
        } else if (password === "") {
            passwordInput.current.focus();
            return dispatch(errorMessageSet(t("enterPassword")));
        } else if (clientCode === "") {
            clientCodeInput.current.focus();
            return dispatch(errorMessageSet(t("enterClientcode")));
        } else if (isNotAPositiveInteger(clientCode)) {
            return dispatch(errorMessageSet(t("clientCodeMustBeNumeric")));
        }

        login();
    };

    const login = async () => {
        const response = await dispatch(verifyUser(t, clientCode, username, password));

        if (!responseHasErrors(response)) {
            dispatch(checkSessionRefreshTimestampPeriodically(t));
            dispatch(componentSet("ChooseWarehouse"));
        }
    };

    return (
        <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
            <Grid.Column>
                <Header as='h2' color='blue'>
                    SEE ON TEST
                    <div>
                        <img className={"logo"} src={erplyLogo2} alt="logo" />
                    </div>
                </Header>
                <Form id={"loginForm"}>
                    <Segment stacked>
                        <Form.Field>
                            <Input ref={usernameInput} id={"userName"} onInput={e => setUsername(e.target.value)} fluid icon='user' iconPosition='left' placeholder={t('username')}/>
                        </Form.Field>
                        <Form.Field>
                            <Input ref={passwordInput} id={"password"} onInput={e => setPassword(e.target.value)} fluid icon='lock' iconPosition='left' placeholder={t('password')} type="password"/>
                        </Form.Field>
                        <Form.Field>
                            <Input ref={clientCodeInput} id={"clientCode"} onInput={e => setClientCode(e.target.value)} fluid icon='industry' iconPosition='left' placeholder={t('clientcode')}/>
                        </Form.Field>
                        <Button color='blue' fluid size='large' onClick={() => validateForm()}>
                            {t("login")}
                        </Button>
                    </Segment>
                </Form>
            </Grid.Column>
            {emptyCache()}
        </Grid>
    );
};

export default Login
