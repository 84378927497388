import React, { useState, useEffect } from 'react';
import {Button, Table} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {componentSet, previousComponentSet, setSequence} from "../actions/component";
import {setDocumentHasBeenPreviouslyScanned, setSelectedDocuments} from "../actions/scan";
import GoBackToStartBtn from "./GoBackToStartBtn";
import GoBackBtn from "./GoBackBtn";
import {
    formatDateStringToEst,
    getApiAttributeValue,
    isSelectedRow,
    setExpandedRow,
    translateAccordingToCountry
} from "../util/misc";
import {getBinRecords, makeErplyRequest} from "../util/erplyRequests";
import {setModal} from "../actions/modal";

const InventoryTransfersListConfirmation = () => {
    const searchResultInventoryTransfers = useSelector(state => state.searchDocumentsReducer.searchResultDocuments);
    const language = useSelector(state => state.languageReducer.language);
    const binRecords = useSelector(state => state.getBinRecordsReducer.binRecords);
    const warehouses = useSelector(state => state.getWarehousesReducer.warehouses);
    const searchResultFollowUpDocuments = useSelector(state => state.searchDocumentsReducer.searchResultFollowUpDocuments);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const isInTransitTransfer = useSelector(state => state.scanReducer.isInTransitTransfer);

    const componentSequence = useSelector(state => state.componentReducer.componentSequence);
    const isOutScan = componentSequence.includes("ProductsOut");

    const [expandedRows, setExpandedRows] = useState([]);
    const [requestedProducts, setRequestedProducts] = useState([]); // Products in expanded inventory transfers, needed to display product info

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getBinRecords(t, searchResultInventoryTransfers.map(doc => doc.inventoryTransferID).join(","), "INVENTORY_TRANSFER", null, true));
        dispatch(previousComponentSet("InventoryTransfersListConfirmation"));
    }, []);

    const handleGoBackOnClick = () => {
        if (isInTransitTransfer) {
            dispatch(setSequence(["ChooseWarehouse", "MainMenu", "ProductsIn"]));
        }
        const component = isInTransitTransfer ? "ProductsIn" : "SearchInventoryTransfers";
        dispatch(componentSet(component));
    };

    const createRows = () => {
        if (binRecords) {
            return searchResultInventoryTransfers.map(transfer => createRow(transfer));
        }
    };

    const createRow = (transfer) => {
        const isSelected = isSelectedRow(transfer.inventoryTransferID, expandedRows);
        const date = language === "est" ? formatDateStringToEst(transfer.date) : transfer.date;
        const packingHasStarted = binRecords.some(binRecord => binRecord.documentID == transfer.inventoryTransferID);
        const buttonColor = packingHasStarted ? "red" : "blue";
        const warehouseIDField = isOutScan ? "warehouseToID" : "warehouseFromID";
        const warehouseName = warehouses.find(warehouse => warehouse.warehouseID == transfer[warehouseIDField]).name;

        let row = [
            <Table.Row key={transfer.inventoryTransferID}>
                <Table.Cell><Button size={"mini"} color={buttonColor} icon={"check"} onClick={() => {handleCheckClick(transfer.inventoryTransferID, packingHasStarted)}}/></Table.Cell>
                <Table.Cell onClick={() => expandRow(transfer)}>{transfer.inventoryTransferNo}</Table.Cell>
                <Table.Cell onClick={() => expandRow(transfer)}>{date}</Table.Cell>
                <Table.Cell onClick={() => expandRow(transfer)}>{warehouseName}</Table.Cell>
            </Table.Row>
        ];

        if (isSelected) {
            row.push(createRowTable(transfer));
        }

        return row;
    };

    const expandRow = async (transfer) => {
        const productsNotRequested = await getProducts(transfer);
        setRequestedProducts(requestedProducts.concat(productsNotRequested));
        setExpandedRow(transfer.inventoryTransferID, expandedRows, setExpandedRows);
    };

    const getProducts = (transfer) => {
        let productIDsToBeRequested = [];

        for (let i = 0, n = transfer.rows.length; i < n; i++) {
            let productHasAlreadyBeenRequested = false;

            for (let j = 0, n = requestedProducts.length; j < n; j++) {
                if (requestedProducts[j].productID == transfer.rows[i].productID) {
                    productHasAlreadyBeenRequested = true;
                }
            }

            if (!productHasAlreadyBeenRequested) {
                productIDsToBeRequested.push(transfer.rows[i].productID);
            }
        }

        if (productIDsToBeRequested.length !== 0) {
            const params = {
                request: "getProducts",
                productIDs: productIDsToBeRequested.join(","),
                includeMatrixVariations: 1
            };

            return dispatch(makeErplyRequest(params, t("getProductsError"), null, null, null, true));
        } else {
            return [];
        }
    };

    const handleCheckClick = (transferID, packingHasStarted) => {
        if (packingHasStarted) {
            const modalText = isOutScan ? t("packingAlreadyStarted") : t("handlingAlreadyStarted");
            dispatch(setModal(t("confirmation"), modalText, () => selectDocumentForScan(transferID, true)));
        } else {
            selectDocumentForScan(transferID, false);
        }
    };

    const selectDocumentForScan = async (transferID, documentHasBeenPreviouslyScanned = false) => {
        dispatch(setDocumentHasBeenPreviouslyScanned(documentHasBeenPreviouslyScanned));
        const selectedDocuments = await dispatch(makeErplyRequest({
            request: "getInventoryTransfers",
            inventoryTransferID: transferID
        }, t("getInventoryTransfersError")));
        await dispatch(setSelectedDocuments(selectedDocuments, t));
        dispatch(componentSet("Scan"));
    };

    const createRowTable = (transfer) => {
        return (
            <tr key={transfer.lastModified}>{/* Didn't know what else to use as key*/}
                <td colSpan={4}>
                    <Table celled structured unstackable color={"blue"} inverted>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t("code")}</Table.HeaderCell>
                                <Table.HeaderCell>{translateAccordingToCountry(t("EAN"), confParameters)}</Table.HeaderCell>
                                <Table.HeaderCell>{t("name")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("amount")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {createRowTableRows(transfer)}
                        </Table.Body>
                    </Table>
                </td>
            </tr>
        )
    };

    const createRowTableRows = (transfer) => {
        const summedRows = getSummedRows(transfer.rows);
        return summedRows.map((row, index) => createRowTableRow(row, index, transfer));
    };

    const getSummedRows = (rows) => {
        let summedRows = [];

        for (let i = 0, n = rows.length; i < n; i++) {
            let amountHasBeenSummed = false;

            for (let j = 0, n = summedRows.length; j < n; j++) {
                if (summedRows[j].productID === rows[i].productID) {
                    summedRows[j].amount = Number(summedRows[j].amount) + Number(rows[i].amount);
                    amountHasBeenSummed = true;
                }
            }

            if (!amountHasBeenSummed) {
                const rowCopy = Object.assign({}, rows[i]);
                summedRows.push(rowCopy);
            }
        }

        return summedRows;
    };

    const createRowTableRow = (row, index, transfer) => {
        const product = requestedProducts.find(product => product.productID == row.productID);
        const amount = getAmount(row, transfer);

        return (
            <Table.Row key={index}>
                <Table.Cell>{product.code}</Table.Cell>
                <Table.Cell>{product.code2}</Table.Cell>
                <Table.Cell>{product.name}</Table.Cell>
                <Table.Cell>{amount}</Table.Cell>
            </Table.Row>
        )
    };

    // Subtract follow-up document amounts (if wmsEnableRepeatedTransferOrderScan is set to 1)
    const getAmount = (row, transferOrder) => {
        if (transferOrder.followupInventoryTransferID == 0) {
            return row.amount;
        } else {
            let newAmount = row.amount;
            const followUpDocuments = searchResultFollowUpDocuments.filter(doc => doc.inventoryTransferOrderID == transferOrder.inventoryTransferID ||
                getApiAttributeValue("sourceTransferOrderID", doc.attributes) == transferOrder.inventoryTransferID);

            for (let i = 0, n = followUpDocuments.length; i < n; i++) {
                newAmount -= getProductAmountOnDocument(followUpDocuments[i], row.productID);
            }

            return newAmount;
        }
    };

    const getProductAmountOnDocument = (document, productID) => {
        let sum = 0;

        for (let i = 0, n = document.rows.length; i < n; i++) {
            if (document.rows[i].productID == productID) {
                sum += Number(document.rows[i].amount);
            }
        }

        return sum;
    };

    return (
        <div>
            <Table celled structured unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell/>
                        <Table.HeaderCell>{t("number")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("date")}</Table.HeaderCell>
                        <Table.HeaderCell>{isOutScan ? t("warehouseTo") : t("warehouseFrom")}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {createRows()}
                </Table.Body>
            </Table>
            <div className={"flex flexCenter"}>
                <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                <GoBackToStartBtn/>
            </div>
        </div>
    );
};

export default InventoryTransfersListConfirmation
