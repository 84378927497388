import React, { useState, useEffect, useRef } from 'react';
import {Button, Input} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {errorMessageSet} from "../actions/headerMessage";
import {useDispatch, useSelector} from "react-redux";
import {getBinRecords, makeErplyRequest} from "../util/erplyRequests";
import {componentSet, previousComponentSet} from "../actions/component";
import {setCreatedDocumentId, setDocumentHasBeenPreviouslyScanned, setSelectedDocuments} from "../actions/scan";
import GoBackToStartBtn from "./GoBackToStartBtn";
import {setModal} from "../actions/modal";
import GoBackBtn from "./GoBackBtn";
import {
    filterOutDocumentsWithAttributeValue,
    filterOutDocumentsWithNoRows,
    getSavedDocumentIdFieldName
} from "../util/misc";
import {isRehvidPluss} from "../util/isClient";

const SalesOrder = () => {
    const orderNoInput = useRef(null);

    const [orderNo, setOrderNo] = useState("");

    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);
    const clientCode = useSelector(state => state.verifyUserReducer.clientCode);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(previousComponentSet("SalesOrder"));
        orderNoInput.current.focus();
    }, []);

    const handleStartHandlingOnClick = () => {
        if (orderNo !== "") {

            getSalesOrder().then(async (salesOrders) => {
                if (salesOrders.length === 0) {
                    dispatch(errorMessageSet(t("noSalesDocFound")));
                } else {
                    const binRecords = await dispatch(getBinRecords(t, salesOrders[0].id, "SALES_DOCUMENT", null, true));

                    if (binRecords.length > 0) {
                        dispatch(setModal(t("confirmation"), t("packingAlreadyStarted"), () => goToScan(salesOrders, true)));
                    } else if (confParameters.wmsSearchSalesOrderByNumberPaymentWarning === "warnIfNoPayments" && Number(salesOrders[0].paid) === 0) {
                        dispatch(setModal(t("warning"), t("orderHasNoPayments"), () => goToScan(salesOrders)));
                    } else if (confParameters.wmsSearchSalesOrderByNumberPaymentWarning === "warnIfNotPaid100%" && Number(salesOrders[0].paid) !== salesOrders[0].total) {
                        dispatch(setModal(t("warning"), t("orderNotFullyPaid"), () => goToScan(salesOrders)));
                    } else {
                        goToScan(salesOrders);
                    }
                }
            });
        } else {
            dispatch(errorMessageSet(t("enterSalesOrderNo")));
        }
    };

    const goToScan = async (salesOrders, documentHasBeenPreviouslyScanned = false) => {
        dispatch(setDocumentHasBeenPreviouslyScanned(documentHasBeenPreviouslyScanned));
        await dispatch(setSelectedDocuments(salesOrders, t));
        dispatch(componentSet("Scan"));
    };

    const handleShowFulfillableOrdersOnClick = () => {
        dispatch(componentSet("FulfillableOrdersFilters"));
    };

    const handleGoBackOnClick = () => {
        dispatch(componentSet("ProductsOut"));
    };

    const getSalesOrder = () => {
        const params = {
            request: "getSalesDocuments",
            number: orderNo,
            type: "ORDER",
            warehouseID: selectedWarehouse.warehouseID,
            // getUnfulfilledDocuments: 1
        };

        return dispatch(makeErplyRequest(params, t("getSalesDocumentsError"))).then((docs) => {
            let filteredDocs = filterOutDocumentsWithNoRows(docs);
            if (isRehvidPluss(clientCode)) {
                filteredDocs = filterOutDocumentsWithAttributeValue(filteredDocs, "documentScannedInWms", 1);
            }
            return filteredDocs;
        });
    };

    return (
        <div>
            <Input ref={orderNoInput} onInput={e => setOrderNo(e.target.value)} className={"searchDocNoInput"} fluid placeholder={t('enterSalesOrderNo')} />
            <Button className={"menuBtn"} primary size={"big"} onClick={handleStartHandlingOnClick}>{t("startIssuing")}</Button>
            <Button className={"menuBtn"} color={"instagram"} onClick={handleShowFulfillableOrdersOnClick} size='big'>{t("showFulfillableOrders")}</Button>
            <div className={"flex flexCenter"}>
                <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                <GoBackToStartBtn/>
            </div>
        </div>
    );
};

export default SalesOrder
