const initialState = {
    reasonCodes: [],
};

export const getReasonCodesReducer = (state = initialState, action) => {
    switch(action.type) {
        case "GET_REASON_CODES_SUCCESS":
            return {
                ...state,
                reasonCodes: action.payload,
            };
        default:
            return state;
    }
};
