import {errorMessageSet} from "../actions/headerMessage";
import {setIsLoading} from "../actions/component";
import {
    documentIsAssignment,
    documentIsInventoryTransfer,
    getIdFieldName,
    getSaveDocumentRequestName,
    isInfralinkSelfService,
    isProdOrPreProd,
    simpleLog
} from "./misc";
import {setMailboxes, setServiceEndpoints} from "../actions/newErplyApis";
import {getReasonCodesSuccess} from "../actions/getReasonCodes";
import {getConfParametersSuccess} from "../actions/getConfParameters";
import {getBinRecordsSuccess} from "../actions/getBinRecords";
import {logout, verifyUserSuccess} from "../actions/verifyUser";
import {setVatRates} from "../actions/scan";
import {AES, enc} from "crypto-js";
import {isInfralink, isTAF} from "./isClient";

const partnerKey = "a7f37426d3573154b0258ef3e0fee5d6103e9bc4";
const maxNoOfReRequests = 2;
const isOpenInAndroidApp = window.ReactNativeWebView;

const getUrl = (clientCode) => {
    let url = `https://${clientCode}.erply.com/api/`;
    return isProdOrPreProd() ? url : `https://w2.intralplugins.com/proxy/?csurl=${url}`;
};

export const isDuplicateDocumentError = (response, clientCode) => {
    // Test this functionality only on TAF as only TAF is having this issue
    return isTAF(clientCode) && response?.errorCode === 1206;
};

export const makeErplyRequest = (params, errorText, pendingAction = null, successAction = null, errorAction = null, getAllPages = false, setLoaders = true) => {
    let noOfReRequests = 0;
    return (dispatch, getState) => {
        if (setLoaders) {
            dispatch(setIsLoading(true));
        }

        if (pendingAction) {
            dispatch(pendingAction());
        }

        const formData = new FormData();
        formData.append("partnerKey", partnerKey);

        let clientCode;
        const state = getState();

        const request = params.request;
        const isGetRequest = request.substr(0, 3) === "get";

        if (request !== "verifyUser") {
            clientCode = state.verifyUserReducer.clientCode;

            formData.append("clientCode", clientCode);
            formData.append("sessionKey", state.verifyUserReducer.user.sessionKey);
        } else {
            clientCode = params.clientCode
        }

        if (getAllPages && !params.hasOwnProperty("pageNo")) {
            formData.append("pageNo", 1);
        }

        let url = getUrl(clientCode);

        Object.keys(params).forEach((param) => {
            formData.append(param, params[param]);
        });

        if (isGetRequest && !params.hasOwnProperty("recordsOnPage")) {
            let recordsOnPage = 100;
            if (request === "getProducts" && params.getStockInfo != 1) {
                recordsOnPage = 1000;
            }

            formData.append("recordsOnPage", recordsOnPage);
        }

        const sendRequest = () => {
            const returnRecords = (records) => {
                if (successAction) {
                    dispatch(successAction(records));
                }
                if (setLoaders) {
                    dispatch(setIsLoading(false));
                }
                if (!isGetRequest && noOfReRequests > 0) logError("Previously failed request succeeded", params);

                return records;
            };

            return fetch(url, {
                method: 'POST',
                body: formData
            })
                .then(async res => {
                    if (res.status === 429) {   // 429 Too Many Requests
                        console.log("429 error, waiting 10s before trying again");
                        dispatch(setIsLoading(true, true));
                        await timeout(10000);
                        return await sendRequest();
                    } else {
                        const resJson = await res.json();
                        console.log(request, resJson);

                        if (resJson.status.errorCode > 0) {
                            return handleError(noOfReRequests, dispatch, errorAction, errorText, params, resJson.status, state, null, true, null, null, formData);
                        }

                        if (getAllPages) {
                            const noOfPagesOfRecordsLeft = getNoOfPagesOfRecordsLeft(resJson);

                            if (noOfPagesOfRecordsLeft > 0) {
                                let requests = [];

                                for (let i = 0; i < noOfPagesOfRecordsLeft; i++) {
                                    const request = {
                                        requestName: params.request,
                                        pageNo: i + 2,
                                        requestID: i
                                    };

                                    for (let pair of formData.entries()) {
                                        if (pair[0] !== "request" && pair[0] !== "pageNo" && pair[0] !== "clientCode" && pair[0] !== "sessionKey") {
                                            request[pair[0]] = pair[1];
                                        }
                                    }

                                    requests.push(request);
                                }

                                return dispatch(makeErplyBulkRequest(requests, errorText, pendingAction, successAction, errorAction, setLoaders)).then((bulkRequestRes) => {
                                    let mergedRecords = resJson.records;
                                    for (let i = 0, n = bulkRequestRes.length; i < n; i++) {
                                        mergedRecords = mergedRecords.concat(bulkRequestRes[i].records);
                                    }

                                    return returnRecords(mergedRecords);
                                })
                            } else {
                                return returnRecords(resJson.records);
                            }
                        } else {
                            return returnRecords(resJson.records);
                        }
                    }
                })
                .catch(async error => {
                    if ((isOpenInAndroidApp || isTAF(clientCode)) && noOfReRequests < maxNoOfReRequests && (error.name === "AbortError" || (error.name === "TypeError" && error.message === "Network request failed"))) {
                        handleError(noOfReRequests, dispatch, errorAction, errorText, params, null, state, error, false, null, null, formData);
                        noOfReRequests ++;
                        return await sendRequest();
                    }
                    return handleError(noOfReRequests, dispatch, errorAction, errorText, params, null, state, error, true, null, null, formData);
                })
        };

        return sendRequest();
    }
};

export const makeErplyBulkRequest = (allRequests, errorText, pendingAction = null, successAction = null, errorAction = null, setLoaders = true, returnOnlyRecords = false) => {
    const isGetRequests = allRequests.every(request => request.requestName.substr(0, 3) === "get");
    let noOfReRequests = 0;
    let isErrorCode429 = false;

    return (dispatch, getState) => {
        const maxNoOfRequestsInBulkRequest = 100;
        let awaitedRequests = [];

        if (setLoaders) {
            dispatch(setIsLoading(true));
        }
        if (pendingAction) {
            dispatch(pendingAction());
        }

        const state = getState();
        const clientCode = state.verifyUserReducer.clientCode;
        const url = getUrl(clientCode);

        const sendBulkRequest = (formData, requests) => {
            return fetch(url, {
                method: 'POST',
                body: formData
            }).then(async res => {
                if (res.status === 429) {   // 429 Too Many Requests
                    console.log("429 error, waiting 10s before trying again");
                    isErrorCode429 = true;
                    dispatch(setIsLoading(true, true));
                    await timeout(10000);
                    return await sendBulkRequest(formData, requests);
                } else {
                    const resText = await res.text();
                    if (resText) {
                        const resJson = JSON.parse(resText);
                        console.log("BulkRequest result:", resJson);
                        if (resJson.status.errorCode > 0) {
                            return handleError(noOfReRequests, dispatch, errorAction, errorText, requests, resJson.status, state);
                        }

                        for (let i = 0, n = resJson.requests.length; i < n; i++) {
                            if (resJson.requests[i].status.errorCode > 0) {
                                return handleError(noOfReRequests, dispatch, errorAction, errorText, requests, resJson.requests[i].status, state);
                            }
                        }

                        const returnedData = returnOnlyRecords ? resJson.requests.map(request => request.records).flat() : resJson.requests;
                        if (successAction) {
                            dispatch(successAction(returnedData));
                        }
                        if (!isGetRequests && noOfReRequests > 0) logError("Previously failed request succeeded", requests);

                        return returnedData;
                    } else {    // Empty response, happens with 429 error
                        console.log("Empty response, waiting 10s");
                        dispatch(setIsLoading(true, true));
                        await timeout(10000);
                        return await sendBulkRequest(formData, requests);
                    }
                }
            }).catch(async error => {
                if (isOpenInAndroidApp && noOfReRequests < maxNoOfReRequests && (error.name === "AbortError" || (error.name === "TypeError" && error.message === "Network request failed"))) {
                    handleError(noOfReRequests, dispatch, errorAction, errorText, requests, null, state, error, false);
                    noOfReRequests ++;
                    return await sendBulkRequest(formData, requests);
                }
                return handleError(noOfReRequests, dispatch, errorAction, errorText, requests, null, state, error);
            });
        };

        for (let i = 0, n = Math.ceil(allRequests.length / maxNoOfRequestsInBulkRequest); i < n; i++) {
            const sliceStart = maxNoOfRequestsInBulkRequest * i;
            const sliceEnd = maxNoOfRequestsInBulkRequest * (i + 1);
            const requests = allRequests.slice(sliceStart, sliceEnd);

            const formData = new FormData();
            formData.append("clientCode", clientCode);
            formData.append("sessionKey", state.verifyUserReducer.user.sessionKey);
            formData.append("partnerKey", partnerKey);
            formData.append("requests", JSON.stringify(requests));

            awaitedRequests.push(sendBulkRequest(formData, requests, i));
        }

        return Promise.all(awaitedRequests).then((data) => {
            if (setLoaders || isErrorCode429) {
                dispatch(setIsLoading(false));
            }
            return [].concat(...data);
        });
    }
};

const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

const handleError = (noOfReRequests, dispatch, errorAction, errorMessageStart, params, responseStatus, state, errorFromCatchBlock = null, displayError = true, responseJson = null, url = null, formData = null) => {
    const clientCode = state.verifyUserReducer.clientCode;
    const component = state.componentReducer.component;
    const componentSequence = state.componentReducer.componentSequence;
    const userName = state.verifyUserReducer.user.userName;

    let error = {status: "error"};
    error.errorMessage = errorMessageStart;

    if (responseStatus && responseStatus.hasOwnProperty("errorCode") && responseStatus.errorCode !== "") {
        if (responseStatus.errorCode === 1054 || responseStatus.errorCode === 1055) {
            if (isInfralink(clientCode)) simpleLog(`Infralink sessionKey expired. Request: ${params.request} User: ${userName} Component: ${component}`);
            dispatch(logout(true));
        }

        error.errorMessage += ` Error code: ${responseStatus.errorCode}`;
        if (responseStatus.errorCode === 429) {
            error.errorMessage += ` Too many requests. Please try again later`;
        }
        error.errorCode = responseStatus.errorCode;
    }
    if (responseStatus && responseStatus.hasOwnProperty("errorField") && responseStatus.errorField !== "") {
        error.errorMessage += ` Error field: ${responseStatus.errorField}`;
        error.errorField = responseStatus.errorField;
    }
    if (errorFromCatchBlock !== null) {
        error.errorMessage += ` Error: ${errorFromCatchBlock}`;
    }

    if (displayError) {
        if (errorAction) {
            dispatch(errorAction(error.errorMessage));
        }
        dispatch(errorMessageSet(error.errorMessage));
    }

    if (params === null) {  // Request body is null
        params = {};
    }
    const isBulkRequest = Array.isArray(params);
    if (isBulkRequest || params.request !== "verifyUser") {  // Do not log username and password on faulty verifyUser request
        let logText = `${error.errorMessage} Error code: ${error.errorCode} ClientCode: ${clientCode} User: ${userName} Component: ${component} ComponentSequence: ${componentSequence} NoOfReRequests: ${noOfReRequests} isOpenInAndroid: ${isOpenInAndroidApp !== undefined}`;
        if (responseJson !== null) logText += ` Response JSON: ${JSON.stringify(responseJson)}`; // Log non-classic Erply API errors more thoroughly
        if (url !== null) logText += ` URL: ${url}`; // Log non-classic Erply API errors more thoroughly
        if (formData !== null) logText += ` FormData: ${JSON.stringify(Object.fromEntries(formData))}`;
        logError(logText, params);
        console.log("Error request params:", params);
    }

    // Take away loading dimmer so any error message would be seen
    dispatch(setIsLoading(false));
    return error;
};

const logError = (message, params) => {
    const body = {message: JSON.stringify(message), params: JSON.stringify(params)};
    let noOfReRequests = 0;

    const sendLog = () => {
        fetch("./services/logger.php", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }).then(response => {
            return response.text();
        }).then(data => {
            console.log(data);
        }).catch(err => {
            if (noOfReRequests < maxNoOfReRequests) {
                noOfReRequests ++;
                sendLog();
            }
        });
    };

    sendLog();
};

const getNoOfPagesOfRecordsLeft = (responseBody) => {
    const recordsInResponse = responseBody.status.recordsInResponse;
    const recordsTotal = responseBody.status.recordsTotal;

    return Math.ceil(recordsTotal / recordsInResponse) - 1;
};

export const getUserRights = (dispatch, t) => {
    const params = {request: "getUserRights", getCurrentUser: 1};
    return dispatch(makeErplyRequest(params, t("getUserRightsError"), null, null, null)).then(userRights => userRights[0]);
};

export const getReasonCodes = (t, setLoaders = false) => {
    return (dispatch) => {
        const params = {request: "getReasonCodes"};
        dispatch(makeErplyRequest(params, t("getReasonCodesError"), null, getReasonCodesSuccess, null, false, setLoaders));
    }
};

export const getConfParameters = (t, setLoaders = false) => {
    return (dispatch) => {
        const params = {request: "getConfParameters"};
        return dispatch(makeErplyRequest(params, t("getConfParametersError"), null, getConfParametersSuccess, null, false, setLoaders)).then((conf) => {
            return conf[0];
        });
    }
};

export const getVatRates = (t, setLoaders = false) => {
    return (dispatch) => {
        const params = {request: "getVatRates"};
        return dispatch(makeErplyRequest(params, t("getVatRatesError"), null, setVatRates, null, true, setLoaders));
    }
};

export const verifyUser = (t, clientCode, username, password) => {
    return async (dispatch, getState) => {
        const eSessSeparator = "^^";

        if (!clientCode && !username && !password) {
            const state = getState();
            const eSess = state.verifyUserReducer.eSess;
            clientCode = state.verifyUserReducer.clientCode;
            if (eSess === "" || clientCode === "") {
                if (isInfralink(clientCode)) simpleLog(`Infralink eSess empty`);
                return dispatch(logout(true));
            }
            const decryptedString = AES.decrypt(eSess, clientCode).toString(enc.Utf8);
            const parts = decryptedString.split(eSessSeparator);
            if (parts.length !== 2) {
                if (isInfralink(clientCode)) simpleLog(`Infralink eSess parts length not 2`);
                return dispatch(logout(true));
            }
            username = parts[0];
            password = parts[1];
        }

        const params = {
            request: "verifyUser",
            clientCode: clientCode,
            username: username,
            password: password,
            sessionLength: 86400
        };

        const response = await dispatch(makeErplyRequest(params, t("verifyUserError")));
        if (!responseHasErrors(response)) {
            const user = response[0];
            let eSess = "";
            if (dispatch(isInfralinkSelfService(clientCode, user))) {
                const encryptedString = `${username}${eSessSeparator}${password}`;
                eSess = AES.encrypt(encryptedString, clientCode).toString();
            }

            const sessionRefreshDate = new Date();
            sessionRefreshDate.setDate(sessionRefreshDate.getDate() + 1);
            const logOutTimestamp = sessionRefreshDate.getTime() + 5000;
            sessionRefreshDate.setSeconds(sessionRefreshDate.getSeconds() - 10 * 60);

            dispatch(verifyUserSuccess(user, clientCode, eSess, sessionRefreshDate.getTime(), logOutTimestamp));
        }

        return response;
    }
};

export const getBinRecords = (t, documentIDs, documentType, binIDs = null, setLoaders = false) => {
    return (dispatch, getState) => {
        const state = getState();
        const selectedWarehouse = state.getWarehousesReducer.selectedWarehouse;

        const params = {
            warehouseId: selectedWarehouse.warehouseID
        };

        if (binIDs !== null) {
            params.binId = binIDs;
        } else {
            params.referenceId = documentIDs;
            params.referenceType = documentType;
        }

        return dispatch(makeWmsApiRequest(t, "bin-inventory-record", "GET", t("getBinRecordsError"), params, null, setLoaders, getBinRecordsSuccess));
    }
};

export const getBins = (t, setLoader = false, postRequestAction = null, preferred = null, allowedProduct = "", code = "", binIDs = "") => {
    return (dispatch, getState) => {
        const state = getState();
        const selectedWarehouse = state.getWarehousesReducer.selectedWarehouse;

        const params = {
            warehouseId: selectedWarehouse.warehouseID,
            active: true,
            allowedProduct: allowedProduct,
            code: code
        };

        if (preferred !== null) params.preferred = preferred;
        if (binIDs !== "") params.id = binIDs;

        return dispatch(makeWmsApiRequest(t, "bin", "GET", t("getBinsError"), params, null, setLoader, postRequestAction)).then(response => {
            if (!responseHasErrors(response)) {
                response.forEach(bin => {
                    // Add compatibility with Erply Classic API
                    bin.binID = bin.id;
                    bin.order = bin.binOrder;
                });
            }

            return response
        });
    }
};

export const saveBin = (t, setLoader = false, code = null, preferred = false, order = 0, maximumAmount = 0, replenishmentMinimum = 0, allowedProduct = "", equipmentNeeded = "", id = null) => {
    return (dispatch, getState) => {
        const state = getState();
        const selectedWarehouse = state.getWarehousesReducer.selectedWarehouse;
        let method = "POST";
        let urlEnd = "bin";

        const params = {
            warehouseId: Number(selectedWarehouse.warehouseID),
            active: true,
            preferred: preferred,
            binOrder: Number(order),
            replenishmentMinimum: Number(replenishmentMinimum),
            maximumAmount: Number(maximumAmount),
            allowedProduct: String(allowedProduct),
            equipmentNeeded: String(equipmentNeeded)
        };

        if (code !== null) params.code = code;

        if (id !== null) {
            method = "PUT";
            urlEnd += `/${id}`;
        }

        return dispatch(makeWmsApiRequest(t, urlEnd, method, t("saveBinError"), null, params, setLoader)).then(response => {
            if (response.errorMessage === `${t("wmsApiError")}: ${t("saveBinError")} bin with the given code already exists`) {
                dispatch(errorMessageSet(t("binAlreadyExists")));
            }

            response.binID = response.id; // Add compatibility with Erply Classic API
            return response;
        });
    }
};

export const deleteBin = (t, setLoader = false, id) => {
    return (dispatch) => {
        return dispatch(makeWmsApiRequest(t, `bin/${id}`, "DELETE", t("deleteBinError"), null, null, setLoader));
    }
};

export const getScannedBatchesByDocument = async (dispatch, documents, t, setLoader = false) => {
    let scannedBatchesByDocument = [];
    for (let i = 0, n = documents.length; i < n; i++) {
        let jsonApiResponse = await dispatch(makeJsonApiRequest(t, `v1/json_object/prcinvoice/${documents[i].id}`, "GET", null, setLoader));
        if (jsonApiResponse.json_object !== null) {
            scannedBatchesByDocument.push({
                documentID: documents[i].id,
                scannedProducts: jsonApiResponse.json_object.WMS.scannedAmounts
            });
        }
    }
    return scannedBatchesByDocument;
};

export const sendRequest = (url, errorText = "", setLoader = true, method = "POST", headers = {}, body = null, returnErrorIfResponseIsNull = true, ignoreErrors = false) => {
    return (dispatch, getState) => {
        let noOfReRequests = 0;

        const state = getState();

        if (setLoader) {
            dispatch(setIsLoading(true));
        }

        const sendRequest = () => {
            return fetch(url, {
                method: method,
                headers: headers,
                body: body
            }).then(async response => {
                if (setLoader) {
                    dispatch(setIsLoading(false));
                }
                try {
                    const resJson = await response.json();
                    console.log(url, resJson);
                    if (resJson === null && !returnErrorIfResponseIsNull) {
                        if (method !== "GET" && noOfReRequests > 0) logError("Previously failed request succeeded", body);
                        return resJson;
                    }
                    if (!ignoreErrors && (resJson.error || (resJson.status && resJson.status.errorCode > 0))) {
                        if (resJson.hasOwnProperty("error")) {
                            errorText += ` ${resJson.error}`;
                        }
                        return handleError(noOfReRequests, dispatch, null, errorText, body, resJson.status, state, null, true, resJson, url);
                    }
                    if (method !== "GET" && noOfReRequests > 0) logError("Previously failed request succeeded", body);
                    if (!ignoreErrors && response.status >= 400) return handleError(noOfReRequests, dispatch, null, `${errorText}: ${resJson}`, body, null, state);
                    return resJson;
                } catch {
                    if (ignoreErrors) {
                        return {};
                    } else {
                        return handleError(noOfReRequests, dispatch, null, errorText, body, null, state);
                    }
                }
            }).catch(async error => {
                if (noOfReRequests < maxNoOfReRequests && (error.name === "AbortError" || (error.name === "TypeError" && error.message === "Network request failed"))) {
                    handleError(noOfReRequests, dispatch, null, errorText, body, null, state, error, false);
                    noOfReRequests++;
                    return await sendRequest();
                } else {
                    return handleError(noOfReRequests, dispatch, null, errorText, body, null, state, error);
                }
            });
        };

        return sendRequest();
    }
};

export const getServiceEndpoints = (t, setLoader = true) => {
    return async (dispatch, getState) => {
        const state = getState();
        const existingServiceEndpoints = state.newErplyApisReducer.serviceEndpoints;
        const clientCode = state.verifyUserReducer.clientCode;

        if (existingServiceEndpoints) {
            return existingServiceEndpoints;
        }

        let serviceEndpointsResponse;
        if (isProdOrPreProd()) {
            const url = `https://${clientCode}.erply.com/api/?request=getServiceEndpoints&sendContentType=1&clientCode=${clientCode}`;
            serviceEndpointsResponse = await dispatch(sendRequest(url, t("getServiceEndpointsError"), setLoader));
        } else {
            const formData = new FormData();
            formData.append("request", "getServiceEndpoints");
            formData.append("sendContentType", "1");
            formData.append("clientCode", clientCode);
            const url = `https://w2.intralplugins.com/proxy/?csurl=https://${clientCode}.erply.com/api/`;
            serviceEndpointsResponse = await dispatch(sendRequest(url, t("getServiceEndpointsError"), setLoader, "POST", {}, formData));
        }

        if (serviceEndpointsResponse.status !== "error") {
            dispatch(setServiceEndpoints(serviceEndpointsResponse.records[0]));
        }
        return serviceEndpointsResponse.records[0];
    }
};

const getServiceEndpointUrl = (t, apiName, urlEnd) => {
    return async (dispatch) => {
        const serviceEndpoints = await dispatch(getServiceEndpoints(t, false));
        if (!serviceEndpoints.hasOwnProperty(apiName)) {
            dispatch(errorMessageSet(apiName + " missing from serviceEndpoints"));
            return false;
        }
        const apiUrl = `${serviceEndpoints[apiName].url}${urlEnd}`;
        return isProdOrPreProd() ? apiUrl : `https://w2.intralplugins.com/proxy/?csurl=${apiUrl}`;
    }
};

const getServiceEndpointHeaders = () => {
    return (dispatch, getState) => {
        const state = getState();
        const sessionKey = state.verifyUserReducer.user.sessionKey;
        const clientCode = state.verifyUserReducer.clientCode;
        return {sessionKey: sessionKey, clientCode: clientCode};
    }
};

export const makeEmsApiRequest = (t, urlEnd, postRequestAction, method, body = null) => {
    return async (dispatch) => {
        const url = await dispatch(getServiceEndpointUrl(t, "ems", urlEnd));
        if (url) {
            const headers = dispatch(getServiceEndpointHeaders());
            const emsResponse = await dispatch(sendRequest(url, t("emsApiError"), false, method, headers, body));
            if (emsResponse.status !== "error" && postRequestAction) {
                dispatch(postRequestAction(emsResponse));
            }
            return emsResponse;
        }
    }
};

export const makeCafaApiRequest = (t, urlEnd, postRequestAction, method, body = null) => {
    return async (dispatch, getState) => {
        const state = getState();
        const url = await dispatch(getServiceEndpointUrl(t, "cafa", urlEnd));
        if (url) {
            const headers = dispatch(getServiceEndpointHeaders());
            let cafaResponse = await dispatch(sendRequest(url, t("cafaApiError"), false, method, headers, body, false));

            if (cafaResponse === null) {
                cafaResponse = [];
            }

            if (cafaResponse.statusCode && !cafaResponse.statusCode.startsWith("2")) {
                return handleError(0, dispatch, null, t("cafaApiError"), body, cafaResponse.statusCode, state, cafaResponse.message);
            } else if (postRequestAction) {
                dispatch(postRequestAction(cafaResponse));
            }

            return cafaResponse;
        }
    }
};

export const makeReportsApiRequest = (t, urlEnd, postRequestAction, queryParams, setLoader = false) => {
    return async (dispatch, getState) => {
        const state = getState();
        let url = await dispatch(getServiceEndpointUrl(t, "reports", urlEnd));
        if (url) {
            if (queryParams) {
                url += getQueryParamsString(queryParams);
            }

            const headers = dispatch(getServiceEndpointHeaders());
            const response = await dispatch(sendRequest(url, t("reportsApiError"), setLoader, "GET", headers, null));

            if (response.statusCode && !response.statusCode.startsWith("2")) {
                return handleError(0, dispatch, null, t("reportsApiError"), queryParams, response.statusCode, state, response.message);
            } else if (postRequestAction) {
                dispatch(postRequestAction(response));
            }

            return response;
        }
    }
};

export const makeJsonApiRequest = (t, urlEnd, method, body = null, setLoader = false) => {
    simpleLog("JSON API request params: " + JSON.stringify(body));
    return async (dispatch) => {
        const url = await dispatch(getServiceEndpointUrl(t, "json", urlEnd));
        if (url) {
            const headers = dispatch(getServiceEndpointHeaders());
            return await dispatch(sendRequest(url, t("jsonApiError"), setLoader, method, headers, body));
        }
    }
};

export const makePimApiRequest = (t, urlEnd, method, body = null, setLoader = false) => {
    return async (dispatch) => {
        const url = await dispatch(getServiceEndpointUrl(t, "pim", urlEnd));
        if (url) {
            const headers = dispatch(getServiceEndpointHeaders());
            return await dispatch(sendRequest(url, t("pimApiError"), setLoader, method, headers, body));
        }
    }
};

// Rename fields to make them compatible with Erply API
const renameWmsApiFields = (response) => {
    return JSON.parse(JSON.stringify(response).split('"binId":').join('"binID":')
        .split('"productId":').join('"productID":')
        .split('"bundleId":').join('"bundleID":')
        .split('"packageId":').join('"packageID":')
        .split('"referenceId":').join('"documentID":'));
};

const getQueryParamsString = (params) => {
    return "?" + Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
};

export const makeWmsApiRequest = (t, urlEnd, method, errorMessage, queryParams = null, body = null, setLoader = false, postRequestAction = null, ignoreErrors = false, pageSize = "999999") => {
    return async (dispatch) => {
        urlEnd = "v1/" + urlEnd;
        let url = await dispatch(getServiceEndpointUrl(t, "wms", urlEnd));
        if (url) {
            const headers = dispatch(getServiceEndpointHeaders());
            errorMessage = `${t("wmsApiError")}: ${errorMessage}`;

            if (queryParams) {
                url += getQueryParamsString(queryParams) + `&pageSize=${pageSize}`;
            }

            if (body) {
                body = JSON.stringify(body);
            }

            const response = renameWmsApiFields(await dispatch(sendRequest(url, errorMessage, setLoader, method, headers, body, true, ignoreErrors)));

            if (response.status !== "error" && postRequestAction) {
                dispatch(postRequestAction(response));
            }

            return response;
        }
    }
};

export const responseHasErrors = (response) => {
    const isBulkRequest = Array.isArray(response);
    if (isBulkRequest) return response.some(resp => resp.status === "error");

    return response.status === "error";
};

export const saveAttributeToDocument = (document, attributeName, attributeValue, t, isAssembly = false) => {
    return (dispatch, getState) => {
        const state = getState();
        const componentSequence = state.componentReducer.componentSequence;
        const errorMessage = `${t("failedToSaveAttribute")} ${attributeName}`;
        const idFieldName = getIdFieldName(documentIsInventoryTransfer(componentSequence), isAssembly, documentIsAssignment(componentSequence));

        dispatch(makeErplyRequest({
            request: getSaveDocumentRequestName(componentSequence, isAssembly),
            [idFieldName]: document[idFieldName],
            attributeName0: attributeName,
            attributeValue0: attributeValue
        }, errorMessage, null, null, null, false, false))
    }
};

export const getMailboxes = (t) => {
    return (dispatch, getState) => {
        const state = getState();
        const mailboxes = state.newErplyApisReducer.mailboxes;
        const clientCode = state.verifyUserReducer.clientCode;
        return mailboxes.length === 0 ? dispatch(makeEmsApiRequest(t, `api/v2/mailbox?mailboxIsPrimary=true&mailboxNameLike=${clientCode}`, setMailboxes, "GET")) : mailboxes;
    }
};

export const sendEmail = (t, subject, body, toAddress, base64File = null, fileName = null) => {
    return async (dispatch) => {
        if (!toAddress || toAddress === "") return console.log("Receiver address is empty. Email was not sent.");

        const mailboxes = await dispatch(getMailboxes(t));
        if (mailboxes.length === 0) return console.log("No mailboxes found. Email was not sent.");

        const requestBody = {
            "subject": subject,
            "type": "email",
            "bodies": [{
                "contentType": "text/plain",
                "content": btoa(body)
            }],
            "contacts": [
                {
                    "address": mailboxes[0].name,
                    "name": "Erply",
                    "type": "from"
                },
                {
                    "address": toAddress,
                    "name": "Receiver",
                    "type": "to"
                }
            ]
        };

        if (base64File !== null && fileName !== null) {
            requestBody.files = [{
                "contentType": "application/pdf",
                "disposition": "attachment",
                "filename": fileName,
                "content": base64File
            }];
        }

        console.log(`Sending email to ${toAddress}`);
        dispatch(makeEmsApiRequest(t, "api/v2/message", null, "POST", JSON.stringify(requestBody)));
    }
};

export const getSalesDocumentArDataset = (t, saveRequestResponse, customer) => {
    return async (dispatch, getState) => {
        const state = getState();
        const clientCode = state.verifyUserReducer.clientCode;

        const params = {
            request: "getSalesDocumentActualReportsDataset",
            salesDocumentID: saveRequestResponse[0].invoiceID
        };

        if (customer.countryID != 1 && customer.countryID != 0) params.lang = "eng";

        const response = await dispatch(makeErplyRequest(params, t("getSalesDocumentActualReportsDatasetError"), null, null, null, false, false));
        const dataset = response[0];

        // Fix API response logo bug present 2023-06-14
        const startString = "https://";
        if (dataset.logoOnInvoicesURL.substr(startString.length, clientCode.length) === clientCode) {
            dataset.logoOnInvoicesURL = `${startString}eu${dataset.logoOnInvoicesURL.substr(startString.length + clientCode.length)}`;
        }

        return dataset;
    }
};

export const getBinQuantities = (t, setLoader = false, postRequestAction = null, binIDs = null, productIDs = null, minimumAmount = null) => {
    return (dispatch, getState) => {
        const state = getState();
        const selectedWarehouse = state.getWarehousesReducer.selectedWarehouse;

        const params = {
            request: "getBinQuantities",
            warehouseID: selectedWarehouse.warehouseID,
        };

        if (binIDs !== null) params.binIDs = binIDs;
        if (productIDs !== null) params.productIDs = productIDs;
        if (minimumAmount !== null) params.minimumAmount = minimumAmount;

        return dispatch(makeErplyRequest(params, t("getBinQuantitiesError"), null, postRequestAction, null, true, setLoader));
    }
};

export const getWmsApiBinQuantities = (t, setLoader = false, postRequestAction = null, binIDs = null, productIDs = null, minimumAmount = null, pageSize = "999999") => {
    return async (dispatch, getState) => {
        const state = getState();
        const selectedWarehouse = state.getWarehousesReducer.selectedWarehouse;

        const params = {
            warehouseId: selectedWarehouse.warehouseID,
        };

        if (binIDs !== null) params.binId = binIDs;
        if (productIDs !== null) params.productId = productIDs;
        if (minimumAmount !== null) params.minimumAmount = minimumAmount;

        let binQuantities = await dispatch(makeWmsApiRequest(t, "bin-inventory", "GET", t("getBinQuantitiesError"), params, null, setLoader, postRequestAction, false, pageSize));
        binQuantities = binQuantities.filter(binQuantity => binQuantity.productCode1 !== null); // Filter out quantities of deleted products
        // Rename fields to make them compatible with Erply API
        binQuantities.forEach(binQuantity => {
            binQuantity.productCode = binQuantity.productCode1;
            binQuantity.productSupplierCode = binQuantity.productCode4;
        });
        return binQuantities;
    }
};
