const initialState = {
    undeliveredGoods: [],
};

export const undeliveredGoodsReducer = (state = initialState, action) => {
    switch(action.type) {
        case "SET_UNDELIVERED_GOODS":
            return {
                ...state,
                undeliveredGoods: action.payload
            };
        default:
            return state;
    }
};
