import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Header, Icon, Modal} from "semantic-ui-react";
import {setInformativeModal} from "../actions/informativeModal";
import {useTranslation} from "react-i18next";

const InformativeModal = () => {
    const dispatch = useDispatch();

    const text = useSelector(state => state.informativeModalReducer.text);
    const header = useSelector(state => state.informativeModalReducer.header);

    const { t } = useTranslation();

    const isOpen = text !== "";

    const close = () => {
        dispatch(setInformativeModal("", ""));
    };

    const getContent = () => {
        const textOnNewLines = [].concat(...text.split("<br/>").map(n => [n, <br/>])).slice(0, -1);
        const content = [<h3 className={"textAlignCenter"}>{textOnNewLines}</h3>];
        if (header) content.unshift(<h2 className={"textAlignCenter"}>{header}</h2>);
        return content;
    };

    return (
        <div>
            <Modal open={isOpen} onClose={close} basic size='small'>
                <Header className={"textAlignCenter"} content={header}>
                    <Icon className={"margin0"} name='warning sign'/>
                </Header>
                <Modal.Content>
                    {getContent()}
                </Modal.Content>
                <Modal.Actions className={"textAlignCenter"}>
                    <Button className={"margin0"} color='green' onClick={close} inverted>
                        <Icon name='checkmark' /> {t("informativeModalOkBtnText")}
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    )
};

export default InformativeModal;
