import {componentSet, setIsLoading} from "../actions/component";
import {Button, Checkbox, Dropdown, Form, Icon, Input, Table} from "semantic-ui-react";
import React, {useState, useEffect, useRef, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getReasonCodes, makeErplyRequest} from "../util/erplyRequests";
import {errorMessageSet, successMessageSet} from "../actions/headerMessage";
import {setModal} from "../actions/modal";
import {getBinQuantitiesSuccess} from "../actions/getBinQuantities";
import {getProductsSuccess} from "../actions/getProducts";
import {
    getGetProductsCodeOrder,
    isNotAPositiveInteger,
    isSelectedRow,
    setExpandedRow,
    translateBinFromEng,
    translateBinToEng, unifyWhiteSpace
} from "../util/misc";
import PrintLabelsButton from "./PrintLabelsButton";

const AmountsInBins = () => {
    const totalAmountOfQueryProduct = useRef(0);
    const productSearchInput = useRef(null);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const bins = useSelector(state => state.getBinsReducer.bins);
    const binQuantities = useSelector(state => state.getBinQuantitiesReducer.binQuantities);
    const products = useSelector(state => state.getProductsReducer.products);
    const user = useSelector(state => state.verifyUserReducer.user);
    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);
    const language = useSelector(state => state.languageReducer.language);
    const reasonCodes = useSelector(state => state.getReasonCodesReducer.reasonCodes);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);

    const createInventoryRegistrationOnAmountChange = confParameters.wmsUseProductLocationsInWarehouse == 1 && confParameters.wmsCreateInvRegistrationOnBinAmountChange == 1;
    const fieldNamesToSearchFor = useMemo(() => getGetProductsCodeOrder(confParameters).concat("name"), [confParameters]);

    const [recordsOnPage, setRecordsOnPage] = useState(20);
    const [searchResults, setSearchResults] = useState(bins);
    const [displayableSearchResults, setDisplayableSearchResults] = useState(searchResults.slice(0, recordsOnPage));
    const [binCode, setBinCode] = useState("");
    const [productSearchString, setProductSearchString] = useState("");
    const [pageNo, setPageNo] = useState(1);
    const [expandedRows, setExpandedRows] = useState([]);
    const [editedBinID, setEditedBinID] = useState("");
    const [changedAmounts, setChangedAmounts] = useState([]);
    const [initialAmounts, setInitialAmounts] = useState([]);   // Initial amounts in a bin selected for change
    const [preferred, setPreferred] = useState(true);
    const [unpreferred, setUnpreferred] = useState(true);
    const [reasonCode, setReasonCode] = useState(null);
    const [getBinQuantitiesOnPageChange, setGetBinQuantitiesOnPageChange] = useState(true);

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown);
        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    });

    useEffect(() => {
        if (createInventoryRegistrationOnAmountChange) {
            dispatch(getReasonCodes(t));
        }
        getDisplayedBinsQuantities(displayableSearchResults);
    }, []);

    const getDisplayedBinsQuantities = (displayableSearchResults, setLoaders = true, requestProducts = true) => {
        getBinQuantities(displayableSearchResults, setLoaders).then((binQuantities) => {
            const productIDs = [...new Set(binQuantities.map(binQuantity => binQuantity.productID))].join(",");
            if (productIDs !== "" && requestProducts) {
                getProducts({productIDs: productIDs});
            }
        });
    };

    const getProducts = (extraParams) => {
        let getAllPages = true;
        const params = {request: "getProducts"};
        Object.assign(params, extraParams);

        if (extraParams.hasOwnProperty("searchNameIncrementally")) {
            getAllPages = false;    // searchNameIncrementally returns a random large number for "recordsTotal"
        }

        return dispatch(makeErplyRequest(params, t("getProductsError"), null, getProductsSuccess, null, getAllPages));
    };

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSearchButtonOnClick();
        }
    };

    const handleGoBackOnClick = () => {
        dispatch(componentSet("MainMenu"));
    };

    const createBinTable = () => {
        return (
            <Table color={"grey"} celled inverted unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t("bin")}</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2}>{t("preferred")}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {createBinRows()}
                </Table.Body>
            </Table>
        )
    };

    const createBinRows = () => {
        return displayableSearchResults.map(bin => createBinRow(bin));
    };

    const createBinRow = (bin) => {
        const isSelected = isSelectedRow(bin.binID, expandedRows);
        const isBeingEdited = editedBinID === bin.binID;
        const preferred = bin.preferred ? t("yes") : t("no");

        const buttons = isBeingEdited && isSelected?
            <div>
                <Button size={"tiny"} color={"green"} icon={"check"} onClick={() => {validateChangedAmounts(bin)}}/>
                <Button size={"tiny"} color={"red"} icon={"cancel"} onClick={() => {handleCancelChangeClick(bin)}}/>
            </div> :
            <Button size={"tiny"} onClick={() => handleChangeButtonOnClick(bin)}>{t("change")}</Button>;

        let row =
            [<Table.Row key={bin.binID}>
                <Table.Cell onClick={() => setExpandedRow(bin.binID, expandedRows, setExpandedRows)}>{translateBinFromEng(bin.code, language)}</Table.Cell>
                <Table.Cell onClick={() => setExpandedRow(bin.binID, expandedRows, setExpandedRows)}>{preferred}</Table.Cell>
                <Table.Cell className={"smallCell"}>{buttons}</Table.Cell>
            </Table.Row>];

        if (isBeingEdited && isSelected && createInventoryRegistrationOnAmountChange) {
            row.push(createReasonCodeDropdown());
        }

        if (isSelected) {
            row.push(createExtraInfoTable(bin));
        }

        return row;
    };

    const validateChangedAmounts = (bin) => {
        let amountsHaveChanged = false;

        for (let i = 0, n = changedAmounts.length; i < n; i++) {
            if (changedAmounts[i].amount < 0) {
                return dispatch(errorMessageSet(t("mustBeANonNegativeInteger")));
            }

            if (!amountsHaveChanged) {
                const amountHasChanged = initialAmounts.find(amount => amount.productID === changedAmounts[i].productID).amount != changedAmounts[i].amount;

                if (amountHasChanged) {
                    amountsHaveChanged = true;
                }
            }
        }

        if (!amountsHaveChanged) {
            return dispatch(errorMessageSet(t("noChanges")));
        }

        if (reasonCode === null && createInventoryRegistrationOnAmountChange) {
            return dispatch(errorMessageSet(t("selectReason")));
        }

        dispatch(setModal(t("confirmation"), t("confirmChange?"), () => confirmChanges(bin)));
    };

    const confirmChanges = (bin) => {
        const adjustBinQuantitiesParams = {request: "adjustBinQuantities"};

        if (createInventoryRegistrationOnAmountChange) {
            var saveInventoryRegistrationParams = {
                request: "saveInventoryRegistration",
                creatorID: user.employeeID,
                warehouseID: selectedWarehouse.warehouseID,
                cause: t("binAmountCorrection"),
                reasonID: reasonCode.reasonID
            };

            var saveInventoryRegistrationRequestCounter = 0;
        }

        for (let i = 0, n = changedAmounts.length; i < n; i++) {
            const oldAmount = initialAmounts.find(amount => amount.productID === changedAmounts[i].productID).amount;
            const amountChange = changedAmounts[i].amount - oldAmount;

            if (amountChange !== 0) {
                adjustBinQuantitiesParams[`binID${i}`] = bin.binID;
                adjustBinQuantitiesParams[`productID${i}`] = changedAmounts[i].productID;
                adjustBinQuantitiesParams[`newAmount${i}`] = changedAmounts[i].amount === "" ? 0 : changedAmounts[i].amount;
                adjustBinQuantitiesParams[`creatorID${i}`] = user.employeeID;

                if (createInventoryRegistrationOnAmountChange) {
                    saveInventoryRegistrationParams[`productID${saveInventoryRegistrationRequestCounter}`] = changedAmounts[i].productID;
                    saveInventoryRegistrationParams[`amount${saveInventoryRegistrationRequestCounter}`] = amountChange;
                    saveInventoryRegistrationRequestCounter ++;
                }
            }
        }

        let saveRequests = [adjustBinQuantities(adjustBinQuantitiesParams)];
        if (createInventoryRegistrationOnAmountChange) {
            saveRequests.push(saveInventoryRegistration(saveInventoryRegistrationParams));
        }

        dispatch(setIsLoading(true));
        Promise.all(saveRequests).then(data => {
            let getRequests = [getDisplayedBinsQuantities(displayableSearchResults, false, false)];

            if (createInventoryRegistrationOnAmountChange) {
                const inventoryRegistrationID = data[1][0].inventoryRegistrationID;
                getRequests.push(getInventoryRegistration(inventoryRegistrationID));
            }

            Promise.all(getRequests).then(data => {
                dispatch(setIsLoading(false));
                let successMessage = `${t("binQuantitiesAdjusted")}!`;

                if (createInventoryRegistrationOnAmountChange) {
                    const inventoryRegistration = data[1];
                    successMessage += ` ${t("inventoryRegistrationCreated")} ${t("withNo")} ${inventoryRegistration.inventoryRegistrationNo}`;
                }

                dispatch(successMessageSet(successMessage, 5000));
                setEditedBinID("");
                setReasonCode(null);
            })
        });
    };

    const getInventoryRegistration = (inventoryRegistrationID) => {
        const params = {
            request: "getInventoryRegistrations",
            inventoryRegistrationID: inventoryRegistrationID
        };

        return dispatch(makeErplyRequest(params, t("getInventoryRegistrationsError"), null, null, null,
            false, false)).then((inventoryRegistrations => {
                return inventoryRegistrations[0];
        }))
    };

    const saveInventoryRegistration = (params) => {
        return dispatch(makeErplyRequest(params, t("saveInventoryRegistrationError"), null, null, null, false, false));
    };

    const adjustBinQuantities = (params) => {
        return dispatch(makeErplyRequest(params, t("adjustBinQuantitiesError"), null, null, null, false, false));
    };

    const getBinQuantities = (bins, setLoaders) => {
        const binIDs = bins.map(bin => bin.binID).join(",");

        const params = {
            request: "getBinQuantities",
            binIDs: binIDs,
            warehouseID: selectedWarehouse.warehouseID,
            minimumAmount: 0.000001
        };

        return dispatch(makeErplyRequest(params, t("getBinQuantitiesError"), null, getBinQuantitiesSuccess, null, true, setLoaders));
    };

    const getBinQuantitiesWithProducts = async (productIDs) => {
        const params = {
            request: "getBinQuantities",
            productIDs: productIDs,
            warehouseID: selectedWarehouse.warehouseID,
            minimumAmount: 0.000001
        };

        return dispatch(makeErplyRequest(params, t("getBinQuantitiesError"), null, getBinQuantitiesSuccess, null, true));
    };

    const handleCancelChangeClick = (bin) => {
        setEditedBinID("");
        setReasonCode(null);
        setExpandedRow(bin.binID, expandedRows, setExpandedRows)
    };

    const createExtraInfoTable = (bin) => {
        return (
            <tr key={bin.code}>
                <td colSpan={3}>
                    <Table celled structured unstackable color={"blue"} inverted>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t("product")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("code")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("amount")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {createExtraInfoTableRows(bin)}
                        </Table.Body>
                    </Table>
                </td>
            </tr>
        )
    };

    const createReasonCodeDropdown = () => {
        return (
            <tr key={"reasonCodeDropdown"}>
                <td colSpan={3}>
                    <Dropdown
                        fluid
                        className={"fullWidth"}
                        placeholder={t("enterReason")}
                        selection
                        options={getReasonCodeOptions()}
                        search
                        onChange={handleReasonCodeDropdownChange}
                        noResultsMessage={t("noResultsFound")}
                        searchInput={{className: "fullHeight" }}
                    />
                </td>
            </tr>
        )
    };

    const handleReasonCodeDropdownChange = (event, { value }) => {
        const reasonCode = reasonCodes.find(reasonCode => reasonCode.reasonID === value);
        setReasonCode(reasonCode);
    };

    const getReasonCodeOptions = () => {
        return reasonCodes.map(reasonCode => getReasonCodeOption(reasonCode));
    };

    const getReasonCodeOption = (reasonCode) => {
        return {key: reasonCode.reasonID, text: reasonCode.name, value: reasonCode.reasonID};
    };

    const createExtraInfoTableRows = (bin) => {
        if (binQuantities) {
            const quantities = binQuantities.filter(binQuantity => binQuantity.binID === bin.binID);
            return quantities.map((quantity, index) => createExtraInfoTableRow(quantity, index));
        }
    };

    const createExtraInfoTableRow = (quantity, index) => {
        if (quantity.amount != 0 && quantity.productCode !== null) {
            const product = products.find(product => product.productID === quantity.productID);

            if (product) {
                if (productSearchString !== "" && !products.some(requestedProduct => requestedProduct.productID == product.productID)) {
                    return null;    // Display only rows with the searchable product
                }

                const isBeingEdited = editedBinID === quantity.binID;
                const amountCell = isBeingEdited ?
                    <Input className={"widthSmall"} type={"number"} onInput={e => setNewChangedAmounts(product.productID, e.target.value)}
                           value={changedAmounts.find(amount => amount.productID === product.productID).amount}/> :
                    quantity.amount;

                let nameCell = [<p key={product.code}>{product.name}</p>];
                nameCell.push(<PrintLabelsButton products={[product]} className={"marginLeftSmall"} size={"mini"} text={t("printLabel")}/>);

                return (
                    <Table.Row key={index}>
                        <Table.Cell><div className={"flex"}>{nameCell}</div></Table.Cell>
                        <Table.Cell>{product.code}</Table.Cell>
                        <Table.Cell>{amountCell}</Table.Cell>
                    </Table.Row>
                )
            }
        }
    };

    const setNewChangedAmounts = (productID, newAmount) => {
        const changedAmountsCopy = changedAmounts.slice();
        changedAmountsCopy.find(amount => amount.productID === productID).amount = newAmount;
        setChangedAmounts(changedAmountsCopy);
    };

    const handleOpenAllOnClick = () => {
        const allTablesAreOpen = expandedRows.length === bins.length;
        if (allTablesAreOpen) {
            setExpandedRows([]);    // Close all extra info tables
        } else {
            openAllExtraInfoTables();
        }
    };

    const openAllExtraInfoTables = () => {
        const binIDs = bins.map(bin => bin.binID);
        setExpandedRows(binIDs);
    };

    const setNextPage = () => {
        if (pageNo * recordsOnPage < searchResults.length) {
            const newPageNo = pageNo + 1;
            setPageNo(newPageNo);
            setExpandedRows([]);    // Close all extra info tables
            setNewDisplayableSearchResults(newPageNo, recordsOnPage, searchResults, getBinQuantitiesOnPageChange);
        }
    };

    const setNewDisplayableSearchResults = (pageNo, recordsOnPage, searchResults, getBinQuantities = false) => {
        const sliceStart = (pageNo - 1) * recordsOnPage;
        const sliceEnd = pageNo * recordsOnPage;
        const newDisplayableSearchResults = searchResults.slice(sliceStart, sliceEnd);

        setDisplayableSearchResults(newDisplayableSearchResults);
        if (getBinQuantities) { // Product name/code input is empty and therefore products and bin quantities have not been requested
            getDisplayedBinsQuantities(newDisplayableSearchResults);
        }
    };

    const setPreviousPage = () => {
        if (pageNo > 1) {
            const newPageNo = pageNo - 1;
            setPageNo(newPageNo);
            setExpandedRows([]);    // Close all extra info tables
            setNewDisplayableSearchResults(newPageNo, recordsOnPage, searchResults, getBinQuantitiesOnPageChange);
        }
    };

    const handleChangeButtonOnClick = (bin) => {
        const extraInfoTableIsOpen = expandedRows.some(binID => binID === bin.binID);
        if (!extraInfoTableIsOpen) {
            setExpandedRow(bin.binID, expandedRows, setExpandedRows);
        }

        const quantities = binQuantities.filter(binQuantity => binQuantity.binID === bin.binID && binQuantity.amount != 0);
        const initialAmounts = quantities.map(quantity => ({productID: quantity.productID, amount: quantity.amount}));
        const initialAmounts2 = quantities.map(quantity => ({productID: quantity.productID, amount: quantity.amount})); // Otherwise setNewChangedAmounts function will also set new initial amounts
        setChangedAmounts(initialAmounts);
        setInitialAmounts(initialAmounts2);

        setEditedBinID(bin.binID);
    };

    const handleSearchButtonOnClick = async () => {
        setExpandedRows([]);    // Close all extra info tables
        let searchResults = bins;
        totalAmountOfQueryProduct.current = 0;
        let getBinQuantitiesAfterwards = true;
        setGetBinQuantitiesOnPageChange(true);

        if (binCode !== "") {
            searchResults = searchResults.filter(bin => bin.code.toLowerCase().includes(translateBinToEng(binCode.toLowerCase(), language)));
        }
        if (preferred !== unpreferred) {    // If both checkboxes are checked/unchecked search both preferred and unpreferred
            const preferredValue = preferred == 1 ? 1 : 0;
            searchResults = searchResults.filter(bin => bin.preferred == preferredValue);
        }
        if (productSearchString !== "") {
            const products = await getProducts({searchNameIncrementally: productSearchString, searchCodeFromMiddle: 1});

            if (products.length > 0) {
                const productIDs = products.map(product => product.productID).join(",");
                const binQuantities = await getBinQuantitiesWithProducts(productIDs);
                const searchStringLowerCase = productSearchString.toLowerCase();
                searchResults = searchResults.filter(bin => binHasProduct(bin, binQuantities, products, searchStringLowerCase));
                setGetBinQuantitiesOnPageChange(false);
                getBinQuantitiesAfterwards = false;
            }
        }

        let correctedRecordsOnPage = recordsOnPage;
        if (isNotAPositiveInteger(correctedRecordsOnPage)) {
            correctedRecordsOnPage = 20;
        } else if (correctedRecordsOnPage > 100) {
            correctedRecordsOnPage = 100;
        }
        setRecordsOnPage(correctedRecordsOnPage);

        let correctedPageNo = pageNo;
        if (isNotAPositiveInteger(correctedPageNo)) {
            correctedPageNo = 1;
        } else if (correctedPageNo !== 1) {
            while (correctedPageNo > 1) {
                if (searchResults.length <= (correctedPageNo - 1) * correctedRecordsOnPage) {   // If user-set page would be empty, set last page containing search results
                    correctedPageNo --;
                } else {
                    break;
                }
            }
        }
        setPageNo(correctedPageNo);

        setSearchResults(searchResults);
        setNewDisplayableSearchResults(correctedPageNo, correctedRecordsOnPage, searchResults, getBinQuantitiesAfterwards);
    };

    // Also updates totalAmountOfQueryProduct
    const binHasProduct = (bin, binQuantities, products, searchStringLowerCase) => {
        const quantities = binQuantities.filter(binQuantity => binQuantity.binID === bin.binID);
        let hasProduct = false;

        for (let i = 0, n = quantities.length; i < n; i++) {
            const product = products.find(product => product.productID === quantities[i].productID);

            for (let j = 0, n = fieldNamesToSearchFor.length; j < n; j++) {
                const fieldValue = product[fieldNamesToSearchFor[j]];

                if (fieldValue !== null && unifyWhiteSpace(fieldValue.toLowerCase()).includes(searchStringLowerCase)) {
                    hasProduct = true;
                    totalAmountOfQueryProduct.current = totalAmountOfQueryProduct.current + Number(quantities[i].amount);
                    break;
                }
            }
        }

        return hasProduct;
    };

    const clearProductSearch = () => {
        setProductSearchString("");
        productSearchInput.current.focus();
    };

    return (
        <div className={"overflow"}>
            <Form unstackable>
                <Form.Group>
                    <Form.Input onInput={e => setBinCode(e.target.value)} value={binCode} label={<label className={"white fontSize08em"}>{t("bin")}:</label>} width={6} />
                    <Form.Field width={6}>
                        <label className={"white fontSize08em"}>{`${t("product")} (${t("nameOrCode")})`}:</label>
                        <Input ref={productSearchInput} onInput={e => setProductSearchString(e.target.value)} value={productSearchString}
                               icon={<Icon name='delete' link onClick={clearProductSearch}/>}/>
                    </Form.Field>
                    <Form.Group grouped>
                        <div className={"marginLeftSmall"}>
                            <Checkbox checked={preferred} onChange={() => setPreferred(!preferred)} className={"marginTopSmall floatLeft"} label={<label className={"white fontSize08em"}>{t("preferred")}</label>}/><br/>
                            <Checkbox checked={unpreferred} onChange={() => setUnpreferred(!unpreferred)} className={"marginTopSmall floatLeft"} label={<label className={"white fontSize08em"}>{t("unpreferred")}</label>}/>
                        </div>
                    </Form.Group>
                </Form.Group>
            </Form>
            <div className={"white boldText flex marginTopSmall"}>
                <div>
                    <label className={"fontSize08em"}>{t("binsOnPage")}:</label>
                    <Input type={"number"} className={"widthVerySmall marginLeftSmall"} onInput={e => setRecordsOnPage(e.target.value)} value={recordsOnPage}/>
                </div>
            </div>
            <div className={"white boldText flex marginTopSmall"}>
                <div>
                    <label className={"fontSize08em"}>{t("pageNo")}:</label>
                    <Button onClick={setPreviousPage} className={"marginLeftSmall margin0Right amountsInBinsChangePageBtn"}>{"<<"}</Button>
                    <Input type={"number"} className={"widthVerySmall"} onInput={e => setPageNo(e.target.value)} value={pageNo}/>
                    <Button onClick={setNextPage} className={"amountsInBinsChangePageBtn"}>{">>"}</Button>
                </div>
            </div>
            <Button onClick={handleSearchButtonOnClick} className={"marginTopSmall marginBottomSmall fullWidth"} size={"big"} primary>{t("search")}</Button>
            <div>
                <p className={"white floatLeft marginTopSmall"}>{t("totalBins")}: <b>{bins.length}</b></p>
                <p className={"white floatLeft marginTopSmall marginLeftSmall marginRightSmall"}>{t("searchResults")}: <b>{searchResults.length}</b></p>
                <div className={"floatRight marginBottomSmall"}>
                    <Button onClick={handleOpenAllOnClick} size={"small"}>{t("openAll")}</Button>
                </div>
                <p className={"white floatLeft marginTopSmall marginBottomSmall"}>{t("totalAmountOfQueryProduct")}: <b>{totalAmountOfQueryProduct.current}</b></p>
            </div>
            {createBinTable()}
            <Button className={"menuBtn"} onClick={handleGoBackOnClick} size='large'>
                <Icon name={"chevron circle left"}/>
                {t("goBack")}
            </Button>
        </div>
    )
};

export default AmountsInBins
