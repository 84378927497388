import React, { useState } from 'react';
import {Button, Input, Transition} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {componentSet} from "../actions/component";
import {useDispatch, useSelector} from "react-redux";
import GoBackToStartBtn from "./GoBackToStartBtn";
import {errorMessageSet, successMessageSet} from "../actions/headerMessage";
import readXlsxFile from 'read-excel-file';
import {makeErplyBulkRequest, makeErplyRequest} from "../util/erplyRequests";
import GoBackBtn from "./GoBackBtn";
import {isNotAPositiveInteger} from "../util/misc";

const fileUrl = "https://w2.intralplugins.com/excelTemplates/importBinOrder.xlsx";
const maxFileSize = 10; // MB

const ImportBinOrder = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [uploadedRows, setUploadedRows] = useState([]);
    const [showExplanations, setShowExplanations] = useState(false);

    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);

    const handleGoBackOnClick = () => {
        dispatch(componentSet("BinManagement"));
    };

    const validateFile = (event) => {
        setUploadedRows([]);
        const file = event.target.files[0];

        if (!isCorrectSize(file)) {
            dispatch(errorMessageSet(`${t("fileSizeExceeds")} ${maxFileSize} MB`));
            clearFileInput();
            return
        }

        readExcelData(file);
    };

    const isCorrectSize = (file) => {
        return !(file.size / 1024 / 1024 > maxFileSize);
    };

    const clearFileInput = () => {
        document.getElementById("uploadFile").value = "";
    };

    const readExcelData = (file) => {
        const schema = {
            'Name': {
                prop: 'code',
                type: String
            },
            'Pickup no': {
                prop: 'order',
                parse(value) {
                    if (value !== "" && isNotAPositiveInteger(value)) {
                        throw new Error(t("invalidPickupNo"))
                    }
                    return value
                }
            },
        };

        readXlsxFile(file, { schema }).then(({ rows, errors }) => {
            if (rows === undefined || rows.length == 0) {
                return displayErrorMessage(`${t("invalidOrEmptyFile")}: "Name", "Pickup no"`);
            }

            if (errors.length > 0) {
                return displayErrorMessage(`${errors[0].error}. ${t("row")}: ${errors[0].row + 1}. ${t("column")}: ${errors[0].column}. ${t("value")}: ${errors[0].value}`);
            }

            for (let i = 0; i < rows.length; i++) {
                if (!rows[i].hasOwnProperty("code")) {
                    return displayErrorMessage(`${t("rowMustHaveName")}. ${t("row")}: ${i + 2}`);
                }
                if (!rows[i].hasOwnProperty("order")) {
                    return displayErrorMessage(`${t("rowMustHavePickupNo")}. ${t("row")}: ${i + 2}`);
                }
            }

            setUploadedRows(rows);
            console.log("Uploaded rows:", rows);
        })
    };

    const displayErrorMessage = (message) => {
        dispatch(errorMessageSet(message));
        return clearFileInput();
    };

    const saveBins = async () => {
        if (uploadedRows.length === 0) {
            return dispatch(errorMessageSet(t("noFile")));
        }

        getBins().then((bins) => {
            let requests = [];

            for (let i = 0, n = uploadedRows.length; i < n; i++) {
                const existingBin = bins.find(bin => bin.code.toLowerCase() == String(uploadedRows[i].code).toLowerCase());
                if (!existingBin) {
                    return displayErrorMessage(`${uploadedRows[i].code} ${t("binDoesNotExist")}`);
                }

                const request = {
                    requestName: "saveBin",
                    order: uploadedRows[i].order,
                    binID: existingBin.binID,
                    requestID: i
                };

                requests.push(request);
            }

            console.log("saveBin requests:", requests);

            dispatch(makeErplyBulkRequest(requests, t("saveBinError"))).then((response) => {
                if (response.status !== "error") {
                    dispatch(successMessageSet(t("binsSaved"), 5000));
                }
            });
        });
    };

    const getBins = () => {
        const params = {
            request: "getBins",
            status: "ACTIVE",
            warehouseID: selectedWarehouse.warehouseID,
        };

        return dispatch(makeErplyRequest(params, t("getBinsError"), null, null, null, true));
    };

    return (
        <div className={"white"}>
            <h1>{t("ImportBinOrder")} {t("fromExcel")}</h1>
            <div className={"textAlignLeft fontSizeMed"}>
                <p>{t("allowedExtensions")}: .xlsx</p>
                <p>{t("downloadSample")} <a href={fileUrl}><b>{t("here")}</b></a>.</p>
                <Button onClick={() => setShowExplanations(!showExplanations)}>{t("showExplanations")}</Button>
                <Transition visible={showExplanations} animation='slide down' duration={200} unmountOnHide>
                    <div>
                        <p>{t("importBinsExplanatoryTextName")}</p>
                        <p>{t("importBinsExplanatoryTextPickupNo")} {t("thisMustAlwaysBeFilled")}</p>
                    </div>
                </Transition>
            </div>
            <Input id={"uploadFile"} className={"marginTop"} type="file" accept='.xlsx' onChange={validateFile} onClick={clearFileInput}/>
            <div className={"btnsGroup"}>
                <Button className={"menuBtn"} primary size={"big"} onClick={saveBins}>{t("import")}</Button>
                <div className={"flex flexCenter"}>
                    <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                    <GoBackToStartBtn/>
                </div>
            </div>
        </div>
    );
};

export default ImportBinOrder
