export const getBinQuantitiesSuccess = (binQuantities) => {
    return {
        type: "GET_BIN_QUANTITIES_SUCCESS",
        payload: binQuantities
    }
};

// Set bin quantities of product not on document (necessary if view is Products Out and wmsEnableScanningProductsNotOnOutDocument is set to 1)
export const setBinQuantitiesOfProductNotOnDocument = (binQuantities) => {
    return {
        type: "SET_BIN_QUANTITIES_OF_PRODUCT_NOT_ON_DOCUMENT",
        payload: binQuantities
    }
};
