import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Dropdown, Image} from "semantic-ui-react";
import flagEE from "../images/flagEE.png";
import flagGB from "../images/flagGB.png";
import flagLV from "../images/flagLV.png";
import flagFR from "../images/flagFR.png";
import {languageSet} from "../actions/language";
import i18n from '../translations/i18n';

const LanguageSelect = () => {
    const language = useSelector(state => state.languageReducer.language);

    const dispatch = useDispatch();

    const languageToFlag = (language) => {
        switch(language) {
            case "est":
                return flagEE;
            case "eng":
                return flagGB;
            // case "lat":
            //     return flagLV;
            case "fra":
                return flagFR;
            default:
                return flagGB;
        }
    };

    const handleLanguageChange = (event, { value }) => {
        dispatch(languageSet(value));
        i18n.changeLanguage(value);
    };

    const trigger = (
        <span>
            <Image avatar src={languageToFlag(language)} />
        </span>
    );

    const options = [
        { key: 'est', text: 'Eesti keel', value: "est", flag: "ee" },
        { key: 'eng', text: 'English', value: "eng", flag: "gb" },
        { key: 'fra', text: 'Française', value: "fra", flag: "fr"},
        // { key: 'lat', text: 'Latvijas', value: "lat", image: { avatar: false, src: languageToFlag("lat") }}, // No translations available at the moment
    ];

    return (
        <Dropdown
            id={"language"}
            value={language}
            onChange={handleLanguageChange}
            trigger={trigger}
            options={options}
            pointing='top left'
            icon={null}
        />
    )
};

export default LanguageSelect;
