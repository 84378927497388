const initialState = {
    warehouses: [],
    selectedWarehouse: null
};

export const getWarehousesReducer = (state = initialState, action) => {
    switch(action.type) {
        case "GET_WAREHOUSES_SUCCESS":
            return {
                ...state,
                warehouses: action.payload,
            };
        case "SET_WAREHOUSE":
            return {
                ...state,
                selectedWarehouse: action.payload,
            };
        default:
            return state;
    }
};
