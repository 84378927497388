import React, { useState } from 'react';
import {Button, Input, Transition} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {componentSet} from "../actions/component";
import {useDispatch, useSelector} from "react-redux";
import GoBackToStartBtn from "./GoBackToStartBtn";
import {errorMessageSet, successMessageSet} from "../actions/headerMessage";
import readXlsxFile from 'read-excel-file';
import {makeErplyBulkRequest, responseHasErrors, saveBin} from "../util/erplyRequests";
import GoBackBtn from "./GoBackBtn";
import {isNotAPositiveInteger} from "../util/misc";

const fileUrl = "https://w2.intralplugins.com/excelTemplates/importBins.xlsx";
const maxFileSize = 10; // MB

const ImportBins = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [uploadedRows, setUploadedRows] = useState([]);
    const [showExplanations, setShowExplanations] = useState(false);

    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);

    const goBack = () => {
        dispatch(componentSet("BinManagement"));
    };

    const validateFile = (event) => {
        setUploadedRows([]);
        const file = event.target.files[0];

        if (!isCorrectSize(file)) {
            dispatch(errorMessageSet(`${t("fileSizeExceeds")} ${maxFileSize} MB`));
            clearFileInput();
            return
        }

        readExcelData(file);
    };

    const isCorrectSize = (file) => {
        return !(file.size / 1024 / 1024 > maxFileSize);
    };

    const clearFileInput = () => {
        document.getElementById("uploadFile").value = "";
    };

    const readExcelData = (file) => {
        const schema = {
            'Name': {
                prop: 'code',
                type: String
            },
            'Preferred': {
                prop: 'preferred',
                parse(value) {
                    if (value !== "" && value != 1 && value != 0) {
                        throw new Error(t("invalidPreferredValue"))
                    }
                    return value
                }
            },
            'Pickup no': {
                prop: 'order',
                parse(value) {
                    if (value !== "" && isNotAPositiveInteger(value)) {
                        throw new Error(t("invalidPickupNo"))
                    }
                    return value
                }
            },
            'Replenishment minimum': {
                prop: 'replenishmentMinimum',
                parse(value) {
                    if (value !== "" && isNotAPositiveInteger(value)) {
                        throw new Error(t("invalidReplenishmentMinimum"))
                    }
                    return value
                }
            },
            'Maximum amount': {
                prop: 'maximumAmount',
                parse(value) {
                    if (value !== "" && isNotAPositiveInteger(value)) {
                        throw new Error(t("invalidMaximumAmount"))
                    }
                    return value
                }
            },
            'Allowed products': {
                prop: 'allowedProduct',
                parse(value) {
                    if (String(value).includes(" ")) {
                        throw new Error(`${t("invalidAllowedProducts")}. ${t("noSpacesAllowed")}`)
                    }
                    return value
                }
            },
            'Equipment needed': {
                prop: 'equipmentNeeded',
                parse(value) {
                    if (String(value).includes(", ")) {
                        throw new Error(`${t("noSpacesAllowedEquipmentNeeded")}`)
                    }
                    return value
                }
            }
        };

        readXlsxFile(file, { schema }).then(({ rows, errors }) => {
            if (rows === undefined || rows.length == 0) {
                dispatch(errorMessageSet(`${t("invalidOrEmptyFile")}: "Name", "Preferred", "Pickup no", "Replenishment minimum", "Maximum amount", "Allowed products", "Equipment needed"`));
                clearFileInput();
                return;
            }

            if (errors.length > 0) {
                dispatch(errorMessageSet(`${errors[0].error}. ${t("row")}: ${errors[0].row + 1}. ${t("column")}: ${errors[0].column}. ${t("value")}: ${errors[0].value}`));
                clearFileInput();
                return;
            }

            for (let i = 0; i < rows.length; i++) {
                if (!rows[i].hasOwnProperty("code")) {
                    dispatch(errorMessageSet(`${t("rowMustHaveName")}. ${t("row")}: ${i + 2}`));
                    clearFileInput();
                    return;
                }
            }

            setUploadedRows(rows);
            console.log("Uploaded rows:", rows);
        })
    };

    const saveBins = async () => {
        if (uploadedRows.length === 0) {
            return dispatch(errorMessageSet(t("noFile")));
        }

        const bins = await getBins();
        const saveBinRequests = [];

        for (let i = 0, n = uploadedRows.length; i < n; i++) {
            const preferred = uploadedRows[i]?.preferred == 1;
            const allowedProduct = uploadedRows[i].allowedProduct ? uploadedRows[i].allowedProduct : "";
            const replenishmentMinimum = uploadedRows[i].replenishmentMinimum ? uploadedRows[i].replenishmentMinimum : 0;
            const maximumAmount = uploadedRows[i].maximumAmount ? uploadedRows[i].maximumAmount : 0;
            const order = uploadedRows[i].order ? uploadedRows[i].order : 0;
            const equipmentNeeded = uploadedRows[i].equipmentNeeded ? uploadedRows[i].equipmentNeeded : "";

            const existingBin = getExistingBin(bins, uploadedRows[i].code);

            const id = existingBin ? existingBin.binID : null;
            const code = existingBin ? null : uploadedRows[i].code;

            saveBinRequests.push(dispatch(saveBin(t, true, code, preferred, order, maximumAmount, replenishmentMinimum, allowedProduct, equipmentNeeded, id)));
        }

        const responses = await Promise.all(saveBinRequests);

        for (let i = 0, n = responses.length; i < n; i++) {
            if (responseHasErrors(responses[i])) return;
        }

        dispatch(successMessageSet(t("binsSaved")));
        goBack();
    };

    const getExistingBin = (bins, code) => {
        for (let i = 0, n = bins.length; i < n; i++) {
            if (bins[i].records.length > 0 && bins[i].records[0].code.toLowerCase() == String(code).toLowerCase()) {
                return bins[i].records[0]
            }
        }

        return null;
    };

    const getBins = () => {
        let requests = [];

        for (let i = 0, n = uploadedRows.length; i < n; i++) {
            const request = {
                requestName: "getBins",
                code: uploadedRows[i].code,
                status: "ACTIVE",
                warehouseID: selectedWarehouse.warehouseID,
                requestID: i + 1
            };

            requests.push(request);
        }

        return dispatch(makeErplyBulkRequest(requests, t("getBinsError")));
    };

    return (
        <div className={"white"}>
            <h1>{t("ImportBins")} {t("fromExcel")}</h1>
            <div className={"textAlignLeft fontSizeMed"}>
                <p>{t("allowedExtensions")}: .xlsx</p>
                <p>{t("downloadSample")} <a href={fileUrl}><b>{t("here")}</b></a>.</p>
                <Button onClick={() => setShowExplanations(!showExplanations)}>{t("showExplanations")}</Button>
                <Transition visible={showExplanations} animation='slide down' duration={200} unmountOnHide>
                    <div>
                        <p>{t("importBinsExplanatoryTextName")}</p>
                        <p>{t("importBinsExplanatoryTextPreferred")}</p>
                        <p>{t("importBinsExplanatoryTextPickupNo")}</p>
                        <p>{t("importBinsExplanatoryTextReplenishmentMinimum")}</p>
                        <p>{t("importBinsExplanatoryTextMaximumAmount")}</p>
                        <p>{t("importBinsExplanatoryTextAllowedProducts")}</p>
                        <p>{t("importBinsExplanatoryTextEquipmentNeeded")}</p>
                    </div>
                </Transition>
            </div>
            <Input id={"uploadFile"} className={"marginTop"} type="file" accept='.xlsx' onChange={validateFile} onClick={clearFileInput}/>
            <div className={"btnsGroup"}>
                <Button className={"menuBtn"} primary size={"big"} onClick={saveBins}>{t("import")}</Button>
                <div className={"flex flexCenter"}>
                    <GoBackBtn handleGoBackOnClick={goBack}/>
                    <GoBackToStartBtn/>
                </div>
            </div>
        </div>
    );
};

export default ImportBins
