const initialState = {
    documentType: false,    // Type of document being created in CreateDocument component ("waybill", "inventoryTransferOrder", "writeOFf", "purchaseInvoice", "returnWaybill", "purchaseReturn", "inventoryTransfer")
    scannedProducts: {} // This is to preserve scanned products when clicking on "Settings" and back
};

export const createDocumentReducer = (state = initialState, action) => {
    switch(action.type) {
        case "SET_DOCUMENT_TYPE":
            return {
                ...state,
                documentType: action.payload
            };
        case "SET_SCANNED_PRODUCTS":
            return {
                ...state,
                scannedProducts: setScannedProducts(action.payload.documentType, action.payload.scannedProducts, state.scannedProducts)
            };
        default:
            return state;
    }
};

const setScannedProducts = (documentType, scannedProducts, currentScannedProducts) => {
    const currentScannedProductsClone = JSON.parse(JSON.stringify(currentScannedProducts));
    currentScannedProductsClone[documentType] = scannedProducts;
    return currentScannedProductsClone;
};
