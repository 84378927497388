import React, { useState, useEffect } from 'react';
import {Button, Checkbox, Table} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {errorMessageSet} from "../actions/headerMessage";
import {useDispatch, useSelector} from "react-redux";
import {componentSet, previousComponentSet} from "../actions/component";
import {setSelectedDocuments} from "../actions/scan";
import GoBackToStartBtn from "./GoBackToStartBtn";
import GoBackBtn from "./GoBackBtn";
import {
    formatDateStringToEst,
    isSelectedRow,
    setExpandedRow,
    setFollowUpDocumentsScannedAmountsFromJsonAttributes,
    translateAccordingToCountry
} from "../util/misc";
import {getBinRecords, makeErplyBulkRequest, makeErplyRequest} from "../util/erplyRequests";
import {setUndeliveredGoods} from "../actions/undeliveredGoods";
import {setModal} from "../actions/modal";

const OrderListConfirmation = () => {
    const searchResultPurchaseDocuments = useSelector(state => state.searchDocumentsReducer.searchResultDocuments);
    const searchResultFollowUpDocuments = useSelector(state => state.searchDocumentsReducer.searchResultFollowUpDocuments);
    const searchResultFollowUpDocumentsAdditionalOrders = useSelector(state => state.searchDocumentsReducer.searchResultFollowUpDocumentsAdditionalOrders);
    const searchResultFollowUpDocumentsJsonAttributes = useSelector(state => state.searchDocumentsReducer.searchResultFollowUpDocumentsJsonAttributes);
    const language = useSelector(state => state.languageReducer.language);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const isGetUndeliveredGoods = useSelector(state => state.searchDocumentsReducer.isGetUndeliveredGoods); // Get purchase invoices to calculate undelivered goods, Rehvid Pluss only

    const multiplePurchaseOrderScanEnabled = confParameters.wmsEnableMultiplePurchaseOrderScan == 1;

    const initializeCheckboxes = () => {
        let checkboxes = [{id: "main", isChecked: false}];
        for (let i = 0, n = searchResultPurchaseDocuments.length; i < n; i++) {
            const checkbox = {id: searchResultPurchaseDocuments[i].id, isChecked: false};
            checkboxes.push(checkbox);
        }

        return checkboxes;
    };

    const [checkboxes, setCheckboxes] = useState(initializeCheckboxes());
    const [expandedRows, setExpandedRows] = useState([]);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(previousComponentSet("OrderListConfirmation"));
    }, []);

    const handleGoBackOnClick = () => {
        dispatch(componentSet("SearchOrders"));
    };

    const createRows = () => {
        return searchResultPurchaseDocuments.map(purchaseDocument => createRow(purchaseDocument));
    };

    const isChecked = (id) => {
        return checkboxes.find(checkbox => checkbox.id === id).isChecked;
    };

    const setChecked = (id) => {
        let checkboxesToBeSet = [];
        for (let i = 0, n = checkboxes.length; i < n; i++) {
            if (checkboxes[i].id === id) {
                checkboxesToBeSet.push({id: id, isChecked: !checkboxes[i].isChecked});
            } else {
                if (isGetUndeliveredGoods || multiplePurchaseOrderScanEnabled) {
                    checkboxesToBeSet.push({id: checkboxes[i].id, isChecked: checkboxes[i].isChecked});
                } else {
                    checkboxesToBeSet.push({id: checkboxes[i].id, isChecked: false});
                }
            }
        }

        setCheckboxes(checkboxesToBeSet);
    };

    const createRow = (purchaseDocument) => {
        const isSelected = isSelectedRow(purchaseDocument.id, expandedRows);
        const date = language === "est" ? formatDateStringToEst(purchaseDocument.date) : purchaseDocument.date;

        let prcDoc = [<Table.Row key={purchaseDocument.id}>
            <td>
                <Checkbox checked={isChecked(purchaseDocument.id)} onChange={() => setChecked(purchaseDocument.id)}/>
            </td>
            <Table.Cell onClick={() => setExpandedRow(purchaseDocument.id, expandedRows, setExpandedRows)}>{purchaseDocument.number}</Table.Cell>
            <Table.Cell onClick={() => setExpandedRow(purchaseDocument.id, expandedRows, setExpandedRows)}>{purchaseDocument.regnumber}</Table.Cell>
            <Table.Cell onClick={() => setExpandedRow(purchaseDocument.id, expandedRows, setExpandedRows)}>{date}</Table.Cell>
        </Table.Row>];

        if (isSelected) {
            prcDoc.push(createPrcDocRowsTable(purchaseDocument));
        }

        return prcDoc;
    };

    const createPrcDocRowsTable = (purchaseDocument) => {
        return (
            <tr key={purchaseDocument.lastModified}>{/* Didn't know what else to use as key*/}
                <td colSpan={4}>
                    <Table celled structured unstackable color={"blue"} inverted>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t("code")}</Table.HeaderCell>
                                <Table.HeaderCell>{translateAccordingToCountry(t("EAN"), confParameters)}</Table.HeaderCell>
                                <Table.HeaderCell>{t("name")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("amount")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {createPrcDocRowsTableRows(purchaseDocument)}
                        </Table.Body>
                    </Table>
                </td>
            </tr>
        )
    };

    const createPrcDocRowsTableRows = (purchaseDocument) => {
        const summedRows = getSummedRows(purchaseDocument.rows);
        return summedRows.map((row, index) => createPrcDocRowsTableRow(row, index, purchaseDocument));
    };

    const getSummedRows = (rows) => {
        let summedRows = [];

        for (let i = 0, n = rows.length; i < n; i++) {
            let amountHasBeenSummed = false;

            for (let j = 0, n = summedRows.length; j < n; j++) {
                if (summedRows[j].productID === rows[i].productID) {
                    summedRows[j].amount = Number(summedRows[j].amount) + Number(rows[i].amount);
                    amountHasBeenSummed = true;
                }
            }

            if (!amountHasBeenSummed) {
                const rowCopy = Object.assign({}, rows[i]);
                summedRows.push(rowCopy);
            }
        }

        return summedRows;
    };

    const createPrcDocRowsTableRow = (row, index, purchaseDocument) => {
        const amount = getAmount(row, purchaseDocument);

        return (
            <Table.Row key={index}>
                <Table.Cell>{row.code}</Table.Cell>
                <Table.Cell>{row.code2}</Table.Cell>
                <Table.Cell>{row.itemName}</Table.Cell>
                <Table.Cell>{amount}</Table.Cell>
            </Table.Row>
        )
    };

    // Subtract follow-up document amounts
    const getAmount = (row, purchaseDocument) => {
        if (purchaseDocument.baseToDocuments.length === 0) {
            return row.amount;
        } else {
            let newAmount = row.amount;

            for (let i = 0, n = purchaseDocument.baseToDocuments.length; i < n; i++) {
                const followUpDocument = searchResultFollowUpDocuments.find(doc => doc.id == purchaseDocument.baseToDocuments[i].id);

                if (multiplePurchaseOrderScanEnabled) {
                    searchResultFollowUpDocumentsJsonAttributes.forEach(jsonAttribute => {
                        if (jsonAttribute.json_object && followUpDocument.id == jsonAttribute.id) {
                            jsonAttribute.json_object.WMS.scannedAmounts.forEach(scannedAmount => {
                                if (purchaseDocument.id == scannedAmount.documentID && row.productID == scannedAmount.productID) {
                                    newAmount -= scannedAmount.amount;
                                }
                            });
                        }
                    });
                } else {
                    const followUpDocumentWasMadeFromSeveralOrders = followUpDocument.baseDocuments.length > 1;

                    if (!followUpDocumentWasMadeFromSeveralOrders) {
                        for (let j = 0, n = followUpDocument.rows.length; j < n; j++) {
                            if (row.productID == followUpDocument.rows[j].productID) {
                                newAmount -= followUpDocument.rows[j].amount;
                            }
                        }
                    } else {
                        const amountOnFollowUpDoc = getProductAmountOnDocument(followUpDocument, row.productID);

                        if (amountOnFollowUpDoc === 0) {
                            continue;
                        }

                        let amountToSubtract = amountOnFollowUpDoc;

                        for (let j = 0, n = followUpDocument.baseDocuments.length; j < n; j++) {
                            let order = searchResultPurchaseDocuments.find(doc => doc.id == followUpDocument.baseDocuments[j].id);

                            // Order is not in search result orders
                            if (order === undefined) {
                                order = searchResultFollowUpDocumentsAdditionalOrders.find(doc => doc.id == followUpDocument.baseDocuments[j].id);
                            }

                            const amountOnOrder = getProductAmountOnDocument(order, row.productID);

                            if (purchaseDocument.id == order.id) {
                                newAmount -= amountToSubtract;

                                if (newAmount < 0) {
                                    return 0;
                                } else {
                                    break;
                                }
                            } else {
                                amountToSubtract -= amountOnOrder;
                            }
                        }
                    }
                }
            }

            return newAmount;
        }
    };

    const getProductAmountOnDocument = (document, productID) => {
        let sum = 0;

        for (let i = 0, n = document.rows.length; i < n; i++) {
            if (document.rows[i].productID == productID) {
                sum += Number(document.rows[i].amount);
            }
        }

        return sum;
    };

    const handleMainCheckboxCheck = () => {
        let checkboxesToBeSet = [];
        const mainCheckboxIsChecked = checkboxes.find(checkbox => checkbox.id === "main").isChecked;

        for (let i = 0, n = checkboxes.length; i < n; i++) {
            checkboxesToBeSet.push({id: checkboxes[i].id, isChecked: !mainCheckboxIsChecked});
        }

        setCheckboxes(checkboxesToBeSet);
    };

    const handleBtnClick = async () => {
        const selectedDocs = getSelectedDocs();
        if (selectedDocs.length === 0) {
            dispatch(errorMessageSet(isGetUndeliveredGoods || multiplePurchaseOrderScanEnabled ? t("selectDocuments") : t("selectDoc")));
        } else {
            if (isGetUndeliveredGoods) {
                const baseDocuments = await getBaseDocuments(selectedDocs);
                const undeliveredGoods = getUndeliveredGoods(selectedDocs, baseDocuments);
                if (undeliveredGoods.length === 0) {
                    return dispatch(errorMessageSet(t("noUndeliveredGoodsFound")));
                }

                dispatch(setUndeliveredGoods(undeliveredGoods));
                dispatch(componentSet("UndeliveredGoods"));
            } else {
                const selectedDocsIDs = selectedDocs.map(doc => doc.id);
                const selectedDocsFollowUpDocuments = searchResultFollowUpDocuments.filter(followUpDoc => followUpDoc.baseDocuments.some(baseDoc => selectedDocsIDs.includes(baseDoc.id)));
                checkFollowUpDocumentsAndBinRecords(selectedDocs, selectedDocsFollowUpDocuments, selectedDocsIDs);
            }
        }
    };

    const checkFollowUpDocumentsAndBinRecords = async (selectedDocs, selectedDocsFollowUpDocuments, selectedDocsIDs) => {
        if (selectedDocsFollowUpDocuments.length > 0) {
            const warning = selectedDocs.length > 1 ? "followUpDocExistsWarningMultiple" : "followUpDocExistsWarning";
            dispatch(setModal(t("warning"), t(warning), () => goToScan(selectedDocs, selectedDocsFollowUpDocuments)));
        } else {
            const binRecords = await dispatch(getBinRecords(t, selectedDocsIDs.join(","), "PURCHASE_DOCUMENT", null, true));

            if (binRecords.length > 0) {
                const message = selectedDocs.length > 1 ? "handlingAlreadyStartedMultiple" : "handlingAlreadyStarted";
                dispatch(setModal(t("confirmation"), t(message), () => goToScan(selectedDocs, selectedDocsFollowUpDocuments)));
            } else {
                goToScan(selectedDocs, selectedDocsFollowUpDocuments);
            }
        }
    };

    const goToScan = async (selectedDocs, selectedDocsFollowUpDocuments) => {
        if (multiplePurchaseOrderScanEnabled) {
            const selectedDocsFollowUpDocumentsIDs = selectedDocsFollowUpDocuments.map(doc => doc.id);
            const followUpDocumentsJsonAttributes = searchResultFollowUpDocumentsJsonAttributes.filter(attribute => selectedDocsFollowUpDocumentsIDs.includes(attribute.id));
            setFollowUpDocumentsScannedAmountsFromJsonAttributes(followUpDocumentsJsonAttributes, selectedDocsFollowUpDocuments);
        }

        selectedDocs = await dispatch(makeErplyRequest({
            request: "getPurchaseDocuments",
            ids: selectedDocs.map(doc => doc.id).join(","),
            getRowsForAllInvoices: 1
        }, t("getPurchaseDocumentsError"), null, null, null, true));
        await dispatch(setSelectedDocuments(selectedDocs, t));
        dispatch(componentSet("Scan"));
    };

    const getUndeliveredGoods = (selectedDocs, baseWaybills) => {
        // Subtract amounts on waybills from purchase invoices
        const purchaseInvoices = JSON.parse(JSON.stringify(selectedDocs));  // Clone array
        for (let i = 0, n = baseWaybills.length; i < n; i++) {
            const purchaseInvoice =  purchaseInvoices.find(invoice => invoice.baseDocuments.some(baseDocument => baseDocument.id == baseWaybills[i].id));

            for (let j = 0, n = baseWaybills[i].rows.length; j < n; j++) {
                let amountLeftToSubtract = Number(baseWaybills[i].rows[j].amount);

                for (let k = 0, n = purchaseInvoice.rows.length; k < n; k++) {
                    if (purchaseInvoice.rows[k].productID == baseWaybills[i].rows[j].productID && purchaseInvoice.rows[k].amount > 0) {
                        const amountToSubtract = Number(purchaseInvoice.rows[k].amount) < amountLeftToSubtract ? Number(purchaseInvoice.rows[k].amount) : amountLeftToSubtract;
                        purchaseInvoice.rows[k].amount -= amountToSubtract;
                        amountLeftToSubtract -= amountToSubtract;
                    }

                    if (amountLeftToSubtract === 0) {
                        break;
                    }
                }

                if (amountLeftToSubtract > 0) {
                    console.log(`Amount on waybills larger than on invoice ${purchaseInvoice.regnumber}`);
                }
            }
        }

        let undeliveredGoods = [];

        purchaseInvoices.forEach(purchaseInvoice => {
            purchaseInvoice.rows.forEach(row => {
                if (row.amount > 0) {
                    const existingProduct = undeliveredGoods.find(product => product.productID === row.productID);
                    if (existingProduct) {
                        existingProduct.amount += Number(row.amount);
                    } else {
                        row.amount = Number(row.amount);
                        undeliveredGoods.push(row);
                    }
                }
            });
        });

        return undeliveredGoods;
    };

    // Get base waybills of partially received invoices
    const getBaseDocuments = (purchaseInvoices) => {
        let requests = [];
        purchaseInvoices.forEach(purchaseInvoice => {
            if (purchaseInvoice.stateID == 5) {
                purchaseInvoice.baseDocuments.forEach(baseDocument => {
                    if (baseDocument.type === "PRCWAYBILL") {
                        requests.push({
                            requestName: "getPurchaseDocuments",
                            id: baseDocument.id,
                            requestID: baseDocument.id
                        });
                    }
                });
            }
        });

        if (requests.length === 0) {
            return [];
        }
        return dispatch(makeErplyBulkRequest(requests, t("getPurchaseDocumentsError"), null, null, null, true, true));
    };

    const getSelectedDocs = () => {
        let selectedDocs = [];
        for (let i = 0, n = searchResultPurchaseDocuments.length; i < n; i++) {
            for (let j = 0, m = checkboxes.length; j < m; j++) {
                if (checkboxes[j].id !== "main" && checkboxes[j].isChecked && checkboxes[j].id === searchResultPurchaseDocuments[i].id) {
                    selectedDocs.push(searchResultPurchaseDocuments[i]);
                }
            }
        }

        return selectedDocs;
    };

    return (
        <div className={"overflow"}>
            <Table celled structured unstackable>
                <Table.Header>
                    <Table.Row>
                        <td id={"headerCheckBox"}>
                            {isGetUndeliveredGoods || multiplePurchaseOrderScanEnabled ? <Checkbox checked={isChecked("main")} onChange={handleMainCheckboxCheck}/> : ""}
                        </td>
                        <Table.HeaderCell>{t("number")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("regNumber")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("date")}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {createRows()}
                </Table.Body>
            </Table>
            <Button className={"menuBtn"} color={"blue"} size={"big"} onClick={handleBtnClick}>{isGetUndeliveredGoods ? t("showUndeliveredGoods") : t("startHandling")}</Button>
            <div className={"flex flexCenter"}>
                <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                <GoBackToStartBtn/>
            </div>
        </div>
    );
};

export default OrderListConfirmation
