import React, {useState, useEffect} from 'react';
import {Button, Dropdown, Input, Label} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {componentSet} from "../actions/component";
import GoBackToStartBtn from "./GoBackToStartBtn";
import {errorMessageSet, successMessageSet} from "../actions/headerMessage";
import {makeErplyRequest} from "../util/erplyRequests";
import GoBackBtn from "./GoBackBtn";
import {getProductGroupsSuccess} from "../actions/getProductGroups";
import {setModal} from "../actions/modal";
import {updateProductOnDocument} from "../actions/scan";
import {documentIsCreditInvoice, translateAccordingToCountry} from "../util/misc";

const CreateEditProduct = () => {
    const productGroups = useSelector(state => state.getProductGroupsReducer.productGroups);
    const editableProduct = useSelector(state => state.createEditProductReducer.editableProduct);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const componentSequence = useSelector(state => state.componentReducer.componentSequence);

    const isCreateProduct = editableProduct === null;
    const isCreditInvoice = documentIsCreditInvoice(componentSequence);

    const [productGroupID, setProductGroupID] = useState(isCreateProduct ? 0 : editableProduct.groupID);
    const [code, setCode] = useState(isCreateProduct ? "" : editableProduct.code);
    const [code2, setCode2] = useState(isCreateProduct ? "" : editableProduct.code2);
    const [code3, setCode3] = useState(isCreateProduct || editableProduct.code3 === null ? "" : editableProduct.code3);
    const [name, setName] = useState(isCreateProduct ? "" : editableProduct.name);
    const [netPrice, setNetPrice] = useState(isCreateProduct ? "" : editableProduct.price);
    const [cost, setCost] = useState(isCreateProduct ? "" : editableProduct.cost);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        getProductGroups();
    }, []);

    const getProductGroups = async () => {
        const params = {request: "getProductGroups"};
        const productGroups = await dispatch(makeErplyRequest(params, t("getProductGroupsError"), null, getProductGroupsSuccess, null, true));
        if (isCreateProduct) setProductGroupID(productGroups[0].productGroupID); // Select first product group
    };

    const getProductGroupOptions = (productGroups, preNameString = "") => {
        const subGroupSymbol = "— ";
        let options = [];

        productGroups.forEach(productGroup => {
            options.push(getProductGroupOption(productGroup, preNameString));
            let newPreNameString = preNameString;
            if (newPreNameString === "") newPreNameString += " ";
            newPreNameString += subGroupSymbol;
            options = options.concat(getProductGroupOptions(productGroup.subGroups, newPreNameString));
        });

        return options;
    };

    const getProductGroupOption = (group, preNameString) => {
        return {key: group.productGroupID, text: preNameString + group.name, value: group.productGroupID};
    };

    const handleProductGroupChange = (event, { value }) => {
        setProductGroupID(value);
    };

    const goBack = () => {
        dispatch(componentSet("Scan"));
    };

    const createRow = (value, setStateFunction, text) => {
        return (
            <div className={"searchRow"}>
                <Label className={"searchLabel"} size={"large"} pointing={"right"}>{translateAccordingToCountry(t(text), confParameters)}</Label>
                <Input className={"searchInput"} onInput={e => setStateFunction(e.target.value)} fluid value={value}/>
            </div>
        )
    };

    const validateInputs = () => {
        if (name === "") {
            return dispatch(errorMessageSet(t("pleaseEnterName")));
        }

        const positiveFloatInputs = [netPrice, cost];
        positiveFloatInputs.forEach(input => {
            if (input !== "" && (isNaN(input) || input < 0)) {
                return dispatch(errorMessageSet(t("priceMustBeNonNegativeNumber")));
            }
        });

        displayConfirmationModal();
    };

    const displayConfirmationModal = () => {
        dispatch(setModal(t("confirmation"), t("saveProductConfirmation"), saveProduct));
    };

    const saveProduct = async () => {
        let params = {request: "saveProduct"};

        let userDefinedParams = {groupID: productGroupID, name: name, code: code, code2: code2, code3: code3, netPrice: netPrice, cost: cost};
        if (!isCreateProduct) {
            userDefinedParams.productID = editableProduct.productID;
        }

        Object.keys(userDefinedParams).forEach(key => {
            params[key] = userDefinedParams[key];
        });

        const response = await dispatch(makeErplyRequest(params, t("saveProductError")));
        if (response.status !== "error") {
            dispatch(successMessageSet(t("productSaved"), 5000));

            if (isCreateProduct) {
                clearInputs();
            } else {
                if (isCreditInvoice) userDefinedParams.price = netPrice;
                dispatch(updateProductOnDocument(userDefinedParams));
                goBack();
            }
        }
    };

    const clearInputs = () => {
        const setStateFunctions = [setCode, setCode2, setCode3, setName, setNetPrice, setCost];
        setStateFunctions.forEach(func => func(""));
    };

    return (
        <div>
            <div className={"searchRow"}>
                <Label className={"searchLabel"} size={"large"} pointing={"right"}>{t("group")}</Label>
                <Dropdown
                    fluid
                    selection
                    options={getProductGroupOptions(productGroups)}
                    value={productGroupID}
                    onChange={handleProductGroupChange}
                />
            </div>
            {createRow(code, setCode, "code")}
            {createRow(code2, setCode2, "EAN")}
            {createRow(code3, setCode3, "code3")}
            {createRow(name, setName, "name")}
            {createRow(netPrice, setNetPrice, "netPrice")}
            {createRow(cost, setCost, "informativeCost")}
            <div className={"btnsGroup"}>
                <Button className={"menuBtn"} color={"blue"} size={"big"} onClick={validateInputs}>{t("save")} </Button>
                <div className={"flex flexCenter"}>
                    <GoBackBtn handleGoBackOnClick={goBack}/>
                    <GoBackToStartBtn/>
                </div>
            </div>
        </div>
    );
};

export default CreateEditProduct
