import {makeId} from "../util/misc";

const initialState = {
    selectedDocuments: [],
    rowsWithSummedProducts: [],
    documentIDs: "",
    scannedProducts: [], // Scanned products from all sessions
    scannedProductsByDocument: [],  // Needed if multiple purchase orders have been selected for scanning
    currentSessionScannedProducts: [],
    productsWithDifferences: [],    // Products with different scanned amounts than on document (includes unscanned products)
    summedScannedProducts: [],  // Same products from different bins summed together. Then products from follow up documents are subtracted from the summed products
    initialSubtractedRows: [],
    followUpDocuments: null,
    extraFields: {},    // notes, internalNotes, package desc
    excludedRows: [],    // Rows excluded from displaying due to wmsExcludedEANs or non-stock products. They are added back in in ScanFinish
    selectedDocumentsProducts: [],  // Products on document as requested with getProducts API call
    unfulfillableRows: [],   // Rows not returned by getFulfillableOrders (orderedAmount exceeds fulfillableAmount). They are added to re-orders in ScanFinish
    isTc2000Only: null,   // Alas-Kuul-specific field; null - has not been set, true - products displayed are only TC2000 products, false - products displayed are only non-TC2000 products
    documentHasBeenPreviouslyScanned: false,    // Alas-Kuul-specific. Needed for KAPP/RIIUL logic
    isAssembly: false,
    recipeProducts: [],
    componentsWithSummedAmounts: [],
    createdDocumentId: null, // Id of the document created after scanning is confirmed
    productIDsToUserEnteredPrices: [], // Array of {productID, price} objects, user-entered prices to scanned products not on document (Products In only),
    productsNotOnDocument: [], // Array of scanned products not on document (Products In only)
    binsContainingDocumentProducts: null,
    unsavedScannedBatches: [],
    scannedBatches: [],
    scannedBatchesByDocument: [],
    scanBySupplier: null, // Supplier whose purchase invoices will be used to map scanned products after scan (Rehvid Pluss only)
    supplierBin: null, // Intermediary bin where products from a specific supplier's purchase invoices are scanned to and later removed after confirming scan (Rehvid Pluss only)
    supplierPurchaseInvoices: [], // All selected supplier's purchase invoices with stateID 3 or 5 (Rehvid Pluss only)
    destinationWarehouse: null, // Inventory transfers only, shown in header
    isInTransitTransfer: false,  // Scanned document is an inventory transfer from the in-transit warehouse set with confParameters.wmsInTransitWarehouseID
    scanSessionStartedTimestamp: null,
    vatRates: [],
    temporaryUUID: null // Parameter sent with the document-creating-request to make it idempotent and thus avoiding creating duplicate documents
};

export const scanReducer = (state = initialState, action) => {
    switch(action.type) {
        case "SET_SELECTED_DOCUMENTS":
            return {
                ...state,
                selectedDocuments: action.payload.selectedDocuments,
                rowsWithSummedProducts: action.payload.rowsWithSummedProducts,
                documentIDs: action.payload.documentIDs,
                excludedRows: action.payload.excludedRows,
                selectedDocumentsProducts: action.payload.selectedDocumentsProducts,
                unfulfillableRows: action.payload.unfulfillableRows,
                extraFields: {},
                scannedProducts: [],
                isTc2000Only : null,
                isAssembly: action.payload.isAssembly,
                recipeProducts: action.payload.recipeProducts,
                componentsWithSummedAmounts: action.payload.componentsWithSummedAmounts,
                productsNotOnDocument: [],
                followUpDocuments: null,
                summedScannedProducts: [],
                binsContainingDocumentProducts: null,
                scannedBatches: action.payload.scannedBatches,
                scannedBatchesByDocument: action.payload.scannedBatchesByDocument,
                unsavedScannedBatches: [],
                scanBySupplier: null,
                supplierBin: null,
                supplierPurchaseInvoices: [],
                scanSessionStartedTimestamp: new Date().getTime(),
                temporaryUUID: makeId(128)
            };
        case "SET_SCANNED_PRODUCTS":
            return {
                ...state,
                scannedProducts: action.payload
            };
        case "SET_SCANNED_PRODUCTS_BY_DOCUMENT":
            return {
                ...state,
                scannedProductsByDocument: action.payload
            };
        case "SET_CURRENT_SESSION_SCANNED_PRODUCTS":
            return {
                ...state,
                currentSessionScannedProducts: action.payload
            };
        case "SET_PRODUCTS_WITH_DIFFERENCES":
            return {
                ...state,
                productsWithDifferences: action.payload
            };
        case "SET_SUMMED_SCANNED_PRODUCTS":
            return {
                ...state,
                summedScannedProducts: action.payload
            };
        case "SET_INITIAL_SUBTRACTED_ROWS":
            return {
                ...state,
                initialSubtractedRows: action.payload
            };
        case "SET_FOLLOW_UP_DOCUMENTS":
            return {
                ...state,
                followUpDocuments: action.payload
            };
        case "SET_EXTRA_FIELDS":
            return {
                ...state,
                extraFields: action.payload,
                selectedDocuments: setExtraFields(state.selectedDocuments, action.payload)
            };
        case "SET_SELECTED_DOCUMENTS_PRODUCTS":
            return {
                ...state,
                selectedDocumentsProducts: action.payload
            };
        case "SET_SELECTED_DOCUMENTS_ATTRIBUTE":
            return {
                ...state,
                selectedDocuments: setAttribute(state.selectedDocuments, action.payload)
            };
        case "REMOVE_SELECTED_DOCUMENTS_ATTRIBUTE":
            return {
                ...state,
                selectedDocuments: removeAttribute(state.selectedDocuments, action.payload)
            };
        case "SET_IS_TC2000_ONLY":
            return {
                ...state,
                isTc2000Only: action.payload
            };
        case "SET_DOCUMENT_HAS_BEEN_PREVIOUSLY_SCANNED":
            return {
                ...state,
                documentHasBeenPreviouslyScanned: action.payload
            };
        case "SET_CREATED_DOCUMENT_ID":
            return {
                ...state,
                createdDocumentId: action.payload
            };
        case "SET_USER_ENTERED_PRICE":
            return {
                ...state,
                productIDsToUserEnteredPrices: addUserEnteredPrice(state.productIDsToUserEnteredPrices, action.payload.productID, action.payload.price)
            };
        case "SET_PRODUCTS_NOT_ON_DOCUMENT":
            return {
                ...state,
                productsNotOnDocument: action.payload
            };
        case "SET_BINS_CONTAINING_DOCUMENT_PRODUCTS":
            return {
                ...state,
                binsContainingDocumentProducts: action.payload
            };
        case "ADD_SCANNED_BATCH":
            return {
                ...state,
                unsavedScannedBatches: addScannedBatch(state.unsavedScannedBatches, action.payload)
            };
        case "SET_SCANNED_BATCHES":
            return {
                ...state,
                scannedBatches: action.payload
            };
        case "UPDATE_PRODUCT_ON_DOCUMENT":
            return {
                ...state,
                scannedProducts: updateProduct(state.scannedProducts, action.payload),
                summedScannedProducts: updateProduct(state.summedScannedProducts, action.payload),
                rowsWithSummedProducts: updateProduct(state.rowsWithSummedProducts, action.payload),
                selectedDocumentsProducts: updateProduct(state.selectedDocumentsProducts, action.payload),
            };
        case "SET_SCAN_BY_SUPPLIER":
            return {
                ...state,
                scanBySupplier: action.payload
            };
        case "SET_SUPPLIER_BIN":
            return {
                ...state,
                supplierBin: action.payload
            };
        case "SET_SUPPLIER_PURCHASE_INVOICES":
            return {
                ...state,
                supplierPurchaseInvoices: action.payload
            };
        case "SET_UNSAVED_SCANNED_BATCHES":
            return {
                ...state,
                unsavedScannedBatches: action.payload
            };
        case "SET_DESTINATION_WAREHOUSE":
            return {
                ...state,
                destinationWarehouse: action.payload
            };
        case "SET_IS_IN_TRANSIT_TRANSFER":
            return {
                ...state,
                isInTransitTransfer: action.payload
            };
        case "SET_NEW_AMOUNT_TO_PRODUCT_IN_ROWS_WITH_SUMMED_PRODUCTS":
            return {
                ...state,
                rowsWithSummedProducts: updateProduct(state.rowsWithSummedProducts,
                    {productID: action.payload.productID, amount: action.payload.newAmount}, action.payload.isBundle, action.payload.bundleName)
            };
        case "SET_VAT_RATES":
            return {
                ...state,
                vatRates: action.payload
            };
        default:
            return state;
    }
};

const setExtraFields = (selectedDocuments, extraFields) => {
    for (const [key, value] of Object.entries(extraFields)) {
        selectedDocuments[0][key] = value;
    }
    return selectedDocuments;
};

const removeAttribute = (selectedDocuments, attributeName) => {
    const selectedDocumentsCopy = selectedDocuments.map(a => ({...a}));    // Clone array
    for (let i = 0, n = selectedDocumentsCopy.length; i < n; i++) {
        if (selectedDocumentsCopy[i].hasOwnProperty("attributes")) {
            const attributeIndex = selectedDocumentsCopy[i].attributes.findIndex(attribute => attribute.attributeName === attributeName);

            if (attributeIndex !== -1) {
                selectedDocumentsCopy[i].attributes.splice(attributeIndex, 1);
            }
        }
    }

    return selectedDocumentsCopy;
};

const setAttribute = (selectedDocuments, attribute) => {
    const selectedDocumentsCopy = selectedDocuments.map(a => ({...a}));    // Clone array
    for (let i = 0, n = selectedDocumentsCopy.length; i < n; i++) {
        if (!selectedDocumentsCopy[i].hasOwnProperty("attributes")) {
            selectedDocumentsCopy[i].attributes = [];
        }

        const existingAttribute = selectedDocumentsCopy[i].attributes.find(existingAttribute => existingAttribute.attributeName === attribute.attributeName);
        if (existingAttribute) {
            existingAttribute.attributeValue = attribute.attributeValue;
        } else {
            selectedDocumentsCopy[i].attributes.push(attribute);
        }
    }

    return selectedDocumentsCopy;
};

const addUserEnteredPrice = (productIDsToUserEnteredPrices, productID, price) => {
    let arrayCopy = productIDsToUserEnteredPrices.map(a => ({...a}));    // Clone array
    let productExists = false;

    for (let i = 0, n = arrayCopy.length; i < n; i++) {
        // Overwrite existing product's price
        if (arrayCopy[i].productID === productID) {
            arrayCopy[i].price = price;
            productExists = true;
            break;
        }
    }

    if (!productExists) {
        arrayCopy.push({productID: productID, price: price});
    }

    return arrayCopy;
};

const addScannedBatch = (scannedBatches, batch) => {
    let arrayCopy = scannedBatches.map(a => ({...a}));    // Clone array
    let batchExists = false;

    for (let i = 0, n = arrayCopy.length; i < n; i++) {
        if (arrayCopy[i].productID == batch.productID && arrayCopy[i].batchCode == batch.batchCode && arrayCopy[i].binID == batch.binID) {
            batchExists = true;
            arrayCopy[i].amount += batch.amount;
            break;
        }
    }

    if (!batchExists) {
        arrayCopy.push(batch);
    }

    return arrayCopy;
};

const updateProduct = (selectedDocumentsProducts, updatedFields, isBundle = false, bundleName) => {
    let arrayCopy = selectedDocumentsProducts.map(a => ({...a}));    // Clone array

    for (let i = 0, n = arrayCopy.length; i < n; i++) {
        if (arrayCopy[i].productID == updatedFields.productID) {
            Object.keys(updatedFields).forEach(field => {
                if (field === "name" && arrayCopy[i].hasOwnProperty("itemName")) {
                    arrayCopy[i].itemName = updatedFields[field];
                }
                if (arrayCopy[i].hasOwnProperty(field)) {
                    arrayCopy[i][field] = updatedFields[field];
                }
            });

            if (isBundle) {
                // Update bundle components' amounts
                const components = arrayCopy.filter(product => product.bundleID == updatedFields.productID && product.bundleName === bundleName);
                const newBundleAmount = updatedFields.amount;

                components.forEach(component => {
                    component.amount = newBundleAmount * component.amountInBundle;
                })
            }

            break;
        }
    }

    return arrayCopy;
};
