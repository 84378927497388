import React, {useRef, useEffect, useState} from 'react';
import {Button, Form, Input, Label, Table, Transition} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {componentSet, setChangeScannedProductsDifferencesOnly, setSequence} from "../actions/component";
import {useDispatch, useSelector} from "react-redux";
import {errorMessageSet, successMessageSet} from "../actions/headerMessage";
import {setModal} from "../actions/modal";
import GoBackToStartBtn from "./GoBackToStartBtn";
import {
    getConfParameters,
    getReasonCodes, getSalesDocumentArDataset, isDuplicateDocumentError,
    makeErplyBulkRequest,
    makeErplyRequest,
    makeJsonApiRequest, makeWmsApiRequest, responseHasErrors, sendEmail
} from "../util/erplyRequests";
import {getBinsSuccess} from "../actions/getBins";
import {setCreatedDocumentId, setProductsWithDifferences} from "../actions/scan";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from 'html2canvas';
import GoBackBtn from "./GoBackBtn";
import {
    documentIsPurchaseReturn,
    documentIsCreditInvoice,
    getApiAttributeValue,
    getNextAttributeNumber,
    getProductProperty,
    getSavedDocumentIdFieldName,
    makeRequest,
    roundFloatingPointError,
    rowHasTc2000Product,
    simpleLog,
    subtractAmountsFromFollowUpDocuments,
    documentIsSalesOrder,
    capitalise,
    rearrangeRowsAccordingToBaseDocument,
    documentIsPurchaseOrder,
    productsAreIdentical,
    rowIsBundle,
    isBundleComponent,
    productNeedsSerialNumber,
    sumSerialNoBinRecords,
    getBundleAmountOnDocument,
    differentiateProductsByPackageID,
    getProductPackage,
    getSummedScannedProducts,
    getDocumentType,
    getGetDocumentsRequestName,
    getIdFieldName,
    getSaveDocumentRequestName, syncQuantitiesOnDocument, getConfParameterValue, getCurrentSessionBinRecords
} from "../util/misc";
import {
    isAlasKuul,
    isAmericanTestAccount, isArvutitark, isCorpowear, isNBQ, isOnOff,
    isPrimePartner,
    isPrimePartnerEst,
    isRehvidPluss,
    isTAF, isTesterClientCode,
} from "../util/isClient";
import {
    getArData,
    sendToActualReportsService,
    sendToPrintService
} from "../util/printRequests";
import {addSsccToInternalNotes, getSsccNo} from "../util/sscc";
import {
    timeoutBetweenPrint,
    arvutitarkWarehouseIDsExcludedFromInTransit,
    lastScanConfirmedAttributeName,
    documentFirstOpenedAttributeName,
    omnivaDeliveryTypeAttributeName, createdInWmsAttributeName
} from "../util/constants";

const ScanFinish = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isAssembly = useSelector(state => state.scanReducer.isAssembly);  // Used for Products Out -> Assemble
    const rowsWithSummedProducts = useSelector(state => state.scanReducer.rowsWithSummedProducts);
    const componentsWithSummedAmounts = useSelector(state => state.scanReducer.componentsWithSummedAmounts);  // Used for Products Out -> Assemble
    const productsOnDocument = useSelector(state => state.scanReducer.selectedDocumentsProducts);
    const productsNotOnDocument = useSelector(state => state.scanReducer.productsNotOnDocument);
    const recipeProducts = useSelector(state => state.scanReducer.recipeProducts);
    const selectedDocumentsProducts = isAssembly ? recipeProducts : productsOnDocument;
    const summedRows = isAssembly ? componentsWithSummedAmounts : rowsWithSummedProducts;

    const scannedProducts = useSelector(state => state.scanReducer.scannedProducts);
    const currentSessionScannedProducts = useSelector(state => state.scanReducer.currentSessionScannedProducts);
    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);
    const selectedDocuments = useSelector(state => state.scanReducer.selectedDocuments);
    const componentSequence = useSelector(state => state.componentReducer.componentSequence);
    const documentIDs = useSelector(state => state.scanReducer.documentIDs);
    const summedScannedProducts = useSelector(state => state.scanReducer.summedScannedProducts);
    const excludedRows = useSelector(state => state.scanReducer.excludedRows);
    const unfulfillableRows = useSelector(state => state.scanReducer.unfulfillableRows);
    const initialSubtractedRows = useSelector(state => state.scanReducer.initialSubtractedRows);  // Rows with scanned products from all previous scan sessions subtracted
    const extraFields = useSelector(state => state.scanReducer.extraFields);
    const user = useSelector(state => state.verifyUserReducer.user);
    const clientCode = useSelector(state => state.verifyUserReducer.clientCode);
    const receivingArea = useSelector(state => state.getBinsReducer.receivingArea);
    const apiSessionKey = useSelector(state => state.verifyUserReducer.user.sessionKey);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const productIDsToUserEnteredPrices = useSelector(state => state.scanReducer.productIDsToUserEnteredPrices);
    const scannedBatches = useSelector(state => state.scanReducer.scannedBatches);
    const followUpDocuments = useSelector(state => state.scanReducer.followUpDocuments);
    const scanBySupplier = useSelector(state => state.scanReducer.scanBySupplier);
    // Intermediary bin where products from a specific supplier's purchase invoices are scanned to and later removed after confirming scan (Rehvid Pluss only)
    const supplierBin = useSelector(state => state.scanReducer.supplierBin);
    // All selected supplier's purchase invoices with stateID 3 or 5 (Rehvid Pluss only)
    const supplierPurchaseInvoices = useSelector(state => state.scanReducer.supplierPurchaseInvoices);
    const isInTransitTransfer = useSelector(state => state.scanReducer.isInTransitTransfer);
    const reasonCodes = useSelector(state => state.getReasonCodesReducer.reasonCodes);
    const binRecords = useSelector(state => state.getBinRecordsReducer.binRecords);
    const scanSessionStartedTimestamp = useSelector(state => state.scanReducer.scanSessionStartedTimestamp);
    const temporaryUUID = useSelector(state => state.scanReducer.temporaryUUID);

    const isAK = isAlasKuul(clientCode);
    const isPP = isPrimePartner(clientCode);
    const isPPEst = isPrimePartnerEst(clientCode);
    const isAmericanTest = isAmericanTestAccount(clientCode);
    const isTc2000Only = useSelector(state => state.scanReducer.isTc2000Only);

    const selectedDocumentCustomer = useRef(null);
    const selectedDocumentSupplier = useRef(null);
    const docNoInput = useRef(null);
    const containersToReOrder = useRef([]);

    const isScanBySupplier = scanBySupplier !== null;
    const isInventoryTransfer = componentSequence.includes("InventoryTransfer");
    const isCreditInvoice = documentIsCreditInvoice(componentSequence);
    const isPurchaseReturn = documentIsPurchaseReturn(componentSequence);
    const isOutScan = componentSequence.includes("ProductsOut");
    const isAssignment = componentSequence.includes("Assignment");
    const isSalesOrder = documentIsSalesOrder(componentSequence, isAssembly);
    const isPurchaseOrder = documentIsPurchaseOrder(componentSequence, isScanBySupplier);
    const negativeScanAllowed = (isSalesOrder || (isInventoryTransfer && isOutScan)) && confParameters.wmsEnableNegativeScanProductsOut == 1;
    const userSetsPurchaseDocNo = confParameters.wmsEnableSettingPurchaseDocNoAfterScan == 1 && !isScanBySupplier;
    const isFromTlnKeskladu = !isScanBySupplier && selectedDocuments[0].warehouseFromID == 2;    // AK only
    const isSaadaKappiDocument = isAK && (isSalesOrder || isCreditInvoice || (isOutScan && isInventoryTransfer && isFromTlnKeskladu));  // AK only
    const isFromMultipleDocuments = selectedDocuments.length > 1;   // Only possible with purchase documents
    const multiplePurchaseOrderScanEnabled = confParameters.wmsEnableMultiplePurchaseOrderScan == 1;
    const needsReasonCodes = isCreditInvoice && confParameters.invoicerows_return_reason == 1;
    const differentiateByPackageID = differentiateProductsByPackageID(componentSequence, isScanBySupplier, confParameters);
    const excludeFromInTransitFunctionality = isArvutitark(clientCode) && (arvutitarkWarehouseIDsExcludedFromInTransit.includes(Number(selectedWarehouse.warehouseID)) ||
        arvutitarkWarehouseIDsExcludedFromInTransit.includes(Number(selectedDocuments[0].warehouseToID)) ||
        arvutitarkWarehouseIDsExcludedFromInTransit.includes(Number(selectedDocuments[0].warehouseFromID)));
    const inTransitFunctionalityEnabled = !excludeFromInTransitFunctionality && confParameters.wmsInTransitWarehouseID !== "";
    // const isOpenInAndroidApp = true;
    const isOpenInAndroidApp = window.ReactNativeWebView;
    const multipleUsersHaveScanned = [...new Set(binRecords.map(binRecord => binRecord.addedBy))].length > 1;

    const [showDetailedDocComparison, setShowDetailedDocComparison] = useState(false);
    const [purchaseDocNo, setPurchaseDocNo] = useState(isPurchaseOrder ? selectedDocuments.map(document => document[confParameters.wmsCreatedPurchaseDocumentNoFieldValue]).join(",") : null);
    const [noOfPackages, setNoOfPackages] = useState(1);    // No of SSCC labels to be printed
    const [additionalCostsPercent, setAdditionalCostsPercent] = useState(3);    // AK only, percentage of created purchase document's net sum to be added to the created purchase document's additional costs

    useEffect(() => {
        if (needsReasonCodes) {
            dispatch(getReasonCodes(t));
        }
    }, []);

    const handleGoBackOnClick = () => {
        dispatch(componentSet("Scan"));
    };

    const handleConfirmGoodsOnClick = (returnReasonID = null, otherUsersHaveFinished = false) => {
        if (summedScannedProducts.length === 0 || summedScannedProducts.every(product => product.amount == 0)) {
            return dispatch(errorMessageSet(t("noScannedProducts")));
        } else if (isPurchaseOrder && userSetsPurchaseDocNo && purchaseDocNo === "") {
            return dispatch(errorMessageSet(t("enterDocNo")));
        } else if (needsReasonCodes && returnReasonID === null) {
            return displayReturnReasonModal();
        } else if (additionalCostsPercent < 0 || additionalCostsPercent > 100) {
            return dispatch(errorMessageSet(t("additionalCostsPercentMustBe0to100")));
        } else if (multipleUsersHaveScanned && !otherUsersHaveFinished) {
            return dispatch(setModal(t("confirmation"), t("otherUsersHaveFinished?"), () => handleConfirmGoodsOnClick(returnReasonID, true)));
        }

        const modalText = isAssembly ? t("confirmAssemblingOfGoods?"): isOutScan ? t("confirmIssuingOfGoods?") : t("confirmArrivalOfGoods?");
        dispatch(setModal(t("confirmation"), modalText, () => confirmScan(returnReasonID)));
    };

    const displayReturnReasonModal = () => {
        const options = reasonCodes.map(reasonCode => ({key: reasonCode.reasonID, text: reasonCode.name, value: reasonCode.reasonID}));
        dispatch(setModal(t("enterReason"), "", (returnReasonID) => handleConfirmGoodsOnClick(returnReasonID), false, options[0].value, options));
    };

    const handleChangeScannedProductsOnClick = async (displayDifferencesOnly = false) => {
        if (displayDifferencesOnly) {
            const productsWithDifferences = getProductsWithDifferences();
            if (productsWithDifferences.length === 0) return dispatch(errorMessageSet(t("noProductsWithDifferences")));

            dispatch(setProductsWithDifferences(productsWithDifferences));
        } else {
            if (scannedProducts.length === 0) return dispatch(errorMessageSet(t("noScannedProducts")));
        }

        getBins().then(() => {
            dispatch(setChangeScannedProductsDifferencesOnly(displayDifferencesOnly));
            dispatch(componentSet("ChangeScannedProducts"));
        });
    };

    const getProductsWithDifferences = () => {
        let products = [];

        // Add scanned products not on document or with scanned amount less than amount on document
        for (let i = 0, n = summedScannedProducts.length; i < n; i++) {
            const initialProductAmount = initialSubtractedRows.find(product => productsAreIdentical(componentSequence, selectedDocuments, product, summedScannedProducts[i]));

            if (initialProductAmount === undefined || initialProductAmount.amount != summedScannedProducts[i].amount) {
                // Get products in all bins
                for (let j = 0, n = currentSessionScannedProducts.length; j < n; j++) {
                    if (productsAreIdentical(componentSequence, selectedDocuments, currentSessionScannedProducts[j], summedScannedProducts[i])) {
                        products.push(currentSessionScannedProducts[j]);
                    }
                }
            }
        }

        if (!isAssembly) {
            // Add non-scanned products
            for (let i = 0, n = summedRows.length; i < n; i++) {
                const productIsScanned = currentSessionScannedProducts.some(product => productsAreIdentical(componentSequence, selectedDocuments, product, summedRows[i]));

                if (!productIsScanned) {
                    const product = Object.assign({}, summedRows[i]);
                    if (!product.hasOwnProperty("name") && !product.hasOwnProperty("itemName")) {   // Inventory transfer rows
                        const name = getProductProperty(selectedDocumentsProducts, product.productID, "name");
                        product.itemName = name;  // This is so it would register as a non-scanned product
                        product.name = name;
                    }

                    product.amount = 0;
                    products.push(product);
                }
            }
        }

        return products;
    };

    const getBins = () => {
        const params = {
            request: "getBins",
            status: "ACTIVE",
            warehouseID: selectedWarehouse.warehouseID
        };

        return dispatch(makeErplyRequest(params, t("getBinsError"), null, getBinsSuccess, null, true));
    };

    const getSelectedDocuments = () => {
        const requestName = getGetDocumentsRequestName(componentSequence, isAssembly);
        const error = `${requestName}Error`;
        const idFieldName = getIdFieldName(isInventoryTransfer, isAssembly, isAssignment);
        const idParameterName = selectedDocuments.length > 1 ? "ids" : idFieldName;
        const ids = selectedDocuments.map(doc => doc[idFieldName]).join(",");
        const params = {
            request: requestName,
            [idParameterName]: ids
        };

        return dispatch(makeErplyRequest(params, t(error)));
    };

    const saveAttributeToDocument = (attributeName, attributeValue, document) => {
        const params = {
            attributeName0: attributeName,
            attributeValue0: attributeValue
        };

        if (isSalesOrder) {
            params.id = document.id;
            params.request = "saveSalesDocument";
            return dispatch(makeErplyRequest(params, t("saveSalesDocumentError"), null, null, null, false, false));
        } else if (isInventoryTransfer) {
            params.inventoryTransferID = document.inventoryTransferID;
            params.request = "saveInventoryTransfer";
            return dispatch(makeErplyRequest(params, t("saveInventoryTransferError"), null, null, null, false, false));
        }
    };

    const confirmScan = async (returnReasonID) => {
        let selectedDocument = selectedDocuments[0];  // If multiple selected documents, choose first one

        if (!isScanBySupplier) {
            const newlyRequestedDocuments = await getSelectedDocuments(); // Request selected documents again in case some attributes or parameters have been changed

            if (!isSaadaKappiDocument) {
                // Check if document scan has already been confirmed by another user
                for (let i = 0, n = newlyRequestedDocuments.length; i < n; i++) {
                    let lastScanConfirmed = getApiAttributeValue(lastScanConfirmedAttributeName, newlyRequestedDocuments[i].attributes);

                    if (lastScanConfirmed) {
                        lastScanConfirmed = JSON.parse(lastScanConfirmed);

                        if (lastScanConfirmed.timestamp > scanSessionStartedTimestamp && lastScanConfirmed.employeeID != user.employeeID) {
                            setPreScanComponent();
                            return dispatch(errorMessageSet(t("documentScanAlreadyConfirmed")));
                        }
                    }
                }

                // Save attribute so another user can't confirm scan if scan was started before last user's confirm
                const requestName = getSaveDocumentRequestName(componentSequence, isAssembly);
                const error = `${requestName}Error`;
                const idFieldName = getIdFieldName(isInventoryTransfer, isAssembly, isAssignment);
                const saveDocumentRequests = selectedDocuments.map(doc => ({
                    requestName: requestName,
                    [idFieldName]: doc[idFieldName],
                    attributeName0: lastScanConfirmedAttributeName,
                    attributeValue0: JSON.stringify({timestamp: new Date().getTime(), employeeID: user.employeeID})
                }));
                dispatch(makeErplyBulkRequest(saveDocumentRequests, t(error), null, null, null, false));
            }

            if (isSalesOrder) selectedDocument = newlyRequestedDocuments[0]; // Some attributes or parameters may have been changed
        }

        // Some necessary settings are configurable only in Back Office so we need to get confParameters before document save in case changes have been made recently
        const confParameters = await dispatch(getConfParameters(t, true));
        // Sync quantities again in case another user has scanned something
        const [newScannedProducts, scannedProductsByDocument, currentSessionScannedProducts, summedScannedProducts] = await dispatch(syncQuantitiesOnDocument(t, documentIDs, componentSequence, isAssembly, isScanBySupplier, supplierBin, selectedDocumentsProducts, multiplePurchaseOrderScanEnabled, scannedProducts, differentiateByPackageID, followUpDocuments, confParameters));

        let saveRequestResponse = "";
        let params = {};

        if (isScanBySupplier) {
            // Get base waybills of partially received invoices
            const getBaseWaybills = async (purchaseInvoices) => {
                let requests = [];
                purchaseInvoices.forEach(purchaseInvoice => {
                    if (purchaseInvoice.stateID == 5) {
                        purchaseInvoice.baseDocuments.forEach(baseDocument => {
                            if (baseDocument.type === "PRCWAYBILL") {
                                requests.push({
                                    requestName: "getPurchaseDocuments",
                                    id: baseDocument.id,
                                    requestID: baseDocument.id
                                });
                            }
                        });
                    }
                });

                if (requests.length === 0) {
                    return [];
                }

                return dispatch(makeErplyBulkRequest(requests, t("getPurchaseDocumentsError"), null, null, null)).then(responses => {
                    return responses.map(response => response.records[0]);
                });
            };

            let purchaseInvoices = JSON.parse(JSON.stringify(supplierPurchaseInvoices));    // Clone array;
            // Sort by date, oldest first
            purchaseInvoices.sort((invoice1, invoice2) => {
                return new Date(`${invoice1.date}T${invoice1.time}`) - new Date (`${invoice2.date}T${invoice2.time}`);
            });

            const baseWaybills = await getBaseWaybills(purchaseInvoices);

            // Subtract amounts on base waybills from purchase invoices
            let waybillAmountGreaterThanInvoiceErrors = [];
            baseWaybills.forEach(waybill => {
                let invoice = purchaseInvoices.find(purchaseInvoice => purchaseInvoice.baseDocuments.some(baseDocument => baseDocument.id == waybill.id));

                for (let i = 0, n = waybill.rows.length; i < n; i++) {
                    let amountRemainingToSubtract = Number(waybill.rows[i].amount);

                    for (let j = 0, n = invoice.rows.length; j < n; j++) {
                        if (waybill.rows[i].productID == invoice.rows[j].productID &&
                            (!invoice.rows[j].hasOwnProperty("amountRemaining") || invoice.rows[j].amountRemaining > 0)) {
                            if (!invoice.rows[j].hasOwnProperty("amountRemaining")) {
                                invoice.rows[j].amountRemaining = Number(invoice.rows[j].amount);
                            }

                            const amountToSubtract = invoice.rows[j].amountRemaining < amountRemainingToSubtract ? invoice.rows[j].amountRemaining : amountRemainingToSubtract;
                            amountRemainingToSubtract = roundFloatingPointError(amountRemainingToSubtract - amountToSubtract);
                            invoice.rows[j].amountRemaining = roundFloatingPointError(invoice.rows[j].amountRemaining - amountToSubtract);
                        }

                        if (amountRemainingToSubtract === 0) {
                            break;
                        }
                    }

                    if (i === n - 1 && amountRemainingToSubtract > 0) {
                        if (!waybillAmountGreaterThanInvoiceErrors.some(error => error.waybillNo === waybill.number && error.itemName === waybill.rows[i].itemName)) {
                            waybillAmountGreaterThanInvoiceErrors.push({waybillNo: waybill.number, itemName: waybill.rows[i].itemName});
                        }
                    }
                }
            });

            const noOfErrors = waybillAmountGreaterThanInvoiceErrors.length;
            if (noOfErrors > 0) {
                let errorMessage = `${t("waybillAmountGreaterThanInvoice")}: `;
                waybillAmountGreaterThanInvoiceErrors.forEach((error, index) => {
                    errorMessage += `${t("numberShort")} ${error.waybillNo}: ${error.itemName}`;
                    if (index !== noOfErrors - 1) {
                        errorMessage += ", ";
                    }
                });

                return dispatch(errorMessageSet(errorMessage));
            }

            // Subtract scanned amounts from purchase invoices starting from oldest
            let missingProductsTexts = [];
            summedScannedProducts.forEach(scannedProduct => {
                let scannedAmountRemaining = Number(scannedProduct.amount);

                for (let i = 0, n = purchaseInvoices.length; i < n; i++) {
                    for (let j = 0, n = purchaseInvoices[i].rows.length; j < n; j++) {
                        if (purchaseInvoices[i].rows[j].productID == scannedProduct.productID &&
                            purchaseInvoices[i].rows[j].amount > 0 &&   // Rows with negative amount are excluded from this process
                            (!purchaseInvoices[i].rows[j].hasOwnProperty("amountRemaining") || purchaseInvoices[i].rows[j].amountRemaining > 0)) {
                            if (!purchaseInvoices[i].rows[j].hasOwnProperty("amountRemaining")) {
                                purchaseInvoices[i].rows[j].amountRemaining = Number(purchaseInvoices[i].rows[j].amount);
                            }
                            if (!purchaseInvoices[i].rows[j].hasOwnProperty("scannedProductAmountSubtracted")) {
                                purchaseInvoices[i].rows[j].scannedProductAmountSubtracted = 0;
                            }

                            const amountToSubtract = purchaseInvoices[i].rows[j].amountRemaining > scannedAmountRemaining ? scannedAmountRemaining : purchaseInvoices[i].rows[j].amountRemaining;
                            scannedAmountRemaining = roundFloatingPointError(scannedAmountRemaining - amountToSubtract);
                            purchaseInvoices[i].rows[j].amountRemaining = roundFloatingPointError(purchaseInvoices[i].rows[j].amountRemaining - amountToSubtract);
                            purchaseInvoices[i].rows[j].scannedProductAmountSubtracted += amountToSubtract;
                        }

                        if (scannedAmountRemaining === 0) {
                            break;
                        }
                    }

                    if (scannedAmountRemaining === 0) {
                        break;
                    }
                }

                if (scannedAmountRemaining > 0) {
                    missingProductsTexts.push(`${scannedAmountRemaining} x ${scannedProduct.name}`);
                }
            });

            simpleLog("Invoices with subtracted amounts from waybills and scanned products: " + JSON.stringify(purchaseInvoices));
            console.log("Invoices with subtracted amounts from waybills and scanned products", purchaseInvoices);

            if (missingProductsTexts.length > 0) {
                const errorMessage = `${t("someProductsAreMissingFromPurchaseInvoices")}: ${missingProductsTexts.join(", ")}`;
                return dispatch(errorMessageSet(errorMessage));
            }

            // Create purchase waybills from invoices where amount has been subtracted
            let savePurchaseWaybillRequests = [];
            let updatePurchaseDocumentAdditionalCostsRequests = [];

            purchaseInvoices.forEach(purchaseInvoice => {
                let savePurchaseWaybillRequest = {
                    requestName: "savePurchaseDocument",
                    warehouseID: purchaseInvoice.warehouseID,
                    currencyCode: "EUR",
                    type: "PRCWAYBILL",
                    supplierID: scanBySupplier.id,
                    no: purchaseInvoice.number,
                    requestID: purchaseInvoice.id,
                    stateID: 3  // Confirmed
                };
                let rowNo = 0;
                let totalAmountOnInvoice = 0;
                let totalAmountOnWaybill = 0;
                let netTotalOnWaybill = 0;

                purchaseInvoice.rows.forEach(row => {
                    if (row.hasOwnProperty("scannedProductAmountSubtracted")) {
                        savePurchaseWaybillRequest[`productID${rowNo}`] = row.productID;
                        savePurchaseWaybillRequest[`amount${rowNo}`] = row.scannedProductAmountSubtracted;
                        savePurchaseWaybillRequest[`price${rowNo}`] = row.price;
                        savePurchaseWaybillRequest[`discount${rowNo}`] = row.discount;
                        savePurchaseWaybillRequest[`vatrateID${rowNo}`] = row.vatrateID;
                        rowNo ++;

                        totalAmountOnWaybill += row.scannedProductAmountSubtracted;
                        const discountedPrice = row.scannedProductAmountSubtracted * Number(row.price) * (row.discount / 100);
                        netTotalOnWaybill += row.scannedProductAmountSubtracted * Number(row.price) - discountedPrice;
                    }

                    totalAmountOnInvoice += Number(row.amount);
                });

                if (rowNo > 0) {
                    savePurchaseWaybillRequest = copyAttributes(purchaseInvoice, savePurchaseWaybillRequest);

                    const divideCostBy = getApiAttributeValue("divideCostBy", purchaseInvoice.attributes);  // This attribute is set by a BO plugin
                    const additionalCosts = getApiAttributeValue("additionalCosts", purchaseInvoice.attributes);  // This attribute is set by a BO plugin
                    let additionalCostsOnWaybill = 0;

                    if (divideCostBy === "amount") {
                        additionalCostsOnWaybill = (totalAmountOnWaybill / totalAmountOnInvoice) * additionalCosts;
                    } else if (divideCostBy === "price") {
                        additionalCostsOnWaybill = (netTotalOnWaybill / purchaseInvoice.netTotal) * additionalCosts;
                    }

                    updatePurchaseDocumentAdditionalCostsRequests.push({
                        requestName: "updatePurchaseDocumentAdditionalCosts",
                        sum: additionalCostsOnWaybill,
                        currencyCode: purchaseInvoice.currencyCode,
                        currencyRate: purchaseInvoice.currencyRate,
                        dividedBy: divideCostBy === "amount" ? "AMOUNT" : "PRICE"
                    });

                    savePurchaseWaybillRequests.push(savePurchaseWaybillRequest);
                    const isPartiallyReceived = purchaseInvoice.rows.some(row => row.amountRemaining !== 0);
                    purchaseInvoice.newStateID = isPartiallyReceived ? 5 : 4;
                }
            });
            console.log("savePurchaseWaybillRequests", savePurchaseWaybillRequests);
            simpleLog("savePurchaseWaybillRequests: " + JSON.stringify(savePurchaseWaybillRequests));

            const purchaseWaybills = await dispatch(makeErplyBulkRequest(savePurchaseWaybillRequests, t("savePurchaseDocumentError"), null, null, null, true, true));

            // Save created waybills as base documents to invoices and update invoice statuses
            let savePurchaseInvoiceRequests = [];
            purchaseWaybills.forEach((purchaseWaybill, index) => {
                const purchaseInvoice = purchaseInvoices.find(invoice => invoice.number == purchaseWaybill.invoiceNo);
                const baseDocumentIDs = purchaseInvoice.baseDocuments.map(baseDocument => baseDocument.id).concat(purchaseWaybill.invoiceID).join(",");

                savePurchaseInvoiceRequests.push({
                    requestName: "savePurchaseDocument",
                    id: purchaseInvoice.id,
                    baseDocumentIDs: baseDocumentIDs,
                    stateID: purchaseInvoice.newStateID,
                    requestID: purchaseInvoice.id
                });

                // Add IDs to updatePurchaseDocumentAdditionalCosts requests
                updatePurchaseDocumentAdditionalCostsRequests[index].id = purchaseWaybill.invoiceID;
            });
            console.log("savePurchaseInvoiceRequests", savePurchaseInvoiceRequests);
            simpleLog("savePurchaseInvoiceRequests: " + JSON.stringify(savePurchaseInvoiceRequests));

            // Remove updatePurchaseDocumentAdditionalCosts requests with 0 sum and update additional costs
            updatePurchaseDocumentAdditionalCostsRequests = updatePurchaseDocumentAdditionalCostsRequests.filter(request => request.sum !== 0);
            dispatch(makeErplyBulkRequest(updatePurchaseDocumentAdditionalCostsRequests, t("updatePurchaseDocumentAdditionalCostsError"), null, null, null, false));

            saveRequestResponse = await dispatch(makeErplyBulkRequest(savePurchaseInvoiceRequests, t("savePurchaseDocumentError"), null, null, null));

            if (!saveRequestResponse.some(request => request.status === "error")) {
                // Save records into receiving area
                let saveBinRecordsRequest = {
                    requestName: "saveBinRecords",
                    requestID: 0
                };

                summedScannedProducts.forEach((scannedProduct, index) => {
                    saveBinRecordsRequest[`productID${index}`] = scannedProduct.productID;
                    saveBinRecordsRequest[`binID${index}`] = receivingArea.binID;
                    saveBinRecordsRequest[`amount${index}`] = scannedProduct.amount;
                    saveBinRecordsRequest[`creatorID${index}`] = user.employeeID;
                });

                // Remove all records from supplier bin
                const deleteBinRecordsRequest = {
                    requestName: "deleteBinRecords",
                    requestID: 1,
                    binIDs: supplierBin.binID
                };

                const requests = [saveBinRecordsRequest, deleteBinRecordsRequest];
                dispatch(makeErplyBulkRequest(requests, t("saveBinRecordsError"), null, null, null, false));
            }
        } else {
            params = addAttributeToParams(params, createdInWmsAttributeName, "1");
            let ssccNo = "";

            if (isSaadaKappiDocument) {
                const kappAssembled = getApiAttributeValue("kappAssembled", selectedDocument.attributes);   // KAPP part of the document has been confirmed
                const riiulAssembled = getApiAttributeValue("riiulAssembled", selectedDocument.attributes); // RIIUL part of the document has been confirmed
                const successMessage = isTc2000Only ? t("kappAssembled") : t("riiulAssembled");

                if (!kappAssembled && !riiulAssembled) {
                    const attributeNameToBeSaved = isTc2000Only ? "kappAssembled" : "riiulAssembled";
                    dispatch(successMessageSet(successMessage));
                    setPreScanComponent();
                    return saveAttributeToDocument(attributeNameToBeSaved, "1", selectedDocument)
                } else if (kappAssembled === "1" && riiulAssembled === "1") {
                    // Confirm as usual
                } else if (!kappAssembled && riiulAssembled === "1" && isTc2000Only) {
                    addAttributeToParams(params, "kappAssembled", "1");
                    // Confirm as usual
                } else if (!riiulAssembled && kappAssembled === "1" && !isTc2000Only) {
                    addAttributeToParams(params, "riiulAssembled", "1");
                    // Confirm as usual
                } else if (kappAssembled === "1" && isTc2000Only) {
                    dispatch(successMessageSet(successMessage));
                    return setPreScanComponent();
                } else if (riiulAssembled === "1" && !isTc2000Only) {
                    dispatch(successMessageSet(successMessage));
                    return setPreScanComponent();
                }
            }

            if (isAssembly) {
                params.request = "saveInventoryRegistration";
                params.inventoryRegistrationID = selectedDocument.inventoryRegistrationID;
                params.confirmed = 1;
                params.cause = "Komplekteeritud";
                params = await addRows(params, selectedDocument, summedScannedProducts);

                saveRequestResponse = await dispatch(makeErplyRequest(params, t("saveInventoryRegistrationError")));
                simpleLog(`Assembly params: ${JSON.stringify(params)}`);
            } else if (isCreditInvoice) {
                params.request = "saveSalesDocument";
                params.id = selectedDocument.id;
                params.confirmInvoice = 1;
                params.invoiceState = "READY";
                params = await addRows(params, selectedDocument, summedScannedProducts, returnReasonID);
                params = addExtraFields(params);

                params = await addTelemaAttribute(params, selectedDocument, confParameters, "CREDITINVOICE");

                saveRequestResponse = await dispatch(makeErplyRequest(params, t("saveSalesDocumentError")));
            } else {
                if (isSalesOrder) {
                    params.customerID = selectedDocument.clientID
                }

                // Copy parameters from document being handled to new document
                params = copyParameters(selectedDocument, params);
                // Add attributes if enabled in confParameters or if conf parameter does not exist
                if (confParameters.salesdocument_copy_with_attributes != 0) {
                    params = copyAttributes(selectedDocument, params);
                }
                // Add warehouseStatus attribute
                if (isAK && !isOutScan) {
                    params = addAttributeToParams(params,"warehouseStatus", "ready");
                }
                // Add document rows
                params = await addRows(params, selectedDocument, summedScannedProducts);

                // Add notes and packageDescription fields specified in Scan component
                params = addExtraFields(params);
                if (isSalesOrder) {
                    params = addBundleComponentsSerialNosToNotes(params);
                }

                if (isFromMultipleDocuments) {  // Purchase orders only
                    params.baseDocumentIDs = documentIDs;
                } else {
                    if (isInventoryTransfer) {
                        if (!inTransitFunctionalityEnabled || (!isOutScan && !isInTransitTransfer)) { // If inventoryTransferOrderID is set, warehouseTo cannot differ from source inventory transfer order's warehouseTo
                            params.inventoryTransferOrderID = selectedDocument.inventoryTransferID;
                        }
                    } else {
                        params.baseDocumentIDs = selectedDocument.id;
                    }
                }

                if (isInventoryTransfer) {
                    params.request = "saveInventoryTransfer";
                    params.type = "TRANSFER";
                    params.confirmed = confParameters.wmsConfirmInventoryTransfers;
                    params.creatorID = user.employeeID;

                    const sourceTransferOrderID = getApiAttributeValue("sourceTransferOrderID", selectedDocument.attributes);
                    if (!isInTransitTransfer && !sourceTransferOrderID) {
                        // Erply API enables saving only 1 followupInventoryTransferID per transfer order. If repeated inventory transfer order scan is enabled, following solution is needed
                        // Also needed for displaying source/follow-up orders/transfers in inTransit BO plugin
                        params = addAttributeToParams(params, "sourceTransferOrderID", selectedDocument.inventoryTransferID);
                    }

                    const isTransferToInTransitWarehouse = inTransitFunctionalityEnabled && isOutScan;
                    if (inTransitFunctionalityEnabled) {
                        if (isInTransitTransfer) {
                            params = addAttributeToParams(params, "sourceWarehouseID", params.warehouseFromID);
                            params = addAttributeToParams(params, "sourceTransferID", selectedDocument.inventoryTransferID);
                            params.warehouseFromID = confParameters.wmsInTransitWarehouseID;
                            params.warehouseToID = getApiAttributeValue("destinationWarehouseID", selectedDocument.attributes);
                            params.inventoryTransferOrderID = sourceTransferOrderID;
                        } else if (isTransferToInTransitWarehouse) {
                            params = addAttributeToParams(params, "destinationWarehouseID", params.warehouseToID);
                            params.warehouseToID = confParameters.wmsInTransitWarehouseID;
                        }
                    }

                    saveRequestResponse = await dispatch(makeErplyRequest(params, t("saveInventoryTransferError")));
                    if (saveRequestResponse.status !== "error") {
                        const printInventoryTransfer = async () => {
                            const url = `https://eu.erply.com/${clientCode}/file.php?lang=${confParameters.default_language}&label_pdf=1&prodmoves=1&id=${saveRequestResponse[0].inventoryTransferID}&format=${confParameters.wmsInventoryTransferReportTemplateID}&authKey=${apiSessionKey}`;
                            const arData = await getArData(url);
                            sendToPrintService(arData, dispatch(getConfParameterValue("wmsInventoryTransferReportPrinterName")), dispatch(getConfParameterValue("wmsPrinterServiceAddress")));
                        };

                        const allProductsScanned = allProductsAreScanned(currentSessionScannedProducts, summedScannedProducts);
                        if (allProductsScanned || isInTransitTransfer || isTransferToInTransitWarehouse) {
                            updateInventoryTransferOrderStatus(selectedDocument, saveRequestResponse, allProductsScanned, isTransferToInTransitWarehouse);
                        }

                        if (isOutScan && isOpenInAndroidApp && confParameters.wmsPrintInventoryTransferReport == 1 && dispatch(getConfParameterValue("wmsInventoryTransferReportPrinterName")) !== "") {
                            if (confParameters.wmsInventoryTransferReportTemplateID === "" && isTesterClientCode(clientCode)) {   // Standard printout
                                const url = `https://eu.erply.com/${clientCode}/popup.php?print=prodmove&prodmove_id=${saveRequestResponse[0].inventoryTransferID}&lang=${confParameters.default_language}&authKey=${apiSessionKey}`;
                                printStandardPrintout(url, dispatch(getConfParameterValue("wmsInventoryTransferReportPrinterName")));
                            } else if (confParameters.wmsInventoryTransferReportTemplateID !== "") {
                                printInventoryTransfer();
                            }
                        }
                    }
                } else {    // Sales and purchase documents
                    if (isSalesOrder) {
                        if (isPP) {
                            if (selectedDocument.addressID == 0) {
                                const customer = await getCustomer(selectedDocument.clientID, true, true, true, true);
                                if (customer.addresses) {
                                    params.addressID = customer.addresses[customer.addresses.length - 1].addressID;
                                }
                            }

                            if (selectedDocument.shipToID != 0) {
                                const getAddresses = selectedDocument.shipToAddressID == 0;
                                const receiver = await getCustomer(selectedDocument.shipToID, true, true, false, getAddresses);
                                params.employeeID = receiver.customerManagerID;

                                if (selectedDocument.shipToAddressID == 0 && receiver.addresses) {
                                    params.shipToAddressID = receiver.addresses[receiver.addresses.length - 1].addressID;
                                }
                            }

                            if (isPPEst) {
                                ssccNo = await getSsccNo(dispatch);
                                params = addAttributeToParams(params, "sscc", ssccNo);
                                params = addSsccToInternalNotes(params, ssccNo);
                            }
                        }

                        params.request = "saveSalesDocument";
                        params.type = confParameters.wmsCreatedSalesDocument === "fromClientCard" ? await getCustomerPreferredSalesDocument(selectedDocument) : confParameters.wmsCreatedSalesDocument;
                        params.confirmInvoice = confParameters.wmsConfirmSalesDocuments;
                        params.packerID = user.employeeID;

                        if (isAK && selectedDocument.clientPaysViaFactoring == 1) {
                            delete params.paymentType;
                            params.paymentTypeID = 5;
                        }

                        if (params.type === 'INVWAYBILL'){
                            const payer = await getPayer(selectedDocument);
                            if (payer && payer.paymentDays != 0){
                                params.paymentDays = payer.paymentDays;
                            }
                        }

                        params = await addTelemaAttribute(params, selectedDocument, confParameters, params.type);
                        if (isArvutitark(clientCode)) await addWorkorderRowsAttributes(params, selectedDocument);

                        const handleOrder = () => {
                            const unfulfillableNonExcludedRows = getUnfulfillableNonExcludedRows();
                            const someProductsWereLeftUnscanned = !allProductsAreScanned(currentSessionScannedProducts, summedScannedProducts) || unfulfillableNonExcludedRows.length > 0;
                            updateSalesOrderStatus(selectedDocument);

                            if (confParameters.partial_fulfilment_splits_order == 1 && someProductsWereLeftUnscanned) {
                                createReOrder(selectedDocument, summedScannedProducts);
                            }
                        };

                        if (confParameters.wmsCreateSalesDocumentAfterScan == 1) {
                            removeInvalidParams(params);
                            saveRequestResponse = await dispatch(makeErplyRequest(params, t("saveSalesDocumentError")));

                            if (saveRequestResponse.status !== "error") {
                                // Send invoice to Latvian account if customer is PP EE
                                const sendToLatvia = async () => {
                                    const url = `https://intralplugins.com/PrimePartner/EDI/sendToLv.php?sessionKey=${apiSessionKey}&documentID=${saveRequestResponse[0].invoiceID}`;
                                    const response  = await makeRequest(url, "GET", null, null, dispatch, false, false);
                                    if (response !== "<pre>Sending invoice to Latvian account was successful!") {
                                        dispatch(errorMessageSet("Dokumendi Lätti saatmine ebaõnnestus: " + JSON.stringify(response)));
                                    }
                                };

                                if (isPPEst && selectedDocument.clientID == 956) {
                                    sendToLatvia();
                                }

                                handleOrder();

                                const printCreatedSalesDocument = async (templateID, printerName) => {
                                    if (templateID === "" && isTesterClientCode(clientCode)) {    // Standard printout
                                        printStandardPrintout(saveRequestResponse[0].invoiceLink, printerName);
                                    } else if (templateID !== "") {
                                        const createdSalesDocumentArDataset = await getCreatedSalesDocumentArDataset(saveRequestResponse, selectedDocument.clientID);
                                        const actualReportsResponse = await dispatch(sendToActualReportsService(clientCode, JSON.stringify(createdSalesDocumentArDataset), templateID, confParameters));
                                        if (!responseHasErrors(actualReportsResponse)) sendToPrintService(actualReportsResponse, printerName, dispatch(getConfParameterValue("wmsPrinterServiceAddress")));
                                    }
                                };

                                const printSsccLabels = async () => {
                                    const createdSalesDocumentArDataset = await getCreatedSalesDocumentArDataset(saveRequestResponse);
                                    let arData = [];

                                    for (let i = 0; i < noOfPackages; i++) {
                                        const label = Object.assign({}, createdSalesDocumentArDataset);
                                        label.packageNo = i + 1;
                                        label["attribute sscc"] = ssccNo;
                                        label["attribute_sscc"] = ssccNo;

                                        arData.push(label);
                                    }

                                    for (let i = 0, n = arData.length; i < n; i++) {
                                        setTimeout(async () => {
                                            const actualReportsResponse = await dispatch(sendToActualReportsService(clientCode, JSON.stringify(arData[i]), confParameters.wmsSsccLabelTemplateID, confParameters));
                                            if (!responseHasErrors(actualReportsResponse)) sendToPrintService(actualReportsResponse, dispatch(getConfParameterValue("wmsSsccLabelPrinterName"), dispatch(getConfParameterValue("wmsSsccLabelPrinterName"))));
                                        }, timeoutBetweenPrint * i) // Otherwise print service might get clogged up and malfunction
                                    }
                                };

                                if (isOpenInAndroidApp) {
                                    let timeout = 0;
                                    if (confParameters.wmsPrintPackingSlip == 1 && dispatch(getConfParameterValue("wmsPackingSlipPrinterName")) !== "" && confParameters.wmsPackingSlipTemplateID !== "") {
                                        timeout += timeoutBetweenPrint;
                                        printCreatedSalesDocument(confParameters.wmsPackingSlipTemplateID, dispatch(getConfParameterValue("wmsPackingSlipPrinterName")));
                                    }
                                    if (confParameters.wmsPrintSalesDocument == 1 && dispatch(getConfParameterValue("wmsSalesDocumentPrinterName")) !== "") {
                                        timeout += timeoutBetweenPrint;
                                        setTimeout(() => {
                                            printCreatedSalesDocument(confParameters.wmsSalesDocumentTemplateID, dispatch(getConfParameterValue("wmsSalesDocumentPrinterName")));
                                        }, timeout) // Otherwise print service might get clogged up and malfunction
                                    }
                                    if (confParameters.wmsPrintSsccLabel == 1 && dispatch(getConfParameterValue("wmsSsccLabelPrinterName")) !== "" && confParameters.wmsSsccLabelTemplateID !== "" && noOfPackages > 0 && ssccNo !== "") {
                                        setTimeout(printSsccLabels, timeout) // Otherwise print service might get clogged up and malfunction
                                    }
                                }
                            }
                        } else {
                            handleOrder();
                        }
                    } else {    // Purchase documents
                        params.request = "savePurchaseDocument";

                        if (isPurchaseOrder) {
                            if (isRehvidPluss(clientCode)) {
                                params.type = allProductsAreScanned(currentSessionScannedProducts, summedScannedProducts) && followUpDocuments.length === 0 ? "PRCINVOICE" : "PRCWAYBILL";
                            } else {
                                params.type = confParameters.wmsCreatedPurchaseDocument;
                            }
                            params.deliveryTermsID = selectedDocument.deliveryTermsID;
                            params.confirmInvoice = confParameters.wmsConfirmPurchaseDocuments;
                            params.employeeID = user.employeeID;

                            if (confParameters.wmsConfirmPurchaseDocuments == 1) {
                                params.stateID = 3; // Confirmed
                            }

                            if (isAK) {
                                params.no = "0 ";
                                params.stateID = 5; // Saabunud/ladu kontrollinud
                            } else {
                                params.no = purchaseDocNo;
                                if (params.no === "") params.no = " "; // API doesn't allow saving empty numbers
                            }
                        } else {    // Purchase return
                            params.id = selectedDocument.id;
                            params.confirmInvoice = 1;
                            params.stateID = 3; // Confirmed
                        }

                        if (!isPurchaseReturn && (params.type === "PRCINVOICE" || selectedDocument.deliveryTermsID == 0 || params.supplierID == 0)) {
                            if (selectedDocument.supplierID == 0) {
                                return dispatch(errorMessageSet(t("noSupplier")));
                            }

                            var supplier = await getSupplier(selectedDocument.supplierID);

                            if (params.type === "PRCINVOICE"){
                                params.paymentDays = supplier.paymentDays;
                            }

                            if (selectedDocument.deliveryTermsID == 0 && supplier.hasOwnProperty("deliveryTermsID")) {
                                params.deliveryTermsID = supplier.deliveryTermsID;
                            }

                            if (params.supplierID == 0) {
                                params.supplierID = supplier.supplierID;
                            }
                        }

                        if (isTAF(clientCode) && temporaryUUID) params.temporaryUUID = temporaryUUID;
                        if (isTAF(clientCode)) params = addAttributeToParams(params, "temporaryUUID", temporaryUUID);

                        saveRequestResponse = await dispatch(makeErplyRequest(params, t("savePurchaseDocumentError")));
                        if ((saveRequestResponse.status !== "error" || isDuplicateDocumentError(saveRequestResponse, clientCode)) && isPurchaseOrder) {
                            updatePurchaseOrderStatus(currentSessionScannedProducts, summedScannedProducts);

                            if (!isDuplicateDocumentError(saveRequestResponse, clientCode)) {
                                if (multiplePurchaseOrderScanEnabled) {
                                    saveScannedAmountsJsonAttribute(saveRequestResponse[0].invoiceID, scannedProductsByDocument);
                                }

                                if (confParameters.wmsEnableScanningBatches == 1 && scannedBatches.length > 0) {
                                    saveBatchRowAttributes(saveRequestResponse);
                                }

                                if ((isAK || isTesterClientCode(clientCode)) && additionalCostsPercent > 0 && additionalCostsPercent !== "") {
                                    updateCreatedPurchaseDocumentAdditionalCosts(params, saveRequestResponse[0]);
                                }
                            }
                        }
                    }
                }
            }

            console.log("saveDocument params", params);
        }

        if ((saveRequestResponse.status !== "error" || isDuplicateDocumentError(saveRequestResponse, clientCode)) && !(isScanBySupplier && saveRequestResponse.some(request => request.status === "error"))) {
            console.log("saveRequestResponse", saveRequestResponse);
            let successMessage = t("scanSuccessful");

            if (!isDuplicateDocumentError(saveRequestResponse, clientCode)) {
                if (isPurchaseOrder || isPurchaseReturn) {
                    successMessage = params.type === "PRCINVOICE" ? t("purchaseInvoiceWaybill") : isPurchaseReturn ? capitalise(t("purchaseReturn")) : t("purchaseWaybill");
                    successMessage += ` ${t("saved")} ${t("withRegNo")} ${saveRequestResponse[0].invoiceRegNo}!`;
                } else if ((confParameters.wmsCreateSalesDocumentAfterScan == 1 && isSalesOrder) || isCreditInvoice) {
                    const number = saveRequestResponse[0].customNumber === "" ? saveRequestResponse[0].invoiceNo : saveRequestResponse[0].customNumber;
                    const documentType = isCreditInvoice ? "CREDITINVOICE" : params.type;
                    successMessage = `${t(documentType)} ${t("saved")} ${t("withNo")} ${number}`;
                }

                if (!(isSalesOrder && confParameters.wmsCreateSalesDocumentAfterScan == 0)) {
                    dispatch(setCreatedDocumentId(saveRequestResponse[0][getSavedDocumentIdFieldName(isInventoryTransfer, isAssembly, isAssignment)]));
                }
            }

            dispatch(successMessageSet(successMessage));

            if ((isSalesOrder || isPurchaseOrder) && confParameters.wmsSendComparisonEmail == 1 && !allProductsAreScanned(currentSessionScannedProducts, summedScannedProducts, true)) {
                sendDifferencesEmail(confParameters, selectedDocument, supplier, currentSessionScannedProducts, summedScannedProducts);
            }

            if (isAssembly) {
                putAssemblyProductsToReceivingArea();
            }

            let deliveryTypeIsDPD = deliveryTypeStartsWith(selectedDocument, "DPD - ");
            const deliveryTypeIsVenipak = deliveryTypeStartsWith(selectedDocument, "Venipak - ");
            const deliveryTypeIsSmartpost = deliveryTypeStartsWith(selectedDocument, "SMARTPOST - ");
            let deliveryTypeIsOmniva = deliveryTypeStartsWith(selectedDocument, "Omniva");

            if (isOutScan && isInventoryTransfer) {
                const omnivaDeliveryTypeAttributeValue = getApiAttributeValue(omnivaDeliveryTypeAttributeName, selectedDocument.attributes);

                if (omnivaDeliveryTypeAttributeValue && omnivaDeliveryTypeAttributeValue != 0 && omnivaDeliveryTypeAttributeValue !== "") {
                    deliveryTypeIsOmniva = true;
                } else {
                    deliveryTypeIsDPD = true;
                }
            }

            if (confParameters.wmsIsUsingDpd == 1 && ((isSalesOrder && deliveryTypeIsDPD && confParameters.wmsCreateSalesDocumentAfterScan == 1) ||
                (isOutScan && isInventoryTransfer && deliveryTypeIsDPD))) {
                dispatch(componentSet(isArvutitark(clientCode) || isOnOff(clientCode) || isAK || clientCode === "384621" || isCorpowear(clientCode) || isNBQ(clientCode) || isPP || clientCode === "374518" || clientCode === "4166" || isTesterClientCode(clientCode) ? "DpdParcelLabelsNew" : "DpdParcelLabels"));
            } else if (confParameters.wmsIsUsingVenipak == 1 && isSalesOrder && deliveryTypeIsVenipak && confParameters.wmsCreateSalesDocumentAfterScan == 1) {
                dispatch(componentSet("VenipakParcelLabels"));
            } else if (confParameters.wmsIsUsingSmartpost == 1 && isSalesOrder && deliveryTypeIsSmartpost && confParameters.wmsCreateSalesDocumentAfterScan == 1) {
                dispatch(componentSet("SmartpostParcelLabels"));
            } else if (confParameters.wmsIsUsingOmniva == 1 && ((isSalesOrder && deliveryTypeIsOmniva && confParameters.wmsCreateSalesDocumentAfterScan == 1) ||
                (isOutScan && isInventoryTransfer && deliveryTypeIsOmniva))) {
                dispatch(componentSet("OmnivaParcelLabels"));
            } else {
                setPreScanComponent();
            }

            // Add WMS API "END" events for analytics
            // Ignore "process already ended" API error because of documents that can be scanned multiple times
            documentIDs.split(",").forEach(documentID => {
                dispatch(makeWmsApiRequest(t, "analytics", "POST", "", null, {documentId: Number(documentID), eventType: "END", documentType: getDocumentType(componentSequence, isAssembly)}, false, null, true));
            });
        }
    };

    const deliveryTypeStartsWith = (selectedDocument, startsWithString) => {
        return !isScanBySupplier && selectedDocument.hasOwnProperty("deliveryTypeName") && selectedDocument.deliveryTypeName !== null && selectedDocument.deliveryTypeName.startsWith(startsWithString);
    };

    const updateCreatedPurchaseDocumentAdditionalCosts = async (savePurchaseDocumentParams, savePurchaseDocumentResponse) => {
        let additionalCosts = savePurchaseDocumentResponse.net;

        // Exclude non-stock products from additional costs' calculations
        if (excludedRows.length > 0 || productsNotOnDocument.length > 0) {
            const potentialNonStockProductIDs = [...new Set(excludedRows.map(row => row.productID).concat(productsNotOnDocument.map(product => product.productID)))].filter(product => product.productID != 0);

            if (potentialNonStockProductIDs.length > 0) {
                const [purchaseDocument, potentialNonStockProducts] = await Promise.all([
                    dispatch(makeErplyRequest({
                        request: "getPurchaseDocuments",
                        id: savePurchaseDocumentResponse.invoiceID
                    }, t("getPurchaseDocumentsError"), null, null, null, false, false)).then(docs => docs[0]),
                    dispatch(makeErplyRequest({
                        request: "getProducts",
                        productIDs: potentialNonStockProductIDs.join(",")
                    }, t("getProductsError"), null, null, null, true, false))
                ]);

                purchaseDocument.rows.forEach(row => {
                    const product = potentialNonStockProducts.find(product => product.productID == row.productID);

                    if (product && product.nonStockProduct == 1) {
                        const amountToSubtract = Number(row.price) * Number(row.amount);
                        console.log(`${product.name}: subtracting ${Number(row.price)} * ${Number(row.amount)} = ${amountToSubtract} from additional costs`);
                        additionalCosts -= amountToSubtract;
                    }
                });
            }
        }

        additionalCosts = additionalCosts / 100 * additionalCostsPercent;
        console.log(`Additional costs: ${additionalCosts}`);

        const params = {
            request: "updatePurchaseDocumentAdditionalCosts",
            id: savePurchaseDocumentResponse.invoiceID,
            sum: additionalCosts,
            currencyCode: savePurchaseDocumentParams.currencyCode,
            currencyRate: savePurchaseDocumentParams.currencyRate,
            dividedBy: "PRICE"
        };

        dispatch(makeErplyRequest(params, t("savePurchaseDocumentError"), null, null, null, false, false));
    };

    const addBundleComponentsSerialNosToNotes = (params) => {
        const binRecordsOfBundleComponents = binRecords.filter(binRecord => binRecord.bundleID !== 0);
        const summedSerialNos = sumSerialNoBinRecords(binRecordsOfBundleComponents);

        if (summedSerialNos.length > 0) {
            if (!params.notes) {
                params.notes = "";
            } else if (params.notes !== "") {
                params.notes += "\n";
            }

            const serialNoNotes = summedSerialNos.map(summedSerialNo => `${summedSerialNo.amount} x ${summedSerialNo.code} SN: ${summedSerialNo.serialNo}`);
            params.notes += serialNoNotes.join("; ");
        }

        return params;
    };

    const removeInvalidParams = (params) => {
        // Field "eInvoiceBuyerID" can only be used for invoice-waybills, invoices, credit invoices and sales orders.
        if (params.hasOwnProperty("eInvoiceBuyerID") && params.type === "WAYBILL") {
            delete params.eInvoiceBuyerID;
        }
    };

    // Multiple purchase orders may be scanned at once, so JSON API attributes are used to keep track of which products from which documents have been scanned
    const saveScannedAmountsJsonAttribute = (invoiceID, scannedProductsByDocument) => {
        let scannedAmountsByDocument = [];

        for (let i = 0, n = scannedProductsByDocument.length; i < n; i++) {
            let productFound = false;

            for (let j = 0, n = scannedAmountsByDocument.length; j < n; j++) {
                if (scannedProductsByDocument[i].productID === scannedAmountsByDocument[j].productID &&
                    scannedProductsByDocument[i].documentID === scannedAmountsByDocument[j].documentID) {
                    scannedAmountsByDocument[j].amount += Number(scannedProductsByDocument[i].amount);
                    productFound = true;
                    break;
                }
            }

            if (!productFound) {
                scannedAmountsByDocument.push({
                    amount: scannedProductsByDocument[i].amount,
                    batchCode: null,
                    binID: null,
                    documentID: scannedProductsByDocument[i].documentID,
                    productID: scannedProductsByDocument[i].productID
                })
            }
        }

        scannedAmountsByDocument = subtractAmountsFromFollowUpDocuments(followUpDocuments, scannedAmountsByDocument, documentIDs.split(","), true);
        const params = {json_object: {WMS: {scannedAmounts: scannedAmountsByDocument}}};
        dispatch(makeJsonApiRequest(t, `v1/json_object/prcinvoice/${invoiceID}`, "PUT", JSON.stringify(params)));
    };

    const printStandardPrintout = async (documentUrl, printerName) => {
        const base64Html = await getArData(documentUrl);
        const html = atob(base64Html);
        const length = html.length;
        const bytes = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
            bytes[i] = html.charCodeAt(i);
        }
        const decoder = new TextDecoder(); // default is utf-8
        let htmlUtf8 = decoder.decode(bytes);

        const pageBreak = `<p style="page-break-before: always">&nbsp;</p>
                        <br>`;
        let pages = [htmlUtf8];
        if (htmlUtf8.includes(pageBreak)) {
            pages = htmlUtf8.split(pageBreak);
        }

        const width = 694;
        let modifiedPages = [];
        pages.forEach(page => {
            // Add width because html2canvas does not respect some CSS
            let modifiedPage = page;
            if (isInventoryTransfer) {
                const searchString = `.data { width: 100%; }`;
                const startIndex = modifiedPage.indexOf(searchString) + searchString.length;
                const styleString = ` { width: ${width}px; }`;
                const styleStringInjectIndex = startIndex - 17;  // Remove existing width
                modifiedPage = modifiedPage.substring(0, styleStringInjectIndex) + styleString + modifiedPage.substring(startIndex);
            }

            const searchString = `<table cellPadding="0" cellSpacing="0" border="0" width="100%"`;
            const startIndex = modifiedPage.indexOf(searchString) + searchString.length;
            const styleString = ` width="${width}px"`;
            const styleStringInjectIndex = startIndex - 13;  // Remove existing width
            modifiedPage = modifiedPage.substring(0, styleStringInjectIndex) + styleString + modifiedPage.substring(startIndex);
            modifiedPages.push(modifiedPage);
        });

        const getPdfImages = async () => {
            let pdfImages = [];
            let pdfImageProcesses = [];

            for (let i = 0, n = modifiedPages.length; i < n; i++) {
                const iframe = document.createElement('iframe');
                document.body.appendChild(iframe);

                const getPdfImage = () => {
                    const iframedoc = iframe.contentDocument || iframe.contentWindow.document;
                    iframedoc.body.innerHTML = modifiedPages[i];
                    return html2canvas(iframedoc.body, {
                        width: width + 20,
                        height: 842
                    }).then(canvas => {
                        document.body.removeChild(iframe);
                        pdfImages.push(canvas.toDataURL('image/png'));
                        return true;
                    })
                };

                pdfImageProcesses.push(getPdfImage());
            }

            await Promise.all(pdfImageProcesses);
            return pdfImages;
        };

        const pdfImages = await getPdfImages();
        const pdf = new jsPDF('p', 'pt');
        pdf.addImage(pdfImages[0], 'PNG', 40, 40);

        if (pdfImages.length > 1) {
            for (let i = 1, n = pdfImages.length; i < n; i++) {
                pdf.addPage();
                pdf.addImage(pdfImages[i], 'PNG', 40, 40);
            }
        }

        sendToPrintService(btoa(pdf.output()), printerName, dispatch(getConfParameterValue("wmsPrinterServiceAddress")));
    };

    const saveBatchRowAttributes = (saveRequestResponse) => {
        let scannedBatchesCopy = scannedBatches.slice();
        let saveBatchesRequests = [];
        const rows = saveRequestResponse[0].rows;

        for (let i = 0, n = rows.length; i < n; i++) {
            for (let j = 0, n = scannedBatchesCopy.length; j < n; j++) {
                if (rows[i].productID == scannedBatchesCopy[j].productID && rows[i].amount == scannedBatchesCopy[j].amount) {
                    saveBatchesRequests.push({
                        json_object: {WMS: {batchCode: scannedBatchesCopy[j].batchCode}},
                        recordId: rows[i].stableRowID,
                        tableName: "prcinv_rows",
                    });

                    scannedBatchesCopy.splice(j, 1);
                    break;
                }
            }
        }

        console.log("saveBatchesRequests", saveBatchesRequests);
        const params = {requests: saveBatchesRequests};
        dispatch(makeJsonApiRequest(t, `v1/json_objects`, "PUT", JSON.stringify(params)));
    };

    const getCreatedSalesDocumentArDataset = async (saveRequestResponse, clientID) => {
        const customer = selectedDocumentCustomer.current ?? await getCustomer(clientID);
        return dispatch(getSalesDocumentArDataset(t, saveRequestResponse, customer));
    };

    const putAssemblyProductsToReceivingArea = () => {
        let counter = 0;
        const params = {request: "saveBinRecords"};

        const addRecord = (productID, amount) => {
            params[`binID${counter}`] = receivingArea.binID;
            params[`productID${counter}`] = productID;
            params[`amount${counter}`] = amount;
            params[`documentID${counter}`] = selectedDocuments[0].inventoryRegistrationID;
            params[`documentType${counter}`] = "INVENTORY_REGISTRATION";
            params[`creatorID${counter}`] = user.employeeID;
            counter ++;
        };

        for (let i = 0, n = rowsWithSummedProducts.length; i < n; i++) {
            addRecord(rowsWithSummedProducts[i].productID, roundFloatingPointError(rowsWithSummedProducts[i].amount));
        }
        // Add components with negative amounts
        for (let i = 0, n = componentsWithSummedAmounts.length; i < n; i++) {
            if (componentsWithSummedAmounts[i].amount < 0) {
                addRecord(componentsWithSummedAmounts[i].productID, -1 * roundFloatingPointError(componentsWithSummedAmounts[i].amount));
            }
        }

        dispatch(makeErplyRequest(params, t("saveBinRecordsError")));
    };

    const allProductsAreScanned = (currentSessionScannedProducts, summedScannedProducts, checkDifferencesForEmail = false, document = null) => {
        const summedScannedProductsArray = differentiateByPackageID ?
            // Get summed scanned rows not differentiated by package ID
            getSummedScannedProducts(currentSessionScannedProducts, false) :
            summedScannedProducts;

        if (checkDifferencesForEmail && summedScannedProductsArray.some(product => product.productIsNotOnDocument === 1)) {
            return false;
        }

        if (isFromMultipleDocuments && document) {
            const productsWithSummedAmounts = [];
            document.rows.forEach(row => {
                const product = productsWithSummedAmounts.find(product => product.productID == row.productID);
                if (product) {
                    product.amount += Number(row.amount);
                } else {
                    productsWithSummedAmounts.push({productID: row.productID, amount: Number(row.amount)});
                }
            });

            for (let i = 0, n = productsWithSummedAmounts.length; i < n; i++) {
                for (let j = 0, n = binRecords.length; j < n; j++) {
                    if (binRecords[j].documentID == document.id && binRecords[j].productID == productsWithSummedAmounts[i].productID) {
                        productsWithSummedAmounts[i].amount -= binRecords[j].amount;
                    }
                }

                if (productsWithSummedAmounts[i].amount > 0) {
                    return false;
                }
            }

            return true;
        } else {
            for (let i = 0, n = initialSubtractedRows.length; i < n; i++) {
                if (!isBundleComponent(initialSubtractedRows[i]) &&
                    (initialSubtractedRows[i].amount > 0 || (initialSubtractedRows[i].amount < 0 && checkDifferencesForEmail)) &&
                    !(isAssembly && initialSubtractedRows[i].amount < 0)) { // NBQ has products with negative amounts in recipes
                    let productHasNotBeenScanned = true;

                    if (rowIsBundle(initialSubtractedRows[i])) {
                        productHasNotBeenScanned = false;
                        const bundleAmountOnDocument = getBundleAmountOnDocument(selectedDocuments[0], initialSubtractedRows[i]);
                        const assembledBundleAmount = summedRows.find(row => row.productID == initialSubtractedRows[i].productID).amount;

                        if (bundleAmountOnDocument > assembledBundleAmount || (checkDifferencesForEmail && bundleAmountOnDocument != assembledBundleAmount)) {
                            return false;
                        }
                    } else {
                        for (let j = 0, n = summedScannedProductsArray.length; j < n; j++) {
                            if (!isBundleComponent(summedScannedProductsArray[j]) && productsAreIdentical(componentSequence, selectedDocuments, summedScannedProductsArray[j], initialSubtractedRows[i])) {
                                productHasNotBeenScanned = false;

                                if (summedScannedProductsArray[j].amount < initialSubtractedRows[i].amount ||
                                    (checkDifferencesForEmail && summedScannedProductsArray[j].amount != initialSubtractedRows[i].amount)) {
                                    return false;
                                }
                            }
                        }
                    }

                    if (productHasNotBeenScanned) {
                        return false;
                    }
                }
            }

            return true;
        }
    };

    const addTelemaAttribute = async (params, selectedDocument, confParameters, type) => {
        if (confParameters.wmsIsUsingTelema == 1) {
            let customer;
            if (selectedDocumentCustomer.current === null) {    // Customer has not been requested yet
                customer = await getCustomer(selectedDocument.clientID);
            } else {
                customer = selectedDocumentCustomer.current;
            }

            const attributeNo = getNextAttributeNumber(params);

            params[`attributeName${attributeNo}`] = "telemaDoc";
            params[`attributeType${attributeNo}`] = "int";

            const telemaCustomer = getApiAttributeValue("telemaCustomer", customer.attributes);
            const telemaDocVariation = getApiAttributeValue("telemaDocVariation", customer.attributes);

            if (telemaCustomer == 1 && ((telemaDocVariation == 0 && (type === "INVWAYBILL" || type === "CREDITINVOICE")) ||
                (telemaDocVariation == 1 && (type === "WAYBILL" || type === "INVOICE" || type === "CREDITINVOICE")))) {
                params[`attributeValue${attributeNo}`] = 1;
            } else {
                params[`attributeValue${attributeNo}`] = 0;
            }
        }

        return Promise.resolve(params);
    };

    const addWorkorderRowsAttributes = async (params, selectedDocument) => {
        if (selectedDocument.assignmentID == 0) return;

        const workorder = await dispatch(makeErplyRequest({
            request: "getAssignments",
            assignmentID: selectedDocument.assignmentID
        }, t("getAssignmentsError"))).then(workorders => workorders[0]);

        const productIDs = [...new Set(workorder.assignmentRows.map(row => row.productID))].join(",");
        const products = await dispatch(makeErplyRequest({
            request: "getProducts",
            productIDs: productIDs
        }, t("getProductsError"), null, null, null, true));

        const serialNoSplitter = "(SN: ";

        workorder.assignmentRows.forEach((row, index) => {
            const product = products.find(product => product.productID == row.productID);
            const code = product ? product.code : "";
            const itemNameParts = row.itemName.split(serialNoSplitter);
            const name = (itemNameParts[0]).trim();
            let serialNo = false;
            if (itemNameParts.length > 1) serialNo = itemNameParts[1].slice(0, -1); // Remove closing bracket

            const attributeName = `WORow${index + 1}`;
            const attributeValue = `${code},${name},${serialNo},${row.amount}`;
            addAttributeToParams(params, attributeName, attributeValue);
        });
    };

    const addAttributeToParams = (params, attributeName, attributeValue) => {
        const attributeNo = getNextAttributeNumber(params);
        params[`attributeName${attributeNo}`] = attributeName;
        params[`attributeValue${attributeNo}`] = attributeValue;

        return params;
    };

    const sendDifferencesEmail = async (confParameters, selectedDocument, supplier, currentSessionScannedProducts, summedScannedProducts) => {
        // Make pdf
        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const customerName = isOutScan ? selectedDocument.clientName : selectedDocument.supplierName;
        const documentType = isOutScan ? t("SalesOrder") : t("PurchaseOrder");
        const firstRow = `${t("productAmountsComparison")} - ${documentType} ${getDocNos()}`;
        let secondRow = isOutScan ? t("customer") : t("supplier");
        secondRow += ": " + customerName;
        let titleRows = [firstRow, secondRow];
        let tableStart = 70;
        if (isOutScan && selectedDocument.hasOwnProperty("shipToName") && selectedDocument.shipToName !== "") {
            const thirdRow = t("receiverOfGoods") + ": " + selectedDocument.shipToName;
            titleRows.push(thirdRow);
            tableStart = 90;
        }
        doc.text(titleRows, marginLeft, 40);

        const headers = [[t("code"), t("name"), t("amountOnOrder"), t("amountOnWaybill")]];
        const data = getPdfRows(currentSessionScannedProducts, summedScannedProducts);

        if (data.length > 0) {
            let content = {
                startY: tableStart,
                head: headers,
                body: data
            };
            doc.autoTable(content);

            const toAddress = await getToAddress(confParameters, selectedDocument, supplier);
            dispatch(sendEmail(t, getSubject(), t("productAmountsOnWaybillDiffer"), toAddress, btoa(doc.output()), `report_${getDocNos()}.pdf`));
        }
    };

    const getDocNos = () => {
        const numberField = isOutScan ? "number" : "regnumber";
        return selectedDocuments.map(document => document[numberField]).join(",");
    };

    const getSubject = () => {
        let subject = isOutScan ? t("SalesOrder") : t("PurchaseOrder");
        subject += ` ${getDocNos()} ${t("productAmountsDifferences")}`;

        return subject;
    };

    const getToAddress = async (confParameters, selectedDocument, supplier) => {
        if (confParameters.wmsEmailReceiver === "creator" && selectedDocument.employeeID != 0) {
            return getEmployees(selectedDocument.employeeID).then((employees) => {
                return employees[0].email;
            })
        } else if (confParameters.wmsEmailReceiver === "customerManager") {    // Client manager from client's client card
            if (isOutScan) {
                return getCustomer(selectedDocument.clientID, false, true).then((customer) => {
                    if (customer.customerManagerID != 0) {
                        return getEmployees(customer.customerManagerID).then((employees) => {
                            return employees[0].email;
                        })
                    } else {
                        return "";
                    }
                })
            } else {
                if (supplier === undefined) {
                    supplier = await getSupplier(selectedDocument.supplierID, false);   // Supplier was not requested beforehand
                }

                if (supplier.supplierManagerID != 0) {
                    return getEmployees(supplier.supplierManagerID).then((employees) => {
                        return employees[0].email;
                    })
                } else {
                    return Promise.resolve("");
                }
            }
        } else if (confParameters.wmsEmailReceiver === "customEmail") {
            return Promise.resolve(confParameters.wmsEmailReceiverCustomEmail.trim());
        } else {
            return Promise.resolve("");
        }
    };

    const getEmployees = (employeeID) => {
        const params = {
            request: "getEmployees",
            employeeID: employeeID
        };

        return dispatch(makeErplyRequest(params, t("getEmployeesError"), null, null, null, false, false));
    };

    const getPdfRows = (currentSessionScannedProducts, summedScannedProducts) => {
        let pdfRows = [];

        const summedScannedProductsArray = differentiateByPackageID ?
            // Get summed scanned rows not differentiated by package ID
            getSummedScannedProducts(currentSessionScannedProducts, false) :
            summedScannedProducts;

        summedScannedProductsArray.forEach(product => {
            if (!isBundleComponent(product)) {
                const initialProduct = initialSubtractedRows.find(initialSubtractedRow => productsAreIdentical(componentSequence, selectedDocuments, product, initialSubtractedRow));
                const productIsNotOnDocument = initialProduct === undefined;
                const amountOnOrder = productIsNotOnDocument ? 0 : initialProduct.amount;

                if (product.amount != amountOnOrder) {
                    pdfRows.push([product.code, product.name, amountOnOrder, product.amount]);
                }
            }
        });

        rowsWithSummedProducts.forEach(product => {
            if (rowIsBundle(product)) {
                const bundleAmountOnDocument = getBundleAmountOnDocument(selectedDocuments[0], product.productID);
                const assembledBundleAmount = summedRows.find(row => row.productID == product.productID).amount;

                if (bundleAmountOnDocument != assembledBundleAmount) {
                    // Bundles
                    pdfRows.push([product.code, product.name, bundleAmountOnDocument, assembledBundleAmount]);
                }
            } else if (!summedScannedProductsArray.some(scannedProduct => productsAreIdentical(componentSequence, selectedDocuments, scannedProduct, product))) {
                const initialSubtractedRow = initialSubtractedRows.find(row => productsAreIdentical(componentSequence, selectedDocuments, row, product));

                if (initialSubtractedRow) {
                    // Unscanned products
                    pdfRows.push([product.code, product.name, initialSubtractedRow.amount, 0]);
                }
            }
        });

        return pdfRows;
    };

    const getCustomerPreferredSalesDocument = (selectedDocument) => {
        return getCustomer(selectedDocument.clientID).then((customer) => {
            return customer.shipGoodsWithWaybills == 1 ? "WAYBILL" : "INVWAYBILL";
        })
    };

    const getCustomer = (clientID, showLoaders = true, detail = false, saveCustomer = true, getAddresses = false) => {
        const params = {
            request: "getCustomers",
            customerID: clientID
        };

        if (detail) {   // Needed to get customerManagerID
            params.responseMode = "detail";
        }

        if (getAddresses) {
            params.getAddresses = 1;
        }

        return dispatch(makeErplyRequest(params, t("getCustomersError"), null, null, null, false, showLoaders)).then((customers) => {
            if (saveCustomer) {
                selectedDocumentCustomer.current = customers[0];    // Save customer for further use in confirmation process
            }
            return customers[0];
        });
    };

    const createReOrder = (selectedDocument, summedScannedProducts) => {
        let params = {request: "saveSalesDocument"};
        params.customerID = selectedDocument.clientID;
        params = copyParameters(selectedDocument, params);
        params = addExtraFields(params);
        params = copyAttributes(selectedDocument, params);
        params.type = "ORDER";
        params.customNumber = getReOrderNo(selectedDocument);
        params = addReOrderRows(params, summedScannedProducts);

        console.log("Re-order params", params);
        dispatch(makeErplyRequest(params, t("saveSalesDocumentError"), null, null, null, false, false));
    };

    const addReOrderRows = (params, summedScannedProducts) => {
        let counter = 1;

        for (let i = 0, n = summedRows.length; i < n; i++) {
            if (!isBundleComponent(summedRows[i])) { // Bundle components are not added as rows
                let amount = summedRows[i].amount;

                if (rowIsBundle(summedRows[i])) {
                    const bundleAmountOnDocument = getBundleAmountOnDocument(selectedDocuments[0], summedRows[i].productID);
                    amount = bundleAmountOnDocument - amount;
                } else {
                    for (let j = 0, n = summedScannedProducts.length; j < n; j++) {
                        if (productsAreIdentical(componentSequence, selectedDocuments, summedRows[i], summedScannedProducts[j])) {
                            amount -= summedScannedProducts[j].amount;
                        }
                    }
                }

                if (amount > 0) {
                    params[`productID${counter}`] = summedRows[i].productID;
                    params[`amount${counter}`] = roundFloatingPointError(amount);
                    params[`price${counter}`] = summedRows[i].price;  // Does not differentiate between rows with same product but different prices
                    params[`vatrateID${counter}`] = summedRows[i].vatrateID;  // Does not differentiate between rows with same product but different vatrateID-s
                    params[`discount${counter}`] = summedRows[i].discount;    // Does not differentiate between rows with same product but different discounts
                    params[`itemName${counter}`] = summedRows[i].hasOwnProperty("productName") ? summedRows[i].productName : summedRows[i].name;

                    counter++;
                }
            }
        }

        const unfulfillableNonExcludedRows = getUnfulfillableNonExcludedRows();
        // Add rows not returned by getFulfillableOrders (orderedAmount exceeds fulfillableAmount)
        for (let i = 0, n = unfulfillableNonExcludedRows.length; i < n; i++) {
            params[`productID${counter}`] = unfulfillableNonExcludedRows[i].productID;
            params[`amount${counter}`] = roundFloatingPointError(unfulfillableNonExcludedRows[i].amount);
            params[`price${counter}`] = unfulfillableNonExcludedRows[i].price;
            params[`vatrateID${counter}`] = unfulfillableNonExcludedRows[i].vatrateID;
            params[`discount${counter}`] = unfulfillableNonExcludedRows[i].discount;

            counter ++;
        }

        // Add containers that were left over from scanned products needing containers
        for (let i = 0, n = containersToReOrder.current.length; i < n; i++) {
            params[`productID${counter}`] = containersToReOrder.current[i].productID;
            params[`amount${counter}`] = containersToReOrder.current[i].amount;
            params[`price${counter}`] = containersToReOrder.current[i].price;
            params[`vatrateID${counter}`] = containersToReOrder.current[i].vatrateID;
            params[`discount${counter}`] = containersToReOrder.current[i].discount;
            params[`itemName${counter}`] = containersToReOrder.current[i].hasOwnProperty("name") ? containersToReOrder.current[i].name :
                containersToReOrder.current[i].hasOwnProperty("itemName") ? containersToReOrder.current[i].itemName : containersToReOrder.current[i].productName;

            counter ++;
        }

        return params;
    };

    const getReOrderNo = (selectedDocument) => {
        const oldNo = selectedDocument.number;
        const oldNoParts = oldNo.split("-");
        const isReOrder = oldNoParts.length > 1;
        const noExtension = isReOrder ? Number(oldNoParts[1]) + 1 : 2;

        return `${oldNoParts[0]}-${noExtension}`;
    };

    const setPreScanComponent = () => {
        const go1ComponentBelow = isAssembly || isScanBySupplier || isInTransitTransfer;
        let preScanComponent = go1ComponentBelow ? componentSequence[2] : componentSequence[3];
        let newSequence = go1ComponentBelow ? componentSequence.slice(0, 3) : componentSequence.slice(0, 4);

        // Something has gone wrong. Take user back to Main Menu
        if (preScanComponent === "Scan" || preScanComponent === "ScanFinish" || preScanComponent === "ChangeScannedProducts") {
            preScanComponent = componentSequence[1];
            newSequence = componentSequence.slice(0, 2);
        }

        dispatch(componentSet(preScanComponent));
        dispatch(setSequence(newSequence));
    };

    const copyParameters = (selectedDocument, params) => {
        // Params not copied
        const skippableParams = ["id", "number", "date", "rows", "attributes", "clientID", "baseDocumentIDs", "inventoryTransferID", "type", "warehouseName", "time", "supplierName",
            "supplierGroupID", "contactName", "employeeName", "supplierID2", "supplierName2", "lastModified", "address", "netTotal", "vatTotal", "total", "netTotalsByTaxRate",
            "vatTotalsByTaxRate", "invoiceLink", "baseToDocuments", "baseDocuments", "clientName", "clientEmail", "clientCardNumber", "clientFactoringContractNumber",
            "clientPaysViaFactoring", "followUpDocuments", "netTotalsByRate", "vatTotalsByRate", "receiptLink", "otherCommissionReceivers", "deliveryTypeName", "referenceNumber",
            "pointOfSaleName", "transactionTypeName", "transportTypeName", "deliveryTerms", "lastModifierUsername", "inventoryTransferNo", "followupInventoryTransferID",
            "added", "paymentStatus", "customNumber", "stateID", "numberSuffix"];
        // Params not copied if their value is 0 or empty array
        const skippableIf0Params = ["shipToID", "shipToAddressID", "shipToContactID", "status", "webShopOrderNumbers", "algorithmVersion", "eInvoiceBuyerID", "ediStatus", "ediText", "workOrderID"];
        const paramNames = Object.keys(selectedDocument);

        for (let i = 0, n = paramNames.length; i < n; i++) {
            if (selectedDocument[paramNames[i]] !== null && // Do not copy params if their value is null
                selectedDocument[paramNames[i]] !== "" &&   // Do not copy params if their value is empty
                !skippableParams.includes(paramNames[i]) &&
                !(skippableIf0Params.includes(paramNames[i]) && (selectedDocument[paramNames[i]] == 0 || selectedDocument[paramNames[i]].length === 0))) {
                params[paramNames[i]] = paramNames[i] === "webShopOrderNumbers" ? JSON.stringify(selectedDocument[paramNames[i]]) : selectedDocument[paramNames[i]];
            }
        }

        return params;
    };

    const copyAttributes = (selectedDocument, params) => {
        const excludedAttributeNames = ["warehouseStatus", "kappAssembled", "riiulAssembled", "tc2000ReturnFileReceived", documentFirstOpenedAttributeName, createdInWmsAttributeName];
        let attributeNo = getNextAttributeNumber(params);

        if (selectedDocument.hasOwnProperty("attributes")) {
            for (let i = 0, n = selectedDocument.attributes.length; i < n; i++) {
                if (!excludedAttributeNames.includes(selectedDocument.attributes[i].attributeName) && !selectedDocument.attributes[i].attributeName.includes("serialNo")) {
                    params[`attributeName${attributeNo}`] = selectedDocument.attributes[i].attributeName;
                    params[`attributeType${attributeNo}`] = selectedDocument.attributes[i].attributeType;
                    params[`attributeValue${attributeNo}`] = selectedDocument.attributes[i].attributeValue;
                    attributeNo ++;
                }
            }
        }

        return params;
    };

    const addExtraFields = (params) => {
        for (let [key, value] of Object.entries(extraFields)) {
            params[key] = value;
        }

        return params;
    };

    const addRows = async (params, selectedDocument, summedScannedProducts, returnReasonID) => {
        let counter = 0;
        let containersNeeded = [];    // Beverage containers that are always sold together with the products
        let productCostsForSpecificAmounts; // Assembly only

        if (isAssembly) {
            // Get product costs for specific amounts
            const getProductCostForSpecificAmountRequest = {
                request: "getProductCostForSpecificAmount",
                warehouseID: selectedWarehouse.warehouseID
            };

            componentsWithSummedAmounts.forEach((component, index) => {
                getProductCostForSpecificAmountRequest[`productID${index}`] = component.productID;
                getProductCostForSpecificAmountRequest[`amount${index}`] = roundFloatingPointError(component.amount);
            });

            simpleLog("getProductCostForSpecificAmountRequest: " + JSON.stringify(getProductCostForSpecificAmountRequest))
            productCostsForSpecificAmounts = await dispatch(makeErplyRequest(getProductCostForSpecificAmountRequest, t("getProductCostForSpecificAmountError"), null, null, null));
            simpleLog("productCostsForSpecificAmounts: " + JSON.stringify(productCostsForSpecificAmounts))

            // Add rows previously on inventory registration
            for (let i = 0, n = rowsWithSummedProducts.length; i < n; i++) {
                const mainProduct = productsOnDocument.find(product => product.productID == rowsWithSummedProducts[i].productID);
                let price = 0;

                if (mainProduct.hasOwnProperty("productComponents")) {
                    mainProduct.productComponents.forEach(component => {
                        let cost;
                        if (component.amount > 0) {
                            const productCostForSpecificAmount = productCostsForSpecificAmounts.find(productCostForSpecificAmount => productCostForSpecificAmount.productID == component.componentID);
                            cost = productCostForSpecificAmount.cost;
                        } else {
                            const recipeProduct = recipeProducts.find(recipeProduct => recipeProduct.productID == component.componentID);
                            cost = recipeProduct.FIFOCost;
                        }

                        price += Number(cost) * Number(component.amount);
                    });
                } else {
                    price = rowsWithSummedProducts[i].price;    // Product is not an assembly product
                }

                params[`productID${counter}`] = rowsWithSummedProducts[i].productID;
                params[`amount${counter}`] = roundFloatingPointError(rowsWithSummedProducts[i].amount);
                params[`price${counter}`] = price;
                counter++;
            }

            // Add components with negative amounts in recipes
            for (let i = 0, n = componentsWithSummedAmounts.length; i < n; i++) {
                if (componentsWithSummedAmounts[i].amount < 0) {
                    params[`productID${counter}`] = componentsWithSummedAmounts[i].productID;
                    params[`amount${counter}`] = -1 * roundFloatingPointError(componentsWithSummedAmounts[i].amount);
                    params[`price${counter}`] = componentsWithSummedAmounts[i].FIFOCost;
                    counter++;
                }
            }
        }

        if (confParameters.wmsSortProductsOnCreatedDocumentBy === "code") {
            summedScannedProducts.sort((a, b) => a.code.localeCompare(b.code));
        }

        for (let i = 0, n = summedScannedProducts.length; i < n; i++) {
            if (!isBundleComponent(summedScannedProducts[i])) { // Bundle components are not added as rows
                let amount = summedScannedProducts[i].amount;
                const productIsNotOnDocument = summedScannedProducts[i].productIsNotOnDocument === 1;

                let vatrateID = getRowFieldValue("vatrateID", summedScannedProducts[i]);
                if (vatrateID === undefined) {  // Scanned product not on document
                    vatrateID = summedScannedProducts[i].vatrateID;

                    if (isPurchaseOrder || isPurchaseReturn) {
                        const supplier = await getSupplier(selectedDocument.supplierID);
                        if (supplier.vatrateID !== 0) vatrateID = supplier.vatrateID;
                    }
                }

                let userEnteredPrice = 0;
                if (productIsNotOnDocument) {
                    const productIDToUserEnteredPrice = productIDsToUserEnteredPrices.find(p => p.productID == summedScannedProducts[i].productID);
                    if (productIDToUserEnteredPrice) {  // App has not been restarted (Redux state persists)
                        userEnteredPrice = productIDToUserEnteredPrice.price;
                    }
                }

                let product = selectedDocumentsProducts.find(product => product.productID == summedScannedProducts[i].productID);
                if (!product) {
                    product = productsNotOnDocument.find(product => product.productID == summedScannedProducts[i].productID);
                }

                // Does not differentiate between rows with same product but different prices
                const getPrice = () => {
                    if (isAssembly) {
                        const productCostForSpecificAmount = productCostsForSpecificAmounts.find(productCostForSpecificAmount => productCostForSpecificAmount.productID == summedScannedProducts[i].productID);
                        return Number(productCostForSpecificAmount.cost);
                    } else if (productIsNotOnDocument) {
                        return isOutScan ? product.price : userEnteredPrice;
                    } else {
                        return Number(getRowFieldValue("price", summedScannedProducts[i]));
                    }
                };

                // Set rows for products with serial no
                if (productNeedsSerialNumber(product, componentSequence, isScanBySupplier)) {
                    const currentSessionBinRecords = getCurrentSessionBinRecords(binRecords, followUpDocuments);
                    const binRecordsOfProduct = currentSessionBinRecords.filter(binRecord => binRecord.productID == summedScannedProducts[i].productID &&
                        binRecord.customName === summedScannedProducts[i].name && binRecord.bundleID === 0);
                    const summedSerialNos = sumSerialNoBinRecords(binRecordsOfProduct);

                    for (let j = 0, n = summedSerialNos.length; j < n; j++) {
                        if (summedSerialNos[j].amount > 0) {
                            params[`productID${counter}`] = summedScannedProducts[i].productID;
                            params[`amount${counter}`] = roundFloatingPointError(summedSerialNos[j].amount);
                            params[`price${counter}`] = getPrice();
                            params[`itemName${counter}`] = `${summedScannedProducts[i].name} SN: ${summedSerialNos[j].serialNo}`;
                            params[`vatrateID${counter}`] = vatrateID;  // Does not differentiate between rows with same product but different vatrateID-s
                            params[`discount${counter}`] = getRowFieldValue("discount", summedScannedProducts[i]);

                            amount -= summedSerialNos[j].amount;
                            counter++;
                        }
                    }
                }

                // Separate rows with batches
                if (!isOutScan && !isInventoryTransfer && confParameters.wmsEnableScanningBatches == 1) {
                    for (let j = 0, n = scannedBatches.length; j < n; j++) {
                        if (summedScannedProducts[i].productID == scannedBatches[j].productID) {
                            params[`productID${counter}`] = summedScannedProducts[i].productID;
                            params[`amount${counter}`] = roundFloatingPointError(scannedBatches[j].amount);
                            params[`price${counter}`] = getPrice();
                            params[`itemName${counter}`] = summedScannedProducts[i].name;
                            params[`vatrateID${counter}`] = vatrateID;
                            params[`discount${counter}`] = getRowFieldValue("discount", summedScannedProducts[i]);    // Does not differentiate between rows with same product but different discounts

                            amount -= scannedBatches[j].amount;
                            counter++;
                        }
                    }
                }

                if (amount > 0 || (negativeScanAllowed && amount != 0)) {
                    const amountOnCreatedDocument = roundFloatingPointError(isAssembly || isCreditInvoice || isPurchaseReturn ? -1 * amount : amount);

                    params[`productID${counter}`] = summedScannedProducts[i].productID;
                    params[`amount${counter}`] = amountOnCreatedDocument;
                    params[`price${counter}`] = getPrice();

                    if (!isInventoryTransfer && !isAssembly) {
                        params[`vatrateID${counter}`] = vatrateID;  // Does not differentiate between rows with same product but different vatrateID-s
                        params[`discount${counter}`] = getRowFieldValue("discount", summedScannedProducts[i]);    // Does not differentiate between rows with same product but different discounts
                        params[`itemName${counter}`] = summedScannedProducts[i].name;
                    }

                    if (needsReasonCodes) {
                        params[`returnReasonID${counter}`] = returnReasonID;
                    }

                    if (summedScannedProducts[i].hasOwnProperty("packageID") && summedScannedProducts[i].packageID != 0) {
                        params[`packageID${counter}`] = summedScannedProducts[i].packageID;
                    }

                    counter ++;

                    // Check for containers
                    if (isSalesOrder && product.containerID != 0) {
                        const containerAmount = amountOnCreatedDocument * Number(product.containerAmount);
                        const existingContainer = containersNeeded.find(container => container.productID == product.containerID);

                        if (existingContainer) {
                            existingContainer.amount += containerAmount;
                        } else {
                            containersNeeded.push({productID: product.containerID, amount: containerAmount});
                        }
                    }
                }
            }
        }

        // Add bundles (bundle components are not added as rows)
        if (isSalesOrder) {
            summedRows.forEach(row => {
                if (rowIsBundle(row)) {
                    params[`productID${counter}`] = row.productID;
                    params[`amount${counter}`] = row.amount;
                    params[`price${counter}`] = row.price;
                    params[`vatrateID${counter}`] = row.vatrateID;
                    params[`discount${counter}`] = row.discount;
                    params[`itemName${counter}`] = row.itemName;
                    counter ++;
                }
            });
        }

        // Add in rows excluded from display (wmsExcludedEANs), only sales and purchase orders and credit invoices
        for (let i = 0, n = excludedRows.length; i < n; i++) {
            let excludedRowAmount = roundFloatingPointError(excludedRows[i].amount);

            // If excluded rows have containers, bring as many of them as needed to the follow-up document, put the rest to re-order
            for (let j = 0, n = containersNeeded.length; j < n; j++) {
                if (excludedRows[i].productID == containersNeeded[j].productID) {
                    if (excludedRowAmount > containersNeeded[j].amount) {
                        excludedRows[i].amount = excludedRowAmount - containersNeeded[j].amount;
                        containersToReOrder.current.push(excludedRows[i]);
                        excludedRowAmount = containersNeeded[j].amount;
                    }

                    containersNeeded[j].amount -= excludedRowAmount;
                    break;
                }
            }

            if (excludedRowAmount > 0 || isCreditInvoice) {
                params[`productID${counter}`] = excludedRows[i].productID;
                params[`amount${counter}`] = excludedRowAmount;
                params[`price${counter}`] = excludedRows[i].price;
                params[`vatrateID${counter}`] = excludedRows[i].vatrateID;
                params[`discount${counter}`] = excludedRows[i].discount;
                params[`itemName${counter}`] = excludedRows[i].hasOwnProperty("name") ? excludedRows[i].name : excludedRows[i].hasOwnProperty("itemName") ? excludedRows[i].itemName : excludedRows[i].productName;

                if (needsReasonCodes) {
                    params[`returnReasonID${counter}`] = returnReasonID;
                }

                counter ++;
            }
        }

        if (!differentiateByPackageID && !isFromMultipleDocuments && !isAssembly && confParameters.wmsSortProductsOnCreatedDocumentBy === "orderOnBaseDocument") {
            params = rearrangeRowsAccordingToBaseDocument(params, excludedRows.concat(summedScannedProducts), selectedDocument, isCreditInvoice);
        }

        return params;
    };

    const updateInventoryTransferOrderStatus = (selectedDocument, saveRequestResponse, allProductsScanned, isTransferToInTransitWarehouse) => {
        let params = {
            request: "saveInventoryTransfer",
            inventoryTransferID: selectedDocument.inventoryTransferID,
        };

        if (allProductsScanned) params.status = "FULFILLED";

        if (isInTransitTransfer || isTransferToInTransitWarehouse) {
            params.attributeName0 = "followUpTransferID";
            params.attributeValue0 = saveRequestResponse[0].inventoryTransferID;
        }

        dispatch(makeErplyRequest(params, t("saveInventoryTransferError"), null, null, null, false, false));
    };

    const updateSalesOrderStatus = (selectedDocument) => {
        let params = {
            request: "saveSalesDocument",
            id: selectedDocument.id
        };

        if (isArvutitark(clientCode)) params = addAttributeToParams(params, "statusWEB", "6"); // Completed

        if (!(isRehvidPluss(clientCode) && confParameters.wmsCreateSalesDocumentAfterScan == 0)) {
            params.invoiceState = "FULFILLED";
        } else {
            params = addAttributeToParams(params, "documentScannedInWms", "1");
        }

        dispatch(makeErplyRequest(params, t("saveSalesDocumentError"), null, null, null, false, false));
    };

    const updatePurchaseOrderStatus = (currentSessionScannedProducts, summedScannedProducts) => {
        let requests = [];

        for (let i = 0, n = selectedDocuments.length; i < n; i++) {
            const request = {
                requestName: "savePurchaseDocument",
                id: selectedDocuments[i].id,
                requestID: i
            };

            if (!allProductsAreScanned(currentSessionScannedProducts, summedScannedProducts, false, selectedDocuments[i]) && confParameters.wmsSetPurchaseOrderAlwaysFulfilled == 0) {
                request.stateID = isAK ? 10 : isAmericanTest ? 6 : isTAF(clientCode) ? 8 : 5; // Partially fulfilled
            } else {
                request.stateID = isAmericanTest ? 5 : 4; // Fulfilled
            }

            requests.push(request)
        }

        dispatch(makeErplyBulkRequest(requests, t("savePurchaseDocumentError"), null, null, null, false));
    };

    const getSupplier = (supplierID, showLoaders = true) => {
        if (selectedDocumentSupplier.current !== null) return selectedDocumentSupplier.current;

        const params = {
            request: "getSuppliers",
            supplierID: supplierID,
            responseMode: "detail"
        };

        return dispatch(makeErplyRequest(params, t("getSuppliersError"), null, null, null, false, showLoaders)).then((suppliers) => {
            selectedDocumentSupplier.current = suppliers[0];
            return suppliers[0];
        });
    };

    const getPayer = (selectedDocument) => {
        let payerID = selectedDocument.clientID;
        if (selectedDocument.hasOwnProperty("payerID") && selectedDocument.payerID != 0) {
            payerID = selectedDocument.payerID;
        }

        const params = {
            request: "getCustomers",
            customerID: payerID,
            responseMode: "detail"
        };

        return dispatch(makeErplyRequest(params, t("getCustomersError"))).then((customers) => {
            return customers[0];
        });
    };

    const getRowFieldValue = (fieldName, product) => {
        for (let i = 0, n = summedRows.length; i < n; i++) {
            if (productsAreIdentical(componentSequence, selectedDocuments, summedRows[i], product)) {
                return summedRows[i][fieldName];
            }
        }
    };

    const createDetailedDocComparison = () => {
        const getPackageHeader = () => {
            if (differentiateByPackageID) {
                return <Table.HeaderCell>{capitalise(t("package"))}</Table.HeaderCell>
            }
        };

        if (summedScannedProducts.length !== 0) {
            return <Transition visible={showDetailedDocComparison} animation='slide down' duration={200}>
                <Table className={"overflow"} color={"grey"} celled inverted unstackable columns={differentiateByPackageID ? 6 : 5}>  {/*For some reason adding an extra column fixes the layout*/}
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign={"center"} colSpan={differentiateByPackageID ? 5 : 4}>{t("scannedProducts")}</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell>{t("code")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("name")}</Table.HeaderCell>
                            {getPackageHeader()}
                            <Table.HeaderCell>{t("amountOnOrder")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("amountOnWaybill")}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {getDetailedDocComparisonRows()}
                    </Table.Body>
                </Table>
            </Transition>
        }
    };

    const getDetailedDocComparisonRows = () => {
        return summedScannedProducts.map(product => getDetailedDocComparisonRow(product));
    };

    const getDetailedDocComparisonRow = (product) => {
        const getPackageCell = () => {
            if (differentiateByPackageID) {
                let packageName = "";

                if (product.hasOwnProperty("packageID") && product.packageID != 0) {
                    const productPackage = getProductPackage(product, selectedDocumentsProducts.concat(productsNotOnDocument));
                    packageName = productPackage.packageType;
                }

                return <Table.Cell>{packageName}</Table.Cell>
            }
        };

        const initialProduct = initialSubtractedRows.find(initialSubtractedRow => productsAreIdentical(componentSequence, selectedDocuments, initialSubtractedRow, product));
        const productIsNotOnDocument = initialProduct === undefined;
        const amountOnOrder = productIsNotOnDocument ? 0 : initialProduct.amount;
        const scannedAmount = product.amount;

        let code = product.code;
        let productWithExtraData = selectedDocumentsProducts.find(productWithExtraData => productWithExtraData.productID == product.productID);
        if (!productWithExtraData) {
            productWithExtraData = productsNotOnDocument.find(productWithExtraData => productWithExtraData.productID == product.productID);
        }
        if (isAK && !isOutScan && productWithExtraData.supplierCode && productWithExtraData.supplierCode !== "") {
            code += ` [${productWithExtraData.supplierCode}]`;
        }

        if (product.amount != 0) {
            return <Table.Row key={product.productID}>
                <Table.Cell>{code}</Table.Cell>
                <Table.Cell>{product.name}</Table.Cell>
                {getPackageCell()}
                <Table.Cell>{amountOnOrder}</Table.Cell>
                <Table.Cell>{scannedAmount}</Table.Cell>
            </Table.Row>
        }
    };

    const getProductsSum = (products, differentiateBetweenTc2000AndRegularProducts = false) => {
        let noOfProducts = 0;

        for (let i = 0, n = products.length; i < n; i++) {
            if (differentiateBetweenTc2000AndRegularProducts && isAK && isSaadaKappiDocument) {
                if (isTc2000Only && rowHasTc2000Product(selectedDocumentsProducts, products[i])) {
                    noOfProducts += Number(products[i].amount);
                } else if (!isTc2000Only && !rowHasTc2000Product(selectedDocumentsProducts, products[i])) {
                    noOfProducts += Number(products[i].amount);
                }
            } else if (!(isAssembly && products[i].amount < 0)) {  // NBQ has products with negative amounts in recipes
                noOfProducts += Number(products[i].amount);
            }
        }

        return roundFloatingPointError(noOfProducts);
    };

    const getConfirmGoodsButtonText = () => {
        return isAssembly ? t("confirmAssembly"): isOutScan ? t("confirmGoodsOut") : t("confirmGoodsIn");
    };

    const createConfirmGoodsButton = () => {
        let disabled = false;
        if (isAssembly && !allProductsAreScanned(currentSessionScannedProducts, summedScannedProducts)) {
            disabled = true;
        }
        return <Button disabled={disabled} className={"menuBtn"} color={"green"} onClick={() => handleConfirmGoodsOnClick()} size='big'>{getConfirmGoodsButtonText()}</Button>;
    };

    const createAdditionalCostsField = () => {
        if (isAK && isPurchaseOrder) {
            return (
                <Form>
                    <Form.Field className={"flex marginBottomVerySmall"}>
                        <Label pointing={"right"}>{t("additionalCosts")} (%)</Label>
                        <Input type={"number"} onInput={e => setAdditionalCostsPercent(e.target.value)} value={additionalCostsPercent}/>
                    </Form.Field>
                </Form>
            )
        }
    };

    const createDocNoField = () => {
        if (isPurchaseOrder && userSetsPurchaseDocNo) {
            return (
                <Form>
                    <Form.Field className={"flex marginBottomVerySmall"}>
                        <Label pointing={"right"}>{t("docNo")}</Label>
                        <Input ref={docNoInput} onInput={e => setPurchaseDocNo(e.target.value)} value={purchaseDocNo} onFocus={() => docNoInput.current.select()}/>
                    </Form.Field>
                </Form>
            )
        }
    };

    const createNoOfPackagesField = () => {
        if (isSalesOrder && confParameters.wmsPrintSsccLabel == 1) {
            return (
                <Form>
                    <Form.Field className={"flex marginBottomSmall"}>
                        <Label pointing={"right"}>{t("noOfPackages")}</Label>
                        <Input type={"number"} onInput={e => setNoOfPackages(e.target.value)} value={noOfPackages}/>
                    </Form.Field>
                </Form>
            )
        }
    };

    // Get out of stock rows that are stock products and are not excluded with the "wmsExcludedEans" setting
    const getUnfulfillableNonExcludedRows = () => {
        return unfulfillableRows.filter(unfulfillableRow => !excludedRows.some(excludedRow => excludedRow.productID == unfulfillableRow.productID));
    };

    const getProductsOnDocText = () => {
        if (isAK && isSaadaKappiDocument) {
            return isTc2000Only ? "tc2000ProductsOnDoc" : "nonTc2000ProductsOnDoc";
        }
        return "productsOnDoc";
    };

    return (
        <div>
            <Table onClick={() => setShowDetailedDocComparison(!showDetailedDocComparison)} celled inverted unstackable>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>{t(getProductsOnDocText())}</Table.Cell>
                        <Table.Cell>{getProductsSum(initialSubtractedRows.filter(row => !rowIsBundle(row)), true)}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>{t("productsScanned")}</Table.Cell>
                        <Table.Cell>{getProductsSum(summedScannedProducts)}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            {createDetailedDocComparison()}
            {createAdditionalCostsField()}
            {createDocNoField()}
            {createNoOfPackagesField()}
            {createConfirmGoodsButton()}
            <Button className={"menuBtn"} primary onClick={() => handleChangeScannedProductsOnClick(true)} size='big'>{t("changeDifferences")}</Button>
            <Button className={"menuBtn"} primary onClick={() => handleChangeScannedProductsOnClick()} size='big'>{t("changeScannedProducts")}</Button>
            <div className={"flex flexCenter"}>
                <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                <GoBackToStartBtn/>
            </div>
        </div>
    );
};

export default ScanFinish
