const initialState = {
    binRecords: [],
};

export const getBinRecordsReducer = (state = initialState, action) => {
    switch(action.type) {
        case "GET_BIN_RECORDS_SUCCESS":
            return {
                ...state,
                binRecords: action.payload,
            };
        case "ADD_BIN_RECORD":
            return {
                ...state,
                binRecords: addBinRecord(state.binRecords, action.payload)
            };
        default:
            return state;
    }
};

const addBinRecord = (binRecords, binRecord) => {
    return [...binRecords, binRecord];
};
