const initialState = {
    bins: null,
    receivingArea: null,
    cart: null
};

export const getBinsReducer = (state = initialState, action) => {
    switch(action.type) {
        case "GET_BINS_SUCCESS":
            return {
                ...state,
                bins: action.payload,
            };
        case "SET_RECEIVING_AREA":
            return {
                ...state,
                receivingArea: action.payload,
            };
        case "SET_CART":
            return {
                ...state,
                cart: action.payload,
            };
        default:
            return state;
    }
};
