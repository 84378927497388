import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
    Accordion,
    Button,
    Checkbox,
    Dropdown,
    Icon,
    Input,
    Modal,
    Table,
    TextArea,
    Transition
} from 'semantic-ui-react'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {errorMessageSet, successMessageSet} from "../actions/headerMessage";
import {
    getBinQuantities,
    getBinRecords, getBins,
    getScannedBatchesByDocument,
    makeErplyBulkRequest,
    makeErplyRequest, makeJsonApiRequest, makeWmsApiRequest
} from "../util/erplyRequests";
import {componentSet, setIsLoading} from "../actions/component";
import {setModal} from "../actions/modal";
import GoBackToStartBtn from "./GoBackToStartBtn";
import {
    setBinsContainingDocumentProducts,
    setDocumentExtraFields,
    setFollowUpDocuments,
    setInitialSubtractedRows,
    setIsTc2000Only,
    setProductsNotOnDocument,
    setScannedProducts,
    setSelectedDocumentsAttribute,
    setSelectedDocumentsProducts,
    setSummedScannedProducts,
    setUserEnteredPrice,
    setScannedBatches,
    addScannedBatch,
    setUnsavedScannedBatches,
    setScannedProductsByDocument,
    setNewAmountToProductInRowsWithSummedProducts,
    setCurrentSessionScannedProducts
} from "../actions/scan";
import {addBinRecord} from "../actions/getBinRecords";
import {
    getBinQuantitiesSuccess,
    setBinQuantitiesOfProductNotOnDocument
} from "../actions/getBinQuantities";
import SaadaKappiButton from "./SaadaKappiButton";
import GoBackBtn from "./GoBackBtn";
import {getCustomersSuccess} from "../actions/getCustomers";
import {
    commaSeparatedCodesIncludeCode,
    documentIsCreditInvoice,
    documentIsPurchaseReturn,
    documentIsSalesOrder,
    getApiAttributeValue,
    getCafaConfParameterValue,
    getDocumentType,
    getGetProductsCodeOrder,
    getIdFieldName,
    getProductProperty,
    makeRequest,
    playSound,
    getScannedProductNamesOnDocuments,
    roundFloatingPointError,
    rowHasTc2000Product,
    setFollowUpDocumentsScannedAmountsFromJsonAttributes,
    sumBatches,
    translateAccordingToCountry,
    translateBinFromEng,
    getSummedScannedProducts,
    documentIsPurchaseOrder,
    getScannedProducts,
    productsAreIdentical,
    deepCopy,
    rowIsBundle,
    isBundleComponent,
    productNeedsSerialNumber,
    productIsInSelectedBundle,
    productPresentOnlyInBundle,
    bundleIsFullyScanned,
    isNotAPositiveInteger,
    differentiateProductsByPackageID,
    getLocationInWarehouseFromProductCard,
    confirmBinAmountWithUser,
    translateBinToEng, getAmountLeftOnDocument, getConfParameterValue, arraysAreEqual,
} from "../util/misc";
import {
    isAlasKuul,
    isCorpowear,
    isMatkasuvilad,
    isNBQ,
    isOverall,
    isPrimePartner,
    isRFix,
    isTAF
} from "../util/isClient";
import {akTc2000BinId, akTc2000LocationInWarehouseId, wmsWarningAttributeName} from "../util/constants";
import {setEditableProduct} from "../actions/createEditProduct";
import {getDeliveryTypesSuccess} from "../actions/getDeliveryTypes";
import PrintLabelsButton from "./PrintLabelsButton";
import {setInformativeModal} from "../actions/informativeModal";

let scannedProduct = null;

const Scan = () => {
    const amountInput = useRef(null);
    const scanInput = useRef(null);
    const binInput = useRef(null);
    const serialNoInput = useRef(null);
    const addEanInput = useRef(null);
    const changeEanInput = useRef(null);
    const inScanBinInput = useRef(null);
    const scanInputTimer = useRef(0);
    const getInScanBinInputTimer = useRef(0);

    const lastScannedCode = useRef("");

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const defaultPackaging = t("piece");
    const defaultPackagingOptions = [{key: 0, text: defaultPackaging, value: "unit_0"}];

    const isAssembly = useSelector(state => state.scanReducer.isAssembly);  // Used for Products Out -> Assemble
    const componentsWithSummedAmounts = useSelector(state => state.scanReducer.componentsWithSummedAmounts);
    const rowsWithSummedProducts = useSelector(state => state.scanReducer.rowsWithSummedProducts);
    const productsOnDocument = useSelector(state => state.scanReducer.selectedDocumentsProducts);
    const recipeProducts = useSelector(state => state.scanReducer.recipeProducts);
    const summedRows = isAssembly ? componentsWithSummedAmounts : rowsWithSummedProducts;
    const selectedDocumentsProducts = isAssembly ? recipeProducts : productsOnDocument;
    const documentIDs = useSelector(state => state.scanReducer.documentIDs);
    const selectedDocuments = useSelector(state => state.scanReducer.selectedDocuments);
    const previousComponent = useSelector(state => state.componentReducer.previousComponent);
    const componentSequence = useSelector(state => state.componentReducer.componentSequence);
    const scannedProducts = useSelector(state => state.scanReducer.scannedProducts);
    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);
    const receivingArea = useSelector(state => state.getBinsReducer.receivingArea);
    const user = useSelector(state => state.verifyUserReducer.user);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const cafaConfParameters = useSelector(state => state.newErplyApisReducer.cafaConfParameters);
    const documentHasBeenPreviouslyScanned = useSelector(state => state.scanReducer.documentHasBeenPreviouslyScanned);   // Alas-Kuul-specific. Needed for KAPP/RIIUL logic. Sales orders and inv transfers
    const isLoading = useSelector(state => state.componentReducer.isLoading);
    const modalMessage = useSelector(state => state.modalReducer.message);
    const modalHeading = useSelector(state => state.modalReducer.heading);
    const previousModalMessage = useSelector(state => state.modalReducer.previousMessage);
    const binRecords = useSelector(state => state.getBinRecordsReducer.binRecords);
    const productsNotOnDocument = useSelector(state => state.scanReducer.productsNotOnDocument);
    const followUpDocuments = useSelector(state => state.scanReducer.followUpDocuments);
    const summedScannedProducts = useSelector(state => state.scanReducer.summedScannedProducts);
    const unsavedScannedBatches = useSelector(state => state.scanReducer.unsavedScannedBatches);
    // Supplier whose purchase invoices will be used to map scanned products after scan (Rehvid Pluss only)
    const scanBySupplier = useSelector(state => state.scanReducer.scanBySupplier);
    // Intermediary bin where products from a specific supplier's purchase invoices are scanned to and later removed after confirming scan (Rehvid Pluss only)
    const supplierBin = useSelector(state => state.scanReducer.supplierBin);
    const isInTransitTransfer = useSelector(state => state.scanReducer.isInTransitTransfer);

    // OutScan-only selectors
    const deliveryTypes = useSelector(state => state.getDeliveryTypesReducer.deliveryTypes);
    const binQuantities = useSelector(state => state.getBinQuantitiesReducer.binQuantities);
    const binQuantitiesOfProductNotOnDocument = useSelector(state => state.getBinQuantitiesReducer.binQuantitiesOfProductNotOnDocument);
    const language = useSelector(state => state.languageReducer.language);
    const clientCode = useSelector(state => state.verifyUserReducer.clientCode);
    const customer = useSelector(state => state.getCustomersReducer.customers)[0];
    const binsContainingDocumentProducts = useSelector(state => state.scanReducer.binsContainingDocumentProducts);  // Needed to sort bins by their "order" field
    // Alas-Kuul-specific field; null - has not been set, true - products displayed are only TC2000 products, false - products displayed are only non-TC2000 products
    const isTc2000Only = useSelector(state => state.scanReducer.isTc2000Only);
    const fulfillableOrders = useSelector(state => state.getSalesDocumentsReducer.fulfillableOrders);

    const isAK = isAlasKuul(clientCode);
    const [showIsTc2000OnlyModal, setShowIsTc2000OnlyModal] = useState(false);
    const [isTc2000OnlyModalOptions, setIsTc2000OnlyModalOptions] = useState({KAPP : false, RIIUL: false}); // Options available to the user in the "KAPP või RIIUL" modal
    const isPP = isPrimePartner(clientCode);
    const isOV = isOverall(clientCode);

    const isScanBySupplier = scanBySupplier !== null;   // Rehvid PLuss only
    const isOutScan = componentSequence.includes("ProductsOut");
    const isCreditInvoice = documentIsCreditInvoice(componentSequence);
    const isPurchaseReturn = documentIsPurchaseReturn(componentSequence);
    const isInventoryTransfer = componentSequence.includes("InventoryTransfer");
    const isAssignment = componentSequence.includes("Assignment");
    const isSalesOrder = documentIsSalesOrder(componentSequence, isAssembly);
    const isPurchaseOrder = documentIsPurchaseOrder(componentSequence, isScanBySupplier);
    const negativeScanAllowed = (isSalesOrder || (isInventoryTransfer && isOutScan)) && confParameters.wmsEnableNegativeScanProductsOut == 1;
    const enableBinOrder = confParameters.wmsEnableBinOrder == 1;
    const isOpenInAndroidApp = window.ReactNativeWebView;
    const binsEnabled = confParameters.wmsUseProductLocationsInWarehouse == 1;
    const batchesEnabled = !isOutScan && !isInventoryTransfer && confParameters.wmsEnableScanningBatches == 1;  // Creates a batch scanning field, only used by Okaidi
    const canLabelPrint = dispatch(getConfParameterValue("wmsLabelPrinterName")) !== "" && confParameters.wmsLabelTemplateID !== "" && dispatch(getConfParameterValue("wmsPrinterServiceAddress")) !== "" && isOpenInAndroidApp && !isScanBySupplier;
    // const canLabelPrint = true;
    const creatingProductsEnabled = !isOutScan && confParameters.wmsEnableCreatingProducts == 1;
    const canChangeDocInfo = !isAssembly && !isScanBySupplier;
    const isFromTlnKeskladu = !isScanBySupplier && selectedDocuments[0].warehouseFromID == 2;    // AK only
    const isSaadaKappiDocument = isAK && (isSalesOrder || isCreditInvoice || (isOutScan && isInventoryTransfer && isFromTlnKeskladu));  // AK only
    const scanAsBatchAllowed = confParameters.wmsScanAsBatch == 1 && isPurchaseOrder;   // Display scanned products as a batch and save them as bin records all at once, only used by M&M
    const isRapidInScan = isTAF(clientCode) ? confParameters.wmsRapidInScan == 1 : getCafaConfParameterValue(cafaConfParameters, "wmsRapidInScan", user) == 1;
    const isRapidOutScan = isTAF(clientCode) ? confParameters.wmsRapidOutScan == 1 : getCafaConfParameterValue(cafaConfParameters, "wmsRapidOutScan", user) == 1;
    const multiplePurchaseOrderScanEnabled = confParameters.wmsEnableMultiplePurchaseOrderScan == 1;
    const searchCodeFromCommaSeparatedCodes = confParameters.api_get_product_use_new_fulltext_index == 1;
    const differentiateByPackageID = differentiateProductsByPackageID(componentSequence, isScanBySupplier, confParameters);
    const canSelectRow = !confParameters.wmsCompulsoryScanUserGroups.split(",").includes(user.groupID);
    const isFromMultipleDocuments = selectedDocuments.length > 1; // Only possible with purchase documents
    const canSetInScanBin = confParameters.wmsEnableSettingInScanBin == 1;

    const [scannedCode, setScannedCode] = useState("");
    const [packagingOptions, setPackagingOptions] = useState(defaultPackagingOptions);

    useEffect(() => {
        syncQuantitiesOnDocument(selectedDocumentsProducts, false, true);

        if (!isScanBySupplier && (isAssembly || !isOutScan || !binsEnabled)) {   // If is Products In, Assembly or bins are not enabled then all records are saved into receiving_area bin
            setBinID(receivingArea.binID);
        } else if (supplierBin) {
            setBinID(supplierBin.binID);
        }

        if (isSalesOrder || isCreditInvoice) {
            getDeliveryTypes();
        }

        if (isPP && isOutScan) {
            getCustomers();  // Needed to display customer's bank name
        }
    }, []);

    useEffect(() => {
        if (modalMessage === "" && previousModalMessage !== t("confirmCancelAssembly?") && !isLoading) {
            scanInput.current.focus();
        } else {
            document.getElementById("scanInput").blur();
        }
    }, [modalMessage, isLoading]);

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown);
        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    });

    useEffect(() => {
        if (!scannedProductIsPackage) {
            setPackaging(packagingOptions[0].value);
        }
    }, [packagingOptions]);

    const initializeSelectedBins = (selectedDocumentsProducts, binQuantities, binsContainingDocumentProducts) => {
        let selectedBins = [];
        for (let i = 0, n = selectedDocumentsProducts.length; i < n; i++) {
            let binsWithProduct = [];

            for (let j = 0, m = binQuantities.length; j < m; j++) {
                if (binQuantities[j].productID == selectedDocumentsProducts[i].productID && binQuantities[j].amount > 0) {
                    let binWithProduct = {
                        binCode: binQuantities[j].binCode,
                        binID: binQuantities[j].binID,
                        order: 9999,
                        preferred: binQuantities[j].binPreferred
                    };

                    if (binsContainingDocumentProducts) {   // Sorting bins by order has been enabled
                        const binContainingDocumentProducts = binsContainingDocumentProducts.find(bin => bin.binID == binQuantities[j].binID);
                        if (binContainingDocumentProducts) {
                            binWithProduct.order = binContainingDocumentProducts.order <= 0 || binContainingDocumentProducts.order === "" ? 9999 : binContainingDocumentProducts.order;
                            binWithProduct.equipmentNeeded = binContainingDocumentProducts.equipmentNeeded;
                        }
                    }

                    binsWithProduct.push(binWithProduct);
                }
            }

            // Sort 1) by order 2) by preferred/unpreferred 3) alphabetically
            binsWithProduct.sort((a, b) => {
                const orderA = a.order;
                const orderB = b.order;
                const textA = a.binCode.toUpperCase();
                const textB = b.binCode.toUpperCase();
                return (orderA < orderB) ? -1 : (orderA > orderB) ? 1 :
                    (a.preferred > b.preferred) ? -1 : (a.preferred < b.preferred) ? 1 :
                    (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

            // Move receiving area to the end
            const receivingAreaIndex = binsWithProduct.findIndex(option => option.binCode === translateBinFromEng("receiving_area", language));
            if (receivingAreaIndex !== -1) {
                binsWithProduct.push(binsWithProduct.splice(receivingAreaIndex, 1)[0]);
            }

            // Set first bin as selected
            if (binsWithProduct.length > 0) {
                selectedBins.push({
                    ...binsWithProduct[0],
                    productID: selectedDocumentsProducts[i].productID
                });
            }
        }

        // Display equipment needed to work with selected bins
        if (documentHasJustBeenOpened()) {
            let equipmentNeeded = [];

            selectedBins.forEach(bin => {
                if (bin.equipmentNeeded && bin.equipmentNeeded !== "") {
                    equipmentNeeded = equipmentNeeded.concat(bin.equipmentNeeded.split(","));
                }
            });

            if (equipmentNeeded.length > 0) dispatch(setInformativeModal([...new Set(equipmentNeeded)].join(", "), `${t("equipmentNeeded")}:`));
        }

        setRowSelectedBins(selectedBins);
    };

    const initializeNotes = () => {
        return canChangeDocInfo ? selectedDocuments[0].notes : "";
    };

    const initializeInternalNotes = () => {
        return isCreditInvoice || isSalesOrder ? selectedDocuments[0].internalNotes : "";
    };

    const initializePackageDescription = () => {
        return isCreditInvoice || isSalesOrder ? selectedDocuments[0].packingUnitsDescription : "";
    };

    const initializeDeliveryTypeID = () => {
        return isCreditInvoice || isSalesOrder ? selectedDocuments[0].deliveryTypeID : 0;
    };

    const getProductPackage = (product) => {
        return product.productPackages.find(productPackage =>
            productPackage.packageCode && (productPackage.packageCode.toLowerCase() == scannedCode.toLowerCase() ||
            (searchCodeFromCommaSeparatedCodes && commaSeparatedCodesIncludeCode(productPackage.packageCode.toLowerCase(), scannedCode))));
    };

    const [scanAsBatch, setScanAsBatch] = useState(scanAsBatchAllowed);
    const [productsScannedAsBatch, setProductsScannedAsBatch] = useState([]);   // Products scanned if wmsScanAsBatch == 1

    const defaultAmount = (isOutScan && isRapidOutScan) || (!isOutScan && isRapidInScan) || scanAsBatch ? 1 : "";

    const [checkIfScannedCodeIsValid, setCheckIfScannedCodeIsValid] = useState(false);
    const [getProductsRequestsFinished, setGetProductsRequestsFinished] = useState(false);  // All getProducts requests are finished
    const [scanButtonClicked, setScanButtonClicked] = useState(false);  // "Ok" button has been clicked
    const [packaging, setPackaging] = useState(defaultPackagingOptions[0].value);
    const [amount, setAmount] = useState(defaultAmount);
    const [selectedRow, setSelectedRow] = useState(null);   // Manually selected row
    const [scannedProductIsPackage, setScannedProductIsPackage] = useState(false);
    const [changeEanValue, setChangeEanValue] = useState("");
    const [changeEanProductID, setChangeEanProductID] = useState(null);
    const [displayChangeEanFirstConfirmation, setDisplayChangeEanFirstConfirmation] = useState(false);
    const [displayChangeEanSecondConfirmation, setDisplayChangeEanSecondConfirmation] = useState(false);
    const [suggestedBinsData, setSuggestedBinsData] = useState(null);
    const [isLisaKappiProduct, setIsLisaKappiProduct] = useState(false);    // Alas-Kuul only
    const [lastScannedProduct, setLastScannedProduct] = useState(null);    // Last scanned product displayed in UI
    const [lastScannedAmount, setLastScannedAmount] = useState(null);    // Last scanned product amount displayed in UI
    const [inScanBinCode, setInScanBinCode] = useState(translateBinFromEng(receivingArea.code, language));    // Manually entered bin code by user
    // OutScan-only setState hooks
    const [rowSelectedBins, setRowSelectedBins] = useState([]);
    const [subtractedRowsSorted, setSubtractedRowsSorted] = useState(false);
    const [binID, setBinID] = useState("");
    const [showExtraFields, setShowExtraFields] = useState(false);
    const [notes, setNotes] = useState(initializeNotes());
    const [internalNotes, setInternalNotes] = useState(initializeInternalNotes());
    const [packageDesc, setPackageDesc] = useState(initializePackageDescription());
    const [deliveryTypeID, setDeliveryTypeID] = useState(initializeDeliveryTypeID());
    const [productNeedsSerialNo, setProductNeedsSerialNo] = useState(false);
    const [serialNo, setSerialNo] = useState("");
    const [batchCode, setBatchCode] = useState("");
    const [addEanBtnClickedProductID, setAddEanBtnClickedProductID] = useState(null);   // Id of the product without EAN whose "Add EAN" button has been clicked
    const [missingEan, setMissingEan] = useState("");   // Value typed into the "Add EAN" field
    const [showAssemblyRecipe, setShowAssemblyRecipe] = useState(false);   // Display assembled products' recipe during assembly
    const [scanLaterProducts, setScanLaterProducts] = useState([]); // Products moved to the end of the scan order to be scanned later, AK only
    const [selectedBundle, setSelectedBundle] = useState(null); // Fulfillable orders only
    // Bundle amounts have been increased (in a previous scan session bundles' amounts were increased by the user and thus a larger amount of components was scanned)
    const [bundleAmountsAdjusted, setBundleAmountsAdjusted] = useState(false);

    useEffect(() => {
        setGetProductsRequestsFinished(false);
        scannedProduct = null;
        lastScannedCode.current = scannedCode;
        handleScanInput();
    }, [scannedCode, selectedRow]);

    useEffect(() => {
        if (getProductsRequestsFinished && scanButtonClicked) {
            scan();
        }
    }, [getProductsRequestsFinished, scanButtonClicked]);

    useEffect(() => {   // Scanned code validity checking after "enter" key press (actual scanning scenario)
        if (getProductsRequestsFinished && checkIfScannedCodeIsValid) {
            if (scannedProduct === null) {
                displayErrorWithFailSound(t("noProductFound"), true);
            } else if (isOutScan && !documentHasProduct(selectedDocumentsProducts) && confParameters.wmsEnableScanningProductsNotOnOutDocument == 0) {
                displayErrorWithFailSound(t("noProductOnDoc"), true);
            } else {
                binInput.current.toggle();  // Focus bin dropdown
            }
            setCheckIfScannedCodeIsValid(false);
        }
    }, [getProductsRequestsFinished, checkIfScannedCodeIsValid]);

    useEffect(() => {
        if (addEanBtnClickedProductID !== null) {
            addEanInput.current.focus();
        }
    }, [addEanBtnClickedProductID]);
    useEffect(() => {
        if (changeEanProductID !== null) {
            changeEanInput.current.focus();
        }
    }, [changeEanProductID]);

    useEffect(() => {
        if (scannedProductIsPackage) {
            const packaging = getProductPackage(scannedProduct);
            setPackaging(getPackageOptionValue(packaging));
        }
    }, [scannedProductIsPackage]);

    const subtractScannedProducts = () => {
        let subtractedRows = [];
        const bundlesWithMissingAmounts = [];

        const getSubtractedAmount = (summedRow) => {
            let newAmount = summedRow.amount;

            scannedProducts.forEach(scannedProduct => {
                if (productsAreIdentical(componentSequence, selectedDocuments, summedRow, scannedProduct)) {
                    newAmount -= scannedProduct.amount;
                }
            });

            return newAmount;
        };

        // Calculate bundle amounts that need to be adjusted
        if (!bundleAmountsAdjusted && isSalesOrder) {
            for (let i = 0, n = summedRows.length; i < n; i++) {
                let newAmount = getSubtractedAmount(summedRows[i]);

                if (!bundleAmountsAdjusted && isSalesOrder && isBundleComponent(summedRows[i]) && newAmount < 0) {
                    // In a previous scan session bundle's amount was increased by the user and thus a larger amount of components was scanned
                    const existingBundleWithMissingAmount = bundlesWithMissingAmounts.find(amount => amount.productID == summedRows[i].bundleID && amount.itemName === summedRows[i].bundleName);
                    const missingBundleAmount = Math.ceil(-1 * newAmount / summedRows[i].amountInBundle);

                    if (existingBundleWithMissingAmount) {
                        if (existingBundleWithMissingAmount.amount < missingBundleAmount) {
                            existingBundleWithMissingAmount.amount = missingBundleAmount;
                        }
                    } else {
                        bundlesWithMissingAmounts.push({productID: summedRows[i].bundleID, itemName: summedRows[i].bundleName, amount: missingBundleAmount});
                    }
                }
            }
        }

        for (let i = 0, n = summedRows.length; i < n; i++) {
            let newAmount = getSubtractedAmount(summedRows[i]);

            // Increment bundle component amounts if in a previous scan session bundle's amount was increased by the user
            if (!bundleAmountsAdjusted && isSalesOrder && isBundleComponent(summedRows[i])) {
                const bundleWithMissingAmount = bundlesWithMissingAmounts.find(bundle => bundle.productID == summedRows[i].bundleID && bundle.itemName === summedRows[i].bundleName);

                if (bundleWithMissingAmount) {
                    newAmount += Number(summedRows[i].amountInBundle * bundleWithMissingAmount.amount);
                }
            }

            if (roundFloatingPointError(newAmount) > 0) {
                subtractedRows.push({...summedRows[i], amount: roundFloatingPointError(newAmount)});
            }
        }

        // Increment bundle amounts if in a previous scan session bundle's amount was increased by the user
        if (bundlesWithMissingAmounts.length > 0) {
            const userDisplayedAdjustments = [];

            bundlesWithMissingAmounts.forEach(bundleWithMissingAmount => {
                const summedRow = summedRows.find(row => row.productID == bundleWithMissingAmount.productID && row.itemName === bundleWithMissingAmount.itemName);
                const newAmount = summedRow.amount + bundleWithMissingAmount.amount;
                userDisplayedAdjustments.push(`${summedRow.name}: ${summedRow.amount}->${newAmount}`);
                subtractedRows.find(row => row.productID == bundleWithMissingAmount.productID && row.itemName === bundleWithMissingAmount.itemName).amount = newAmount;
                dispatch(setNewAmountToProductInRowsWithSummedProducts(bundleWithMissingAmount.productID, newAmount, true, bundleWithMissingAmount.itemName));
            });

            const bundleAdjustmentsMessage = `${t("bundleAmountsHaveBeenAdjusted")}. ${userDisplayedAdjustments.join(", ")}`;
            dispatch(successMessageSet(bundleAdjustmentsMessage));
        }

        // Add products not on document
        for (let i = 0, n = scannedProducts.length; i < n; i++) {
            const productIsOnDocument = selectedDocumentsProducts.some(product => product.productID == scannedProducts[i].productID);

            if (!productIsOnDocument) {
                const productInSubtractedRows = subtractedRows.find(product => product.productID == scannedProducts[i].productID);

                if (!productInSubtractedRows) {
                    const productClone = Object.assign({}, scannedProducts[i]);
                    productClone.amount = productClone.amount * (-1);
                    productClone.productIsNotOnDocument = 1;
                    subtractedRows.push(productClone);
                } else {
                    productInSubtractedRows.amount -= Number(scannedProducts[i].amount);
                }
            }
        }

        // Set selectedBundleID to null if a bundle was fully scanned
        if (selectedBundle !== null && bundleIsFullyScanned(subtractedRows, selectedBundle)) {
            setSelectedBundle(null);
        }

        setBundleAmountsAdjusted(true);
        return subtractedRows;
    };

    // Displayed rows with subtracted scanned products
    const [subtractedRows, setSubtractedRows] = useState([]);
    useEffect(() => {
        if (scannedProducts.length === 0) {
            setSubtractedRows(summedRows);
        } else {
            setSubtractedRows(subtractScannedProducts())
        }
    }, [scannedProducts, summedRows]);

    useEffect(() => {
        if (isSaadaKappiDocument && isTc2000Only === null) { // isTc2000Only has not been set
            const subtractedRowsHasBeenCalculated = (documentHasBeenPreviouslyScanned && scannedProducts.length > 0) || !documentHasBeenPreviouslyScanned;

            if (subtractedRowsHasBeenCalculated) {
                const unscannedTc2000ProductsExist = subtractedRows.some(row => rowHasTc2000Product(selectedDocumentsProducts, row));
                const unscannedNonTc2000ProductsExist = subtractedRows.some(row => !rowHasTc2000Product(selectedDocumentsProducts, row));

                if (unscannedTc2000ProductsExist || unscannedNonTc2000ProductsExist) {
                    // Previous confirms were carelessly forgotten by the user or there are no products of type RIIUL or type KAPP on the document
                    if (!unscannedTc2000ProductsExist && !getApiAttributeValue("kappAssembled")) {
                        saveDocumentAttribute("kappAssembled", "1");
                    }
                    if (!unscannedNonTc2000ProductsExist && !getApiAttributeValue("riiulAssembled")) {
                        saveDocumentAttribute("riiulAssembled", "1");
                    }

                    setIsTc2000OnlyModalOptions({KAPP: unscannedTc2000ProductsExist, RIIUL: unscannedNonTc2000ProductsExist});
                    setShowIsTc2000OnlyModal(true);
                }
            }
        }
    }, [subtractedRows]);

    const [productIDsToLabelPrint, setProductIDsToLabelPrint] = useState([]);   // Products checked for label printing

    useEffect(() => {
        if (canLabelPrint) {
            let newProductIDsToLabelPrint;
            if (subtractedRows.length > 0) {
                newProductIDsToLabelPrint = [...new Set(subtractedRows.map(row => row.productID))];
            } else {
                newProductIDsToLabelPrint = productIDsToLabelPrint.slice();
                for (let i = 0, n = newProductIDsToLabelPrint.length; i < n; i++) {
                    let rowIDExists = false;

                    for (let j = 0, n = subtractedRows.length; j < n; j++) {
                        if (newProductIDsToLabelPrint[i] === subtractedRows[j].productID) {
                            rowIDExists = true;
                        }
                    }

                    if (!rowIDExists) {
                        newProductIDsToLabelPrint.splice(i, 1);
                    }
                }
            }

            if (!arraysAreEqual(productIDsToLabelPrint, newProductIDsToLabelPrint)) setProductIDsToLabelPrint(newProductIDsToLabelPrint);
        }
    }, [subtractedRows]);

    useEffect(() => {
        if (rowSelectedBins.length > 0 || !binsEnabled) {
            sortSubtractedRows();
        }
    }, [rowSelectedBins, subtractedRows, scanLaterProducts]);

    // Sort displayable subtracted rows 1) by order 2) alphabetically by their selected bin code; Move "Scan later" rows to the end
    const sortSubtractedRows = () => {
        const getBinCode = (rowBin) => {
            if (rowBin === undefined) {
                // Product is not present in any address. It must be moved to the end of the displayed rows
                return "ZZZZZZZZZZ";
            } else {
                return rowBin.binCode.toUpperCase();
            }
        };
        const getBinOrder = (rowBin) => {
            return rowBin ? rowBin.order : Number.MAX_SAFE_INTEGER;
        };

        let sortedSubtractedRows = subtractedRows;
        if (binsEnabled) {
            sortedSubtractedRows = sortedSubtractedRows.sort((subtractedRowA, subtractedRowB) => {
                const rowABin = rowSelectedBins.find(selectedBin => selectedBin.productID == subtractedRowA.productID);
                const rowBBin = rowSelectedBins.find(selectedBin => selectedBin.productID == subtractedRowB.productID);
                const rowABinOrder = getBinOrder(rowABin);
                const rowBBinOrder = getBinOrder(rowBBin);
                const rowABinCode = getBinCode(rowABin);
                const rowBBinCode = getBinCode(rowBBin);

                return (rowABinOrder < rowBBinOrder) ? -1 : (rowABinOrder > rowBBinOrder) ? 1 : (rowABinCode < rowBBinCode) ? -1 : (rowABinCode > rowBBinCode) ? 1 : 0;
            });
        }

        // Move "Scan later" rows to the end
        sortedSubtractedRows = sortedSubtractedRows.sort((subtractedRowA, subtractedRowB) => {
            const rowAIsScannedLater = scanLaterProducts.some(product => product.productID == subtractedRowA.productID && product.name === getName(subtractedRowA));
            const rowBIsScannedLater = scanLaterProducts.some(product => product.productID == subtractedRowB.productID && product.name === getName(subtractedRowB));
            return rowAIsScannedLater === rowBIsScannedLater ? 0 : rowAIsScannedLater ? 1 : -1;
        });

        setSubtractedRows(sortedSubtractedRows);
        setSubtractedRowsSorted(true);  // This is so that scannableDocRows would update
    };

    const syncQuantitiesOnDocument = (products, playSuccessSound = false, firstSync = false, localBinRecords = null) => {
        let requests = [];
        if (!localBinRecords) {
            requests.push(dispatch(getBinRecords(t, documentIDs, getDocumentType(componentSequence, isAssembly), isScanBySupplier ? supplierBin.binID : null)));
        }

        if (isOutScan && binsEnabled) {
            const productIDs = selectedDocumentsProducts.map(product => product.productID).join(",");
            requests.push(dispatch(getBinQuantities(t, true, getBinQuantitiesSuccess, null, productIDs)));
        }

        dispatch(setIsLoading(true));
        Promise.all(requests).then(async (data) => {
            const freshBinRecords = !localBinRecords ? data[0] : localBinRecords;

            if (isOutScan && binsEnabled) {
                const binQuantities = data[1];
                const bins = firstSync ? await getBinsContainingDocumentProducts(binQuantities) : binsContainingDocumentProducts;

                initializeSelectedBins(products, binQuantities, bins);
            }

            updateScannedProducts(freshBinRecords, products);
            clearInputs();
            dispatch(setIsLoading(false));

            if (playSuccessSound) {
                playSound("success");
            }
        })
    };

    const clearInputs = () => {
        setScannedCode("");
        scanInput.current.focus();
        setAmount(defaultAmount);
    };

    const getDeliveryTypes = () => {
        if (deliveryTypes.length === 0) {
            const params = {request: "getDeliveryTypes"};
            dispatch(makeErplyRequest(params, t("getDeliveryTypesError"), null, getDeliveryTypesSuccess, null, true));
        }
    };

    const getBinsContainingDocumentProducts = (binQuantities) => {
        const binIDs = [...new Set(binQuantities.filter(binQuantity => binQuantity.amount > 0).map(binQuantity => binQuantity.binID))].join(",");
        return dispatch(getBins(t, true, setBinsContainingDocumentProducts, null, "", "", binIDs));
    };

    const updateScannedProducts = async (binRecords, products) => {
        const [productsNotOnDocument, followUpDocuments] = await Promise.all([getProductsNotOnDocument(binRecords, products), getFollowUpDocuments()]);
        const [scannedProducts, scannedProductsByDocument, currentSessionScannedProducts] = getScannedProducts(binRecords, products, componentSequence, isScanBySupplier, multiplePurchaseOrderScanEnabled, productsNotOnDocument, differentiateByPackageID, followUpDocuments, confParameters);
        dispatch(setScannedProducts(scannedProducts));
        dispatch(setScannedProductsByDocument(scannedProductsByDocument));
        dispatch(setCurrentSessionScannedProducts(currentSessionScannedProducts));
        const summedScannedProducts = getSummedScannedProducts(currentSessionScannedProducts, differentiateByPackageID);
        dispatch(setSummedScannedProducts(summedScannedProducts));
    };

    const getProductsNotOnDocument = (binRecords, products) => {
        let newProductsNotOnDocumentIDs = [];

        for (let i = 0, n = binRecords.length; i < n; i++) {
            const productIsOnDocument = products.some(product => product.productID == binRecords[i].productID);

            if (!productIsOnDocument) {
                const newProductNotOnDocument = productsNotOnDocument.find(product => product.productID == binRecords[i].productID);
                if (!newProductNotOnDocument) {
                    newProductsNotOnDocumentIDs.push(binRecords[i].productID);
                }
            }
        }

        newProductsNotOnDocumentIDs = [...new Set(newProductsNotOnDocumentIDs)];
        if (newProductsNotOnDocumentIDs.length > 0) {
            let requests = newProductsNotOnDocumentIDs.map(productID => {return {
                requestName: "getProducts",
                productID: productID,
                getPackageInfo: 1,
                requestID: productID,
                getContainerInfo: 1
            }});

            return dispatch(makeErplyBulkRequest(requests, t("getProductsError"))).then((productResponses) => {
                const products = productsNotOnDocument.concat(productResponses.map(response => response.records[0]));
                dispatch(setProductsNotOnDocument(products)); // Save to memory so recurrent requesting is not necessary
                return products;
            });
        } else {
            return Promise.resolve(productsNotOnDocument);
        }
    };

    //Field name order by which products are requested by using the field name in parameters
    const getProductsCodeOrder = useMemo(() => getGetProductsCodeOrder(confParameters), [confParameters]);

    const searchQueryBinFound = () => {
        const searchQuery = binInput.current.state.searchQuery.toLowerCase();
        if (searchQuery === "") {   // An option is being selected
            return true;
        }
        const mainBinOptions = getMainBinOptions();

        for (let i = 0, n = mainBinOptions.length; i < n; i++) {
            if (mainBinOptions[i].text.toLowerCase() === searchQuery) {
                return true;
            }
        }
        return false;
    };

    const onKeyDown = (e) => {
        if (!isLoading && modalMessage === "" && modalHeading === "" && e.key === "Enter") {
            setCheckIfScannedCodeIsValid(false);

            if ((isOutScan && isRapidOutScan) || (!isOutScan && isRapidInScan) || scanAsBatch) {
                if (isOutScan && binsEnabled) {
                    const serialNoInputIsSelected = serialNoInput.current && serialNoInput.current.inputRef.current === document.activeElement;

                    if (binInput.current.state.focus === true) {
                        if (!searchQueryBinFound()) {
                            dispatch(errorMessageSet(t("searchQueryBinWasNotFound")))
                        } else if (binID !== "") {
                            if (productNeedsSerialNo && serialNo === "") {
                                serialNoInput.current.select();
                            } else {
                                handleOkOnClick();
                            }
                        } else {
                            displayErrorWithFailSound(t("noBinFound"));
                        }
                    } else if (serialNoInputIsSelected) {
                        handleOkOnClick();
                    } else {
                        if (scannedCodeEmpty()) {
                            displayErrorWithFailSound(t("noItemsScanned"));
                        } else {
                            setCheckIfScannedCodeIsValid(true);
                        }
                    }
                } else {
                    handleOkOnClick();
                }
            } else {
                amountInput.current.select();
            }
        }
    };

    const handleGoBackOnClick = () => {
        updateScannedBatches(true);
        dispatch(componentSet(previousComponent));
    };

    const handleOkOnClick = () => {
        if (!scannedCodeEmpty()) {
            setScanButtonClicked(true);
        }
    };

    const scannedCodeEmpty = () => {
        return scannedCode.trim() === "" || scannedCode === "0";
    };

    const scan = (warnOfLargeAmounts = true, customName = null) => {
        if (scannedCodeEmpty()) {
            displayErrorWithFailSound(t("noItemsScanned"));
        } else if (scannedProduct === null) {
            displayErrorWithFailSound(t("noProductFound"), true);
        } else if (!documentHasProduct(selectedDocumentsProducts) && ((isOutScan && confParameters.wmsEnableScanningProductsNotOnOutDocument == 0) || (!isOutScan && confParameters.wmsEnableScanningProductsNotOnInDocument == 0))) {
            displayErrorWithFailSound(t("noProductOnDoc"), true);
        } else if (binsEnabled && binID === "") {
            isOutScan ? displayErrorWithFailSound(t("enterBin")) : displayErrorWithFailSound(t("noBinFound"));
        } else if (productNeedsSerialNo && serialNo === "" && isSalesOrder) {
            displayErrorWithFailSound(t("enterSerialNo"));
        } else if (isOV && productNeedsSerialNo && serialNo !== "" && amount != 1) {
            displayErrorWithFailSound(t("serialNoAmountMustBe1"));
        } else if (batchesEnabled && batchCode === "") {
            displayErrorWithFailSound(t("enterBatch"));
        } else if (scannedProduct.type === "BUNDLE") {
            displayErrorWithFailSound(t("bundlesMayNotBeScanned"));
        } else if (isSalesOrder && selectedBundle !== null && scannedProduct.bundleID != selectedBundle.productID) {
            displayErrorWithFailSound(t("onlyComponentsMayBeScannedIfBundleIsSelected"));
        } else if (isSalesOrder && selectedBundle === null && productPresentOnlyInBundle(scannedProduct, summedRows)) {
            displayErrorWithFailSound(t("bundleComponentsMayBeScannedOnlyIfBundleIsSelected"));
        } else {
            if (amount == 0 || (amount < 0 && !negativeScanAllowed)) {
                displayErrorWithFailSound(t("amountMustBeOver0"));
            } else {
                let calculatedAmount = roundFloatingPointError(calculateAmount());
                let productNamesOnDocument = isScanBySupplier ? [] : getScannedProductNamesOnDocuments(scannedProduct, selectedDocuments);

                if (!selectedBundle && productNamesOnDocument.length === 1 && productNamesOnDocument[0] !== scannedProduct.name) {
                    scannedProduct.name = productNamesOnDocument[0];    // Set custom name on document to scanned product
                } else if (productNamesOnDocument.length > 1) {
                    // Do not offer product names of fully scanned products as options
                    const unscannedProductNames = subtractedRows.filter(row => row.productID == scannedProduct.productID).map(product => product.name);
                    if (unscannedProductNames.length === 1) {
                        scannedProduct.name = unscannedProductNames[0];    // Set custom name on document to scanned product
                    }
                    productNamesOnDocument = unscannedProductNames;
                }

                const checkAmount = (ignoreModals = false, offerAvailableAmountIfAmountInBinExceeded = false) => {
                    if (isBundleComponent(scannedProduct) && scannedAmountExceedsAvailableAmountOnDoc(calculatedAmount)) {
                        displayErrorWithFailSound(t("scannedAmountExceedsAmountInBundle"));
                    } else if (!isScanBySupplier && ((isOutScan && !isAssembly && confParameters.wmsAllowOutScanAmountExceeding == 0 && scannedAmountExceedsAvailableAmountOnDoc(calculatedAmount)) ||
                        (!isOutScan && confParameters.wmsAllowInScanAmountExceeding == 0 && scannedAmountExceedsAvailableAmountOnDoc(calculatedAmount)))) {
                        displayErrorWithFailSound(t("scannedAmountExceedsAvailableAmount"));
                    } else if (isOutScan && binsEnabled && scannedAmountExceedsAvailableAmountInBin(calculatedAmount)) {
                        if (offerAvailableAmountIfAmountInBinExceeded) {
                            const amountInBin = roundFloatingPointError(getSelectedBinAmount(scannedProduct.productID));
                            if (amountInBin > 0) {
                                const modalMessage = `${t("scannedAmountExceedsAvailableAmountInBin")}. ${t("correctedMaxAmountAvailableToScan")}: ${amountInBin}. ${t("areYouSureConfirmScan")}`;
                                dispatch(setModal(t("warning"), modalMessage, () => confirmScan(amountInBin, scannedAmountExceedsAvailableAmountOnDoc(amountInBin))));
                            } else {
                                displayErrorWithFailSound(t("scannedAmountExceedsAvailableAmountInBin"));
                            }
                        } else {
                            displayErrorWithFailSound(t("scannedAmountExceedsAvailableAmountInBin"));
                        }
                    } else if (!ignoreModals &&!isScanBySupplier && !documentHasProduct(selectedDocumentsProducts) &&
                        ((!isOutScan && confParameters.wmsEnableScanningProductsNotOnInDocument == 1) || (isOutScan && confParameters.wmsEnableScanningProductsNotOnOutDocument == 1))) {
                        const onYesFunction = isOutScan ? () => confirmScan(calculatedAmount, false) : () => displaySetPriceModal(calculatedAmount);
                        dispatch(setModal(t("warning"), `${t("noProductOnDoc")} ${t("areYouSureConfirmScan")}`, onYesFunction, false, "", [], clearInputs));
                    } else if (!ignoreModals && ((isOutScan && !isAssembly && confParameters.wmsAllowOutScanAmountExceeding == 1 && scannedAmountExceedsAvailableAmountOnDoc(calculatedAmount)) ||
                        (!isOutScan && confParameters.wmsAllowInScanAmountExceeding == 1 && scannedAmountExceedsAvailableAmountOnDoc(calculatedAmount)))) {
                        dispatch(setModal(t("warning"), `${t("scannedAmountExceedsAvailableAmount")}. ${t("areYouSureConfirmScan")}`, () => confirmScan(calculatedAmount, true)));
                    } else {
                        confirmScan(calculatedAmount, scannedAmountExceedsAvailableAmountOnDoc(calculatedAmount));
                    }
                };

                if (warnOfLargeAmounts && String(calculatedAmount).length > 7) {  // User might have scanned a product code by accident
                    dispatch(setModal(t("warning"), t("largeAmountWarning"), () => scan(false)));
                } else if (isSalesOrder && customName === null && productNamesOnDocument.length > 1 && selectedRow === null) {
                    const options = productNamesOnDocument.map(name => ({key: name, text: name, value: name}));
                    dispatch(setModal(t("productHasSeveralNamesOnDocumentChooseName"), "", (customName) => {
                        scannedProduct.name = customName;
                        scan(warnOfLargeAmounts, customName);
                    }, false, options[0].value, options));
                } else if (isInventoryTransfer && confParameters.wmsInvTransferScanReorderMultiple == 1 && scannedProduct.reorderMultiple > 0
                    && calculatedAmount % scannedProduct.reorderMultiple !== 0) {
                    calculatedAmount += (scannedProduct.reorderMultiple - (calculatedAmount % scannedProduct.reorderMultiple));
                    let message = `${t("productReordableOnlyInMultiplesOf")} ${scannedProduct.reorderMultiple}`;
                    if (language === "est") {
                        message += ` ${t("by")}`;
                    }
                    message += `. ${t("adjustedScannedAmount")}: ${calculatedAmount}. ${t("areYouSureConfirmScan")}`;

                    dispatch(setModal(t("warning"), message, checkAmount));
                } else {
                    checkAmount();
                }
            }
        }

        setScanButtonClicked(false);
    };

    const displaySetPriceModal = async (amount) => {
        const productWithFIFO = await getProductWithFIFO();

        dispatch(setModal(t("enterPrice"), "", (price) => {
            price = String(price).replace(",", ".");
            if (isNaN(price) || price < 0) {
                displayErrorWithFailSound(t("valueMustNotBeNegative"), true);
            } else {
                dispatch(setUserEnteredPrice(scannedProduct.productID, price));
                confirmScan(amount);
            }
        }, true, productWithFIFO.purchasePrice));
    };

    const displayErrorWithFailSound = (errorMessage, selectScanInput = false) => {
        dispatch(errorMessageSet(errorMessage));
        playSound("fail");
        if (selectScanInput) {
            scanInput.current.select();
        }
    };

    const scannedAmountExceedsAvailableAmountOnDoc = (amount) => {
        let productIsInSubtractedRows = false;

        for (let i = 0, n = subtractedRows.length; i < n; i++) {
            if (productsAreIdentical(componentSequence, selectedDocuments, subtractedRows[i], scannedProduct)) {
                productIsInSubtractedRows = true;

                if (!subtractedRows[i].productIsNotOnDocument && amount > subtractedRows[i].amount) {   // Ignore products not on document
                    return true;
                }
            }
        }

        if (!productIsInSubtractedRows) {
            for (let i = 0, n = selectedDocumentsProducts.length; i < n; i++) {
                if (productsAreIdentical(componentSequence, selectedDocuments, scannedProduct, selectedDocumentsProducts[i], false)) {
                    return true;    // Product has been fully scanned so is not present in subtractedRows
                }
            }
        }

        return false;
    };

    const scannedAmountExceedsAvailableAmountInBin = (amount) => {
        const amountInBin = getSelectedBinAmount(scannedProduct.productID);
        return amount > amountInBin;
    };

    const saveDocumentAttribute = async (attributeName, attributeValue) => {
        const params = {
            attributeName0: attributeName,
            attributeValue0: attributeValue
        };

        let response;
        if (isSalesOrder || isCreditInvoice) {
            params.id = selectedDocuments[0].id;
            params.request = "saveSalesDocument";
            response = await dispatch(makeErplyRequest(params, t("saveSalesDocumentError"), null, null, null, false, false));
        } else if (isInventoryTransfer) {
            params.inventoryTransferID = selectedDocuments[0].inventoryTransferID;
            params.request = "saveInventoryTransfer";
            response = await dispatch(makeErplyRequest(params, t("saveInventoryTransferError"), null, null, null, false, false));
        }

        if (response.status !== "error") {
            dispatch(setSelectedDocumentsAttribute(attributeName, attributeValue));
        }
    };

    const addToProductsScannedAsBatch = (amount) => {
        let productsScannedAsBatchCopy = productsScannedAsBatch.map(a => ({...a}));    // Clone array
        let productExists = false;
        const masterCase = scannedProduct?.productPackages.find(productPackage => productPackage.packageType.toLowerCase().trim() === "master case");

        let productPackage;
        if (scannedProductIsPackage) {
            productPackage = getProductPackage(scannedProduct);
            if (selectedRow && productPackage.packageType.toLowerCase().trim() !== "master case" && masterCase) productPackage = masterCase;
        } else {
            if (masterCase) productPackage = masterCase;
            else if (scannedProduct.unitName !== "" && scannedProduct.unitName !== null) productPackage = {packageType: scannedProduct.unitName, packageAmount: 1};
            else productPackage = {packageType: t("pieceShort"), packageAmount: 1};
        }

        for (let i = 0, n = productsScannedAsBatchCopy.length; i < n; i++) {
            if (productsScannedAsBatchCopy[i].productID == scannedProduct.productID && productsScannedAsBatchCopy[i].packageType == productPackage.packageType &&
                productsScannedAsBatchCopy[i].scannedCode === scannedCode) {
                productExists = true;
                productsScannedAsBatchCopy[i].amount += amount;
                productsScannedAsBatchCopy.unshift(productsScannedAsBatchCopy.splice(i, 1)[0]); // Move product to the top of list
                break;
            }
        }

        if (!productExists) {
            scannedProduct.scannedCode = scannedCode;
            scannedProduct.amount = amount;
            scannedProduct.packageType = productPackage.packageType;
            scannedProduct.packageAmount = productPackage.packageAmount;
            scannedProduct.packageID = productPackage.packageID;
            productsScannedAsBatchCopy.unshift(scannedProduct);
        }

        setProductsScannedAsBatch(productsScannedAsBatchCopy);
        clearInputs();
    };

    const confirmScan = (amount, scannedAmountExceedsAvailableAmount = false) => {
        const getWmsApiBinRecordParams = (amount, documentID) => {
            const binRecordParams = {
                productId: Number(scannedProduct.productID),
                binId: Number(binID),
                amount: Number(amount),
                added: Number(user.employeeID),
                customName: scannedProduct.name
            };

            if (documentID) {
                binRecordParams.referenceId = documentID;
                binRecordParams.referenceType = getDocumentType(componentSequence, isAssembly)
            }
            if (selectedBundle) {
                binRecordParams.bundleId = Number(selectedBundle.productID);
                binRecordParams.bundleName = selectedBundle.itemName;
            }
            if (productNeedsSerialNo) {
                binRecordParams.serialNo = serialNo;
            }
            if (scannedProductIsPackage && differentiateByPackageID) {
                const packaging = getProductPackage(scannedProduct);
                binRecordParams.packageId = packaging.packageID;
            }

            return binRecordParams;
        };

        if (scanAsBatch) {
            addToProductsScannedAsBatch(amount);
        } else {
            let params = [];

            if (isSalesOrder && selectedBundle !== null) {    // Bundle components are not on document rows
                params.push(getWmsApiBinRecordParams(-1 * amount, selectedDocuments[0][getIdFieldName(isInventoryTransfer, isAssembly, isAssignment)]));
            } else if (isScanBySupplier) {
                params.push(getWmsApiBinRecordParams(amount));
            } else {
                let amountRemaining = amount;

                for (let i = 0, n = selectedDocuments.length; i < n; i++) {
                    for (let j = 0, n = selectedDocuments[i].rows.length; j < n; j++) {
                        if (scannedProduct.productID == selectedDocuments[i].rows[j].productID) {
                            const rowAmount = isPurchaseReturn || isCreditInvoice ? Number(selectedDocuments[i].rows[j].amount) * (-1) : Number(selectedDocuments[i].rows[j].amount);
                            let amountToSubtract = rowAmount >= amountRemaining ? amountRemaining : rowAmount;

                            if (isFromMultipleDocuments) {
                                const amountLeftOnDocument = getAmountLeftOnDocument(selectedDocuments[i], scannedProduct.productID, binRecords);
                                if (amountLeftOnDocument <= 0) {
                                    continue;
                                } else if (amountLeftOnDocument < amountToSubtract) {
                                    amountToSubtract = amountLeftOnDocument;
                                }
                            }

                            amountRemaining -= amountToSubtract;

                            const amount = isOutScan ? amountToSubtract * (-1) : amountToSubtract;
                            const documentID = selectedDocuments[i][getIdFieldName(isInventoryTransfer, isAssembly, isAssignment)];
                            params.push(getWmsApiBinRecordParams(amount, documentID));
                        }

                        if (amountRemaining == 0) {
                            break;
                        }
                    }

                    if (amountRemaining == 0) {
                        break;
                    }
                }

                if (scannedAmountExceedsAvailableAmount && amountRemaining > 0) {
                    if (isFromMultipleDocuments && params.length === 0) { // A product with a fully scanned amount has been scanned
                        const documentWithProduct = selectedDocuments.find(doc => doc.rows.some(row => productsAreIdentical(componentSequence, selectedDocuments, scannedProduct, row)));
                        const documentID = documentWithProduct[getIdFieldName(isInventoryTransfer, isAssembly, isAssignment)];
                        params.push(getWmsApiBinRecordParams(amount, documentID));
                    } else {
                        params[0][`amount`] = isOutScan ? params[0][`amount`] - amountRemaining : params[0][`amount`] + amountRemaining;
                    }
                }

                // A product was scanned that was not on the document
                if (params.length === 0) {
                    const amountToParams = isOutScan ? amount * (-1) : amount;
                    const documentID = selectedDocuments[0][getIdFieldName(isInventoryTransfer, isAssembly, isAssignment)];  // If multiple documents, take the first one
                    params.push(getWmsApiBinRecordParams(amountToParams, documentID));
                }
            }

            const request = dispatch(makeWmsApiRequest(t, "bin-inventory-record", "POST", t("saveBinRecordsError"), null, params, true));

            request.then((response) => {
                if (response.status !== "error") {
                    // Add WMS API "IN" and "OUT" events for analytics
                    if (!isScanBySupplier) { // No documentId
                        response.forEach(binInventoryRecord => {
                            const eventType = isOutScan ? "OUT" : "IN";

                            dispatch(makeWmsApiRequest(t, "analytics", "POST", t("wmsApiAnalyticsError"), null, {
                                documentId: binInventoryRecord.documentID,
                                eventType: eventType,
                                documentType: getDocumentType(componentSequence, isAssembly),
                                binInventoryRecordId: binInventoryRecord.id
                            }, false, null, true));
                        });
                    }

                    setLastScannedProduct(deepCopy(scannedProduct));
                    setLastScannedAmount(amount);

                    if (batchesEnabled) {
                        dispatch(addScannedBatch({productID: scannedProduct.productID, batchCode: batchCode, amount: amount, binID: binID, documentID: null}));

                        const binRecord = {
                            binID: receivingArea.binID,
                            productID: scannedProduct.productID,
                            amount: amount
                        };
                        dispatch(addBinRecord(binRecord));   // Save bin records to local memory and don't request them from Erply
                        syncQuantitiesOnDocument(selectedDocumentsProducts, true, false, [...binRecords, binRecord]);
                    } else {
                        const syncQuantitiesFunction = () => {
                            syncQuantitiesOnDocument(selectedDocumentsProducts, true);
                        };

                        if (isOutScan) {
                            const newAmountInBin = getSelectedBinAmount(scannedProduct.productID) - amount;
                            dispatch(confirmBinAmountWithUser(t, newAmountInBin, binID, scannedProduct.productID, syncQuantitiesFunction));
                        } else {
                            syncQuantitiesFunction();
                        }
                    }

                    if (!isOutScan && isMatkasuvilad(clientCode)) {
                        const wmsWarningAttributeValue = getApiAttributeValue(wmsWarningAttributeName, scannedProduct.attributes);

                        if (wmsWarningAttributeValue !== false && wmsWarningAttributeValue.trim() !== "") {
                            dispatch(setInformativeModal(wmsWarningAttributeValue, `${t("warningOnProduct")}`));
                        }
                    }
                } else {
                    playSound("fail");
                }
            });
        }
    };

    // Update batches from JSON API. Save unsaved scanned batches to JSON API. NB! Does not work if multiple documents are selected for scan
    const updateScannedBatches = async (saveOnly = false) => {
        if (batchesEnabled && !(saveOnly && unsavedScannedBatches.length === 0)) {
            const scannedBatchesByDocument = await getScannedBatchesByDocument(dispatch, selectedDocuments, t);
            const scannedBatches = sumBatches(scannedBatchesByDocument);

            for (let i = 0, n = unsavedScannedBatches.length; i < n; i++) {
                let scannedProductFound = false;

                for (let j = 0, n = scannedBatches.length; j < n; j++) {
                    if (scannedBatches[j].productID == unsavedScannedBatches[i].productID &&
                        scannedBatches[j].binID == unsavedScannedBatches[i].binID && scannedBatches[j].batchCode == unsavedScannedBatches[i].batchCode) {
                        scannedBatches[j].amount += Number(unsavedScannedBatches[i].amount);
                        scannedProductFound = true;
                        break;
                    }
                }

                if (!scannedProductFound) {
                    scannedBatches.push(unsavedScannedBatches[i]);
                }
            }

            dispatch(setScannedBatches(scannedBatches));

            if (unsavedScannedBatches.length > 0) {
                const params = {json_object: {WMS: {scannedAmounts: scannedBatches}}};
                dispatch(makeJsonApiRequest(t, `v1/json_object/prcinvoice/${selectedDocuments[0].id}`, "PUT", JSON.stringify(params)));
                dispatch(setUnsavedScannedBatches([]));
            }
        }
    };

    const calculateAmount = () => {
        if (scanAsBatch) {
            return amount;
        } else {
            if (scannedProductIsPackage) {
                const packagingOption = getProductPackage(scannedProduct);
                return packagingOption.packageAmount * amount;
            }

            const parts = packaging.split("_");
            const packageType = parts[0];

            if (packageType === "unit") {
                return Number(amount);
            } else {    // Is a package
                const amountInPackage = parts[2];
                return amount * amountInPackage;
            }
        }
    };

    const documentHasProduct = (products) => {
        for (let i = 0, n = products.length; i < n; i++) {
            for (let j = 0, n = getProductsCodeOrder.length; j < n; j++) {
                if (products[i][getProductsCodeOrder[j]] && (products[i][getProductsCodeOrder[j]].toLowerCase() == scannedCode.toLowerCase() ||
                    (searchCodeFromCommaSeparatedCodes && commaSeparatedCodesIncludeCode(products[i][getProductsCodeOrder[j]].toLowerCase(), scannedCode)))) {
                    return true;
                }
            }

            // Search from product's packages' EANs
            for (let j = 0, n = products[i].productPackages.length; j < n; j++) {
                if (products[i].productPackages[j].packageCode && (products[i].productPackages[j].packageCode.toLowerCase() == scannedCode.toLowerCase() ||
                    (searchCodeFromCommaSeparatedCodes && commaSeparatedCodesIncludeCode(products[i].productPackages[j].packageCode.toLowerCase(), scannedCode)))) {
                    return true;
                }
            }
        }

        return false;
    };

    const handleScanInput = () => {
        if (!scannedCodeEmpty()) {
            clearTimeout(scanInputTimer.current);

            scanInputTimer.current = setTimeout(() => {
                getProduct().then((product) => {
                    if (scannedCode.toLowerCase() === lastScannedCode.current.toLowerCase()) {
                        if (product !== null) {
                            const productIsOnDocument = selectedDocumentsProducts.some(docProduct => docProduct.productID == product.productID);
                            const needsSerialNo = productNeedsSerialNumber(product, componentSequence, isScanBySupplier);
                            setProductNeedsSerialNo(needsSerialNo);
                            setSerialNo("");

                            const productWasScanned = selectedRow === null;
                            if ((isPurchaseOrder && confParameters.wmsTotalPurchaseAmountAsScanAmount == 1 && !(productWasScanned && confParameters.wmsTotalPurchaseAmountAsScanAmountListClickOnly == 1)) ||
                                (isOutScan && !isInventoryTransfer && confParameters.wmsTotalSalesAmountAsScanAmount == 1 && !(productWasScanned && confParameters.wmsTotalSalesAmountAsScanAmountListClickOnly == 1)) ||
                                (isInventoryTransfer && confParameters.wmsTotalInventoryTransferAmountAsScanAmount == 1 && !(productWasScanned && confParameters.wmsTotalInventoryTransferAmountAsScanAmountListClickOnly == 1)) ||
                                (selectedBundle !== null && !needsSerialNo)) {
                                setTotalAmountAsAmount(product);
                            } else if (scanAsBatch || confParameters.wmsSetDefaultAmountAfterScan == 1) {
                                setAmount(defaultAmount);
                            }

                            let packageOptions = product.productPackages.map(productPackage => getPackagingOption(productPackage));

                            const unitID = product.unitID;
                            if (unitID !== 0) {
                                const unitOption = {key: `unit_${unitID}`, text: product.unitName, value: `unit_${unitID}`};
                                packageOptions.unshift(unitOption);
                            }

                            if (packageOptions.length === 0) {
                                setPackagingOptions(defaultPackagingOptions);
                            } else {
                                setPackagingOptions(packageOptions);
                            }

                            if (isOutScan && binsEnabled) {
                                // If enabled in settings, set selected bin as the bin selected in product's bin dropdown
                                const selectSuggestedBin = confParameters.wmsSelectSuggestedBin == 1

                                if (productIsOnDocument && selectSuggestedBin) {
                                    const selectedRowBin = rowSelectedBins.find(rowBin => rowBin.productID == product.productID);
                                    if (selectedRowBin) {
                                        setBinID(selectedRowBin.binID);
                                    } else {
                                        setBinID("");
                                    }
                                } else {
                                    setBinID("");
                                }

                                // Get bin quantities for a product that is not on document
                                if (!productIsOnDocument && confParameters.wmsEnableScanningProductsNotOnOutDocument == 1) {
                                    dispatch(getBinQuantities(t, true, setBinQuantitiesOfProductNotOnDocument, null, product.productID));
                                }
                            }

                            const productHasLocationInWarehouseText = product.locationInWarehouseText !== "" && product.locationInWarehouseText !== "määramata";
                            if (!isOutScan && !isCreditInvoice && isAK && !productHasLocationInWarehouseText) {
                                setIsLisaKappiProduct(true);
                            } else {
                                setIsLisaKappiProduct(false);
                            }

                            if (isAK && !isOutScan) {
                                const binID = product.locationInWarehouseText === "TC2000" ? akTc2000BinId : receivingArea.binID;
                                setBinID(binID);
                            }
                        } else {
                            scannedProduct = null;
                            setDefaultPackaging();
                            setProductNeedsSerialNo(false);
                            setIsLisaKappiProduct(false);
                        }

                        setGetProductsRequestsFinished(true);
                    }
                });
            }, 80); // Healthy timeout for scanner so that requests aren't made on every char entered
        } else {
            setPackagingOptions(defaultPackagingOptions);
            setProductNeedsSerialNo(false);
            setIsLisaKappiProduct(false);
            clearTimeout(scanInputTimer.current);
        }
    };

    const setTotalAmountAsAmount = (product) => {
        const productOnDocument = subtractedRows.find(row => row.productID == product.productID &&
            !(selectedBundle !== null && (row.bundleID != selectedBundle.productID || row.bundleName !== selectedBundle.itemName)));
        if (productOnDocument) {    // Product exists on document
            let totalAmount = productOnDocument.amount;

            const productPackage = getProductPackage(product);
            const productWithoutPackage = searchProductFromDocumentsProducts();
            if (productPackage && (!productWithoutPackage || confParameters.wmsFirstCheckPackageCodes == 1)) {
                totalAmount = Math.floor(totalAmount / productPackage.packageAmount);
            }

            setAmount(roundFloatingPointError(totalAmount));
        } else {
            setAmount(defaultAmount);
        }
    };

    const getPackageOptionValue = (productPackage) => {
        return `package_${productPackage.packageTypeID}_${productPackage.packageAmount}`;
    };

    const getPackagingOption = (productPackage) => {
        const optionText = `${productPackage.packageType} ${productPackage.packageAmount}`;
        return {key: `package_${productPackage.packageCode}`, text: optionText, value: getPackageOptionValue(productPackage)};
    };

    const handleFinishWorkOnClick = async (scanAsBatch) => {
        const bundleComponentRows = subtractedRows.filter(row => isBundleComponent(row));
        const allBundlesFullyScanned = !bundleComponentRows.some(row => row.amount > 0);    // A bundle component may have a subtracted amount of less than zero if it was initially scanned but later the bundle was removed from the document
        if (!allBundlesFullyScanned) {
            return dispatch(errorMessageSet(t("allBundlesMustBeFullyScanned")));
        }

        dispatch(setInitialSubtractedRows(initialSubtractedRows));

        // Add extra fields
        if (canChangeDocInfo) {
            const saveDocumentParams = {};
            const extraFields = {};
            const setExtraField = (name, value) => {
                if (selectedDocuments[0].hasOwnProperty(name)) {
                    extraFields[name] = value;

                    if (selectedDocuments[0][name] != value) {
                        saveDocumentParams[name] = value;
                    }
                }
            };

            setExtraField("notes", notes);
            setExtraField("packingUnitsDescription", packageDesc);
            setExtraField("internalNotes", internalNotes);
            setExtraField("deliveryTypeID", deliveryTypeID);
            dispatch(setDocumentExtraFields(extraFields));

            // Save extra fields to scanned document
            if (Object.keys(saveDocumentParams).length > 0) {
                saveDocumentParams.request = isInventoryTransfer ? "saveInventoryTransfer" : isSalesOrder || isCreditInvoice ? "saveSalesDocument" : "savePurchaseDocument";
                saveDocumentParams[getIdFieldName(isInventoryTransfer)] = selectedDocuments[0][getIdFieldName(isInventoryTransfer)];
                const errorText = isInventoryTransfer ? "saveInventoryTransferError" : isSalesOrder || isCreditInvoice ? "saveSalesDocumentError" : "savePurchaseDocumentError";
                dispatch(makeErplyRequest(saveDocumentParams, t(errorText), null, null, null, false, false));
            }
        }

        // Alas-Kuul-specific: get RETURN file from FTP and add amounts in the file to scanned products
        if (isAK && isTc2000Only && !isCreditInvoice) {
            const tc2000ReceivedAttribute = selectedDocuments[0].attributes && selectedDocuments[0].attributes.find(attribute => attribute.attributeName === "tc2000ReturnFileReceived");

            if (!tc2000ReceivedAttribute || tc2000ReceivedAttribute.attributeValue !== "1") {
                const returnFile = await getAkFtpReturnFile();

                if (returnFile.hasOwnProperty("error")) {
                    dispatch(errorMessageSet(`RETURN faili ei leitud! Veateade: ${returnFile.error}`));
                } else {
                    console.log("TC2000 RETURN file", returnFile);
                    let params = [];
                    const rowIDsOfProductsFound = [];

                    const addReturnFileRowToParams = (returnFileRow, index) => {
                        if (returnFileRow.Delivered != 0) {
                            const productID = returnFileRow.ArticleNumber;
                            const product = selectedDocumentsProducts.find(product => product.productID == productID);

                            if (!product) {
                                return dispatch(errorMessageSet(`TC2000 RETURN failis olev toode ID-ga ${productID} puudub tellimusel`));
                            }

                            // If a single row with product is found or a row with a matching amount is found, use product name on document row, else use default name
                            // NB! This is not 100% accurate as TC2000 RETURN file does not return scanned product's name so names may never be compared
                            const rowsWithProduct = selectedDocuments[0].rows.filter(row => row.productID == productID);
                            let row;
                            if (rowsWithProduct.length === 1) {
                                row = rowsWithProduct[0];
                            } else if (rowsWithProduct.length > 1) {
                                row = rowsWithProduct.find(row => row.amount == returnFileRow.Delivered &&
                                    returnFileRow.Delivered == returnFileRow.Ordered && !rowIDsOfProductsFound.includes(row.rowID));

                                if (row) {
                                    rowIDsOfProductsFound.push(row.rowID);
                                }
                            }

                            const name = row ? row.itemName : product.name;
                            params.push({
                                productId: product.productID,
                                binId: akTc2000BinId,
                                amount: -1 * returnFileRow.Delivered,
                                added: Number(user.employeeID),
                                customName: name,
                                referenceId: selectedDocuments[0][getIdFieldName(isInventoryTransfer, isAssembly, isAssignment)],
                                referenceType: getDocumentType(componentSequence, isAssembly)
                            });
                        }
                    };

                    if (typeof returnFile.Data === "object" && returnFile.Data !== null && !Array.isArray(returnFile.Data)) {  // Single row is returned as an object
                        addReturnFileRowToParams(returnFile.Data, 0);
                    } else {    // Multiple rows are returned as an array
                        for (let i = 0, n = returnFile.Data.length; i < n; i++) {
                            addReturnFileRowToParams(returnFile.Data[i], i);
                        }
                    }

                    const saveBinRecordsResponse = await dispatch(makeWmsApiRequest(t, "bin-inventory-record", "POST", t("saveBinRecordsError"), null, params, true));
                    if (saveBinRecordsResponse.status !== "error") {
                        await updateBinRecords();
                        playSound("success");
                        saveDocumentAttribute("tc2000ReturnFileReceived", "1");
                        dispatch(componentSet("ScanFinish"));
                    }
                }
            } else {
                dispatch(errorMessageSet(`Sellel tellimusel on juba kapitooted maha arvatud. Uut mahaarvamist ei tehtud.`));
                await updateBinRecords();
                dispatch(componentSet("ScanFinish"));
            }
        } else {
            if (scanAsBatch) {
                let params = [];

                for (let i = 0, n = productsScannedAsBatch.length; i < n; i++) {
                    params.push({
                        productId: productsScannedAsBatch[i].productID,
                        binId: binID,
                        amount: productsScannedAsBatch[i].amount * productsScannedAsBatch[i].packageAmount,
                        added: Number(user.employeeID),
                        referenceId: selectedDocuments[0][getIdFieldName(isInventoryTransfer, isAssembly, isAssignment)],
                        referenceType: getDocumentType(componentSequence, isAssembly),
                        packageId: productsScannedAsBatch[i].packageID
                    });
                }

                const request = dispatch(makeWmsApiRequest(t, "bin-inventory-record", "POST", t("saveBinRecordsError"), null, params, true));
                request.then(async (response) => {
                    if (response.status !== "error") {
                        await updateBinRecords();
                        playSound("success");
                        setProductsScannedAsBatch([]);
                    }
                });
            } else {
                await updateBinRecords();

                if (batchesEnabled) {
                    await updateScannedBatches();
                }

                dispatch(componentSet("ScanFinish"));
            }
        }
    };

    const updateBinRecords = async () => {
        const binRecords = await dispatch(getBinRecords(t, documentIDs, getDocumentType(componentSequence, isAssembly), isScanBySupplier ? supplierBin.binID : null, true));
        updateScannedProducts(binRecords, selectedDocumentsProducts);
    };

    const getAkFtpReturnFile = () => {
        const body = JSON.stringify({
            documentNo: selectedDocuments[0][isInventoryTransfer ? "inventoryTransferNo" : "number"]
        });

        return makeRequest("./services/getReturnFileFromAkFtp.php", "POST", body, null, dispatch, true);
        // return makeRequest("http://localhost/wms/services/getReturnFileFromAkFtp.php", "POST", body, null, dispatch, true);
    };

    const getInitialSubtractedRows = (summedScannedProducts) => {
        let initialSubtractedRows = summedRows.map(summedRow => Object.assign({}, summedRow));

        // Subtract previously scanned products
        for (let i = 0, n = initialSubtractedRows.length; i < n; i++) {
            for (let j = 0, n = scannedProducts.length; j < n; j++) {
                if (productsAreIdentical(componentSequence, selectedDocuments, initialSubtractedRows[i], scannedProducts[j], true)) {
                    initialSubtractedRows[i].amount -= scannedProducts[j].amount;
                }
            }
        }

        // Add currently scanned products
        for (let i = 0, n = summedScannedProducts.length; i < n; i++) {
            for (let j = 0, n = initialSubtractedRows.length; j < n; j++) {
                if (productsAreIdentical(componentSequence, selectedDocuments, summedScannedProducts[i], initialSubtractedRows[j], true)) {
                    initialSubtractedRows[j].amount += summedScannedProducts[i].amount;
                    break;
                }
            }
        }

        return initialSubtractedRows;
    };

    const getFollowUpDocuments = async () => {
        if (followUpDocuments !== null) {
            return followUpDocuments;
        } else if (isInTransitTransfer || !(isPurchaseOrder || (isInventoryTransfer && confParameters.wmsEnableRepeatedTransferOrderScan == 1))) {
            dispatch(setFollowUpDocuments([]));
            return [];
        }

        const followUpDocumentsIDs = await getFollowUpDocumentIDs();

        if (followUpDocumentsIDs.length === 0) {
            dispatch(setFollowUpDocuments([]));
            return [];
        } else {
            let requests = [];
            let bulkRequests = [];

            for (let i = 0, n = followUpDocumentsIDs.length; i < n; i++) {
                const request = {
                    requestName: isInventoryTransfer ? "getInventoryTransfers" : "getPurchaseDocuments",
                    [getIdFieldName(isInventoryTransfer, isAssembly)]: followUpDocumentsIDs[i]
                };
                if (!isInventoryTransfer) request.getAddedTimestamp = 1;
                bulkRequests.push(request);
            }

            if (isInventoryTransfer) {
                requests.push(dispatch(makeErplyBulkRequest(bulkRequests, t("getInventoryTransfersError"), null, null, null, true, true)));
            } else {
                requests.push(dispatch(makeErplyBulkRequest(bulkRequests, t("getPurchaseDocumentsError"), null, null, null, true, true)));
            }

            if (isPurchaseOrder && multiplePurchaseOrderScanEnabled) {
                requests.push(dispatch(makeJsonApiRequest(t, `v1/json_object/prcinvoice/${followUpDocumentsIDs.join(";")}`, "GET", null)));
            }

            let [followUpDocuments, followUpDocumentsJsonAttributes] = await Promise.all(requests);
            if (isPurchaseOrder && multiplePurchaseOrderScanEnabled) {
                setFollowUpDocumentsScannedAmountsFromJsonAttributes(followUpDocumentsJsonAttributes, followUpDocuments);
            }

            dispatch(setFollowUpDocuments(followUpDocuments));
            return followUpDocuments
        }
    };

    const getFollowUpDocumentIDs = async () => {
        let followUpDocumentsIDs = [];

        for (let i = 0, n = selectedDocuments.length; i < n; i++) {
            if (isInventoryTransfer) {
                const params = {
                    request: "getInventoryTransfers",
                    searchAttributeName: "sourceTransferOrderID",
                    searchAttributeValue: selectedDocuments[0].inventoryTransferID,
                };

                const inventoryTransfers = await dispatch(makeErplyRequest(params, t("getInventoryTransfersError")));
                followUpDocumentsIDs = inventoryTransfers.map(transfer => transfer.inventoryTransferID);
                if (selectedDocuments[i].followupInventoryTransferID != 0) {
                    followUpDocumentsIDs.push(selectedDocuments[i].followupInventoryTransferID);
                }
            } else {
                for (let j = 0, n = selectedDocuments[i].baseToDocuments.length; j < n; j++) {
                    followUpDocumentsIDs.push(selectedDocuments[i].baseToDocuments[j].id);
                }
            }
        }

        return Promise.resolve([...new Set(followUpDocumentsIDs)]);
    };

    const getProductWithFIFO = () => {
        const params = {
            request: "getProducts",
            productID: scannedProduct.productID,
            getFIFOCost: 1,
            warehouseID: selectedWarehouse.warehouseID
        };

        return dispatch(makeErplyRequest(params, t("getProductsError"))).then(products => products[0]);
    };

    // Searches from selected documents' products and scanned products not on documents
    const searchProductFromDocumentsProducts = () => {
        const searchFromProducts = (products) => {
            for (let j = 0, m = getProductsCodeOrder.length; j < m; j++) {
                for (let i = 0, n = products.length; i < n; i++) {
                    if (products[i][getProductsCodeOrder[j]] &&
                        (products[i][getProductsCodeOrder[j]].toLowerCase() == scannedCode.toLowerCase() ||
                            (searchCodeFromCommaSeparatedCodes && commaSeparatedCodesIncludeCode(products[i][getProductsCodeOrder[j]].toLowerCase(), scannedCode)))) {
                        scannedProduct = deepCopy(products[i]);

                        if (isSalesOrder && selectedRow !== null && scannedProduct.productID == selectedRow.productID) { // Document row may have a custom name
                            scannedProduct.name = selectedRow.name;
                        }

                        if (selectedBundle && productIsInSelectedBundle(scannedProduct, summedRows, selectedBundle)) {
                            scannedProduct.bundleID = selectedBundle.productID;
                            scannedProduct.bundleName = selectedBundle.itemName;
                        }

                        return products[i];
                    }
                }
            }
        };

        const productFoundInDocumentProducts = searchFromProducts(selectedDocumentsProducts);
        if (productFoundInDocumentProducts) {
            return productFoundInDocumentProducts;
        }

        // Search from scanned products not on document
        return searchFromProducts(productsNotOnDocument);
    };
    // Searches from selected documents' products' and scanned products not on documents package EAN codes
    const searchProductFromDocumentsPackageCodes = () => {
        const searchFromPackageCodes = (products) => {
            for (let i = 0, n = products.length; i < n; i++) {
                for (let j = 0, m = products[i].productPackages.length; j < m; j++) {
                    if (products[i].productPackages[j].packageCode &&
                        (products[i].productPackages[j].packageCode.toLowerCase() == scannedCode.toLowerCase() ||
                            (searchCodeFromCommaSeparatedCodes && commaSeparatedCodesIncludeCode(products[i].productPackages[j].packageCode.toLowerCase(), scannedCode)))) {
                        scannedProduct = deepCopy(products[i]);
                        setScannedProductIsPackage(true);
                        return products[i];
                    }
                }
            }
        };

        const productFoundInDocumentProducts = searchFromPackageCodes(selectedDocumentsProducts);
        if (productFoundInDocumentProducts) {
            return productFoundInDocumentProducts;
        }

        // Search from scanned products not on document
        return searchFromPackageCodes(productsNotOnDocument);
    };

    const getProduct = async () => {
        setScannedProductIsPackage(false);

        if (confParameters.wmsFirstCheckPackageCodes == 1) {
            let product = searchProductFromDocumentsPackageCodes();
            if (product) {
                return Promise.resolve(product);
            }

            product = searchProductFromDocumentsProducts();
            if (product) {
                return Promise.resolve(product);
            }
        } else {
            let product = searchProductFromDocumentsProducts();
            if (product) {
                return Promise.resolve(product);
            }

            product = searchProductFromDocumentsPackageCodes();
            if (product) {
                return Promise.resolve(product);
            }
        }

        if (!isScanBySupplier) {
            // If product was not found from selected document's products, make getProducts API call
            let requests = [];
            for (let i = 0, n = searchCodeFromCommaSeparatedCodes ? 1 : getProductsCodeOrder.length; i < n; i++) {
                const request = {
                    requestName: "getProducts",
                    getPackageInfo: 1,
                    active: 1,
                    requestID: i,
                    getContainerInfo: 1
                };

                if (searchCodeFromCommaSeparatedCodes) {
                    request.searchNameIncrementally = scannedCode;
                    request.searchCodeFromMiddle = 1;
                } else {
                    request[getProductsCodeOrder[i]] = scannedCode;
                }

                requests.push(request)
            }

            const getProductsBulkResponse = await dispatch(makeErplyBulkRequest(requests, t("getProductsError"), null, null, null, false));

            for (let i = 0, n = getProductsBulkResponse.length; i < n; i++) {
                if (getProductsBulkResponse[i].hasOwnProperty("records") && getProductsBulkResponse[i].records.length > 0) {
                    let product;

                    if (searchCodeFromCommaSeparatedCodes) {
                        for (let k = 0, n = getProductsCodeOrder.length; k < n; k++) {
                            for (let j = 0, n = getProductsBulkResponse[i].records.length; j < n; j++) {
                                if (commaSeparatedCodesIncludeCode(getProductsBulkResponse[i].records[j][getProductsCodeOrder[k]], scannedCode)) {
                                    product = getProductsBulkResponse[i].records[j];
                                    break;
                                }
                            }
                        }
                    } else {
                        product = getProductsBulkResponse[i].records[0];
                    }

                    if (product && scannedCode.toLowerCase() === lastScannedCode.current.toLowerCase()) {
                        scannedProduct = product;

                        if (confParameters.wmsFirstCheckPackageCodes == 1 && product.productPackages.some(productPackage => productPackage.packageCode.toLowerCase() == scannedCode.toLowerCase() ||
                            (searchCodeFromCommaSeparatedCodes && commaSeparatedCodesIncludeCode(productPackage.packageCode.toLowerCase(), scannedCode)))) {
                            // Package has the same code as product
                            setScannedProductIsPackage(true);
                        }

                        const productsNotOnDocumentCopy = productsNotOnDocument.map(p => ({...p}));
                        productsNotOnDocumentCopy.push(product);
                        dispatch(setProductsNotOnDocument(productsNotOnDocumentCopy)); // Save to memory so recurrent requesting is not necessary

                        return product;
                    }
                }
            }
        }

        return null;
    };

    const setDefaultPackaging = () => {
        setPackagingOptions(defaultPackagingOptions);
    };

    const checkRowToLabelPrint = (productID) => {
        let newRowIDsToLabelPrint;

        if (productIDsToLabelPrint.includes(productID)) {
            newRowIDsToLabelPrint = productIDsToLabelPrint.filter(printableRowID => printableRowID !== productID);
        } else {
            newRowIDsToLabelPrint = productIDsToLabelPrint.concat([productID]);
        }

        setProductIDsToLabelPrint(newRowIDsToLabelPrint);
    };

    const handleLisaKappiClick = (product) => {
        dispatch(setModal(t("confirmation"), `Kas oled kindel, et lisad kappi?`, () => saveTC2000LocationInWarehouse(product)))
    };

    const saveTC2000LocationInWarehouse = async (product) => {
        const locationInWarehouseText = "TC2000";

        const params = {
            request: "saveProduct",
            locationInWarehouseText: locationInWarehouseText,
            locationInWarehouseID: akTc2000LocationInWarehouseId,
            productID: product.productID
        };

        const response = await dispatch(makeErplyRequest(params, t("saveProductError")));
        if (response.status !== "error") {
            let updatedSelectedDocumentsProducts = selectedDocumentsProducts.map(a => ({...a}));    // Clone array
            for (let i = 0, n = updatedSelectedDocumentsProducts.length; i < n; i++) {
                if (updatedSelectedDocumentsProducts[i].productID == product.productID) {
                    updatedSelectedDocumentsProducts[i].locationInWarehouseText = locationInWarehouseText;
                    updatedSelectedDocumentsProducts[i].locationInWarehouseID = akTc2000LocationInWarehouseId;
                    break;
                }
            }

            if (scannedProduct && scannedProduct.productID == product.productID) {
                setBinID(akTc2000BinId);
            }

            dispatch(setSelectedDocumentsProducts(updatedSelectedDocumentsProducts));
            dispatch(successMessageSet("Kappi lisatud!"));
            setIsLisaKappiProduct(false);
        }
    };

    const removeAddEanInput = () => {
        setMissingEan("");
        setAddEanBtnClickedProductID(null);
    };

    const saveEan = async (productID, ean) => {
        if (ean.trim() === "") {
            return dispatch(errorMessageSet(translateAccordingToCountry(t("enterEan"), confParameters)));
        } else if (confParameters.product_code2_unique == 1) {
            const productsWithDuplicateCode2 = await dispatch(makeErplyRequest({
                request: "getProducts",
                code2: ean
            }, t("getProductsError")));

            if (productsWithDuplicateCode2.length > 0) {
                const message = `${translateAccordingToCountry(t("eanMustBeUnique"), confParameters)}${productsWithDuplicateCode2[0].code}`;
                return dispatch(errorMessageSet(message));
            }
        }

        const params = {
            request: "saveProduct",
            productID: productID,
            code2: ean
        };

        const response = await dispatch(makeErplyRequest(params, t("saveProductError")));
        if (response.status !== "error") {
            let updatedSelectedDocumentsProducts = selectedDocumentsProducts.map(a => ({...a}));    // Clone array
            for (let i = 0, n = updatedSelectedDocumentsProducts.length; i < n; i++) {
                if (updatedSelectedDocumentsProducts[i].productID == productID) {
                    updatedSelectedDocumentsProducts[i].code2 = ean;
                    break;
                }
            }

            dispatch(setSelectedDocumentsProducts(updatedSelectedDocumentsProducts));
            removeAddEanInput();
            dispatch(successMessageSet(translateAccordingToCountry(t("eanAdded"), confParameters)));
        }
    };

    const getPackageCalculation = (productID, amount, amountInBold = true) => {
        let remainingAmount = amount;
        const product = selectedDocumentsProducts.find(product => product.productID == productID);
        let calculatedAmount = "";
        const smallestUnitName = product && product.unitName !== "" && product.unitName !== null ? product.unitName : t("pieceShort");

        while (remainingAmount > 0) {
            let packageWithLargestAmount = {packageAmount: 1};
            packageWithLargestAmount.packageType = product.unitName !== "" && product.unitName !== null ? product.unitName : defaultPackaging;

            for (let i = 0, n = product.productPackages.length; i < n; i++) {
                const excludedPackageTypes = confParameters.wmsExcludedPackageTypesFromPackageCalculator.split(",").map(Number);

                if (!excludedPackageTypes.includes(product.productPackages[i].packageTypeID) &&
                    Number(packageWithLargestAmount.packageAmount) <= Number(product.productPackages[i].packageAmount) &&
                    remainingAmount >= Number(product.productPackages[i].packageAmount)) {
                    packageWithLargestAmount = product.productPackages[i];
                }
            }

            let timesInRemainingAmount = Math.floor(remainingAmount / Number(packageWithLargestAmount.packageAmount));
            if (calculatedAmount !== "") {
                calculatedAmount += " + ";
            }

            remainingAmount -= timesInRemainingAmount * packageWithLargestAmount.packageAmount;
            if (remainingAmount > 0 && remainingAmount < 1) {
                timesInRemainingAmount += remainingAmount;
                remainingAmount -= remainingAmount;
            }

            calculatedAmount += `${timesInRemainingAmount} x ${packageWithLargestAmount.packageType}(${packageWithLargestAmount.packageAmount}${smallestUnitName})`;
        }

        return amountInBold ? <span>{calculatedAmount} / <strong>{amount}</strong></span> : calculatedAmount + ` / ${amount}`;
    };

    const getBinDocRow = (productID, showPackageCalculations, isBundle) => {
        if (isOutScan && binsEnabled && binQuantities && !isBundle) {
            const selectedBinAmount = getSelectedRowBinAmount(productID, showPackageCalculations);
            const binAmount = <Input className={'binAmount'} readOnly value={selectedBinAmount || ""}/>;

            return (
                <Table.Row>
                    <Table.Cell className={"tableHeading"} width={1}>{t("binAmount")}</Table.Cell>
                    <Table.Cell>
                        <div className={"flex"}>
                            <Dropdown
                                className={'binSelect'}
                                value={getSelectedRowBinID(productID)}
                                fluid
                                selection
                                options={getBinOptions(productID)}
                                onChange={(e, {value}) => setSelectedRowBin(value, productID)}
                                search
                                noResultsMessage={t("noResultsFound")}
                            />
                            {binAmount}
                        </div>
                    </Table.Cell>
                </Table.Row>
            )
        }
    };

    const getSelectedBinAmount = (productID) => {
        for (let i = 0, n = binQuantities.length; i < n; i++) {
            if (binQuantities[i].binID == binID && binQuantities[i].productID == productID) {
                return binQuantities[i].amount;
            }
        }
    };

    const getSelectedRowBinAmount = (productID, showPackageCalculations) => {
        const selectedBinID = getSelectedRowBinID(productID);
        for (let i = 0, n = binQuantities.length; i < n; i++) {
            if (binQuantities[i].binID == selectedBinID && binQuantities[i].productID == productID) {
                if (showPackageCalculations) {
                    return getPackageCalculation(productID, binQuantities[i].amount, false);
                } else {
                    return binQuantities[i].amount;
                }
            }
        }
    };

    //duplicate
    const getSelectedRowBinID = (productID) => {
        for (let i = 0, n = rowSelectedBins.length; i < n; i++) {
            if (rowSelectedBins[i].productID == productID) {
                return rowSelectedBins[i].binID;
            }
        }
    };

    //duplicate
    const setSelectedRowBin = (value, productID) => {
        let selectedBinsCopy = rowSelectedBins.slice();
        for (let i = 0, n = selectedBinsCopy.length; i < n; i++) {
            if (selectedBinsCopy[i].productID == productID) {
                selectedBinsCopy[i].binID = value;
                setRowSelectedBins(selectedBinsCopy);
                break;
            }
        }
    };

    const getBinOptions = (productID, isMainBin = false) => {
        let options = [];
        const quantities = binQuantitiesOfProductNotOnDocument.length > 0 && isMainBin ? binQuantitiesOfProductNotOnDocument : binQuantities;

        if (quantities && (!enableBinOrder || (enableBinOrder && binsContainingDocumentProducts))) {
            for (let i = 0, n = quantities.length; i < n; i++) {
                if (productID == quantities[i].productID && quantities[i].amount > 0) {
                    const option = {key: quantities[i].binID, text: translateBinFromEng(quantities[i].binCode, language),
                        value: quantities[i].binID, order: 9999, preferred: quantities[i].binPreferred};

                    if (enableBinOrder) {
                        const binContainingDocumentProducts = binsContainingDocumentProducts.find(bin => bin.binID == quantities[i].binID);
                        if (binContainingDocumentProducts) {
                            option.order = binContainingDocumentProducts.order <= 0 || binContainingDocumentProducts.order === "" ? 9999 : binContainingDocumentProducts.order;
                        }
                    }
                    options.push(option);
                }
            }

            // Sort 1) by order 2) by preferred/unpreferred 3) alphabetically
            options.sort((a, b) => {
                const orderA = a.order;
                const orderB = b.order;
                const textA = a.text.toUpperCase();
                const textB = b.text.toUpperCase();
                return (orderA < orderB) ? -1 : (orderA > orderB) ? 1 :
                    (a.preferred > b.preferred) ? -1 : (a.preferred < b.preferred) ? 1 :
                        (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });

            // Move receiving area to the end
            const receivingAreaIndex = options.findIndex(option => option.text === translateBinFromEng("receiving_area", language));
            if (receivingAreaIndex !== -1) {
                options.push(options.splice(receivingAreaIndex, 1)[0]);
            }
        }

        return options;
    };

    const getCode = (row, codeField) => {
        return row.hasOwnProperty(codeField) && row[codeField] !== null && row[codeField] !== "" ? row[codeField] : getProductProperty(selectedDocumentsProducts, row.productID, codeField);
    };

    const getName = (row) => {
        return row.hasOwnProperty("itemName") ? row.itemName : row.hasOwnProperty("name") ? row.name : getProductProperty(selectedDocumentsProducts, row.productID, "name");
    };

    const isSelectedRow = (row) => {
        let isSelected = false;

        if (selectedRow !== null) {
            const noOfKeys = Object.keys(row).length;
            let counter = 1;

            Object.keys(row).forEach(function(key) {
                if (row[key] !== selectedRow[key]) {
                    return false;
                } else if (counter < noOfKeys) {
                    counter ++;
                } else {
                    isSelected = true;
                    return true;
                }
            });
        }

        return isSelected
    };

    const handleTableClick = (row, ignoreClick, isBundleComponent = false) => {
        if (row.productID != 0 && !ignoreClick) {
            if (rowIsBundle(row)) {
                if (isSelectedRow(row) || (selectedBundle && selectedBundle.productID == row.productID && selectedBundle.itemName === row.itemName)) {
                    // Collapse previously selected bundle
                    setSelectedBundle(null);
                    setSelectedRow(null);

                    if (scannedProduct && scannedProduct.hasOwnProperty("bundleID")) {
                        delete scannedProduct.bundleID;
                        delete scannedProduct.bundleName;
                    }
                } else {
                    setSelectedRow(row);
                    setSelectedBundle(deepCopy(row));
                }
            } else if (canSelectRow) {
                if (!isBundleComponent) {
                    setSelectedBundle(null);
                }

                setSelectedRow(row);
                setFields(row);
                dispatch(setBinQuantitiesOfProductNotOnDocument([]));

                window.scrollTo(0, 0);
                if (!scanAsBatch && amountInput.current) {
                    amountInput.current.focus();
                    amountInput.current.select();
                }
            }
        }
    };

    const setFields = (row) => {
        let selectedProduct = selectedDocumentsProducts.find(product => product.productID == row.productID);
        if (!selectedProduct) {    // Product is not on document
            selectedProduct = scannedProducts.find(product => product.productID == row.productID);
        }

        const scannedCode = selectedProduct.code2 === null || selectedProduct.code2 === "" ? selectedProduct.code : selectedProduct.code2;
        setScannedCode(scannedCode);

        if (isOutScan && binsEnabled) {
            const selectedRowBin = rowSelectedBins.find(rowBin => rowBin.productID == row.productID);
            if (selectedRowBin) {
                setBinID(selectedRowBin.binID);
            }
        }
    };

    const getScannedProductName = () => {
        let name = scannedProduct !== null && scannedCode !== "" ? scannedProduct.name : "";
        let packagingOptionText = "";
        if (scannedProductIsPackage && scannedProduct !== null) {
            const packagingOption = getProductPackage(scannedProduct);

            if (packagingOption) {
                packagingOptionText = ` ${packagingOption.packageType} ${packagingOption.packageAmount}`;
            }
        }

        let elements = [<p key={"packagingOptionText"} className={"smallWhiteText"}>{name} <span className="blue">{packagingOptionText}</span> </p>];
        let classNames = "flex";

        const lisaKappiButton = getScannedProductLisaKappiButton();
        if (lisaKappiButton !== "") {
            elements.push(lisaKappiButton);
        } else {
            classNames += " marginBottom";
        }

        return <div className={classNames}>{elements}</div>;
    };

    const displayLastScannedProduct = () => {
        if (lastScannedProduct !== null && !scanAsBatch) {
            const summedScannedProduct = summedScannedProducts.find(product => productsAreIdentical(componentSequence, selectedDocuments, product, lastScannedProduct));
            const productOnDocument = initialSubtractedRows.find(product => productsAreIdentical(componentSequence, selectedDocuments, product, lastScannedProduct));
            const totalAmountOnDocument = productOnDocument ? productOnDocument.amount : 0;

            if (summedScannedProduct) {
                let totalText = summedScannedProduct.amount;
                if (!isScanBySupplier) {
                    totalText += `/${totalAmountOnDocument}`;
                }

                let code = lastScannedProduct.code;
                if (isAK && !isOutScan && lastScannedProduct.supplierCode && lastScannedProduct.supplierCode !== "") {
                    code += ` [${lastScannedProduct.supplierCode}]`;
                }

                return <p className={"smallWhiteText"}>{`${t("lastScanned")}: ${code} ${lastScannedProduct.name} `}
                    <span className="blue">{lastScannedAmount}</span>{`. ${t("total")}: `}<span className="blue">{totalText}</span></p>;
            }
        }
    };

    const createMainBinField = () => {
        if (binsEnabled) {
            if (isOutScan) {
                return (
                    <div>
                        <label className={"inScanLabel"}>{t("bin")}:</label>
                        <Dropdown
                            ref={binInput}
                            value={binID}
                            fluid
                            selection
                            options={getMainBinOptions()}
                            onChange={(e, {value}) => setBinID(value)}
                            search
                            noResultsMessage={t("noResultsFound")}
                        />
                    </div>
                );
            } else if (!isAK && !isScanBySupplier && canSetInScanBin) {  // If this were to be displayed to AK, then TC2000 bin logic needs to be handled
                // const successIconName = "check"; // More conventional icon
                const successIconName = "thumbs up"; // Less conventional icon
                const successColour = "green";
                // const failIconName = "minus circle"; // More conventional icon
                const failIconName = "thumbs down"; // Less conventional icon
                const failColour = "red";
                const iconName = binID === "" ? failIconName : successIconName;
                const iconColour = binID === "" ? failColour : successColour;

                const setReceivingAreaAsBin = () => {
                    setBinID(receivingArea.binID);
                    setInScanBinCode(translateBinFromEng(receivingArea.code, language));
                };

                const handleInScanBinCodeInput = (code) => {
                    clearTimeout(getInScanBinInputTimer.current);
                    setInScanBinCode(code);
                    if (code === "") return setBinID("");

                    getInScanBinInputTimer.current = setTimeout(async () => {
                        const params = {
                            request: "getBins",
                            status: "ACTIVE",
                            warehouseID: selectedWarehouse.warehouseID,
                            code: translateBinToEng(code, language)
                        };

                        const bins = await dispatch(makeErplyRequest(params, t("getBinsError"), null, null, null, false));

                        if (bins.length > 0) {
                            setBinID(bins[0].binID);
                        } else {
                            setBinID("");
                        }
                    }, 10);
                };

                return (
                    <div>
                        <label className={"inScanLabel"}>{t("bin")}:</label>
                        <Input ref={inScanBinInput} onFocus={() => inScanBinInput.current.select()} onInput={e => {handleInScanBinCodeInput(e.target.value)}} fluid placeholder={t('enterBin')} value={inScanBinCode} icon>
                            <Icon className="marginRightBig" name="question circle" link onClick={handleSuggestBinsClick}/>
                            <Icon className="marginRight" name={iconName} color={iconColour}/>
                            <Icon name='delete' link onClick={setReceivingAreaAsBin}/>
                            <input />
                        </Input>
                    </div>
                );
            }
        }
    };

    const handleSuggestBinsClick = async () => {
        const excludeBins = (bins) => {
            return bins.filter(bin => bin.code !== "receiving_area" && bin.code !== "cart" && !bin.code.startsWith("supplier"));
        };
        const getAllowedProductPreferredBins = () => {
            return dispatch(getBins(t, false, null, true, scannedProduct.code.trim())).then(bins => excludeBins(bins));
        };
        const getBinsWithScannedProduct = async () => {
            binQuantitiesOfScannedProduct = await dispatch(getBinQuantities(t, false, null, null, scannedProduct.productID));
            if (binQuantitiesOfScannedProduct.length === 0) return [];
            return dispatch(getBins(t, false, null, null, "", "", binQuantitiesOfScannedProduct.map(binQuantity => binQuantity.binID).join(","))).then(bins => excludeBins(bins));
        };

        if (!scannedProduct) return dispatch(errorMessageSet(t("noItemsScanned")));

        dispatch(setIsLoading(true));
        let binQuantitiesOfScannedProduct;
        const [allowedProductPreferredBins, binsWithScannedProduct] = await Promise.all([getAllowedProductPreferredBins(), getBinsWithScannedProduct()]);
        dispatch(setIsLoading(false));
        if (allowedProductPreferredBins.length === 0 && binsWithScannedProduct.length === 0) return dispatch(errorMessageSet(t("suggestedBinsNotFound")));

        const preferredBinsWithProduct = [];
        const unpreferredBinsWithProduct = [];
        const preferredBinsWithoutProduct = [];
        const unpreferredBinsWithoutProduct = [];

        binQuantitiesOfScannedProduct.forEach(binQuantity => {
            const bin = binsWithScannedProduct.find(bin => bin.binID == binQuantity.binID);

            if (bin) {
                if (binQuantity.amount > 0) {
                    bin.preferred == 1 ? preferredBinsWithProduct.push(bin) : unpreferredBinsWithProduct.push(bin);
                } else {
                    bin.preferred == 1 ? preferredBinsWithoutProduct.push(bin) : unpreferredBinsWithoutProduct.push(bin);
                }
            }
        });

        setSuggestedBinsData({
            preferredBinsWithProduct: preferredBinsWithProduct,
            unpreferredBinsWithProduct: unpreferredBinsWithProduct,
            allowedProductPreferredBins: allowedProductPreferredBins,
            preferredBinsWithoutProduct: preferredBinsWithoutProduct,
            unpreferredBinsWithoutProduct: unpreferredBinsWithoutProduct,
        });
    };

    const displaySuggestedBinsModal = () => {
        const getDisplayableBinList = (bins) => {
            return bins.length > 0 ? sortAlphabetically(bins).map(bin => <span className={"cursorPointer"} onClick={() => {
                setBinID(bin.id);
                setInScanBinCode(bin.code);
                setSuggestedBinsData(null);
            }}>{bin.code}</span>).reduce((prev, curr) => [prev, '; ', curr]) : [];
        };
        const sortAlphabetically = (bins) => {
            return bins.sort((a, b) => {
                const textA = a.code.toUpperCase();
                const textB = b.code.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
        };

        if (suggestedBinsData) {
            const preferredBinsWithProduct = getDisplayableBinList(suggestedBinsData.preferredBinsWithProduct);
            const unpreferredBinsWithProduct = getDisplayableBinList(suggestedBinsData.unpreferredBinsWithProduct);
            const allowedProductPreferredBins = getDisplayableBinList(suggestedBinsData.allowedProductPreferredBins);
            const preferredBinsWithoutProduct = getDisplayableBinList(suggestedBinsData.preferredBinsWithoutProduct);
            const unpreferredBinsWithoutProduct = getDisplayableBinList(suggestedBinsData.unpreferredBinsWithoutProduct);

            return <Modal size={"small"} open={suggestedBinsData} onClose={() => setSuggestedBinsData(false)}>
                <Modal.Header>{t("suggestedBins")}</Modal.Header>
                <Modal.Content>
                    <Table celled inverted unstackable>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell><div>
                                    <div><div className={"square floatLeft lightGreenBackgroundColor"}/><span>&nbsp;{t("binPriority1Explanation")}</span></div>
                                    <div><div className={"square floatLeft blueBackgroundColor"}/><span>&nbsp;{t("binPriority2Explanation")}</span></div>
                                    <div><div className={"square floatLeft goldBackgroundColor"}/><span>&nbsp;{t("binPriority3Explanation")}</span></div>
                                    <div><div className={"square floatLeft silverBackgroundColor"}/><span>&nbsp;{t("binPriority4Explanation")}</span></div>
                                    <div><div className={"square floatLeft bronzeBackgroundColor"}/><span>&nbsp;{t("binPriority5Explanation")}</span></div>
                                </div></Table.Cell>
                                <Table.Cell>{[
                                    <p key={"binPriority1"} className={"lightGreenColor"}>{preferredBinsWithProduct}</p>,
                                    <p key={"binPriority2"} className={"blueColor"}>{unpreferredBinsWithProduct}</p>,
                                    <p key={"binPriority3"} className={"goldColor"}>{allowedProductPreferredBins}</p>,
                                    <p key={"binPriority4"} className={"silverColor"}>{preferredBinsWithoutProduct}</p>,
                                    <p key={"binPriority5"} className={"bronzeColor"}>{unpreferredBinsWithoutProduct}</p>,
                                ]}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Modal.Content>
            </Modal>;
        }
    };

    const getMainBinOptions = () => {
        return scannedProduct !== null ? getBinOptions(scannedProduct.productID, true) : [];
    };

    const handlePackagingChange = (event, { value }) => {
        setPackaging(value);
    };

    const createChangeDocInfoBtn = () => {
        const colour = (isCorpowear(clientCode) || isNBQ(clientCode)) && isSalesOrder && (internalNotes !== "" || packageDesc !== "") ? "red" : "instagram";
        return canChangeDocInfo ?
            <Button className={"menuBtn"} color={colour} size={"large"} onClick={() => setShowExtraFields(!showExtraFields)}>{t("changeDocInfo")} </Button>
            : null;
    };

    const createCreateProductsBtn = () => {
        if (creatingProductsEnabled) {
            return <Button className={"menuBtn"} color={"facebook"} size={"large"} onClick={openCreateProductView}>{t("createProducts")} </Button>;
        }
    };

    const createSwitchViewBtn = () => {
        if (scanAsBatchAllowed) {
            const onClickFunction = () => {
                if (scanAsBatch && productsScannedAsBatch.length > 0) {
                    handleFinishWorkOnClick(true);
                }
                setScanAsBatch(!scanAsBatch);
            };

            const buttonText = scanAsBatch ? t("normalView") : t("batchView");
            return <Button className={"menuBtn"} color={"olive"} size={"large"} onClick={onClickFunction}>{buttonText} </Button>;
        }
    };

    const openCreateProductView = () => {
        dispatch(setEditableProduct(null));
        dispatch(componentSet("CreateEditProduct"));
    };

    const createDocInfoFields = () => {
        const createInputField = (labelText, setStateFunction, value) => {
            return (
                <div key={labelText}>
                    <label className={"inScanLabel"}>{`${t(labelText)}:`}</label>
                    <TextArea className={"fullWidth"} onInput={e => setStateFunction(e.target.value)} value={value}/>
                </div>
            )
        };

        const notesFieldDescription = isCreditInvoice || isSalesOrder ? "notesOnInvoice" : "notes";
        const extraFields = [createInputField(notesFieldDescription, setNotes, notes)];

        if (isCreditInvoice || isSalesOrder) {
            extraFields.push(createInputField("internalNotes", setInternalNotes, internalNotes));
            extraFields.push(createInputField("packageDesc", setPackageDesc, packageDesc),
                <div key={"deliveryType"}>
                    <label className={"inScanLabel"}>{t("deliveryType")}:</label>
                    <Dropdown
                        selection
                        options={getDeliveryTypeOptions()}
                        onChange={handleOnDeliveryTypeChange}
                        fluid
                        value={deliveryTypeID}
                    />
                </div>);
        }

        return (
            <Transition visible={showExtraFields} animation='zoom' duration={300}>
                <div id={"extraFields"}>
                    {extraFields}
                </div>
            </Transition>
        );
    };

    const handleAmountFocus = () => {
        amountInput.current.select();
    };

    const getClientName = () => {
        if (isSalesOrder) {
            return <p className={"smallWhiteText"}>{t("customer")}: {selectedDocuments[0].clientName}</p>;
        }
    };

    const createSerialNoField = () => {
        if (productNeedsSerialNo) {
            return (
                <div>
                    <label className={"inScanLabel"}>{t("serialNo")}:</label>
                    <Input ref={serialNoInput} onInput={e => setSerialNo(e.target.value)} fluid value={serialNo}/>
                </div>
            )
        }
    };

    const createBatchField = () => {
        if (batchesEnabled) {
            return (
                <div>
                    <label className={"inScanLabel"}>{t("batch")}:</label>
                    <Input onInput={e => setBatchCode(e.target.value)} fluid value={batchCode}/>
                </div>
            )
        }
    };

    const showLocationInWarehouse = () => {
        if (isSaadaKappiDocument) {
            let locationInWarehouse = "";

            if (scannedProduct !== null) {
                if (scannedProduct.locationInWarehouseText === "") {
                    locationInWarehouse = <b className={"redColor"}>Määramata</b>;
                } else if (scannedProduct.locationInWarehouseText === "TC2000") {
                    locationInWarehouse = <b className={"greenColor"}>{scannedProduct.locationInWarehouseText}</b>;
                } else {
                    locationInWarehouse = <b>{scannedProduct.locationInWarehouseText}</b>;
                }
            }

            if (locationInWarehouse === "") {
                return <p className={"smallWhiteText"}/>    // Just an empty line
            } else {
                return <p className={"smallWhiteText"}>Laoaadress: {locationInWarehouse}</p>
            }
        }
    };

    const createSaadaKappiButton = () => {
        if (isSaadaKappiDocument && isTc2000OnlyModalOptions.KAPP) {
            const hasTC2000Product = selectedDocumentsProducts.some(product => product.locationInWarehouseText === "TC2000" || product.locationInWarehouseID == akTc2000LocationInWarehouseId);

            if (hasTC2000Product) {
                const hasBeenSentToTC2000 = getApiAttributeValue("warehouseStatus", selectedDocuments[0].attributes) === "sentToWMS";

                if (!hasBeenSentToTC2000) {
                    return <SaadaKappiButton/>;
                }
            }
        }
    };

    const getCustomers = () => {
        const params = {
            request: "getCustomers",
            customerID: selectedDocuments[0].clientID,
            responseMode: "detail"
        };

        dispatch(makeErplyRequest(params, t("getCustomersError"), null, getCustomersSuccess, null, false, false));
    };

    const displayClientCardFields = () => {
        if (isPP && isOutScan && customer) {
            return <p className={"smallWhiteText"}>{t("bank")}: {customer.bankName}</p>;
        }
    };

    const createPrintLabelsBtn = (isTop = false, printInitialSubtractedAmounts = false) => {
        if (canLabelPrint && scannableDocRows && scannableDocRows.length > 0) {
            let classNames = "menuBtnHalfWidth marginBottomSmall";
            classNames += isTop ? "" : " marginTopSmall";
            classNames += printInitialSubtractedAmounts ? " margin0Right" : "";

            let products = [];
            if (printInitialSubtractedAmounts) {
                productIDsToLabelPrint.forEach(productID => {
                    const product = initialSubtractedRows.find(row => row.productID == productID);

                    if (product) {
                        for (let i = 0; i < product.amount; i++) {
                            products.push(product);
                        }
                    }
                });
            } else {
                products = productIDsToLabelPrint.map(productID => initialSubtractedRows.find(row => row.productID == productID));
            }

            const text = printInitialSubtractedAmounts ? t("printCheckedLabelsWithAmountsOnOrder") : t("printCheckedLabelsOneAtATime");
            const colour = printInitialSubtractedAmounts ? "purple" : "teal";
            return <PrintLabelsButton products={products} className={classNames} size={"large"} text={text} isCheckedProducts={true} colour={colour}/>;
        }
    };

    const closeChangeEanModal = () => {
        setChangeEanProductID(null);
        setChangeEanValue("");
        setDisplayChangeEanFirstConfirmation(false);
        setDisplayChangeEanSecondConfirmation(false);
    };

    const displayChangeEanModal = () => {
        return (
            <div>
                {/*Change Ean input modal*/}
                <Modal size={"mini"} open={changeEanProductID !== null && !displayChangeEanFirstConfirmation} onClose={closeChangeEanModal}>
                    <Modal.Header>{translateAccordingToCountry(t("changeEan"), confParameters)}</Modal.Header>
                    <Modal.Content>
                        <Input ref={changeEanInput} onInput={e => setChangeEanValue(e.target.value)} fluid value={changeEanValue}/>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={closeChangeEanModal} negative>{t("no")}</Button>
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content={t("yes")}
                            onClick={() => setDisplayChangeEanFirstConfirmation(true)}
                        />
                    </Modal.Actions>
                </Modal>
                {/*Change Ean first confirmation*/}
                <Modal size={"mini"} open={displayChangeEanFirstConfirmation && !displayChangeEanSecondConfirmation} onClose={closeChangeEanModal}>
                    <Modal.Header>{t("confirmation")}</Modal.Header>
                    <Modal.Content>
                        <p>{translateAccordingToCountry(t("changeEanAreYouSure?"), confParameters)}</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={closeChangeEanModal} negative>{t("no")}</Button>
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content={t("yes")}
                            onClick={() => setDisplayChangeEanSecondConfirmation(true)}
                        />
                    </Modal.Actions>
                </Modal>
                {/*Change Ean final confirmation*/}
                <Modal size={"mini"} open={displayChangeEanSecondConfirmation} onClose={closeChangeEanModal}>
                    <Modal.Header>{t("confirmation")}</Modal.Header>
                    <Modal.Content>
                        <p>{translateAccordingToCountry(t("changeEanAreYouAbsolutelySure?"), confParameters)}</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={closeChangeEanModal} negative>{t("no")}</Button>
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content={t("yes")}
                            onClick={() => {saveEan(changeEanProductID, changeEanValue); closeChangeEanModal()}}
                        />
                    </Modal.Actions>
                </Modal>
            </div>
        )
    };

    const displayIsTc2000OnlyModal = () => {
        const getModalButtons = () => {
            let buttons = [];
            if (isTc2000OnlyModalOptions.KAPP) {
                buttons.push(<Button key={"kappModalBtn"} onClick={() => {
                    setShowIsTc2000OnlyModal(false);
                    dispatch(setIsTc2000Only(true));
                    scanInput.current.focus();

                    const hasBeenSentToTC2000 = getApiAttributeValue("warehouseStatus", selectedDocuments[0].attributes) === "sentToWMS";
                    if (hasBeenSentToTC2000) {
                        dispatch(successMessageSet("See tellimus on juba kappi saadetud"));
                    }
                }} primary>KAPP</Button>);
            }
            if (isTc2000OnlyModalOptions.RIIUL) {
                buttons.push(<Button key={"riiulModalBtn"} onClick={() => {
                    setShowIsTc2000OnlyModal(false);
                    dispatch(setIsTc2000Only(false));
                    scanInput.current.focus();
                }} color={"instagram"}>RIIUL</Button>);
            }

            return buttons;
        };

        return (
            <Modal closeOnDimmerClick={false} size={"mini"} open={showIsTc2000OnlyModal} onClose={() => setShowIsTc2000OnlyModal(false)}>
                <Modal.Header>Kas KAPP või RIIUL?</Modal.Header>
                <Modal.Actions>
                    {getModalButtons()}
                </Modal.Actions>
            </Modal>
        )
    };

    const displayAssembledProducts = () => {
        if (isAssembly) {
            const getRecipeString = (productComponents) => {
                let recipe = [];
                for (let i = 0, n = productComponents.length; i < n; i++) {
                    const component = recipeProducts.find(product => product.productID == productComponents[i].componentID);
                    recipe.push(`${productComponents[i].amount} x ${component.name}`);
                }
                return `(${recipe.join("; ")})`;
            };

            const assembledProductsWithRecipes = rowsWithSummedProducts.map((product) => {
                const assemblyProduct = productsOnDocument.find(p => p.productID == product.productID);
                const recipe = assemblyProduct.hasOwnProperty("productComponents") ? getRecipeString(assemblyProduct.productComponents) : "";
                return <p key={`${product.productID}recipe`} className={"inScanLabel"}>{product.amount} x {assemblyProduct.name} {recipe}</p>
            });

            return (
                <Accordion inverted>
                    <Accordion.Title className={"inScanLabel boldText"} active={showAssemblyRecipe} onClick={() => setShowAssemblyRecipe(!showAssemblyRecipe)}>
                        <Icon name='dropdown' />
                        {t("assembledProducts")}
                    </Accordion.Title>
                    <Accordion.Content active={showAssemblyRecipe}>
                        {assembledProductsWithRecipes}
                    </Accordion.Content>
                </Accordion>
            )
        }
    };

    const getScannedProductLisaKappiButton = () => {
        return isLisaKappiProduct ? <Button className={"marginLeft"} key={"scannedProductLisaKappi"} size={"mini"} color={"green"} onClick={() => {handleLisaKappiClick(scannedProduct)}}>Lisa kappi</Button> : "";
    };

    const createDocRow = (row, index) => {
        if (row.hasOwnProperty("productIsNotOnDocument") && row.productIsNotOnDocument === 1) { // Do not display products not on document
            return null;
        } else {
            let docRowBtnClicked = false;   // Avoid selecting product

            const isSelected = isSelectedRow(row);
            const isBundle = rowIsBundle(row);
            const color = isBundle ? "violet" : isSelected ? "blue" : "black";
            const amount = roundFloatingPointError(row.amount);
            let displayedAmount = amount;

            const product = selectedDocumentsProducts.find(product => product.productID == row.productID);
            if (!product) {  // For some reason product has been deleted (possible with API only)
                dispatch(errorMessageSet(t("productNotFound")));
                return null;
            }

            let code2Cell = [];
            let code2Heading = <Table.Cell className={"tableHeading"} width={1}>{translateAccordingToCountry(t("EAN"), confParameters)}</Table.Cell>;

            if (confParameters.wmsEnableAddingCode2 == 0 || (product.code2 !== null && product.code2 !== "")) {
                code2Cell.push(product.code2);

                // Add "Change EAN" button or "Edit product" button (replaces "change EAN" button if both are enabled in settings)
                if (!isOutScan && (creatingProductsEnabled || confParameters.wmsEnableCode2Change == 1)) {
                    const buttonColour = creatingProductsEnabled ? "facebook" : "green";
                    const onClickFunction = creatingProductsEnabled ? () => {openEditProductView(product); docRowBtnClicked = true} :
                        () => {setChangeEanProductID(row.productID); setChangeEanValue(product.code2); docRowBtnClicked = true};

                    code2Heading = <Table.Cell className={"tableHeading"} width={1}>
                        <div className={"flex"}>
                            {translateAccordingToCountry(t("EAN"), confParameters)}
                            <Button className={"marginLeftSmall"} size={"mini"} color={buttonColour} icon={"pencil alternate"} onClick={onClickFunction}/>
                        </div>
                    </Table.Cell>
                }
            } else {
                const addEanBtnHasBeenClicked = addEanBtnClickedProductID == row.productID;

                if (addEanBtnHasBeenClicked) {
                    code2Cell.push([
                        <Input ref={addEanInput} onInput={e => setMissingEan(e.target.value)} value={missingEan} onClick={() => docRowBtnClicked = true}/>,
                        <Button className={"marginLeftSmall"} size={"mini"} color={"green"} icon={"check"}
                                onClick={() => {saveEan(row.productID, missingEan); docRowBtnClicked = true}}/>,
                        <Button size={"mini"} color={"red"} icon={"cancel"} onClick={() => {removeAddEanInput(); docRowBtnClicked = true}}/>,
                    ]);
                } else {
                    code2Cell.push(<Button key={`setEan${row.productID}`} size={"mini"} color={"green"}
                                           onClick={() => {setAddEanBtnClickedProductID(row.productID); docRowBtnClicked = true}}>{t("addEan")}</Button>);
                }
            }


            if (!isBundle && isRFix(clientCode)) {
                const locationInWarehouse = getLocationInWarehouseFromProductCard(product);

                if (locationInWarehouse !== "") {
                    code2Cell.push(<p key={`locationInWarehouse${row.productID}`} className={"floatRight boldText greenColor"}>{locationInWarehouse}</p>);
                }
            }

            const showPackageCalculations = confParameters.wmsShowPackageCalculations == 1;
            if (showPackageCalculations && selectedDocumentsProducts.length > 0) { // Wait for getProducts to finish
                displayedAmount = getPackageCalculation(row.productID, amount);
            }

            let code = getCode(row, "code");
            if (isAK && !isOutScan && product.supplierCode && product.supplierCode !== "") {
                code += ` [${product.supplierCode}]`;
            }
            let codeCell = [<p key={code}>{code}</p>];

            const productHasLocationInWarehouseText = product.locationInWarehouseText !== "" && product.locationInWarehouseText !== "määramata";
            if (!isOutScan && isAK && !productHasLocationInWarehouseText) {
                codeCell.push(<Button key={code + "btn"} className={"marginLeft"} size={"mini"} color={"green"}
                                      onClick={() => {handleLisaKappiClick(product); docRowBtnClicked = true}}>Lisa kappi</Button>);
            }

            if (canLabelPrint) {
                codeCell.push(<PrintLabelsButton products={[product]} className={"marginLeftSmall"} size={"mini"} text={t("printLabel")}
                     onClickExtraFunction={() => {docRowBtnClicked = true; setTimeout(() => docRowBtnClicked = false, 100)}}
                />);
            }

            const getLabelCheckbox = (productID) => {
                if (canLabelPrint) {
                    return <Checkbox className={"marginLeftSmall"} checked={productIDsToLabelPrint.includes(productID)}
                                     onChange={() => {checkRowToLabelPrint(productID); docRowBtnClicked = true}}/>;
                }
            };

            const name = getName(row);
            let nameCell = [<p key={name}>{name}</p>];
            if (isOutScan) {
                const handleScanLaterButtonClick = () => {
                    setScanLaterProducts([...scanLaterProducts, {productID: product.productID, name: name}]);
                    setSubtractedRowsSorted(false);
                };

                if (!scanLaterProducts.some(scanLaterProduct => scanLaterProduct.productID === product.productID && scanLaterProduct.name === name)) {
                    nameCell.push(<Button key={name + "scanLaterBtn"} className={"marginLeftSmall"} size={"mini"} color={"grey"}
                                          onClick={() => {handleScanLaterButtonClick(); docRowBtnClicked = true}}>{t("scanLater")}</Button>);
                }
            }

            const getBundleComponentRow = (component) => {
                const subtractedRow = subtractedRows.find(subtractedRow => subtractedRow.bundleID == row.productID && subtractedRow.bundleName === row.itemName &&
                    subtractedRow.productID == component.productID);

                if (subtractedRow) {
                    const componentAmount = subtractedRow.amount;
                    const componentIsSelected = isSelectedRow(component);
                    const componentCode2Cell = [component.code2];

                    if (isRFix(clientCode)) {
                        const componentWithExtraData = selectedDocumentsProducts.find(product => product.productID == component.productID);
                        const componentLocationInWarehouse = getLocationInWarehouseFromProductCard(componentWithExtraData);

                        if (componentLocationInWarehouse !== "") {
                            componentCode2Cell.push(<p key={`locationInWarehouse${component.productID}`} className={"floatRight boldText greenColor"}>{componentLocationInWarehouse}</p>);
                        }
                    }

                    return (
                        <Table key={`bundle_${index}_${component.productID}`} size={"small"} inverted={componentIsSelected} color="purple" celled structured unstackable
                               onClick={() => handleTableClick(component, docRowBtnClicked, true)}>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell className={"tableHeading"} width={1}>{translateAccordingToCountry(t("EAN"), confParameters)}</Table.Cell>
                                    <Table.Cell>{componentCode2Cell}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className={"tableHeading"} width={1}>{t("code")}</Table.Cell>
                                    <Table.Cell><div className={"flex"}>{component.code}</div></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell className={"tableHeading"} width={1}>{t("amount")}</Table.Cell>
                                    <Table.Cell>{componentAmount}</Table.Cell>
                                </Table.Row>
                                {getBinDocRow(component.productID, showPackageCalculations)}
                                <Table.Row>
                                    <Table.Cell className={"tableHeading"} width={1}>{t("name")}</Table.Cell>
                                    <Table.Cell><div className={"flex"}>{component.name}</div></Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    )
                }
            };

            const bundleComponentRows = isBundle ? row.productComponents.map(component => getBundleComponentRow(component)).filter(row => row !== undefined) : [];
            const bundleIsFullyScanned = isBundle && bundleComponentRows.length === 0;
            let amountCell = [displayedAmount];

            if (isBundle) {
                if (bundleIsFullyScanned) {
                    // Display "ASSEMBLED" after bundle's amount if all bundle's components have been scanned
                    amountCell.push(<span key={`assembledText${row.productID}`} className={"marginLeftSmall fontSizeMedSmall lightGreenColor boldText"}>{t("assembled").toUpperCase()}</span>)
                }

                // Add "Cancel assembly" button
                code2Cell.push(<Button key={`cancelAssembly${row.productID}`} className={"floatRight"} size={"mini"} color={"red"}
                                       onClick={() => {dispatch(setModal(t("confirmation"), t("confirmCancelAssembly?"), () => cancelAssembly(row))); docRowBtnClicked = true}}>{t("cancelAssembly")}</Button>);

                // Add "Change amount" button
                amountCell.push(<Button key={`changeAmount${row.productID}`} className={"floatRight"} size={"mini"} color={"yellow"}
                                        onClick={() => {dispatch(setModal(t("enterNewAmount"), "", (newAmount) => changeBundleAmount(row, newAmount, amount), true, amount)); docRowBtnClicked = true}}>{t("changeAmount")}</Button>);
            }

            let displayableRow = [
                <Table key={index} inverted={isSelected || isBundle} color={color} celled structured unstackable
                       onClick={() => handleTableClick(row, docRowBtnClicked || bundleIsFullyScanned, false)}>
                    <Table.Body>
                        <Table.Row>
                            {code2Heading}
                            <Table.Cell>{code2Cell}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell className={"tableHeading"} width={1}>{t("code")}{getLabelCheckbox(row.productID)}</Table.Cell>
                            <Table.Cell><div className={"flex"}>{codeCell}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell className={"tableHeading"} width={1}>{t("amount")}</Table.Cell>
                            <Table.Cell>{amountCell}</Table.Cell>
                        </Table.Row>
                        {getBinDocRow(row.productID, showPackageCalculations, isBundle)}
                        <Table.Row>
                            <Table.Cell className={"tableHeading"} width={1}>{t("name")}</Table.Cell>
                            <Table.Cell><div className={"flex"}>{nameCell}</div></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            ];

            if (isBundle && bundleComponentRows.length > 0) {
                displayableRow.push(
                    <Transition visible={(selectedBundle && selectedBundle.productID == row.productID && selectedBundle.itemName === row.itemName) || isSelected} animation='fade down' duration={200}>
                        <div>{bundleComponentRows}</div>
                    </Transition>
                );
            }

            return displayableRow;
        }
    };

    const changeBundleAmount = async (bundle, newAmount, oldAmount) => {
        if (oldAmount != newAmount) {
            const binRecordIDs = binRecords.filter(binRecord => binRecord.bundleID == bundle.productID && binRecord.bundleName === bundle.itemName).map(binRecord => binRecord.id);

            if (binRecordIDs.length > 0) {
                dispatch(errorMessageSet(t("bundleAmountMayNotBeChangedIfComponentsHaveBeenScanned")));
            } else if (isNotAPositiveInteger(newAmount)) {
                dispatch(errorMessageSet(t("notAPositiveInteger")));
            } else {
                if (newAmount > oldAmount) {
                    const fulfillableOrder = fulfillableOrders.find(order => order.id == selectedDocuments[0].id);
                    const bundleOnFulfillableOrder = fulfillableOrder.rows.find(row => row.productId == bundle.productID && row.productName === bundle.itemName);

                    if (bundleOnFulfillableOrder.amountInStock < newAmount) {
                        dispatch(errorMessageSet(t("bundleMayNotBeAssembledWithNewAmount")));
                    } else {
                        dispatch(setNewAmountToProductInRowsWithSummedProducts(bundle.productID, newAmount, true, bundle.itemName));
                    }
                } else {
                    dispatch(setNewAmountToProductInRowsWithSummedProducts(bundle.productID, newAmount, true, bundle.itemName));
                }
            }
        }
    };

    const cancelAssembly = async (bundle) => {
        const binRecordIDs = binRecords.filter(binRecord => binRecord.bundleID == bundle.productID && binRecord.bundleName === bundle.itemName).map(binRecord => binRecord.id);
        if (binRecordIDs.length > 0) {
            const requests = [];
            binRecordIDs.forEach(binRecordID => {
                requests.push(dispatch(makeWmsApiRequest(t, `bin-inventory-record/${binRecordID}`, "DELETE", t("deleteBinRecordError"))));
            });
            dispatch(setIsLoading(true));
            const responses = await Promise.all(requests);
            dispatch(setIsLoading(false));

            if (!responses.some(response => response.status === "error")) {
                dispatch(successMessageSet(t("bundleAssemblyCancelled")));
            }

            syncQuantitiesOnDocument(selectedDocumentsProducts);
        } else {
            dispatch(errorMessageSet(t("noScannedComponentsFound")));
        }
    };

    const displayScannableDocRows = () => {
        return scannableDocRows;
    };

    const scannableDocRows = useMemo(() => {
        if (subtractedRows.length > 0) {
            let displayableRows = subtractedRows;
            displayableRows = displayableRows.filter(row => !isBundleComponent(row));

            if (isSaadaKappiDocument) {
                if (isTc2000Only) {
                    displayableRows = displayableRows.filter(row => rowHasTc2000Product(selectedDocumentsProducts, row));
                } else {
                    displayableRows = displayableRows.filter(row => !rowHasTc2000Product(selectedDocumentsProducts, row));
                }
            }

            return displayableRows.map((row, index) => createDocRow(row, index));
        }
    }, [subtractedRows, selectedRow, selectedDocumentsProducts, rowSelectedBins, addEanBtnClickedProductID,
        productIDsToLabelPrint, isTc2000Only, changeEanProductID, modalMessage, confParameters,
        missingEan, subtractedRowsSorted, language, selectedBundle, modalHeading]);

    // Rows with scanned products from all previous scan sessions subtracted
    const initialSubtractedRows = useMemo(() => {
        return isOutScan && !isInventoryTransfer && !isPurchaseReturn ? summedRows : getInitialSubtractedRows(summedScannedProducts);
    }, [summedRows, summedScannedProducts]);

    const handleSetAmount = (amount) => {
        const maxAmountAllowedToScan = confParameters.wmsMaxAmountAllowedToScan;
        const amountIsDecimal = amount.includes(".");
        const amountStartsWith0 = amount.substr(0, 1) == 0;

        if (maxAmountAllowedToScan !== "" && amount > Number(maxAmountAllowedToScan)) {
            setAmount(0);
        } else if (amount.length > 1 && amountStartsWith0 && !amountIsDecimal) {
            setAmount(amount.substr(1));
        } else {
            setAmount(amount);
        }
    };

    const openEditProductView = (product) => {
        dispatch(setEditableProduct(product));
        dispatch(componentSet("CreateEditProduct"));
    };

    const getDeliveryTypeOptions = () => {
        const nullOption = {key: 0, text: "", value: 0};
        let options = deliveryTypes.map(deliveryType => getDeliveryTypeOption(deliveryType));
        options = options.filter(deliveryTypeOption => !deliveryTypeOption.text.startsWith("SMARTPOST - ") || // These delivery types require parcel terminals to be selected
            deliveryTypeOption.value === selectedDocuments[0].deliveryTypeID);  // Leave delivery type option if it is the one saved on document
        options.unshift(nullOption);

        return options;
    };

    const getDeliveryTypeOption = (deliveryType) => {
        return {key: deliveryType.deliveryTypeID, text: deliveryType.name, value: deliveryType.deliveryTypeID};
    };

    const handleOnDeliveryTypeChange = (event, { value }) => {
        setDeliveryTypeID(value);
    };

    const createPackagingDropdown = () => {
        return (
            <div>
                <label className={"inScanLabel"}>{t("packaging")}:</label>
                <Dropdown
                    id='packagingSelect'
                    value={packaging}
                    fluid
                    selection
                    options={packagingOptions}
                    onChange={handlePackagingChange}
                    search
                    noResultsMessage={t("noResultsFound")}
                    disabled={scannedProductIsPackage}
                />
            </div>
        )
    };

    const createScanAsBatchInterface = () => {
        if (productsScannedAsBatch.length > 0) {
            const totalAmount = productsScannedAsBatch.reduce((accumulator, product) => accumulator + product.amount, 0);
            return (
                <Table celled unstackable compact={"very"} size={"small"}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{t("scannedCode")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("code")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("packaging")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("amount")}</Table.HeaderCell>
                            <Table.HeaderCell/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {createScanAsBatchRows()}
                        <Table.Row key={`scanAsBatchTotalRow`}>
                            <Table.Cell/>
                            <Table.Cell/>
                            <Table.Cell><b>{t("total")}</b></Table.Cell>
                            <Table.Cell>{totalAmount}</Table.Cell>
                            <Table.Cell/>
                        </Table.Row>
                    </Table.Body>
                </Table>
            )
        }
    };

    const createScanAsBatchRows = () => {
        return productsScannedAsBatch.map((product) => createScanAsBatchRow(product));
    };

    const createScanAsBatchRow = (product) => {
        return (
            <Table.Row key={`scanAsBatchRow_${product.productID}_${product.packageType}_${product.scannedCode}`}>
                <Table.Cell>{product.scannedCode}</Table.Cell>
                <Table.Cell>{product.code}</Table.Cell>
                <Table.Cell>{product.packageType}</Table.Cell>
                <Table.Cell>{product.amount}</Table.Cell>
                <Table.Cell className={"smallCell"}><Button size={"tiny"} icon onClick={() => deleteScanAsBatchRow(product)}><Icon name='delete'/></Button></Table.Cell>
            </Table.Row>
        )
    };

    const deleteScanAsBatchRow = (product) => {
        let productsScannedAsBatchCopy = productsScannedAsBatch.map(a => ({...a}));    // Clone array

        for (let i = 0, n = productsScannedAsBatchCopy.length; i < n; i++) {
            if (productsScannedAsBatchCopy[i].productID == product.productID && productsScannedAsBatchCopy[i].packageType == product.packageType &&
                productsScannedAsBatchCopy[i].scannedCode == product.scannedCode) {
                productsScannedAsBatchCopy.splice(i, 1);
                break;
            }
        }

        setProductsScannedAsBatch(productsScannedAsBatchCopy);
    };

    const createRegularInterface = () => {
        return (
            <div>
                {createPackagingDropdown()}
                <label className={"inScanLabel"}>{t("amount")}:</label>
                <Input ref={amountInput} type="number" onFocus={handleAmountFocus} onInput={e => handleSetAmount(e.target.value)} fluid value={amount}/>
                {createMainBinField()}
                {createSerialNoField()}
                {createBatchField()}
            </div>
        )
    };

    const createFinishWorkButton = () => {
        let addAmountsToBin, buttonText;
        if (scanAsBatch && productsScannedAsBatch.length > 0) {
            addAmountsToBin = true;
            buttonText = t("nextBatch");
        } else {
            addAmountsToBin = false;
            buttonText = isTAF(clientCode) ? t("nextStep") : t("finishWork");
        }

        return <Button className={"menuBtnHalfWidth margin0Right"} color={"green"} size={"large"} onClick={() => handleFinishWorkOnClick(addAmountsToBin)}>{buttonText}</Button>
    };

    const documentHasJustBeenOpened = () => {
        return followUpDocuments === null;
    };

    return (
        <div className={"overflow"}>
            <div>
                {getClientName()}
                <Input id={"scanInput"} ref={scanInput} onInput={e => {setScannedCode(e.target.value); setSelectedRow(null)}} fluid placeholder={t('scanProduct')} value={scannedCode}
                       icon={<Icon name='delete' link onClick={clearInputs}/>} autoComplete="off"/>
                {getScannedProductName()}
                {showLocationInWarehouse()}
                {scanAsBatch ? createScanAsBatchInterface() : createRegularInterface()}
            </div>
            <div className={"grid"}>
                {displayClientCardFields()}
                {displayLastScannedProduct()}
                <div className={"btnsGroup"}>
                    <div className={"flexCenter"}>
                        <Button className={"menuBtnHalfWidth"} primary size={"large"} onClick={handleOkOnClick}>Ok</Button>
                        {createFinishWorkButton()}
                    </div>
                    {createSaadaKappiButton()}
                    {createChangeDocInfoBtn()}
                    {createDocInfoFields()}
                    {createCreateProductsBtn()}
                    <div className={"flexCenter"}>
                        <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                        <GoBackToStartBtn extraOnClickFunction={() => updateScannedBatches(true)}/>
                    </div>
                    {/*{createSwitchViewBtn()}*/}
                </div>
            </div>
            <div className={"flexCenter"}>
                {createPrintLabelsBtn(true)}
                {createPrintLabelsBtn(true, true)}
            </div>
            <div id={"documentRowsTable"}>
                {displayAssembledProducts()}
                {displayScannableDocRows()}
            </div>
            <div className={"flexCenter"}>
                {createPrintLabelsBtn()}
                {createPrintLabelsBtn(false, true)}
            </div>
            {displayChangeEanModal()}
            {displayIsTc2000OnlyModal()}
            {displaySuggestedBinsModal()}
        </div>
    );
};

export default Scan
