import React, {useState} from 'react';
import {Button} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {componentSet, previousComponentSet, setIsLoading} from "../actions/component";
import {useDispatch, useSelector} from "react-redux";
import GoBackToStartBtn from "./GoBackToStartBtn";
import {setBinBeingEdited} from "../actions/binManagement";
import GoBackBtn from "./GoBackBtn";
import {getBins, getWmsApiBinQuantities, makeErplyRequest} from "../util/erplyRequests";
import {errorMessageSet} from "../actions/headerMessage";
import ReactExport from "react-data-export";
import * as XLSX from 'xlsx';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const BinManagement = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [binQuantitiesExcelDataset, setBinQuantitiesExcelDataset] = useState([]);
    const [binQuantitiesExcelDatasetBinCodes, setBinQuantitiesExcelDatasetBinCodes] = useState([]);

    const downloadBinQuantitiesExcelBtnRef = React.useRef(null);

    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);

    const handleAddBinOnClick = () => {
        dispatch(previousComponentSet("BinManagement"));
        dispatch(setBinBeingEdited(null));  // AddBin is also used for editing existing bin in ChangeBin
        dispatch(componentSet("AddBin"));
    };

    const handleChangeDeleteBinOnClick = () => {
        dispatch(componentSet("ChangeBin"));
    };

    const handleImportBinsOnClick = () => {
        dispatch(componentSet("ImportBins"));
    };

    const handleImportBinQuantitiesOnClick = () => {
        dispatch(componentSet("ImportBinQuantities"));
    };

    const handleImportBinOrderOnClick = () => {
        dispatch(componentSet("ImportBinOrder"));
    };

    const getBinQuantities = (bins) => {
        const params = {
            request: "getBinQuantities",
            minimumAmount: 0.000001,
            warehouseID: selectedWarehouse.warehouseID
        };

        if (bins) {
            params.binIDs = bins.map(bin => bin.binID).join(",");
        }

        return dispatch(makeErplyRequest(params, t("getBinQuantitiesError"), null, null, null, true));
    };

    const handleExportBinsDataOnClick = async () => {
        const getQuantitiesInBinCellData = (binID) => {
            const quantities = binQuantities.filter(quantity => quantity.binID == binID);
            let quantitiesInBinCellData = quantities.map(quantity => `${quantity.productCode1}:${quantity.amount}`).join(";");
            const maxCharsInExcelCell = 32767;
            return quantitiesInBinCellData.substring(0, maxCharsInExcelCell);
        };

        dispatch(setIsLoading(true));

        const [bins, binQuantities] = await Promise.all([
            dispatch(getBins(t)),
            dispatch(getWmsApiBinQuantities(t, false, null, null, null, 0.000001))
        ]);

        if (bins.length === 0) {
            dispatch(setIsLoading(false));
            return dispatch(errorMessageSet("noBinsFound"));
        }

        const headers = [
            t("name"),
            t("type"),
            t("pickupNo"),
            t("replenishmentMinimum"),
            t("maximumAmount"),
            t("pickupNo"),
            t("preferredProductCodes"),
            t("quantitiesInBin"),
        ];

        const reportRows = [headers];

        for (let i = 0, n = bins.length; i < n; i++) {
            reportRows.push([
                bins[i].code,
                bins[i].preferred ? t("preferred") : t("unpreferred"),
                bins[i].binOrder,
                bins[i].replenishmentMinimum,
                bins[i].maximumAmount,
                bins[i].allowedProduct,
                bins[i].equipmentNeeded,
                getQuantitiesInBinCellData(bins[i].binID)
            ]);
        }

        const sheetName = t("bins");
        const fileName = `${sheetName}.xlsx`;
        const book = XLSX.utils.book_new();
        const sheet = XLSX.utils.aoa_to_sheet(reportRows);
        XLSX.utils.book_append_sheet(book, sheet, sheetName);
        XLSX.writeFile(book, fileName);

        dispatch(setIsLoading(false));
    };

    const handleExportBinQuantitiesDataOnClick = async () => {
        dispatch(setIsLoading(true));
        const binQuantities = await dispatch(getWmsApiBinQuantities(t, false, null, null, null, 0.000001));
        const products = [...new Map(binQuantities.map(binQuantity => [binQuantity.productID, binQuantity])).values()];
        const binCodes = [...new Set(binQuantities.map(quantity => quantity.binCode))];
        console.log(products)
        console.log(binCodes)
        throw new Error()

        const binCodeChunks = [];
        const maxNoOfBinCodesPerChunk = 300;
        for (let i = 0, n = binCodes.length; i < n; i += maxNoOfBinCodesPerChunk) {
            binCodeChunks.push(binCodes.slice(i , i + maxNoOfBinCodesPerChunk));
        }

        const productChunks = [];
        const maxNoOfProductsPerChunk = 10000;
        for (let i = 0, n = products.length; i < n; i += maxNoOfProductsPerChunk) {
            productChunks.push(products.slice(i , i + maxNoOfProductsPerChunk));
        }

        binCodeChunks.forEach(binCodeChunk => {
            productChunks.forEach(productChunk => {
                const headers = [
                    t("productID"),
                    t("productCode"),
                    t("name"),
                    ...binCodeChunk
                ];

                const reportRows = [headers];

                for (let i = 0, n = productChunk.length; i < n; i++) {
                    const reportRow = [
                        productChunk[i].productID,
                        productChunk[i].productCode1,
                        productChunk[i].name,
                    ];

                    const binCodesToQuantities = {};

                    for (let j = 0, n = binQuantities.length; j < n; j++) {
                        if (binQuantities[j].productID === productChunk[i].productID && binCodeChunk.includes(binQuantities[j].binCode)) {
                            binCodesToQuantities[binQuantities[j].binCode] = binQuantities[j].amount;
                        }
                    }

                    for (let j = 0, n = binCodeChunk.length; j < n; j++) {
                        const amount = binCodesToQuantities.hasOwnProperty(binCodeChunk[j]) ? binCodesToQuantities[binCodeChunk[j]] : 0;
                        reportRow.push(amount);
                    }

                    console.log(`Report row no ${i + 1} of ${n} done`);
                    reportRows.push(reportRow);

                    // for (let j = 0, n = binCodes.length; j < n; j++) {
                    //     const binQuantity = binQuantities.find(binQuantity => binQuantity.productID === products[i].productID && binQuantity.binCode === binCodes[j]);
                    //     console.log(binQuantity)
                    //     excelRowData[binCodes[j]] = binQuantity ? binQuantity.amount : 0;
                    // }
                    // for (let j = 0, n = binQuantities.length; j < n; j++) {
                    //     if (binQuantities[j].productID === products[i].productID && binCodes.includes(binQuantities[j].binCode)) {
                    //         excelRowData[binQuantities[j].binCode] = binQuantities[j].amount;
                    //     }
                    // }
                    //
                    // for (let j = 0, n = binCodes.length; j < n; j++) {
                    //     if (!excelRowData.hasOwnProperty(binCodes[j])) {
                    //         excelRowData[binCodes[j]] = 0;
                    //     }
                    // }
                    //
                    // dataset.push(excelRowData);
                    // console.log(excelRowData)
                }

                const sheetName = t("binQuantities");
                const fileName = `${sheetName}.xlsx`;
                const book = XLSX.utils.book_new();
                const sheet = XLSX.utils.aoa_to_sheet(reportRows);
                XLSX.utils.book_append_sheet(book, sheet, sheetName);
                XLSX.writeFile(book, fileName);
            });
        });

        dispatch(setIsLoading(false));

        // let binCodeChunks = [];
        // // const maxNoOfBinCodesPerChunk = 1;    // The library used might not handle larger files
        // const maxNoOfBinCodesPerChunk = 300;    // The library used might not handle larger files
        // for (let i = 0, n = binCodes.length; i < n; i += maxNoOfBinCodesPerChunk) {
        //     binCodeChunks.push(binCodes.slice(i , i + maxNoOfBinCodesPerChunk));
        // }
        // products = products.slice(0,1)

        // dispatch(setIsLoading(true));
        // for (let i = 0, n = binCodeChunks.length; i < n; i++) {
        //     setBinQuantitiesExcelDatasetBinCodes(binCodeChunks[i]);
        //     setBinQuantitiesExcelDataset(getBinQuantitiesExcelDataset(products, binQuantities, binCodeChunks[i]));
        //     downloadBinQuantitiesExcelBtnRef.current.click();
        //     if (i !== n - 1) {
        //         await new Promise(resolve => setTimeout(resolve, 7000));
        //     }
        // }
        // dispatch(setIsLoading(false));
    };

    const getBinQuantitiesExcelDataset = (products, binQuantities, binCodes) => {
        let dataset = [];

        for (let i = 0, n = products.length; i < n; i++) {
            const excelRowData = {
                productID: products[i].productID,
                productCode: products[i].productCode1,
                productName: products[i].name
            };

            // for (let j = 0, n = binCodes.length; j < n; j++) {
            //     const binQuantity = binQuantities.find(binQuantity => binQuantity.productID === products[i].productID && binQuantity.binCode === binCodes[j]);
            //     console.log(binQuantity)
            //     excelRowData[binCodes[j]] = binQuantity ? binQuantity.amount : 0;
            // }
            for (let j = 0, n = binQuantities.length; j < n; j++) {
                if (binQuantities[j].productID === products[i].productID && binCodes.includes(binQuantities[j].binCode)) {
                    excelRowData[binQuantities[j].binCode] = binQuantities[j].amount;
                }
            }

            for (let j = 0, n = binCodes.length; j < n; j++) {
                if (!excelRowData.hasOwnProperty(binCodes[j])) {
                    excelRowData[binCodes[j]] = 0;
                }
            }

            dataset.push(excelRowData);
            console.log(excelRowData)
        }
        console.log(dataset)
        return dataset;
    };

    const generateBinQuantitiesExcelColumns = () => {
        let columns = [
            <ExcelColumn label={t("productID")} value="productID"/>,
            <ExcelColumn label={t("productCode")} value="productCode"/>,
            <ExcelColumn label={t("name")} value="productName"/>
        ];

        binQuantitiesExcelDatasetBinCodes.forEach(code => columns.push(<ExcelColumn label={code} value={code}/>));

        return columns;
    };

    const handleGoBackOnClick = () => {
        dispatch(componentSet("Settings"));
    };

    return (
        <div>
            <Button className={"menuBtn"} onClick={handleAddBinOnClick} size='big'>{t("AddBin")}</Button>
            <Button className={"menuBtn"} onClick={handleChangeDeleteBinOnClick} size='big'>{t("ChangeBin")}</Button>
            <Button className={"menuBtn"} onClick={handleImportBinsOnClick} size='big'>{t("ImportBins")}</Button>
            <Button className={"menuBtn"} onClick={handleImportBinQuantitiesOnClick} size='big'>{t("ImportBinQuantities")}</Button>
            <Button className={"menuBtn"} onClick={handleImportBinOrderOnClick} size='big'>{t("ImportBinOrder")}</Button>
            <Button className={"menuBtn"} onClick={handleExportBinsDataOnClick} size='big'>{t("exportBinsDataToExcel")}</Button>
            <Button className={"menuBtn"} onClick={handleExportBinQuantitiesDataOnClick} size='big'>{t("exportBinQuantitiesDataToExcel")}</Button>
            <ExcelFile filename={"binQuantities"} element={<button ref={downloadBinQuantitiesExcelBtnRef} className={"hidden"}/>}>
                <ExcelSheet data={binQuantitiesExcelDataset} name={t("binQuantities")}>
                    {generateBinQuantitiesExcelColumns()}
                </ExcelSheet>
            </ExcelFile>
            <div className={"flex flexCenter"}>
                <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                <GoBackToStartBtn/>
            </div>
        </div>
    );
};

export default BinManagement
