import React, {useState, useEffect, useRef} from 'react';
import {Button, Input, Label, Radio} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {componentSet, setSequence} from "../actions/component";
import GoBackToStartBtn from "./GoBackToStartBtn";
import {errorMessageSet, successMessageSet} from "../actions/headerMessage";
import {deleteBin, makeErplyRequest, responseHasErrors, saveBin} from "../util/erplyRequests";
import GoBackBtn from "./GoBackBtn";
import {setModal} from "../actions/modal";

const AddBin = () => {
    const previousComponent = useSelector(state => state.componentReducer.previousComponent);
    const binBeingEdited = useSelector(state => state.binManagementReducer.binBeingEdited);
    const componentSequence = useSelector(state => state.componentReducer.componentSequence);

    const isChangeBin = binBeingEdited !== null;

    const [binCode, setBinCode] = useState(isChangeBin ? binBeingEdited.code : "");
    const [productCodes, setProductCodes] = useState(isChangeBin ? binBeingEdited.allowedProduct : "");
    const [pickupNo, setPickupNo] = useState(isChangeBin ? binBeingEdited.binOrder : 0);
    const [replenishmentMinimum, setReplenishmentMinimum] = useState(isChangeBin ? binBeingEdited.replenishmentMinimum : 0);
    const [maximumAmount, setMaximumAmount] = useState(isChangeBin ? binBeingEdited.maximumAmount : 0);
    const [binType, setBinType] = useState(isChangeBin ? binBeingEdited.preferred == 1 ? "preferred" : "unpreferred" : "unpreferred");
    const [equipmentNeeded, setEquipmentNeeded] = useState(isChangeBin ? binBeingEdited.equipmentNeeded : "");

    const binCodeInput = useRef(null);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (isChangeBin) {
            dispatch(setSequence(componentSequence.slice(0, componentSequence.length - 1)));    // Remove "AddBin"
        }
        binCodeInput.current.focus();
    }, []);

    const goBack = () => {
        dispatch(componentSet(previousComponent));
        if (isChangeBin) {
            const newSequence = componentSequence.slice();
            newSequence.push("ChangeBin");
            dispatch(setSequence(newSequence));
        }
    };

    const handleSaveButtonClick = async () => {
        if (binCode === "") {
            return dispatch(errorMessageSet(t("pleaseEnterBin")));
        } else if (productCodes !== "" && productCodes.includes(" ")) {
            return dispatch(errorMessageSet(t("noSpacesAllowed")));
        } else if (equipmentNeeded !== "" && equipmentNeeded.includes(", ")) {
            return dispatch(errorMessageSet(t("noSpacesAllowedEquipmentNeeded")));
        }

        const preferred = binType === "preferred";
        const binID = isChangeBin ? binBeingEdited.id : null;
        const saveBinResponse = await dispatch(saveBin(t, true, binCode, preferred, pickupNo, maximumAmount,
            replenishmentMinimum, productCodes, equipmentNeeded, binID));

        if (!responseHasErrors(saveBinResponse)) {
            dispatch(successMessageSet(t("binSaved")));

            if (isChangeBin) {
                goBack();
            } else {
                setBinCode("");
                binCodeInput.current.focus();
            }
        }
    };

    const handleRadioChange = (e, { value }) => {
        setBinType(value);
    };

    const createField = (labelText, ref, setStateFunction, value, type) => {
        return (
            <div className={"searchRow"}>
                <Label className={"searchLabel"} size={"large"} pointing={"right"}>{t(labelText)}</Label>
                <Input ref={ref} className={"searchInput"} type={type} onInput={e => setStateFunction(e.target.value)} fluid value={value}/>
            </div>
        )
    };

    const checkForExistingProductsInBin = async () => {
        const binQuantities = await dispatch(makeErplyRequest({
            request: "getBinQuantities",
            binIDs: binBeingEdited.binID,
            minimumAmount: 0.000001
        }));

        if (binQuantities.length > 0) {
            return dispatch(errorMessageSet(t("removeProductsBeforeBinDelete")));
        }

        dispatch(setModal(t("confirmation"), t("areYouSureDeleteBin"), hardDeleteBin));
    };

    const hardDeleteBin = async () => {
        const response = await dispatch(deleteBin(t, true, binBeingEdited.id));

        if (!responseHasErrors(response)) {
            dispatch(successMessageSet(t("binDeleted")));
            goBack();
        }
    };

    const createDeleteButton = () => {
        if (isChangeBin) {
            return <Button className={"menuBtn"} color={"red"} size={"big"} onClick={checkForExistingProductsInBin}>{t("delete")} </Button>
        }
    };

    return (
        <div>
            <div className={"searchRow"}>
                <Label className={"searchLabel"} size={"large"} pointing={"right"}>{t("type")}</Label>
                <div className={"radioBtns"}>
                    <Radio
                        label={<label className={"settingsCheckboxLabel"}>{t("preferred")}</label>}
                        value={"preferred"}
                        checked={binType === "preferred"}
                        onChange={handleRadioChange}
                    />
                    <Radio
                        className={"marginLeft"}
                        label={<label className={"settingsCheckboxLabel"}>{t("unpreferred")}</label>}
                        value={"unpreferred"}
                        checked={binType === "unpreferred"}
                        onChange={handleRadioChange}
                    />
                </div>
            </div>
            {createField("name", binCodeInput, setBinCode, binCode, "string")}
            {createField("productCodes", null, setProductCodes, productCodes, "string")}
            {createField("pickupNo", null, setPickupNo, pickupNo, "number")}
            {createField("replenishmentMinimum", null, setReplenishmentMinimum, replenishmentMinimum, "number")}
            {createField("maximumAmount", null, setMaximumAmount, maximumAmount, "number")}
            {createField("equipmentNeeded", null, setEquipmentNeeded, equipmentNeeded, "string")}
            <div className={"btnsGroup"}>
                <Button className={"menuBtn"} color={"blue"} size={"big"} onClick={handleSaveButtonClick}>{t("save")} </Button>
                {createDeleteButton()}
                <div className={"flex flexCenter"}>
                    <GoBackBtn handleGoBackOnClick={goBack}/>
                    <GoBackToStartBtn/>
                </div>
            </div>
        </div>
    );
};

export default AddBin
