import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getReasonCodes, makeErplyRequest} from "../util/erplyRequests";
import {
    setBinQuantities,
    setChangedBinQuantities,
    setExpandedProductIDs,
    setExpandedStocktakingIDs,
    setInitialBinQuantities, setBinCodeInput,
    setProductIDsOfBinQuantitiesRequested,
    setProducts,
    setStocktakingReadings,
    setStocktakings, setFromDate, setProductStock
} from "../actions/stocktakings";
import {
    deepCopy,
    isDateObject,
    isSelectedRow, roundFloatingPointError,
    timestampToDateString,
    translateAccordingToCountry,
    translateBinFromEng
} from "../util/misc";
import {Button, Icon, Input, Table} from "semantic-ui-react";
import {componentSet, setIsLoading} from "../actions/component";
import GoBackBtn from "./GoBackBtn";
import {errorMessageSet, successMessageSet} from "../actions/headerMessage";
import {setModal} from "../actions/modal";
import DatePickerComponent from "./DatePickerComponent";

const Stocktakings = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const stockTakings = useSelector(state => state.stocktakingsReducer.stocktakings);
    const stocktakingReadings = useSelector(state => state.stocktakingsReducer.stocktakingReadings);
    const products = useSelector(state => state.stocktakingsReducer.products);
    const binQuantities = useSelector(state => state.stocktakingsReducer.binQuantities);
    const productStock = useSelector(state => state.stocktakingsReducer.productStock);
    const expandedStocktakingIDs = useSelector(state => state.stocktakingsReducer.expandedStocktakingIDs);
    const expandedProductIDs = useSelector(state => state.stocktakingsReducer.expandedProductIDs);
    const productIDsOfBinQuantitiesRequested = useSelector(state => state.stocktakingsReducer.productIDsOfBinQuantitiesRequested);  // Required so that bin quantities of products with 0 bin quantities don't get re-requested
    const initialBinQuantities = useSelector(state => state.stocktakingsReducer.initialBinQuantities);
    const changedBinQuantities = useSelector(state => state.stocktakingsReducer.changedBinQuantities);
    const binCodeInput = useSelector(state => state.stocktakingsReducer.binCodeInput);
    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const user = useSelector(state => state.verifyUserReducer.user);
    const reasonCodes = useSelector(state => state.getReasonCodesReducer.reasonCodes);
    const language = useSelector(state => state.languageReducer.language);
    let fromDate = useSelector(state => state.stocktakingsReducer.fromDate);
    if (fromDate !== null && !isDateObject(fromDate)) fromDate = new Date(fromDate);   // Convert to date if value came from persisted session storage

    useEffect(() => {
        getStocktakings(fromDate, true);
        dispatch(getReasonCodes(t));
    }, []);

    const getStocktakings = async (date, isInitialRequest = false) => {
        const params = {
            request: "getStocktakings",
            status: "CONFIRMED"
        };

        if (date !== null) {
            params.changedSince = + date / 1000;
        }

        let stockTakings = await dispatch(makeErplyRequest(params, t("getStocktakingsError"), null, null, null, true));
        stockTakings = stockTakings.filter(stockTaking => stockTaking.warehouses == selectedWarehouse.warehouseID);
        dispatch(setStocktakings(stockTakings));

        if (!isInitialRequest && stockTakings.length === 0) {
            dispatch(errorMessageSet(t("criteriaDocsNotFound")));
        }
    };

    const handleGoBackOnClick = () => {
        dispatch(componentSet("MainMenu"));
    };

    const createStocktakingsTable = () => {
        return stockTakings.map(stocktaking => getStocktakingRow(stocktaking));
    };

    const getStocktakingReadings = async (stocktakingID) => {
        const params = {
            request: "getStocktakingReadings",
            stocktakingID: stocktakingID
        };

        const stocktakingReadings = await dispatch(makeErplyRequest(params, t("getStocktakingReadingsError"), null, null, null, true));
        stocktakingReadings.forEach(stocktakingReading => stocktakingReading.stocktakingID = stocktakingID);
        return stocktakingReadings;
    };

    const getBinQuantities = (productIDs) => {
        dispatch(setProductIDsOfBinQuantitiesRequested([...new Set(productIDsOfBinQuantitiesRequested.concat(productIDs))]));
        if (productIDs.length === 0) return [];

        const params = {
            request: "getBinQuantities",
            productIDs: productIDs.join(","),
            warehouseID: selectedWarehouse.warehouseID,
            minimumAmount: 0.000001
        };

        return dispatch(makeErplyRequest(params, t("getBinQuantitiesError"), null, null, null, true));
    };

    const getProductStock = (productIDs) => {
        if (productIDs.length === 0) return [];

        const params = {
            request: "getProductStock",
            productIDs: productIDs.join(","),
            warehouseID: selectedWarehouse.warehouseID
        };

        return dispatch(makeErplyRequest(params, t("getProductStockError"), null, null, null, true));
    };

    const getProducts = (productIDs) => {
        const params = {
            request: "getProducts",
            productIDs: productIDs.join(","),
            getFIFOCost: 1
        };

        return dispatch(makeErplyRequest(params, t("getProductsError"), null, null, null, true));
    };

    const getProductsWithDifferences = (stocktakingReadings, binQuantities, productsStock) => {
        const productsWithDifferences = [];

        stocktakingReadings.forEach(stocktakingReading => {
            const amountOnStocktaking = stocktakingReading.countPcs === "" ? 0 : Number(stocktakingReading.countPcs);
            const productStock = productsStock.find(productStock => productStock.productID === stocktakingReading.productID);
            const amountInStock = productStock ? roundFloatingPointError(productStock.amountInStock) : 0; // API does not return product stock if amountInStock is 0
            let amountInBins = 0;

            binQuantities.forEach(binQuantity => {
                if (stocktakingReading.productID === binQuantity.productID) {
                    amountInBins += binQuantity.amount;
                }
            });

            if (amountOnStocktaking !== amountInBins || amountOnStocktaking !== amountInStock) {
                productsWithDifferences.push({
                    productID: stocktakingReading.productID,
                    amountOnStocktaking: amountOnStocktaking,
                    amountInBins: amountInBins,
                    amountInStock: amountInStock
                });
            }
        });

        return productsWithDifferences;
    };

    const expandRow = async (stocktakingID) => {
        const expandedStocktakingReadings = stocktakingReadings.filter(stockTakingReading => stockTakingReading.stocktakingID === stocktakingID);

        if (expandedStocktakingReadings.length === 0) {
            const newStocktakingReadings = await getStocktakingReadings(stocktakingID);
            dispatch(setStocktakingReadings(stocktakingReadings.concat(newStocktakingReadings)));

            const productIDs = newStocktakingReadings.map(stocktakingReading => stocktakingReading.productID);
            const newProductIDs = productIDs.filter(productID => !products.some(product => product.productID === productID) && !productIDsOfBinQuantitiesRequested.includes(productID));

            if (newProductIDs.length > 0) {
                const [newBinQuantities, newProductStock] = await Promise.all([getBinQuantities(newProductIDs), getProductStock(newProductIDs)]);
                dispatch(setBinQuantities(binQuantities.concat(newBinQuantities)));
                dispatch(setProductStock(productStock.concat(newProductStock)));

                const productsWithDifferences = getProductsWithDifferences(newStocktakingReadings, newBinQuantities, newProductStock);

                const newProductsWithDifferences = productsWithDifferences.filter(productWithDifferences => !products.some(product => product.productID === productWithDifferences.productID));
                const newProducts = await getProducts(newProductsWithDifferences.map(product => product.productID));
                dispatch(setProducts(products.concat(newProducts)));
            }
        } else {
            const productIDs = expandedStocktakingReadings.map(stocktakingReading => stocktakingReading.productID);

            if (productIDs.length > 0) {
                const newProductIDs = productIDs.filter(productID => !binQuantities.some(binQuantity => binQuantity.productID === productID) && !productIDsOfBinQuantitiesRequested.includes(productID));

                if (newProductIDs.length > 0) {
                    const newBinQuantities = await getBinQuantities(newProductIDs);
                    dispatch(setBinQuantities(binQuantities.concat(newBinQuantities)));
                }
            }
        }

        dispatch(setExpandedStocktakingIDs(expandedStocktakingIDs.includes(stocktakingID) ? [] : [stocktakingID]));
    };

    const getStocktakingRow = (stocktaking) => {
        // Only display stocktakings without a follow-up document
        if (stocktaking.inventoryRegistrationID !== 0 || stocktaking.inventoryWriteOffID !== 0) return "";

        let elements = [<Table key={stocktaking.stocktakingID} celled structured unstackable>
            <Table.Body>
                <Table.Row>
                    <Table.Cell className={"tableHeading"} width={1} onClick={() => expandRow(stocktaking.stocktakingID)}>{t("number")}</Table.Cell>
                    <Table.Cell onClick={() => expandRow(stocktaking.stocktakingID)}>{stocktaking.stocktakingID}</Table.Cell>
                    {createFinishCheckButton(stocktaking)}
                </Table.Row>
                <Table.Row onClick={() => expandRow(stocktaking.stocktakingID)}>
                    <Table.Cell className={"tableHeading"} width={1}>{t("confirmed")}</Table.Cell>
                    <Table.Cell>{timestampToDateString(stocktaking.confirmedTimestamp)}</Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>];

        if (isSelectedRow(stocktaking.stocktakingID, expandedStocktakingIDs)) {
            elements.push(createDifferencesTable(stocktaking));
        }

        return elements;
    };

    const createFinishCheckButton = (stocktaking) => {
        if (expandedStocktakingIDs.includes(stocktaking.stocktakingID)) {
            return (
                <Table.Cell collapsing rowSpan='2'>
                    <Button key={"finish" + stocktaking.stocktakingID} color={"green"} onClick={() => displayReasonCodeModal(stocktaking)}>
                        {t("finishCheck")}
                    </Button>
                </Table.Cell>
            );
        }
    };

    const displayReasonCodeModal = (stocktaking) => {
        const options = reasonCodes.map(reasonCode => ({key: reasonCode.reasonID, text: reasonCode.name, value: reasonCode.reasonID}));
        dispatch(setModal(t("enterReason"), "", (reasonID) => createWriteOffAndInvRegistration(reasonID, stocktaking), false, options[0].value, options));
    };

    const createWriteOffAndInvRegistration = async (reasonID, stocktaking) => {
        const currentStocktakingReadings = stocktakingReadings.filter(stocktakingReading =>
            stocktakingReading.stocktakingID === stocktaking.stocktakingID && products.some(product => product.productID === stocktakingReading.productID));
        const productsWithDifferences = getProductsWithDifferences(currentStocktakingReadings, binQuantities, productStock);

        const saveInventoryRegistrationParams = {
            request: "saveInventoryRegistration",
            creatorID: user.employeeID,
            warehouseID: selectedWarehouse.warehouseID,
            stocktakingID: stocktaking.stocktakingID,
            reasonID: reasonID
        };

        const saveInventoryWriteOffParams = {
            ...saveInventoryRegistrationParams,
            request: "saveInventoryWriteOff"
        };

        let inventoryRegistrationProductsCounter = 0;
        let inventoryWriteOffProductsCounter = 0;

        productsWithDifferences.forEach(productWithDifferences => {
            const isInventoryRegistrationProduct = productWithDifferences.amountInBins > productWithDifferences.amountInStock;
            const params = isInventoryRegistrationProduct ? saveInventoryRegistrationParams : saveInventoryWriteOffParams;
            const amount = isInventoryRegistrationProduct ? productWithDifferences.amountInBins - productWithDifferences.amountInStock : productWithDifferences.amountInStock - productWithDifferences.amountInBins;
            const counter = isInventoryRegistrationProduct ? inventoryRegistrationProductsCounter : inventoryWriteOffProductsCounter;

            if (amount !== 0) {
                params[`productID${counter}`] = productWithDifferences.productID;
                params[`amount${counter}`] = amount;

                if (isInventoryRegistrationProduct) { // Only necessary for inventory registrations. Write-offs use FIFOCost by default
                    const product = products.find(product => product.productID === productWithDifferences.productID);
                    params[`price${counter}`] = product[confParameters.wmsInventoryRegistrationRowPrice];
                }

                isInventoryRegistrationProduct ? inventoryRegistrationProductsCounter ++ : inventoryWriteOffProductsCounter ++;
            }
        });

        const requests = [];
        if (saveInventoryRegistrationParams.hasOwnProperty("productID0") && stocktaking.inventoryRegistrationID === 0) {
            requests.push(dispatch(makeErplyRequest(saveInventoryRegistrationParams, t("saveInventoryRegistrationError"), null, null, null, false, false)));
        }
        if (saveInventoryWriteOffParams.hasOwnProperty("productID0") && stocktaking.inventoryWriteOffID === 0) {
            requests.push(dispatch(makeErplyRequest(saveInventoryWriteOffParams, t("saveInventoryWriteOffError"), null, null, null, false, false)));
        }

        if (requests.length === 0) {
            if (stocktaking.inventoryRegistrationID !== 0) {
                return dispatch(errorMessageSet(t("stocktakingAlreadyHasRegistration")));
            } else if (stocktaking.inventoryWriteOffID !== 0) {
                return dispatch(errorMessageSet(t("stocktakingAlreadyHasWriteOff")));
            } else {
                return dispatch(errorMessageSet(t("noDifferencesBetweenProductStockAndAmountsInBins")));
            }
        }

        dispatch(setIsLoading(true));
        const responses = await Promise.all(requests);
        dispatch(setIsLoading(false));

        if (!responses.some(response => response.status === "error")) {
            let successMessage;
            const newStocktakings = deepCopy(stockTakings);
            const newProductStock = deepCopy(productStock);
            const updatedStocktaking = newStocktakings.find(newStocktaking => newStocktaking.stocktakingID === stocktaking.stocktakingID);

            productsWithDifferences.forEach(productWithDifferences => {
                let updatedProductStock = newProductStock.find(productStock => productStock.productID === productWithDifferences.productID);
                if (!updatedProductStock) { // API does not return product stock if amountInStock is 0
                    updatedProductStock = {
                        productID: productWithDifferences.productID,
                        amountInStock: 0
                    };

                    newProductStock.push(updatedProductStock);
                }

                updatedProductStock.amountInStock = productWithDifferences.amountInBins;
            });

            if (requests.length === 2) {
                successMessage = t("inventoryRegistrationAndWriteOffCreated");
                updatedStocktaking.inventoryRegistrationID = responses[0].inventoryRegistrationID;
                updatedStocktaking.inventoryWriteOffID = responses[0].inventoryWriteOffID;
            } else {
                if (saveInventoryRegistrationParams.hasOwnProperty("productID0")) {
                    successMessage = t("inventoryRegistrationCreated");
                    updatedStocktaking.inventoryRegistrationID = responses[0].inventoryRegistrationID;
                } else {
                    successMessage = t("inventoryWriteOffCreated");
                    updatedStocktaking.inventoryWriteOffID = responses[0].inventoryWriteOffID;
                }
            }

            dispatch(setStocktakings(newStocktakings));
            dispatch(setProductStock(newProductStock));
            dispatch(successMessageSet(successMessage));
        }
    };

    const createDifferencesTable = (stocktaking) => {
        return (
            <Table celled structured unstackable color={"blue"} inverted size={"small"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t("code")}</Table.HeaderCell>
                        <Table.HeaderCell>{translateAccordingToCountry(t("EAN"), confParameters)}</Table.HeaderCell>
                        <Table.HeaderCell>{t("name")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("amountOnStocktaking")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("amountInStock")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("amountInBins")}</Table.HeaderCell>
                        <Table.HeaderCell/>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {createDifferencesTableRows(stocktaking.stocktakingID)}
                </Table.Body>
            </Table>
        )
    };

    const createDifferencesTableRows = (stocktakingID) => {
        const currentStocktakingReadings = stocktakingReadings.filter(stocktakingReading => stocktakingReading.stocktakingID === stocktakingID);
        const productIDs = currentStocktakingReadings.map(stocktakingReading => stocktakingReading.productID);
        const currentBinQuantities = binQuantities.filter(binQuantity => productIDs.includes(binQuantity.productID));
        const productsWithDifferences = getProductsWithDifferences(currentStocktakingReadings, currentBinQuantities, productStock);

        if (productsWithDifferences.length > 0) {
            return productsWithDifferences.map((productWithDifferences, index) => {
                const product = products.find(product => product.productID === productWithDifferences.productID);

                if (product) {
                    return createDifferencesTableRow(product, index, productWithDifferences);
                }
            })
        } else {
            dispatch(setExpandedStocktakingIDs([]));   // Shrink row
            dispatch(successMessageSet(t("noDifferencesBetweenStocktakingAndAmountsInBins")));
            return null;
        }
    };

    const createDifferencesTableRow = (product, index, amountsData) => {
        const isSelected = isSelectedRow(product.productID, expandedProductIDs);

        let elements = [
            <Table.Row key={index}>
                <Table.Cell onClick={() => handleProductClick(product, isSelected)}>{product.code}</Table.Cell>
                <Table.Cell onClick={() => handleProductClick(product, isSelected)}>{product.code2}</Table.Cell>
                <Table.Cell onClick={() => handleProductClick(product, isSelected)}>{product.name}</Table.Cell>
                <Table.Cell onClick={() => handleProductClick(product, isSelected)}>{amountsData.amountOnStocktaking}</Table.Cell>
                <Table.Cell onClick={() => handleProductClick(product, isSelected)}>{amountsData.amountInStock}</Table.Cell>
                <Table.Cell onClick={() => handleProductClick(product, isSelected)}>{amountsData.amountInBins}</Table.Cell>
                <Table.Cell>
                    <Button key={product.productID + "scanLaterBtn"} className={"margin0"} size={"mini"} color={"grey"} onClick={() => scanLater(product.productID)}>{t("scanLater")}</Button>
                </Table.Cell>
            </Table.Row>
        ];

        if (isSelected) {
            elements.push(createProductInBinsTable(product));
        }

        return elements;
    };

    // Move stocktaking readings with the product to the end of the stocktaking readings array
    const scanLater = (productID) => {
        let newStocktakingReadings = deepCopy(stocktakingReadings);

        for (let i = 0, n = newStocktakingReadings.length; i < n; i++) {
            if (newStocktakingReadings[i].productID === productID) {
                newStocktakingReadings.push(newStocktakingReadings.splice(i, 1)[0]);
            }
        }

        dispatch(setStocktakingReadings(newStocktakingReadings));
    };

    const handleProductClick = (product, isSelected) => {
        if (!isSelected) {
            const quantities = binQuantities.filter(binQuantity => binQuantity.productID === product.productID);
            dispatch(setChangedBinQuantities(quantities));
            dispatch(setInitialBinQuantities(deepCopy(quantities)));
        }

        dispatch(setExpandedProductIDs(expandedProductIDs.includes(product.productID) ? [] : [product.productID]));
    };

    const createProductInBinsTable = (product) => {
        return (
            <tr key={product.productID}>
                <td colSpan={6}>
                    <Table color={"grey"} celled inverted unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t("bin")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("preferred")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("amount")}</Table.HeaderCell>
                                <Table.HeaderCell collapsing><Button size={"tiny"} color={"green"} onClick={validateChangedBinQuantities}>{t("change")}</Button></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {createProductInBinsTableRows(product)}
                            {createAddBinInput(product)}
                        </Table.Body>
                    </Table>
                </td>
            </tr>
        )
    };

    const createAddBinInput = (product) => {
        return (
            <Table.Row key={"addBinInput"}>
                <Table.Cell colSpan={3}>
                    <Input fluid placeholder={t("enterBin")} onInput={e => dispatch(setBinCodeInput(e.target.value))} value={binCodeInput}/>
                </Table.Cell>
                <Table.Cell>
                    <Button icon onClick={() => addBin(product)}>
                        <Icon color={"green"} name='add' />
                    </Button>
                </Table.Cell>
            </Table.Row>
        )
    };

    const addBin = async (product) => {
        const bins = await dispatch(makeErplyRequest({
            request: "getBins",
            code: binCodeInput,
            status: "ACTIVE",
            warehouseID: selectedWarehouse.warehouseID
        }));

        if (bins.length > 0) {
            if (changedBinQuantities.some(binQuantity => binQuantity.binID === bins[0].binID)) {
                dispatch(errorMessageSet(t("binAlreadyExists")));
            } else {
                const newBinQuantity = {binID: bins[0].binID, binCode: bins[0].code, productID: product.productID, amount: 0};

                dispatch(setChangedBinQuantities([...changedBinQuantities, newBinQuantity]));
                dispatch(setInitialBinQuantities([...initialBinQuantities, newBinQuantity]));
                dispatch(setBinQuantities([...binQuantities, newBinQuantity]));
                dispatch(setBinCodeInput(""));
            }
        } else {
            dispatch(errorMessageSet(t("noBinFound")));
        }
    };

    const validateChangedBinQuantities = () => {
        let amountsHaveChanged = false;

        for (let i = 0, n = changedBinQuantities.length; i < n; i++) {
            if (changedBinQuantities[i].amount < 0) {
                return dispatch(errorMessageSet(t("mustBeANonNegativeInteger")));
            }

            if (!amountsHaveChanged) {
                const amountHasChanged = initialBinQuantities.find(binQuantity => binQuantity.binID === changedBinQuantities[i].binID).amount != changedBinQuantities[i].amount;

                if (amountHasChanged) {
                    amountsHaveChanged = true;
                }
            }
        }

        if (!amountsHaveChanged) {
            return dispatch(errorMessageSet(t("noChanges")));
        }

        dispatch(setModal(t("confirmation"), t("confirmChange?"), confirmChanges));
    };

    const confirmChanges = async () => {
        const params = {request: "adjustBinQuantities"};
        const newBinQuantities = deepCopy(binQuantities);

        for (let i = 0, n = changedBinQuantities.length; i < n; i++) {
            const oldAmount = initialBinQuantities.find(binQuantity => binQuantity.binID === changedBinQuantities[i].binID).amount;
            const amountChange = changedBinQuantities[i].amount - oldAmount;

            if (amountChange !== 0) {
                params[`binID${i}`] = changedBinQuantities[i].binID;
                params[`productID${i}`] = changedBinQuantities[i].productID;
                params[`newAmount${i}`] = changedBinQuantities[i].amount === "" ? 0 : changedBinQuantities[i].amount;
                params[`creatorID${i}`] = user.employeeID;

                newBinQuantities.find(binQuantity => binQuantity.binID === changedBinQuantities[i].binID &&
                    binQuantity.productID === changedBinQuantities[i].productID).amount = changedBinQuantities[i].amount;
            }
        }

        const response = await dispatch(makeErplyRequest(params, t("adjustBinQuantitiesError")));

        if (response.status !== "error") {
            dispatch(setBinQuantities(newBinQuantities));
            dispatch(setInitialBinQuantities(deepCopy(changedBinQuantities)));
            dispatch(successMessageSet(t("binQuantitiesAdjusted")));
        }
    };

    const createProductInBinsTableRows = (product) => {
        return changedBinQuantities.map((binQuantity, index) => createProductInBinsTableRow(product, binQuantity, index))
    };

    const createProductInBinsTableRow = (product, binQuantity, index) => {
        const preferred = binQuantity.binPreferred ? t("yes") : t("no");

        return (
            <Table.Row key={index}>
                <Table.Cell>{translateBinFromEng(binQuantity.binCode, language)}</Table.Cell>
                <Table.Cell>{preferred}</Table.Cell>
                <Table.Cell colSpan={2}>
                    <Input fluid
                           type={"number"}
                           onInput={e => setNewChangedBinQuantities(binQuantity.binID, e.target.value)}
                           value={changedBinQuantities.find(changedBinQuantity => changedBinQuantity.binID === binQuantity.binID).amount.toString()} // toString() removes leading zeroes
                           onFocus={e => e.target.select()}
                    />
                </Table.Cell>
            </Table.Row>
        )
    };

    const setNewChangedBinQuantities = (binID, newAmount) => {
        const changedBinQuantitiesCopy = deepCopy(changedBinQuantities);
        changedBinQuantitiesCopy.find(binQuantity => binQuantity.binID === binID).amount = Number(newAmount);
        dispatch(setChangedBinQuantities(changedBinQuantitiesCopy));
    };

    return (
        <div className={"overflow"}>
            <DatePickerComponent labelText={"dateFrom"} value={fromDate} setStateFunction={(date) => dispatch(setFromDate(date))} className={"searchInput"}/>
            <Button className={"menuBtn"} primary size={"big"} onClick={() => getStocktakings(fromDate)}>Ok</Button>
            {createStocktakingsTable()}
            <GoBackBtn addClassNames={["menuBtn"]} removeClassNames={["menuBtnHalfWidth"]} handleGoBackOnClick={handleGoBackOnClick} size={"big"}/>
        </div>
    );
};

export default Stocktakings
