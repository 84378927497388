const initialState = {
    errorMessage: "",
    successMessage: "",
    warningMessage: "",
    delayedClose: null,  // milliseconds
    hideMessageTimeoutIsSet: false
};

export const headerMessageReducer = (state = initialState, action) => {
    switch(action.type) {
        case "ERROR_MESSAGE_SET":
            return {
                ...state,
                errorMessage: action.payload.text,
                successMessage: "",
                warningMessage: "",
                delayedClose: action.payload.delayedClose,
                hideMessageTimeoutIsSet: false
            };
        case "SUCCESS_MESSAGE_SET":
            return {
                ...state,
                errorMessage: "",
                successMessage: action.payload.text,
                warningMessage: "",
                delayedClose: action.payload.delayedClose,
                hideMessageTimeoutIsSet: false
            };
        case "WARNING_MESSAGE_SET":
            return {
                ...state,
                errorMessage: "",
                successMessage: "",
                warningMessage: action.payload.text,
                delayedClose: action.payload.delayedClose,
                hideMessageTimeoutIsSet: false
            };
        case "SET_HIDE_MESSAGE_TIMEOUT_IS_SET":
            return {
                ...state,
                hideMessageTimeoutIsSet: action.payload
            };
        case "MESSAGE_REMOVE":
            return {
                ...state,
                errorMessage: "",
                successMessage: "",
                warningMessage: "",
                delayedClose: null,
                hideMessageTimeoutIsSet: false
            };
        default:
            return state;
    }
};
// const initialState = {
//     errorMessages: [],
//     successMessages: [],
// };
//
// export const headerMessageReducer = (state = initialState, action) => {
//     switch(action.type) {
//         case "ERROR_MESSAGE_SET":
//             return {
//                 ...state,
//                 errorMessages: [...state.errorMessages, action.payload]
//             };
//         case "SUCCESS_MESSAGE_SET":
//             return {
//                 ...state,
//                 successMessages: [...state.successMessages, action.payload]
//             };
//         default:
//             return state;
//     }
// };
