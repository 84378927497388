import React, {useState, useRef, useEffect} from 'react';
import {Button, Icon, Input, Table} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {componentSet, previousComponentSet, setIsLoading} from "../actions/component";
import {errorMessageSet} from "../actions/headerMessage";
import {useDispatch, useSelector} from "react-redux";
import GoBackToStartBtn from "./GoBackToStartBtn";
import {
    setReceivingAreaReportProducts,
    setSalesOrdersWithProducts,
    setScannedProduct, setSourceBin, setScannedProducts, setSourceBinQuantities
} from "../actions/placeProducts";
import {getWmsApiBinQuantities, makeErplyBulkRequest, makeErplyRequest} from "../util/erplyRequests";
import GoBackBtn from "./GoBackBtn";
import {
    commaSeparatedCodesIncludeCode, deepCopy,
    getApiAttributeValue, getConfParameterValue,
    getGetProductsCodeOrder, playSound,
    translateAccordingToCountry
} from "../util/misc";
import {setModal} from "../actions/modal";

const RegisterToAddressScanProduct = () => {
    const scanInput = useRef(null);
    const amountInput = useRef(null);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isRapidScan = dispatch(getConfParameterValue("wmsRapidInScan")) == 1;
    const defaultAmount = isRapidScan ? 1 : "";
    const maxProductsDisplayed = 100;

    const [scannedCode, setScannedCode] = useState("");
    const [currentScannedProduct, setCurrentScannedProduct] = useState(null);
    const [amount, setAmount] = useState(defaultAmount);

    const sourceBinQuantities = useSelector(state => state.placeProductsReducer.sourceBinQuantities);
    const isCheckGoodsInReceivingArea = useSelector(state => state.placeProductsReducer.isCheckGoodsInReceivingArea);
    const isCheckGoodsInBin = useSelector(state => state.placeProductsReducer.isCheckGoodsInBin);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const receivingArea = useSelector(state => state.getBinsReducer.receivingArea);
    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);
    const isLoading = useSelector(state => state.componentReducer.isLoading);
    const scannedProducts = useSelector(state => state.placeProductsReducer.scannedProducts);
    const sourceBin = useSelector(state => state.placeProductsReducer.sourceBin);

    const isRegisterProductsToAddressView = sourceBinQuantities === null;
    const searchCodeFromCommaSeparatedCodes = confParameters.api_get_product_use_new_fulltext_index == 1;

    useEffect(() => {
        scanInput.current.focus();
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown);
        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    });

    useEffect(() => {
        if (isCheckGoodsInBin) {
            setCurrentScannedProduct(null);
            handleScanInput();
        }
    }, [scannedCode]);

    const handleScanInput = async () => {
        if (scannedCode !== "") {
            const product = await getProductByAllCodes(false);
            setCurrentScannedProduct(product);
        }
    };

    const getDisplayedBinQuantities = (scannedProducts) => {
        let displayedBinQuantities = deepCopy(sourceBinQuantities);

        scannedProducts.forEach(scannedProduct => {
            for (let i = 0, n = displayedBinQuantities.length; i < n; i++) {
                if (displayedBinQuantities[i].productID == scannedProduct.productID && displayedBinQuantities[i].amount - scannedProduct.amount <= 0) {
                    displayedBinQuantities.splice(i, 1);
                    break;
                }
            }
        });

        return displayedBinQuantities.slice(0, maxProductsDisplayed);
    };

    const handleGoBackOnClick = () => {
        if (isCheckGoodsInBin && scannedProducts.length > 0) {
            dispatch(setModal(t("warning"), t("confirmGoBackCheckGoodsInBin"), goBack));
        } else {
            goBack();
        }
    };

    const goBack = () => {
        dispatch(componentSet("PlaceProducts"));
    };

    const handleOkOnClick = async (scannedCode) => {
        if (scannedCode === "") {
            dispatch(errorMessageSet(t("noItemsScanned")));
        } else {
            if (isRegisterProductsToAddressView || isCheckGoodsInBin) {
                const scannedProduct = await getProductByAllCodes();

                if (scannedProduct === undefined) {
                    dispatch(errorMessageSet(t("noProductFound")));
                } else {
                    if (isCheckGoodsInReceivingArea) {  // Rehvid Pluss only
                        dispatch(setIsLoading(true));
                        const [binQuantities, salesOrders] = await Promise.all([getProductAmountInReceivingArea(scannedProduct), getActiveSalesOrders(scannedProduct)]);
                        dispatch(setSalesOrdersWithProducts(salesOrders));

                        scannedProduct.forEach(product => {
                            const binQuantity = binQuantities.find(quantity => quantity.productID == product.productID);
                            product.amount = binQuantity ? binQuantity.amount : 0;
                        });

                        dispatch(setIsLoading(false));
                        dispatch(setReceivingAreaReportProducts(scannedProduct));
                        dispatch(setSourceBin(receivingArea));
                        dispatch(componentSet("ProductInReceivingAreaReport"));
                    } else if (isCheckGoodsInBin) {
                        const newScannedProducts = deepCopy(scannedProducts);
                        const existingScannedProduct = newScannedProducts.find(product => product.productID == scannedProduct.productID);

                        if (existingScannedProduct) {
                            existingScannedProduct.amount += Number(amount);
                        } else {
                            scannedProduct.amount = Number(amount);
                            newScannedProducts.push(scannedProduct);
                        }

                        dispatch(setScannedProducts(newScannedProducts));
                        setScannedCode("");
                        scanInput.current.focus();
                        setAmount(defaultAmount);
                        playSound("success");
                    } else {
                        displayEnterAddress(scannedProduct);
                    }
                }
            } else {    // Is "Move products from receiving area to warehouse addresses" or "Move product from address A to address B"
                let scannedProduct;
                const codePriorityOrder = confParameters.wmsPrioritiseCode1 == 1 ? ["productCode", "productCode2", "productCode3", "productSupplierCode", "productCode5", "productCode6", "productCode7", "productCode8"] :
                    ["productCode2", "productCode", "productCode3", "productSupplierCode", "productCode5", "productCode6", "productCode7", "productCode8"];

                for (let i = 0, n = codePriorityOrder.length; i < n; i++) {
                    scannedProduct = sourceBinQuantities.find(product => product[codePriorityOrder[i]] !== null && product[codePriorityOrder[i]].trim() == scannedCode);
                    if (scannedProduct) break;
                }

                if (scannedProduct && scannedProduct.hasOwnProperty("amount")) {    // Is not a 0-amount product
                    scannedProduct.code = scannedProduct.productCode;   // For easier data management
                    displayEnterAddress(scannedProduct);
                } else {
                    const product = await getProductByAllCodes();
                    if (!product) return dispatch(errorMessageSet(t("noProductFound")));

                    const binQuantities = await dispatch(getWmsApiBinQuantities(t, true, null, sourceBin.binID, product.productID, 0.000001));
                    if (binQuantities.length === 0) return dispatch(errorMessageSet(t("productNotInBin")));

                    scannedProduct = binQuantities[0];
                    scannedProduct.code = scannedProduct.productCode;   // For easier data management
                    displayEnterAddress(scannedProduct);
                }
            }
        }
    };

    const getProductAmountInReceivingArea = (scannedProducts) => {
        const params = {
            request: "getBinQuantities",
            binIDs: receivingArea.binID,
            productIDs: scannedProducts.map(product => product.productID).join(",")
        };

        return dispatch(makeErplyRequest(params, t("getBinQuantitiesError"), null, null, null, true, false));
    };

    const getActiveSalesOrders = (scannedProducts) => {
        const requests = scannedProducts.map(product => {
            const params = {
                request: "getSalesDocuments",
                warehouseID: selectedWarehouse.warehouseID,
                productID: product.productID,
                confirmed: 1,
                type: "ORDER",
                getRowsForAllInvoices: 1
            };
            return dispatch(makeErplyRequest(params, t("getSalesDocumentsError"), null, null, null, true, false));
        });

        return Promise.all(requests).then(data => {
            const salesOrders = data.flat();
            return salesOrders.filter(salesOrder => salesOrder.reserveGoods == 1 && salesOrder.followUpDocuments.length === 0 && getApiAttributeValue("status_color", salesOrder.attributes) !== false);
        });
    };

    const displayEnterAddress = (scannedProduct) => {
        dispatch(setScannedProduct(scannedProduct));
        dispatch(previousComponentSet("RegisterToAddressScanProduct"));
        dispatch(componentSet("RegisterToAddressEnterAddress"));
    };

    const getProductByAllCodes = async (requestProducts = true) => {
        //Field name order by which products are requested by using the field name in parameters
        const getProductsCodeOrder = getGetProductsCodeOrder(confParameters);

        if (isCheckGoodsInBin) {
            const productArrays = [scannedProducts, sourceBinQuantities];

            for (let i = 0, n = getProductsCodeOrder.length; i < n; i++) {
                for (let j = 0, n = productArrays.length; j < n; j++) {
                    const product = productArrays[j].find(product => product[getProductsCodeOrder[i]] !== null &&
                        (product[getProductsCodeOrder[i]].trim() == scannedCode || (searchCodeFromCommaSeparatedCodes && commaSeparatedCodesIncludeCode(product[getProductsCodeOrder[i]].trim(), scannedCode))));
                    if (product) return deepCopy(product);
                }
            }
        }

        if (!requestProducts) return;

        let requests = [];
        for (let i = 0, n = searchCodeFromCommaSeparatedCodes ? 1 : getProductsCodeOrder.length; i < n; i++) {
            const request = {
                requestName: "getProducts",
                active: 1
            };

            if (searchCodeFromCommaSeparatedCodes) {
                request.searchNameIncrementally = scannedCode;
                request.searchCodeFromMiddle = 1;
            } else {
                request[getProductsCodeOrder[i]] = scannedCode;
            }

            requests.push(request)
        }

        const getProductsBulkResponse = await dispatch(makeErplyBulkRequest(requests, t("getProductsError")));

        for (let i = 0, n = getProductsBulkResponse.length; i < n; i++) {
            if (getProductsBulkResponse[i].records && getProductsBulkResponse[i].records.length > 0) {
                if (isCheckGoodsInReceivingArea) {
                    return getProductsBulkResponse[i].records;  // Rehvid Pluss may have multiple products with the same code2
                } else if (searchCodeFromCommaSeparatedCodes) {
                    for (let k = 0, n = getProductsCodeOrder.length; k < n; k++) {
                        for (let j = 0, n = getProductsBulkResponse[i].records.length; j < n; j++) {
                            if (commaSeparatedCodesIncludeCode(getProductsBulkResponse[i].records[j][getProductsCodeOrder[k]], scannedCode)) {
                                return getProductsBulkResponse[i].records[j];
                            }
                        }
                    }
                } else {
                    return getProductsBulkResponse[i].records[0];
                }
            }
        }
    };

    const getTableRows = () => {
        if (!isRegisterProductsToAddressView && sourceBinQuantities.length > 0) {
            return getDisplayedBinQuantities(scannedProducts).map((product, index) => getTableRow(product, index));
        }
    };

    const getTableRow = (product, index) => {
        if (product.amount != 0 && product.productCode !== null) {
            let docRowBtnClicked = false;
            const scannedCode = product.productCode2 !== null ? product.productCode2 : product.productCode;
            let amount = product.amount;
            const scannedProduct = scannedProducts.find(scannedProduct => scannedProduct.productID == product.productID);
            if (scannedProduct) amount -= scannedProduct.amount;
            let nameCell = [<p className={"alignItemsCenter flex marginBottom0"} key={product.name}>{product.name}</p>];

            if (isCheckGoodsInBin) {
                const handleScanLaterButtonClick = () => {
                    const sourceBinQuantitiesCopy = deepCopy(sourceBinQuantities);
                    sourceBinQuantitiesCopy.push(sourceBinQuantitiesCopy.splice(index, 1)[0]);
                    dispatch(setSourceBinQuantities(sourceBinQuantitiesCopy));
                    docRowBtnClicked = true;
                };

                nameCell.push(<Button key={product.name + "scanLaterBtn"} className={"marginLeftSmall"} size={"mini"} color={"grey"} onClick={handleScanLaterButtonClick}>{t("scanLater")}</Button>);
            }

            return <Table key={product.productID} celled structured unstackable onClick={() => handleTableOnClick(scannedCode, docRowBtnClicked)}>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell className={"tableHeading"} width={1}>{t("name")}</Table.Cell>
                        <Table.Cell><div className={"flex"}>{nameCell}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className={"tableHeading"} width={1}>{t("code")}</Table.Cell>
                        <Table.Cell>{product.productCode}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className={"tableHeading"} width={1}>{translateAccordingToCountry(t("EAN"), confParameters)}</Table.Cell>
                        <Table.Cell>{product.productCode2}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className={"tableHeading"} width={1}>{t("amount")}</Table.Cell>
                        <Table.Cell>{amount}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        }
    };

    const handleTableOnClick = (EAN, ignoreClick) => {
        if (!ignoreClick) {
            setScannedCode(EAN);
            if (!isCheckGoodsInBin) handleOkOnClick(EAN);
        }
    };

    const clearScannedCode = () => {
        setScannedCode("");
        scanInput.current.focus();
    };

    const createAmountInput = () => {
        if (isCheckGoodsInBin) {
            return <Input ref={amountInput} type="number" onFocus={() => amountInput.current.select()} onInput={e => handleSetAmount(e.target.value)} fluid value={amount} placeholder={t('enterAmount')}/>;
        }
    };

    const handleSetAmount = (amount) => {
        const maxAmountAllowedToScan = confParameters.wmsMaxAmountAllowedToScan;
        const amountIsDecimal = amount.includes(".");
        const amountStartsWith0 = amount.substr(0, 1) == 0;

        if ((maxAmountAllowedToScan !== "" && amount > Number(maxAmountAllowedToScan)) || amount < 0) {
            setAmount(0);
        } else if (amount.length > 1 && amountStartsWith0 && !amountIsDecimal) {
            setAmount(amount.substr(1));
        } else {
            setAmount(amount);
        }
    };

    const onKeyDown = (e) => {
        if (!isLoading && e.key === "Enter") {
            if (isRapidScan || !isCheckGoodsInBin) {
                handleOkOnClick(scannedCode);
            } else {
                amountInput.current.select();
            }
        }
    };

    const createFinishWorkButton = () => {
        if (isCheckGoodsInBin) {
            return <Button className={"menuBtnHalfWidth margin0Right"} color={"green"} size={"large"} onClick={handleFinishWorkOnClick}>{t("finishWork")}</Button>
        }
    };

    const handleFinishWorkOnClick = () => {
        dispatch(componentSet("CheckGoodsInBinScanFinish"));
    };

    const getScannedProductName = () => {
        if (isCheckGoodsInBin) {
            const name = currentScannedProduct && scannedCode !== "" ? currentScannedProduct.name : "";
            return <div className="flex marginBottom"><p className={"smallWhiteText"}>{name}</p></div>;
        }
    };

    return (
        <div>
            <Input ref={scanInput} className={isCheckGoodsInBin ? "marginBottomSmall" : "searchDocNoInput"} onInput={e => setScannedCode(e.target.value)} fluid placeholder={t('scanProduct')} value={scannedCode}
                   icon={<Icon name='delete' link onClick={clearScannedCode}/>} autoComplete="off"/>
            {getScannedProductName()}
            {createAmountInput()}
            <div className={"btnsGroup"}>
                <div className={isCheckGoodsInBin ? "flexCenter" : ""}>
                    <Button className={isCheckGoodsInBin ? "menuBtnHalfWidth" : "menuBtn"} primary size={"large"} onClick={() => handleOkOnClick(scannedCode)}>Ok</Button>
                    {createFinishWorkButton()}
                </div>
                <div className={"flex flexCenter"}>
                    <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                    <GoBackToStartBtn displayWarning={isCheckGoodsInBin && scannedProducts.length > 0} warning={t("confirmGoBackCheckGoodsInBin")} onYes={async () => {}}/>
                </div>
            </div>
            {getTableRows()}
        </div>
    );
};

export default RegisterToAddressScanProduct
