import React, { useState, useEffect, useRef } from 'react';
import {Button, Dropdown, Input} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {errorMessageSet, successMessageSet} from "../actions/headerMessage";
import {useDispatch, useSelector} from "react-redux";
import {getBinRecords, makeErplyRequest} from "../util/erplyRequests";
import {componentSet, previousComponentSet} from "../actions/component";
import {setDocumentHasBeenPreviouslyScanned, setSelectedDocuments} from "../actions/scan";
import GoBackToStartBtn from "./GoBackToStartBtn";
import {setModal} from "../actions/modal";
import GoBackBtn from "./GoBackBtn";
import {filterOutDocumentsWithNoRows} from "../util/misc";
import {setIsGetUndeliveredGoods} from "../actions/searchDocuments";

const PurchaseOrder = () => {
    const orderNoInput = useRef(null);

    const [orderNo, setOrderNo] = useState("");
    const [showDocSelection, setShowDocSelection] = useState(false);
    const [purchaseDocs, setPurchaseDocs] = useState([]);
    const [selectedDropdownDoc, setSelectedDropdownDoc] = useState(null);

    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(previousComponentSet("PurchaseOrder"));

        orderNoInput.current.focus();
    }, []);

    const handleStartHandlingOnClick = () => {
        if (orderNo !== "") {
            getPurchaseDocuments();
        } else {
            dispatch(errorMessageSet(t("enterOrderNo")));
        }
    };

    const handleSearchOrdersOnClick = () => {
        dispatch(setIsGetUndeliveredGoods(false));
        dispatch(componentSet("SearchOrders"));
    };

    const handleGoBackOnClick = () => {
        dispatch(componentSet("ProductsIn"));
    };

    const getPurchaseDocuments = () => {
        setPurchaseDocs([]);
        setShowDocSelection(false);

        const params = {
            request: "getPurchaseDocuments",
            type: "PRCORDER",
            getRowsForAllInvoices: 1,
            warehouseID: selectedWarehouse.warehouseID,
            confirmed: 1
        };

        getPurchaseDocumentsByRegNumber(params).then(function (purchaseDocumentsByRegNumber) {
            console.log("purchaseDocumentsByRegNumber", purchaseDocumentsByRegNumber);

            if (purchaseDocumentsByRegNumber.length === 0) {
                getPurchaseDocumentsByNumber(params).then(function (purchaseDocumentsByNumber) {
                    console.log("purchaseDocumentsByNumber", purchaseDocumentsByNumber);

                    if (purchaseDocumentsByNumber.length === 0) {
                        dispatch(errorMessageSet(t("noDocFound")));
                    } else if (purchaseDocumentsByNumber.length === 1) {
                        checkFollowUpDocumentsAndBinRecords(purchaseDocumentsByNumber[0]);
                    } else {
                        dispatch(successMessageSet(t("multiplePrcDocFound")));
                        setPurchaseDocs(purchaseDocumentsByNumber);
                        setShowDocSelection(true);
                    }
                });
            } else {
                checkFollowUpDocumentsAndBinRecords(purchaseDocumentsByRegNumber[0]);
            }
        });
    };

    const selectPurchaseDoc = async (purchaseDoc, documentHasBeenPreviouslyScanned) => {
        dispatch(setDocumentHasBeenPreviouslyScanned(documentHasBeenPreviouslyScanned));
        await dispatch(setSelectedDocuments([purchaseDoc], t));
        dispatch(componentSet("Scan"));
    };

    const getPurchaseDocumentsByNumber = (params) => {
        delete params["regnumber"];
        params.number = orderNo;

        return dispatch(makeErplyRequest(params, t("getPurchaseDocumentsError"))).then((docs) => {
            let filteredDocs = filterOutDocumentsWithNoRows(docs);
            return filterOutDocumentsWithInappropriateStatuses(filteredDocs);
        });
    };

    const getPurchaseDocumentsByRegNumber = (params) => {
        params.regnumber = orderNo;
        return dispatch(makeErplyRequest(params, t("getPurchaseDocumentsError"))).then((docs) => {
            let filteredDocs = filterOutDocumentsWithNoRows(docs);
            return filterOutDocumentsWithInappropriateStatuses(filteredDocs);
        });
    };

    const filterOutDocumentsWithInappropriateStatuses = (docs) => {
        return docs;
        // return docs.filter(doc => doc.status === "READY" || doc.status === "PARTIALLY_RECEIVED");
    };

    const createDocSelection = () => {
        const options = purchaseDocs.map(purchaseDoc => getPurchaseDocOption(purchaseDoc));

        return (
            <div>
                <Dropdown
                    className={"searchDocNoInput"}
                    defaultValue={options[0].value}
                    fluid
                    selection
                    options={options}
                    onChange={handleDropdownOnChange}
                    search
                    noResultsMessage={t("noResultsFound")}
                />
                <Button className={"menuBtn"} primary size={"big"} onClick={handleDropdownOkClick}>{t("startHandling")}</Button>
            </div>
        )
    };

    const handleDropdownOnChange = (event, { value }) => {
        const doc = purchaseDocs.find(doc => doc.id === value);
        setSelectedDropdownDoc(doc);
    };

    const handleDropdownOkClick = () => {
        let selectedDocument;
        if (selectedDropdownDoc === null) {
            selectedDocument = purchaseDocs[0];
        } else {
            selectedDocument = selectedDropdownDoc;
        }

        checkFollowUpDocumentsAndBinRecords(selectedDocument);
    };

    const checkFollowUpDocumentsAndBinRecords = async (selectedDocument) => {
        if (selectedDocument.baseToDocuments.length > 0) {
            dispatch(setModal(t("warning"), t("followUpDocExistsWarning"), () => askFollowUpDocumentNo(selectedDocument, true)));
        } else {
            const binRecords = await dispatch(getBinRecords(t, selectedDocument.id, "PURCHASE_DOCUMENT", null, true));

            if (binRecords.length > 0) {
                dispatch(setModal(t("confirmation"), t("handlingAlreadyStarted"), () => askFollowUpDocumentNo(selectedDocument, true)));
            } else {
                askFollowUpDocumentNo(selectedDocument);
            }
        }
    };

    const askFollowUpDocumentNo = (purchaseDoc, documentHasBeenPreviouslyScanned = false) => {
        // if (isArvutitark(clientCode)) {
        //     dispatch(displayFollowUpPurchaseDocumentNoModal(t, () => selectPurchaseDoc(purchaseDoc, documentHasBeenPreviouslyScanned)));
        // } else {
            selectPurchaseDoc(purchaseDoc, documentHasBeenPreviouslyScanned);
        // }
    };

    const getPurchaseDocOption = (purchaseDoc) => {
        const text = `${t("prcOrder")} ${t("regNo")}: ${purchaseDoc.regnumber}`;
        return {key: purchaseDoc.id, text: text, value: purchaseDoc.id};
    };

    return (
        <div id={"purchaseDocument"}>
            <div>
                {showDocSelection
                    ?
                    createDocSelection()
                    :
                    <div>
                        <Input ref={orderNoInput} onInput={e => setOrderNo(e.target.value)} className={"searchDocNoInput"} fluid placeholder={t('enterOrderNo')} />
                        <Button className={"menuBtn"} primary size={"big"} onClick={handleStartHandlingOnClick}>{t("startHandling")}</Button>
                    </div>
                }
            </div>
            <Button className={"menuBtn"} color={"instagram"} onClick={handleSearchOrdersOnClick} size='big'>{t("searchOrders")}</Button>
            <div className={"flex flexCenter"}>
                <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                <GoBackToStartBtn/>
            </div>
        </div>
    );
};

export default PurchaseOrder
