import React, {useEffect, useState} from 'react';
import {Button, Table, Transition} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {componentSet, setIsLoading} from "../actions/component";
import {useDispatch, useSelector} from "react-redux";
import {errorMessageSet, successMessageSet} from "../actions/headerMessage";
import {setModal} from "../actions/modal";
import GoBackToStartBtn from "./GoBackToStartBtn";
import {getReasonCodes, makeErplyRequest, responseHasErrors} from "../util/erplyRequests";
import GoBackBtn from "./GoBackBtn";
import {roundFloatingPointError, getConfParameterValue} from "../util/misc";

const CheckGoodsInBinScanFinish = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const sourceBinQuantities = useSelector(state => state.placeProductsReducer.sourceBinQuantities);
    const scannedProducts = useSelector(state => state.placeProductsReducer.scannedProducts);
    const reasonCodes = useSelector(state => state.getReasonCodesReducer.reasonCodes);
    const user = useSelector(state => state.verifyUserReducer.user);
    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);
    const sourceBin = useSelector(state => state.placeProductsReducer.sourceBin);

    const [showDetailedDocComparison, setShowDetailedDocComparison] = useState(false);

    const createInventoryRegistration = dispatch(getConfParameterValue("wmsCreateInvRegistrationOnBinAmountChange")) == 1;

    useEffect(() => {
        if (createInventoryRegistration) dispatch(getReasonCodes(t));
    }, []);

    const getProductsSum = (products) => {
        let noOfProducts = 0;

        for (let i = 0, n = products.length; i < n; i++) {
            noOfProducts += Number(products[i].amount);
        }

        return roundFloatingPointError(noOfProducts);
    };

    const createDetailedDocComparison = () => {
        if (scannedProducts.length !== 0) {
            return <Transition visible={showDetailedDocComparison} animation='slide down' duration={200}>
                <Table className={"overflow"} color={"grey"} celled inverted unstackable columns={6}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign={"center"} colSpan={4}>{t("scannedProducts")}</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell>{t("code")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("name")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("binAmount")}</Table.HeaderCell>
                            <Table.HeaderCell>{t("scannedAmount")}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {getDetailedDocComparisonRows()}
                    </Table.Body>
                </Table>
            </Transition>
        }
    };

    const getDetailedDocComparisonRows = () => {
        return scannedProducts.map(product => getDetailedDocComparisonRow(product));
    };

    const getDetailedDocComparisonRow = (product) => {
        const sourceBinQuantity = sourceBinQuantities.find(quantity => quantity.productID == product.productID);
        const amountInBin = sourceBinQuantity ? sourceBinQuantity.amount : 0;

        return <Table.Row key={product.productID}>
            <Table.Cell>{product.code}</Table.Cell>
            <Table.Cell>{product.name}</Table.Cell>
            <Table.Cell>{amountInBin}</Table.Cell>
            <Table.Cell>{product.amount}</Table.Cell>
        </Table.Row>;
    };

    const handleConfirmGoodsOnClick = () => {
        if (scannedProducts.length === 0) return dispatch(errorMessageSet(t("noScannedProducts")));
        else if (createInventoryRegistration) return displayReturnReasonModal();

        dispatch(setModal(t("confirmation"), t("confirmBinCheck?"), confirmScan));
    };

    const displayReturnReasonModal = () => {
        const options = reasonCodes.map(reasonCode => ({key: reasonCode.reasonID, text: reasonCode.name, value: reasonCode.reasonID}));
        dispatch(setModal(t("enterReason"), "", (reasonCodeID) => confirmScan(reasonCodeID), false, options[0].value, options));
    };

    const confirmScan = async (reasonCodeID) => {
        const adjustBinQuantitiesParams = {request: "adjustBinQuantities"};
        const saveInventoryRegistrationParams = {
            request: "saveInventoryRegistration",
            creatorID: user.employeeID,
            warehouseID: selectedWarehouse.warehouseID,
            cause: t("binAmountCorrection"),
            reasonID: reasonCodeID
        };
        let adjustBinQuantitiesCounter = 0;
        let saveInventoryRegistrationCounter = 0;

        const addToParams = (productID, binQuantityAmount, inventoryRegistrationAmount) => {
            adjustBinQuantitiesParams[`binID${adjustBinQuantitiesCounter}`] = sourceBin.binID;
            adjustBinQuantitiesParams[`productID${adjustBinQuantitiesCounter}`] = productID;
            adjustBinQuantitiesParams[`newAmount${adjustBinQuantitiesCounter}`] = binQuantityAmount;
            adjustBinQuantitiesParams[`creatorID${adjustBinQuantitiesCounter}`] = user.employeeID;
            adjustBinQuantitiesCounter ++;

            if (createInventoryRegistration && inventoryRegistrationAmount !== 0) {
                saveInventoryRegistrationParams[`productID${saveInventoryRegistrationCounter}`] = productID;
                saveInventoryRegistrationParams[`amount${saveInventoryRegistrationCounter}`] = inventoryRegistrationAmount;
                saveInventoryRegistrationCounter ++;
            }
        };

        sourceBinQuantities.forEach(quantity => {
            const scannedProduct = scannedProducts.find(product => product.productID == quantity.productID);
            const binQuantityAmount = scannedProduct ? scannedProduct.amount : 0;
            const inventoryRegistrationAmount = binQuantityAmount - quantity.amount;
            addToParams(quantity.productID, binQuantityAmount, inventoryRegistrationAmount);
        });

        const scannedProductsNotInBin = scannedProducts.filter(product => !sourceBinQuantities.some(quantity => quantity.productID == product.productID));
        scannedProductsNotInBin.forEach(product => {
            addToParams(product.productID, product.amount, product.amount);
        });

        const canCreateInventoryRegistration = createInventoryRegistration && saveInventoryRegistrationCounter > 0;

        const saveRequests = [dispatch(makeErplyRequest(adjustBinQuantitiesParams, t("adjustBinQuantitiesError"), null, null, null, false, false))];
        if (canCreateInventoryRegistration) {
            saveRequests.push(dispatch(makeErplyRequest(saveInventoryRegistrationParams, t("saveInventoryRegistrationError"), null, null, null, false, false)));
        }

        dispatch(setIsLoading(true));
        const saveResponses = await Promise.all(saveRequests);
        let successMessage = `${t("binQuantitiesAdjusted")}!`;

        if (canCreateInventoryRegistration && !responseHasErrors(saveResponses[1])) {
            const inventoryRegistrationID = saveResponses[1][0].inventoryRegistrationID;
            const getInventoryRegistrationResponse = await dispatch(makeErplyRequest({
                    request: "getInventoryRegistrations",
                    inventoryRegistrationID: inventoryRegistrationID
            }, t("getInventoryRegistrationsError"), null, null, null, false, false));
            successMessage += ` ${t("inventoryRegistrationCreated")} ${t("withNo")} ${getInventoryRegistrationResponse[0].inventoryRegistrationNo}`;
        }

        dispatch(setIsLoading(false));
        dispatch(successMessageSet(successMessage));
        dispatch(componentSet("PlaceProducts"));
    };

    const handleChangeScannedProductsOnClick = (displayDifferencesOnly = false) => {
        // if (displayDifferencesOnly) {
        //     const productsWithDifferences = getProductsWithDifferences();
        //     if (productsWithDifferences.length === 0) return dispatch(errorMessageSet(t("noProductsWithScanAndBinAmountDifference")));
        //
        //     dispatch(setProductsWithDifferences(productsWithDifferences));
        // } else if (scannedProducts.length === 0) {
        //     return dispatch(errorMessageSet(t("noScannedProducts")));
        // }
        //
        // dispatch(setChangeScannedProductsDifferencesOnly(displayDifferencesOnly));
        if (scannedProducts.length === 0) return dispatch(errorMessageSet(t("noScannedProducts")));
        dispatch(componentSet("CheckGoodsInBinChangeScannedProducts"));
    };

    // const getProductsWithDifferences = () => {
    //     let products = [];
    //
    //     for (let i = 0, n = scannedProducts.length; i < n; i++) {
    //         const initialProductAmount = initialSubtractedRows.find(product => productsAreIdentical(componentSequence, selectedDocuments, product, summedScannedProducts[i]));
    //
    //         if (initialProductAmount === undefined || initialProductAmount.amount != summedScannedProducts[i].amount) {
    //             // Get products in all bins
    //             for (let j = 0, n = currentSessionScannedProducts.length; j < n; j++) {
    //                 if (productsAreIdentical(componentSequence, selectedDocuments, currentSessionScannedProducts[j], summedScannedProducts[i])) {
    //                     products.push(currentSessionScannedProducts[j]);
    //                 }
    //             }
    //         }
    //     }
    //
    //     if (!isAssembly) {
    //         // Add non-scanned products
    //         for (let i = 0, n = summedRows.length; i < n; i++) {
    //             const productIsScanned = currentSessionScannedProducts.some(product => productsAreIdentical(componentSequence, selectedDocuments, product, summedRows[i]));
    //
    //             if (!productIsScanned) {
    //                 const product = Object.assign({}, summedRows[i]);
    //                 if (!product.hasOwnProperty("name") && !product.hasOwnProperty("itemName")) {   // Inventory transfer rows
    //                     const name = getProductProperty(selectedDocumentsProducts, product.productID, "name");
    //                     product.itemName = name;  // This is so it would register as a non-scanned product
    //                     product.name = name;
    //                 }
    //
    //                 product.amount = 0;
    //                 products.push(product);
    //             }
    //         }
    //     }
    //
    //     return products;
    // };

    const handleGoBackOnClick = () => {
        dispatch(componentSet("RegisterToAddressScanProduct"));
    };

    return (
        <div>
            <Table onClick={() => setShowDetailedDocComparison(!showDetailedDocComparison)} celled inverted unstackable>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>{t("productsInBin")}</Table.Cell>
                        <Table.Cell>{getProductsSum(sourceBinQuantities)}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>{t("productsScanned")}</Table.Cell>
                        <Table.Cell>{getProductsSum(scannedProducts)}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            {createDetailedDocComparison()}
            <Button className={"menuBtn"} color={"green"} onClick={handleConfirmGoodsOnClick} size='big'>{t("confirmGoodsInBinCheck")}</Button>
            {/*<Button className={"menuBtn"} primary onClick={() => handleChangeScannedProductsOnClick(true)} size='big'>{t("changeDifferences")}</Button>*/}
            <Button className={"menuBtn"} primary onClick={handleChangeScannedProductsOnClick} size='big'>{t("changeScannedProducts")}</Button>
            <div className={"flex flexCenter"}>
                <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                <GoBackToStartBtn displayWarning={scannedProducts.length > 0} warning={t("confirmGoBackCheckGoodsInBin")} onYes={async () => {}}/>
            </div>
        </div>
    );
};

export default CheckGoodsInBinScanFinish
