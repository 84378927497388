const initialState = {
    confParameters: null,
    confParametersInitialised: false
};

export const getConfParametersReducer = (state = initialState, action) => {
    switch(action.type) {
        case "GET_CONF_PARAMETERS_SUCCESS":
            return {
                ...state,
                confParameters: action.payload,
            };
        case "SET_CONF_PARAMETERS":
            return {
                ...state,
                confParameters: setUpdatedParameters(state, action.payload)
            };
        case "SET_CONF_PARAMETERS_INITIALISED":
            return {
                ...state,
                confParametersInitialised: action.payload
            };
        default:
            return state;
    }
};

const setUpdatedParameters = (state, updatedValues) => {
    const confParametersClone = Object.assign({}, state.confParameters);
    for (let i = 0, n = updatedValues.length; i < n; i++) {
        confParametersClone[updatedValues[i].parameterName] = updatedValues[i].parameterValue;
    }

    return confParametersClone;
};
