import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Dropdown, Input, Modal} from "semantic-ui-react";
import {setModal} from "../actions/modal";
import {extractBoldTagsFromString} from "../util/misc";

const ModalPopup = () => {
    const modalMessage = useSelector(state => state.modalReducer.message);
    const modalHeading = useSelector(state => state.modalReducer.heading);
    const onYes = useSelector(state => state.modalReducer.onYes);
    const onNo = useSelector(state => state.modalReducer.onNo);
    const includeTextInput = useSelector(state => state.modalReducer.includeTextInput);
    const defaultInputValue = useSelector(state => state.modalReducer.inputValue);
    const dropdownOptions = useSelector(state => state.modalReducer.dropdownOptions);
    const showModal = modalMessage !== "" || modalHeading !== "";

    const [inputValue, setInputValue] = useState("");

    const inputRef = useRef(null);

    useEffect(() => {
        // Saving functions to Redux state does not persist them when restoring a session and the value ends up undefined
        // Close modal if a browser window refresh has been made and a modal had been displayed pre-refresh
        if (onYes === undefined) {
            close();
        }
    }, []);

    useEffect(() => {
        if (includeTextInput && inputRef.current) {
            setTimeout(() => {
                inputRef.current.focus();
                inputRef.current.select();
            })
        }
    }, [includeTextInput]);

    useEffect(() => {
        if (defaultInputValue !== "") {
            setInputValue(defaultInputValue)
        }
    }, [defaultInputValue]);

    const dispatch = useDispatch();
    const hasDropdown = dropdownOptions.length > 0;

    const close = () => {
        dispatch(setModal("", ""));
    };

    const onYesClick = () => {
        close();
        includeTextInput || hasDropdown ? onYes(inputValue) : onYes();
    };

    const onNoClick = () => {
        close();
        if (onNo !== null) onNo();
    };

    const getContent = () => {
        if (hasDropdown) {
            return <Dropdown fluid selection options={dropdownOptions} onChange={(event, { value }) => setInputValue(value)} value={inputValue}/>;
        } else if (includeTextInput) {
            return <Input ref={inputRef} onInput={e => setInputValue(e.target.value)} fluid value={inputValue}/>;
        } else {
            return <p>{extractBoldTagsFromString(modalMessage)}</p>;
        }
    };

    return (
        <div id={"modal"}>
            <Modal size={"mini"} open={showModal} onClose={onNoClick}>
                <Modal.Header>{modalHeading}</Modal.Header>
                <Modal.Content>
                    {getContent()}
                </Modal.Content>
                <Modal.Actions className={"flex justifyContentSpaceEvenly"}>
                    <Button className={"widthSmall"} negative onClick={onNoClick} icon='times'/>
                    <Button className={"widthSmall"} positive onClick={onYesClick} icon='checkmark'/>
                </Modal.Actions>
            </Modal>
        </div>
    )
};

export default ModalPopup;
