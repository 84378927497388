import React, {useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Icon, Message} from "semantic-ui-react";
import {errorMessageSet, messageRemove, setHideMessageTimeoutIsSet} from "../actions/headerMessage";
import LanguageSelect from "./LanguageSelect";
import {useTranslation} from "react-i18next";
import {
    componentSet,
    setPreSettingsComponent,
    setPreSettingsSequence, setSequence,
    setSettingsBtnHasBeenClicked
} from "../actions/component";
import {capitalise, documentIsCreditInvoice, exitSettingsView} from "../util/misc";

const Header = () => {
    const errorMessage = useSelector(state => state.headerMessageReducer.errorMessage);
    const successMessage = useSelector(state => state.headerMessageReducer.successMessage);
    const warningMessage = useSelector(state => state.headerMessageReducer.warningMessage);
    const delayedClose = useSelector(state => state.headerMessageReducer.delayedClose);
    const hideMessageTimeoutIsSet = useSelector(state => state.headerMessageReducer.hideMessageTimeoutIsSet);
    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);
    const componentSequence = useSelector(state => state.componentReducer.componentSequence);
    const component = useSelector(state => state.componentReducer.component);
    const settingsBtnHasBeenClicked = useSelector(state => state.componentReducer.settingsBtnHasBeenClicked);
    const selectedDocuments = useSelector(state => state.scanReducer.selectedDocuments);
    const preSettingsSequence = useSelector(state => state.componentReducer.preSettingsSequence);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const user = useSelector(state => state.verifyUserReducer.user);
    const createDocumentType = useSelector(state => state.createDocumentReducer.documentType);  // Type of document being created in CreateDocument component
    const scanBySupplier = useSelector(state => state.scanReducer.scanBySupplier);
    const destinationWarehouse = useSelector(state => state.scanReducer.destinationWarehouse);

    const isOutScan = componentSequence.includes("ProductsOut");
    const isAssembly = useSelector(state => state.scanReducer.isAssembly);
    const isInventoryTransfer = componentSequence.includes("InventoryTransfer");
    const isCreditInvoice = documentIsCreditInvoice(componentSequence);
    const isScanBySupplier = scanBySupplier !== null;

    const closeMessageTimeout = useRef(0);

    const warehouseName = selectedWarehouse !== null ? selectedWarehouse.name : "";

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const displayMessage = () => {
        if (errorMessage !== "") {
            return getMessage( false, true, errorMessage)
        } else if (successMessage !== "") {
            return getMessage( true, false, successMessage)
        } else if (warningMessage !== "") {
            return getMessage( false, false, warningMessage)
        } else {
            return null;
        }
    };

    const getMessage = (positive, negative, text) => {
        if (!hideMessageTimeoutIsSet) { // Prevent setting new timeouts on old messages when changing components
            clearTimeout(closeMessageTimeout.current);

            if (delayedClose !== null) {
                closeMessageTimeout.current = setTimeout(dismissMessage, delayedClose);
            }
            dispatch(setHideMessageTimeoutIsSet(true));
        }

        return <Message id={"message"} positive={positive} negative={negative} onDismiss={dismissMessage} color={!positive && !negative ? "yellow" : ""}>
            <Message.Header>{text}</Message.Header>
        </Message>
    };

    const dismissMessage = () => {
        dispatch(messageRemove());
    };

    const getDisplayableComponentSequence = () => {
        if (component === "Login") {
            return [];
        } else {
            const nonDisplayableComponents = ["OrderListConfirmation", "SearchOrders", "FulfillableOrders", "FulfillableOrdersFilters", "Login", "ChooseWarehouse", "MainMenu",
                "ReplenishmentProductSelected", "DpdParcelLabels", "DpdParcelLabelsNew", "VenipakParcelLabels", "SearchInventoryTransfers", "InventoryTransfersListConfirmation", "AssignmentList"];
            const separator = "->";
            let displayableSequence = [];

            for (let i = 0, n = componentSequence.length; i < n; i++) {
                if (!nonDisplayableComponents.includes(componentSequence[i])) {    // To shorten header text
                    if (componentSequence[i] === "Scan") {
                        if (isScanBySupplier) {
                            displayableSequence.push(<span key={"headerDocNoText"} className={"headerDocNoText"}>{scanBySupplier.name}</span>);
                        } else {
                            let docNos = getDocNos();

                            if (docNos != 0) {  //Exclude unconfirmed credit invoices
                                if (isInventoryTransfer && isOutScan && destinationWarehouse) {
                                    docNos += `(${destinationWarehouse.name})`;
                                }

                                displayableSequence.push(`${t("numberShort")}`, <span key={"headerDocNoText"} className={"headerDocNoText"}> {docNos}</span>);
                            }
                        }
                    } else if (componentSequence[i] === "CreateDocument") {
                        displayableSequence.push(`${t("create")} ${t(createDocumentType)}`);
                    } else if (componentSequence[i] === "ReturnsListConfirmation" && isOutScan) {
                        displayableSequence.push(capitalise(t("purchaseReturn")));
                    } else {
                        displayableSequence.push(t(componentSequence[i]));
                    }

                    if (i !== n - 1) {
                        displayableSequence.push(separator)
                    }
                }
            }

            if (displayableSequence.includes(t("Settings"))) {
                const settingsIndex = displayableSequence.indexOf(t("Settings"));
                displayableSequence = displayableSequence.slice(settingsIndex); // Show only settings-related components
            }

            if (displayableSequence[displayableSequence.length - 1] === separator) {
                displayableSequence.pop();
            }

            return displayableSequence;
        }
    };

    const getDocNos = () => {
        let docNos = [];

        for (let i = 0, n = selectedDocuments.length; i < n; i++) {
            if (isInventoryTransfer) {
                docNos.push(selectedDocuments[i].inventoryTransferNo);
            } else if (isAssembly) {
                docNos.push(`${selectedDocuments[i].inventoryRegistrationNo} komplekteerimine`);
            } else if (isOutScan || isCreditInvoice) { // Sales documents
                docNos.push(selectedDocuments[i].number);
            } else {    // Purchase orders
                let docNo = selectedDocuments[i].regnumber;
                if (selectedDocuments[i].number !== "") {
                    docNo += `(${selectedDocuments[i].number})`;
                }
                docNos.push(docNo);
            }
        }
        return docNos.join(",");
    };

    const handleSettingsOnClick = () => {
        const isAdmin = confParameters.wmsAdminUserGroup.split(",").includes(user.groupID) || user.groupName.toLowerCase().includes("admin");

        if (!isAdmin) {
            dispatch(errorMessageSet(t("settingsAdminOnly")));
        } else if (!settingsBtnHasBeenClicked) {
            dispatch(setPreSettingsSequence(componentSequence.slice()));
            dispatch(setSettingsBtnHasBeenClicked(true));
            dispatch(setPreSettingsComponent(component));
            dispatch(componentSet("Settings"));
        } else if (component === "Settings") {
            dispatch(exitSettingsView());
        } else {    // In bin management view
            dispatch(setSequence(preSettingsSequence.slice()));
            dispatch(componentSet("Settings"));
        }
    };

    const WmsHeader = <div id={"header"}>
        <LanguageSelect/>
        {component !== "Login" && component !== "ChooseWarehouse" ?
            <Button onClick={handleSettingsOnClick} color={"blue"} size={"mini"} circular id={"settingsBtn"} icon>
                <Icon size={"large"} name='setting' />
            </Button>
            : null}
        <p className={"headerText"} id={"headerWarehouse"}>{warehouseName}</p>
        <br/>
        <p className={"headerText"} id={"headerSequenceText"}>{getDisplayableComponentSequence()}</p>
    </div>;

    return (
        <div id={"headerContainer"}>
            {WmsHeader}
            {displayMessage()}
        </div>
    )
};

export default Header;
