export const errorMessageSet = (text, delayedClose = 7000) => {
    return {
        type: "ERROR_MESSAGE_SET",
        payload: {
            text: text,
            delayedClose: delayedClose
        }
    }
};

export const successMessageSet = (text, delayedClose = 6000) => {
    return {
        type: "SUCCESS_MESSAGE_SET",
        payload: {
            text: text,
            delayedClose: delayedClose
        }
    }
};

export const warningMessageSet = (text, delayedClose = 6000) => {
    return {
        type: "WARNING_MESSAGE_SET",
        payload: {
            text: text,
            delayedClose: delayedClose
        }
    }
};

// Prevent setting new timeouts on old messages when changing components
export const setHideMessageTimeoutIsSet = (hideMessageTimeoutIsSet) => {
    return {
        type: "SET_HIDE_MESSAGE_TIMEOUT_IS_SET",
        payload: hideMessageTimeoutIsSet
    }
};

export const messageRemove = () => {
    return {
        type: "MESSAGE_REMOVE"
    }
};
