export const getConfParametersSuccess = (confParameters) => {
    return {
        type: "GET_CONF_PARAMETERS_SUCCESS",
        payload: confParameters[0]
    }
};

export const setConfParameters = (confParameters) => {
    return {
        type: "SET_CONF_PARAMETERS",
        payload: confParameters
    }
};

export const setConfParametersInitialised = (confParametersInitialised) => {
    return {
        type: "SET_CONF_PARAMETERS_INITIALISED",
        payload: confParametersInitialised
    }
};
