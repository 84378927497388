const initialState = {
    serviceEndpoints: null,
    mailboxes: [],
    cafaConfParameters: []
};

export const newErplyApisReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_SERVICE_ENDPOINTS":
            return {
                ...state,
                serviceEndpoints: action.payload,
            };
        case "SET_MAILBOXES":
            return {
                ...state,
                mailboxes: action.payload,
            };
        case "SET_CAFA_CONF_PARAMETERS":
            return {
                ...state,
                cafaConfParameters: action.payload,
            };
        default:
            return state;
    }
};
