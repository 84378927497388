import React, { useState } from 'react';
import {Button} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {componentSet} from "../actions/component";
import {useDispatch, useSelector} from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {
    makeErplyBulkRequest,
    makeErplyRequest, makeJsonApiRequest
} from "../util/erplyRequests";
import {errorMessageSet} from "../actions/headerMessage";
import GoBackToStartBtn from "./GoBackToStartBtn";
import GoBackBtn from "./GoBackBtn";
import {filterOutDocumentsWithNoRows, formatDate} from "../util/misc";
import SupplierSearchInput from "./SupplierSearchInput";
import {
    setSearchResultFollowUpDocuments,
    setSearchResultFollowUpDocumentsAdditionalOrders,
    setSearchResultDocuments,
    setSearchResultFollowUpDocumentsJsonAttributes
} from "../actions/searchDocuments";
import DatePickerComponent from "./DatePickerComponent";
import {isTAF} from "../util/isClient";

const SearchOrders = () => {
    const getDefaultFromDate = () => {
        let d = new Date();
        d.setMonth(d.getMonth() - 3);
        return d;
    };

    const [fromDate, setFromDate] = useState(getDefaultFromDate);
    const [toDate, setToDate] = useState(new Date());
    const [supplierID, setSupplierID] = useState("");

    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);
    const isGetUndeliveredGoods = useSelector(state => state.searchDocumentsReducer.isGetUndeliveredGoods);// Get purchase invoices to calculate undelivered goods, Rehvid Pluss only
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const clientCode = useSelector(state => state.verifyUserReducer.clientCode);

    const multiplePurchaseOrderScanEnabled = confParameters.wmsEnableMultiplePurchaseOrderScan == 1;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleGoBackOnClick = () => {
        const component = isGetUndeliveredGoods ? "ProductsIn" : "PurchaseOrder";
        dispatch(componentSet(component));
    };

    const searchPurchaseDocuments = async () => {
        if (supplierID === "") {
            dispatch(errorMessageSet(t("selectSupplier")));
        } else {
            const params = {
                request: "getPurchaseDocuments",
                type: isGetUndeliveredGoods ? "PRCINVOICEONLY" : "PRCORDER",
                supplierID: supplierID,
                getRowsForAllInvoices: 1,
                confirmed: 1,
                warehouseID: selectedWarehouse.warehouseID
            };

            if (isGetUndeliveredGoods) {
                params.stateID = 3; // For some reason, purchase invoices' "status" does not change on status change
                params.dateFrom = formatDate(fromDate);
                params.dateTo = formatDate(toDate);
            } else {
                params.status = "READY";
                params.shipDateFrom = formatDate(fromDate);
                params.shipDateTo = formatDate(toDate);
            }

            const getReadyOrdersRequest = dispatch(makeErplyRequest(params, t("getPurchaseDocumentsError"), null, null, null, true));
            if (isGetUndeliveredGoods) {
                params.stateID = 5;
            } else {
                params.status = "PARTIALLY_RECEIVED";
            }

            const getPartiallyReceivedOrdersRequest = dispatch(makeErplyRequest(params, t("getPurchaseDocumentsError"), null, null, null, true));
            const [readyOrders, partiallyReceivedOrders] = await Promise.all([getReadyOrdersRequest, getPartiallyReceivedOrdersRequest]);
            let purchaseDocuments = readyOrders.concat(partiallyReceivedOrders);
            if (isTAF(clientCode)) purchaseDocuments = purchaseDocuments.filter(doc => doc.stateID != 10 && doc.stateID != 11); // 10 = Cancelled, 11 = Closed
            purchaseDocuments = purchaseDocuments.sort((a, b) => a.regnumber - b.regnumber);
            const filteredPurchaseDocuments = filterOutDocumentsWithNoRows(purchaseDocuments);

            if (filteredPurchaseDocuments.length === 0) {
                dispatch(errorMessageSet(t("criteriaDocsNotFound")));
            } else {
                if (isGetUndeliveredGoods) {
                    goToPurchaseDocumentList(filteredPurchaseDocuments);
                } else {
                    const followUpDocuments = await getFollowUpDocuments(filteredPurchaseDocuments);    // Needed to subtract follow-up documents' amounts to get scannable amount
                    let requests = [getFollowUpDocumentsOrdersNotInSearchResults(followUpDocuments, filteredPurchaseDocuments)];    // Needed to subtract follow-up documents' amounts to get scannable amount

                    if (multiplePurchaseOrderScanEnabled) {
                        const followUpDocumentsIDs = [...new Set(followUpDocuments.map(document => document.id))];

                        if (followUpDocumentsIDs.length > 0) {
                            requests.push(dispatch(makeJsonApiRequest(t, `v1/json_object/prcinvoice/${followUpDocumentsIDs.join(";")}`, "GET", null)));
                        }
                    }

                    let [additionalOrders, followUpDocumentsJsonAttributes] = await Promise.all(requests);

                    if (multiplePurchaseOrderScanEnabled && followUpDocumentsJsonAttributes) {
                        if (!Array.isArray(followUpDocumentsJsonAttributes)) {
                            followUpDocumentsJsonAttributes = [followUpDocumentsJsonAttributes];
                        }
                        dispatch(setSearchResultFollowUpDocumentsJsonAttributes(followUpDocumentsJsonAttributes));
                    }

                    dispatch(setSearchResultFollowUpDocumentsAdditionalOrders(additionalOrders));
                    dispatch(setSearchResultFollowUpDocuments(followUpDocuments));
                    goToPurchaseDocumentList(filteredPurchaseDocuments);
                }
            }
        }
    };

    const goToPurchaseDocumentList = (purchaseDocuments) => {
        dispatch(setSearchResultDocuments(purchaseDocuments));
        dispatch(componentSet("OrderListConfirmation"));
    };

    const getFollowUpDocumentsOrdersNotInSearchResults = (followUpDocuments, orders) => {
        let ids = [];

        for (let i = 0, n = followUpDocuments.length; i < n; i++) {
            for (let j = 0, n = followUpDocuments[i].baseDocuments.length; j < n; j++) {
                let orderIsInSearchResults = false;

                for (let k = 0, n = orders.length; k < n; k++) {
                    if (followUpDocuments[i].baseDocuments[j].id == orders[k].id) {
                        orderIsInSearchResults = true;
                        break;
                    }
                }

                if (!orderIsInSearchResults) {
                    ids.push(followUpDocuments[i].baseDocuments[j].id);
                }
            }
        }

        if (ids.length > 0) {
            let requests = [];

            for (let i = 0, n = ids.length; i < n; i++) {
                const request = {
                    requestName: "getPurchaseDocuments",
                    id: ids[i],
                    requestID: i
                };

                requests.push(request);
            }

            return dispatch(makeErplyBulkRequest(requests, t("getPurchaseDocumentsError"), null, null, null, true, true));
        } else {
            return Promise.resolve([]);
        }
    };

    const getFollowUpDocuments = (orders) => {
        const followUpDocumentsIDs = getFollowUpDocumentIDs(orders);

        if (followUpDocumentsIDs.length === 0) {
            return Promise.resolve([]);
        } else {
            let requests = [];

            for (let i = 0, n = followUpDocumentsIDs.length; i < n; i++) {
                const request = {
                    requestName: "getPurchaseDocuments",
                    id: followUpDocumentsIDs[i],
                    requestID: i + 1
                };

                requests.push(request)
            }

            return dispatch(makeErplyBulkRequest(requests, t("getPurchaseDocumentsError"), null, null, null, true, true));
        }
    };

    const getFollowUpDocumentIDs = (orders) => {
        let followUpDocumentsIDs = [];

        for (let i = 0, n = orders.length; i < n; i++) {
            for (let j = 0, n = orders[i].baseToDocuments.length; j < n; j++) {
                followUpDocumentsIDs.push(orders[i].baseToDocuments[j].id);
            }
        }

        return followUpDocumentsIDs;
    };

    return (
        <div>
            <DatePickerComponent labelText={isGetUndeliveredGoods ? "dateFrom" : "shipDateFrom"} value={fromDate} setStateFunction={setFromDate} className={"searchInput"}/>
            <DatePickerComponent labelText={isGetUndeliveredGoods ? "dateTo" : "shipDateTo"} value={toDate} setStateFunction={setToDate} className={"searchInput"}/>
            <SupplierSearchInput setSupplierID={setSupplierID}/>
            <div className={"btnsGroup"}>
                <Button className={"menuBtn"} color={"blue"} size={"big"} onClick={searchPurchaseDocuments}>{t("search")} </Button>
                <div className={"flex flexCenter"}>
                    <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                    <GoBackToStartBtn/>
                </div>
            </div>
        </div>
    );
};

export default SearchOrders
