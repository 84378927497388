import {makeErplyRequest} from "./erplyRequests";

export const getSsccNo = async (dispatch) => {
    const lastSsccCounter = await getLastSsccCounter(dispatch);
    const newSsccCounter = lastSsccCounter == 9999999 ? 0 : lastSsccCounter + 1;
    saveNewSsccCounter(newSsccCounter, dispatch);
    return generateSsccCode(newSsccCounter);
};

const getLastSsccCounter = async (dispatch) => {
    const params = {
        request: "getObjects",
        objectID: 52,   // SSCC object ID for Prime Partner
        partnerKey: "9026f7d802b50a16765dd1c1fec8b121cd77a38d", // Needed to save/get objects, must be separately requested for each account
    };

    const objects = await dispatch(makeErplyRequest(params, "Failed to get last SSCC counter", null, null, null));
    return Number(objects[0].attributes.find(attribute => attribute.attributeName === "ssccCounter").attributeValue);
};

const generateSsccCode = (counter) => {
    const extensionDigit = "1";
    const primePartnerCompanyPrefix = "474200200";
    let sscc = extensionDigit + primePartnerCompanyPrefix + String(counter).padStart(7, "0");
    const checkDigit = calculateCheckDigit(sscc);
    const barCodeStart = "00";
    sscc = barCodeStart + sscc + checkDigit;
    return sscc;
};

const calculateCheckDigit = (sscc) => {
    let sum = 0;
    for (let i = 0, n = sscc.length; i < n; i++) {
        const indexIsOdd = i % 2;
        const multiplier = indexIsOdd ? 1 : 3;
        sum += Number(sscc.substr(i, 1)) * multiplier;
    }

    const nearestEqualOrHigherMultipleOfTen = Math.ceil(sum / 10) * 10;
    return nearestEqualOrHigherMultipleOfTen - sum;
};

const saveNewSsccCounter = (counter, dispatch) => {
    const params = {
        request: "saveObject",
        objectID: 52,
        partnerKey: "9026f7d802b50a16765dd1c1fec8b121cd77a38d",
        group: "sscc",
        attributeName0: "ssccCounter",
        attributeValue0: counter,
        attributeType0: "integer"
    };

    dispatch(makeErplyRequest(params, "Failed to save new SSCC counter", null, null, null, false, false));
};

export const addSsccToInternalNotes = (params, sscc) => {
    const ssccText = `SSCC numbers: ${sscc};`;

    if (params.internalNotes) {
        params.internalNotes += "\n" + ssccText;
    } else {
        params.internalNotes = ssccText;
    }

    return params;
};
