import React, {useState} from 'react';
import {Button, Table} from "semantic-ui-react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {componentSet} from "../actions/component";
import {makeErplyRequest} from "../util/erplyRequests";
import {setSelectedBin} from "../actions/replenishment";
import {setCart} from "../actions/getBins";
import GoBackToStartBtn from "./GoBackToStartBtn";
import GoBackBtn from "./GoBackBtn";
import {getElementByFieldValue, isSelectedRow, setExpandedRow, translateAccordingToCountry} from "../util/misc";

const Replenishment = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [expandedRows, setExpandedRows] = useState([]);

    const bins = useSelector(state => state.replenishmentReducer.binsAvailableForReplenishment);
    const products = useSelector(state => state.getProductsReducer.products);
    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);
    const cart = useSelector(state => state.getBinsReducer.cart);   // An intermediary bin holding products between scanning and confirming replenishment
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);

    const createTable = () => {
        return <Table color={"grey"} celled inverted unstackable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{t("bin")}</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2}>{t("code")}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {createTableRows()}
            </Table.Body>
        </Table>
    };

    const createTableRows = () => {
        return bins.map((bin, index) => createTableRow(bin, index));
    };

    const createTableRow = (bin, index) => {
        const isSelected = isSelectedRow(index, expandedRows);
        const name = getElementByFieldValue(products, "productID", bin.productID).name;
        bin.name = name;

        // Siin vb peab ka code asendama seadetest kuvatava koodiga
        let row= [<Table.Row key={index}>
            <Table.Cell onClick={() => setExpandedRow(index, expandedRows, setExpandedRows)}>{bin.binCode}</Table.Cell>
            <Table.Cell onClick={() => setExpandedRow(index, expandedRows, setExpandedRows)}>{bin.code}</Table.Cell>
            <Table.Cell className={"smallCell"}><Button onClick={() => handleReplenishOnClick(bin)}>{t("replenish")}</Button></Table.Cell>
        </Table.Row>];

        if (isSelected) {
            row.push(createExtraInfoTable(bin, name))
        }

        return row;
    };

    const createExtraInfoTable = (bin) => {
        return (
            <tr key={`${bin.binID}:${bin.productID}`}>{/* Didn't know what else to use as key*/}
                <td colSpan={3}>
                    <Table celled structured unstackable inverted>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell className={"tableHeading"} width={1}>{translateAccordingToCountry(t("EAN"), confParameters)}</Table.Cell>
                                <Table.Cell>{bin.code2}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"tableHeading"} width={1}>{t("product")}</Table.Cell>
                                <Table.Cell>{bin.name}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"tableHeading"} width={1}>{t("amount")}</Table.Cell>
                                <Table.Cell>{bin.amount}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"tableHeading"} width={1}>{t("minAmount")}</Table.Cell>
                                <Table.Cell>{bin.replenishmentMinimum}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"tableHeading"} width={1}>{t("maxAmount")}</Table.Cell>
                                <Table.Cell>{bin.maximumAmount}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"tableHeading"} width={1}>{t("amountTotalInUnpreferredBins")}</Table.Cell>
                                <Table.Cell>{getAmountsSum(bin.amountsInUnpreferredBins)}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </td>
            </tr>
        )
    };

    const getAmountsSum = (amounts) => {
        let sum = 0;
        for (let i = 0, n = amounts.length; i < n; i++) {
            sum += amounts[i].amount;
        }

        return sum;
    };

    const handleReplenishOnClick = (bin) => {
        if (cart === null) {
            getCart();
        }

        dispatch(setSelectedBin(bin));
        dispatch(componentSet("ReplenishmentProductSelected"));
    };

    const getCart = () => {
        const params = {
            request: "getBins",
            status: "ACTIVE",
            code: "cart",
            warehouseID: selectedWarehouse.warehouseID
        };

        dispatch(makeErplyRequest(params, t("getBinsError"), null, setCart, null)).then((bins) => {
            if (bins.length === 0) {
                console.log("Creating cart...");
                createCart();
            }
        });
    };

    const createCart = () => {
        const params = {
            request: "saveBin",
            code: "cart",
            warehouseID: selectedWarehouse.warehouseID
        };

        dispatch(makeErplyRequest(params, t("saveBinError"), null, setCart, null));
    };

    const handleGoBackOnClick = () => {
        dispatch(componentSet("MainMenu"));
    };

    return (
        <div>
            {createTable()}
            <div className={"flex flexCenter"}>
                <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                <GoBackToStartBtn/>
            </div>
        </div>
    );
};

export default Replenishment
