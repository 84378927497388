import React, {useEffect} from 'react';
import {Button, Dimmer, Icon, Loader} from 'semantic-ui-react'
import {errorMessageSet} from "../actions/headerMessage";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {componentSet} from "../actions/component";
import {logout} from "../actions/verifyUser";
import {makeErplyBulkRequest, makeErplyRequest} from "../util/erplyRequests";
import {getBinsSuccess, setReceivingArea} from "../actions/getBins";
import {setBinsAvailableForReplenishment} from "../actions/replenishment";
import {getProductsSuccess} from "../actions/getProducts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons'
import {
    setBinQuantities, setExpandedProductIDs,
    setExpandedStocktakingIDs,
    setProductIDsOfBinQuantitiesRequested,
    setProducts, setProductStock, setStocktakingReadings
} from "../actions/stocktakings";

const MainMenu = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const confParametersInitialised = useSelector(state => state.getConfParametersReducer.confParametersInitialised);
    const receivingArea = useSelector(state => state.getBinsReducer.receivingArea);

    useEffect(() => {
        checkForReceivingArea();    // Get receiving area bin for future use, create receiving area bin if it doesn't exist
    }, []);

    const checkForReceivingArea = () => {
        if (receivingArea === null || receivingArea.warehouseID != selectedWarehouse.warehouseID) { //Receiving area doesn't exist or is of previously selected warehouse
            const params = {
                request: "getBins",
                status: "ACTIVE",
                code: "receiving_area",
                warehouseID: selectedWarehouse.warehouseID
            };

            dispatch(makeErplyRequest(params, t("getBinsError"), null, setReceivingArea, null)).then((bins) => {
                if (bins.length === 0) {
                    console.log("Creating receiving_area...");
                    createReceivingArea();
                }
            });
        }
    };

    const createReceivingArea = () => {
        const params = {
            request: "saveBin",
            code: "receiving_area",
            warehouseID: selectedWarehouse.warehouseID,
            preferred: 1
        };

        dispatch(makeErplyRequest(params, t("saveBinError"), null, setReceivingArea, null));
    };

    const handleProductsInOnClick = () => {
        dispatch(componentSet("ProductsIn"));
    };

    const handleProductsOutOnClick = () => {
        dispatch(componentSet("ProductsOut"));
    };

    const handlePlaceProductsOnClick = () => {
        dispatch(componentSet("PlaceProducts"));
    };

    const handleReplenishmentOnClick = async () => {
        const bins = await getReplenishmentBins();
        const binsAvailableForReplenishment = await getBinsAvailableForReplenishment(bins);

        if (binsAvailableForReplenishment.length === 0) {
            dispatch(errorMessageSet(t("noBinsAvailableForReplenishment")));
        } else {
            dispatch(setBinsAvailableForReplenishment(binsAvailableForReplenishment));
            dispatch(componentSet("Replenishment"));
        }
    };

    const getBinsAvailableForReplenishment = async (bins) => {
        const binQuantities = await getReplenishmentBinQuantities(bins);
        const productAmountsInUnpreferredBins = await getProductAmountsInUnpreferredBins(binQuantities);
        let binsAvailableForReplenishment = [];
        let productIDs = [];

        for (let i = 0, n = binQuantities.length; i < n; i++) {
            for (let k = 0, n = binQuantities[i].records.length; k < n; k++) {
                for (let j = 0, n = bins.length; j < n; j++) {
                    if (binQuantities[i].records[k].binID === bins[j].binID) {
                        if (binQuantities[i].records[k].amount < bins[j].replenishmentMinimum) {
                            const amountsInUnpreferredBins = getAmountsInUnpreferredBins(productAmountsInUnpreferredBins, binQuantities[i].records[k].productID);
                            // Needed if getReplenishmentBinQuantities getBinQuantities didn't return product info due to no bin records
                            const productInfo = productAmountsInUnpreferredBins.find(product => product.productID == binQuantities[i].records[k].productID);

                            if (amountsInUnpreferredBins.length !== 0) {
                                const binAvailableForReplenishment = {
                                    binID: bins[j].binID,
                                    binCode: bins[j].code,
                                    productID: binQuantities[i].records[k].productID,
                                    code: productInfo.productCode,
                                    code2: productInfo.productCode2,
                                    amount: binQuantities[i].records[k].amount,
                                    replenishmentMinimum: bins[j].replenishmentMinimum,
                                    maximumAmount: bins[j].maximumAmount,
                                    amountsInUnpreferredBins: amountsInUnpreferredBins
                                };

                                productIDs.push(binQuantities[i].records[k].productID);
                                binsAvailableForReplenishment.push(binAvailableForReplenishment);
                            }
                        }
                    }
                }
            }
        }

        productIDs = [...new Set(productIDs)].join(",");
        if (productIDs !== "") {
            await getProducts(productIDs);
        }

        return binsAvailableForReplenishment;
    };

    const getProducts = (productIDs) => {
        const params = {
            request: "getProducts",
            productIDs: productIDs
        };

        return dispatch(makeErplyRequest(params, t("getProductsError"), null, getProductsSuccess, null, true));
    };

    // Single product
    const getAmountsInUnpreferredBins = (productAmountsInUnpreferredBins, productID) => {
        let amounts = [];

        for (let i = 0, n = productAmountsInUnpreferredBins.length; i < n; i++) {
            if (productAmountsInUnpreferredBins[i].productID == productID) {
                const amount = {
                    binID: productAmountsInUnpreferredBins[i].binID,
                    binCode: productAmountsInUnpreferredBins[i].binCode,
                    amount: Number(productAmountsInUnpreferredBins[i].amount)
                };

                amounts.push(amount);
            }
        }

        return amounts;
    };

    const getReplenishmentBinQuantities = async (bins) => {
        let requests = [];
        let getBinQuantitiesRequestID = 1;
        let productCodes = [];
        let products = [];

        for (let i = 0, n = bins.length; i < n; i++) {
            if (bins[i].allowedProduct !== "") {
                const allowedProductCodesArray = bins[i].allowedProduct.split(",");
                productCodes = productCodes.concat(allowedProductCodesArray);
            }
        }

        if (productCodes.length > 0) {
            products = await getProductsByCode([...new Set(productCodes)]);
        }

        for (let i = 0, n = bins.length; i < n; i++) {
            if (bins[i].allowedProduct !== "") {
                let allowedProductsIDs = [];
                const allowedProductCodesArray = bins[i].allowedProduct.split(",");

                for (let i = 0, n = allowedProductCodesArray.length; i < n; i++) {
                    const allowedProduct = products.find(product => product.code == allowedProductCodesArray[i]);

                    if (allowedProduct !== undefined) {
                        allowedProductsIDs.push(allowedProduct.productID);
                    }
                }

                if (allowedProductsIDs.length !== 0) {
                    const request = {
                        requestName: "getBinQuantities",
                        binIDs: bins[i].binID,
                        productIDs: allowedProductsIDs.join(","),
                        warehouseID: selectedWarehouse.warehouseID,
                        requestID: getBinQuantitiesRequestID
                    };

                    requests.push(request);
                    getBinQuantitiesRequestID ++;
                }
            }
        }

        return dispatch(makeErplyBulkRequest(requests, t("getBinQuantitiesError"))).then((responseRequests) => {
            // Manually add quantities for allowed products with no bin records in bin (API does not return them)
            for (let i = 0, n = responseRequests.length; i < n; i++) {
                if (responseRequests[i].records.length === 0) {
                    const sentRequest = requests.find(request => request.requestID == responseRequests[i].status.requestID);
                    const productIDs = sentRequest.productIDs.split(",");

                    for (let j = 0, n = productIDs.length; j < n; j++) {
                        responseRequests[i].records.push(
                            {
                                binID: sentRequest.binIDs,
                                productID: productIDs[j],
                                amount: 0
                            }
                        )
                    }
                }
            }

            return responseRequests;
        });
    };

    const getProductsByCode = (productCodesArray) => {
        let requests = [];

        for (let i = 0, n = productCodesArray.length; i < n; i++) {
            const request = {
                requestName: "getProducts",
                code: productCodesArray[i],
                requestID: i
            };

            requests.push(request);
        }

        return dispatch(makeErplyBulkRequest(requests, t("getProductsError"), null, getProductsSuccess, null)).then((requests) => {
            return requests.filter(request => request.records.length > 0).map(request => request.records[0]);
        });
    };

    //All products
    const getProductAmountsInUnpreferredBins = (binQuantities) => {
        let productIDs = [];
        for (let i = 0, n = binQuantities.length; i < n; i++) {
            for (let j = 0, n = binQuantities[i].records.length; j < n; j++) {
                productIDs.push(binQuantities[i].records[j].productID);
            }
        }
        productIDs = [...new Set(productIDs)].join(",");

        const params = {
            request: "getBinQuantities",
            productIDs: productIDs,
            warehouseID: selectedWarehouse.warehouseID,
            preferred: 0,
            minimumAmount: 1
        };

        return dispatch(makeErplyRequest(params, t("getBinQuantitiesError"), null, null, null, true));
    };

    const getReplenishmentBins = () => {
        const params = {
            request: "getBins",
            warehouseID: selectedWarehouse.warehouseID,
            status: "ACTIVE",
            preferred: 1
        };

        return dispatch(makeErplyRequest(params, t("getBinsError"), null, null, null, true));
    };

    const handleAmountsInBinsOnClick = () => {
        getAmountsInBinsBins().then((bins) => {
            dispatch(componentSet("AmountsInBins"));
        });
    };

    const getAmountsInBinsBins = () => {
        const params = {
            request: "getBins",
            warehouseID: selectedWarehouse.warehouseID,
            status: "ACTIVE"
        };

        return dispatch(makeErplyRequest(params, t("getBinsError"), null, getBinsSuccess, null, true));
    };

    const handleGoBackOnClick = () => {
        dispatch(componentSet("ChooseWarehouse"));
    };

    const handleLogOutOnClick = () => {
        dispatch(logout());
    };

    const handleStocktakingsOnClick = () => {
        dispatch(setProductIDsOfBinQuantitiesRequested([]));
        dispatch(setExpandedStocktakingIDs([]));
        dispatch(setProducts([]));
        dispatch(setBinQuantities([]));
        dispatch(setProductStock([]));
        dispatch(setExpandedProductIDs([]));
        dispatch(setStocktakingReadings([]));
        dispatch(componentSet("Stocktakings"));
    };

    const handleActiveDocumentReadOnlyViewOnClick = () => {
        dispatch(componentSet("ActiveDocumentReadOnlyView"));
    };

    const getButtons = () => {
        const buttons = [];

        if (confParameters.wmsEnabledModules.includes("ProductsIn")) {
            buttons.push(<Button key={"ProductsIn"} className={"menuBtn"} onClick={handleProductsInOnClick} size='big'><Icon name={"sign-in"}/>{t("ProductsIn")}</Button>)
        }

        if (confParameters.wmsEnabledModules.includes("ProductsOut")) {
            buttons.push(<Button key={"ProductsOut"} className={"menuBtn"} onClick={handleProductsOutOnClick} size='big'><Icon name={"sign-out"}/>{t("ProductsOut")}</Button>)
        }

        if (confParameters.wmsUseProductLocationsInWarehouse == 1) {
            if (confParameters.wmsEnabledModules.includes("PlaceProducts")) {
                buttons.push(<Button key={"PlaceProducts"} className={"menuBtn"} onClick={handlePlaceProductsOnClick} size='big'><Icon name={"dolly"}/>{t("PlaceProducts")}</Button>)
            }

            if (confParameters.wmsEnabledModules.includes("Replenishment")) {
                buttons.push(<Button key={"Replenishment"} className={"menuBtn"} onClick={handleReplenishmentOnClick} size='big'><Icon name={"sync"}/>{t("Replenishment")}</Button>)
            }

            if (confParameters.wmsEnabledModules.includes("AmountsInBins")) {
                buttons.push(<Button key={"AmountsInBins"} className={"menuBtn"} onClick={handleAmountsInBinsOnClick} size='big'><Icon name={"law"}/>{t("AmountsInBins")}</Button>)
            }
        }

        if (confParameters.wmsEnabledModules.includes("Stocktakings")) {
            buttons.push(<Button key={"Stocktakings"} className={"menuBtn"} onClick={handleStocktakingsOnClick} size='big'><Icon name={"warehouse"}/>{t("Stocktakings")}</Button>)
        }

        buttons.push(<Button key={"ActiveDocumentReadOnlyView"} className={"menuBtn"} onClick={handleActiveDocumentReadOnlyViewOnClick} size='big'><Icon name={"warehouse"}/>{t("ActiveDocumentReadOnlyView")}</Button>);

        buttons.push(
            <div key={"bottomButtons"} className={"flexCenter"}>
                <Button className={"menuBtnHalfWidth"} size={"large"} onClick={handleGoBackOnClick}><Icon name={"chevron circle left"}/>{t("goBack")}</Button>
                <Button className={"menuBtnHalfWidth margin0Right"} color={"blue"} size={"large"} onClick={handleLogOutOnClick}><FontAwesomeIcon className={"marginRightSmall"} icon={faDoorOpen}/>{t("logOut")}</Button>
            </div>
        );

        return <div>{buttons}</div>;
    };

    return (
        !confParametersInitialised
            ?
            <Dimmer active>
                <Loader size='massive'>{t("loading")}</Loader>
            </Dimmer>
            :
            getButtons()
    );
};

export default MainMenu
