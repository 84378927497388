export const setBinsAvailableForReplenishment = (bins) => {
    return {
        type: "SET_BINS_AVAILABLE_FOR_REPLENISHMENT",
        payload: bins
    }
};

export const setSelectedBin = (bin) => {
    return {
        type: "SET_SELECTED_BIN",
        payload: bin
    }
};

export const setAddedAmounts = (addedAmounts) => {
    return {
        type: "SET_ADDED_AMOUNTS",
        payload: addedAmounts
    }
};
