export const setStocktakings = (stocktakings) => {
    return {
        type: "SET_STOCKTAKINGS",
        payload: stocktakings
    }
};

export const setStocktakingReadings = (stocktakingReadings) => {
    return {
        type: "SET_STOCKTAKING_READINGS",
        payload: stocktakingReadings
    }
};

export const setProducts = (products) => {
    return {
        type: "SET_PRODUCTS",
        payload: products
    }
};

export const setBinQuantities = (binQuantities) => {
    return {
        type: "SET_BIN_QUANTITIES",
        payload: binQuantities
    }
};

export const setProductStock = (productStock) => {
    return {
        type: "SET_PRODUCT_STOCK",
        payload: productStock
    }
};

export const setExpandedStocktakingIDs = (stocktakingIDs) => {
    return {
        type: "SET_EXPANDED_STOCKTAKING_IDS",
        payload: stocktakingIDs
    }
};

export const setExpandedProductIDs = (productIDs) => {
    return {
        type: "SET_EXPANDED_PRODUCT_IDS",
        payload: productIDs
    }
};

// Required so that bin quantities of products with 0 bin quantities don't get re-requested
export const setProductIDsOfBinQuantitiesRequested = (productIDs) => {
    return {
        type: "SET_PRODUCT_IDS_OF_BIN_QUANTITIES_REQUESTED",
        payload: productIDs
    }
};

export const setFromDate = (fromDate) => {
    return {
        type: "SET_FROM_DATE",
        payload: fromDate
    }
};

export const setChangedBinQuantities = (changedBinQuantities) => {
    return {
        type: "SET_CHANGED_BIN_QUANTITIES",
        payload: changedBinQuantities
    }
};

export const setInitialBinQuantities = (initialBinQuantities) => {
    return {
        type: "SET_INITIAL_BIN_QUANTITIES",
        payload: initialBinQuantities
    }
};

export const setBinCodeInput = (binCodeInput) => {
    return {
        type: "SET_BIN_CODE_INPUT",
        payload: binCodeInput
    }
};
