import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import {useSelector} from "react-redux";
import {Label} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

const DatePickerComponent = ({value, setStateFunction, className, labelText}) => {
    const language = useSelector(state => state.languageReducer.language);

    const isOpenInAndroidApp = window.ReactNativeWebView;
    const popperClassName = isOpenInAndroidApp ? "transformNone" : "";
    const dateFormat = language === "est" ? 'dd.MM.yyyy' : 'yyyy-MM-dd';
    const { t } = useTranslation();

    const isValidValue = value === null || typeof value.getMonth === 'function';
    if (!isValidValue) value = new Date(value); // Browser window refresh has been made and date objects have been incorrectly persisted as strings

    const elements = [<DatePicker popperClassName={popperClassName} popperPlacement={"bottom-end"} dateFormat={dateFormat} className={className} selected={value} onChange={setStateFunction} isClearable/>];
    if (labelText) {
        elements.unshift(<Label className={"searchLabel"} size={"large"} pointing={"right"}>{t(labelText)}</Label>)
    }

    return <div className={labelText ? "searchRow" : ""}>{elements}</div>
};

export default DatePickerComponent
