const initialState = {
    userGroups: [],
};

export const getUserGroupsReducer = (state = initialState, action) => {
    switch(action.type) {
        case "GET_USER_GROUPS_SUCCESS":
            return {
                ...state,
                userGroups: action.payload,
            };
        default:
            return state;
    }
};
