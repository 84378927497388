import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Input} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {printLabel} from "../util/printRequests";
import {errorMessageSet} from "../actions/headerMessage";
import {timeoutBetweenPrint} from "../util/constants";
import {setLabelPrinterTimeoutFinished} from "../actions/printing";
import {setModal} from "../actions/modal";
import {getConfParameterValue} from "../util/misc";

const PrintLabelsButton = ({products, className, size, text, isCheckedProducts = false, onClickExtraFunction, colour}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [noOfLabels, setNoOfLabels] = useState(1);

    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const loginUrl = useSelector(state => state.verifyUserReducer.user.loginUrl);
    const apiSessionKey = useSelector(state => state.verifyUserReducer.user.sessionKey);
    const labelPrinterTimeoutFinished = useSelector(state => state.printingReducer.labelPrinterTimeoutFinished);

    const isOpenInAndroidApp = window.ReactNativeWebView;
    // const canLabelPrint = true;
    const canLabelPrint = dispatch(getConfParameterValue("wmsLabelPrinterName")) !== "" && confParameters.wmsLabelTemplateID !== "" && dispatch(getConfParameterValue("wmsPrinterServiceAddress")) !== "" && isOpenInAndroidApp;

    const maxNoOfLabels = 50;
    const minNoOfLabelsForModal = 10;

    const handleButtonClick = () => {

        if (onClickExtraFunction) {
            onClickExtraFunction();
        }

        if (labelPrinterTimeoutFinished) {
            const noOfProducts = products.length;
            if (noOfProducts === 0) {
                return dispatch(errorMessageSet(t("noCheckboxesChecked")));
            } else if (!isCheckedProducts && noOfLabels < 1) {
                return dispatch(errorMessageSet(t("notAPositiveInteger")));
            }

            if (noOfProducts >= minNoOfLabelsForModal && noOfProducts <= maxNoOfLabels) {
                const modalText = `${t("noOfLabelsThatWouldBePrinted")}: ${noOfProducts}`;
                dispatch(setModal(t("confirmation"), modalText, () => printProductLabels(noOfProducts)));
            } else if (noOfProducts > maxNoOfLabels) {
                const errorMessage = `${t("tooManyLabels")} (${noOfProducts})`;
                dispatch(errorMessageSet(errorMessage));
            } else {
                printProductLabels(noOfProducts);
            }
        } else {
            dispatch(errorMessageSet(t("printActionInProcessPleaseWait"), timeoutBetweenPrint));
        }
    };

    const printProductLabels = async (noOfProducts) => {
        dispatch(setLabelPrinterTimeoutFinished(false));

        const noOfPrintJobs = isCheckedProducts ? noOfProducts : noOfLabels;
        for (let i = 0; i < noOfPrintJobs; i++) {
            setTimeout(async () => {
                const product = isCheckedProducts ? products[i] : products[0];
                console.log(`Printing productID ${product.productID}`);
                printLabel(product, loginUrl, confParameters, selectedWarehouse, apiSessionKey, dispatch);
            }, timeoutBetweenPrint * i) // Otherwise print service might get clogged up and malfunction
        }

        setTimeout(() => dispatch(setLabelPrinterTimeoutFinished(true)), noOfPrintJobs * timeoutBetweenPrint);
    };

    const handleNoOfLabelsInput = (noOfLabels) => {
        if (noOfLabels > minNoOfLabelsForModal) {
            noOfLabels = minNoOfLabelsForModal;
        }

        setNoOfLabels(noOfLabels);
    };

    const handleNoOfLabelsFocus = () => {
        if (onClickExtraFunction) {
            onClickExtraFunction();
        }
    };

    if (canLabelPrint) {
        const key = isCheckedProducts ? "printLabelsBtn" : products[0].code + "printLabelBtn";
        const color = colour ? colour : "teal";
        const elements = [<Button key={key} className={className} size={size} color={color} onClick={handleButtonClick}>{text}</Button>];

        if (!isCheckedProducts) {
            elements.push(
                <Input key={key + "Input"} type={"number"} size={size} className={"widthVerySmall"} onFocus={handleNoOfLabelsFocus} onInput={e => handleNoOfLabelsInput(e.target.value)} value={noOfLabels}/>
            );
        }

        return elements;
    } else {
        return "";
    }
};

export default PrintLabelsButton;
