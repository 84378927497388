export const getWarehousesSuccess = (warehouses) => {
    return {
        type: "GET_WAREHOUSES_SUCCESS",
        payload: warehouses
    }
};

export const setWarehouse = (warehouse) => {
    return {
        type: "SET_WAREHOUSE",
        payload: warehouse
    }
};
