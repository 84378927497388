export const getBinRecordsSuccess = (binRecords) => {
    return {
        type: "GET_BIN_RECORDS_SUCCESS",
        payload: binRecords
    }
};

// Save bin records to local memory to not request them from Erply
export const addBinRecord = (binRecord) => {
    return {
        type: "ADD_BIN_RECORD",
        payload: binRecord
    }
};
