import {getDateOneMonthAgo} from "../util/misc";

const initialState = {
    stocktakings: [],
    stocktakingReadings: [],
    products: [],
    binQuantities: [],
    productStock: [],
    expandedStocktakingIDs: [],
    expandedProductIDs: [],
    productIDsOfBinQuantitiesRequested: [], // Required so that bin quantities of products with 0 bin quantities don't get re-requested
    fromDate: getDateOneMonthAgo(),
    changedBinQuantities: [],
    initialBinQuantities: [],
    binCodeInput: ""
};

export const stocktakingsReducer = (state = initialState, action) => {
    switch(action.type) {
        case "SET_STOCKTAKINGS":
            return {
                ...state,
                stocktakings: action.payload
            };
        case "SET_STOCKTAKING_READINGS":
            return {
                ...state,
                stocktakingReadings: action.payload
            };
        case "SET_PRODUCTS":
            return {
                ...state,
                products: action.payload
            };
        case "SET_BIN_QUANTITIES":
            return {
                ...state,
                binQuantities: action.payload
            };
        case "SET_PRODUCT_STOCK":
            return {
                ...state,
                productStock: action.payload
            };
        case "SET_EXPANDED_STOCKTAKING_IDS":
            return {
                ...state,
                expandedStocktakingIDs: action.payload
            };
        case "SET_EXPANDED_PRODUCT_IDS":
            return {
                ...state,
                expandedProductIDs: action.payload
            };
        case "SET_PRODUCT_IDS_OF_BIN_QUANTITIES_REQUESTED":
            return {
                ...state,
                productIDsOfBinQuantitiesRequested: action.payload
            };
        case "SET_FROM_DATE":
            return {
                ...state,
                fromDate: action.payload
            };
        case "SET_CHANGED_BIN_QUANTITIES":
            return {
                ...state,
                changedBinQuantities: action.payload
            };
        case "SET_INITIAL_BIN_QUANTITIES":
            return {
                ...state,
                initialBinQuantities: action.payload
            };
        case "SET_BIN_CODE_INPUT":
            return {
                ...state,
                binCodeInput: action.payload
            };
        default:
            return state;
    }
};
