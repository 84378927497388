import {errorMessageSet} from "../actions/headerMessage";
import {getConfParameterValue, simpleLog} from "./misc";
import {isAlasKuul, isPrimePartnerEst} from "./isClient";

export const getArData = (url) => {
    // return fetch("http://localhost/wms/services/getArData.php", {   // TEST
    return fetch("./services/getArData.php", {
        method: "POST",
        body: JSON.stringify({url: url})
    }).then((response) => {
        return response.text();
    }).catch((err) => {
        console.log(err);
    });
};

export const sendToActualReportsService = (clientCode, data, templateID, confParameters) => {
    return (dispatch) => {
        const url = isAlasKuul(clientCode) || isPrimePartnerEst(clientCode) ? "https://arlatest.erply.com/Api/Editor/pdf" : 'https://ar1.erply.com/Api/Editor/pdf';
        const email = confParameters.actual_reports_username;

        const params = {
            email: email,
            data: data, // needs to be JSON
            output: "S",
            template: templateID,
            url: url
        };

        return fetch("http://localhost/wms/services/actualReports.php", {    // TEST
        // return fetch("./services/actualReports.php", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        }).then(async response => {
            const responseText = await response.text();
            const base64Decoded = atob(responseText);

            if (isJson(base64Decoded)) { // Is an error sent from Actual Reports
                const reponseJson = JSON.parse(base64Decoded);
                simpleLog("arErrorResponse " + reponseJson.error);
                dispatch(errorMessageSet("Actual reports error: " + reponseJson.error));
                reponseJson.status = "error";
                return reponseJson;
            }
            return responseText;
        }).catch(err => {
            dispatch(errorMessageSet(`Actual reports error: ${err}`));
        });
    }
};

export const sendToPrintService = (pdf, printerName, printerServiceAddress) => {
    const params = {
        pdf: pdf,
        printerName: printerName,
        printerServiceAddress: `http://${printerServiceAddress}:5000/printPdf`
    };
    console.log(params)

    // window.ReactNativeWebView.postMessage(JSON.stringify(params));

    // TEST
    fetch("http://localhost:5000/printPdf", {
        method: 'POST',
        body: JSON.stringify(params)
    });
};

const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const printLabel = async (product, loginUrl, confParameters, selectedWarehouse, apiSessionKey, dispatch) => {
    const url = `${loginUrl}file.php?lang=${confParameters.default_language}&label_pdf=1&product_id=${product.productID}&posted=1&amount=1&code_separator=%2C&mode=pdf&ARSection=products&amountoption=fixed&sel_lang=${confParameters.default_language}&warehouse_id=${selectedWarehouse.warehouseID}&format=${confParameters.wmsLabelTemplateID}&authKey=${apiSessionKey}`;
    const arData = await getArData(url);
    sendToPrintService(arData, dispatch(getConfParameterValue("wmsLabelPrinterName")), dispatch(getConfParameterValue("wmsPrinterServiceAddress")));
};
