import React, { useState, useEffect } from 'react';
import {Button, Table} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {componentSet, previousComponentSet} from "../actions/component";
import {makeErplyRequest, makeWmsApiRequest} from "../util/erplyRequests";
import GoBackToStartBtn from "./GoBackToStartBtn";
import {setModal} from "../actions/modal";
import GoBackBtn from "./GoBackBtn";
import {
    isSelectedRow,
    setExpandedRow,
    timestampToDateString, translateAccordingToCountry
} from "../util/misc";
import {setSelectedDocuments} from "../actions/scan";

const AssignmentList = () => {
    const assignments = useSelector(state => state.getAssignmentsReducer.assignments);
    const language = useSelector(state => state.languageReducer.language);
    const binRecords = useSelector(state => state.getBinRecordsReducer.binRecords);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);

    const [expandedRows, setExpandedRows] = useState([]);
    const [requestedProducts, setRequestedProducts] = useState([]); // Products in expanded inventory transfers, needed to display product info

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        getBinRecords();
    }, []);

    const getBinRecords = () => {
        const documentIDs = assignments.map(doc => doc.assignmentID).join(",");

        if (documentIDs.length !== 0) {
            const params = {
                referenceId: documentIDs,
                referenceType: "ASSIGNMENT"
            };

            // dispatch(makeWmsApiRequest("GET", "bin-inventory-record", params, t("getBinRecordsError"), getBinRecordsPending, getBinRecordsSuccess, getBinRecordsError));
        }
    };

    const handleGoBackOnClick = () => {
        dispatch(componentSet("Assignment"));
    };

    const createRows = () => {
        return assignments.map(assignment => createRow(assignment));
    };

    const createRow = (assignment) => {
        const isSelected = isSelectedRow(assignment.id, expandedRows);

        const creationDate = timestampToDateString(assignment.createdUnixTime, language === "est");

        console.log("binRecords",binRecords)
        const packingHasStarted = binRecords.some(binRecord => binRecord.documentID == assignment.id);
        const buttonColor = packingHasStarted ? "red" : "blue";

        let assignmentRow = [
            <Table.Row key={assignment.id}>
                <Table.Cell><Button size={"mini"} color={buttonColor} icon={"check"} onClick={() => {handleCheckClick(assignment.assignmentID, packingHasStarted)}}/></Table.Cell>
                <Table.Cell onClick={() => expandRow(assignment)}>{assignment.assignmentNumber}</Table.Cell>
                <Table.Cell onClick={() => expandRow(assignment)}>{assignment.customerName}</Table.Cell>
                <Table.Cell onClick={() => expandRow(assignment)}>{creationDate}</Table.Cell>
            </Table.Row>
        ];

        if (isSelected) {
            assignmentRow.push(createAssignmentRowsTable(assignment));
        }

        return assignmentRow;
    };

    const expandRow = async (assignment) => {
        const productsNotRequested = await getProducts(assignment);
        setRequestedProducts(requestedProducts.concat(productsNotRequested));
        setExpandedRow(assignment.inventoryTransferID, expandedRows, setExpandedRows);
    };

    const getProducts = (assignment) => {
        let productIDsToBeRequested = [];

        for (let i = 0, n = assignment.assignmentRows.length; i < n; i++) {
            let productHasAlreadyBeenRequested = false;

            for (let j = 0, n = requestedProducts.length; j < n; j++) {
                if (requestedProducts[j].productID == assignment.assignmentRows[i].productID) {
                    productHasAlreadyBeenRequested = true;
                }
            }

            if (!productHasAlreadyBeenRequested) {
                productIDsToBeRequested.push(assignment.assignmentRows[i].productID);
            }
        }

        if (productIDsToBeRequested.length !== 0) {
            const params = {
                request: "getProducts",
                productIDs: productIDsToBeRequested.join(","),
                includeMatrixVariations: 1
            };

            return dispatch(makeErplyRequest(params, t("getProductsError"), null, null, null, true));
        } else {
            return [];
        }
    };

    const handleCheckClick = (assignmentID, packingHasStarted) => {
        if (packingHasStarted) {
            dispatch(setModal(t("confirmation"), t("packingAlreadyStarted"), () => selectDocumentForScan(assignmentID)));
        } else {
            selectDocumentForScan(assignmentID);
        }
    };

    const selectDocumentForScan = async (assignmentID) => {
        const selectedDocument = assignments.find(assignment => assignment.assignmentID == assignmentID);
        await dispatch(setSelectedDocuments([selectedDocument], t, false, false, false, true));

        dispatch(previousComponentSet("AssignmentList"));
        dispatch(componentSet("Scan"));
    };

    const createAssignmentRowsTable = (assignment) => {
        return (
            <tr key={assignment.assignmentNumber}>
                <td colSpan={6}>
                    <Table celled structured unstackable color={"blue"} inverted>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t("code")}</Table.HeaderCell>
                                <Table.HeaderCell>{translateAccordingToCountry(t("EAN"), confParameters)}</Table.HeaderCell>
                                <Table.HeaderCell>{t("name")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("amount")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {createAssignmentRowsTableRows(assignment)}
                        </Table.Body>
                    </Table>
                </td>
            </tr>
        )
    };

    const createAssignmentRowsTableRows = (assignment) => {
        return assignment.assignmentRows.map((row, index) => createAssignmentRowsTableRow(row, index));
    };

    const createAssignmentRowsTableRow = (row, index) => {
        const product = requestedProducts.find(product => product.productID == row.productID);

        return (
            <Table.Row key={index}>
                <Table.Cell>{product.code}</Table.Cell>
                <Table.Cell>{product.code2}</Table.Cell>
                <Table.Cell>{product.name}</Table.Cell>
                <Table.Cell>{row.amount}</Table.Cell>
            </Table.Row>
        )
    };

    return (
        <div className={"overflow"}>
            <Table celled structured unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell/>
                        <Table.HeaderCell>{t("number")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("customer")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("date")}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {createRows()}
                </Table.Body>
            </Table>
            <div className={"flex flexCenter"}>
                <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                <GoBackToStartBtn/>
            </div>
        </div>
    );
};

export default AssignmentList
