import React, { useState } from 'react';
import {Button, Input, Transition} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {componentSet, setIsLoading} from "../actions/component";
import {useDispatch, useSelector} from "react-redux";
import GoBackToStartBtn from "./GoBackToStartBtn";
import {errorMessageSet, successMessageSet} from "../actions/headerMessage";
import readXlsxFile from 'read-excel-file';
import {makeErplyBulkRequest, responseHasErrors} from "../util/erplyRequests";
import GoBackBtn from "./GoBackBtn";
import {getUniqueArray, isNonNegativeInteger} from "../util/misc";

const fileUrl = "https://w2.intralplugins.com/excelTemplates/importBinQuantities.xlsx";
const maxFileSize = 10; // MB

const ImportBinQuantities = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [uploadedRows, setUploadedRows] = useState([]);
    const [showExplanations, setShowExplanations] = useState(false);

    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);
    const user = useSelector(state => state.verifyUserReducer.user);

    const handleGoBackOnClick = () => {
        dispatch(componentSet("BinManagement"));
    };

    const validateFile = (event) => {
        setUploadedRows([]);
        const file = event.target.files[0];

        if (!isCorrectSize(file)) {
            dispatch(errorMessageSet(`${t("fileSizeExceeds")} ${maxFileSize} MB`));
            clearFileInput();
            return
        }

        readExcelData(file);
    };

    const isCorrectSize = (file) => {
        return !(file.size / 1024 / 1024 > maxFileSize);
    };

    const clearFileInput = () => {
        document.getElementById("uploadFile").value = "";
    };

    const readExcelData = (file) => {
        const schema = {
            'Bin': {
                prop: 'binCode',
                type: String
            },
            'Product code': {
                prop: 'productCode',
                type: String
            },
            'Product ID': {
                prop: 'productID',
                type: String
            },
            'Amount': {
                prop: 'amount',
                parse(value) {
                    if (!isNonNegativeInteger(value)) {
                        throw new Error(t("mustBeANonNegativeInteger"))
                    }
                    return value
                }
            }
        };

        readXlsxFile(file, { schema }).then(({ rows, errors }) => {
            if (rows === undefined || rows.length == 0) {
                dispatch(errorMessageSet(`${t("invalidOrEmptyFile")}: "Bin", "Product ID", "Product code", "Amount"`));
                clearFileInput();
                return;
            }

            if (errors.length > 0) {
                dispatch(errorMessageSet(`${errors[0].error}. ${t("row")}: ${errors[0].row + 1}. ${t("column")}: ${errors[0].column}. ${t("value")}: ${errors[0].value}`));
                clearFileInput();
                return;
            }

            for (let i = 0; i < rows.length; i++) {
                if (!rows[i].hasOwnProperty("binCode")) {
                    dispatch(errorMessageSet(`${t("rowMustHaveBin")}. ${t("row")}: ${i + 2}`));
                    clearFileInput();
                    return;
                }

                if (!rows[i].hasOwnProperty("productCode") && !rows[i].hasOwnProperty("productID")) {
                    dispatch(errorMessageSet(`${t("rowMustHaveProductCode")}. ${t("row")}: ${i + 2}`));
                    clearFileInput();
                    return;
                }

                if (!rows[i].hasOwnProperty("amount")) {
                    dispatch(errorMessageSet(`${t("rowMustHaveAmount")}. ${t("row")}: ${i + 2}`));
                    clearFileInput();
                    return;
                }
            }

            setUploadedRows(rows);
            console.log("Uploaded rows:", rows);
        })
    };

    const adjustBinQuantities = async () => {
        if (uploadedRows.length === 0) {
            return dispatch(errorMessageSet(t("noFile")));
        }

        dispatch(setIsLoading(true));
        Promise.all([getProductsByCode(), getBins()]).then((data) => {
            dispatch(setIsLoading(false));
            const products = data[0];
            const bins = data[1];

            if (!products || !bins) {
                return; // Some products or bins were not found
            }

            let uploadedRowsChunks = [];
            const maxNoOfRowsPerChunk = 1000;    // Erply API can't handle much more
            for (let i = 0, n = uploadedRows.length; i < n; i += maxNoOfRowsPerChunk) {
                uploadedRowsChunks.push(uploadedRows.slice(i , i + maxNoOfRowsPerChunk));
            }

            let requests = [];
            uploadedRowsChunks.forEach((chunk, index) => {
                const request = {requestName: "adjustBinQuantities", requestID: index};

                chunk.forEach((uploadedRow, index) => {
                    const binID = bins.find(bin => bin.code.toLowerCase() == String(uploadedRow.binCode).toLowerCase()).binID;
                    const productID = (uploadedRow.hasOwnProperty("productID")) ? uploadedRow.productID :
                        products.find(product => product.code.toLowerCase().trim() == String(uploadedRow.productCode).toLowerCase().trim()).productID;

                    request[`binID${index}`] = binID;
                    request[`productID${index}`] = productID;
                    request[`newAmount${index}`] = uploadedRow.amount;
                    request[`creatorID${index}`] = user.employeeID;
                });

                requests.push(request);
            });

            dispatch(makeErplyBulkRequest(requests, t("adjustBinQuantitiesError"))).then((response) => {
                if (!responseHasErrors(response)) {
                    dispatch(successMessageSet(t("binQuantitiesSaved"), 5000));
                }
            });
        });
    };

    const getBins = () => {
        const binCodes = getUniqueArray(uploadedRows, "binCode");
        let requests = [];

        for (let i = 0, n = binCodes.length; i < n; i++) {
            const request = {
                requestName: "getBins",
                code: binCodes[i],
                status: "ACTIVE",
                warehouseID: selectedWarehouse.warehouseID,
                requestID: i + 1
            };

            requests.push(request);
        }

        return dispatch(makeErplyBulkRequest(requests, t("getBinsError"), null, null, null, false)).then((bins) => {
            if (bins.status === "error") {
                return false;
            }

            let binsFound = [];
            let binsNotFound = [];

            for (let i = 0, n = bins.length; i < n; i++) {
                if (bins[i].records.length > 0) {
                    binsFound.push(bins[i].records[0]);
                } else {
                    const requestID = bins[i].status.requestID;
                    const binCode = requests.find(request => request.requestID == requestID).code;
                    binsNotFound.push(binCode);
                }
            }

            if (binsNotFound.length > 0) {
                dispatch(errorMessageSet(`${t("notAllBinsFound")}. ${t("codes")}: ${binsNotFound.join(", ")}`));
                return false;
            }

            return binsFound;
        });
    };

    const getProductsByCode = () => {
        const productCodes = [];
        uploadedRows.forEach(uploadedRow => {
            if (!uploadedRow.hasOwnProperty("productID") && !productCodes.includes(uploadedRow.productCode)) productCodes.push(uploadedRow.productCode);
        });
        if (productCodes.length === 0) return [];

        let requests = [];

        for (let i = 0, n = productCodes.length; i < n; i++) {
            const request = {
                requestName: "getProducts",
                code: productCodes[i],
                active: 1,
                requestID: i
            };

            requests.push(request);
        }

        return dispatch(makeErplyBulkRequest(requests, t("getProductsError"), null, null, null, false)).then((products) => {
            if (products.status === "error") {
                return false;
            }

            let productsFound = [];
            let productCodesNotFound = [];

            for (let i = 0, n = products.length; i < n; i++) {
                if (products[i].records.length > 0) {
                    productsFound.push(products[i].records[0]);
                } else {
                    const requestID = products[i].status.requestID;
                    const productCode = requests.find(request => request.requestID == requestID).code;
                    productCodesNotFound.push(productCode);
                }
            }

            if (productCodesNotFound.length > 0) {
                dispatch(errorMessageSet(`${t("notAllProductsFound")}. ${t("codes")}: ${productCodesNotFound.join(", ")}`));
                return false;
            }

            return productsFound;
        });
    };

    return (
        <div className={"white"}>
            <h1>{t("ImportBinQuantities")} {t("fromExcel")}</h1>
            <div className={"textAlignLeft fontSizeMed"}>
                <p>{t("allowedExtensions")}: .xlsx</p>
                <p>{t("downloadSample")} <a href={fileUrl}><b>{t("here")}</b></a>.</p>
                <Button onClick={() => setShowExplanations(!showExplanations)}>{t("showExplanations")}</Button>
                <Transition visible={showExplanations} animation='slide down' duration={200} unmountOnHide>
                    <div>
                        <p>{t("importBinsExplanatoryTextBin")}</p>
                        <p>{t("importBinsExplanatoryTextProductID")}</p>
                        <p>{t("importBinsExplanatoryTextProductCode")}</p>
                        <p>{t("importBinsExplanatoryTextAmount")}</p>
                    </div>
                </Transition>
            </div>
            <Input id={"uploadFile"} className={"marginTop"} type="file" accept='.xlsx' onChange={validateFile} onClick={clearFileInput}/>
            <div className={"btnsGroup"}>
                <Button className={"menuBtn"} primary size={"big"} onClick={adjustBinQuantities}>{t("import")}</Button>
                <div className={"flex flexCenter"}>
                    <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                    <GoBackToStartBtn/>
                </div>
            </div>
        </div>
    );
};

export default ImportBinQuantities
