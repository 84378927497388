const initialState = {
    deliveryTypes: [],
};

export const getDeliveryTypesReducer = (state = initialState, action) => {
    switch(action.type) {
        case "GET_DELIVERY_TYPES_SUCCESS":
            return {
                ...state,
                deliveryTypes: action.payload,
            };
        default:
            return state;
    }
};
