import { combineReducers } from "redux";
import { verifyUserReducer } from "./verifyUser";
import { headerMessageReducer } from "./headerMessage";
import { languageReducer } from "./language";
import { getWarehousesReducer } from "./getWarehouses";
import {componentReducer} from "./component";
import {getProductsReducer} from "./getProducts";
import {getSuppliersReducer} from "./getSuppliers";
import {getCustomersReducer} from "./getCustomers";
import {getSalesDocumentsReducer} from "./getSalesDocuments";
import {modalReducer} from "./modal";
import {getDeliveryTypesReducer} from "./getDeliveryTypes";
import {getUserGroupsReducer} from "./getUserGroups";
import {getConfParametersReducer} from "./getConfParameters";
import {informativeModalReducer} from "./informativeModal";
import {getBinQuantitiesReducer} from "./getBinQuantities";
import {getBinsReducer} from "./getBins";
import {getBinRecordsReducer} from "./getBinRecords";
import {placeProductsReducer} from "./placeProducts";
import {scanReducer} from "./scan";
import {replenishmentReducer} from "./replenishment";
import {binManagementReducer} from "./binManagement";
import {getAssignmentsReducer} from "./getAssignments";
import {createDocumentReducer} from "./createDocument";
import {getReasonCodesReducer} from "./getReasonCodes";
import {filtersReducer} from "./filters";
import {getProductGroupsReducer} from "./getProductGroups";
import {createEditProductReducer} from "./createEditProduct";
import {getProductPackageTypesReducer} from "./getProductPackageTypes";
import {searchDocumentsReducer} from "./searchDocuments";
import {undeliveredGoodsReducer} from "./undeliveredGoods";
import {newErplyApisReducer} from "./newErplyApis";
import {printingReducer} from "./printing";
import {stocktakingsReducer} from "./stocktakings";

const appReducer = combineReducers({
    verifyUserReducer,
    headerMessageReducer,
    languageReducer,
    getWarehousesReducer,
    componentReducer,
    getProductsReducer,
    getSuppliersReducer,
    getCustomersReducer,
    getSalesDocumentsReducer,
    modalReducer,
    getDeliveryTypesReducer,
    getUserGroupsReducer,
    getConfParametersReducer,
    informativeModalReducer,
    getBinQuantitiesReducer,
    getBinsReducer,
    getBinRecordsReducer,
    placeProductsReducer,
    scanReducer,
    replenishmentReducer,
    binManagementReducer,
    getAssignmentsReducer,
    createDocumentReducer,
    getReasonCodesReducer,
    filtersReducer,
    getProductGroupsReducer,
    createEditProductReducer,
    getProductPackageTypesReducer,
    searchDocumentsReducer,
    undeliveredGoodsReducer,
    newErplyApisReducer,
    printingReducer,
    stocktakingsReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        state = undefined;   // Resets state

        // Reload page to ensure user doesn't just use the same app version and the app gets updated
        setTimeout(() => {
            if (action.showYouWereLoggedOutMessage) {
                sessionStorage.setItem("wmsShowYouWereLoggedOutMessage", "1");
            }
            window.location.reload();
        }, 300);    // An increased timeout so Redux-Persist will have enough time to clear state
    }

    return appReducer(state, action)
};

export default rootReducer;
