import React, { useState, useEffect } from 'react';
import {Button, Table} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {componentSet, previousComponentSet} from "../actions/component";
import {getBinRecords, makeErplyRequest} from "../util/erplyRequests";
import {setDocumentHasBeenPreviouslyScanned, setSelectedDocuments} from "../actions/scan";
import GoBackToStartBtn from "./GoBackToStartBtn";
import {setModal} from "../actions/modal";
import GoBackBtn from "./GoBackBtn";
import {
    formatDateStringToEst, getFulfillableOrderTotal, getProductCode, getProductCode2, getProductName,
    isSelectedRow,
    roundFloatingPointError, rowIsBundle,
    setExpandedRow,
    translateAccordingToCountry
} from "../util/misc";
import {isAlasKuul} from "../util/isClient";

const FulfillableOrders = () => {
    const fulfillableOrders = useSelector(state => state.getSalesDocumentsReducer.fulfillableOrders);
    const language = useSelector(state => state.languageReducer.language);
    const binRecords = useSelector(state => state.getBinRecordsReducer.binRecords);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const clientCode = useSelector(state => state.verifyUserReducer.clientCode);
    const deliveryTypes = useSelector(state => state.getDeliveryTypesReducer.deliveryTypes);
    const vatRates = useSelector(state => state.scanReducer.vatRates);

    const [expandedRows, setExpandedRows] = useState([]);
    const [expandedBundles, setExpandedBundles] = useState([]);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getBinRecords(t, fulfillableOrders.map(doc => doc.id).join(","), "SALES_DOCUMENT", null, true));
    }, []);

    const handleGoBackOnClick = () => {
        dispatch(componentSet("FulfillableOrdersFilters"));
    };

    const createRows = () => {
        return fulfillableOrders.map(salesDocument => createRow(salesDocument));
    };

    const createRow = (fulfillableOrder) => {
        const isSelected = isSelectedRow(fulfillableOrder.id, expandedRows);

        const deliveryDate = getDeliveryDate(fulfillableOrder);
        const deliveryType = deliveryTypes.find(deliveryType => deliveryType.deliveryTypeID == fulfillableOrder.deliveryTypeId);
        const deliveryTypeName = deliveryType ? deliveryType.name : "";

        const packingHasStarted = binRecords.some(binRecord => binRecord.documentID == fulfillableOrder.id);
        const buttonColor = packingHasStarted ? "red" : "blue";

        let order = [
            <Table.Row key={fulfillableOrder.id}>
                <Table.Cell><Button size={"mini"} color={buttonColor} icon={"check"} onClick={() => {handleCheckClick(fulfillableOrder, packingHasStarted)}}/></Table.Cell>
                <Table.Cell onClick={() => setExpandedRow(fulfillableOrder.id, expandedRows, setExpandedRows)}>{fulfillableOrder.number}</Table.Cell>
                <Table.Cell onClick={() => setExpandedRow(fulfillableOrder.id, expandedRows, setExpandedRows)}>{fulfillableOrder.customerName}</Table.Cell>
                <Table.Cell onClick={() => setExpandedRow(fulfillableOrder.id, expandedRows, setExpandedRows)}>{isAlasKuul(clientCode) ? deliveryTypeName : deliveryDate}</Table.Cell>
                <Table.Cell onClick={() => setExpandedRow(fulfillableOrder.id, expandedRows, setExpandedRows)}>{getFulfillableOrderTotal(fulfillableOrder, vatRates)}</Table.Cell>
            </Table.Row>
        ];

        if (isSelected) {
            order.push(createSubTable(fulfillableOrder.number, fulfillableOrder.rows));
        }

        return order;
    };

    const getDeliveryDate = (salesDocument) => {
        if (salesDocument.deliveryDate !== "") {
            return language === "est" ? formatDateStringToEst(salesDocument.deliveryDate) : salesDocument.deliveryDate;
        } else {
            return "";
        }
    };

    const handleCheckClick = (fulfillableOrder, packingHasStarted) => {
        if (packingHasStarted) {
            dispatch(setModal(t("confirmation"), t("packingAlreadyStarted"), () => selectDocumentForScan(fulfillableOrder, true)));
        } else {
            selectDocumentForScan(fulfillableOrder);
        }
    };

    const selectDocumentForScan = async (fulfillableOrder, documentHasBeenPreviouslyScanned = false) => {
        dispatch(setDocumentHasBeenPreviouslyScanned(documentHasBeenPreviouslyScanned));
        let salesDocument = await getSalesDocument(fulfillableOrder.id); // This will be requested with rows
        const unfulfillableRows = getUnfulfillableRowsAndAddComponentData(salesDocument, fulfillableOrder);
        await dispatch(setSelectedDocuments([salesDocument], t, unfulfillableRows, false, false, false));

        dispatch(previousComponentSet("FulfillableOrders"));
        dispatch(componentSet("Scan"));
    };

    const getUnfulfillableRowsAndAddComponentData = (salesDocument, fulfillableOrder) => {
        let unfulfillableRows = [];

        fulfillableOrder.rows.forEach((fulfillableOrderRow, index) => {
            // Add rowID to ease further filtering
            fulfillableOrderRow.rowID = index;

            // Add rows with insufficient amount in stock to unfulfillableRows (are later added to re-orders)
            if ((rowIsBundle(fulfillableOrderRow) && fulfillableOrderRow.fulfillableAmount < 1) || fulfillableOrderRow.fulfillableAmount < fulfillableOrderRow.orderedAmount) {
                const amount = rowIsBundle(fulfillableOrderRow) && fulfillableOrderRow.fulfillableAmount < 1 ? fulfillableOrderRow.orderedAmount :
                    fulfillableOrderRow.orderedAmount - fulfillableOrderRow.fulfillableAmount;

                unfulfillableRows.push({
                    productID: fulfillableOrderRow.productId,
                    code: fulfillableOrderRow.productCode,
                    code2: fulfillableOrderRow.productCode2,
                    amount: amount,
                    price: fulfillableOrderRow.price,
                    discount: fulfillableOrderRow.discount,
                    vatrateID: fulfillableOrderRow.vatRateId
                })
            }

            if (rowIsBundle(fulfillableOrderRow)) {
                // Standardise component field names
                fulfillableOrderRow.productComponents = JSON.parse(JSON.stringify(fulfillableOrderRow.productComponents).split('"productId":').join('"productID":')
                    .split('"productName":').join('"name":')
                    .split('"productCode":').join('"code":')
                    .split('"productCode2":').join('"code2":'));

                const summedComponents = [];
                fulfillableOrderRow.productComponents.forEach(component => {
                    // Add "amountInBundle" property to components
                    component.amountInBundle = component.orderedAmount / fulfillableOrderRow.orderedAmount;

                    // Sum components with the same productID
                    const existingSummedComponent = summedComponents.find(summedComponent => summedComponent.productID == component.productID);
                    if (existingSummedComponent) {
                        existingSummedComponent.fulfillableAmount += component.fulfillableAmount;
                        existingSummedComponent.orderedAmount += component.orderedAmount;
                        existingSummedComponent.amountInBundle += component.amountInBundle;
                    } else {
                        summedComponents.push(component);
                    }
                });

                fulfillableOrderRow.productComponents = summedComponents;
            }
        });

        // Add rows with no amount in stock to unfulfillableRows (are later added to re-orders)
        // Add components to sales document rows
        let rowIDsChecked = [];
        for (let i = 0, n = salesDocument.rows.length; i < n; i++) {
            const fulfillableBundle = fulfillableOrder.rows.find(fulfillableOrderRow => rowIsBundle(fulfillableOrderRow) && salesDocument.rows[i].productID == fulfillableOrderRow.productId);
            if (fulfillableBundle) {
                salesDocument.rows[i].productComponents = fulfillableBundle.productComponents;
            }

            let rowPresentOnFulfillableOrder = false;

            for (let j = 0, n = fulfillableOrder.rows.length; j < n; j++) {
                if (salesDocument.rows[i].productID == fulfillableOrder.rows[j].productId &&
                    salesDocument.rows[i].amount == fulfillableOrder.rows[j].orderedAmount && !rowIDsChecked.includes(fulfillableOrder.rows[j].rowID)) {
                    rowIDsChecked.push(fulfillableOrder.rows[j].rowID);
                    rowPresentOnFulfillableOrder = true;
                    break;
                }
            }

            if (!rowPresentOnFulfillableOrder) {
                const unfulfillableRow = {
                    productID: salesDocument.rows[i].productID,
                    code: salesDocument.rows[i].code,
                    code2: salesDocument.rows[i].barcode,
                    amount: salesDocument.rows[i].amount,
                    price: salesDocument.rows[i].price,
                    discount: salesDocument.rows[i].discount,
                    vatrateID: salesDocument.rows[i].vatrateID,
                };

                unfulfillableRows.push(unfulfillableRow);
            }
        }

        return unfulfillableRows;
    };

    const getSalesDocument = (id) => {
        const params = {
            request: "getSalesDocuments",
            id: id
        };
        return dispatch(makeErplyRequest(params, t("getSalesDocumentsError"))).then(salesDocuments => salesDocuments[0]);
    };

    // If bundleRow is supplied then table is of bundle's components
    const createSubTable = (key, rows, bundleRow = null) => {
        const colour = bundleRow ? "violet" : "blue";
        return (
            <tr key={key}>
                <td colSpan={6}>
                    <Table celled structured unstackable color={colour} inverted>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t("code")}</Table.HeaderCell>
                                <Table.HeaderCell>{translateAccordingToCountry(t("EAN"), confParameters)}</Table.HeaderCell>
                                <Table.HeaderCell>{t("name")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("amount")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {createSubTableRows(rows, bundleRow)}
                        </Table.Body>
                    </Table>
                </td>
            </tr>
        )
    };

    const createSubTableRows = (rows, bundleRow) => {
        return rows.map((row, index) => createSubTableRow(row, index, bundleRow));
    };

    const createSubTableRow = (row, index, bundleRow) => {
        const isBundle = rowIsBundle(row);
        const className = isBundle ? "backgroundViolet" : "";

        let amount = roundFloatingPointError(row.fulfillableAmount);

        if (!(isBundle && amount < 1)) {    // Do not display bundles with fulfillable amount less than 1
            // Row is a bundle component row
            const bundleIsPartlyFulfillable = bundleRow && bundleRow.fulfillableAmount !== bundleRow.orderedAmount;
            const componentIsEntirelyFulfillable = row.fulfillableAmount === row.orderedAmount;
            if (bundleIsPartlyFulfillable && componentIsEntirelyFulfillable) {
                amount *= bundleRow.fulfillableAmount / bundleRow.orderedAmount;
            }

            const documentRow = [
                <Table.Row key={index} className={className}>
                    <Table.Cell onClick={() => setExpandedRow(row.productId, expandedBundles, setExpandedBundles)}>{getProductCode(row)}</Table.Cell>
                    <Table.Cell onClick={() => setExpandedRow(row.productId, expandedBundles, setExpandedBundles)}>{getProductCode2(row)}</Table.Cell>
                    <Table.Cell onClick={() => setExpandedRow(row.productId, expandedBundles, setExpandedBundles)}>{getProductName(row)}</Table.Cell>
                    <Table.Cell onClick={() => setExpandedRow(row.productId, expandedBundles, setExpandedBundles)}>{amount}</Table.Cell>
                </Table.Row>
            ];

            if (isBundle && isSelectedRow(row.productId, expandedBundles)) {
                documentRow.push(createSubTable(row.productId, row.productComponents, row));
            }

            return documentRow;
        }
    };

    const createBackButtons = (isTop) => {
        if (!(isTop && fulfillableOrders.length < 6)) {
            let classNames = "flex flexCenter";
            if (isTop) {
                classNames += " marginBottom-5";
            }

            return (
                <div className={classNames}>
                    <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                    <GoBackToStartBtn/>
                </div>
            );
        }
    };

    return (
        <div className={"overflow"}>
            {createBackButtons(true)}
            <Table celled structured unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell/>
                        <Table.HeaderCell>{t("number")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("customer")}</Table.HeaderCell>
                        <Table.HeaderCell>{isAlasKuul(clientCode) ? t("deliveryType") : t("deliveryDate")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("sum")}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {createRows()}
                </Table.Body>
            </Table>
            {createBackButtons()}
        </div>
    );
};

export default FulfillableOrders
