export const setDocumentType = (documentType) => {
    return {
        type: "SET_DOCUMENT_TYPE",
        payload: documentType
    }
};

export const setScannedProducts = (documentType, scannedProducts) => {
    return {
        type: "SET_SCANNED_PRODUCTS",
        payload: {
            documentType: documentType,
            scannedProducts: scannedProducts
        }
    }
};
