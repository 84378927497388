import React, {useState, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Dropdown, Icon, Input, Table} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {makeErplyBulkRequest, makeJsonApiRequest, makeWmsApiRequest} from "../util/erplyRequests";
import {componentSet} from "../actions/component";
import {errorMessageSet, successMessageSet} from "../actions/headerMessage";
import {setModal} from "../actions/modal";
import {setScannedBatches} from "../actions/scan";
import {
    capitalise, deepCopy,
    differentiateProductsByPackageID,
    documentIsCreditInvoice, documentIsPurchaseOrder,
    documentIsPurchaseReturn,
    documentIsSalesOrder, getAmountLeftOnDocument, getCurrentSessionBinRecords,
    getDocumentType,
    getIdFieldName, getProductPackage, isBundleComponent,
    productsAreIdentical,
    roundFloatingPointError, rowIsBundle, sumSerialNoBinRecords, syncQuantitiesOnDocument,
    translateAccordingToCountry,
    translateBinFromEng
} from "../util/misc";

const ChangeScannedProducts = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isAssembly = useSelector(state => state.scanReducer.isAssembly);  // Used for Products Out -> Assemble
    const componentsWithSummedAmounts = useSelector(state => state.scanReducer.componentsWithSummedAmounts);
    const rowsWithSummedProducts = useSelector(state => state.scanReducer.rowsWithSummedProducts);
    const productsOnDocument = useSelector(state => state.scanReducer.selectedDocumentsProducts);
    const recipeProducts = useSelector(state => state.scanReducer.recipeProducts);
    const summedProducts = isAssembly ? componentsWithSummedAmounts : rowsWithSummedProducts;
    const selectedDocumentsProducts = isAssembly ? recipeProducts : productsOnDocument;

    const scannedProducts = useSelector(state => state.scanReducer.scannedProducts);
    const currentSessionScannedProducts = useSelector(state => state.scanReducer.currentSessionScannedProducts);
    const componentSequence = useSelector(state => state.componentReducer.componentSequence);
    const bins = useSelector(state => state.getBinsReducer.bins);
    const receivingArea = useSelector(state => state.getBinsReducer.receivingArea);
    const selectedDocuments = useSelector(state => state.scanReducer.selectedDocuments);
    const user = useSelector(state => state.verifyUserReducer.user);
    const documentIDs = useSelector(state => state.scanReducer.documentIDs);
    const initialSubtractedRows = useSelector(state => state.scanReducer.initialSubtractedRows);  // Rows with scanned products from all previous scan sessions subtracted
    const summedScannedProducts = useSelector(state => state.scanReducer.summedScannedProducts);
    const followUpDocuments = useSelector(state => state.scanReducer.followUpDocuments);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const language = useSelector(state => state.languageReducer.language);
    // Display only products with differences between scanned amount and amount on document
    const isDifferencesOnly = useSelector(state => state.componentReducer.changeScannedProductsDifferencesOnly);
    const productsWithDifferences = useSelector(state => state.scanReducer.productsWithDifferences);
    const scannedBatches = useSelector(state => state.scanReducer.scannedBatches);
    const scanBySupplier = useSelector(state => state.scanReducer.scanBySupplier);
    const supplierBin = useSelector(state => state.scanReducer.supplierBin);
    const productsNotOnDocument = useSelector(state => state.scanReducer.productsNotOnDocument);
    const binRecords = useSelector(state => state.getBinRecordsReducer.binRecords);
    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);

    const isScanBySupplier = scanBySupplier !== null;
    const isOutScan = componentSequence.includes("ProductsOut");
    const isInventoryTransfer = componentSequence.includes("InventoryTransfer");
    const isCreditInvoice = documentIsCreditInvoice(componentSequence);
    const isPurchaseReturn = documentIsPurchaseReturn(componentSequence);
    const isPurchaseOrder = documentIsPurchaseOrder(componentSequence, isScanBySupplier);
    const binsEnabled = confParameters.wmsUseProductLocationsInWarehouse == 1;
    const batchesEnabled = !isOutScan && !isInventoryTransfer && confParameters.wmsEnableScanningBatches == 1;
    const isAssignment = componentSequence.includes("Assignment");
    const isSalesOrder = documentIsSalesOrder(componentSequence, isAssembly);
    const negativeScanAllowed = (isSalesOrder || (isInventoryTransfer && isOutScan)) && confParameters.wmsEnableNegativeScanProductsOut == 1;
    const multiplePurchaseOrderScanEnabled = confParameters.wmsEnableMultiplePurchaseOrderScan == 1;
    const differentiateByPackageID = differentiateProductsByPackageID(componentSequence, isScanBySupplier, confParameters);
    const isFromMultipleDocuments = selectedDocuments.length > 1; // Only possible with purchase documents

    const isNonScannedProduct = (product) => {
        return product.hasOwnProperty("itemName");  // Is a row object from document
    };

    const displayableProducts = useMemo(() => {
        let products;
        if (isDifferencesOnly) {
            products = productsWithDifferences.map(a => ({...a}));    // Clone array;
        } else {
            products = currentSessionScannedProducts.map(a => ({...a}));    // Clone array;
        }

        // Do not display bundles and bundles' components
        products = products.filter(product => !rowIsBundle(product) && !isBundleComponent(product));

        // Add serial nos to products
        if (isSalesOrder || isPurchaseOrder) {
            const currentSessionBinRecords = getCurrentSessionBinRecords(binRecords, followUpDocuments);
            const summedSerialNos = sumSerialNoBinRecords(currentSessionBinRecords, true);

            for (let i = 0, n = summedSerialNos.length; i < n; i++) {
                const scannedProduct = products.find(product => product.productID == summedSerialNos[i].productID && product.binID == summedSerialNos[i].binID && product.name === summedSerialNos[i].name);

                if (scannedProduct) {
                    if (scannedProduct.amount > summedSerialNos[i].amount) {
                        scannedProduct.amount -= summedSerialNos[i].amount;
                        const newSerialNoProduct = Object.assign({}, scannedProduct);
                        newSerialNoProduct.amount = summedSerialNos[i].amount;
                        newSerialNoProduct.serialNo = summedSerialNos[i].serialNo;
                        newSerialNoProduct.nameWithSerialNo = `${newSerialNoProduct.name} SN: ${summedSerialNos[i].serialNo}`;
                        products.push(newSerialNoProduct);
                    } else {
                        scannedProduct.serialNo = summedSerialNos[i].serialNo;
                        scannedProduct.nameWithSerialNo = `${scannedProduct.name} SN: ${summedSerialNos[i].serialNo}`;
                    }
                }
            }
        }

        return products;
    }, [scannedProducts, summedScannedProducts, selectedDocuments]);

    const initializeSelectedBins = () => {
        let selectedBins = [];

        for (let i = 0, n = displayableProducts.length; i < n; i++) {
            let binID;
            if (isNonScannedProduct(displayableProducts[i])) {
                binID = isOutScan && binsEnabled ? "" : receivingArea.binID;
            } else {
                binID = displayableProducts[i].binID;
            }

            const selectedBin = {index: i, selectedBinID: binID};
            selectedBins.push(selectedBin);
        }

        return selectedBins;
    };

    const initializeSelectedBatches = () => {
        let selectedBatches = [];

        for (let i = 0, n = displayableProducts.length; i < n; i++) {
            if (!isNonScannedProduct(displayableProducts[i])) {
                const selectedBin = selectedBins.find(bin => bin.index === i);
                const firstScannedBatch = scannedBatches.find(batch => batch.productID == displayableProducts[i].productID && batch.binID == selectedBin.selectedBinID);

                if (firstScannedBatch) {
                    const selectedBatch = {index: i, batchCode: firstScannedBatch.batchCode, amount: firstScannedBatch.amount};
                    selectedBatches.push(selectedBatch);
                }
            }
        }

        return selectedBatches;
    };

    const initializeUserChangedBatches = () => {
        return scannedBatches.slice();
    };

    const initializeAmounts = () => {
        let amounts = [];

        for (let i = 0, n = displayableProducts.length; i < n; i++) {
            const scannedAmount = isNonScannedProduct(displayableProducts[i]) ? 0 : roundFloatingPointError(displayableProducts[i].amount);

            const amount = {index: i, amount: scannedAmount};
            amounts.push(amount);
        }

        return amounts;
    };

    const [selectedBins, setSelectedBins] = useState(initializeSelectedBins());
    const [selectedBatches, setSelectedBatches] = useState(initializeSelectedBatches());
    const [userChangedBatches, setUserChangedBatches] = useState(initializeUserChangedBatches());
    const [amounts, setAmounts] = useState(initializeAmounts());

    const getBinOptions = () => {
        return bins.map(bin => getBinOption(bin));
    };

    const getBatchOptions = (index) => {
        const product = displayableProducts.find((product, productIndex) => productIndex === index);
        const productBatches = scannedBatches.filter(batch => batch.productID == product.productID);
        return productBatches.map(batch => getBatchOption(batch, index));
    };

    const getBinOption = (bin) => {
        return {key: bin.binID, text: translateBinFromEng(bin.code, language), value: bin.binID};
    };

    const getBatchOption = (batch) => {
        return {key: batch.batchCode, text: batch.batchCode, value: batch.batchCode};
    };

    const getSelectedBinID = (index) => {
        for (let i = 0, n = selectedBins.length; i < n; i++) {
            if (selectedBins[i].index == index) {
                return selectedBins[i].selectedBinID;
            }
        }
    };

    const getSelectedBatch = (index) => {
        for (let i = 0, n = selectedBatches.length; i < n; i++) {
            if (selectedBatches[i].index == index) {
                return selectedBatches[i];
            }
        }
    };

    const setSelectedBin = (value, index) => {
        let selectedBinsCopy = selectedBins.slice();
        for (let i = 0, n = selectedBinsCopy.length; i < n; i++) {
            if (selectedBinsCopy[i].index == index) {
                selectedBinsCopy[i].selectedBinID = value;
                setSelectedBins(selectedBinsCopy);
                break;
            }
        }
    };

    const setSelectedBatch = (value, index) => {
        let selectedBatchesCopy = selectedBatches.slice();
        for (let i = 0, n = selectedBatchesCopy.length; i < n; i++) {
            if (selectedBatchesCopy[i].index == index) {
                selectedBatchesCopy[i].batchCode = value;
                setSelectedBatches(selectedBatchesCopy);
                break;
            }
        }
    };

    const getAmount = (index) => {
        for (let i = 0, n = amounts.length; i < n; i++) {
            if (amounts[i].index == index) {
                return amounts[i].amount;
            }
        }
    };

    const setAmount = (value, index, onBlur = false) => {
        let amountsCopy = amounts.slice();
        for (let i = 0, n = amountsCopy.length; i < n; i++) {
            if (amountsCopy[i].index == index) {
                const newAmount = onBlur && value === "" ? 0 : value;   // Change amount to 0 if amount is empty and user clicks away
                amountsCopy[i].amount = newAmount;
                setAmounts(amountsCopy);
                break;
            }
        }
    };

    const createButtons = (isBottom = false) => {
        // Do not display bottom group of buttons If less than 3 products
        if (!(isBottom && displayableProducts.length < 3)) {
            return (
                <div className={"settingsBtns flexCenter"}>
                    <Button className={"menuBtnHalfWidth"} size={"large"} onClick={handleGoBackOnClick}>
                        <Icon name={"chevron circle left"}/>
                        {t("goBack")}
                    </Button>
                    <Button className={"menuBtnHalfWidth"} size={"large"} color={"green"} onClick={handleSaveOnClick}>
                        {t("save")}
                    </Button>
                </div>
            )
        }
    };

    const handleGoBackOnClick = () => {
        dispatch(componentSet("ScanFinish"));
    };

    const amountsAreValid = (products) => {
        for (let i = 0, n = amounts.length; i < n; i++) {
            if (Number(amounts[i].amount) < 0 && !negativeScanAllowed) {
                dispatch(errorMessageSet(t("amountMustBeNonNegative")));
                return false;
            }
        }

        // Check if changed amounts are valid package amounts if products were scanned as a package
        if (isPurchaseOrder && differentiateByPackageID) {
            for (let i = 0, n = products.length; i < n; i++) {
                if (products[i].hasOwnProperty("packageID") && products[i].packageID != 0) {
                    const productPackage = getProductPackage(products[i], selectedDocumentsProducts.concat(productsNotOnDocument));

                    if (amounts[i].amount % productPackage.packageAmount !== 0) {
                        const message = `${t("amountMustBeDivisibleBy")} ${productPackage.packageAmount}${t("withSuffix")}. ${t("product")}: ${products[i].code}; ${t("package")}: ${productPackage.packageType}`;
                        dispatch(errorMessageSet(message));
                        return false;
                    }
                }
            }
        }

        if ((isOutScan && !isAssembly && confParameters.wmsAllowOutScanAmountExceeding == 0) || (!isOutScan && confParameters.wmsAllowInScanAmountExceeding == 0)) {
            let totalScannedAmounts = [];
            for (let i = 0, n = amounts.length; i < n; i++) {
                let productFound = false;

                for (let j = 0, n = totalScannedAmounts.length; j < n; j++) {
                    if (productsAreIdentical(componentSequence, selectedDocuments, products[i], totalScannedAmounts[j])) {    // Amounts and displayed products have the same indexes
                        productFound = true;
                        totalScannedAmounts[j].amount += Number(amounts[i].amount);     // Amounts and displayed products have the same indexes
                    }
                }

                if (!productFound) {
                    const productCopy = Object.assign({}, products[i]);
                    productCopy.amount = Number(amounts[i].amount);
                    totalScannedAmounts.push(productCopy);
                }
            }

            for (let i = 0, n = summedProducts.length; i < n; i++) {
                for (let j = 0, n = totalScannedAmounts.length; j < n; j++) {
                    if (productsAreIdentical(componentSequence, selectedDocuments, summedProducts[i], totalScannedAmounts[j]) && summedProducts[i].amount < totalScannedAmounts[j].amount) {
                        const amountOnDoc = summedProducts[i].amount;

                        if (amountOnDoc < totalScannedAmounts[j].amount) {
                            const name = summedProducts[i].hasOwnProperty("productName") ? summedProducts[i].productName : summedProducts[i].itemName;
                            dispatch(errorMessageSet(`${t("scannedAmountExceedsAvailableAmount")}. ${t("product")}: ${name}`));
                            return false;
                        }
                    }
                }
            }
        }

        return true;
    };

    const handleSaveOnClick = async () => {
        const getWmsApiBinRecordParams = (amount, product, documentID, binID) => {
            const binRecordParams = {
                productId: Number(product.productID),
                binId: Number(binID),
                amount: Number(amount),
                added: Number(user.employeeID),
                customName: product.name
            };

            if (documentID) {
                binRecordParams.referenceId = documentID;
                binRecordParams.referenceType = getDocumentType(componentSequence, isAssembly)
            }
            if (product.hasOwnProperty("packageID") && product.packageID != 0) {
                binRecordParams.packageID = product.packageID;
            }
            if (product.hasOwnProperty("serialNo")) { // OutScan only
                binRecordParams.serialNo = product.serialNo;
            }

            return binRecordParams;
        };

        if (!amountsAreValid(displayableProducts)) {
            return;
        }

        const params = [];
        let requestNo = 1;

        for (let i = 0, n = displayableProducts.length; i < n; i++) {
            // Amounts, selected bins and displayed products have the same indexes
            const amountChanged = (isNonScannedProduct(displayableProducts[i]) && amounts[i].amount !== 0) ||
                (!isNonScannedProduct(displayableProducts[i]) && Number(displayableProducts[i].amount) !== Number(amounts[i].amount));
            const binChanged = (isOutScan && isNonScannedProduct(displayableProducts[i]) && selectedBins[i].selectedBinID !== "") ||
                (!isScanBySupplier && !isNonScannedProduct(displayableProducts[i]) && displayableProducts[i].binID !== selectedBins[i].selectedBinID) ||
                (!isOutScan && isNonScannedProduct(displayableProducts[i]) && displayableProducts[i].binID !== undefined);

            if (amountChanged || binChanged) {
                if (binChanged) {
                    let amountRemainingToRemove = displayableProducts[i].amount;

                    if (!isNonScannedProduct(displayableProducts[i])) {
                        //Remove bin records from former selected bin
                        for (let j = 0, n = selectedDocuments.length; j < n; j++) {
                            for (let k = 0, n = selectedDocuments[j].rows.length; k < n; k++) {
                                if (productsAreIdentical(componentSequence, selectedDocuments, displayableProducts[i], selectedDocuments[j].rows[k])) {
                                    const rowAmount = isCreditInvoice || isPurchaseReturn ? Number(selectedDocuments[j].rows[k].amount) * (-1) : Number(selectedDocuments[j].rows[k].amount);
                                    const amountToSubtract = rowAmount >= amountRemainingToRemove ? amountRemainingToRemove : rowAmount;
                                    amountRemainingToRemove -= amountToSubtract;

                                    const amount = isOutScan ? amountToSubtract : amountToSubtract * (-1);  // Add records if ProductsOut, remove bin records if ProductsIn
                                    const documentID = selectedDocuments[j][getIdFieldName(isInventoryTransfer, isAssembly, isAssignment)];
                                    params.push(getWmsApiBinRecordParams(amount, displayableProducts[i], documentID, displayableProducts[i].binID));

                                    requestNo ++;
                                }

                                if (amountRemainingToRemove == 0) {
                                    break;
                                }
                            }

                            if (amountRemainingToRemove == 0) {
                                break;
                            }
                        }
                    }

                    let amountRemainingToAdd = amounts[i].amount;

                    //Save bin records to new selected bin
                    for (let j = 0, n = selectedDocuments.length; j < n; j++) {
                        for (let k = 0, n = selectedDocuments[j].rows.length; k < n; k++) {
                            if (productsAreIdentical(componentSequence, selectedDocuments, displayableProducts[i], selectedDocuments[j].rows[k])) {
                                const rowAmount = isCreditInvoice || isPurchaseReturn ? Number(selectedDocuments[j].rows[k].amount) * (-1) : Number(selectedDocuments[j].rows[k].amount);
                                const amountToSubtract = rowAmount >= amountRemainingToAdd ? amountRemainingToAdd : rowAmount;
                                amountRemainingToAdd -= amountToSubtract;

                                const amount = isOutScan ? amountToSubtract * (-1) : amountToSubtract;
                                const documentID = selectedDocuments[j][getIdFieldName(isInventoryTransfer, isAssembly, isAssignment)];
                                params.push(getWmsApiBinRecordParams(amount, displayableProducts[i], documentID, selectedBins[i].selectedBinID));

                                requestNo ++;
                            }

                            if (amountRemainingToAdd == 0) {
                                break;
                            }
                        }

                        if (amountRemainingToAdd == 0) {
                            break;
                        }
                    }
                } else {    // Amount changed
                    if (isScanBySupplier) {
                        params.push(getWmsApiBinRecordParams(amounts[i].amount - displayableProducts[i].amount, displayableProducts[i], null, selectedBins[i].selectedBinID));
                        requestNo ++;
                    } else {
                        if (!productIsOnDocument(displayableProducts[i])) {    // Product is not on document, InScan or OutScan with wmsEnableScanningProductsNotOnOutDocument == 1
                            const amount = isOutScan ? displayableProducts[i].amount - amounts[i].amount : amounts[i].amount - displayableProducts[i].amount;
                            const documentID = selectedDocuments[0][getIdFieldName(isInventoryTransfer, isAssembly, isAssignment)];
                            params.push(getWmsApiBinRecordParams(amount, displayableProducts[i], documentID, selectedBins[i].selectedBinID));

                            requestNo ++;
                        } else {
                            const amountIncreased = amounts[i].amount > displayableProducts[i].amount;

                            if (amountIncreased) {
                                let amountRemaining = amounts[i].amount - displayableProducts[i].amount;

                                for (let j = 0, n = selectedDocuments.length; j < n; j++) {
                                    for (let k = 0, n = selectedDocuments[j].rows.length; k < n; k++) {
                                        if (productsAreIdentical(componentSequence, selectedDocuments, displayableProducts[i], selectedDocuments[j].rows[k])) {
                                            const rowAmount = isCreditInvoice || isPurchaseReturn ? Number(selectedDocuments[j].rows[k].amount) * (-1) : Number(selectedDocuments[j].rows[k].amount);
                                            let amountToSubtract = rowAmount >= amountRemaining ? amountRemaining : rowAmount;

                                            if (isFromMultipleDocuments) {
                                                const amountLeftOnDocument = getAmountLeftOnDocument(selectedDocuments[j], displayableProducts[i].productID, binRecords);
                                                if (amountLeftOnDocument <= 0) {
                                                    continue;
                                                } else if (amountLeftOnDocument < amountToSubtract) {
                                                    amountToSubtract = amountLeftOnDocument;
                                                }
                                            }

                                            amountRemaining -= amountToSubtract;

                                            const amount = isOutScan ? amountToSubtract * (-1) : amountToSubtract;
                                            const documentID = selectedDocuments[j][getIdFieldName(isInventoryTransfer, isAssembly, isAssignment)];
                                            params.push(getWmsApiBinRecordParams(amount, displayableProducts[i], documentID, selectedBins[i].selectedBinID));

                                            requestNo ++;
                                        }

                                        if (amountRemaining == 0) {
                                            break;
                                        }
                                    }

                                    if (amountRemaining == 0) {
                                        break;
                                    }
                                }

                                // Scanned amount exceeds available amount (must be enabled in settings)
                                if (amountRemaining > 0) {
                                    if (isFromMultipleDocuments) {
                                        const documentWithProduct = selectedDocuments.find(doc => doc.rows.some(row => productsAreIdentical(componentSequence, selectedDocuments, displayableProducts[i], row)));
                                        const documentID = documentWithProduct[getIdFieldName(isInventoryTransfer, isAssembly, isAssignment)];
                                        const amount = isOutScan ? -1 * amountRemaining : amountRemaining;
                                        params.push(getWmsApiBinRecordParams(amount, displayableProducts[i], documentID, selectedBins[i].selectedBinID));
                                    } else {
                                        params[requestNo - 2][`amount`] = isOutScan ? params[requestNo - 2][`amount`] - amountRemaining : params[requestNo - 2][`amount`] + amountRemaining;
                                    }
                                }
                            } else {    // Amount decreased
                                let amountRemaining = displayableProducts[i].amount - amounts[i].amount;

                                for (let j = 0, n = selectedDocuments.length; j < n; j++) {
                                    for (let k = 0, n = selectedDocuments[j].rows.length; k < n; k++) {
                                        if (productsAreIdentical(componentSequence, selectedDocuments, displayableProducts[i], selectedDocuments[j].rows[k])) {
                                            const rowAmount = isCreditInvoice || isPurchaseReturn ? Number(selectedDocuments[j].rows[k].amount) * (-1) : Number(selectedDocuments[j].rows[k].amount);
                                            const amountToSubtract = rowAmount >= amountRemaining ? amountRemaining : rowAmount;
                                            amountRemaining -= amountToSubtract;

                                            const amount = isOutScan ? amountToSubtract : amountToSubtract * (-1);
                                            const documentID = selectedDocuments[j][getIdFieldName(isInventoryTransfer, isAssembly, isAssignment)];
                                            params.push(getWmsApiBinRecordParams(amount, displayableProducts[i], documentID, selectedBins[i].selectedBinID));

                                            requestNo ++;
                                        }

                                        if (amountRemaining == 0) {
                                            break;
                                        }
                                    }

                                    if (amountRemaining == 0) {
                                        break;
                                    }
                                }

                                // Scanned amount exceeds available amount (must be enabled in settings)
                                if (amountRemaining > 0) {
                                    if (isFromMultipleDocuments) {
                                        const documentWithProduct = selectedDocuments.find(doc => doc.rows.some(row => productsAreIdentical(componentSequence, selectedDocuments, displayableProducts[i], row)));
                                        const documentID = documentWithProduct[getIdFieldName(isInventoryTransfer, isAssembly, isAssignment)];
                                        const amount = isOutScan ? amountRemaining : -1 * amountRemaining;
                                        params.push(getWmsApiBinRecordParams(amount, displayableProducts[i], documentID, selectedBins[i].selectedBinID));
                                    } else {
                                        params[requestNo - 2][`amount`] = isOutScan ? params[requestNo - 2][`amount`] + amountRemaining : params[requestNo - 2][`amount`] - amountRemaining;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // Check for empty selected bins
        if (isOutScan && binsEnabled && params.some(param => param.binId === 0)) return dispatch(errorMessageSet(t("changesSelectedBinsEmpty")));

        if (params.length > 0) {
            console.log("saveBinRecords params", params);

            if (binsEnabled && isOutScan) {
                const binRecordsExceedingAvailableAmountsInBins = await getBinRecordsExceedingAvailableAmountsInBins(params);

                if (binRecordsExceedingAvailableAmountsInBins.length > 0) {
                    const errors = binRecordsExceedingAvailableAmountsInBins.map((record, index) => {
                        const product = displayableProducts.find(product => product.productID == record.productId);
                        const bin = bins.find(bin => bin.binID === record.binId);
                        return `${index + 1}. ${t("product")}: ${product.code}, ${t("bin")}: ${bin.code}`;
                    });

                    return dispatch(errorMessageSet(`${t("amountExceedsAvailableAmountInBin")}: ${errors.join("; ")}`));
                }
            }

            dispatch(setModal(t("confirmation"), t("confirmChange?"), () => saveBinRecords(params)));
        } else {
            dispatch(errorMessageSet(t("noChanges")));
        }
    };

    const getBinRecordsExceedingAvailableAmountsInBins = async (saveBinRecordsParams) => {
        const summedBinRecords = []; // Records with same productIDs and binIDs but different names summed together

        saveBinRecordsParams.forEach(record => {
            const existingProduct = summedBinRecords.find(summedRecord => summedRecord.productId === record.productId && summedRecord.binId === record.binId);

            if (existingProduct) {
                existingProduct.amount += record.amount;
            } else {
                summedBinRecords.push(deepCopy(record));
            }
        });

        const binRecordsWithSubtractiveAmounts = summedBinRecords.filter(record => record.amount < 0);
        if (binRecordsWithSubtractiveAmounts.length === 0) return [];

        const getBinQuantitiesRequests = binRecordsWithSubtractiveAmounts.map(record => ({
            requestName: "getBinQuantities",
            warehouseID: selectedWarehouse.warehouseID,
            productIDs: record.productId,
            binIDs: record.binId
        }));

        const binQuantities = await dispatch(makeErplyBulkRequest(getBinQuantitiesRequests, t("getBinQuantitiesError"), null, null, null, true, true));

        return binRecordsWithSubtractiveAmounts.filter(record => {
            const binQuantity = binQuantities.find(binQuantity => record.binId == binQuantity.binID && record.productId == binQuantity.productID);
            return !binQuantity || binQuantity.amount < Math.abs(record.amount);
        });
    };

    const productIsOnDocument = (product) => {
        for (let j = 0, n = selectedDocuments.length; j < n; j++) {
            for (let k = 0, n = selectedDocuments[j].rows.length; k < n; k++) {
                if (product.productID == selectedDocuments[j].rows[k].productID) {
                    return true;
                }
            }
        }

        return false
    };

    const saveBinRecords = async (params) => {
        const response = await dispatch(makeWmsApiRequest(t, "bin-inventory-record", "POST", t("saveBinRecordsError"), null, params, true));
        if (response.status !== "error") {
            if (batchesEnabled) {
                updateScannedBatches();
            }

            await dispatch(syncQuantitiesOnDocument(t, documentIDs, componentSequence, isAssembly, isScanBySupplier, supplierBin, selectedDocumentsProducts, multiplePurchaseOrderScanEnabled, scannedProducts, differentiateByPackageID, followUpDocuments, confParameters));
            dispatch(successMessageSet(t("scannedAmountsChanged")));
            dispatch(componentSet("ScanFinish"));
        }
    };

    const updateScannedBatches = () => {
        const batchesWith0AmountsRemoved = userChangedBatches.filter(batch => batch.amount !== 0 && batch.amount !== "");
        dispatch(setScannedBatches(batchesWith0AmountsRemoved));

        const params = {json_object: {WMS: {scannedAmounts: batchesWith0AmountsRemoved}}};
        dispatch(makeJsonApiRequest(t, `v1/json_object/prcinvoice/${selectedDocuments[0].id}`, "PUT", JSON.stringify(params)));
    };

    const createTables = () => {
        return displayableProducts.map((product, index) => createProductTable(product, index));
    };

    const createSelectedBinDropdown = (product, index) => {
        if (binsEnabled) {
            const selectedBinID = getSelectedBinID(index);
            const isClearable = isNonScannedProduct(product);

            return (
                <Table.Row>
                    <Table.Cell className={"tableHeading"} width={1}>{t("bin")}</Table.Cell>
                    <Table.Cell>
                        <Dropdown
                            value={selectedBinID}
                            fluid
                            selection
                            options={getBinOptions()}
                            onChange={(e, {value}) => setSelectedBin(value, index)}
                            search
                            noResultsMessage={t("noResultsFound")}
                            clearable={isClearable}
                            disabled={!isOutScan}
                        />
                    </Table.Cell>
                </Table.Row>
            )
        }
    };

    const createBatchDropdown = (product, index) => {
        if (batchesEnabled && !isNonScannedProduct(product)) {
            const selectedBatch = getSelectedBatch(index);

            if (selectedBatch) {
                const product = displayableProducts.find((product, productIndex) => productIndex === index);
                const selectedBin = selectedBins.find((bin, binIndex) => binIndex === index);
                const batchAmount = userChangedBatches.find(batch => batch.batchCode == selectedBatch.batchCode && batch.productID == product.productID && batch.binID == selectedBin.selectedBinID).amount;
                const batchAmountInput = <Input type="number" className={'binAmount'} value={batchAmount} onChange={(e, {value}) => setBatchAmount(selectedBatch, product, selectedBin, value, index)}/>;

                return (
                    <Table.Row>
                        <Table.Cell className={"tableHeading"} width={1}>{t("batch")}</Table.Cell>
                        <Table.Cell>
                            <div className={"flex"}>
                                <Dropdown
                                    value={selectedBatch.batchCode}
                                    fluid
                                    selection
                                    options={getBatchOptions(index)}
                                    onChange={(e, {value}) => setSelectedBatch(value, index)}
                                    search
                                    noResultsMessage={t("noResultsFound")}
                                />
                                {batchAmountInput}
                            </div>
                        </Table.Cell>
                    </Table.Row>
                )
            }
        }
    };

    const setBatchAmount = (selectedBatch, product, selectedBin, newAmount, index) => {
        const clonedArray = userChangedBatches.slice();
        const batch = clonedArray.find(batch => batch.batchCode == selectedBatch.batchCode && batch.productID == product.productID && batch.binID == selectedBin.selectedBinID);

        const amountDifference = newAmount - batch.amount;
        const previousScannedAmount = amounts.find(amount => amount.index === index).amount;
        const newScannedAmount = previousScannedAmount + amountDifference;
        setAmount(newScannedAmount, index);

        batch.amount = Number(newAmount);
        setUserChangedBatches(clonedArray);
    };

    const createPackageRow = (product, index) => {
        if (differentiateByPackageID && product.hasOwnProperty("packageID") && product.packageID != 0) {
            const productPackage = getProductPackage(product, selectedDocumentsProducts.concat(productsNotOnDocument));

            return (
                <Table.Row key={`package${index}`}>
                    <Table.Cell className={"tableHeading"} width={1}>{capitalise(t("package"))}</Table.Cell>
                    <Table.Cell>{productPackage.packageType}</Table.Cell>
                </Table.Row>
            )
        }
    };

    const createProductTable = (product, index) => {
        const initialProduct = initialSubtractedRows.find(initialSubtractedRow => productsAreIdentical(componentSequence, selectedDocuments, initialSubtractedRow, product));
        const productIsNotOnDocument = initialProduct === undefined;
        const amount = productIsNotOnDocument ? 0 : initialProduct.amount;
        const code2 = product.hasOwnProperty("code2") ? product.code2 : product.hasOwnProperty("barcode") ? product.barcode : product.productCode2;
        const code = product.hasOwnProperty("code") ? product.code : product.productCode;
        const name = product.hasOwnProperty("nameWithSerialNo") ? product.nameWithSerialNo : product.name;

        return (
            <Table key={index} celled structured unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign='center' colSpan={"2"}>{index + 1}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    <Table.Row>
                        <Table.Cell className={"tableHeading"} width={1}>{t("product")}</Table.Cell>
                        <Table.Cell>{name}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className={"tableHeading"} width={1}>{t("code")}</Table.Cell>
                        <Table.Cell>{code}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className={"tableHeading"} width={1}>{translateAccordingToCountry(t("EAN"), confParameters)}</Table.Cell>
                        <Table.Cell>{code2}</Table.Cell>
                    </Table.Row>
                    {createSelectedBinDropdown(product, index)}
                    <Table.Row>
                        <Table.Cell className={"tableHeading"} width={1}>{t("productsOnDoc")}</Table.Cell>
                        <Table.Cell>{amount}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className={"tableHeading"} width={1}>{t("scannedProducts")}</Table.Cell>
                        <Table.Cell>
                            <Input type="number" className={"fullWidth"} onChange={(e, {value}) => setAmount(value, index)} value={getAmount(index)}
                                   onBlur={(e) => setAmount(e.target.value, index, true)} disabled={batchesEnabled}/>
                        </Table.Cell>
                    </Table.Row>
                    {createBatchDropdown(product, index)}
                    {createPackageRow(product, index)}
                </Table.Body>
            </Table>
        )
    };

    return (
        <div>
            {createButtons()}
            {createTables()}
            {createButtons(true)}
        </div>
    )
};

export default ChangeScannedProducts
