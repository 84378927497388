import React, {useEffect} from 'react';
import {Button, Icon} from 'semantic-ui-react';
import {useTranslation} from "react-i18next";
import {componentSet, setIsLoading} from "../actions/component";
import {useDispatch, useSelector} from "react-redux";
import {errorMessageSet} from "../actions/headerMessage";
import {getWmsApiBinQuantities} from "../util/erplyRequests";
import {
    setIsCheckGoodsInBin,
    setIsCheckGoodsInReceivingArea,
    setSourceBin,
    setSourceBinQuantities
} from "../actions/placeProducts";
import {isRehvidPluss} from "../util/isClient";

const PlaceProducts = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const receivingArea = useSelector(state => state.getBinsReducer.receivingArea);
    const clientCode = useSelector(state => state.verifyUserReducer.clientCode);

    useEffect(() => {
        dispatch(setIsCheckGoodsInReceivingArea(false));
        dispatch(setIsCheckGoodsInBin(false));
    }, []);

    const handleMoveProductsFromReceivingAreaOnClick = async () => {
        dispatch(setIsLoading(true));

        getReceivingAreaBinQuantities().then((binQuantities) => {
            if (binQuantities.length > 0) {
                dispatch(setSourceBinQuantities(binQuantities));
                dispatch(setSourceBin(receivingArea));
                dispatch(componentSet("RegisterToAddressScanProduct"));
            } else {
                dispatch(errorMessageSet(t("noProductsInReceivingArea")));
            }

            dispatch(setIsLoading(false));
        });
    };

    const getReceivingAreaBinQuantities = () => {
        return dispatch(getWmsApiBinQuantities(t, false, null, receivingArea.binID, null, 0.000001, 100));
    };

    const handleTakeProductsFromAddressOnClick = () => {
        dispatch(componentSet("TakeProductsFromAddress"));
    };

    const handleRegisterProductsToAddressOnClick = () => {
        dispatch(setSourceBinQuantities(null));
        dispatch(componentSet("RegisterToAddressScanProduct"));
        dispatch(setSourceBin(null));
    };

    const handleGoBackOnClick = () => {
        dispatch(componentSet("MainMenu"));
    };

    const createCheckGoodsInReceivingAreaButton = () => {
        if (isRehvidPluss(clientCode)) {
            return <Button className={"menuBtn"} onClick={handleCheckGoodsInReceivingAreaButtonClick} size='big'>{t("checkGoodsInReceivingArea")}</Button>;
        }
    };

    const handleCheckGoodsInReceivingAreaButtonClick = () => {
        dispatch(setIsCheckGoodsInReceivingArea(true));
        handleRegisterProductsToAddressOnClick();
    };

    const handleCheckGoodsInBinOnClick = () => {
        dispatch(setIsCheckGoodsInBin(true));
        dispatch(componentSet("TakeProductsFromAddress"));
    };

    return (
        <div>
            <Button className={"menuBtn"} onClick={handleMoveProductsFromReceivingAreaOnClick} size='big'>{t("moveProductsFromReceivingArea")}</Button>
            <Button className={"menuBtn"} onClick={handleTakeProductsFromAddressOnClick} size='big'>{t("takeProductsFromAddress")}</Button>
            <Button className={"menuBtn"} onClick={handleRegisterProductsToAddressOnClick} size='big'>{t("registerProductsToAddress")}</Button>
            <Button className={"menuBtn"} onClick={handleCheckGoodsInBinOnClick} size='big'>{t("checkGoodsInBin")}</Button>
            {createCheckGoodsInReceivingAreaButton()}
            <Button className={"goBackBtn menuBtn"} onClick={handleGoBackOnClick} size='big'>
                <Icon name={"chevron circle left"}/>
                {t("goBack")}
            </Button>
        </div>
    );
};

export default PlaceProducts
