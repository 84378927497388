import {
    getScannedBatchesByDocument,
    makeErplyRequest,
    makeWmsApiRequest,
    saveAttributeToDocument
} from "../util/erplyRequests";
import {
    deepCopy, getApiAttributeValue, getDocumentType,
    getIdFieldName,
    roundFloatingPointError,
    rowIsBundle,
    sumBatches
} from "../util/misc";
import {documentFirstOpenedAttributeName} from "../util/constants";
import {warningMessageSet} from "./headerMessage";

export const setSelectedDocuments = (documents, t, unfulfillableRows = [], multiplyAmountsByMinus1 = false, isAssembly = false, isAssignment = false) => {
    return async (dispatch, getState) => {
        const state = getState();
        const confParameters = state.getConfParametersReducer.confParameters;
        const componentSequence = state.componentReducer.componentSequence;
        const warehouse = state.getWarehousesReducer.selectedWarehouse;
        const excludedEANsArray = confParameters.wmsExcludedEans.split(",");
        const isPurchaseOrder = documents.length > 0 && documents[0].type && documents[0].type === "PRCORDER";
        const batchesEnabled = confParameters.wmsEnableScanningBatches == 1 && isPurchaseOrder;
        const isInventoryTransfer = documents.length > 0 && documents[0].hasOwnProperty("inventoryTransferID");
        const isOutScan = componentSequence.includes("ProductsOut");
        const isSalesOrder = isOutScan && documents.length > 0 && documents[0].type && documents[0].type === "ORDER";

        if (isAssignment) {
            // This makes row data management easier
            documents[0].rows = documents[0].assignmentRows;
        } else if (isInventoryTransfer && isOutScan) {
            // Get destination warehouse to show in header
            const params = {request: "getWarehouses", warehouseID: documents[0].warehouseToID};
            dispatch(makeErplyRequest(params, t("getWarehousesError"))).then(warehouses => {
                dispatch(setDestinationWarehouse(warehouses[0]));
            });
        }

        const productIDs = getProductIDsFromDocs(documents, excludedEANsArray);
        const products = productIDs.length === 0 ? [] : await getProducts(t, dispatch, productIDs, isAssembly);
        const recipeProducts = isAssembly ? await getRecipeProducts(t, dispatch, products, warehouse.warehouseID) : [];   // Assembly products' components
        const stockProducts = products.filter(product => product.nonStockProduct != 1);
        const nonStockProducts = products.filter(product => product.nonStockProduct == 1);
        const nonStockProductIDsArray = nonStockProducts.map(product => String(product.productID));
        const rowsWithSummedAmounts = getRowsWithSummedAmounts(documents, excludedEANsArray, nonStockProductIDsArray, unfulfillableRows, multiplyAmountsByMinus1, isSalesOrder);
        const componentsWithSummedAmounts = isAssembly ? getComponentsWithSummedAmounts(documents[0], products, recipeProducts) : [];   // Assembly products' components' amounts summed
        const scannedBatchesByDocument = batchesEnabled ? await getScannedBatchesByDocument(dispatch, documents, t, true) : [];
        const scannedBatches = sumBatches(scannedBatchesByDocument);
        const documentIDs = documents.length > 0 ? getDocumentIDs(documents, isAssembly, isAssignment) : [];

        // Add WMS API "START" events for analytics
        // Ignore "process already started" API error to avoid checking document's "START" status
        documentIDs.forEach(documentID => {
            dispatch(makeWmsApiRequest(t, "analytics", "POST", "", null, {documentId: documentID, eventType: "START", documentType: getDocumentType(componentSequence, isAssembly)}, false, null, true));
        });

        // Add/check "documentFirstOpenedInWMS" attribute
        documents.forEach(document => {
            let documentFirstOpenedData = getApiAttributeValue(documentFirstOpenedAttributeName, document.attributes);
            const documentHasNotBeenOpened = documentFirstOpenedData === false;
            const currentTimestamp = new Date().getTime();
            const user = state.verifyUserReducer.user;

            if (documentHasNotBeenOpened) {
                const attributeValue = JSON.stringify({timestamp: currentTimestamp, employeeID: user.employeeID, employeeName: user.employeeName});
                dispatch(saveAttributeToDocument(document, documentFirstOpenedAttributeName, attributeValue, t, isAssembly));
            } else {
                documentFirstOpenedData = JSON.parse(documentFirstOpenedData);

                if (documentFirstOpenedData.employeeID != user.employeeID && currentTimestamp - documentFirstOpenedData.timestamp <= 3 * 60 * 1000) { // 3 minutes
                    const warning = `${t("thisDocumentHasAlreadyBeenOpenedBy")} ${documentFirstOpenedData.employeeName}`;
                    dispatch(warningMessageSet(warning));
                }
            }
        });

        if (!isInventoryTransfer) dispatch(setIsInTransitTransfer(false));

        return dispatch({
            type: "SET_SELECTED_DOCUMENTS",
            payload: {
                selectedDocuments: isAssembly ? putRecipeProductsOnRows(documents, componentsWithSummedAmounts) : documents,
                rowsWithSummedProducts: rowsWithSummedAmounts,
                documentIDs: documentIDs.join(","),
                excludedRows: getExcludedRows(documents, excludedEANsArray, nonStockProductIDsArray),
                selectedDocumentsProducts: stockProducts,
                unfulfillableRows: unfulfillableRows,    // Rows not returned by getFulfillableOrders (orderedAmount exceeds fulfillableAmount). They are added to re-orders in ScanFinish
                isAssembly: isAssembly,
                recipeProducts: recipeProducts,   // Assembly products' components
                componentsWithSummedAmounts: componentsWithSummedAmounts,
                scannedBatches: scannedBatches,
                scannedBatchesByDocument: scannedBatchesByDocument
            }
        })
    }
};

// Substitute assembly document rows with assembly products' components
const putRecipeProductsOnRows = (documents, componentsWithSummedAmounts) => {
    documents[0].rows = [];
    for (let i = 0, n = componentsWithSummedAmounts.length; i < n; i++) {
        documents[0].rows[i] = componentsWithSummedAmounts[i];
    }
    return documents;
};

// Get assembly products' components
const getRecipeProducts = (t, dispatch, products, warehouseID) => {
    const getRecipeProductsIDs = (products) => {
        let productIDs = [];
        for (let i = 0, n = products.length; i < n; i++) {
            if (products[i].hasOwnProperty("productComponents")) {
                for (let j = 0, n = products[i].productComponents.length; j < n; j++) {
                    productIDs.push(products[i].productComponents[j].componentID)
                }
            }
        }
        return productIDs;
    };

    const recipeProductsIDs = [...new Set(getRecipeProductsIDs(products))];
    return getProducts(t, dispatch, recipeProductsIDs, false, true, warehouseID);
};

const getProducts = async (t, dispatch, productIDs, getRecipes = false, getFIFOCost = false, warehouseID) => {
    if (productIDs.length === 0) {
        return [];
    }

    const params = {
        request: "getProducts",
        productIDs: productIDs.join(","),
        getPackageInfo: 1,
        includeMatrixVariations: 1,
        getRecipes: getRecipes ? 1 : 0,
        getFIFOCost: getFIFOCost ? 1 : 0,
        getContainerInfo: 1
    };

    if (getFIFOCost) {
        params.warehouseID = warehouseID;
    }

    return dispatch(makeErplyRequest(params, t("getProductsError"), null, null, null, true));
};

export const setScannedProducts = (products) => {
    return {
        type: "SET_SCANNED_PRODUCTS",
        payload: products
    }
};

// Needed if multiple purchase orders have been selected for scanning
export const setScannedProductsByDocument = (products) => {
    return {
        type: "SET_SCANNED_PRODUCTS_BY_DOCUMENT",
        payload: products
    }
};

export const setCurrentSessionScannedProducts = (products) => {
    return {
        type: "SET_CURRENT_SESSION_SCANNED_PRODUCTS",
        payload: products
    }
};

export const setProductsWithDifferences = (products) => {
    return {
        type: "SET_PRODUCTS_WITH_DIFFERENCES",
        payload: products
    }
};

export const setSummedScannedProducts = (products) => {
    return {
        type: "SET_SUMMED_SCANNED_PRODUCTS",
        payload: products
    }
};

export const setInitialSubtractedRows = (rows) => {    // Rows with scanned products from all previous scan sessions subtracted
    return {
        type: "SET_INITIAL_SUBTRACTED_ROWS",
        payload: rows
    }
};

export const setFollowUpDocuments = (documents) => {    // Follow-up documents of the scanned documents
    return {
        type: "SET_FOLLOW_UP_DOCUMENTS",
        payload: documents
    }
};

export const setDocumentExtraFields = (extraFields) => {
    return {
        type: "SET_EXTRA_FIELDS",
        payload: extraFields
    }
};

export const setSelectedDocumentsProducts = (products) => {
    return {
        type: "SET_SELECTED_DOCUMENTS_PRODUCTS",
        payload: products
    }
};

export const setSelectedDocumentsAttribute = (attributeName, attributeValue) => {
    return {
        type: "SET_SELECTED_DOCUMENTS_ATTRIBUTE",
        payload: {
            attributeName: attributeName,
            attributeValue: attributeValue
        }
    }
};

export const removeSelectedDocumentsAttribute = (attributeName) => {
    return {
        type: "REMOVE_SELECTED_DOCUMENTS_ATTRIBUTE",
        payload: attributeName
    }
};

// Set id of the document created after scanning is confirmed
export const setCreatedDocumentId = (id) => {
    return {
        type: "SET_CREATED_DOCUMENT_ID",
        payload: id
    }
};

// Alas-Kuul-specific; true - products displayed are only TC2000 products, false - products displayed are only non-TC2000 products
export const setIsTc2000Only = (bool) => {
    return {
        type: "SET_IS_TC2000_ONLY",
        payload: bool
    }
};

// Alas-Kuul-specific. Needed for KAPP/RIIUL logic
export const setDocumentHasBeenPreviouslyScanned = (bool) => {
    return {
        type: "SET_DOCUMENT_HAS_BEEN_PREVIOUSLY_SCANNED",
        payload: bool
    }
};

// Set user-entered price to a scanned product not on document (Products In only)
export const setUserEnteredPrice = (productID, price) => {
    return {
        type: "SET_USER_ENTERED_PRICE",
        payload: {
            productID: productID,
            price: price
        }
    }
};

// Add a product not on document to redux memory so recurrent requesting of the product is not necessary (Products In only)
export const setProductsNotOnDocument = (products) => {
    return {
        type: "SET_PRODUCTS_NOT_ON_DOCUMENT",
        payload: products
    }
};

// Bins containing document's products
export const setBinsContainingDocumentProducts = (bins) => {
    return {
        type: "SET_BINS_CONTAINING_DOCUMENT_PRODUCTS",
        payload: bins
    }
};

// Add batch to be later saved as a row attribute to the created document
export const addScannedBatch = (batch) => {
    return {
        type: "ADD_SCANNED_BATCH",
        payload: batch
    }
};

// Replace all existing batches with new ones
export const setScannedBatches = (batches) => {
    return {
        type: "SET_SCANNED_BATCHES",
        payload: batches
    }
};

// Update a product on document (after being edited in CreateEditProduct)
export const updateProductOnDocument = (updatedFields) => {
    return {
        type: "UPDATE_PRODUCT_ON_DOCUMENT",
        payload: updatedFields
    }
};

// Set supplier whose purchase invoices will be used to map scanned products after scan (Rehvid Pluss only)
export const setScanBySupplier = (supplier) => {
    return {
        type: "SET_SCAN_BY_SUPPLIER",
        payload: supplier
    }
};

// Set an intermediary bin where products from a specific supplier's purchase invoices are scanned to and later removed after confirming scan (Rehvid Pluss only)
export const setSupplierBin = (bin) => {
    return {
        type: "SET_SUPPLIER_BIN",
        payload: bin
    }
};

// All selected supplier's purchase invoices with stateID 3 or 5 (Rehvid Pluss only)
export const setSupplierPurchaseInvoices = (purchaseInvoices) => {
    return {
        type: "SET_SUPPLIER_PURCHASE_INVOICES",
        payload: purchaseInvoices
    }
};

// Unsaved scanned batches only in Redux state
export const setUnsavedScannedBatches = (batches) => {
    return {
        type: "SET_UNSAVED_SCANNED_BATCHES",
        payload: batches
    }
};

// Set destination warehouse to show in header
export const setDestinationWarehouse = (warehouse) => {
    return {
        type: "SET_DESTINATION_WAREHOUSE",
        payload: warehouse
    }
};

// Scanned document is an inventory transfer from the in-transit warehouse set with confParameters.wmsInTransitWarehouseID
export const setIsInTransitTransfer = (isInTransitTransfer) => {
    return {
        type: "SET_IS_IN_TRANSIT_TRANSFER",
        payload: isInTransitTransfer
    }
};

// Used when changing a bundle's amount in "Scan" view
export const setNewAmountToProductInRowsWithSummedProducts = (productID, newAmount, isBundle, bundleName) => {
    return {
        type: "SET_NEW_AMOUNT_TO_PRODUCT_IN_ROWS_WITH_SUMMED_PRODUCTS",
        payload: {
            productID: productID,
            newAmount: newAmount,
            isBundle: isBundle,
            bundleName: bundleName
        }
    }
};

export const setVatRates = (vatRates) => {
    return {
        type: "SET_VAT_RATES",
        payload: vatRates
    }
};

const getExcludedRows = (documents, excludedEANsArray, nonStockProductIDsArray) => {
    let excludedRows = [];

    for (let i = 0, m = documents.length; i < m; i++) {
        for (let j = 0, n = documents[i].rows.length; j < n; j++) {
            const hasProductID = documents[i].rows[j].productID != 0;
            const isNonStockProduct = nonStockProductIDsArray.includes(documents[i].rows[j].productID);

            if (productIsInExcludedProducts(documents[i].rows[j], excludedEANsArray) || !hasProductID || isNonStockProduct) {
                excludedRows.push(documents[i].rows[j]);
            }
        }
    }

    return excludedRows;
};

const getProductIDsFromDocs = (documents, excludedEANsArray) => {
    let ids = [];

    for (let i = 0, m = documents.length; i < m; i++) {
        for (let j = 0, n = documents[i].rows.length; j < n; j++) {
            const hasProductID = documents[i].rows[j].productID != 0;

            // Do not include product if it is in excluded EAN-s in settings or if it is a row with a custom product name
            if (!productIsInExcludedProducts(documents[i].rows[j], excludedEANsArray) && hasProductID) {
                ids.push(documents[i].rows[j].productID);

                // Get component IDs from fulfillable order's bundles
                if (documents[i].rows[j].productComponents) {
                    ids = ids.concat(documents[i].rows[j].productComponents.map(component => component.productID));
                }
            }
        }
    }

    return [...new Set(ids)];
};

const getComponentsWithSummedAmounts = (selectedDocument, assemblyProducts, recipeProducts) => {
    let summedComponents = [];

    for (let i = 0, n = selectedDocument.rows.length; i < n; i++) {
        const assemblyProduct = assemblyProducts.find(assemblyProduct => assemblyProduct.productID == selectedDocument.rows[i].productID);

        if (assemblyProduct.hasOwnProperty("productComponents")) {
            for (let j = 0, n = assemblyProduct.productComponents.length; j < n; j++) {
                let amountHasBeenSummed = false;

                for (let k = 0, n = summedComponents.length; k < n; k++) {
                    if (summedComponents[k].productID == assemblyProduct.productComponents[j].componentID) {
                        const totalComponentAmount = Number(selectedDocument.rows[i].amount) * assemblyProduct.productComponents[j].amount;
                        summedComponents[k].amount = roundFloatingPointError(Number(summedComponents[k].amount) + totalComponentAmount);
                        amountHasBeenSummed = true;
                    }
                }

                if (!amountHasBeenSummed) {
                    const component = recipeProducts.find(product => product.productID == assemblyProduct.productComponents[j].componentID);
                    component.amount = roundFloatingPointError(Number(selectedDocument.rows[i].amount) * assemblyProduct.productComponents[j].amount);
                    summedComponents.push(component);
                }
            }
        }
    }

    return summedComponents;
};

const getRowsWithSummedAmounts = (selectedDocuments, excludedEANsArray, nonStockProductIDsArray, unfulfillableRows, multiplyAmountsByMinus1, isSalesOrder) => {
    let summedRows = [];
    let amountHasBeenSummed = false;

    for (let i = 0, n = selectedDocuments.length; i < n; i++) {
        for (let j = 0, m = selectedDocuments[i].rows.length; j < m; j++) {
            amountHasBeenSummed = false;

            for (let k = 0, l = summedRows.length; k < l; k++) {
                if (summedRows[k].productID === selectedDocuments[i].rows[j].productID &&
                    !(isSalesOrder && summedRows[k].itemName !== selectedDocuments[i].rows[j].itemName)) {
                    summedRows[k].amount = roundFloatingPointError(Number(summedRows[k].amount) + Number(selectedDocuments[i].rows[j].amount));
                    amountHasBeenSummed = true;
                }
            }

            const hasProductID = selectedDocuments[i].rows[j].productID != 0;
            const isNonStockProduct = nonStockProductIDsArray.includes(String(selectedDocuments[i].rows[j].productID));

            // Do not include product if 1) It is in excluded EAN-s in settings 2) It is a text row with no productID 3) It is a non-stock product
            if (!amountHasBeenSummed && !productIsInExcludedProducts(selectedDocuments[i].rows[j], excludedEANsArray) && hasProductID && !isNonStockProduct) {
                const rowCopy = Object.assign({}, selectedDocuments[i].rows[j]);
                rowCopy.amount = roundFloatingPointError(rowCopy.amount);

                if (rowCopy.hasOwnProperty(("itemName"))) {
                    rowCopy.name = rowCopy.itemName;    // This is for easier data management later on
                }
                summedRows.push(rowCopy);
            }
        }
    }

    // Subtract unfulfillable rows
    for (let i = 0, n = summedRows.length; i < n; i++) {
        for (let j = 0, n = unfulfillableRows.length; j < n; j++) {
            if (summedRows[i].productID == unfulfillableRows[j].productID) {
                summedRows[i].amount -= unfulfillableRows[j].amount;
            }
        }

        if (multiplyAmountsByMinus1) {
            summedRows[i].amount = summedRows[i].amount * (-1);
        }
    }

    // Remove 0-amount rows (due to subtracting unfulfillable rows)
    summedRows = summedRows.filter(row => row.amount != 0);

    // Add bundles' components
    summedRows.forEach(row => {
        if (rowIsBundle(row)) {
            row.productComponents.forEach(component => {
                const componentClone = deepCopy(component);
                componentClone.amount = roundFloatingPointError(row.amount) * component.amountInBundle;
                componentClone.bundleID = row.productID;
                componentClone.bundleName = row.itemName;
                summedRows.push(componentClone);
            })
        }
    });

    return summedRows;
};

const productIsInExcludedProducts = (row, excludedEANsArray) => {
    return (row.hasOwnProperty("barcode") && row.barcode !== "" && excludedEANsArray.includes(row.barcode)) ||
        (row.hasOwnProperty("code2") && row.code2 !== "" && excludedEANsArray.includes(row.code2)) ||
        (row.hasOwnProperty("productCode2") && row.productCode2 !== "" && excludedEANsArray.includes(row.productCode2));
};

const getDocumentIDs = (documents, isAssembly, isAssignment) => {
    const isInventoryTransfer = documents[0].hasOwnProperty("inventoryTransferID");
    return documents.map(document => document[getIdFieldName(isInventoryTransfer, isAssembly, isAssignment)]);
};
