import React, {useState} from 'react';
import {Button, Input, Label} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {componentSet, setSequence} from "../actions/component";
import {useDispatch, useSelector} from "react-redux";
import {errorMessageSet} from "../actions/headerMessage";
import {makeErplyRequest, sendRequest} from "../util/erplyRequests";
import {getApiAttributeValue, getConfParameterValue, isNotAPositiveInteger} from "../util/misc";
import {sendToPrintService} from "../util/printRequests";
import * as PDFLib from 'pdf-lib';
import {omnivaDeliveryTypeAttributeName} from "../util/constants";

const OmnivaParcelLabels = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const createdDocumentId = useSelector(state => state.scanReducer.createdDocumentId);
    const selectedDocuments = useSelector(state => state.scanReducer.selectedDocuments);
    const componentSequence = useSelector(state => state.componentReducer.componentSequence);
    const clientCode = useSelector(state => state.verifyUserReducer.clientCode);
    const apiSessionKey = useSelector(state => state.verifyUserReducer.user.sessionKey);

    const isInventoryTransfer = componentSequence.includes("InventoryTransfer");
    const isOpenInAndroidApp = window.ReactNativeWebView;
    const canPrint = dispatch(getConfParameterValue("wmsDpdPrinterName")) !== "" && dispatch(getConfParameterValue("wmsPrinterServiceAddress")) !== "" && isOpenInAndroidApp;

    const noOfParcelsAttributeName = "noOfOmnivaParcels";
    const pickupPointCodeAttributeName = "omnivaPickupPointCode";
    const pickupPointCountryAttributeName = "omnivaPickupPointCountry";
    const mainServiceAttributeName = "omnivaMainService";
    const additionalServicesAttributeName = "omnivaAdditionalServices";
    const courierDeliveryTypeName = "COURIER";
    // const serviceUrl = "http://localhost:8024/"; // TEST
    const serviceUrl = "https://omv-int.erply.com/";

    const getInitialNoOfParcels = () => {
        const noOfParcelsOnBaseDocument = getApiAttributeValue(noOfParcelsAttributeName, selectedDocuments[0].attributes);
        return noOfParcelsOnBaseDocument && noOfParcelsOnBaseDocument != 0 && noOfParcelsOnBaseDocument !== "" ? noOfParcelsOnBaseDocument : 1;
    };

    const [noOfParcels, setNoOfParcels] = useState(getInitialNoOfParcels());

    const setPreScanComponent = () => {
        const preScanComponent = componentSequence[3];
        const newSequence = componentSequence.slice(0, 4);

        dispatch(componentSet(preScanComponent));
        dispatch(setSequence(newSequence));
    };

    const sendToOmniva = async () => {
        if (isNotAPositiveInteger(noOfParcels)) {
            return dispatch(errorMessageSet(t("notAPositiveInteger")));
        } else if (noOfParcels > 20) {
            return dispatch(errorMessageSet(t("maxNoOfParcelsIs20")));
        }

        let deliveryType = courierDeliveryTypeName;
        let pickupPointCountry = "EE";
        let pickupPointCode = "";
        let mainService = getApiAttributeValue(mainServiceAttributeName, selectedDocuments[0].attributes);
        let additionalServices = getApiAttributeValue(additionalServicesAttributeName, selectedDocuments[0].attributes);

        if (!isInventoryTransfer) {
            const createdDocument = await getCreatedDocument();
            deliveryType = getApiAttributeValue(omnivaDeliveryTypeAttributeName, createdDocument.attributes);
            pickupPointCountry = getApiAttributeValue(pickupPointCountryAttributeName, createdDocument.attributes);
            mainService = getApiAttributeValue(mainServiceAttributeName, createdDocument.attributes);
            additionalServices = getApiAttributeValue(additionalServicesAttributeName, createdDocument.attributes);
            if (!deliveryType || deliveryType === "") return dispatch(errorMessageSet(t("omnivaDeliveryTypeMissing")));

            if (deliveryType !== courierDeliveryTypeName) {
                pickupPointCode = getApiAttributeValue(pickupPointCodeAttributeName, createdDocument.attributes);
                if (!pickupPointCode || pickupPointCode === "") return dispatch(errorMessageSet(t("omnivaPickupPointCodeMissing")));
            }
        }

        if (additionalServices === "" || additionalServices === false) {
            additionalServices = [];
        } else {
            additionalServices = additionalServices.split("|");
        }

        const body = {
            clientCode: clientCode,
            sessionKey: apiSessionKey,
            documentID: createdDocumentId,
            noOfParcels: Number(noOfParcels),
            mainService: mainService,
            additionalServices: additionalServices,
            deliveryChannel: deliveryType,
            isInventoryTransfer: isInventoryTransfer,
            pickupPointCountry: pickupPointCountry
        };

        if (deliveryType !== courierDeliveryTypeName) body.pickupPointCode = pickupPointCode;

        const response = await dispatch(sendRequest(serviceUrl + "createDelivery", t("omnivaError"), true, "POST", {}, JSON.stringify(body)));
        getLabels(response);
    };

    const getLabels = async (serviceResponse) => {
        if (serviceResponse?.successAddressCards?.length > 0) {
            let base64Pdf = serviceResponse.successAddressCards[0].fileData;

            if (serviceResponse.successAddressCards.length > 1) {
                const urls = serviceResponse.successAddressCards.map(label => `data:application/pdf;base64,${encodeURI(label.fileData)}`);
                base64Pdf = await mergePDFs(urls);
            }

            if (canPrint) {
                sendToPrintService(base64Pdf, dispatch(getConfParameterValue("wmsDpdPrinterName")), dispatch(getConfParameterValue("wmsPrinterServiceAddress")));
            } else {
                downloadPdf(base64Pdf);
            }

            setPreScanComponent();
        }
    };

    const downloadPdf = (base64Pdf) => {
        const byteCharacters = atob(base64Pdf);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const file = new Blob([byteArray], { type: 'application/pdf;base64' });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `OMNIVA_${t("parcelLabels")}`;
        link.dispatchEvent(new MouseEvent('click'));
    };

    const mergePDFs = async (urls) => {
        const pdfDoc = await PDFLib.PDFDocument.create();
        const numDocs = urls.length;

        for (let i = 0; i < numDocs; i++) {
            const donorPdfBytes = await fetch(urls[i]).then(res => res.arrayBuffer());
            const donorPdfDoc = await PDFLib.PDFDocument.load(donorPdfBytes);
            const docLength = donorPdfDoc.getPageCount();
            for (let j = 0; j < docLength; j++) {
                const [donorPage] = await pdfDoc.copyPages(donorPdfDoc, [j]);
                pdfDoc.addPage(donorPage);
            }
        }

        const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });

        // Strip off the first part to the first comma "data:image/png;base64,iVBORw0K..."
        return pdfDataUri.substring(pdfDataUri.indexOf(',') + 1);
    };

    const getCreatedDocument = () => {
        return dispatch(makeErplyRequest({
            request: "getSalesDocuments",
            id: createdDocumentId
        }, t("getSalesDocumentsError"))).then(docs => docs[0]);
    };

    return (
        <div>
            <div className={"searchRow"}>
                <Label className={"searchLabel searchLabelMedium"} size={"large"} pointing={"right"}>{t("noOfParcels")}</Label>
                <Input className={"searchInput searchInputMedium"} type="number" onInput={e => setNoOfParcels(e.target.value)} value={noOfParcels}/>
            </div>
            <Button className={"menuBtn"} primary onClick={sendToOmniva} size='big'>{t("createOmnivaDelivery")}</Button>
            <Button className={"menuBtn marginTop"} onClick={setPreScanComponent} size='big'>{t("doNotWantOmnivaLabels")}</Button>
        </div>
    );
};

export default OmnivaParcelLabels
