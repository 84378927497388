const initialState = {
    labelPrinterTimeoutFinished: true   // A timeout is set between every print action to refrain the user from printing too much at a time. If true, labels may be printed
};

export const printingReducer = (state = initialState, action) => {
    switch(action.type) {
        case "SET_LABEL_PRINTER_TIMEOUT_FINISHED":
            return {
                ...state,
                labelPrinterTimeoutFinished: action.payload
            };
        default:
            return state;
    }
};
