import {componentSet, setSequence, setSettingsBtnHasBeenClicked} from "../actions/component";
import {Button, Icon} from "semantic-ui-react";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {setModal} from "../actions/modal";

const GoBackToStartBtn = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const componentSequence = useSelector(state => state.componentReducer.componentSequence);

    let classNames = ["menuBtnHalfWidth", "margin0Right", "padding0"];
    if (props.hasOwnProperty("removeClassNames")) {
        for (let i = 0, n = props.removeClassNames.length; i < n; i++) {
            classNames = classNames.filter(className => className !== props.removeClassNames[i]);
        }
    }
    if (props.hasOwnProperty("addClassNames")) {
        classNames = classNames.concat(props.addClassNames);
    }

    const size = props.hasOwnProperty("size") ? props.size : "large";

    const isFromBinManagement = componentSequence.includes("BinManagement");

    const handleGoBackToStartOnClick = () => {
        if (props.displayWarning) {
            dispatch(setModal(t("warning"), props.warning, handleOnYes));
        } else {
            if (props.extraOnClickFunction) {
                props.extraOnClickFunction();
            }
            goToMainMenu();
        }
    };

    const handleOnYes = () => {
        props.onYes().then(() => {
            goToMainMenu();
        });
    };

    const goToMainMenu = () => {
        if (isFromBinManagement) {
            dispatch(setSettingsBtnHasBeenClicked(false));
        }

        dispatch(componentSet("MainMenu"));
        dispatch(setSequence(["ChooseWarehouse", "MainMenu"]));
    };

    return <Button className={classNames.join(" ")} onClick={handleGoBackToStartOnClick} size={size}>
        <Icon name={"angle double left"}/>
        {t("goBackToStart")}
    </Button>
};

export default GoBackToStartBtn
