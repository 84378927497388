import React, { useState, useEffect, useRef } from 'react';
import {Button, Input} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {componentSet, setIsLoading, setSequence} from "../actions/component";
import {errorMessageSet} from "../actions/headerMessage";
import {useDispatch, useSelector} from "react-redux";
import GoBackToStartBtn from "./GoBackToStartBtn";
import {getWmsApiBinQuantities, makeErplyRequest} from "../util/erplyRequests";
import {setScannedProducts, setSourceBin, setSourceBinQuantities} from "../actions/placeProducts";
import GoBackBtn from "./GoBackBtn";
import {binQuantityFieldsToProductFields, translateBinToEng} from "../util/misc";

const TakeProductsFromAddress = () => {
    const binCodeInput = useRef(null);

    const [binCode, setBinCode] = useState("");

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);
    const language = useSelector(state => state.languageReducer.language);
    const isCheckGoodsInBin = useSelector(state => state.placeProductsReducer.isCheckGoodsInBin);

    useEffect(() => {
        binCodeInput.current.focus();
    }, []);

    const handleGoBackOnClick = () => {
        dispatch(componentSet("PlaceProducts"));
    };

    const handleOkOnClick = async () => {
        if (binCode === "") {
            dispatch(errorMessageSet(t("enterFromAddress")));
        } else {
            dispatch(setIsLoading(true));
            const bin = await getBin();

            if (bin === undefined) {
                dispatch(errorMessageSet(t("noBinFound")));
            } else {
                const minimumAmount = isCheckGoodsInBin ? null : 0.000001;
                const pageSize = isCheckGoodsInBin ? 999999 : 100;
                const binQuantities = await dispatch(getWmsApiBinQuantities(t, false, null, bin.binID, null, minimumAmount, pageSize));

                if (isCheckGoodsInBin) binQuantityFieldsToProductFields(binQuantities);

                if (binQuantities.length > 0 || isCheckGoodsInBin) {
                    dispatch(setSourceBinQuantities(binQuantities));
                    dispatch(setSourceBin(bin));
                    dispatch(setSequence(["ChooseWarehouse", "MainMenu", "PlaceProducts"]));    // Avoids route tweaking later
                    dispatch(setScannedProducts([]));
                    dispatch(componentSet("RegisterToAddressScanProduct"));
                } else {
                    dispatch(errorMessageSet(t("noProductsInBin")));
                }
            }

            dispatch(setIsLoading(false));
        }
    };

    const getBin = () => {
        const params = {
            request: "getBins",
            status: "ACTIVE",
            warehouseID: selectedWarehouse.warehouseID,
            code: translateBinToEng(binCode, language)
        };

        return dispatch(makeErplyRequest(params, t("getBinsError"), null, null, null, false, false)).then((bins) => {
            return bins[0];
        });
    };

    return (
        <div>
            <Input ref={binCodeInput} className={"searchDocNoInput"} onInput={e => setBinCode(e.target.value)} fluid placeholder={t('enterFromAddress')} value={binCode}/>
            <div className={"btnsGroup"}>
                <Button className={"menuBtn"} primary size={"big"} onClick={handleOkOnClick}>Ok</Button>
                <div className={"flex flexCenter"}>
                    <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                    <GoBackToStartBtn/>
                </div>
            </div>
        </div>
    );
};

export default TakeProductsFromAddress
