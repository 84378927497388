import React, {useEffect, useRef, useState} from 'react';
import {Dropdown, Label} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {makePimApiRequest} from "../util/erplyRequests";
import {getSuppliersSuccess} from "../actions/getSuppliers";

const SupplierSearchInput = ({setSupplierID}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const suppliers = useSelector(state => state.getSuppliersReducer.suppliers);

    const supplierInput = useRef(null);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        setTimeout(() => {
            supplierInput.current.toggle();
        }, 500);    // Transition (if exists) time
    }, []);

    const getSuppliers = async (name) => {
        if (name.length > 1) {
            const filter = encodeURIComponent(`[["name","contains","${name}"],"and",["supplier_type_id","!=","3"]]`);   // Filter out contacts of suppliers
            const suppliers = await dispatch(makePimApiRequest(t, `v1/supplier?filter=${filter}`, "GET"));
            dispatch(getSuppliersSuccess(suppliers));
            setDropdownOpen(suppliers.length > 0);
        } else {
            setDropdownOpen(false);
        }
    };

    const getOptions = () => {
        return suppliers.map(supplier => getSupplierOption(supplier));
    };

    const getSupplierOption = (supplier) => {
        return {key: supplier.id, text: supplier.name, value: supplier.id};
    };

    const handleOnChange = (event, { value }) => {
        setSupplierID(value);
        setDropdownOpen(false);
    };

    const handleNameOnBlur = () => {
        setDropdownOpen(false);
    };

    return (
        <div className={"searchRow"}>
            <Label className={"searchLabel searchLabelShort"} size={"large"} pointing={"right"}>{t("supplier")}</Label>
            <Dropdown
                fluid
                className={"searchInput searchInputLong"}
                placeholder={t("enterName")}
                selection
                open={dropdownOpen}
                onInput={e => getSuppliers(e.target.value)}
                options={getOptions()}
                search
                onChange={handleOnChange}
                clearable
                noResultsMessage={t("noResultsFound")}
                onBlur={handleNameOnBlur}
                ref={supplierInput}
            />
        </div>
    );
};

export default SupplierSearchInput
