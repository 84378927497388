import React, { useState, useRef, useEffect } from 'react';
import {Button, Input} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {errorMessageSet} from "../actions/headerMessage";
import {useDispatch, useSelector} from "react-redux";
import {getBinRecords, makeErplyRequest} from "../util/erplyRequests";
import {componentSet, previousComponentSet} from "../actions/component";
import {setDocumentHasBeenPreviouslyScanned, setSelectedDocuments} from "../actions/scan";
import GoBackToStartBtn from "./GoBackToStartBtn";
import GoBackBtn from "./GoBackBtn";
import {filterOutDocumentsWithNoRows, getApiAttributeValue, isNotAPositiveInteger} from "../util/misc";
import {setModal} from "../actions/modal";

const InventoryTransfer = () => {
    const inventoryTransferNoInput = useRef(null);

    const [inventoryTransferNo, setInventoryTransferNo] = useState("");

    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);
    const componentSequence = useSelector(state => state.componentReducer.componentSequence);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);

    const isOutInventoryTransfer = componentSequence.includes("ProductsOut");

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        inventoryTransferNoInput.current.focus();
    }, []);

    const handleStartHandlingOnClick = () => {
        if (inventoryTransferNo === "") return dispatch(errorMessageSet(t("enterInventoryTransferOrderNo")));
        if (isNotAPositiveInteger(inventoryTransferNo)) return dispatch(errorMessageSet(t("noDocFound")));

        getInventoryTransfers().then(async (inventoryTransfers) => {
            if (inventoryTransfers.length === 0) {
                dispatch(errorMessageSet(t("noDocFound")));
            } else {
                checkIfScanningHasAlreadyStarted(inventoryTransfers);
            }
        });
    };

    const checkIfScanningHasAlreadyStarted = (selectedDocuments) => {
        dispatch(getBinRecords(t, selectedDocuments[0].inventoryTransferID, "INVENTORY_TRANSFER", null, true)).then((binRecords) => {
            if (binRecords.length > 0) {
                const modalText = isOutInventoryTransfer ? t("packingAlreadyStarted") : t("handlingAlreadyStarted");
                dispatch(setModal(t("confirmation"), modalText, () => goToScan(selectedDocuments, true)));
            } else {
                goToScan(selectedDocuments, false);
            }
        });
    };

    const goToScan = async (inventoryTransfers, documentHasBeenPreviouslyScanned = false) => {
        dispatch(setDocumentHasBeenPreviouslyScanned(documentHasBeenPreviouslyScanned));
        await dispatch(setSelectedDocuments(inventoryTransfers, t));
        dispatch(previousComponentSet("InventoryTransfer"));
        dispatch(componentSet("Scan"));
    };

    const handleGoBackOnClick = () => {
        isOutInventoryTransfer ? dispatch(componentSet("ProductsOut")) : dispatch(componentSet("ProductsIn"));
    };

    const getInventoryTransfers = () => {
        const params = {
            request: "getInventoryTransfers",
            type: "TRANSFER_ORDER",
            inventoryTransferNo: inventoryTransferNo,
            confirmed: 1
        };

        // if (confParameters.wmsEnableRepeatedTransferOrderScan != 1) params.fulfilled = 0;
        if (isOutInventoryTransfer) {
            params["warehouseFromID"] = selectedWarehouse.warehouseID;
        } else {
            params["warehouseToID"] = selectedWarehouse.warehouseID;
        }

        return dispatch(makeErplyRequest(params, t("getInventoryTransfersError"))).then((invTransfers) => {
            console.log(invTransfers)
            return invTransfers
            let transfers = filterOutDocumentsWithNoRows(invTransfers);
            transfers = transfers.filter(order => order.status !== "REJECTED");
            if (confParameters.wmsInTransitWarehouseID !== "") {
                transfers = transfers.filter(transferOrder => getApiAttributeValue("followUpTransferID", transferOrder.attributes) === false);
            }
            if (confParameters.wmsEnableRepeatedTransferOrderScan == 1) {
                return transfers;
            } else {
                return transfers.filter(transfer => transfer.followupInventoryTransferID == 0); // Filter out transfer orders with follow up transfers
            }
        });
    };

    const handleSearchTransfersOnClick = () => {
        dispatch(componentSet("SearchInventoryTransfers"));
    };

    return (
        <div>
            <Input type={"number"} ref={inventoryTransferNoInput} onInput={e => setInventoryTransferNo(e.target.value)} className={"searchDocNoInput"} fluid placeholder={t("enterInventoryTransferOrderNo")} />
            <Button className={"menuBtn"} primary size={"big"} onClick={handleStartHandlingOnClick}>{isOutInventoryTransfer ? t("startIssuing") : t("startHandling")} </Button>
            <Button className={"menuBtn"} color={"instagram"} onClick={handleSearchTransfersOnClick} size='big'>{t("searchInventoryTransferOrders")}</Button>
            <div className={"flex flexCenter"}>
                <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                <GoBackToStartBtn/>
            </div>
        </div>
    );
};

export default InventoryTransfer
