export const setModal = (heading, message, onYes, includeTextInput = false, inputValue = "", dropdownOptions = [], onNo = null) => {
    return {
        type: "SET_MODAL",
        payload: {
            heading: heading,
            message: message,
            onYes: onYes,
            includeTextInput: includeTextInput,
            inputValue: inputValue,
            dropdownOptions: dropdownOptions,
            onNo: onNo
        }
    }
};
