import React, { useState, useEffect, useRef } from 'react';
import {Button, Checkbox, Input, Modal, Table} from 'semantic-ui-react'
import GoBackToStartBtn from "./GoBackToStartBtn";
import GoBackBtn from "./GoBackBtn";
import {componentSet} from "../actions/component";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getConfParameterValue, translateAccordingToCountry} from "../util/misc";
import {errorMessageSet, successMessageSet} from "../actions/headerMessage";
import {makeErplyRequest} from "../util/erplyRequests";
import {setUndeliveredGoods} from "../actions/undeliveredGoods";
import PrintLabelsButton from "./PrintLabelsButton";

const UndeliveredGoods = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const undeliveredGoods = useSelector(state => state.undeliveredGoodsReducer.undeliveredGoods);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);

    const addEanInput = useRef(null);
    const changeEanInput = useRef(null);

    const [changeEanValue, setChangeEanValue] = useState("");
    const [changeEanProductID, setChangeEanProductID] = useState(null);
    const [addEanBtnClickedProductID, setAddEanBtnClickedProductID] = useState(null);   // Id of the product without EAN whose "Add EAN" button has been clicked
    const [missingEan, setMissingEan] = useState("");   // Value typed into the "Add EAN" field
    const [productIDsToLabelPrint, setProductIDsToLabelPrint] = useState([]);   // Products checked for label printing

    const isOpenInAndroidApp = window.ReactNativeWebView;
    // const canLabelPrint = true
    const canLabelPrint = dispatch(getConfParameterValue("wmsLabelPrinterName")) !== "" && confParameters.wmsLabelTemplateID !== "" && dispatch(getConfParameterValue("wmsPrinterServiceAddress")) !== "" && isOpenInAndroidApp;

    useEffect(() => {
        if (addEanBtnClickedProductID !== null) {
            addEanInput.current.focus();
        }
    }, [addEanBtnClickedProductID]);
    useEffect(() => {
        if (changeEanProductID !== null) {
            changeEanInput.current.focus();
        }
    }, [changeEanProductID]);

    const checkRowToLabelPrint = (productID) => {
        let newRowIDsToLabelPrint;

        if (productIDsToLabelPrint.includes(productID)) {
            newRowIDsToLabelPrint = productIDsToLabelPrint.filter(printableRowID => printableRowID !== productID);
        } else {
            newRowIDsToLabelPrint = productIDsToLabelPrint.concat([productID]);
        }

        setProductIDsToLabelPrint(newRowIDsToLabelPrint);
    };

    const saveEan = async (productID, ean) => {
        if (ean.trim() === "") {
            return dispatch(errorMessageSet(translateAccordingToCountry(t("enterEan"), confParameters)));
        }

        const params = {
            request: "saveProduct",
            productID: productID,
            code2: ean
        };

        const response = await dispatch(makeErplyRequest(params, t("saveProductError")));
        if (response.status !== "error") {
            let updatedUndeliveredGoods = undeliveredGoods.map(a => ({...a}));    // Clone array
            for (let i = 0, n = updatedUndeliveredGoods.length; i < n; i++) {
                if (updatedUndeliveredGoods[i].productID == productID) {
                    updatedUndeliveredGoods[i].code2 = ean;
                    break;
                }
            }

            dispatch(setUndeliveredGoods(updatedUndeliveredGoods));
            removeAddEanInput();
            dispatch(successMessageSet(translateAccordingToCountry(t("eanAdded"), confParameters)));
        }
    };

    const removeAddEanInput = () => {
        setMissingEan("");
        setAddEanBtnClickedProductID(null);
    };

    const createPrintLabelsBtn = (isTop = false) => {
        let classNames = "fullWidth marginBottomSmall";
        classNames += isTop ? "" : " marginTopSmall";
        const products = productIDsToLabelPrint.map(productID => undeliveredGoods.find(row => row.productID == productID));

        return <PrintLabelsButton products={products} className={classNames} size={"large"} text={t("printCheckedLabels")} isCheckedProducts={true}/>;
    };

    const displayChangeEanModal = () => {
        const closeChangeEanModal = () => {
            setChangeEanProductID(null);
            setChangeEanValue("");
        };

        return (
            <div>
                <Modal size={"mini"} open={changeEanProductID !== null} onClose={closeChangeEanModal}>
                    <Modal.Header>{translateAccordingToCountry(t("changeEan"), confParameters)}</Modal.Header>
                    <Modal.Content>
                        <Input ref={changeEanInput} onInput={e => setChangeEanValue(e.target.value)} fluid value={changeEanValue}/>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={closeChangeEanModal} negative>{t("no")}</Button>
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content={t("yes")}
                            onClick={() => {saveEan(changeEanProductID, changeEanValue); closeChangeEanModal()}}
                        />
                    </Modal.Actions>
                </Modal>
            </div>
        )
    };

    const createRow = (product, index) => {
        const getLabelCheckbox = (productID) => {
            if (canLabelPrint) {
                return <Checkbox className={"marginLeftSmall"} checked={productIDsToLabelPrint.includes(productID)} onChange={() => checkRowToLabelPrint(productID)}/>;
            }
        };

        let code2Cell;
        let code2Heading = <Table.Cell className={"tableHeading"} width={1}>{translateAccordingToCountry(t("EAN"), confParameters)}</Table.Cell>;

        if (product.code2 !== null && product.code2 !== "") {
            code2Cell = product.code2;

            // Add "Change EAN" button
            if (confParameters.wmsEnableCode2Change == 1) {
                code2Heading = <Table.Cell className={"tableHeading"} width={1}>
                    <div className={"flex"}>
                        {translateAccordingToCountry(t("EAN"), confParameters)}
                        <Button className={"marginLeftSmall"} size={"mini"} color={"green"} icon={"pencil alternate"} onClick={() => {setChangeEanProductID(product.productID); setChangeEanValue(product.code2)}}/>
                    </div>
                </Table.Cell>
            }
        } else {
            const addEanBtnHasBeenClicked = addEanBtnClickedProductID == product.productID;

            if (addEanBtnHasBeenClicked) {
                code2Cell = <div>
                    <Input ref={addEanInput} onInput={e => setMissingEan(e.target.value)} value={missingEan}/>
                    <Button className={"marginLeftSmall"} size={"mini"} color={"green"} icon={"check"} onClick={() => saveEan(product.productID, missingEan)}/>
                    <Button size={"mini"} color={"red"} icon={"cancel"} onClick={() => removeAddEanInput()}/>
                </div>;
            } else {
                code2Cell = <Button size={"mini"} color={"green"} onClick={() => setAddEanBtnClickedProductID(product.productID)}>{t("addEan")}</Button>;
            }
        }

        let codeCell = [<p key={product.code}>{product.code}</p>];
        codeCell.push(<PrintLabelsButton products={[product]} className={"marginLeftSmall"} size={"mini"} text={t("printLabel")}/>);

        return (
            <Table key={index} color={"black"} celled structured unstackable>
                <Table.Body>
                    <Table.Row>
                        {code2Heading}
                        <Table.Cell>{code2Cell}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className={"tableHeading"} width={1}>{t("code")}{getLabelCheckbox(product.productID)}</Table.Cell>
                        <Table.Cell><div className={"flex"}>{codeCell}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className={"tableHeading"} width={1}>{t("amount")}</Table.Cell>
                        <Table.Cell>{product.amount}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className={"tableHeading"} width={1}>{t("name")}</Table.Cell>
                        <Table.Cell>{product.itemName}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        )
    };

    const displayUndeliveredGoods = () => {
        return undeliveredGoods.map((row, index) => createRow(row, index));
    };

    const handleGoBackOnClick = () => {
        dispatch(componentSet("OrderListConfirmation"));
    };

    return (
        <div className={"overflow"}>
            {displayChangeEanModal()}
            <div id={"documentRowsTable"}>
                {displayUndeliveredGoods()}
            </div>
            <div className={"btnsGroup"}>
                {createPrintLabelsBtn(true)}
                <div className={"flexCenter"}>
                    <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                    <GoBackToStartBtn/>
                </div>
            </div>
        </div>
    );
};

export default UndeliveredGoods
