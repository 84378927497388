import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Dropdown, Icon, Input, Transition, Form, Radio} from "semantic-ui-react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {componentSet} from "../actions/component";
import {errorMessageSet, successMessageSet} from "../actions/headerMessage";
import {makeCafaApiRequest, makeErplyBulkRequest, makeErplyRequest} from "../util/erplyRequests";
import {getUserGroupsSuccess} from "../actions/getUserGroups";
import {setConfParameters} from "../actions/getConfParameters";
import {
    capitalise,
    exitSettingsView,
    getCafaConfParameterValue,
    isNotAPositiveInteger,
    translateAccordingToCountry
} from "../util/misc";
import {getProductPackageTypesSuccess} from "../actions/getProductPackageTypes";
import {isArvutitark, isOkaidi, isPrimePartnerEst, isTAF, isTesterClientCode} from "../util/isClient";
import {getWarehousesSuccess} from "../actions/getWarehouses";
import {setCafaConfParameters} from "../actions/newErplyApis";

const Settings = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const userGroups = useSelector(state => state.getUserGroupsReducer.userGroups);
    const productPackageTypes = useSelector(state => state.getProductPackageTypesReducer.productPackageTypes);
    const warehouses = useSelector(state => state.getWarehousesReducer.warehouses);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const user = useSelector(state => state.verifyUserReducer.user);
    const cafaConfParameters = useSelector(state => state.newErplyApisReducer.cafaConfParameters);
    const clientCode = useSelector(state => state.verifyUserReducer.clientCode);
    const isPPEst = isPrimePartnerEst(clientCode);

    // Modules that can be enabled with wmsEnabledModules setting
    const modules = ["ProductsIn", "ProductsOut", "PlaceProducts", "Replenishment", "AmountsInBins", "Stocktakings"];

    const [showGeneralSettings, setShowGeneralSettings] = useState(true);
    const [showProductsInSettings, setShowProductsInSettings] = useState(true);
    const [showProductsOutSettings, setShowProductsOutSettings] = useState(true);
    const [showInventoryTransferOrderSettings, setShowInventoryTransferOrderSettings] = useState(true);
    const [showStocktakingSettings, setShowStocktakingSettings] = useState(true);

    const [adminUserGroup, setAdminUserGroup] = useState(["1"]);
    const [enabledModules, setEnabledModules] = useState(modules);
    const [rapidInScan, setRapidInScan] = useState(0);
    const [rapidOutScan, setRapidOutScan] = useState(0);
    const [useProductLocationsInWarehouse, setUseProductLocationsInWarehouse] = useState(0);
    const [selectSuggestedBin, setSelectSuggestedBin] = useState(0);
    const [excludedEans, setExcludedEans] = useState("");
    const [createdPurchasedDocument, setCreatedPurchaseDocument] = useState("PRCWAYBILL");
    const [confirmPurchaseDocuments, setConfirmPurchaseDocuments] = useState(0);
    const [totalPurchaseAmountAsScanAmount, setTotalPurchaseAmountAsScanAmount] = useState(0);    // Purchase orders only
    const [totalPurchaseAmountAsScanAmountListClickOnly, setTotalPurchaseAmountAsScanAmountListClickOnly] = useState(0);
    const [totalSalesAmountAsScanAmount, setTotalSalesAmountAsScanAmount] = useState(0);    // Sales orders only
    const [totalSalesAmountAsScanAmountListClickOnly, setTotalSalesAmountAsScanAmountListClickOnly] = useState(0);
    const [totalInventoryTransferAmountAsScanAmount, setTotalInventoryTransferAmountAsScanAmount] = useState(0);    // Inventory transfers orders only
    const [totalInventoryTransferAmountAsScanAmountListClickOnly, setTotalInventoryTransferAmountAsScanAmountListClickOnly] = useState(0);
    const [createSalesDocAfterScan, setCreateSalesDocAfterScan] = useState(1);
    const [createdSalesDocument, setCreatedSalesDocument] = useState("WAYBILL");
    const [confirmSalesDocuments, setConfirmSalesDocuments] = useState(0);
    const [allowOutScanAmountExceeding, setAllowOutScanAmountExceeding] = useState(0);
    const [allowInScanAmountExceeding, setAllowInScanAmountExceeding] = useState(0);
    const [confirmInventoryTransfers, setConfirmInventoryTransfers] = useState(0);
    const [inTransitWarehouseID, setInTransitWarehouseID] = useState("");
    const [confirmWriteOffs, setConfirmWriteOffs] = useState(0);
    const [sendComparisonEmail, setSendComparisonEmail] = useState(0);
    const [emailReceiver, setEmailReceiver] = useState("creator");
    const [emailReceiverCustomEmail, setEmailReceiverCustomEmail] = useState("");
    const [showPackageCalculations, setShowPackageCalculations] = useState(0);
    const [createInvRegistrationOnBinAmountChange, setCreateInvRegistrationOnBinAmountChange] = useState(0);
    const [enableCreatingWaybills, setEnableCreatingWaybills] = useState(0);
    const [enableCreatingInvWriteOffs, setEnableCreatingInvWriteOffs] = useState(0);
    const [enableCreatingInvTransferOrders, setEnableCreatingInvTransferOrders] = useState(0);
    const [enableCreatingPurchaseInvoices, setEnableCreatingPurchaseInvoices] = useState(0);
    const [enableConfirmingDirectlyCreatedPurchaseInvoices, setEnableConfirmingDirectlyCreatedPurchaseInvoices] = useState(0);
    const [enableProductsReturn, setEnableProductsReturn] = useState(0);
    const [enableCode2Change, setEnableCode2Change] = useState(0);
    const [enableRepeatedTransferOrderScan, setEnableRepeatedTransferOrderScan] = useState(0);
    const [maxAmountAllowedToScan, setMaxAmountAllowedToScan] = useState("");
    const [enableSettingPurchaseDocNoAfterScan, setEnableSettingPurchaseDocNoAfterScan] = useState(0);
    const [firstCheckPackageCodes, setFirstCheckPackageCodes] = useState(0);
    const [enableNegativeScanProductsOut, setEnableNegativeScanProductsOut] = useState(0);
    const [setPurchaseOrderAlwaysFulfilled, setSetPurchaseOrderAlwaysFulfilled] = useState(0);
    const [enableBinOrder, setEnableBinOrder] = useState(0);
    const [enableScanningBatches, setEnableScanningBatches] = useState(0);
    const [enableCreatingProducts, setEnableCreatingProducts] = useState(0);
    const [excludedPackageTypesFromPackageCalculator, setExcludedPackageTypesFromPackageCalculator] = useState([]);
    const [enableScanningProductsNotOnOutDocument, setEnableScanningProductsNotOnOutDocument] = useState(0);
    const [enableScanningProductsNotOnInDocument, setEnableScanningProductsNotOnInDocument] = useState(1);
    const [scanAsBatch, setScanAsBatch] = useState(0);
    const [enableAssembly, setEnableAssembly] = useState(0);
    const [enableCreatingReturnWaybills, setEnableCreatingReturnWaybills] = useState(1);
    const [invTransferScanReorderMultiple, setInvTransferScanReorderMultiple] = useState(0);
    const [createPurchaseInvoiceRowPrice, setCreatePurchaseInvoiceRowPrice] = useState("purchasePrice");
    const [enableSettingCreatePurchaseInvoiceRowPrice, setEnableSettingCreatePurchaseInvoiceRowPrice] = useState(0);
    const [enableAddingCode2, setEnableAddingCode2] = useState(1);
    const [enableMultiplePurchaseOrderScan, setEnableMultiplePurchaseOrderScan] = useState(0);
    const [enableCreatingPurchaseReturns, setEnableCreatingPurchaseReturns] = useState(0);
    const [enablePurchaseReturn, setEnablePurchaseReturn] = useState(0);
    const [searchProductCodeFromCommaSeparatedCodes, setSearchProductCodeFromCommaSeparatedCodes] = useState(0);
    const [enableCreatingInvTransfers, setEnableCreatingInvTransfers] = useState(0);
    const [sortProductsOnCreatedDocumentBy, setSortProductsOnCreatedDocumentBy] = useState("scanOrder");
    const [putProductsScannedAsPackagesOnCreatedDocumentAsPackages, setPutProductsScannedAsPackagesOnCreatedDocumentAsPackages] = useState(0);
    const [prioritiseCode1, setPrioritiseCode1] = useState(0);
    const [confirmBinAmountWithUserIfLessThan, setConfirmBinAmountWithUserIfLessThan] = useState(1);
    const [compulsoryScanUserGroups, setCompulsoryScanUserGroups] = useState([]);
    const [setDefaultAmountAfterScan, setSetDefaultAmountAfterScan] = useState(1);
    const [inventoryRegistrationRowPrice, setInventoryRegistrationRowPrice] = useState("cost");
    const [enableSettingInScanBin, setEnableSettingInScanBin] = useState(1);
    const [searchSalesOrderByNumberPaymentWarning, setSearchSalesOrderByNumberPaymentWarning] = useState("noWarningIfNoPayments");
    const [createdPurchaseDocumentNoFieldValue, setCreatedPurchaseDocumentNoFieldValue] = useState("regnumber");

    const [openPrintersMenu, setOpenPrintersMenu] = useState(false);
    const [printerServiceAddress, setPrinterServiceAddress] = useState("");
    const [labelPrinterName, setLabelPrinterName] = useState("");
    const [labelTemplateID, setLabelTemplateID] = useState("");
    const [dpdPrinterName, setDpdPrinterName] = useState("");
    const [printPackingSlip, setPrintPackingSlip] = useState(0);
    const [packingSlipPrinterName, setPackingSlipPrinterName] = useState("");
    const [packingSlipTemplateID, setPackingSlipTemplateID] = useState("");
    const [printSalesDocument, setPrintSalesDocument] = useState(0);
    const [salesDocumentPrinterName, setSalesDocumentPrinterName] = useState("");
    const [salesDocumentTemplateID, setSalesDocumentTemplateID] = useState("");
    const [printInventoryTransferReport, setPrintInventoryTransferReport] = useState(0);
    const [inventoryTransferReportPrinterName, setInventoryTransferReportPrinterName] = useState("");
    const [inventoryTransferReportTemplateID, setInventoryTransferReportTemplateID] = useState("");
    const [printSsccLabel, setPrintSsccLabel] = useState(0);
    const [ssccLabelPrinterName, setSsccLabelPrinterName] = useState("");
    const [ssccLabelTemplateID, setSsccLabelTemplateID] = useState("");

    const [openInterfacesMenu, setOpenInterfacesMenu] = useState(false);
    const [isUsingTelema, setIsUsingTelema] = useState(0);
    const [isUsingDpd, setIsUsingDpd] = useState(0);
    const [isUsingVenipak, setIsUsingVenipak] = useState(0);
    const [isUsingSmartpost, setIsUsingSmartpost] = useState(0);
    const [isUsingOmniva, setIsUsingOmniva] = useState(0);

    // Mapping between WMS settings in confParameters and their setState functions and state values in this component
    const confParamSettingsMapping = [
        // Can access and change settings
        {confParameter: "wmsAdminUserGroup", setFunction: setAdminUserGroup, newValue: adminUserGroup, isMultipleChoice: true},
        // Enabled modules
        {confParameter: "wmsEnabledModules", setFunction: setEnabledModules, newValue: enabledModules, isMultipleChoice: true},
        // If set to 1: when scanning in, Enter key press clicks "Ok" button and default amount is 1. If set to 0: when scanning, Enter key press selects amount field and default amount is ""
        {confParameter: "wmsRapidInScan", setFunction: setRapidInScan, newValue: rapidInScan, isCafaParameter: !isTAF(clientCode)},
        // If set to 1: when scanning out, Enter key press opens bin dropdown and default amount is 1. If set to 0: when scanning, Enter key press selects amount field and default amount is ""
        {confParameter: "wmsRapidOutScan", setFunction: setRapidOutScan, newValue: rapidOutScan, isCafaParameter: !isTAF(clientCode)},
        // Enable bin-related functionality
        {confParameter: "wmsUseProductLocationsInWarehouse", setFunction: setUseProductLocationsInWarehouse, newValue: useProductLocationsInWarehouse},
        // When scanning, set suggested bin (bin selected in dropdown of scanned product) as selected
        {confParameter: "wmsSelectSuggestedBin", setFunction: setSelectSuggestedBin, newValue: selectSuggestedBin},
        // Products with these EAN codes are not displayed when scanning, eg transportation (they are added as rows when confirming document scan)
        {confParameter: "wmsExcludedEans", setFunction: setExcludedEans, newValue: excludedEans},
        // Document created after confirming purchase order scan
        {confParameter: "wmsCreatedPurchaseDocument", setFunction: setCreatedPurchaseDocument, newValue: createdPurchasedDocument},
        // Created purchase documents are confirmed
        {confParameter: "wmsConfirmPurchaseDocuments", setFunction: setConfirmPurchaseDocuments, newValue: confirmPurchaseDocuments},
        // When scanning, set the total amount of product on document as the initial scannable amount (purchase orders)
        {confParameter: "wmsTotalPurchaseAmountAsScanAmount", setFunction: setTotalPurchaseAmountAsScanAmount, newValue: totalPurchaseAmountAsScanAmount},
        // If set to 1, wmsTotalPurchaseAmountAsScanAmount effects only manually choosing products from the list (and not scanning) in Scan view
        {confParameter: "wmsTotalPurchaseAmountAsScanAmountListClickOnly", setFunction: setTotalPurchaseAmountAsScanAmountListClickOnly, newValue: totalPurchaseAmountAsScanAmountListClickOnly},
        // When scanning, set the total amount of product on document as the initial scannable amount (sales orders)
        {confParameter: "wmsTotalSalesAmountAsScanAmount", setFunction: setTotalSalesAmountAsScanAmount, newValue: totalSalesAmountAsScanAmount},
        // If set to 1, wmsTotalSalesAmountAsScanAmount effects only manually choosing products from the list (and not scanning) in Scan view
        {confParameter: "wmsTotalSalesAmountAsScanAmountListClickOnly", setFunction: setTotalSalesAmountAsScanAmountListClickOnly, newValue: totalSalesAmountAsScanAmountListClickOnly},
        // When scanning, set the total amount of product on document as the initial scannable amount (inventory transfer orders)
        {confParameter: "wmsTotalInventoryTransferAmountAsScanAmount", setFunction: setTotalInventoryTransferAmountAsScanAmount, newValue: totalInventoryTransferAmountAsScanAmount},
        // If set to 1, wmsTotalInventoryTransferAmountAsScanAmount effects only manually choosing products from the list (and not scanning) in Scan view
        {confParameter: "wmsTotalInventoryTransferAmountAsScanAmountListClickOnly", setFunction: setTotalInventoryTransferAmountAsScanAmountListClickOnly, newValue: totalInventoryTransferAmountAsScanAmountListClickOnly},
        // Create follow-up sales document after sales order scan
        {confParameter: "wmsCreateSalesDocumentAfterScan", setFunction: setCreateSalesDocAfterScan, newValue: createSalesDocAfterScan},
        // Document created after confirming sales order scan
        {confParameter: "wmsCreatedSalesDocument", setFunction: setCreatedSalesDocument, newValue: createdSalesDocument},
        // Created sales documents are confirmed
        {confParameter: "wmsConfirmSalesDocuments", setFunction: setConfirmSalesDocuments, newValue: confirmSalesDocuments},
        // When scanning out, allow scanning amounts larger than on order
        {confParameter: "wmsAllowOutScanAmountExceeding", setFunction: setAllowOutScanAmountExceeding, newValue: allowOutScanAmountExceeding},
        // When scanning in, allow scanning amounts larger than on order
        {confParameter: "wmsAllowInScanAmountExceeding", setFunction: setAllowInScanAmountExceeding, newValue: allowInScanAmountExceeding},
        // Created inventory transfers are confirmed
        {confParameter: "wmsConfirmInventoryTransfers", setFunction: setConfirmInventoryTransfers, newValue: confirmInventoryTransfers},
        // If set, "Create new inventory transfer order" creates two transfer orders: 1) to inTransit 2) from inTransit
        {confParameter: "wmsInTransitWarehouseID", setFunction: setInTransitWarehouseID, newValue: inTransitWarehouseID},
        // Created write-offs are confirmed
        {confParameter: "wmsConfirmWriteOffs", setFunction: setConfirmWriteOffs, newValue: confirmWriteOffs},
        // Send an email if a confirmed scan has differences
        {confParameter: "wmsSendComparisonEmail", setFunction: setSendComparisonEmail, newValue: sendComparisonEmail},
        // Receiver of the email
        {confParameter: "wmsEmailReceiver", setFunction: setEmailReceiver, newValue: emailReceiver},
        // Email address if "wmsEmailReceiver" value is "customEmail"
        {confParameter: "wmsEmailReceiverCustomEmail", setFunction: setEmailReceiverCustomEmail, newValue: emailReceiverCustomEmail},
        // Calculates the number of different packages needed to fill amount in Scan component
        {confParameter: "wmsShowPackageCalculations", setFunction: setShowPackageCalculations, newValue: showPackageCalculations},
        // Creates an inventory registration act when changing bin amounts in Amounts In Bins
        {confParameter: "wmsCreateInvRegistrationOnBinAmountChange", setFunction: setCreateInvRegistrationOnBinAmountChange, newValue: createInvRegistrationOnBinAmountChange},
        // Enables creating waybills under Products Out
        {confParameter: "wmsEnableCreatingWaybills", setFunction: setEnableCreatingWaybills, newValue: enableCreatingWaybills},
        // Enables creating write offs under Products Out
        {confParameter: "wmsEnableCreatingInvWriteOffs", setFunction: setEnableCreatingInvWriteOffs, newValue: enableCreatingInvWriteOffs},
        // Enables creating inventory transfer orders under Products Out
        {confParameter: "wmsEnableCreatingInvTransferOrders", setFunction: setEnableCreatingInvTransferOrders, newValue: enableCreatingInvTransferOrders},
        // Enables creating purchase invoices under Products In
        {confParameter: "wmsEnableCreatingPurchaseInvoices", setFunction: setEnableCreatingPurchaseInvoices, newValue: enableCreatingPurchaseInvoices},
        // Enables confirming purchase invoices created under Products In -> Create purchase invoice
        {confParameter: "wmsEnableConfirmingDirectlyCreatedPurchaseInvoices", setFunction: setEnableConfirmingDirectlyCreatedPurchaseInvoices, newValue: enableConfirmingDirectlyCreatedPurchaseInvoices},
        // Enables returning products under Products In
        {confParameter: "wmsEnableProductsReturn", setFunction: setEnableProductsReturn, newValue: enableProductsReturn},
        // Displays "Change EAN" buttons in Scan (Products In) view
        {confParameter: "wmsEnableCode2Change", setFunction: setEnableCode2Change, newValue: enableCode2Change},
        // Enable repeated inventory transfer order scanning
        {confParameter: "wmsEnableRepeatedTransferOrderScan", setFunction: setEnableRepeatedTransferOrderScan, newValue: enableRepeatedTransferOrderScan},
        // Max amount allowed to enter to "Amount" field in "Scan" view. Exceeding it will empty the field
        {confParameter: "wmsMaxAmountAllowedToScan", setFunction: setMaxAmountAllowedToScan, newValue: maxAmountAllowedToScan},
        // Enables the user to set the number of the created purchase document in ScanFinish view
        {confParameter: "wmsEnableSettingPurchaseDocNoAfterScan", setFunction: setEnableSettingPurchaseDocNoAfterScan, newValue: enableSettingPurchaseDocNoAfterScan},
        // If set to 1, checks for package codes before product codes when scanning
        {confParameter: "wmsFirstCheckPackageCodes", setFunction: setFirstCheckPackageCodes, newValue: firstCheckPackageCodes},
        // Enables scanning negative amounts in Products Out
        {confParameter: "wmsEnableNegativeScanProductsOut", setFunction: setEnableNegativeScanProductsOut, newValue: enableNegativeScanProductsOut},
        // If set to 1, sets purchase order state to fulfilled even if not all products are scanned
        {confParameter: "wmsSetPurchaseOrderAlwaysFulfilled", setFunction: setSetPurchaseOrderAlwaysFulfilled, newValue: setPurchaseOrderAlwaysFulfilled},
        // If set to 1, displayed bin options in Scan view are sorted by bins' "order" field value
        {confParameter: "wmsEnableBinOrder", setFunction: setEnableBinOrder, newValue: enableBinOrder},
        // If set to 1, creates a batch scanning field in Scan (purchase orders only), only used by Okaidi
        {confParameter: "wmsEnableScanningBatches", setFunction: setEnableScanningBatches, newValue: enableScanningBatches},
        // If set to 1, enables creating products in Scan view
        {confParameter: "wmsEnableCreatingProducts", setFunction: setEnableCreatingProducts, newValue: enableCreatingProducts},
        // Exclude package types from package calculation in Scan
        {confParameter: "wmsExcludedPackageTypesFromPackageCalculator", setFunction: setExcludedPackageTypesFromPackageCalculator, newValue: excludedPackageTypesFromPackageCalculator, isMultipleChoice: true},
        // Enable scanning products not on ProductsOut document
        {confParameter: "wmsEnableScanningProductsNotOnOutDocument", setFunction: setEnableScanningProductsNotOnOutDocument, newValue: enableScanningProductsNotOnOutDocument},
        // Enable scanning products not on ProductsIn document
        {confParameter: "wmsEnableScanningProductsNotOnInDocument", setFunction: setEnableScanningProductsNotOnInDocument, newValue: enableScanningProductsNotOnInDocument},
        // Enable displaying scanned products as a batch and saving them as bin records all at once (purchase orders only), only used by M&M
        {confParameter: "wmsScanAsBatch", setFunction: setScanAsBatch, newValue: scanAsBatch},
        // Enable Assembly under Products Out
        {confParameter: "wmsEnableAssembly", setFunction: setEnableAssembly, newValue: enableAssembly},
        // Enable creating return waybills
        {confParameter: "wmsEnableCreatingReturnWaybills", setFunction: setEnableCreatingReturnWaybills, newValue: enableCreatingReturnWaybills},
        // When scanning inv transfer orders, scan amount must be divisible by product's reorderMultiple if product has reorderMultiple set
        {confParameter: "wmsInvTransferScanReorderMultiple", setFunction: setInvTransferScanReorderMultiple, newValue: invTransferScanReorderMultiple},
        // Product price field used when saving purchase document rows with "Create purchase invoice"
        {confParameter: "wmsCreatePurchaseInvoiceRowPrice", setFunction: setCreatePurchaseInvoiceRowPrice, newValue: createPurchaseInvoiceRowPrice},
        // Display "Enter price" modal when adding rows to purchase invoice with "Create purchase invoice"
        {confParameter: "wmsEnableSettingCreatePurchaseInvoiceRowPrice", setFunction: setEnableSettingCreatePurchaseInvoiceRowPrice, newValue: enableSettingCreatePurchaseInvoiceRowPrice},
        // Display "Add EAN" button in Scan view if product has no EAN
        {confParameter: "wmsEnableAddingCode2", setFunction: setEnableAddingCode2, newValue: enableAddingCode2},
        // Enable scanning multiple purchase orders at once. Requests attributes from JSON API before scanning purchase orders
        {confParameter: "wmsEnableMultiplePurchaseOrderScan", setFunction: setEnableMultiplePurchaseOrderScan, newValue: enableMultiplePurchaseOrderScan},
        // Enables creating purchase returns under Products Out
        {confParameter: "wmsEnableCreatingPurchaseReturns", setFunction: setEnableCreatingPurchaseReturns, newValue: enableCreatingPurchaseReturns},
        // Enables scanning unconfirmed purchase returns under Products Out. Confirming the scan confirms the document
        {confParameter: "wmsEnablePurchaseReturn", setFunction: setEnablePurchaseReturn, newValue: enablePurchaseReturn},
        // Enables searching product codes from product code fields where different product codes are separated by commas, works on all code fields (1-8)
        {confParameter: "api_get_product_use_new_fulltext_index", setFunction: setSearchProductCodeFromCommaSeparatedCodes, newValue: searchProductCodeFromCommaSeparatedCodes},
        // Enables creating inventory transfers under Products Out
        {confParameter: "wmsEnableCreatingInvTransfers", setFunction: setEnableCreatingInvTransfers, newValue: enableCreatingInvTransfers},
        // The order in which products are sorted on created documents
        {confParameter: "wmsSortProductsOnCreatedDocumentBy", setFunction: setSortProductsOnCreatedDocumentBy, newValue: sortProductsOnCreatedDocumentBy},
        // Put products scanned as packages on created document as packages instead of regular products (pcs)
        {confParameter: "wmsPutProductsScannedAsPackagesOnCreatedDocumentAsPackages", setFunction: setPutProductsScannedAsPackagesOnCreatedDocumentAsPackages, newValue: putProductsScannedAsPackagesOnCreatedDocumentAsPackages},
        // Prioritise code1 over code2 (EAN) when scanning products
        {confParameter: "wmsPrioritiseCode1", setFunction: setPrioritiseCode1, newValue: prioritiseCode1},
        // Ask user to confirm remaining bin amount if is out scan, bins are enabled and bin amount falls below the set number after scanning a product
        {confParameter: "wmsConfirmBinAmountWithUserIfLessThan", setFunction: setConfirmBinAmountWithUserIfLessThan, newValue: confirmBinAmountWithUserIfLessThan},
        // User groups IDs that are forbidden from manually selecting a product in Scan view
        {confParameter: "wmsCompulsoryScanUserGroups", setFunction: setCompulsoryScanUserGroups, newValue: compulsoryScanUserGroups, isMultipleChoice: true},
        // Set default amount (1 if rapid scan setting is enabled, "" if rapid scan setting is disabled) in Scan view after scanning or manually selecting a product
        // Default amount is still set after a scan is confirmed
        // This functionality is always applied for purchase orders if "wmsScanAsBatch" setting is on and cannot be disabled
        {confParameter: "wmsSetDefaultAmountAfterScan", setFunction: setSetDefaultAmountAfterScan, newValue: setDefaultAmountAfterScan},
        // Product price field used when saving inventory registration rows in "Stocktakings" module
        {confParameter: "wmsInventoryRegistrationRowPrice", setFunction: setInventoryRegistrationRowPrice, newValue: inventoryRegistrationRowPrice},
        // Enables changing bin in Scan view under Products In. Otherwise all products are scanned to "receiving_area" bin
        {confParameter: "wmsEnableSettingInScanBin", setFunction: setEnableSettingInScanBin, newValue: enableSettingInScanBin},
        // On which conditions is an insufficient payment warning displayed when searching for a sales order by number
        {confParameter: "wmsSearchSalesOrderByNumberPaymentWarning", setFunction: setSearchSalesOrderByNumberPaymentWarning, newValue: searchSalesOrderByNumberPaymentWarning},
        // Purchase order field which value is used as "no" value when creating a follow-up purchase document
        {confParameter: "wmsCreatedPurchaseDocumentNoFieldValue", setFunction: setCreatedPurchaseDocumentNoFieldValue, newValue: createdPurchaseDocumentNoFieldValue},

        // Printer service address
        {confParameter: "wmsPrinterServiceAddress", setFunction: setPrinterServiceAddress, newValue: printerServiceAddress, isCafaParameter: isArvutitark(clientCode)},
        // Label printer name
        {confParameter: "wmsLabelPrinterName", setFunction: setLabelPrinterName, newValue: labelPrinterName, isCafaParameter: isArvutitark(clientCode)},
        // Label Actual Reports template ID
        {confParameter: "wmsLabelTemplateID", setFunction: setLabelTemplateID, newValue: labelTemplateID},
        // Dpd package label printer name
        {confParameter: "wmsDpdPrinterName", setFunction: setDpdPrinterName, newValue: dpdPrinterName, isCafaParameter: isArvutitark(clientCode)},
        // If checked prints a packing slip after confirming sales order
        {confParameter: "wmsPrintPackingSlip", setFunction: setPrintPackingSlip, newValue: printPackingSlip},
        // Packing slip printer name
        {confParameter: "wmsPackingSlipPrinterName", setFunction: setPackingSlipPrinterName, newValue: packingSlipPrinterName, isCafaParameter: isArvutitark(clientCode)},
        // Packing slip Actual Reports template ID
        {confParameter: "wmsPackingSlipTemplateID", setFunction: setPackingSlipTemplateID, newValue: packingSlipTemplateID},
        // If checked prints the created sales document after confirming sales order
        {confParameter: "wmsPrintSalesDocument", setFunction: setPrintSalesDocument, newValue: printSalesDocument},
        // Sales document printer name
        {confParameter: "wmsSalesDocumentPrinterName", setFunction: setSalesDocumentPrinterName, newValue: salesDocumentPrinterName, isCafaParameter: isArvutitark(clientCode)},
        // Sales document Actual Reports template ID
        {confParameter: "wmsSalesDocumentTemplateID", setFunction: setSalesDocumentTemplateID, newValue: salesDocumentTemplateID},
        // If checked prints an inventory transfer report after confirming outgoing inventory transfer
        {confParameter: "wmsPrintInventoryTransferReport", setFunction: setPrintInventoryTransferReport, newValue: printInventoryTransferReport},
        // Inventory transfer report printer name
        {confParameter: "wmsInventoryTransferReportPrinterName", setFunction: setInventoryTransferReportPrinterName, newValue: inventoryTransferReportPrinterName, isCafaParameter: isArvutitark(clientCode)},
        // Inventory transfer report Actual Reports template ID
        {confParameter: "wmsInventoryTransferReportTemplateID", setFunction: setInventoryTransferReportTemplateID, newValue: inventoryTransferReportTemplateID},
        // If checked prints a label containing the SSCC attribute after confirming outgoing sales document
        {confParameter: "wmsPrintSsccLabel", setFunction: setPrintSsccLabel, newValue: printSsccLabel},
        // SSCC label printer name
        {confParameter: "wmsSsccLabelPrinterName", setFunction: setSsccLabelPrinterName, newValue: ssccLabelPrinterName, isCafaParameter: isArvutitark(clientCode)},
        // SSCC label Actual Reports template ID
        {confParameter: "wmsSsccLabelTemplateID", setFunction: setSsccLabelTemplateID, newValue: ssccLabelTemplateID},

        // Adds "telemaDoc" attribute to salesDocuments if client is using Telema interface
        {confParameter: "wmsIsUsingTelema", setFunction: setIsUsingTelema, newValue: isUsingTelema},
        // Enables user to create a DPD shipment and download or print DPD parcel labels after confirming sales order with DPD delivery type
        {confParameter: "wmsIsUsingDpd", setFunction: setIsUsingDpd, newValue: isUsingDpd},
        // Enables user to create a Venipak shipment and download or print Venipak parcel labels after confirming sales order with Venipak delivery type
        {confParameter: "wmsIsUsingVenipak", setFunction: setIsUsingVenipak, newValue: isUsingVenipak},
        // Enables user to create a Smartpost shipment and download or print Smartpost parcel labels after confirming sales order with Smartpost delivery type
        {confParameter: "wmsIsUsingSmartpost", setFunction: setIsUsingSmartpost, newValue: isUsingSmartpost},
        // Enables user to create an Omniva shipment and download or print Omniva parcel labels after confirming sales order with Omniva delivery type
        {confParameter: "wmsIsUsingOmniva", setFunction: setIsUsingOmniva, newValue: isUsingOmniva},
    ];

    const canShowStocktakingSettings = enabledModules.includes("Stocktakings");

    useEffect(() => {
        getUserGroups();
        getProductPackageTypes();
        getWarehouses();
        setSettings();
    }, []);

    const getUserGroups = () => {
        const params = {
            request: "getUserGroups"
        };
        dispatch(makeErplyRequest(params, t("getUserGroupsError"), null, getUserGroupsSuccess, null));
    };

    const getProductPackageTypes = () => {
        const params = {
            request: "getProductPackageTypes"
        };
        dispatch(makeErplyRequest(params, t("getProductPackageTypesError"), null, getProductPackageTypesSuccess, null, true));
    };

    const getWarehouses = () => {
        const params = {
            request: "getWarehouses"
        };
        dispatch(makeErplyRequest(params, t("getWarehousesError"), null, getWarehousesSuccess, null, true));
    };

    // Set WMS settings from confParameters as selected settings
    const setSettings = () => {
        for (let i = 0, n = confParamSettingsMapping.length; i < n; i++) {
            const confParameter = confParamSettingsMapping[i].confParameter;
            const setFunction = confParamSettingsMapping[i].setFunction;
            let confSettingValue;

            if (confParamSettingsMapping[i].isCafaParameter) {
                confSettingValue = getCafaConfParameterValue(cafaConfParameters, confParameter, user);
            } else if (confParameters.hasOwnProperty(confParameter)) {  // Regular conf parameters
                confSettingValue = confParameters[confParameter];

                // Convert comma-separated-string to array if setting can have multiple values
                if (confParamSettingsMapping[i].isMultipleChoice) {
                    confSettingValue = confSettingValue.split(",");

                    if (confParameter === "wmsAdminUserGroup" || confParameter === "wmsCompulsoryScanUserGroups") {
                        confSettingValue = confSettingValue.map(Number);
                    }
                }
            }

            if (confSettingValue !== undefined) {
                setFunction(confSettingValue);
            }
        }
    };

    const handleGoBackOnClick = () => {
        dispatch(exitSettingsView());
    };

    const validateFields = () => {
        if (excludedEans.includes(" ")) {
            return dispatch(errorMessageSet(t("noSpacesAllowed")));
        } else if (maxAmountAllowedToScan !== "" && isNotAPositiveInteger(maxAmountAllowedToScan)) {
            return dispatch(errorMessageSet(`${t("maxAmountAllowedToScan")} ${t("mustBeAPositiveInteger")}`));
        } else if (confirmBinAmountWithUserIfLessThan !== "" && isNotAPositiveInteger(confirmBinAmountWithUserIfLessThan)) {
            return dispatch(errorMessageSet(`"${t("confirmBinAmountWithUserIfLessThan")}" ${t("mustBeAPositiveInteger")} ${t("or")} ${t("empty")}`));
        } else if (inTransitWarehouseID !== "" && enableRepeatedTransferOrderScan === 1) {
            return dispatch(errorMessageSet(t("inTransitWarehouseAndRepeatedTransferOrderScanForbiddenTogether")));
        }

        saveConfParameters();
    };

    const saveConfParameters = () => {
        let requests = [];
        let cafaRequests = [];
        let counter = 1;

        for (let i = 0, n = confParamSettingsMapping.length; i < n; i++) {
            const confParameter = confParamSettingsMapping[i].confParameter;
            let newValue = confParamSettingsMapping[i].newValue;

            if (confParamSettingsMapping[i].isMultipleChoice) {
                newValue = newValue.join(",");
            }

            if (confParamSettingsMapping[i].isCafaParameter) {
                const cafaConfParameterValue = getCafaConfParameterValue(cafaConfParameters, confParameter, user);

                if (cafaConfParameterValue && cafaConfParameterValue != newValue) {
                    const params = JSON.stringify({
                        application: "WMS",
                        level: "User",
                        level_id: user.userID,
                        name: confParameter,
                        value: String(newValue)
                    });
                    cafaRequests.push(dispatch(makeCafaApiRequest(t, "v3/configuration", null, "PUT", params)));
                }
            } else {    // Regular confParameters
                if (confParameters[confParameter] != newValue) {
                    const request = {
                        requestName: "saveConfParameter",
                        parameterName: confParameter,
                        parameterValue: newValue,
                        requestID: counter
                    };

                    requests.push(request);
                    counter ++;
                }
            }
        }

        if (requests.length > 0 || cafaRequests.length > 0) {
            let cafaRequestsSuccesful = cafaRequests.length === 0;
            let erplyApiRequestsSuccesful = requests.length === 0;

            if (requests.length > 0) {
                dispatch(makeErplyBulkRequest(requests, t("saveConfParameterError"))).then((response) => {
                    if (response.status !== "error") {
                        dispatch(setConfParameters(requests));
                        erplyApiRequestsSuccesful = true;

                        if (cafaRequestsSuccesful) {
                            dispatch(successMessageSet(t("settingsSaved")));
                        }
                    }
                });
            }
            if (cafaRequests.length > 0) {
                Promise.all(cafaRequests).then(([...responses]) => {
                    let requestsSuccessful = true;
                    responses.forEach(response => {
                        if (response.statusCode !== "201") {
                            requestsSuccessful = false;
                        }
                    });

                    if (requestsSuccessful) {
                        cafaRequestsSuccesful = true;
                        if (erplyApiRequestsSuccesful) {
                            dispatch(successMessageSet(t("settingsSaved")));
                        }
                    }

                    dispatch(makeCafaApiRequest(t, "configuration/WMS", setCafaConfParameters, "GET"));
                });
            }
        } else {
            dispatch(errorMessageSet(t("noChanges")));
        }
    };

    const getUserGroupOptions = () => {
        return userGroups.map(userGroup => getUserGroupOption(userGroup));
    };

    const getUserGroupOption = (userGroup) => {
        return {key: userGroup.userGroupID, text: userGroup.name, value: userGroup.userGroupID};
    };

    const getEnabledModulesOptions = () => {
        return modules.map(module => getEnabledModulesOption(module));
    };

    const getEnabledModulesOption = (module) => {
        return {key: module, text: t(module), value: module};
    };

    const sortProductsOnCreatedDocumentByOptions = [
        {key: "scanOrder", text: t("scanOrder"), value: "scanOrder"},
        {key: "code", text: t("byCode"), value: "code"},
        {key: "orderOnSourceDocument", text: t("orderOnBaseDocument"), value: "orderOnBaseDocument"}
    ];

    const searchSalesOrderByNumberPaymentWarningOptions = [
        {key: "noWarningIfNoPayments", text: t("noWarningIfNoPayments"), value: "noWarningIfNoPayments"},
        {key: "warnIfNoPayments", text: t("warnIfNoPayments"), value: "warnIfNoPayments"},
        {key: "warnIfNotPaid100%", text: t("warnIfNotPaid100%"), value: "warnIfNotPaid100%"}
    ];

    const getPackageTypesOptions = () => {
        return productPackageTypes.map(productPackageType => getPackageTypesOption(productPackageType));
    };

    const getPackageTypesOption = (productPackageType) => {
        return {key: productPackageType.packageTypeID, text: productPackageType.name, value: productPackageType.packageTypeID};
    };

    const getInTransitWarehouseOptions = () => {
        let options = warehouses.map(warehouse => getInTransitWarehouseOption(warehouse));
        options.unshift({key: 0, text: "", value: "", className: "dropdownOption"}); // Empty option
        return options;
    };

    const getInTransitWarehouseOption = (warehouse) => {
        return {key: warehouse.warehouseID, text: warehouse.name, value: warehouse.warehouseID};
    };

    const createdPurchaseDocumentOptions = [
        {key: "purchaseWaybill", text: t("purchaseWaybill"), value: "PRCWAYBILL"},
        {key: "purchaseInvoiceWaybill", text: t("purchaseInvoiceWaybill"), value: "PRCINVOICE"}
    ];

    const createdSalesDocumentOptions = [
        {key: "waybill", text: t("WAYBILL"), value: "WAYBILL"},
        {key: "invoiceWaybill", text: t("INVWAYBILL"), value: "INVWAYBILL"},
        {key: "fromClientCard", text: t("fromClientCard"), value: "fromClientCard"}, // If customer has shipGoodsWithWaybills set to 1, then created document is a waybill, else an invoice-waybill
    ];

    const emailReceiverOptions = [
        {key: "creator", text: t("creator"), value: "creator"},
        {key: "customerManager", text: t("customerManager"), value: "customerManager"},
        {key: "customEmail", text: t("customEmail"), value: "customEmail"}
    ];

    const createRowPriceOptions = [
        {key: "FIFOCost", text: t("FIFOCost"), value: "FIFOCost"},
        {key: "purchasePrice", text: t("averagePurchasePrice"), value: "purchasePrice"},
        {key: "cost", text: t("informativeCost"), value: "cost"}
    ];

    const createdPurchaseDocumentNoFieldValueOptions = [
        {key: "regnumber", text: t("regNumber"), value: "regnumber"},
        {key: "number", text: t("orderTrackingNumber"), value: "number"}
    ];

    const settingsBtns = (isBottom = false) => {
        if (!(isBottom && !showGeneralSettings && !showProductsInSettings && !showProductsOutSettings)) {
            return (
                <div className={"settingsBtns flexCenter"}>
                    <Button className={"menuBtnHalfWidth"} size={"large"} onClick={handleGoBackOnClick}>
                        <Icon name={"chevron circle left"}/>
                        {t("goBack")}
                    </Button>
                    <Button className={"menuBtnHalfWidth"} size={"large"} color={"green"} onClick={validateFields}>
                        {t("save")}
                    </Button>
                </div>
            )
        }
    };

    const handleAdminUserGroupChange = (event, { value }) => {
        setAdminUserGroup(value);
    };

    const handleEnabledModulesChange = (event, { value }) => {
        setEnabledModules(value);
    };

    const handleSortProductsOnCreatedDocumentByChange = (event, { value }) => {
        setSortProductsOnCreatedDocumentBy(value);
    };

    const handleExcludedPackageTypesChange = (event, { value }) => {
        setExcludedPackageTypesFromPackageCalculator(value);
    };

    const handleInTransitWarehouseIdChange = (event, { value }) => {
        setInTransitWarehouseID(value);
    };

    const handleProductsInDocumentChange = (event, { value }) => {
        setCreatedPurchaseDocument(value);
    };

    const handleProductsOutDocumentChange = (event, { value }) => {
        setCreatedSalesDocument(value);
    };

    const handleEmailReceiverChange = (event, { value }) => {
        setEmailReceiver(value);
    };

    const handleCreatePurchaseInvoiceRowPriceChange = (event, { value }) => {
        setCreatePurchaseInvoiceRowPrice(value);
    };

    const handleCompulsoryScanUserGroupsChange = (event, { value }) => {
        setCompulsoryScanUserGroups(value);
    };

    const handleInventoryRegistrationRowPriceChange = (event, { value }) => {
        setInventoryRegistrationRowPrice(value);
    };

    const handleSearchSalesOrderByNumberPaymentWarningChange = (event, { value }) => {
        setSearchSalesOrderByNumberPaymentWarning(value);
    };

    const handleCreatedPurchaseDocumentNoFieldValueChange = (event, { value }) => {
        setCreatedPurchaseDocumentNoFieldValue(value);
    };

    const onBinManagementClick = () => {
        dispatch(componentSet("BinManagement"));
    };

    const getOppositeBooleanNumericValue = (oldValue) => {
        return oldValue == 1 ? 0 : 1;
    };

    const interfacesMenu = () => {
        return (
            <Transition visible={openInterfacesMenu} animation='slide down' duration={200}>
                <div>
                    {createCheckBoxSetting(isUsingTelema, setIsUsingTelema, "Telema")}
                    {createCheckBoxSetting(isUsingDpd, setIsUsingDpd, "DPD")}
                    {createCheckBoxSetting(isUsingVenipak, setIsUsingVenipak, "Venipak")}
                    {createCheckBoxSetting(isUsingSmartpost, setIsUsingSmartpost, "Smartpost")}
                    {createCheckBoxSetting(isUsingOmniva, setIsUsingOmniva, "Omniva")}
                </div>
            </Transition>
        )
    };

    const getBinsEnabledOnlySettings = () => {
        if (useProductLocationsInWarehouse == 1) {
            return (
                <div className={"marginBottom"}>
                    {createCheckBoxSetting(selectSuggestedBin, setSelectSuggestedBin, "selectSuggestedBin")}
                    {createCheckBoxSetting(createInvRegistrationOnBinAmountChange, setCreateInvRegistrationOnBinAmountChange, "createInvRegistrationOnBinAmountChange")}
                    {createCheckBoxSetting(enableBinOrder, setEnableBinOrder, "enableBinOrder")}
                    {createInputSetting("confirmBinAmountWithUserIfLessThan", confirmBinAmountWithUserIfLessThan, setConfirmBinAmountWithUserIfLessThan, "number")}
                    <Button primary onClick={onBinManagementClick}>{t("BinManagement")}</Button>
                </div>
            )
        }
    };

    const getTotalSalesAmountAsScanAmountOnlySettings = () => {
        if (totalSalesAmountAsScanAmount == 1) {
            return createRadioSetting(t("listClickOnly"), t("bothListClickAndScan"), totalSalesAmountAsScanAmountListClickOnly, setTotalSalesAmountAsScanAmountListClickOnly);
        }
    };

    const getTotalPurchaseAmountAsScanAmountOnlySettings = () => {
        if (totalPurchaseAmountAsScanAmount == 1) {
            return createRadioSetting(t("listClickOnly"), t("bothListClickAndScan"), totalPurchaseAmountAsScanAmountListClickOnly, setTotalPurchaseAmountAsScanAmountListClickOnly);
        }
    };

    const getTotalInventoryTransferAmountAsScanAmountOnlySettings = () => {
        if (totalInventoryTransferAmountAsScanAmount == 1) {
            return createRadioSetting(t("listClickOnly"), t("bothListClickAndScan"), totalInventoryTransferAmountAsScanAmountListClickOnly, setTotalInventoryTransferAmountAsScanAmountListClickOnly);
        }
    };

    const getCreateSalesDocAfterScanOnlySettings = () => {
        if (createSalesDocAfterScan == 1) {
            return createDropdownSetting("productsOutDocument", createdSalesDocumentOptions, createdSalesDocument, handleProductsOutDocumentChange);
        }
    };

    const getSendComparisonEmailEnabledOnlySettings = () => {
        if (sendComparisonEmail == 1) {
            let settings = [createDropdownSetting("emailReceiver", emailReceiverOptions, emailReceiver, handleEmailReceiverChange)];
            if (emailReceiver === "customEmail") {
                settings.push(createInputSetting("eMailAddress", emailReceiverCustomEmail, setEmailReceiverCustomEmail))
            }
            return settings;
        }
    };

    const getEnableCreatingPurchaseInvoicesOnlySettings = () => {
        if (enableCreatingPurchaseInvoices == 1) {
            return [
                createCheckBoxSetting(enableConfirmingDirectlyCreatedPurchaseInvoices, setEnableConfirmingDirectlyCreatedPurchaseInvoices, "enableConfirmingDirectlyCreatedPurchaseInvoices"),
                createDropdownSetting("createPurchaseInvoiceRowPrice", createRowPriceOptions, createPurchaseInvoiceRowPrice, handleCreatePurchaseInvoiceRowPriceChange),
                createCheckBoxSetting(enableSettingCreatePurchaseInvoiceRowPrice, setEnableSettingCreatePurchaseInvoiceRowPrice, "enableSettingCreatePurchaseInvoiceRowPrice")
            ];
        }
    };

    const getShowPackageCalculationsOnlySettings = () => {
        if (showPackageCalculations == 1) {
            return createDropdownSetting("excludedPackageTypesFromPackageCalculator", getPackageTypesOptions(), excludedPackageTypesFromPackageCalculator, handleExcludedPackageTypesChange, true);
        }
    };

    const printersMenu = () => {
        const ssccSettings = isPPEst
            ? [createCheckBoxSetting(printSsccLabel, setPrintSsccLabel, "printSsccLabel"),
        <Transition visible={printSsccLabel == 1} animation='slide down' duration={200}>
            <div>
                {createInputSetting("ssccLabelPrinterName", ssccLabelPrinterName, setSsccLabelPrinterName)}
                {createInputSetting("ssccLabelTemplateID", ssccLabelTemplateID, setSsccLabelTemplateID)}
            </div>
        </Transition>]
            : null;

        return (
            <Transition visible={openPrintersMenu} animation='slide down' duration={200}>
                <div>
                    {createInputSetting("printerServiceAddress", printerServiceAddress, setPrinterServiceAddress)}
                    {createInputSetting("labelPrinterName", labelPrinterName, setLabelPrinterName)}
                    {createInputSetting("labelTemplateID", labelTemplateID, setLabelTemplateID)}
                    {createInputSetting("dpdPrinterName", dpdPrinterName, setDpdPrinterName)}
                    {createCheckBoxSetting(printPackingSlip, setPrintPackingSlip, "printPackingSlip")}
                    <Transition visible={printPackingSlip == 1} animation='slide down' duration={200}>
                        <div>
                            {createInputSetting("packingSlipPrinterName", packingSlipPrinterName, setPackingSlipPrinterName)}
                            {createInputSetting("packingSlipTemplateID", packingSlipTemplateID, setPackingSlipTemplateID)}
                        </div>
                    </Transition>
                    {createCheckBoxSetting(printSalesDocument, setPrintSalesDocument, "printSalesDocument")}
                    <Transition visible={printSalesDocument == 1} animation='slide down' duration={200}>
                        <div>
                            {createInputSetting("salesDocumentPrinterName", salesDocumentPrinterName, setSalesDocumentPrinterName)}
                            {createInputSetting("salesDocumentTemplateID", salesDocumentTemplateID, setSalesDocumentTemplateID)}
                        </div>
                    </Transition>
                    {createCheckBoxSetting(printInventoryTransferReport, setPrintInventoryTransferReport, "printInventoryTransferReport")}
                    <Transition visible={printInventoryTransferReport == 1} animation='slide down' duration={200}>
                        <div>
                            {createInputSetting("inventoryTransferReportPrinterName", inventoryTransferReportPrinterName, setInventoryTransferReportPrinterName)}
                            {createInputSetting("inventoryTransferReportTemplateID", inventoryTransferReportTemplateID, setInventoryTransferReportTemplateID)}
                        </div>
                    </Transition>
                    {ssccSettings}
                </div>
            </Transition>
        )
    };

    const createCheckBoxSetting = (settingValue, setSettingFunction, text) => {
        return (
            [<Checkbox key={text} checked={settingValue == 1} onChange={() => setSettingFunction(getOppositeBooleanNumericValue(settingValue))}
                      label={<label className={"settingsCheckboxLabel"}>{translateAccordingToCountry(t(text), confParameters)}</label>}/>,<br/>]
        )
    };

    const createDropdownSetting = (heading, options, value, onChange, canSelectMultiple = false) => {
        return (
            [<div key={heading}>
                <p className={"fontSizeMedSmall"}>{t(heading)}</p>
            </div>,
            <Dropdown
                key={heading + "Dropdown"}
                className={"marginBottom"}
                fluid
                selection
                options={options}
                multiple={canSelectMultiple}
                value={value}
                onChange={onChange}
            />]
        )
    };

    const createInputSetting = (heading, value, setStateFunction, type = "text") => {
        return (
            [<div key={heading}>
                <p className={"fontSizeMedSmall"}>{translateAccordingToCountry(t(heading), confParameters)}</p>
            </div>,
            <Input
                key={heading + "Input"}
                className={"marginBottom"}
                value={value}
                onChange={(e) => setStateFunction(e.target.value)}
                fluid
                type={type}
            />]
        )
    };

    const createRadioSetting = (option1Text, option2Text, stateValue, setStateFunction) => {
        const handleChange = (e, { value }) => setStateFunction(value);

        return (
            <Form className={"marginLeft marginBottom"}>
                <Form.Field>
                    <Radio
                        label={<label className={"white"}>{option1Text}</label>}
                        value="1"
                        checked={stateValue == 1}
                        onChange={handleChange}
                    />
                </Form.Field>
                <Form.Field>
                    <Radio
                        label={<label className={"white"}>{option2Text}</label>}
                        value="0"
                        checked={stateValue == 0}
                        onChange={handleChange}
                    />
                </Form.Field>
            </Form>
        )
    };

    const createGeneralSettings = () => {
        if (showGeneralSettings) {
            return (
                <div>
                    {createDropdownSetting("adminUserGroup", getUserGroupOptions(), adminUserGroup, handleAdminUserGroupChange, true)}
                    {createDropdownSetting("enabledModules", getEnabledModulesOptions(), enabledModules, handleEnabledModulesChange, true)}
                    {createCheckBoxSetting(useProductLocationsInWarehouse, setUseProductLocationsInWarehouse, "useProductLocationsInWarehouse")}
                    {getBinsEnabledOnlySettings()}
                    {createCheckBoxSetting(firstCheckPackageCodes, setFirstCheckPackageCodes, "firstCheckPackageCodes")}
                    {createCheckBoxSetting(setDefaultAmountAfterScan, setSetDefaultAmountAfterScan, "setDefaultAmountAfterScan")}
                    {createCheckBoxSetting(showPackageCalculations, setShowPackageCalculations, "showPackageCalculations")}
                    {getShowPackageCalculationsOnlySettings()}
                    {createCheckBoxSetting(enableAddingCode2, setEnableAddingCode2, "enableAddingCode2")}
                    {createCheckBoxSetting(sendComparisonEmail, setSendComparisonEmail, "sendComparisonEmail")}
                    {getSendComparisonEmailEnabledOnlySettings()}
                    {createCheckBoxSetting(searchProductCodeFromCommaSeparatedCodes, setSearchProductCodeFromCommaSeparatedCodes, "searchProductCodeFromCommaSeparatedCodes")}
                    {createCheckBoxSetting(prioritiseCode1, setPrioritiseCode1, "prioritiseCode1")}
                    {createInputSetting("excludedEans", excludedEans, setExcludedEans)}
                    {createInputSetting("maxAmountAllowedToScan", maxAmountAllowedToScan, setMaxAmountAllowedToScan, "number")}
                    {createDropdownSetting("sortProductsOnCreatedDocumentBy", sortProductsOnCreatedDocumentByOptions, sortProductsOnCreatedDocumentBy, handleSortProductsOnCreatedDocumentByChange)}
                    {createDropdownSetting("compulsoryScanUserGroups", getUserGroupOptions(), compulsoryScanUserGroups, handleCompulsoryScanUserGroupsChange, true)}
                </div>
            )
        }
    };

    const createProductsInSettings = () => {
        if (showProductsInSettings) {
            return (
                <div>
                    {createCheckBoxSetting(rapidInScan, setRapidInScan, "rapidInScan")}
                    {createDropdownSetting("productsInDocument", createdPurchaseDocumentOptions, createdPurchasedDocument, handleProductsInDocumentChange)}
                    {createDropdownSetting("createdPurchaseDocumentNoFieldValue", createdPurchaseDocumentNoFieldValueOptions, createdPurchaseDocumentNoFieldValue, handleCreatedPurchaseDocumentNoFieldValueChange)}
                    {createCheckBoxSetting(enableCreatingPurchaseInvoices, setEnableCreatingPurchaseInvoices, "enableCreatingPurchaseInvoices")}
                    {getEnableCreatingPurchaseInvoicesOnlySettings()}
                    {createCheckBoxSetting(enableProductsReturn, setEnableProductsReturn, "enableProductsReturn")}
                    {createCheckBoxSetting(enableCreatingReturnWaybills, setEnableCreatingReturnWaybills, "enableCreatingReturnWaybills")}
                    {createCheckBoxSetting(confirmPurchaseDocuments, setConfirmPurchaseDocuments, "confirmPurchaseDocuments")}
                    {createCheckBoxSetting(totalPurchaseAmountAsScanAmount, setTotalPurchaseAmountAsScanAmount, "setTotalPurchaseAmountAsScanAmount")}
                    {getTotalPurchaseAmountAsScanAmountOnlySettings()}
                    {createCheckBoxSetting(allowInScanAmountExceeding, setAllowInScanAmountExceeding, "allowInScanAmountExceeding")}
                    {createCheckBoxSetting(enableCode2Change, setEnableCode2Change, "enableCode2Change")}
                    {createCheckBoxSetting(enableSettingPurchaseDocNoAfterScan, setEnableSettingPurchaseDocNoAfterScan, "enableSettingPurchaseDocNoAfterScan")}
                    {createCheckBoxSetting(setPurchaseOrderAlwaysFulfilled, setSetPurchaseOrderAlwaysFulfilled, "setPurchaseOrderAlwaysFulfilled")}
                    {isOkaidi(clientCode) || isTesterClientCode(clientCode) ? createCheckBoxSetting(enableScanningBatches, setEnableScanningBatches, "enableScanningBatches") : null}
                    {createCheckBoxSetting(enableCreatingProducts, setEnableCreatingProducts, "enableCreatingProducts")}
                    {createCheckBoxSetting(scanAsBatch, setScanAsBatch, "scanAsBatch")}
                    {createCheckBoxSetting(enableScanningProductsNotOnInDocument, setEnableScanningProductsNotOnInDocument, "enableScanningProductsNotOnInDocument")}
                    {!isOkaidi(clientCode) ? createCheckBoxSetting(enableMultiplePurchaseOrderScan, setEnableMultiplePurchaseOrderScan, "enableMultiplePurchaseOrderScan") : null}
                    {createCheckBoxSetting(putProductsScannedAsPackagesOnCreatedDocumentAsPackages, setPutProductsScannedAsPackagesOnCreatedDocumentAsPackages, "putProductsScannedAsPackagesOnCreatedDocumentAsPackages")}
                    {createCheckBoxSetting(enableSettingInScanBin, setEnableSettingInScanBin, "enableSettingInScanBin")}
                </div>
            )
        }
    };

    const createProductsOutSettings = () => {
        if (showProductsOutSettings) {
            return (
                <div>
                    {createCheckBoxSetting(rapidOutScan, setRapidOutScan, "rapidOutScan")}
                    {createCheckBoxSetting(createSalesDocAfterScan, setCreateSalesDocAfterScan, "createSalesDocAfterScan")}
                    {getCreateSalesDocAfterScanOnlySettings()}
                    {createCheckBoxSetting(enableCreatingWaybills, setEnableCreatingWaybills, "enableCreatingWaybills")}
                    {createCheckBoxSetting(enableCreatingInvTransferOrders, setEnableCreatingInvTransferOrders, "enableCreatingInvTransferOrders")}
                    {createCheckBoxSetting(enableCreatingInvTransfers, setEnableCreatingInvTransfers, "enableCreatingInvTransfers")}
                    {createCheckBoxSetting(enableCreatingInvWriteOffs, setEnableCreatingInvWriteOffs, "enableCreatingInvWriteOffs")}
                    {createCheckBoxSetting(enableCreatingPurchaseReturns, setEnableCreatingPurchaseReturns, "enableCreatingPurchaseReturns")}
                    {createCheckBoxSetting(confirmSalesDocuments, setConfirmSalesDocuments, "confirmSalesDocuments")}
                    {createCheckBoxSetting(confirmWriteOffs, setConfirmWriteOffs, "confirmWriteOffs")}
                    {createCheckBoxSetting(totalSalesAmountAsScanAmount, setTotalSalesAmountAsScanAmount, "setTotalSalesAmountAsScanAmount")}
                    {getTotalSalesAmountAsScanAmountOnlySettings()}
                    {createCheckBoxSetting(allowOutScanAmountExceeding, setAllowOutScanAmountExceeding, "allowOutScanAmountExceeding")}
                    {createCheckBoxSetting(enableNegativeScanProductsOut, setEnableNegativeScanProductsOut, "enableNegativeScanProductsOut")}
                    {createCheckBoxSetting(enableScanningProductsNotOnOutDocument, setEnableScanningProductsNotOnOutDocument, "enableScanningProductsNotOnOutDocument")}
                    {createCheckBoxSetting(enableAssembly, setEnableAssembly, "enableAssembly")}
                    {createCheckBoxSetting(enablePurchaseReturn, setEnablePurchaseReturn, "enablePurchaseReturn")}
                    {createDropdownSetting("whenSearchingForSalesOrderByNumber", searchSalesOrderByNumberPaymentWarningOptions, searchSalesOrderByNumberPaymentWarning, handleSearchSalesOrderByNumberPaymentWarningChange)}
                </div>
            )
        }
    };

    const createInventoryTransferOrderSettings = () => {
        if (showInventoryTransferOrderSettings) {
            return (
                <div>
                    {createCheckBoxSetting(confirmInventoryTransfers, setConfirmInventoryTransfers, "confirmInventoryTransfers")}
                    {createCheckBoxSetting(totalInventoryTransferAmountAsScanAmount, setTotalInventoryTransferAmountAsScanAmount, "setTotalInventoryTransferAmountAsScanAmount")}
                    {getTotalInventoryTransferAmountAsScanAmountOnlySettings()}
                    {createCheckBoxSetting(enableRepeatedTransferOrderScan, setEnableRepeatedTransferOrderScan, "enableRepeatedTransferOrderScan")}
                    {createCheckBoxSetting(invTransferScanReorderMultiple, setInvTransferScanReorderMultiple, "invTransferScanReorderMultiple")}
                    {createDropdownSetting("inTransitWarehouse", getInTransitWarehouseOptions(), inTransitWarehouseID, handleInTransitWarehouseIdChange)}
                </div>
            )
        }
    };

    const createStocktakingSettings = () => {
        if (canShowStocktakingSettings && showStocktakingSettings) {
            return (
                <div>
                    {createDropdownSetting("inventoryRegistrationRowPrice", createRowPriceOptions, inventoryRegistrationRowPrice, handleInventoryRegistrationRowPriceChange)}
                </div>
            )
        }
    };

    return (
        <div id={"settings"}>
            {settingsBtns()}
            <h3 onClick={() => setShowGeneralSettings(!showGeneralSettings)}>{t("general")}</h3>
            {createGeneralSettings()}
            <h3 onClick={() => setShowProductsInSettings(!showProductsInSettings)}>{t("ProductsIn")}</h3>
            {createProductsInSettings()}
            <h3 onClick={() => setShowProductsOutSettings(!showProductsOutSettings)}>{t("ProductsOut")}</h3>
            {createProductsOutSettings()}
            <h3 onClick={() => setShowInventoryTransferOrderSettings(!showInventoryTransferOrderSettings)}>{capitalise(t("inventoryTransferOrder"))}</h3>
            {createInventoryTransferOrderSettings()}
            {canShowStocktakingSettings ? <h3 onClick={() => setShowStocktakingSettings(!showStocktakingSettings)}>{t("Stocktakings")}</h3> : null}
            {createStocktakingSettings()}
            <div className={"marginBottom"}>
                <Button primary onClick={() => {setOpenInterfacesMenu(!openInterfacesMenu); setOpenPrintersMenu(false)}}>{t("interfaces")}</Button>
                <Button primary onClick={() => {setOpenPrintersMenu(!openPrintersMenu); setOpenInterfacesMenu(false)}}>{t("printers")}</Button>
            </div>
            {interfacesMenu()}
            {printersMenu()}
            {settingsBtns(true)}
        </div>
    )
};

export default Settings;
