export const setSourceBin = (bin) => {
    return {
        type: "SET_SOURCE_BIN",
        payload: bin
    }
};

export const setScannedProduct = (product) => {
    return {
        type: "SET_SCANNED_PRODUCT",
        payload: product
    }
};

export const setPlacedAmount = (amount) => {
    return {
        type: "SET_PLACED_AMOUNT",
        payload: amount
    }
};

export const setDestinationBin = (bin) => {
    return {
        type: "SET_DESTINATION_BIN",
        payload: bin
    }
};

export const setDestinationBinAmountBefore = (amount) => {
    return {
        type: "SET_DESTINATION_BIN_AMOUNT_BEFORE",
        payload: amount
    }
};

export const setSourceBinQuantities = (binQuantities) => {
    return {
        type: "SET_SOURCE_BIN_QUANTITIES",
        payload: binQuantities
    }
};

export const setPreferredBins = (bins) => {
    return {
        type: "SET_PREFERRED_BINS",
        payload: bins
    }
};

export const setPreferredBinsBinQuantities = (binQuantities) => {
    return {
        type: "SET_PREFERRED_BINS_BIN_QUANTITIES",
        payload: binQuantities
    }
};

export const setBinQuantitiesWithScannedProduct = (binQuantities) => {
    return {
        type: "SET_BIN_QUANTITIES_WITH_SCANNED_PRODUCT",
        payload: binQuantities
    }
};

export const setBinQuantitiesWithScannedProductBins = (bins) => {
    return {
        type: "SET_BIN_QUANTITIES_WITH_SCANNED_PRODUCT_BINS",
        payload: bins
    }
};

export const setIsCheckGoodsInReceivingArea = (isCheckGoodsInReceivingArea) => {
    return {
        type: "SET_IS_CHECK_GOODS_IN_RECEIVING_AREA",
        payload: isCheckGoodsInReceivingArea
    }
};

export const setSalesOrdersWithProducts = (salesOrders) => {
    return {
        type: "SET_SALES_ORDERS_WITH_PRODUCTS",
        payload: salesOrders
    }
};

export const setReceivingAreaReportProducts = (products) => {
    return {
        type: "SET_RECEIVING_AREA_REPORT_PRODUCTS",
        payload: products
    }
};

export const setIsCheckGoodsInBin = (isCheckGoodsInBin) => {
    return {
        type: "SET_IS_CHECK_GOODS_IN_BIN",
        payload: isCheckGoodsInBin
    }
};

export const setScannedProducts = (scannedProducts) => {
    return {
        type: "SET_SCANNED_PRODUCTS",
        payload: scannedProducts
    }
};
