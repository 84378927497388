const initialState = {
    searchResultDocuments: [],
    searchResultFollowUpDocuments: [],
    searchResultFollowUpDocumentsAdditionalOrders: [],  // Orders of search result follow up documents that are not in search result. Needed to subtract amounts to get scannable amounts
    searchResultFollowUpDocumentsJsonAttributes: [],  // Needed for multiple order scan where scanned amounts from each order are saved to JSON attributes
    isGetUndeliveredGoods: false    // Get purchase invoices to calculate undelivered goods, Rehvid Pluss only
};

export const searchDocumentsReducer = (state = initialState, action) => {
    switch(action.type) {
        case "SET_SEARCH_RESULT_DOCUMENTS":
            return {
                ...state,
                searchResultDocuments: action.payload
            };
        case "SET_SEARCH_RESULT_FOLLOW_UP_DOCUMENTS":
            return {
                ...state,
                searchResultFollowUpDocuments: action.payload
            };
        case "SET_SEARCH_RESULT_FOLLOW_UP_DOCUMENTS_ADDITIONAL_ORDERS":
            return {
                ...state,
                searchResultFollowUpDocumentsAdditionalOrders: action.payload
            };
        case "SET_IS_GET_UNDELIVERED_GOODS":
            return {
                ...state,
                isGetUndeliveredGoods: action.payload
            };
        case "SET_SEARCH_RESULT_FOLLOW_UP_DOCUMENTS_JSON_ATTRIBUTES":
            return {
                ...state,
                searchResultFollowUpDocumentsJsonAttributes: action.payload
            };
        default:
            return state;
    }
};
