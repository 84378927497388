const initialState = {
    productPackageTypes: [],
};

export const getProductPackageTypesReducer = (state = initialState, action) => {
    switch(action.type) {
        case "GET_PRODUCT_PACKAGE_TYPES_SUCCESS":
            return {
                ...state,
                productPackageTypes: action.payload
            };
        default:
            return state;
    }
};
