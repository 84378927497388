const initialState = {
    binBeingEdited: null
};

export const binManagementReducer = (state = initialState, action) => {
    switch(action.type) {
        case "SET_BIN_BEING_EDITED":
            return {
                ...state,
                binBeingEdited: action.payload
            };
        default:
            return state;
    }
};
