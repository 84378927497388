const initialState = {
    fulfillableOrders : []
};

export const getSalesDocumentsReducer = (state = initialState, action) => {
    switch(action.type) {
        case "SET_FULFILLABLE_ORDERS":
            return {
                ...state,
                fulfillableOrders: action.payload
            };
        default:
            return state;
    }
};
