import React from 'react';
import {Button, Icon} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {componentSet, previousComponentSet} from "../actions/component";
import {useDispatch, useSelector} from "react-redux";
import {setDocumentType} from "../actions/createDocument";
import {capitalise} from "../util/misc";
import {makeErplyRequest} from "../util/erplyRequests";
import {errorMessageSet} from "../actions/headerMessage";
import {setModal} from "../actions/modal";
import {setIsInTransitTransfer, setSelectedDocuments} from "../actions/scan";

const ProductsOut = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);

    const handleSalesOrderOnClick = () => {
        dispatch(componentSet("SalesOrder"));
    };

    const handleInventoryTransferOnClick = () => {
        dispatch(setIsInTransitTransfer(false));
        dispatch(componentSet("InventoryTransfer"));
    };

    const handleCreateWaybillOnClick = () => {
        dispatch(setDocumentType("waybill"));
        dispatch(componentSet("CreateDocument"));
    };

    const handleCreateInventoryTransferOrderOnClick = () => {
        dispatch(setDocumentType("inventoryTransferOrder"));
        dispatch(componentSet("CreateDocument"));
    };

    const handleCreateInventoryTransferOnClick = () => {
        dispatch(setDocumentType("inventoryTransfer"));
        dispatch(componentSet("CreateDocument"));
    };

    const handleCreateWriteOffOnClick = () => {
        dispatch(setDocumentType("writeOff"));
        dispatch(componentSet("CreateDocument"));
    };

    const handleCreatPurchaseReturnOnClick = () => {
        dispatch(setDocumentType("purchaseReturn"));
        dispatch(componentSet("CreateDocument"));
    };

    const createCreateWaybillButton = () => {
        if (confParameters.wmsEnableCreatingWaybills == 1) {
            return <Button className={"menuBtn"} onClick={handleCreateWaybillOnClick} size='big'>{t("create")} {t("waybill")}</Button>;
        }
    };

    const createCreateInventoryTransferButton = () => {
        if (confParameters.wmsEnableCreatingInvTransfers == 1) {
            return <Button className={"menuBtn"} onClick={handleCreateInventoryTransferOnClick} size='big'>{t("create")} {t("inventoryTransfer")}</Button>;
        }
    };

    const createCreateInventoryTransferOrderButton = () => {
        if (confParameters.wmsEnableCreatingInvTransferOrders == 1) {
            return <Button className={"menuBtn"} onClick={handleCreateInventoryTransferOrderOnClick} size='big'>{t("create")} {t("inventoryTransferOrder")}</Button>;
        }
    };

    const createCreateWriteOffButton = () => {
        if (confParameters.wmsEnableCreatingInvWriteOffs == 1) {
            return <Button className={"menuBtn"} onClick={handleCreateWriteOffOnClick} size='big'>{t("create")} {t("writeOff")}</Button>;
        }
    };

    const createCreatePurchaseReturnButton = () => {
        if (confParameters.wmsEnableCreatingPurchaseReturns == 1) {
            return <Button className={"menuBtn"} onClick={handleCreatPurchaseReturnOnClick} size='big'>{t("create")} {t("purchaseReturn")}</Button>;
        }
    };

    const handleAssembleOnClick = async () => {
        const beingAssembledCause = "Komplekteerimisel";
        const beforeAssemblyCause = "Saadetud komplekteerimisele";

        const setInvRegistrationAsBeingAssembled = (id) => {
            const params = {
                request: "saveInventoryRegistration",
                cause: beingAssembledCause,
                inventoryRegistrationID: id
            };

            dispatch(makeErplyRequest(params, t("saveInventoryRegistrationError")));
        };

        const selectInvRegistrationForScan = async (invRegistration, markInvRegistrationAsBeingAssembled = false) => {
            if (markInvRegistrationAsBeingAssembled) {
                setInvRegistrationAsBeingAssembled(invRegistration.inventoryRegistrationID);
            }

            await dispatch(setSelectedDocuments([invRegistration], t, [], false, true));
            dispatch(previousComponentSet("ProductsOut"));
            dispatch(componentSet("Scan"));
        };

        const getInventoryRegistrations = (cause) => {
            const params = {
                request: "getInventoryRegistrations",
                cause: cause,
                warehouseID: selectedWarehouse.warehouseID
            };

            return dispatch(makeErplyRequest(params, t("getInventoryRegistrationsError"))).then(registrations => {
                return registrations.filter(reg => reg.confirmed == 0); // Return only unconfirmed invRegistrations
            });
        };

        const invRegistrationsSentToAssembly = await getInventoryRegistrations(beforeAssemblyCause);

        if (invRegistrationsSentToAssembly.length > 0) {
            dispatch(setModal(t("confirmation"), t("invRegistrationSentToAssemblyFound"), () => selectInvRegistrationForScan(invRegistrationsSentToAssembly[0], true)));
        } else {
            const invRegistrationsBeingAssembled = await getInventoryRegistrations(beingAssembledCause);

            if (invRegistrationsBeingAssembled.length > 0) {
                dispatch(setModal(t("confirmation"), t("invRegistrationBeingAssembledFound"), () => selectInvRegistrationForScan(invRegistrationsBeingAssembled[0])));
            } else {
                dispatch(errorMessageSet(t("noAssemblyInvRegistrationsFound")));
            }
        }
    };

    const handleAssignmentOnClick = () => {
        dispatch(componentSet("Assignment"));
    };

    const handleGoBackOnClick = () => {
        dispatch(componentSet("MainMenu"));
    };

    const createAssembleButton = () => {
        if (confParameters.wmsEnableAssembly == 1) {
            return <Button className={"menuBtn"} onClick={handleAssembleOnClick} size='big'>{t("assemble")}</Button>;
        }
    };

    const createPurchaseReturnButton = () => {
        if (confParameters.wmsEnablePurchaseReturn == 1) {
            return <Button className={"menuBtn"} onClick={handlePurchaseReturnOnClick} size='big'>{capitalise(t("purchaseReturn"))}</Button>;
        }
    };

    const handlePurchaseReturnOnClick = () => {
        dispatch(componentSet("ReturnProducts"));
    };

    return (
        <div>
            <Button className={"menuBtn"} onClick={handleSalesOrderOnClick} size='big'>{t("SalesOrder")}</Button>
            {createCreateWaybillButton()}
            <Button className={"menuBtn"} onClick={handleInventoryTransferOnClick} size='big'>{capitalise(t("inventoryTransferOrder"))}</Button>
            {createCreateInventoryTransferOrderButton()}
            {createCreateInventoryTransferButton()}
            {createCreateWriteOffButton()}
            {createPurchaseReturnButton()}
            {createCreatePurchaseReturnButton()}
            {createAssembleButton()}
            {/*<Button className={"menuBtn"} onClick={handleAssignmentOnClick} size='big'>{t("Assignment")}</Button>*/}
            <Button className={"goBackBtn menuBtn"} onClick={handleGoBackOnClick} size='big'>
                <Icon name={"chevron circle left"}/>
                {t("goBack")}
            </Button>
        </div>
    );
};

export default ProductsOut
