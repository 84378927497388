import React from 'react';
import {Button, Table} from 'semantic-ui-react'
import GoBackToStartBtn from "./GoBackToStartBtn";
import GoBackBtn from "./GoBackBtn";
import {componentSet, previousComponentSet} from "../actions/component";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setScannedProduct} from "../actions/placeProducts";
import {errorMessageSet} from "../actions/headerMessage";

const ProductInReceivingAreaReport = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const scannedProducts = useSelector(state => state.placeProductsReducer.receivingAreaReportProducts);
    const salesOrdersWithProducts = useSelector(state => state.placeProductsReducer.salesOrdersWithProducts);

    const createReport = () => {
        return scannedProducts.map((product, index) => {
            const salesOrdersWithProduct = salesOrdersWithProducts.filter(order => order.rows.some(row => row.productID == product.productID));
            const tableClasses = index === 0 ? "marginBottomSmall" : "marginBottomSmall marginTop";

            return (
                <div key={product.productID}>
                    <Table key={index} color={"black"} celled structured unstackable className={tableClasses}>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell className={"tableHeading"} width={1}>{t("name")}</Table.Cell>
                                <Table.Cell>{product.name}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"tableHeading"} width={1}>{t("code")}</Table.Cell>
                                <Table.Cell>{product.code}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"tableHeading"} width={1}>{t("amountInReceivingArea")}</Table.Cell>
                                <Table.Cell>{product.amount}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"tableHeading"} colSpan='2'><div className={"textAlignCenter"}>{t("activeOrders")}</div></Table.Cell>
                            </Table.Row>
                            {createRowsByDeliveryType()}
                            <Table.Row>
                                <Table.Cell className={"tableHeading"}><div className={"floatRight"}>{t("total")}:</div></Table.Cell>
                                <Table.Cell width={1}>{salesOrdersWithProduct.length}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    <Button className={"menuBtn"} primary size={"big"} onClick={() => handleMoveProductToBinClick(product, product.amount)}>{t("moveProductToBin")}</Button>
                </div>
            )
        });
    };

    const createRowsByDeliveryType = () => {
        const deliveryTypes = [];
        salesOrdersWithProducts.forEach(salesOrder => {
            if (salesOrder.deliveryTypeName === null) {
                salesOrder.deliveryTypeName = t("withoutDeliveryType");
            }
            const existingDeliveryType = deliveryTypes.find(deliveryType => deliveryType.name === salesOrder.deliveryTypeName);
            if (existingDeliveryType) {
                existingDeliveryType.amount ++;
            } else {
                deliveryTypes.push({name: salesOrder.deliveryTypeName, amount: 1});
            }
        });

        return deliveryTypes.map(deliveryType => {
            return (
                <Table.Row>
                    <Table.Cell className={"tableHeading"} width={1}>{deliveryType.name}</Table.Cell>
                    <Table.Cell>{deliveryType.amount}</Table.Cell>
                </Table.Row>
            )
        })
    };

    const handleGoBackOnClick = () => {
        dispatch(componentSet("RegisterToAddressScanProduct"));
    };

    const handleMoveProductToBinClick = (product, amountInReceivingArea) => {
        if (amountInReceivingArea <= 0) {
            return dispatch(errorMessageSet("Kaupa ei ole vastuvõtualas"));
        }
        dispatch(setScannedProduct(product));
        dispatch(previousComponentSet("ProductInReceivingAreaReport"));
        dispatch(componentSet("RegisterToAddressEnterAddress"));
    };

    return (
        <div>
            <div id={"documentRowsTable"}>
                {createReport()}
            </div>
            <div className={"btnsGroup"}>
                <div className={"flexCenter"}>
                    <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                    <GoBackToStartBtn/>
                </div>
            </div>
        </div>
    );
};

export default ProductInReceivingAreaReport
