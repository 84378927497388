import React, { useEffect } from 'react';
import './App.css';
import Login from "./components/Login";
import Header from "./components/Header";
import {useDispatch, useSelector} from "react-redux";
import ChooseWarehouse from "./components/ChooseWarehouse";
import MainMenu from "./components/MainMenu";
import ProductsIn from "./components/ProductsIn";
import PurchaseOrder from "./components/PurchaseOrder";
import Scan from "./components/Scan";
import SearchOrders from "./components/SearchOrders";
import OrderListConfirmation from "./components/OrderListConfirmation";
import ReturnProducts from "./components/ReturnProducts";
import ReturnsListConfirmation from "./components/ReturnsListConfirmation";
import InventoryTransfer from "./components/InventoryTransfer";
import ProductsOut from "./components/ProductsOut";
import ModalPopup from "./components/ModalPopup";
import SalesOrder from "./components/SalesOrder";
import FulfillableOrdersFilters from "./components/FulfillableOrdersFilters";
import FulfillableOrders from "./components/FulfillableOrders";
import PlaceProducts from "./components/PlaceProducts";
import ScanFinish from "./components/ScanFinish";
import Settings from "./components/Settings";
import RegisterToAddressScanProduct from "./components/RegisterToAddressScanProduct";
import TakeProductsFromAddress from "./components/TakeProductsFromAddress";
import InformativeModal from "./components/InformativeModal";
import {useTranslation} from "react-i18next";
import ChangeScannedProducts from "./components/ChangeScannedProducts";
import RegisterToAddressEnterAddress from "./components/RegisterToAddressEnterAddress";
import RegisterToAddressResult from "./components/RegisterToAddressResult";
import CreateDocument from "./components/CreateDocument";
import Replenishment from "./components/Replenishment";
import ReplenishmentProductSelected from "./components/ReplenishmentProductSelected";
import AmountsInBins from "./components/AmountsInBins";
import Stocktakings from "./components/Stocktakings";
import BinManagement from "./components/BinManagement";
import AddBin from "./components/AddBin";
import ChangeBin from "./components/ChangeBin";
import ImportBins from "./components/ImportBins";
import ImportBinQuantities from "./components/ImportBinQuantities";
import {Dimmer, Loader} from "semantic-ui-react";
import Assignment from "./components/Assignment";
import DpdParcelLabels from "./components/DpdParcelLabels";
import VenipakParcelLabels from "./components/VenipakParcelLabels";
import SearchInventoryTransfers from "./components/SearchInventoryTransfers";
import InventoryTransfersListConfirmation from "./components/InventoryTransfersListConfirmation";
import AssignmentList from "./components/AssignmentList";
import ImportBinOrder from "./components/ImportBinOrder";
import CreateEditProduct from "./components/CreateEditProduct";
import UndeliveredGoods from "./components/UndeliveredGoods";
import ProductInReceivingAreaReport from "./components/ProductInReceivingAreaReport";
import SmartpostParcelLabels from "./components/SmartpostParcelLabels";
import ActiveDocumentReadOnlyView from "./components/ActiveDocumentReadOnlyView";
import {componentSet, setIsLoading, setSequence} from "./actions/component";
import {errorMessageSet, successMessageSet, warningMessageSet} from "./actions/headerMessage";
import {reduxPersistLocalStorageItemName} from "./util/constants";
import {isTesterClientCode, isUuemViis} from "./util/isClient";
import {
    checkSessionRefreshTimestamp,
    checkSessionRefreshTimestampPeriodically,
    isInfralinkSelfService
} from "./util/misc";
import DpdParcelLabelsNew from "./components/DpdParcelLabelsNew";
import CheckGoodsInBinScanFinish from "./components/CheckGoodsInBinScanFinish";
import CheckGoodsInBinChangeScannedProducts from "./components/CheckGoodsInBinChangeScannedProducts";
import OmnivaParcelLabels from "./components/OmnivaParcelLabels";

// This is to stop persisting in case of a white screen error
const removeReduxPersistData = () => {
    setTimeout(() => {
        localStorage.removeItem(reduxPersistLocalStorageItemName);
    }, 500);
};

function App() {
    const component = useSelector(state => state.componentReducer.component);
    const isLoading = useSelector(state => state.componentReducer.isLoading);
    const isLongLoad = useSelector(state => state.componentReducer.isLongLoad);
    const modalMessage = useSelector(state => state.modalReducer.message);
    const modalHeading = useSelector(state => state.modalReducer.heading);
    const language = useSelector(state => state.languageReducer.language);
    const componentSequence = useSelector(state => state.componentReducer.componentSequence);
    const errorMessage = useSelector(state => state.headerMessageReducer.errorMessage);
    const successMessage = useSelector(state => state.headerMessageReducer.successMessage);
    const warningMessage = useSelector(state => state.headerMessageReducer.warningMessage);
    const clientCode = useSelector(state => state.verifyUserReducer.clientCode);

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    // Handle app reload with Redux-persist
    useEffect(() => {
        i18n.changeLanguage(language);  // Apply language in Redux state

        dispatch(checkSessionRefreshTimestamp(t));
        dispatch(checkSessionRefreshTimestampPeriodically(t));

        // Display messages again so that their timeout gets reapplied
        if (warningMessage !== "") dispatch(warningMessageSet(warningMessage));
        if (successMessage !== "") dispatch(successMessageSet(successMessage));
        if (errorMessage !== "") dispatch(errorMessageSet(errorMessage));

        if (isUuemViis(clientCode) || isTesterClientCode(clientCode)) {
            window.setRequestTimeout = true;
        }

        if (isLoading) {
            dispatch(setIsLoading(false));  // Remove loader

            // Set "MainMenu" component to avoid any situation where an API call has finished but the page was reloaded before component change had taken place
            if (componentSequence.includes("MainMenu")) {
                const fallbackComponent = dispatch(isInfralinkSelfService()) ? "ChooseWarehouse" : "MainMenu";
                const fallbackSequenceIndexEnd = dispatch(isInfralinkSelfService()) ? 1 : 2;
                dispatch(componentSet(fallbackComponent));
                dispatch(setSequence(componentSequence.slice(0, fallbackSequenceIndexEnd)));
            }
        }
    }, []);

    const showDimmer = () => {
        const loadingMessage = isLongLoad ? t("longLoadMessage") : t("loading");
        if (isLoading) {
            return <Dimmer active>
                <Loader size='massive'>{loadingMessage}</Loader>
            </Dimmer>
        }
    };

    const renderComponent = () => {
        const renderedComponent = getComponent();
        if (component === "Login" || component === "ChooseWarehouse") {
            return renderedComponent;
        } else {
            return (
                <div className={"menu"}>
                    <div className={"menuContent"}>
                        {renderedComponent}
                    </div>
                </div>
            )
        }
    };

    const getComponent = () => {
        switch(component) {
            case "ChooseWarehouse":
                return <ChooseWarehouse/>;
            case "MainMenu":
                return <MainMenu/>;
            case "ProductsIn":
                return <ProductsIn/>;
            case "PurchaseOrder":
                return <PurchaseOrder/>;
            case "Scan":
                return <Scan/>;
            case "SearchOrders":
                return <SearchOrders/>;
            case "OrderListConfirmation":
                return <OrderListConfirmation/>;
            case "ReturnProducts":
                return <ReturnProducts/>;
            case "ReturnsListConfirmation":
                return <ReturnsListConfirmation/>;
            case "InventoryTransfer":
                return <InventoryTransfer/>;
            case "ProductsOut":
                return <ProductsOut/>;
            case "SalesOrder":
                return <SalesOrder/>;
            case "FulfillableOrdersFilters":
                return <FulfillableOrdersFilters/>;
            case "FulfillableOrders":
                return <FulfillableOrders/>;
            case "PlaceProducts":
                return <PlaceProducts/>;
            case "ScanFinish":
                return <ScanFinish/>;
            case "Settings":
                return <Settings/>;
            case "RegisterToAddressScanProduct":
                return <RegisterToAddressScanProduct/>;
            case "RegisterToAddressEnterAddress":
                return <RegisterToAddressEnterAddress/>;
            case "RegisterToAddressResult":
                return <RegisterToAddressResult/>;
            case "TakeProductsFromAddress":
                return <TakeProductsFromAddress/>;
            case "ChangeScannedProducts":
                return <ChangeScannedProducts/>;
            case "CreateDocument":
                return <CreateDocument/>;
            case "Replenishment":
                return <Replenishment/>;
            case "ReplenishmentProductSelected":
                return <ReplenishmentProductSelected/>;
            case "AmountsInBins":
                return <AmountsInBins/>;
            case "Stocktakings":
                return <Stocktakings/>;
            case "BinManagement":
                return <BinManagement/>;
            case "AddBin":
                return <AddBin/>;
            case "ChangeBin":
                return <ChangeBin/>;
            case "ImportBins":
                return <ImportBins/>;
            case "ImportBinQuantities":
                return <ImportBinQuantities/>;
            case "Assignment":
                return <Assignment/>;
            case "DpdParcelLabels":
                return <DpdParcelLabels/>;
            case "DpdParcelLabelsNew":
                return <DpdParcelLabelsNew/>;
            case "VenipakParcelLabels":
                return <VenipakParcelLabels/>;
            case "SmartpostParcelLabels":
                return <SmartpostParcelLabels/>;
            case "OmnivaParcelLabels":
                return <OmnivaParcelLabels/>;
            case "SearchInventoryTransfers":
                return <SearchInventoryTransfers/>;
            case "InventoryTransfersListConfirmation":
                return <InventoryTransfersListConfirmation/>;
            case "AssignmentList":
                return <AssignmentList/>;
            case "ImportBinOrder":
                return <ImportBinOrder/>;
            case "CreateEditProduct":
                return <CreateEditProduct/>;
            case "UndeliveredGoods":
                return <UndeliveredGoods/>;
            case "CheckGoodsInBinScanFinish":
                return <CheckGoodsInBinScanFinish/>;
            case "CheckGoodsInBinChangeScannedProducts":
                return <CheckGoodsInBinChangeScannedProducts/>;
            case "ProductInReceivingAreaReport":
                return <ProductInReceivingAreaReport/>;
            case "ActiveDocumentReadOnlyView":
                return <ActiveDocumentReadOnlyView/>;
            default:
                return <Login/>;
        }
    };

    const showModal = () => {
        if (modalHeading !== "" || modalMessage !== "") {
            return <ModalPopup/>;
        }
    };

    return (
        <div className="App">
            <div>
                <Header/>
                {showModal()}
                <InformativeModal/>
                {showDimmer()}
                {renderComponent()}
            </div>
        </div>
  );
}

removeReduxPersistData();

export default App;
