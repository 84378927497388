const initialState = {
    text: "",
    header: ""
};

export const informativeModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_INFORMATIVE_MODAL":
            return {
                ...state,
                text: action.payload.text,
                header: action.payload.header
            };
        default:
            return state;
    }
};
