export const getBinsSuccess = (bins) => {
    return {
        type: "GET_BINS_SUCCESS",
        payload: bins
    }
};

export const setReceivingArea = (bins) => {
    return {
        type: "SET_RECEIVING_AREA",
        payload: bins[0]
    }
};

export const setCart = (bins) => {  // An intermediary bin holding products between scanning and confirming replenishment
    return {
        type: "SET_CART",
        payload: bins[0]
    }
};
