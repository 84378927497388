import React, { useState, useEffect, useRef } from 'react';
import {Button, Input, Label, Table, Transition} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {componentSet, setIsLoading} from "../actions/component";
import {errorMessageSet, successMessageSet} from "../actions/headerMessage";
import {useDispatch, useSelector} from "react-redux";
import GoBackToStartBtn from "./GoBackToStartBtn";
import {getBinQuantities, getBins, makeErplyRequest} from "../util/erplyRequests";
import {
    setBinQuantitiesWithScannedProduct, setBinQuantitiesWithScannedProductBins,
    setDestinationBin,
    setDestinationBinAmountBefore,
    setPlacedAmount,
    setPreferredBins, setPreferredBinsBinQuantities
} from "../actions/placeProducts";
import GoBackBtn from "./GoBackBtn";
import {translateBinToEng} from "../util/misc";
import {isAlasKuul} from "../util/isClient";
import PrintLabelsButton from "./PrintLabelsButton";

const RegisterToAddressEnterAddress = () => {
    const binCodeInput = useRef(null);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [binCode, setBinCode] = useState("");
    const [amount, setAmount] = useState("");
    const [showExplanations, setShowExplanations] = useState(false);

    const scannedProduct = useSelector(state => state.placeProductsReducer.scannedProduct);
    const sourceBin = useSelector(state => state.placeProductsReducer.sourceBin);
    const preferredBins = useSelector(state => state.placeProductsReducer.preferredBins);
    const preferredBinsBinQuantities = useSelector(state => state.placeProductsReducer.preferredBinsBinQuantities);
    const binQuantitiesWithScannedProduct = useSelector(state => state.placeProductsReducer.binQuantitiesWithScannedProduct);
    const binQuantitiesWithScannedProductBins = useSelector(state => state.placeProductsReducer.binQuantitiesWithScannedProductBins);
    const user = useSelector(state => state.verifyUserReducer.user);
    const selectedWarehouse = useSelector(state => state.getWarehousesReducer.selectedWarehouse);
    const language = useSelector(state => state.languageReducer.language);
    const isLoading = useSelector(state => state.componentReducer.isLoading);
    const previousComponent = useSelector(state => state.componentReducer.previousComponent);
    const clientCode = useSelector(state => state.verifyUserReducer.clientCode);

    const isRegisterProductsToAddress = !scannedProduct.hasOwnProperty("amount");   // From "Place products" -> "Register products to address" or "Stocktakings", no source bin

    useEffect(() => {
        let requestBlockIsComplete = false;
        dispatch(setIsLoading(true));

        getAllowedProductPreferredBins().then((preferredBins) => {
            if (preferredBins.status !== "error") {
                getPreferredBinsBinQuantities(preferredBins).then(() => {
                    if (requestBlockIsComplete) {
                        dispatch(setIsLoading(false));
                        binCodeInput.current.focus();
                    } else {
                        requestBlockIsComplete = true;
                    }
                });
            }
        });

        getBinQuantitiesWithScannedProduct().then(binQuantitiesWithScannedProduct => {
            if (binQuantitiesWithScannedProduct.status !== "error") {
                getBinQuantitiesWithScannedProductBins(binQuantitiesWithScannedProduct).then(() => {
                    if (requestBlockIsComplete) {
                        dispatch(setIsLoading(false));
                        binCodeInput.current.focus();
                    } else {
                        requestBlockIsComplete = true;
                    }
                });
            }
        });
    }, []);

    const getAllowedProductPreferredBins = () => {
        if (scannedProduct.code === null || scannedProduct.code.trim() === "") {
            dispatch(setPreferredBins([]));
            return Promise.resolve([]);
        }
        return dispatch(getBins(t, false, setPreferredBins, true, scannedProduct.code.trim()));
    };

    const getPreferredBinsBinQuantities = (preferredBins) => {
        const params = {
            request: "getBinQuantities",
            warehouseID: selectedWarehouse.warehouseID,
            binIDs: preferredBins.filter(bin => bin.code !== "receiving_area").map(bin => bin.binID).join(","),
        };

        return dispatch(makeErplyRequest(params, t("getBinQuantitiesError"), null, setPreferredBinsBinQuantities, null, true, false));
    };

    const getBinQuantitiesWithScannedProduct = () => {
        const params = {
            request: "getBinQuantities",
            warehouseID: selectedWarehouse.warehouseID,
            productIDs: scannedProduct.productID
        };

        return dispatch(makeErplyRequest(params, t("getBinQuantitiesError"), null, setBinQuantitiesWithScannedProduct, null, true, false));
    };

    const getBinQuantitiesWithScannedProductBins = (binQuantitiesWithScannedProduct) => {
        if (binQuantitiesWithScannedProduct.length === 0) {
            dispatch(setBinQuantitiesWithScannedProductBins([]));
            return Promise.resolve([]);
        } else {
            const params = {
                request: "getBins",
                status: "ACTIVE",
                warehouseID: selectedWarehouse.warehouseID,
                binIDs: binQuantitiesWithScannedProduct.map(binQuantity => binQuantity.binID).join(",")
            };

            return dispatch(makeErplyRequest(params, t("getBinsError"), null, setBinQuantitiesWithScannedProductBins, null, true, false));
        }
    };

    const getTotalAmountOfProductsInBin = (binID, binQuantities) => {
        let amount = 0;
        for (let i = 0, n = binQuantities.length; i < n; i++) {
            if (binQuantities[i].binID == binID && binQuantities[i].amount > 0) {
                amount += binQuantities[i].amount;
            }
        }

        return amount;
    };

    const handleGoBackOnClick = () => {
        dispatch(componentSet(previousComponent));
    };

    const handleOkOnClick = async () => {
        if (binCode === null) {
            dispatch(errorMessageSet(t("enterBin")));
        } else if (Number(amount) <= 0) {
            dispatch(errorMessageSet(t("amountMustBeOver0")));
        } else if (amount > scannedProduct.amount) {
            dispatch(errorMessageSet(t("amountExceedsAmountInBin")));
        } else {
            const bin = await getBin();

            if (bin === undefined) {
                dispatch(errorMessageSet(t("noBinFound")));
            } else if (!isRegisterProductsToAddress && sourceBin.binID === bin.binID) {
                dispatch(errorMessageSet(t("binsAreSame")));
            } else {
                saveBinRecords(bin);
            }
        }
    };

    const getBin = () => {
        const params = {
            request: "getBins",
            status: "ACTIVE",
            warehouseID: selectedWarehouse.warehouseID,
            code: translateBinToEng(binCode, language)
        };

        return dispatch(makeErplyRequest(params, t("getBinsError"))).then((bins) => {
            return bins[0];
        });
    };

    const saveBinRecords = async (bin) => {
        let binIDs = bin.binID;
        if (!isRegisterProductsToAddress) binIDs += `,${sourceBin.binID}`;
        const binQuantities = await dispatch(getBinQuantities(t, true, null, binIDs, scannedProduct.productID));
        const destinationBinQuantity = binQuantities.find(binQuantity => binQuantity.binID == bin.binID);
        const destinationBinAmountBefore = destinationBinQuantity ? destinationBinQuantity.amount : 0;

        const params = {
            request: "saveBinRecords",
            // Add amount to destination bin
            binID1: bin.binID,
            productID1: scannedProduct.productID,
            amount1: amount,
            creatorID1: user.employeeID
        };

        if (!isRegisterProductsToAddress) {
            const sourceBinQuantity = binQuantities.find(binQuantity => binQuantity.binID == sourceBin.binID);
            scannedProduct.amount = sourceBinQuantity ? sourceBinQuantity.amount : 0;
            if (amount > scannedProduct.amount) return dispatch(errorMessageSet(`${t("sourceBinAmountHasChanged")}! ${t("amountExceedsAmountInBin")}`));

            // Subtract amount from source bin
            params["binID2"] = sourceBin.binID;
            params["productID2"] = scannedProduct.productID;
            params["amount2"] = amount * (-1);
            params["creatorID2"] = user.employeeID;
        }

        dispatch(makeErplyRequest(params, t("saveBinRecordsError"))).then((response) => {
            if (response.status !== "error") {
                dispatch(setDestinationBin(bin));
                dispatch(setDestinationBinAmountBefore(destinationBinAmountBefore));
                dispatch(setPlacedAmount(Number(amount)));
                dispatch(successMessageSet(t("productWasSavedToBin"), 5000));
                dispatch(componentSet("RegisterToAddressResult"))
            }
        });
    };

    const getAmountRow = () => {
        if (!isRegisterProductsToAddress) {
            return <Table.Row>
                    <Table.Cell>{t("amount")}</Table.Cell>
                    <Table.Cell>{scannedProduct.amount}</Table.Cell>
                </Table.Row>
        }
    };

    const getSuggestedBins = () => {
        let preferredBinsWithProduct = [];
        let unpreferredBinsWithProduct = [];
        let allowedProductBins = [];
        let preferredBinsWithoutProduct = [];
        let unpreferredBinsWithoutProduct = [];

        // Check for bins with product code in "allowedProduct" field
        for (let i = 0, n = preferredBins.length; i < n; i++) {
            const binIsSourceBin = !isRegisterProductsToAddress && sourceBin.binID == preferredBins[i].binID;

            if (preferredBins[i].code !== "receiving_area" && !binIsSourceBin &&
                preferredBins[i].hasOwnProperty("allowedProduct") && preferredBins[i].allowedProduct !== "") {   // allowedProduct is a comma-separated string of product codes
                const allowedProductCodesArray = preferredBins[i].allowedProduct.split(",");

                if (allowedProductCodesArray.includes(scannedProduct.code)) {
                    if (preferredBins[i].maximumAmount > 0) {   // Maximum amount is set
                        const totalAmountOfProductsInBin = getTotalAmountOfProductsInBin(preferredBins[i].binID, preferredBinsBinQuantities); // Total of ALL products in bin

                        if (totalAmountOfProductsInBin < preferredBins[i].maximumAmount) {
                            allowedProductBins.push(preferredBins[i].code);
                        }
                    } else {
                        allowedProductBins.push(preferredBins[i].code);
                    }
                }
            }
        }

        // Check for bins where the product has been or is currently
        for (let i = 0, n = binQuantitiesWithScannedProduct.length; i < n; i++) {
            const bin = binQuantitiesWithScannedProductBins.find(bin => bin.binID == binQuantitiesWithScannedProduct[i].binID);

            if (bin !== undefined) {    // Bin has been archived
                const binIsSourceBin = !isRegisterProductsToAddress && sourceBin.binID == bin.binID;

                if (bin.code !== "receiving_area" && bin.code !== "cart" && !binIsSourceBin && !bin.code.startsWith("supplier")) {
                    if (binQuantitiesWithScannedProduct[i].amount > 0) {
                        bin.preferred == 1 ? preferredBinsWithProduct.push(bin.code) : unpreferredBinsWithProduct.push(bin.code);
                    } else {
                        bin.preferred == 1 ? preferredBinsWithoutProduct.push(bin.code) : unpreferredBinsWithoutProduct.push(bin.code);
                    }
                }
            }
        }

        [preferredBinsWithProduct, unpreferredBinsWithProduct, allowedProductBins, preferredBinsWithoutProduct, unpreferredBinsWithoutProduct].forEach(bins => {
            sortBinsAlphabetically(bins);
        });

        preferredBinsWithProduct = getDisplayableBinList(preferredBinsWithProduct);
        unpreferredBinsWithProduct = getDisplayableBinList(unpreferredBinsWithProduct);
        allowedProductBins = getDisplayableBinList(allowedProductBins);
        preferredBinsWithoutProduct = getDisplayableBinList(preferredBinsWithoutProduct);
        unpreferredBinsWithoutProduct = getDisplayableBinList(unpreferredBinsWithoutProduct);

        return [
            <p key={"binPriority1"} className={"lightGreenColor"}>{preferredBinsWithProduct}</p>,
            <p key={"binPriority2"} className={"blueColor"}>{unpreferredBinsWithProduct}</p>,
            <p key={"binPriority3"} className={"goldColor"}>{allowedProductBins}</p>,
            <p key={"binPriority4"} className={"silverColor"}>{preferredBinsWithoutProduct}</p>,
            <p key={"binPriority5"} className={"bronzeColor"}>{unpreferredBinsWithoutProduct}</p>,
        ];
    };

    const getDisplayableBinList = (binCodes) => {
        return binCodes.length > 0 ? binCodes.map(bin => <span onClick={() => setBinCode(bin)}>{bin}</span>).reduce((prev, curr) => [prev, '; ', curr]) : [];
    };

    const sortBinsAlphabetically = (bins) => {
        return bins.sort((a, b) => {
            const textA = a.toUpperCase();
            const textB = b.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
    };

    const createTable = () => {
        const suggestedBins = getSuggestedBins();
        const displayInfoButton = <Button className={"marginLeftSmall"} size={"tiny"} onClick={() => setShowExplanations(!showExplanations)}>?</Button>;
        const suggestedBinsExplanations = [
            <Transition visible={showExplanations} animation='slide down' duration={200} unmountOnHide>
                <div>
                    <div><div className={"square floatLeft lightGreenBackgroundColor"}/><span>&nbsp;{t("binPriority1Explanation")}</span></div>
                    <div><div className={"square floatLeft blueBackgroundColor"}/><span>&nbsp;{t("binPriority2Explanation")}</span></div>
                    <div><div className={"square floatLeft goldBackgroundColor"}/><span>&nbsp;{t("binPriority3Explanation")}</span></div>
                    <div><div className={"square floatLeft silverBackgroundColor"}/><span>&nbsp;{t("binPriority4Explanation")}</span></div>
                    <div><div className={"square floatLeft bronzeBackgroundColor"}/><span>&nbsp;{t("binPriority5Explanation")}</span></div>
                </div>
            </Transition>
        ];

        let code = scannedProduct.code;
        if (isAlasKuul(clientCode) && scannedProduct.productSupplierCode && scannedProduct.productSupplierCode !== "") {
            code += ` [${scannedProduct.productSupplierCode}]`;
        }

        return (
            <div>
                <Table celled inverted unstackable>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>{t("product")}</Table.Cell>
                            <Table.Cell>{code} {scannedProduct.name}</Table.Cell>
                        </Table.Row>
                        {getAmountRow()}
                        <Table.Row>
                            <Table.Cell><p>{t("suggestedBins")}{displayInfoButton}</p>{suggestedBinsExplanations}</Table.Cell>
                            <Table.Cell>{suggestedBins}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <div className={"searchRow"}>
                    <Label className={"searchLabel searchLabelMedium"} size={"large"} pointing={"right"}>{t("bin")}</Label>
                    <Input ref={binCodeInput} className={"searchInput searchInputMedium"} onChange={e => setBinCode(e.target.value)} placeholder={t("enterBin")} value={binCode}/>
                </div>
                <div className={"searchRow"}>
                    <Label className={"searchLabel searchLabelMedium"} size={"large"} pointing={"right"}>{t("amount")}</Label>
                    <Input type="number" className={"searchInput searchInputMedium"} onChange={e => setAmount(e.target.value)} placeholder={t("enterAmount")} value={amount}/>
                </div>
                <div className={"btnsGroup"}>
                    <Button className={"menuBtn"} primary size={"big"} onClick={handleOkOnClick}>Ok</Button>
                    <div className={"flex marginBottomSmall"}><PrintLabelsButton products={[scannedProduct]} className={"fullWidth"} size={"large"} text={t("printLabel")}/></div>
                    <div className={"flex flexCenter"}>
                        <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                        <GoBackToStartBtn/>
                    </div>
                </div>
            </div>
        )
    };

    return isLoading || !preferredBins ? null : createTable();
};

export default RegisterToAddressEnterAddress
