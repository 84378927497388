const initialState = {
    editableProduct: null,
};

export const createEditProductReducer = (state = initialState, action) => {
    switch(action.type) {
        case "SET_EDITABLE_PRODUCT":
            return {
                ...state,
                editableProduct: action.payload
            };
        default:
            return state;
    }
};
