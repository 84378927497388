export const setSearchResultDocuments = (documents) => {
    return {
        type: "SET_SEARCH_RESULT_DOCUMENTS",
        payload: documents
    }
};

export const setSearchResultFollowUpDocuments = (documents) => {
    return {
        type: "SET_SEARCH_RESULT_FOLLOW_UP_DOCUMENTS",
        payload: documents
    }
};

// Orders of search result follow up documents that are not in search result. Needed to subtract amounts to get scannable amounts
export const setSearchResultFollowUpDocumentsAdditionalOrders = (documents) => {
    return {
        type: "SET_SEARCH_RESULT_FOLLOW_UP_DOCUMENTS_ADDITIONAL_ORDERS",
        payload: documents
    }
};

// Get purchase invoices to calculate undelivered goods, Rehvid Pluss only
export const setIsGetUndeliveredGoods = (isGetUndeliveredGoods) => {
    return {
        type: "SET_IS_GET_UNDELIVERED_GOODS",
        payload: isGetUndeliveredGoods
    }
};

// Needed for multiple order scan where scanned amounts from each order are saved to JSON attributes
export const setSearchResultFollowUpDocumentsJsonAttributes = (jsonAttributes) => {
    return {
        type: "SET_SEARCH_RESULT_FOLLOW_UP_DOCUMENTS_JSON_ATTRIBUTES",
        payload: jsonAttributes
    }
};
