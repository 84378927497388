import {Button, Icon} from "semantic-ui-react";
import React from "react";
import {useTranslation} from "react-i18next";

const GoBackBtn = (props) => {
    const { t } = useTranslation();
    const text = props.hasOwnProperty("btnText") ? props.btnText : t("goBack");

    let classNames = ["menuBtnHalfWidth", "paddingLeft"];
    if (props.hasOwnProperty("removeClassNames")) {
        for (let i = 0, n = props.removeClassNames.length; i < n; i++) {
            classNames = classNames.filter(className => className !== props.removeClassNames[i]);
        }
    }

    if (props.hasOwnProperty("addClassNames")) {
        classNames = classNames.concat(props.addClassNames);
    }

    const size = props.hasOwnProperty("size") ? props.size : "large";

    return (
        <Button className={classNames.join(" ")} onClick={props.handleGoBackOnClick} size={size}>
            <Icon name={"angle left"}/>
            {text}
        </Button>
    )
};

export default GoBackBtn
