export const getCustomersSuccess = (customers) => {
    return {
        type: "GET_CUSTOMERS_SUCCESS",
        payload: customers
    }
};

export const setReceivers = (customers) => {
    return {
        type: "SET_RECEIVERS",
        payload: customers
    }
};
