import React, { useState, useEffect } from 'react';
import {Button, Table} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {componentSet, previousComponentSet} from "../actions/component";
import {setSelectedDocuments} from "../actions/scan";
import GoBackToStartBtn from "./GoBackToStartBtn";
import {setModal} from "../actions/modal";
import GoBackBtn from "./GoBackBtn";
import {
    formatDateStringToEst,
    getElementByFieldValue,
    isSelectedRow,
    setExpandedRow,
    translateAccordingToCountry
} from "../util/misc";
import {getBinRecords} from "../util/erplyRequests";

const ReturnsListConfirmation = () => {
    const searchResultDocuments = useSelector(state => state.searchDocumentsReducer.searchResultDocuments);
    const language = useSelector(state => state.languageReducer.language);
    const binRecords = useSelector(state => state.getBinRecordsReducer.binRecords);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const componentSequence = useSelector(state => state.componentReducer.componentSequence);

    const isPurchaseReturn = componentSequence.includes("ProductsOut");

    const [expandedRows, setExpandedRows] = useState([]);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getBinRecords(t, searchResultDocuments.map(doc => doc.id).join(","), isPurchaseReturn? "PURCHASE_DOCUMENT" : "SALES_DOCUMENT", null, true));
    }, []);

    const handleGoBackOnClick = () => {
        dispatch(componentSet("ReturnProducts"));
    };

    const createRows = () => {
        return searchResultDocuments.map(document => createRow(document));
    };

    const createRow = (document) => {
        const isSelected = isSelectedRow(document.id, expandedRows);
        const date = language === "est" ? formatDateStringToEst(document.date) : document.date;

        const packingHasStarted = binRecords.some(binRecord => binRecord.documentID == document.id);
        const buttonColor = packingHasStarted ? "red" : "blue";

        let salesDoc = [<Table.Row key={document.id}>
            <Table.Cell><Button size={"mini"} color={buttonColor} icon={"check"} onClick={() => {handleCheckClick(document.id, packingHasStarted)}}/></Table.Cell>
            <Table.Cell onClick={() => setExpandedRow(document.id, expandedRows, setExpandedRows)}>{isPurchaseReturn ? document.supplierName : document.clientName}</Table.Cell>
            <Table.Cell onClick={() => setExpandedRow(document.id, expandedRows, setExpandedRows)}>{isPurchaseReturn ? document.number : (document.shipToName ?? "")}</Table.Cell>
            <Table.Cell onClick={() => setExpandedRow(document.id, expandedRows, setExpandedRows)}>{t(document.type)}</Table.Cell>
            <Table.Cell onClick={() => setExpandedRow(document.id, expandedRows, setExpandedRows)}>{date}</Table.Cell>
            <Table.Cell onClick={() => setExpandedRow(document.id, expandedRows, setExpandedRows)}>{document.warehouseName}</Table.Cell>
        </Table.Row>];

        if (isSelected) {
            salesDoc.push(createDocRowsTable(document));
        }

        return salesDoc;
    };

    const createDocRowsTable = (document) => {
        return (
            <tr key={document.lastModified}>
                <td colSpan={6}>
                    <Table celled structured unstackable color={"blue"} inverted>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t("code")}</Table.HeaderCell>
                                <Table.HeaderCell>{translateAccordingToCountry(t("EAN"), confParameters)}</Table.HeaderCell>
                                <Table.HeaderCell>{t("name")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("amount")}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {createDocRowsTableRows(document)}
                        </Table.Body>
                    </Table>
                </td>
            </tr>
        )
    };

    const createDocRowsTableRows = (document) => {
        return document.rows.map((row, index) => createDocRowsTableRow(row, index));
    };

    const createDocRowsTableRow = (row, index) => {
        return (
            <Table.Row key={index}>
                <Table.Cell>{row.code}</Table.Cell>
                <Table.Cell>{isPurchaseReturn ? row.code2 : row.barcode}</Table.Cell>
                <Table.Cell>{row.itemName}</Table.Cell>
                <Table.Cell>{row.amount}</Table.Cell>
            </Table.Row>
        )
    };

    const handleCheckClick = (documentID, packingHasStarted) => {
        if (packingHasStarted) {
            dispatch(setModal(t("confirmation"), t(isPurchaseReturn ? "documentPackingAlreadyStarted" : "creditInvoiceHandlingAlreadyStarted"), () => selectDocumentForScan(documentID)));
        } else {
            selectDocumentForScan(documentID);
        }
    };

    const selectDocumentForScan = async (documentID) => {
        const selectedDocument = getElementByFieldValue(searchResultDocuments, "id", documentID);
        await dispatch(setSelectedDocuments([selectedDocument], t, [], true));
        dispatch(componentSet("Scan"));
        dispatch(previousComponentSet("ReturnsListConfirmation"));
    };

    return (
        <div className={"overflow"}>
            <Table className={"fontSizeMed"} celled structured unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell/>
                        <Table.HeaderCell>{isPurchaseReturn ? t("supplier") : t("customer")}</Table.HeaderCell>
                        <Table.HeaderCell>{isPurchaseReturn ? t("number") : t("receiverOfGoods")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("type")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("date")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("warehouse")}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {createRows()}
                </Table.Body>
            </Table>
            <div className={"flex flexCenter"}>
                <GoBackBtn handleGoBackOnClick={handleGoBackOnClick}/>
                <GoBackToStartBtn/>
            </div>
        </div>
    );
};

export default ReturnsListConfirmation
