// export const akTc2000BinId = 1;  // TEST
export const akTc2000BinId = 2490;
export const akTc2000LocationInWarehouseId = 1310;
export const timeoutBetweenPrint = 1000;
export const arvutitarkWarehouseIDsExcludedFromInTransit = [
    2, // Garantiiladu
    14 // Tootmine
];

export const reduxPersistLocalStorageItemName = "persist:root";

export const dpdUserWarehouseIdAttributeName = "dpdUserWarehouseId"; // OnOff only
export const inventoryTransferDeliveryTypeAttributeName = "deliveryType";
export const lastScanConfirmedAttributeName = "wmsLastScanConfirmed";
export const wmsWarningAttributeName = "wmsWarning";
export const documentFirstOpenedAttributeName = "documentFirstOpenedInWMS";
export const omnivaDeliveryTypeAttributeName = "omnivaDeliveryChannel";
export const createdInWmsAttributeName = "createdInWms";
