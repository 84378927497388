import React, {useState} from 'react';
import {Button, Input, Label} from 'semantic-ui-react'
import {useTranslation} from "react-i18next";
import {componentSet, setIsLoading, setSequence} from "../actions/component";
import {useDispatch, useSelector} from "react-redux";
import {getApiAttributeValue, getConfParameterValue, isNotAPositiveInteger, simpleLog} from "../util/misc";
import {errorMessageSet} from "../actions/headerMessage";
import {sendToPrintService} from "../util/printRequests";
import {makeErplyRequest} from "../util/erplyRequests";

const VenipakParcelLabels = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const createdDocumentId = useSelector(state => state.scanReducer.createdDocumentId);
    const componentSequence = useSelector(state => state.componentReducer.componentSequence);
    const confParameters = useSelector(state => state.getConfParametersReducer.confParameters);
    const sessionKey = useSelector(state => state.verifyUserReducer.user.sessionKey);
    const clientCode = useSelector(state => state.verifyUserReducer.clientCode);
    const selectedDocuments = useSelector(state => state.scanReducer.selectedDocuments);

    const isOpenInAndroidApp = window.ReactNativeWebView;
    const canPrint = dispatch(getConfParameterValue("wmsDpdPrinterName")) !== "" && dispatch(getConfParameterValue("wmsPrinterServiceAddress")) !== "" && isOpenInAndroidApp;
    const noOfParcelsOnDocument = getApiAttributeValue("num_of_parcel", selectedDocuments[0].attributes);

    const [noOfParcels, setNoOfParcels] = useState(noOfParcelsOnDocument && noOfParcelsOnDocument != 0 ? noOfParcelsOnDocument : 1);

    const getCreatedDocument = () => {
        const params = {
            request: "getSalesDocuments",
            id: createdDocumentId
        };

        return dispatch(makeErplyRequest(params, t("getSalesDocumentsError"))).then(docs => docs[0]);
    };

    const validate = async () => {
        if (isNotAPositiveInteger(noOfParcels)) {
            return dispatch(errorMessageSet(t("notAPositiveInteger")));
        } else if (noOfParcels > 20) {
            return dispatch(errorMessageSet(t("maxNoOfParcelsIs20")));
        }

        const createdDocument = await getCreatedDocument();
        const receiverID = confParameters.invoice_client_is_payer == 1 ? createdDocument.shipToID : createdDocument.clientID;
        const receiverAddressID = confParameters.invoice_client_is_payer == 1 ? createdDocument.shipToAddressID : createdDocument.addressID;

        if (receiverID == 0) {
            return dispatch(errorMessageSet(t("createdDocumentHasNoReceiver")));
        } else if (receiverAddressID == 0) {
            return dispatch(errorMessageSet(t("createdDocumentHasNoReceiverAddress")));
        }

        sendToVenipakService(createdDocument);
    };

    const sendToVenipakService = (createdDocument) => {
        const parameters = {
            sessionKey: sessionKey,
            customerCode: clientCode,
            invoiceID: createdDocumentId,
            noOfParcels: noOfParcels,
            returnBase64: 1
        };
        // let url = 'http://localhost/venipak/public/getShipmentData';
        let url = 'https://intralplugins.com/Venipak/public/getShipmentData';
        url += `?${Object.entries(parameters).map(([key, val]) => `${key}=${val}`).join('&')}`;

        dispatch(setIsLoading(true));
        fetch(url, {
            method: "GET"
        })
            .then(response => response.text())
            .then(data => {
                dispatch(setIsLoading(false));
                simpleLog("Venipak url: " + url);
                simpleLog("Venipak response: " + data);
                try {
                    atob(data); // If not base64 then it is an error message
                    saveAttributes();

                    if (canPrint) {
                        sendToPrintService(data, dispatch(getConfParameterValue("wmsDpdPrinterName")), dispatch(getConfParameterValue("wmsPrinterServiceAddress")));
                    } else {
                        downloadLabels(data, createdDocument);
                    }

                    setPreScanComponent();
                } catch (e) {
                    const errorMessage = `${t("venipakError")}: ${data}`;
                    dispatch(errorMessageSet(errorMessage));
                }
            }).catch(err => {
                dispatch(errorMessageSet(err));
            });
    };

    const saveAttributes = () => {
        dispatch(makeErplyRequest({
            request: "saveSalesDocument",
            id: createdDocumentId,
            attributeName0: "num_of_parcel",
            attributeValue0: noOfParcels
        }, t("saveSalesDocumentError"), null, null, null, false, false));
    };

    const downloadLabels = (labels, createdDocument) => {
        const byteCharacters = atob(labels);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const file = new Blob([byteArray], { type: 'application/pdf;base64' });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = `Venipak_${t("parcelLabels")}_${createdDocument.number}`;
        link.dispatchEvent(new MouseEvent('click'));
    };

    const setPreScanComponent = () => {
        const preScanComponent = componentSequence[3];
        const newSequence = componentSequence.slice(0, 4);

        dispatch(componentSet(preScanComponent));
        dispatch(setSequence(newSequence));
    };

    return (
        <div>
            <div className={"searchRow"}>
                <Label className={"searchLabel"} size={"large"} pointing={"right"}>{t("noOfParcels")}</Label>
                <Input className={"searchInput searchInputMedium"} type="number" onInput={e => setNoOfParcels(e.target.value)} value={noOfParcels}/>
            </div>
            <Button className={"menuBtn"} primary onClick={validate} size='big'>{t("createVenipakLabels")}</Button>
            <Button className={"menuBtn marginTop"} onClick={setPreScanComponent} size='big'>{t("goBack")}</Button>
            {/*<Button className={"menuBtn marginTop"} onClick={setPreScanComponent} size='big'>{t("doNotWantVenipakLabels")}</Button>*/}
        </div>
    );
};

export default VenipakParcelLabels
