import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import thunk from 'redux-thunk';
import rootReducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import './translations/i18n';
import "./util/polyfills"
import HttpsRedirect from 'react-https-redirect';
import * as Sentry from "@sentry/react";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { PersistGate } from 'redux-persist/integration/react'

const persistConfig = {
    key: 'root',
    storage,
    // There is an issue in the source code of redux-persist (default setTimeout does not clean)
    timeout: null,
    // blacklist: ['getSalesDocumentsReducer']
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = applyMiddleware(thunk);
const store = createStore(persistedReducer, composeWithDevTools(middleware));
// const store = createStore(rootReducer, composeWithDevTools(middleware));
const persistor = persistStore(store);

// Sentry.init({
//     dsn: "https://7b114fe609a74b78a60b2294eb1ce3b1@sentry.nimi24.com/54",
//     release: "1.0.0",
// });

ReactDOM.render(
    <HttpsRedirect>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </HttpsRedirect>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
