const initialState = {
    sourceBin: null,
    scannedProduct: null,
    placedAmount: 0,
    destinationBin: null,
    destinationBinAmountBefore: 0,
    sourceBinQuantities: null,
    preferredBins: [],   // Needed for getting bins where "allowedProduct" field contains product
    preferredBinsBinQuantities: [],   // Needed to check whether quantity exceeds bin's max
    binQuantitiesWithScannedProduct: [],   // Needed to suggest bins
    binQuantitiesWithScannedProductBins: [],   // Needed to suggest bins
    isCheckGoodsInReceivingArea: false,
    salesOrdersWithProducts: [],
    receivingAreaReportProducts: [],
    isCheckGoodsInBin: false,
    scannedProducts: [],
};

export const placeProductsReducer = (state = initialState, action) => {
    switch(action.type) {
        case "SET_SOURCE_BIN":
            return {
                ...state,
                sourceBin: action.payload
            };
        case "SET_SCANNED_PRODUCT":
            return {
                ...state,
                scannedProduct: action.payload
            };
        case "SET_PLACED_AMOUNT":
            return {
                ...state,
                placedAmount: action.payload
            };
        case "SET_DESTINATION_BIN":
            return {
                ...state,
                destinationBin: action.payload
            };
        case "SET_DESTINATION_BIN_AMOUNT_BEFORE":
            return {
                ...state,
                destinationBinAmountBefore: action.payload
            };
        case "SET_SOURCE_BIN_QUANTITIES":
            return {
                ...state,
                sourceBinQuantities: action.payload
            };
        case "SET_PRODUCTS_DISPLAYED":
            return {
                ...state,
                productsDisplayed: action.payload
            };
        case "SET_PREFERRED_BINS":
            return {
                ...state,
                preferredBins: action.payload
            };
        case "SET_PREFERRED_BINS_BIN_QUANTITIES":
            return {
                ...state,
                preferredBinsBinQuantities: action.payload
            };
        case "SET_BIN_QUANTITIES_WITH_SCANNED_PRODUCT":
            return {
                ...state,
                binQuantitiesWithScannedProduct: action.payload
            };
        case "SET_BIN_QUANTITIES_WITH_SCANNED_PRODUCT_BINS":
            return {
                ...state,
                binQuantitiesWithScannedProductBins: action.payload
            };
        case "SET_IS_CHECK_GOODS_IN_RECEIVING_AREA":
            return {
                ...state,
                isCheckGoodsInReceivingArea: action.payload
            };
        case "SET_SALES_ORDERS_WITH_PRODUCTS":
            return {
                ...state,
                salesOrdersWithProducts: action.payload
            };
        case "SET_RECEIVING_AREA_REPORT_PRODUCTS":
            return {
                ...state,
                receivingAreaReportProducts: action.payload
            };
        case "SET_IS_CHECK_GOODS_IN_BIN":
            return {
                ...state,
                isCheckGoodsInBin: action.payload
            };
        case "SET_SCANNED_PRODUCTS":
            return {
                ...state,
                scannedProducts: action.payload
            };
        default:
            return state;
    }
};
