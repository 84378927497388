const initialState = {
    binQuantities: [],
    binQuantitiesOfProductNotOnDocument: []
};

export const getBinQuantitiesReducer = (state = initialState, action) => {
    switch(action.type) {
        case "GET_BIN_QUANTITIES_SUCCESS":
            return {
                ...state,
                binQuantities: action.payload,
                binQuantitiesOfProductNotOnDocument : []
            };
        case "SET_BIN_QUANTITIES_OF_PRODUCT_NOT_ON_DOCUMENT":
            return {
                ...state,
                binQuantitiesOfProductNotOnDocument: action.payload
            };
        default:
            return state;
    }
};
